import { Component, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from '../../../core/overlay/dialog/dialog.service';

@Component( {
    selector:    'rsp-legal-notice-dialog',
    templateUrl: './legal-notice-dialog.component.html',
    styleUrls:   [
        '../../scss/05_module/standard-dialog.scss',
        './legal-notice-dialog.component.scss',
    ],
} )
export class LegalNoticeDialogComponent {
    @ViewChild( 'dialogHeadline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContent', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooter', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    constructor(
        private dialogService: DialogService,
    ) {
    }

    openDialog(): void {
        this.dialogService.openDialog( {
            headlineTemplate: this.headlineTemplate,
            contentTemplate:  this.contentTemplate,
            footerTemplate:   this.footerTemplate,
            withBackdrop:     true,
        } );
    }

    closeDialog(): void {
        this.dialogService.closeDialog();
    }
}
