import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { UntypedFormGroup, } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { MaterialInfo, } from '../../../../../../../shared/model/materialInfo';
import { MessagesCssSubmodule, MessageType } from '../../../../../../../shared/ui/messages/messages.component';
import { ArticleMaterial } from '../../../../../../../shared/model/articleMaterial';

@Component( {
    selector:    'rsp-edit-material',
    templateUrl: './edit-material.component.html',
    styleUrls:   [
        './edit-material.component.scss',
    ],
} )
export class EditMaterialComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Output() selected: EventEmitter<MaterialInfo> = new EventEmitter<MaterialInfo>();

    /**
     * Optional CSS Submodule for <rsp-messages>.
     */
    @Input() validationMessagesCssSubmodule: MessagesCssSubmodule;

    /**
     * Is emitted when a display-edit-component gets invalid and provides the validation messages to the parent component.
     */
    @Output() validationMessagesChanged: EventEmitter<string[]> = new EventEmitter();

    @Output() materialRemoved: EventEmitter<void> = new EventEmitter<void>();

    messageTypeError: MessageType = MessageType.Error;

    filterIds: Array<string> = [];

    set validationMessages( validationMessages: Array<string> ) { this.setValidationMessages( validationMessages ); }
    get validationMessages(): Array<string> { return this._validationMessages; }
    private _validationMessages: Array<string> = [];


    ngOnInit(): void {
        if ( !this.group ) {
            throw new Error( 'Attribute "formGroup" must be set!' );
        }

        // in case of "clear" (via text-input directive) the changes seem to be delayed, so small delay is needed
        this.group.valueChanges.pipe( debounceTime(1) ).subscribe( () => {
            this.validationMessages = [];

            if ( this.group && this.group.dirty && !this.group.valid && this.group.errors ) {

                if ( this.group.errors[ 'nameRequiredWhenPercentage' ] ) {
                    this.validationMessages.push( 'Material has to be set, if percentage is given.' );
                }
                if ( this.group.errors[ 'percentageNotValidNumber' ] ) {
                    this.validationMessages.push( 'Percentage value is not valid.' );
                }
            }
        } );

        this.setFilterIds();
    }



    selectedItem( materialSuggestItem: MaterialInfo ): void {
        this.selected.emit( materialSuggestItem );
        this.setFilterIds();
    }

    triggerRemove(): void {
        this.materialRemoved.emit();
    }

    private setValidationMessages( validationMessages: Array<string> ): void {
        this._validationMessages = validationMessages;
        this.validationMessagesChanged.emit( validationMessages );
    }


    private setFilterIds(): void {
        if ( this.group.parent && this.group.parent.value ) {
            this.filterIds =
                this.group.parent.value.filter( ( item: ArticleMaterial ) => {
                    return item.materialId !== this.group.value.materialId;
                } ).map( ( item: ArticleMaterial ) => {
                    return item.materialId;
                });
        }
    }
}
