import { Injectable } from '@angular/core';

/**
 * Use this service to generate uniq strings that can be used as HTML-ID attribute.
 * For example if you want to link a `<label>` and an `<input>`:
 *
 *     // ts:
 *     ngOnInit() { this.htmlId = service.getUniqueHtmlId(); }
 *
 *     // html:
 *     <label for="{{ htmlId }}">Label:</label>
 *     <input id="{{ htmlId }}">
 *
 * @example
 *     service.getUniqueHtmlId();        // will return 'html-node-1';
 *     service.getUniqueHtmlId( 'foo' ); // will return 'foo-2';
 *     service.getUniqueHtmlId( 'foo' ); // will return 'foo-3';
 *     service.getUniqueHtmlId( 'bar' ); // will return 'bar-4';
 */
@Injectable()
export class UniqueHtmlIdService {
    currentId: number = 0;

    getUniqueHtmlId( prefix?: string ): string {
        this.currentId++;
        return ( prefix || 'html-node' ) + '-' + this.currentId;
    }
}
