import { AfterViewInit, Component, Input, ViewChild } from '@angular/core';

import { AuthenticationService } from '../authentication.service';
import { LegalNoticeDialogComponent } from '../../shared/ui/legal-notice-dialog/legal-notice-dialog.component';
import { ImprintDialogComponent } from '../../shared/ui/imprint-dialog/imprint-dialog.component';

@Component( {
    selector:    'rsp-login',
    templateUrl: './login.component.html',
    styleUrls:   [
        './login.component.scss',
    ],
} )
export class LoginComponent implements AfterViewInit {
    @ViewChild( LegalNoticeDialogComponent, { static: true } ) legalNoticeDialog: LegalNoticeDialogComponent;
    @ViewChild( ImprintDialogComponent, { static: true } ) imprintDialog: ImprintDialogComponent;

    @Input() showLogoutMessage: boolean;

    constructor(
        private authenticationService: AuthenticationService,
    ) {}

    ngAfterViewInit(): void {
        window.document.cookie = 'TK=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    }

    login(): void {
        this.authenticationService.login();
    }

    openLegalNotice(): void {
        this.legalNoticeDialog.openDialog();
    }

    openImprint(): void {
        this.imprintDialog.openDialog();
    }
}
