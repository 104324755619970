import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable } from 'rxjs';


/**
 * Controls main menu.
 */
@Injectable()
export class MainMenuService {

    isOpen$: Observable<boolean>;

    private isOpen: BehaviorSubject<boolean> = new BehaviorSubject( false );


    constructor() {
        this.isOpen$ = this.isOpen.asObservable();
    }

    /**
     * Opens main menu.
     */
    open(): void {
        this.isOpen.next( true );
    }

    /**
     * Closes main menu.
     */
    close(): void {
        this.isOpen.next( false );
    }

    /**
     * Toggles main menu visibility.
     */
    toggle(): void {
        if ( this.isOpen.value ) {
            this.close();
        }
        else {
            this.open();
        }
    }
}
