import { Injectable } from '@angular/core';

@Injectable()
export class FormatNumberService {

    /**
     * Small numbers in JavaScript maybe displayed in scientific exponential display, e.g. 0.000000006 becomes 6e-9.
     * Use this method to convert 6e-9 back to 0.000000006 (as string);
     * This method will not convert numbers with more than 10 decimal places (they aren't readable in fixed format anyway).
     */
    public convertExponentialToFixed( value: number ): string {
        let wasConversionSuccessful: boolean = false;
        let fixedRepresentation: string = '';

        for ( let i: number = 10; i >= 0; i-- ) {
            fixedRepresentation = value.toFixed( i );
            if ( fixedRepresentation.charAt( fixedRepresentation.length - 1 ) !== '0' ) {
                wasConversionSuccessful = true;
                break;
            }
        }

        return wasConversionSuccessful ? fixedRepresentation : value.toString();
    }

}
