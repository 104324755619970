import { NgModule, Optional, SkipSelf, } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { PageTitleService } from '@nureg/angular-page-title';

import { SharedModule,                 } from '../shared/shared.module';

import { ApplicationTimeService,       } from './application-time.service';
import { RspUserGuard,          } from './guards/rsp-user.guard';
import { BootstrapService,             } from './bootstrap.service';
import { MasterHeadComponent,          } from './master-head/master-head.component';
import { NotificationService,               } from './overlay/notification/notification.service';
import { StorageService,               } from './storage.service';
import { SystemInfoComponent,          } from './system-info/system-info.component';
import { UniqueHtmlIdService,          } from './unique-html-id.service';
import { CurrentUserService,                  } from './current-user.service';
import { WebApiService,                } from './webapi.service';
import { AuthenticationService,        } from './authentication.service';
import { PopupComponent } from './overlay/popup/popup.component';
import { PopupService } from './overlay/popup/popup.service';
import { NotificationListComponent } from './overlay/notification/notification-list/notification-list.component';
import { DialogComponent } from './overlay/dialog/dialog.component';
import { DialogService } from './overlay/dialog/dialog.service';
import { WindowEventService } from './window-event.service';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MainMenuService } from './main-menu/main-menu.service';
import { MainSlideInComponent } from './overlay/main-slide-in/main-slide-in.component';
import { MainSlideInService } from './overlay/main-slide-in/main-slide-in.service';
import { BackdropComponent } from './overlay/backdrop/backdrop.component';
import { BackdropService } from './overlay/backdrop/backdrop.service';
import { MainLoadingIndicatorComponent } from './main-loading-indicator/main-loading-indicator.component';
import { MainLoadingIndicatorService } from './main-loading-indicator/main-loading-indicator.service';
import { ConfirmDialogComponent } from './overlay/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from './overlay/confirm-dialog/confirm-dialog.service';
import { ObjectNotFoundPageComponent } from './object-not-found-page/object-not-found-page.component';
import { AccessRightGuard } from './guards/access-right.guard';
import { IdentityServerLoginGuard } from './guards/identity-server-login.guard';
import { NotificationItemComponent } from './overlay/notification/notification-item/notification-item.component';
import { LoginComponent } from './login/login.component';
import { OverlayPositionService } from './overlay/overlay-position.service';
import { GlobalSearchComponent } from './master-head/global-search/global-search.component';
import { InterruptNavigationGuard } from './guards/interrupt-navigation.guard';


@NgModule( {
    declarations: [
        BackdropComponent,
        DialogComponent,
        MainLoadingIndicatorComponent,
        MainMenuComponent,
        MainSlideInComponent,
        MasterHeadComponent,
        NotificationListComponent,
        NotificationItemComponent,
        PopupComponent,
        SystemInfoComponent,
        ConfirmDialogComponent,
        ObjectNotFoundPageComponent,
        LoginComponent,
        GlobalSearchComponent,
    ],
    exports:      [
        BackdropComponent,
        DialogComponent,
        MasterHeadComponent,
        MainLoadingIndicatorComponent,
        MainMenuComponent,
        MainSlideInComponent,
        NotificationListComponent,
        PopupComponent,
        SystemInfoComponent,
        ConfirmDialogComponent,
        ObjectNotFoundPageComponent,
        LoginComponent,
    ],
    imports:      [
        HttpClientModule,
        SharedModule,
    ],
    providers: [
        // singleton services
        AccessRightGuard,
        ApplicationTimeService,
        AuthenticationService,
        BackdropService,
        BootstrapService,
        ConfirmDialogService,
        DialogService,
        IdentityServerLoginGuard,
        MainMenuService,
        MainLoadingIndicatorService,
        MainSlideInService,
        NotificationService,
        PageTitleService,
        PopupService,
        RspUserGuard,
        StorageService,
        WebApiService,
        WindowEventService,
        CurrentUserService,
        UniqueHtmlIdService,
        OverlayPositionService,
        InterruptNavigationGuard,
    ],
} )
export class CoreModule {
    // https://angular.io/docs/ts/latest/guide/ngmodule.html 2017-01-10
    constructor( @Optional() @SkipSelf() parentModule: CoreModule ) {
        if ( parentModule ) {
            throw new Error( 'CoreModule is already loaded. Import it in the AppModule only' );
        }
    }
}
