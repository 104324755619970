<ng-container *ngIf="!isEditMode">
    <nur-date-time-range
        [datetimeFrom]="datetimeFrom"
        [datetimeTo]="datetimeUntil"
        [format]="format"
        [withWeekday]="withWeekday"
        [withTime]="withTime"
        [withSeconds]="withSeconds"
        [withTimezone]="withTimezone"
    ></nur-date-time-range>
</ng-container>
<rsp-edit-date-time-range
    *ngIf="isEditMode"
    [myFormGroup]="myFormGroup"
    [fromPlaceholder]="fromPlaceholder || null"
    [untilPlaceholder]="untilPlaceholder || null"
    [disablePastFromDates]="disablePastFromDates"
    [disablePastUntilDates]="disablePastUntilDates"
    [fromInputFieldHtmlId]="fromInputFieldHtmlId || null"
    [untilInputFieldHtmlId]="untilInputFieldHtmlId || null"
>
</rsp-edit-date-time-range>

