export interface ArticleProcurementCategory {
    id: string;
    name: string;
}

export const ARTICLE_PROCUREMENT_CATEGORIES: Array<ArticleProcurementCategory> = [
    { name: 'Fixtures & Support', id: 'Fixtures & Support' },
    { name: 'Mannequins', id: 'Mannequins' },
    { name: 'Hangers', id: 'Hangers' },
    { name: 'IT Retail Store Hardware', id: 'IT Retail Store Hardware' },
    { name: 'Shopping Bags', id: 'Shopping Bags' },
];
