import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SortingItem } from '../../model';
import { EnumConverterService } from '../../utils/enum/enum-converter.service';
import { BaseStoreService } from '../lists/store/base-store.service';
import { MainSlideInService } from '../../../core/overlay/main-slide-in/main-slide-in.service';
import { SecondaryToolbarService } from './secondary-toolbar.service';
import { Component } from '@angular/core';

@Component({
    template: '',
})
export abstract class ToolbarBaseComponent implements OnInit, OnDestroy {
    availableSorting: Observable<string[]>;
    activeSorting: SortingItem[];

    isViewSwitcherVisible: Observable<boolean>;

    isStoreInfoVisible: Observable<boolean>;

    isPrevNextNavigationVisible: Observable<boolean>;

    isSortByVisible: Observable<boolean>;

    sortingTitle: string;
    sortingLabel: string     = 'Sort by';
    sortingDirection: string = EnumConverterService.getHumanReadableEnumValue( SortingItem.SortingOrderEnum, SortingItem.SortingOrderEnum.Ascending );

    protected isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        protected storeService: BaseStoreService<any>,
        protected router: Router,
        protected activatedRoute: ActivatedRoute,
        protected mainSlideInService: MainSlideInService,
        protected toolbarService: SecondaryToolbarService,
    ) {
        this.storeService = storeService;
    }

    ngOnInit(): void {
        // component visibility
        this.isViewSwitcherVisible       = this.toolbarService.isViewSwitcherVisible$;
        this.isStoreInfoVisible          = this.toolbarService.isStoreInfoVisible$;
        this.isPrevNextNavigationVisible = this.toolbarService.isPrevNextNavigationVisible$;
        this.isSortByVisible             = this.toolbarService.isSortByVisible$;

        if ( this.storeService ) {
            this.availableSorting = this.storeService.availableSorting$;

            this.storeService
                .usedSorting$
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( sortingItems: SortingItem[] ) => {
                    this.activeSorting = sortingItems;

                    if ( sortingItems && sortingItems.length ) {
                        if ( sortingItems.length === 1 ) {
                            this.sortingLabel = sortingItems[ 0 ].name;
                        }
                        else {
                            const label: string = sortingItems.map( ( item: SortingItem ) => item.name ).join( ', ' );
                            this.sortingLabel   = label.length > 14 ? label.substring( 0, 14 ) + '...' : label;
                            this.sortingTitle   = label;
                        }
                        this.sortingDirection = EnumConverterService.getHumanReadableEnumValue( SortingItem.SortingOrderEnum, sortingItems[ 0 ].sortingOrder );
                    }
                    else {
                        this.sortingLabel     = 'Sort by';
                        this.sortingDirection =
                            EnumConverterService.getHumanReadableEnumValue( SortingItem.SortingOrderEnum, SortingItem.SortingOrderEnum.Ascending );
                    }
                } );
        }

    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    setSorting( sortBy: SortingItem[] ): void {
        const navigationExtras: NavigationExtras = {
            queryParams: {},
        };

        // merge all query params
        Object.assign( navigationExtras.queryParams, this.activatedRoute.snapshot.queryParams );

        if ( sortBy && sortBy.length ) {
            navigationExtras.queryParams[ 'sorting' ] = JSON.stringify( sortBy );
        }
        else {
            delete navigationExtras.queryParams[ 'sorting' ];
        }

        this.router.navigate( [], navigationExtras );

        this.mainSlideInService.closeSlideIn();
    }
}
