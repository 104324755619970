/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface FileKindItem {
    id?: string;

    name?: string;

    description?: string;

    areas?: Array<FileKindItem.AreasEnum>;

}

export namespace FileKindItem {
    'use strict';
    export enum AreasEnum {
        OrderDocuments,
        CompanyDocuments,
        LocationPriceListManagement,
        LocationInventory,
        Basket,
        Windows,
        User,
        Jobs,
        Extracts,
        ArticlesAndAssemblies,
        ArticleGraphics,
        ArticleStylesAndArtworks,
        ConceptAndClusters,
        PriceBaseLayout,
        Company,
        CompanyPriceListManagement,
        Documents,
        ProductionOrder,
        Imports,
        OrderErpDetails,
    }
    export namespace AreasEnum {
        export function toString( enumValue: AreasEnum ): string {
            return AreasEnum[ enumValue ];
        }
    }
}

