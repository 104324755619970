import { UntypedFormGroup, ValidationErrors, } from '@angular/forms';

export class DistributionChannelValidator {
    static distributionChannelRequiredValidator( formGroup: UntypedFormGroup ): ValidationErrors | null {
        if ( !formGroup.controls ) {
            return null;
        }

        const activeDistributionChannelCount: number =
            Object.keys( formGroup.controls ).map( ( distributionChannel: string ) => {
                return formGroup.get( distributionChannel ).get( 'isActive' ).value;
            } ).filter( ( isActive: boolean ) => {
                return isActive;
            } ).length;


        if ( activeDistributionChannelCount === 0 ) {
            return { distributionChannelRequired: true };
        }

        return null;

    }

}
