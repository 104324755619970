import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Configuration } from './configuration';

import { ArticleManagementArticlesService } from '../api/articleManagementArticles.service';
import { ArticleManagementAssembliesService } from '../api/articleManagementAssemblies.service';
import { ArticleManagementBrandLogosService } from '../api/articleManagementBrandLogos.service';
import { ArticleManagementCapacityTypesService } from '../api/articleManagementCapacityTypes.service';
import { ArticleManagementClustersService } from '../api/articleManagementClusters.service';
import { ArticleManagementConceptsService } from '../api/articleManagementConcepts.service';
import { ArticleManagementConceptsOrClustersService } from '../api/articleManagementConceptsOrClusters.service';
import { ArticleManagementGraphicsService } from '../api/articleManagementGraphics.service';
import { ArticleManagementMaterialsService } from '../api/articleManagementMaterials.service';
import { ArticleManagementNonTradeItemsService } from '../api/articleManagementNonTradeItems.service';
import { ArticleManagementServiceModelsService } from '../api/articleManagementServiceModels.service';
import { ArticleManagementSubComponentsService } from '../api/articleManagementSubComponents.service';
import { BatchProcessManagementBatchProcessesService } from '../api/batchProcessManagementBatchProcesses.service';
import { FileManagementDocumentsService } from '../api/fileManagementDocuments.service';
import { FileManagementFileKindsService } from '../api/fileManagementFileKinds.service';
import { FileManagementFilesService } from '../api/fileManagementFiles.service';
import { IndexAdvancedSearchFilterService } from '../api/indexAdvancedSearchFilter.service';
import { IndexGlobalsearchService } from '../api/indexGlobalsearch.service';
import { IndexManagementService } from '../api/indexManagement.service';
import { MasterDataBrandsService } from '../api/masterDataBrands.service';
import { MasterDataCulturesService } from '../api/masterDataCultures.service';
import { MasterDataCurrenciesService } from '../api/masterDataCurrencies.service';
import { MasterDataDistributionChannelStoreFormatsService } from '../api/masterDataDistributionChannelStoreFormats.service';
import { MasterDataDistributionChannelsService } from '../api/masterDataDistributionChannels.service';
import { MasterDataExperiencesService } from '../api/masterDataExperiences.service';
import { MasterDataKeywordGroupsService } from '../api/masterDataKeywordGroups.service';
import { MasterDataKeywordsService } from '../api/masterDataKeywords.service';
import { MasterDataPaymentTermsService } from '../api/masterDataPaymentTerms.service';
import { MasterDataProcurementCategoriesService } from '../api/masterDataProcurementCategories.service';
import { MasterDataRetailSegmentationsService } from '../api/masterDataRetailSegmentations.service';
import { MasterDataSPMService } from '../api/masterDataSPM.service';
import { MasterDataStoreFormatsService } from '../api/masterDataStoreFormats.service';
import { MasterDataStoreSubFormatsService } from '../api/masterDataStoreSubFormats.service';
import { ReportingExtractService } from '../api/reportingExtract.service';
import { SystemAdministrationService } from '../api/systemAdministration.service';
import { SystemLogsService } from '../api/systemLogs.service';
import { SystemNagiosService } from '../api/systemNagios.service';
import { SystemResponseTestsService } from '../api/systemResponseTests.service';
import { UserManagementAccessRightsService } from '../api/userManagementAccessRights.service';
import { UserManagementCurrentUserService } from '../api/userManagementCurrentUser.service';
import { UserManagementModuleGroupsService } from '../api/userManagementModuleGroups.service';
import { UserManagementModulesService } from '../api/userManagementModules.service';
import { UserManagementUsersService } from '../api/userManagementUsers.service';

@NgModule({
    imports:      [ CommonModule, HttpClientModule ],
    declarations: [],
    exports:      [],
    providers: [
        ArticleManagementArticlesService,
        ArticleManagementAssembliesService,
        ArticleManagementBrandLogosService,
        ArticleManagementCapacityTypesService,
        ArticleManagementClustersService,
        ArticleManagementConceptsService,
        ArticleManagementConceptsOrClustersService,
        ArticleManagementGraphicsService,
        ArticleManagementMaterialsService,
        ArticleManagementNonTradeItemsService,
        ArticleManagementServiceModelsService,
        ArticleManagementSubComponentsService,
        BatchProcessManagementBatchProcessesService,
        FileManagementDocumentsService,
        FileManagementFileKindsService,
        FileManagementFilesService,
        IndexAdvancedSearchFilterService,
        IndexGlobalsearchService,
        IndexManagementService,
        MasterDataBrandsService,
        MasterDataCulturesService,
        MasterDataCurrenciesService,
        MasterDataDistributionChannelStoreFormatsService,
        MasterDataDistributionChannelsService,
        MasterDataExperiencesService,
        MasterDataKeywordGroupsService,
        MasterDataKeywordsService,
        MasterDataPaymentTermsService,
        MasterDataProcurementCategoriesService,
        MasterDataRetailSegmentationsService,
        MasterDataSPMService,
        MasterDataStoreFormatsService,
        MasterDataStoreSubFormatsService,
        ReportingExtractService,
        SystemAdministrationService,
        SystemLogsService,
        SystemNagiosService,
        SystemResponseTestsService,
        UserManagementAccessRightsService,
        UserManagementCurrentUserService,
        UserManagementModuleGroupsService,
        UserManagementModulesService,
        UserManagementUsersService,
        ],
})
export class ApiModule {
    constructor( @Optional() @SkipSelf() parentModule: ApiModule) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import your base AppModule only.');
        }
    }

    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ],
        };
    }
}
