<ng-template #headline>
    <h2 class="standard-dialog--headline">Set Status for selected Assemblies</h2>
</ng-template>

<ng-template #content>
    <div class="standard-dialog--content">
        <rsp-non-trade-item-selection-list [nonTradeItemType]="assemblyType"></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #footer>
    <div class="standard-dialog--button">
        <rsp-dropdown
            [placeholder]="'Select Status'"
            [myFormControl]="form.get( 'status' )"
            (selected)="setStatus( $event )"
        >
            <rsp-dropdown-item [item]="'Publish'">
                Publish
            </rsp-dropdown-item>
            <rsp-dropdown-item [item]="'Unpublish'">
                Unpublish
            </rsp-dropdown-item>
        </rsp-dropdown>

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            (clicked)="doAction()"
            [disabled]="!form.valid || isProcessing"
            style="margin-left: 1em"
        >
            Apply
        </rsp-button>
    </div>
</ng-template>
