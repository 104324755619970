import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { FacetItem } from '../../../model/facetItem';
import { StoreInfo } from './store-info.model';
import { PagingInfo } from '../../../model/pagingInfo';
import { FacetFilterItem } from '../../../model/facetFilterItem';
import { AdvancedSearchFilterGroup } from '../../../model/advancedSearchFilterGroup';
import { AdvancedSearchFilterTypeOperator } from '../../../model/advancedSearchFilterTypeOperator';
import { AdvancedSearchFilterProperty } from '../../../model/advancedSearchFilterProperty';
import { SelectListItem, SortingItem } from '../../../model';
import { BrowsingConfiguration } from '../../../model/browsingConfiguration';
import * as moment from 'moment-timezone';

import { isEqual } from 'lodash';

// TODO: rename "store" to "storage"

export type StoreSearchConfiguration = BrowsingConfiguration;


/**
 * Base class for all store services.
 *
 * @example
 *
 *      class MyObject { id: string, ... }
 *
 *      class MyObjectStoreService extends BaseStoreService<MyObject> {
 *
 *          protected getId( item: MyObject ) {
 *              return item.id;
 *          }
 *
 *          protected loadItemsFromBackend( request: FacetSearchConfiguration ): void {
 *
 *              this.myObjectApi
 *                  .getMyObjects( request )
 *                  .subscribe( (result: MyObjectsFacetedPagedList ) => {
 *                      super.setBackendResult( result.data, result.paging, result.facets );
 *                  } );
 *          }
 *      }
 */
export abstract class BaseStoreService<T> {

    /**
     * Observable with all non-trade-items.
     */
    readonly items$: Observable<Array<T>>;

    /**
     * Observable with current store infos.
     */
    readonly storeInfo$: Observable<StoreInfo>;

    /**
     * Observable with available sorting info.
     */
    readonly availableSorting$: Observable<string[]>;

    /**
     * Observable with currently used sorting info.
     */
    readonly usedSorting$: Observable<SortingItem[]>;

    /**
     * Observable with currently used quick search
     */
    readonly usedQuickSearchTerm$: Observable<string>;

    /**
     * Observable with current facets.
     */
    readonly facets$: Observable<Array<FacetItem>>;

    /**
     * Observable with current select lists.
     */
    readonly selectLists$: Observable<Array<SelectListItem>>;

    /**
     * Observable with the special facet type _ChosenValues_.
     */
    readonly chosenValuesFacet$: Observable<Array<FacetItem>>;

    /**
     * Observable with TODO
     */
    readonly advancedSearchFilterTypeOperators$: Observable<Array<AdvancedSearchFilterTypeOperator>>;

    /**
     * Observable with TODO
     */
    readonly advancedSearchFilterProperties$: Observable<Array<AdvancedSearchFilterProperty>>;

    /**
     * Observable with TODO
     */
    readonly advancedSearchFilterGroups$: Observable<Array<AdvancedSearchFilterGroup>>;

    /**
     * Observable with TODO
     */
    readonly facetFilters$: Observable<Array<FacetFilterItem>>;

    /**
     * Observable with loading status. Value is true when backend http call is running.
     */
    readonly loading$: Observable<boolean>;

    readonly unregisterLoading$: Observable<boolean>;

    readonly sortByChanged$: Observable<boolean>;

    private items: BehaviorSubject<Array<T>>;
    private storeInfo: BehaviorSubject<StoreInfo>;
    private availableSorting: BehaviorSubject<string[]>;
    private usedSorting: BehaviorSubject<SortingItem[]>;
    private usedQuickSearchTerm: BehaviorSubject<string>;
    private facets: BehaviorSubject<Array<FacetItem>>;
    private chosenValuesFacet: BehaviorSubject<Array<FacetItem>>;
    private selectLists: BehaviorSubject<Array<SelectListItem>>;
    private advancedSearchFilterTypeOperators: BehaviorSubject<AdvancedSearchFilterTypeOperator[]>;
    private advancedSearchFilterProperties: BehaviorSubject<AdvancedSearchFilterProperty[]>;
    private advancedSearchFilterGroups: BehaviorSubject<AdvancedSearchFilterGroup[]>;
    private facetFilters: BehaviorSubject<FacetFilterItem[]>;
    private sortByChanged: BehaviorSubject<boolean>;
    private loading: BehaviorSubject<boolean>;
    private unregisterLoading: BehaviorSubject<boolean>;
    private page: number     = 0;
    private pageSize: number = 50;
    private facetFilterItems: FacetFilterItem[];
    private sorting: SortingItem[];
    private advancedSearchFilterGroupsToSend: AdvancedSearchFilterGroup[];
    private quickSearchTerm: string;
    private timeZone: string;
    private loadItemsSubscription: Subscription;

    private timeZoneRegexp: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?([+-]\d{2}:\d{2})$/;

    constructor() {

        this.items  = new BehaviorSubject<Array<T>>( [] );
        this.items$ = this.items.asObservable();

        this.storeInfo  = new BehaviorSubject<StoreInfo>( null );
        this.storeInfo$ = this.storeInfo.asObservable();

        this.availableSorting  = new BehaviorSubject<string[]>( [] );
        this.availableSorting$ = this.availableSorting.asObservable();

        this.usedSorting  = new BehaviorSubject<SortingItem[]>( [] );
        this.usedSorting$ = this.usedSorting.asObservable();

        this.facets  = new BehaviorSubject<Array<FacetItem>>( null );
        this.facets$ = this.facets.asObservable();

        this.chosenValuesFacet  = new BehaviorSubject<Array<FacetItem>>( null );
        this.chosenValuesFacet$ = this.chosenValuesFacet.asObservable();

        this.facetFilters  = new BehaviorSubject<Array<FacetFilterItem>>( null );
        this.facetFilters$ = this.facetFilters.asObservable();

        this.selectLists  = new BehaviorSubject( null );
        this.selectLists$ = this.selectLists.asObservable();

        this.advancedSearchFilterTypeOperators  = new BehaviorSubject<AdvancedSearchFilterTypeOperator[]>( null );
        this.advancedSearchFilterTypeOperators$ = this.advancedSearchFilterTypeOperators.asObservable();

        this.advancedSearchFilterProperties  = new BehaviorSubject<AdvancedSearchFilterProperty[]>( null );
        this.advancedSearchFilterProperties$ = this.advancedSearchFilterProperties.asObservable();

        this.advancedSearchFilterGroups  = new BehaviorSubject<AdvancedSearchFilterGroup[]>( null );
        this.advancedSearchFilterGroups$ = this.advancedSearchFilterGroups.asObservable();

        this.sortByChanged  = new BehaviorSubject<boolean>( false );
        this.sortByChanged$ = this.sortByChanged.asObservable();

        this.loading  = new BehaviorSubject<boolean>( false );
        this.loading$ = this.loading.asObservable();

        this.unregisterLoading  = new BehaviorSubject<boolean>( false );
        this.unregisterLoading$ = this.unregisterLoading.asObservable();

        this.usedQuickSearchTerm  = new BehaviorSubject<string>( '' );
        this.usedQuickSearchTerm$ = this.usedQuickSearchTerm.asObservable();

        this.timeZone = moment.tz.guess();
    }


    /**
     * Clears all data from the store.
     */
    clear(): void {
        this.items.next( [] );
        this.storeInfo.next( null );
        this.availableSorting.next( [] );
        this.usedSorting.next( [] );
        this.facets.next( null );
        this.chosenValuesFacet.next( null );
        this.facetFilters.next( null );
        this.advancedSearchFilterTypeOperators.next( null );
        this.advancedSearchFilterProperties.next( null );
        this.advancedSearchFilterGroups.next( null );
    }

    /**
     * Clears only items.
     */
    clearItems(): void {
        this.items.next( [] );
    }

    /**
     * Returns true when there are any items loaded.
     */
    hasItems(): boolean {
        return this.items.getValue().length !== 0;
    }


    /**
     * Returns count of cached items.
     */
    itemsCount(): number {
        return this.items.getValue().length;
    }

    /**
     * Returns total count of items.
     */
    totalCount(): number {
        return this.storeInfo.getValue().totalCount;
    }

    /**
     * Sets the page to 0 (like a reset) and calls loadNextPage() to get the first page.
     *
     * @param {boolean} ignoreRunningRequests See same param for `loadNextPage()`.
     */
    loadFirstPage( ignoreRunningRequests: boolean = false ): void {
        this.page     = 0;
        this.pageSize = 50;
        this.loadNextPage( ignoreRunningRequests );
    }


    /**
     * Gets next page from the backend.
     *
     * @param {boolean} ignoreRunningRequests When set to `false` no new request is triggered, when another one is still running. `true` "cancels" running
     * requests and creates a new one.
     */
    loadNextPage( ignoreRunningRequests: boolean = false ): void {

        if ( ( !ignoreRunningRequests && this.loading.getValue() ) || !this.hasNextPage() ) {
            // currently loading or nothing to load
            return;
        }

        const request: BrowsingConfiguration = {
            page:                       ++this.page,
            size:                       this.pageSize,
            filters:                    this.facetFilterItems,
            selectLists:                this.selectLists.value,
            advancedSearchFilterGroups: this.advancedSearchFilterGroupsToSend,
            sortings:                   this.sorting && this.sorting.length ? this.sorting : undefined,
            quickSearchTerm:            this.quickSearchTerm,
            timeZone:                   this.timeZone,
        };

        this.notifyObserversStartLoading();

        if ( this.loadItemsSubscription ) {
            this.loadItemsSubscription.unsubscribe();
        }

        this.loadItemsSubscription = this.loadItemsFromBackend( request );
    }


    /**
     * Sets facet filter value which will be used with next loadNextPage() call.
     */
    setFacetFilterValues( filterItems: FacetFilterItem[] ): BaseStoreService<T> {
        // console.log( '[BaseStoreService] settings facet filter values', filterItems );

        this.facetFilterItems = filterItems;
        this.facetFilters.next( this.facetFilterItems );
        return this;
    }

    setSelectListsValues( selectLists: SelectListItem[] ): BaseStoreService<T> {
        this.selectLists.next( selectLists );

        return this;
    }

    setSortBy( sorting: SortingItem[] ): BaseStoreService<T> {
        let changed: boolean = false;

        if ( sorting && sorting.length ) {
            if ( !this.sorting || !isEqual( this.sorting, sorting ) ) {
                changed = true;
            }

            this.sorting = sorting;
        }
        else {
            if ( this.sorting ) {
                changed = true;
            }

            this.sorting = null;
        }

        if ( changed ) {
            this.sortByChanged.next( true );
        }

        return this;
    }

    setQuickSearch( searchTerm: string ): BaseStoreService<T> {
        this.quickSearchTerm = searchTerm;

        return this;
    }

    getQuickSearch(): string {
        return this.quickSearchTerm;
    }

    getSelectLists(): SelectListItem[] {
        return this.selectLists.getValue() || [];
    }

    /**
     * Sets advanced search value which will be used with next loadNextPage() call.
     */
    setAdvancedSearchValues( advancedSearchFilterGroups: AdvancedSearchFilterGroup[] ): BaseStoreService<T> {
        this.advancedSearchFilterGroupsToSend = advancedSearchFilterGroups;
        return this;
    }


    /**
     * Gets array with items from the range between item1 and item2. Array contains also item1 and item2.
     * If store does not contain either item1 or item2, empty list is returned.
     */
    getItemsRange( item1: T, item2: T ): Array<T> {

        // search item1
        const index1: number
                  = this.items.getValue().findIndex( ( item: T ) => this.getId( item ) === this.getId( item1 ) );

        if ( index1 === -1 ) {
            return [];
        }

        // search item2
        const index2: number
                  = this.items.getValue().findIndex( ( item: T ) => this.getId( item ) === this.getId( item2 ) );

        if ( index2 === -1 ) {
            return [];
        }

        if ( index1 < index2 ) {
            return this.items.getValue().slice( index1, index2 + 1 );
        }

        return this.items.getValue().slice( index2, index1 + 1 );
    }


    /**
     * Returns the index of the item in the store-array or -1 when not found.
     */
    getItemIndex( item: T ): number {
        return this.items.getValue().findIndex( ( storeItem: T ) => this.getId( storeItem ) === this.getId( item ) );
    }


    /**
     * Returns item for given index.
     */
    getItemByIndex( index: number ): T {

        if ( index < this.items.getValue().length ) {
            return this.items.getValue()[ index ];
        }

        return null;
    }

    /**
     * Return current value of facet filters
     */
    getFacetFilters(): FacetFilterItem[] {
        return this.facetFilters.getValue() || [];
    }

    /**
     * Return current value of advanced search filter groups
     */
    getAdvancedSearchFilterGroups(): AdvancedSearchFilterGroup[] {
        return this.advancedSearchFilterGroups.getValue() || [];
    }

    /**
     * Inheriting class must tell what is the item's ID.
     */
    protected abstract getId( item: T ): string;

    /**
     * Inheriting class is responsible for loading items from backend. Loaded data must be set with setBackendResult().
     */
    protected abstract loadItemsFromBackend( request: StoreSearchConfiguration ): Subscription;

    /**
     * In case of an error in the webapi call, unregister the loading observable (to hide main loading indicator)
     */
    protected unregisterLoadingObservable(): void {
        this.unregisterLoading.next( true );
    }

    /**
     * Sets data loaded from backend. Triggers all observables to notify subscribers.
     */
    protected setBackendResult(
        items: Array<T>,
        pagingInfo: PagingInfo,
        facets: Array<FacetItem>,
        selectLists: Array<SelectListItem>,
        advancedSearchFilterTypeOperators?: AdvancedSearchFilterTypeOperator[],
        advancedSearchFilterProperties?: AdvancedSearchFilterProperty[],
        advancedSearchFilterGroups?: AdvancedSearchFilterGroup[],
        availableSortings?: string[],
        usedSortings?: SortingItem[],
        quickSearchTerm?: string,
    ): void {

        // notify observers about new values

        // update items
        if ( this.page === 1 ) {
            this.items.next( items );
        }
        else {
            this.items.next( this.items.getValue().concat( items ) );
        }

        // storage info
        this.storeInfo.next(
            new StoreInfo(
                this.items.getValue().length,
                pagingInfo.totalCount,
                pagingInfo.hasNextPage,
            ),
        );

        // sorting
        this.availableSorting.next( availableSortings );
        this.usedSorting.next( usedSortings );

        // facets
        this.facets.next( facets );
        this.chosenValuesFacet.next( facets );
        this.selectLists.next( selectLists );

        // quick search
        this.usedQuickSearchTerm.next( quickSearchTerm );

        // advanced search
        this.advancedSearchFilterTypeOperators.next( advancedSearchFilterTypeOperators );
        this.advancedSearchFilterProperties.next( advancedSearchFilterProperties );
        this.advancedSearchFilterGroups.next( advancedSearchFilterGroups );

        this.notifyObserversEndLoading();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private hasNextPage(): boolean {

        const storageInfo: StoreInfo = this.storeInfo.getValue();

        if ( storageInfo === null || this.page === 0 ) {
            // no storage info yet, probably there is next page
            return true;
        }

        return storageInfo.hasNextPage;
    }

    private notifyObserversStartLoading(): void {
        this.loading.next( true );
    }

    private notifyObserversEndLoading(): void {
        this.loading.next( false );
    }
}
