import { Injectable } from '@angular/core';
import { ServiceModelItem, UpdateConceptServiceModels } from '../../../../../../shared/model';
import { ConceptServiceModelItem } from '../../../../../../shared/model';
import { ServiceLevelItem as ServiceLevelItemApi } from '../../../../../../shared/model';
import { ConceptServiceModelAssignmentItem, LevelGroupOptionItem } from './concept-service-model-assignment-item.model';
import { ServiceLevelItem } from '../../../../../../shared/ui/service-models-table/service-level-item';


@Injectable()
export class EditConceptServiceModelsAssignmentService {


    createRowItems( serviceModels: Array<ServiceModelItem>, assignedServiceModels: Array<ConceptServiceModelItem> ): Array<ConceptServiceModelAssignmentItem> {

        const result: Array<ConceptServiceModelAssignmentItem> = [];

        serviceModels.forEach( ( serviceModelItem: ServiceModelItem ) => {

            if ( this.isAnyLevelGroupEnabled( serviceModelItem ) ) {

                result.push(
                    new ConceptServiceModelAssignmentItem(
                        serviceModelItem.id,
                        serviceModelItem.name,
                        this.getAssignedLevelGroup( serviceModelItem, assignedServiceModels ),
                        this.getServiceLevelGroupOptions( serviceModelItem ),
                        this.getServiceLevelItems( serviceModelItem ),
                    ),
                );
            }
        } );


        return result;
    }


    convertToSubmitData( items: Array<ConceptServiceModelAssignmentItem>, conceptId: string ): UpdateConceptServiceModels {
        return {
            conceptId:     conceptId,
            serviceModels: items.filter( ( item: ConceptServiceModelAssignmentItem ) => item.assignedLevelGroupValue !== null )
                                .map( ( item: ConceptServiceModelAssignmentItem ) => {
                                    return {
                                        serviceModelId:     item.serviceModelId,
                                        assignedLevelGroup: item.assignedLevelGroupValue,
                                    };
                                } ),
        };
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private getAssignedLevelGroup( serviceModelItem: ServiceModelItem, assignedServiceModels: Array<ConceptServiceModelItem> ): number {

        const foundItem: ConceptServiceModelItem =
                  assignedServiceModels.find( ( item: ConceptServiceModelItem ) => item.serviceModelId === serviceModelItem.id );

        if ( foundItem ) {
            return foundItem.assignedLevelGroup;
        }

        return null;
    }

    private getServiceLevelGroupOptions( serviceModelItem: ServiceModelItem ): Array<LevelGroupOptionItem> {

        const result: Array<LevelGroupOptionItem> = [];

        result.push( { id: null, name: 'Select Level' } );

        if ( serviceModelItem.levelGroup1IsAvailable ) {
            result.push( { id: 1, name: 'Level 1' } );
        }

        if ( serviceModelItem.levelGroup2IsAvailable ) {
            result.push( { id: 2, name: 'Level 2' } );
        }

        if ( serviceModelItem.levelGroup3IsAvailable ) {
            result.push( { id: 3, name: 'Level 3' } );
        }

        if ( serviceModelItem.levelGroup4IsAvailable ) {
            result.push( { id: 4, name: 'Level 4' } );
        }

        if ( serviceModelItem.levelGroup5IsAvailable ) {
            result.push( { id: 5, name: 'Level 5' } );
        }

        if ( serviceModelItem.levelGroup6IsAvailable ) {
            result.push( { id: 6, name: 'Level 6' } );
        }

        return result;
    }

    private isAnyLevelGroupEnabled( serviceModelItem: ServiceModelItem ): boolean {

        return serviceModelItem.levelGroup1IsAvailable
            || serviceModelItem.levelGroup2IsAvailable
            || serviceModelItem.levelGroup3IsAvailable
            || serviceModelItem.levelGroup4IsAvailable
            || serviceModelItem.levelGroup5IsAvailable
            || serviceModelItem.levelGroup6IsAvailable;
    }

    private getServiceLevelItems( serviceModelItem: ServiceModelItem ): Array<ServiceLevelItem> {
        return serviceModelItem.serviceLevels
                               .map( ( item: ServiceLevelItemApi ) => ServiceLevelItem.createFromApiServiceLevelItem( item ) );
    }
}
