import { Component } from '@angular/core';
import { Router } from '@angular/router';

/* tslint:disable:max-line-length */
import { DetailPagePrevNextNavigationComponent } from '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component';
import { UserListItem } from '../../../../../shared/model';
import { UserPrevNextNavigationService } from './user-prev-next-navigation.service';
/* tslint:enable:max-line-length */

@Component( {
    selector:    'rsp-user-prev-next-navigation',
    templateUrl: '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component.html',
    styleUrls:   [ '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component.scss', ],
} )
export class UserPrevNextNavigationComponent extends DetailPagePrevNextNavigationComponent<UserListItem> {
    constructor(
        router: Router,
        userPrevNextNavigationService: UserPrevNextNavigationService,
    ) {
        super( router, userPrevNextNavigationService );
    }
}
