<div class="tab">

    <div *ngIf="userHasEditRight" class="tab--toolbar">
        <rsp-button
            [submodule]="'primary'"
            class="tab--toolbar-item is-right"
            (clicked)="onEditClick()"
        >Edit</rsp-button>
    </div>

    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <rsp-no-content *ngIf="isLoading.closed && serviceModels.length === 0">
            This concept does not hold any service levels.
        </rsp-no-content>

        <rsp-service-models-table [items]="serviceModels">
        </rsp-service-models-table>
    </div>

</div>

<router-outlet></router-outlet>
