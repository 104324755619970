import { Injectable, } from '@angular/core';
import { Params, Router, UrlTree, } from '@angular/router';

import { isEmpty } from 'lodash';


@Injectable()
export class QueryParamService {
    protected routingMap: { [key: string]: Params } = {};

    constructor( private router: Router ) {
    }

    public addUrl( value: string ): void {
        const tree: UrlTree = this.router.parseUrl( value );

        if ( !isEmpty( tree.queryParams ) ) {
            this.routingMap[ value.split( '?' )[ 0 ] ] = tree.queryParams;
        }
    }

    public getQueryParamsFor( url: string ): Params {
        return this.routingMap[ url ];
    }
}
