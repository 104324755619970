import { Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from '../../../../../core/overlay/dialog/dialog.service';
import { FileContainerWithReference } from '../../../../../shared/model';

@Component( {
    selector:    'rsp-file-exports-dialog',
    templateUrl: './file-exports-dialog.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/standard-dialog.scss',
        './file-exports-dialog.component.scss',
    ],
} )
export class FileExportsDialogComponent {
    @ViewChild( 'dialogHeaderTemplate', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContentTemplate', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooterTemplate', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    @Input() type: string;
    @Input() files: FileContainerWithReference[] = [];

    constructor(
        private dialogService: DialogService,
    ) {
    }

    open(): void {
        this.dialogService.openDialog( {
            headlineTemplate: this.dialogHeaderTemplate,
            contentTemplate:  this.dialogContentTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }
}
