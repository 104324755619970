<!-- Subtemplate which generates keywords (and walks through this.facets recursively): -->
<ng-template #facetTemplateRef let-facet="facet">
    <ng-container *ngFor="let facetValue of facet.values">
        <ng-container *ngIf="facetValue.facets.length > 0">
            <ng-template
                *ngFor="let nestedFacet of facetValue.facets"
                [ngTemplateOutlet]="facetTemplateRef"
                [ngTemplateOutletContext]="{ facet: nestedFacet }"
            ></ng-template>
        </ng-container>

        <ng-container *ngIf="facetValue.facets.length === 0">
            <span
                rspKeyword
                [withClearButton]="true"
                (clearClick)="removeFacetValue( facetValue, facet )"
            >
                {{ facetValue.value | camelCaseToSpace }}
            </span>
        </ng-container>
    </ng-container>
</ng-template>

<div
    class="chosen-values"
    [class.is-expanded]="isExpanded"
    [class.is-expandable]="areSomeKeywordsHidden"
>
    <div
        [@keywordsExpand]="isExpanded"
        class="chosen-values--values"
    >
        <span
            *ngIf="areAdvancedSearchFiltersSet"
            rspKeyword
            [withClearButton]="true"
            [submodule]="'bold'"
            (clearClick)="removeAdvancedSearchFilters()"
        >
            Advanced Search
        </span>

        <span
            *ngIf="usedQuickSearchTerm"
            rspKeyword
            [withClearButton]="true"
            [submodule]="'bold'"
            (clearClick)="removeQuickSearch()"
        >
            Quick Search: {{ usedQuickSearchTerm }}
        </span>

        <ng-container *ngIf="facets">
            <ng-template
                *ngFor="let facet of facets"
                [ngTemplateOutlet]="facetTemplateRef"
                [ngTemplateOutletContext]="{ facet: facet }"
            ></ng-template>
        </ng-container>
    </div>

    <div
        *ngIf="areSomeKeywordsHidden"
        class="chosen-values--show-more-area"
    >
        <rsp-badge-counter
            [content]="numberOfKeywords"
            class="chosen-values--show-more-counter"
            title="{{ numberOfKeywords }} filters chosen"
        ></rsp-badge-counter>

        <rsp-button
            [type]="'button'"
            [submodule]="'seamless'"
            (click)="toggleExpand()"
            [@togglerRotated]="isExpanded"
            class="chosen-values--show-more-button"
        >
            Show more
        </rsp-button>
    </div>
</div>
