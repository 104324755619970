import { Component, OnDestroy, TemplateRef, ViewChild, } from '@angular/core';
import { Observable, Subscription, } from 'rxjs';
import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';

import { ClusterSuggestItem, } from '../../../../../../../shared/model/clusterSuggestItem';
import { Reply } from '../../../../../../../shared/model/reply';
import { PluralService, } from '../../../../../../../shared/utils/string/plural.service';
import { NotificationService, } from '../../../../../../../core/overlay/notification/notification.service';
import { EntityTypeEnum } from '../../../../../../../shared/model/enum/entity-type.enum';
import { AddArticlesAndAssembliesToClusters } from '../../../../../../../shared/model/addArticlesAndAssembliesToClusters';
import { NonTradeItemSelectionService } from '../../../../shared/non-trade-item-selection.service';
import { NonTradeListItem } from '../../../../../../../shared/model/nonTradeListItem';
import { ArticleManagementClustersService } from '../../../../../../../shared/api/index';

import { remove } from 'lodash';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component( {
    selector:    'rsp-add-or-remove-cluster-dialog',
    templateUrl: './add-or-remove-cluster-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        './add-or-remove-cluster-dialog.component.scss',
    ],
} )
export class AddOrRemoveClusterDialogComponent implements OnDestroy {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    currentList: Array<ClusterSuggestItem> = [];

    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.Cluster;

    filterList: Array<string> = [];

    isLoading: Subscription;
    form: UntypedFormGroup;

    removeItems: boolean;
    caption: string;

    constructor(
        private clustersApi: ArticleManagementClustersService,
        private notificationService: NotificationService,
        private pluralService: PluralService,
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private dialogService: DialogService,
    ) {
    }

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    addToList( item: ClusterSuggestItem ): void {
        this.currentList.push( item );
        this.form.get( 'clusterIds' ).setValue( this.currentList.map( ( cluster: ClusterSuggestItem ) => {
            return cluster.id;
        } ) );
        this.updateFilter();
    }

    removeFromList( item: ClusterSuggestItem ): void {
        remove( this.currentList, ( current: ClusterSuggestItem ) => {
            return item.id === current.id;
        } );
        this.form.get( 'clusterIds' ).setValue( this.currentList.map( ( cluster: ClusterSuggestItem ) => {
            return cluster.id;
        } ) );
        this.updateFilter();
    }

    doAction(): void {

        const selectedArticles: Array<NonTradeListItem>   = this.nonTradeItemSelectionService.getSelectedArticles();
        const selectedAssemblies: Array<NonTradeListItem> = this.nonTradeItemSelectionService.getSelectedAssemblies();

        if ( !this.currentList.length || (!selectedArticles.length && !selectedAssemblies.length) ) {
            this.notificationService.warning( 'Nothing was done' );
            this.dialogService.closeDialog();
            return;
        }

        const articleIds: Array<string> = selectedArticles.map( ( item: NonTradeListItem ) => item.id );

        const assemblyIds: Array<string> = selectedAssemblies.map( ( item: NonTradeListItem ) => item.id );

        const data: AddArticlesAndAssembliesToClusters = {
            clusterIds:  this.form.get( 'clusterIds' ).value,
            articleIds:  articleIds,
            assemblyIds: assemblyIds,
            reason: this.form.get( 'reason' ).value,
        };

        let request: Observable<Reply>;

        if ( !this.removeItems ) {
            request = this.clustersApi.clustersAddArticlesAndAssembliesToClusters( data );
        }
        else {
            request = this.clustersApi.clustersRemoveArticlesAndAssembliesFromClusters( data );
        }

        this.isLoading = request.subscribe( () => {
            const message: string = this.buildMsg( articleIds.length, assemblyIds.length );
            this.notificationService.success( message );
            this.dialogService.closeDialog();
        } );
    }

    open( removeItems: boolean = false ): void {
        this.currentList = [];
        this.updateFilter();

        this.form = new UntypedFormGroup( {
            clusterIds: new UntypedFormControl( null, Validators.required ),
            reason: new UntypedFormControl( '' ),
        } );

        this.removeItems = removeItems;
        this.caption     = this.removeItems ? 'Remove selection from Cluster' : 'Add selection to Cluster';
        this.dialogService.openDialog( {
            contentTemplate:  this.contentTemplate,
            headlineTemplate: this.headlineTemplate,
            footerTemplate:   this.footerTemplate,
            withBackdrop:     true,
        } );
    }

    getActionButtonLabel(): string {
        return this.removeItems ? 'Remove' : 'Add';
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private updateFilter(): void {
        this.filterList = this.currentList.map( ( item: ClusterSuggestItem ) => {
            return item.id;
        } );
    }

    private buildMsg( articleCount: number, assemblyCount: number ): string {
        const message: Array<string> = [];

        if ( articleCount ) {
            message.push( this.pluralService.pluralize( articleCount, 'article' ) );
        }
        if ( assemblyCount ) {
            if ( articleCount ) {
                message.push( 'and' );
            }
            message.push( this.pluralService.pluralize( assemblyCount, 'assembly' ) );
        }
        message.push( (assemblyCount + articleCount) > 1 ? 'have been' : 'has been' );
        message.push( this.removeItems ? 'removed from' : 'added to' );
        message.push( this.pluralService.pluralize( this.filterList.length, 'cluster' ) );

        return message.join( ' ' ) + '.';
    }
}
