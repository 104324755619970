import { Component, Input, OnInit, } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { FormatNumberService } from '../../../utils/number/format-number.service';
import { NOT_AVAILABLE } from '../../../ui/not-available/not-available.component';


/**
 * Displays number as label (display mode) and as input field (edit mode).
 * In edit mode, the result is added to given FormGroup as property with given name.
 *
 * @example
 *      <rsp-display-edit-number
 *          [myFormGroup]="myForm"
 *          [name]="myName"
 *          [size]="10"
 *          ...
 *      ></<rsp-display-edit-number>
 *
 *  `myForm.value` contains
 *
 *      {
 *          ...
 *          myName: ...,
 *      }
 *
 */
@Component( {
    selector:    'rsp-display-edit-number',
    templateUrl: './display-edit-number.component.html',
    styleUrls:   [
        './display-edit-number.component.scss',
    ],
} )
export class DisplayEditNumberComponent {

    // TODO: this component is similar to DisplayEditTextComponent. Maybe we should create a common
    // base abstract component -> DisplayEditInputComponent? It will be easier to do it,
    // after ViewModelProperties are dropped.

    @Input()
    set value( value: number | string ) { this.setValue( value ); }
    get value(): number | string { return this._value; }

    @Input() myFormGroup: UntypedFormGroup;
    @Input() isEditMode: boolean;
    @Input() name: string;
    @Input() hideValidationMessages: boolean;

    /**
     * Specify a size (in number of chars) for the input field. If not given, the size will be calculated (and updated) by the current value.
     */
    @Input() size: number;

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;
    /**
     * Specifies if "n/a" should be displayed when provided value is not empty (null|undefined) in the display mode.
     * @type {boolean}
     */
    @Input() showNotAvailable: boolean = true;
    /**
     * Specifies string which is displayed when given value is empty and [showNotAvailable] is true. Default 'n / a'.
     * @type {string}
     */
    @Input() notAvailableString: string = NOT_AVAILABLE;

    /**
     * Optional text for the HTML `placeholder` attribute.
     */
    @Input() placeholder: string;

    private _value: number | string;

    constructor(
        private formatNumberService: FormatNumberService,
    ) { }

    static buildFormControl( value: number, validators?: Array<ValidatorFn> ): UntypedFormControl {
        return new UntypedFormControl( value, validators );
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setValue( value: number | string ): void {
        if ( value && value.toString().indexOf( 'e-' ) >= 0 ) {
            this._value = this.formatNumberService.convertExponentialToFixed( parseFloat( value + '' ) );
        }
        else {
            this._value = value;
        }
    }

}
