import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ConceptClusterStoreService } from '../shared/concept-cluster-store.service';
import { StorageService, STORAGE_KEYS } from '../../../../core/storage.service';
import { MainLoadingIndicatorService } from '../../../../core/main-loading-indicator/main-loading-indicator.service';
import { ViewSwitcherService } from '../../../../shared/ui/secondary-toolbar/view-switcher/view-switcher.service';
import { ConceptClusterSelectionService } from '../shared/concept-cluster-selection.service';
import { BaseListPageWithAlternativeViewsComponent } from '../../../../shared/ui/lists/base-list-page-with-alternative-views.component';
import { BaseStoreService } from '../../../../shared/ui/lists/store/base-store.service';
import { BaseSelectionService } from '../../../../shared/ui/lists/base-selection.service';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { ViewModelBrowsingListConceptOrClusterItem } from '../../../../shared/model';


@Component( {
    selector:    'rsp-concept-cluster-list-page',
    templateUrl: './concept-cluster-list-page.component.html',
    styleUrls:   [
        './concept-cluster-list-page.component.scss',
    ],
} )
export class ConceptListPageComponent
    extends BaseListPageWithAlternativeViewsComponent<ViewModelBrowsingListConceptOrClusterItem>
    implements OnInit {

    get storageKeyIsSidebarVisible(): string { return STORAGE_KEYS.concepts.list.isSidebarVisible; }

    get storageKeyScrollToId(): string { return STORAGE_KEYS.concepts.list.scrollToId; }


    // TODO: Remove concept-prefix of member variables so one could more easily compare let's say non-trade-item-list-page vs. concept-cluster-list-page.
    constructor(
        private toolbarService: SecondaryToolbarService,
        router: Router,
        activatedRoute: ActivatedRoute,
        mainLoadingIndicatorService: MainLoadingIndicatorService,
        selectionService: ConceptClusterSelectionService,
        storageService: StorageService,
        storeService: ConceptClusterStoreService,
        viewSwitcherService: ViewSwitcherService,
    ) {
        super(
            viewSwitcherService,
            router,
            activatedRoute,
            storeService as BaseStoreService<ViewModelBrowsingListConceptOrClusterItem>,
            selectionService as BaseSelectionService<ViewModelBrowsingListConceptOrClusterItem>,
            storageService,
            mainLoadingIndicatorService,
        );

        // configure toolbar
        // NOTE: previously it was in ngOnInit, but it throws a ExpressionChangedAfterItHasBeenCheckedError exception.
        // We are not sure why it occurs ... maybe something with named outlet (where toolbar is placed) and change detection.
        // This issue occurs in angular > 4.2.5.
        // https://github.com/angular/angular/issues/17572
        this.toolbarService.showViewSwitcher();
        this.toolbarService.showStoreInfo();
        this.toolbarService.showSortBy();
        this.toolbarService.hidePrevNextNavigation();
    }


    ngOnInit(): void {
        super.ngOnInit();
    }
}
