import { Component, Input, ElementRef, OnInit, EventEmitter, Output, } from '@angular/core';

/**
 * Component that creates a <rsp-button> with only a certain icon.
 *
 * Possible selectors are:
 *     * `<rsp-x-button>`: Useful for remove or delete buttons (please consider using together with `ConfirmDialogService`).
 *     * `<rsp-pencil-button>`: Useful for edit buttons.
 *
 * Provided innerText is not visible, only for a11y reasons.
 *
 * ```
 * <rsp-x-button (clicked)="deleteSomething()">Delete</rsp-icon-button>
 * <rsp-x-button [disabled]="true">Not deletable yet</rsp-icon-button>
 * <rsp-x-button [type]="'submit'">Submit my mini-delete-form</rsp-icon-button>
 *
 * <rsp-pencil-button [href]="[ 'edit-route' ]">Edit</rsp-icon-button>
 * <rsp-pencil-button (clicked)="editSomething()">Edit</rsp-icon-button>
 * <rsp-pencil-button [disabled]="true">Not editable yet</rsp-icon-button>
 * ```
 */
@Component( {
    selector:    'rsp-x-button, rsp-pencil-button',
    templateUrl: './icon-button.component.html',
    styleUrls:   [ './icon-button.component.scss', ],
} )
export class IconButtonComponent implements OnInit {
    @Input() type: 'button' | 'submit' = 'button';
    @Input() disabled: boolean         = false;
    @Input() href: any[];
    @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();

    cssModuleName: 'x-button' | 'pencil-button';

    constructor ( private element: ElementRef, ) {
    }

    ngOnInit(): void {
        const tagName: string = this.element.nativeElement.nodeName.toLowerCase();
        switch ( tagName ) {
            case 'rsp-x-button':
                this.cssModuleName = 'x-button';
                break;

            case 'rsp-pencil-button':
                this.cssModuleName = 'pencil-button';
                break;

            default:
                throw new Error( 'IconButtonComponent: Don\'t know how handle ' + tagName );
        }
    }

    emitClicked( event: Event ): void {
        this.clicked.emit( event );
    }
}
