import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';

import { CollectionView, SortDescriptor } from '../../collection-view';
import { StandardSorters } from '../../sorters/standard-sorters';
import { Subscription } from 'rxjs';


/**
 * Handles sorting of the table. Table must use CollectionView.
 */
@Component( {
    selector:    'rsp-column-header',
    templateUrl: './column-header.component.html',
    styleUrls:   [
        './column-header.component.scss',
    ],
} )
export class ColumnHeaderComponent implements OnInit, OnDestroy {

    @Input()
    collectionView: CollectionView<any>;

    @Input()
    propertyName: string;

    @Input()
    sortFunction: ( value1: any, value2: any, ascending: boolean ) => number;

    isColumnSorted: boolean = false;
    ascending: boolean      = false;

    private subscription: Subscription;

    constructor( private standardSorters: StandardSorters ) {
    }


    @HostListener( 'click' ) onClick(): void {
        this.sortThisColumn();
    }


    ngOnInit(): void {

        this.ensureRequiredPropertiesAreSet();

        this.setColumnSortFunctionForCollectionView();

        this.refreshIsColumnSorted();

        this.subscription
            = this.collectionView
                  .itemsChanged
                  .subscribe( () => {
                      this.refreshIsColumnSorted();
                  } );
    }

    ngOnDestroy(): void {
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private sortThisColumn(): void {

        this.ensureRequiredPropertiesAreSet();

        this.ascending = !this.ascending;

        this.collectionView
            .sortBy( this.propertyName, this.ascending )
            .refresh();

        this.isColumnSorted = true;
    }


    private ensureRequiredPropertiesAreSet(): void {

        if ( !this.collectionView ) {
            throw new Error( 'Input property #collectionView must be set!' );
        }

        if ( !this.propertyName ) {
            throw new Error( 'Input property #propertyName must be set!' );
        }
    }


    private setColumnSortFunctionForCollectionView(): void {

        // has collectionView sort function defined for this property?
        if ( !this.collectionView.getPropertySortFunction( this.propertyName ) ) {

            // set provided or default sort function
            if ( this.sortFunction ) {
                this.collectionView.setPropertySortFunction( this.propertyName, this.sortFunction );
            }
            else {
                this.collectionView.setPropertySortFunction( this.propertyName, this.standardSorters.default.bind( this.standardSorters ) );
            }

            const refresh: boolean = this.collectionView
                                         .getSortDescriptors()
                                         .find( ( sortDescriptor: SortDescriptor<any> ) => {
                                             return sortDescriptor.propertyName === this.propertyName;
                                         }) ? true : false;

            if ( refresh ) {
                this.collectionView.refresh();
            }
        }
    }


    private refreshIsColumnSorted(): void {

        const sortDescriptors: Array<SortDescriptor<any>> = this.collectionView.getSortDescriptors();

        if ( sortDescriptors && sortDescriptors.length > 0 ) {

            this.isColumnSorted = sortDescriptors[ 0 ].propertyName === this.propertyName;
            this.ascending      = sortDescriptors[ 0 ].ascending;
        }
        else {
            this.isColumnSorted = false;
            this.ascending      = false;
        }
    }
}
