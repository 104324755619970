import { Component, OnDestroy, TemplateRef, ViewChild, } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, } from '@angular/forms';
import { Router, } from '@angular/router';

import { Subscription, } from 'rxjs';
import { DialogService } from '../../../../../core/overlay/dialog/dialog.service';
import { CustomValidators } from '../../../../../shared/forms/validation/custom-validators';
import { ConceptSuggestItem } from '../../../../../shared/model/conceptSuggestItem';
import { ArticleManagementClustersService } from '../../../../../shared/api';
import { CreateClusterData } from '../../../../../shared/model/createClusterData';
import { ReplyGuid } from '../../../../../shared/model/replyGuid';
import { NonTradeItemSelectionService } from '../../../non-trade-items/shared/non-trade-item-selection.service';
import { NonTradeListItem } from '../../../../../shared/model';
import { EditTextComponent } from '../../../../../shared/forms/controls/edit-text/edit-text.component';



@Component( {
    selector:    'rsp-create-cluster-dialog',
    templateUrl: './create-cluster-dialog.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../shared/scss/05_module/simple-list.scss',
        './create-cluster-dialog.component.scss',
    ],
} )
export class CreateClusterDialogComponent implements OnDestroy {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    fromSelection: boolean;
    caption: string;

    form: UntypedFormGroup;
    isLoading: Subscription;

    constructor(
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private clustersApi: ArticleManagementClustersService,
        private dialogService: DialogService,
        private router: Router,
    ) {}

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    setConcept( item: ConceptSuggestItem ): void {
        this.form.get( 'concept' ).setValue(item);
    }

    doAction(): void {

        if ( this.form.valid ) {

            const articleIds: Array<string> =
                      this.nonTradeItemSelectionService
                          .getSelectedArticles()
                          .map( ( item: NonTradeListItem ) => item.id );

            const assemblyIds: Array<string> =
                      this.nonTradeItemSelectionService
                          .getSelectedAssemblies()
                          .map( ( item: NonTradeListItem ) => item.id );


            const data: CreateClusterData = {
                conceptId:   this.form.get('concept').value.id,
                articleIds:  this.fromSelection ? articleIds : [],
                assemblyIds: this.fromSelection ? assemblyIds : [],
                clusterName: this.form.get('clusterName').value,
            };

            this.isLoading =
                this.clustersApi.clustersCreateCluster( data ).subscribe( ( result: ReplyGuid ) => {
                    this.dialogService.closeDialog();
                    this.router.navigate( [ '/cluster', result.value, ] );
                } );
        }
    }

    open( createFromSelection: boolean = false ): void {
        this.form = new UntypedFormGroup( {
            concept: new UntypedFormControl( null, Validators.required ),
            clusterName: EditTextComponent.buildFormControl( null, [ Validators.required, CustomValidators.stringNotEmpty, ] ),
        } );

        this.fromSelection = createFromSelection;
        this.caption = this.fromSelection ? 'Create Cluster from selection' : 'Create Cluster';
        this.dialogService.openDialog( {
                                           contentTemplate:  this.contentTemplate,
                                           headlineTemplate: this.headlineTemplate,
                                           footerTemplate:   this.footerTemplate,
                                           withBackdrop:     true,
                                       } );
    }
}
