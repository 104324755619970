import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
    AuthConfig,
    OAuthEvent,
    OAuthService,
} from 'angular-oauth2-oidc';

import { environment } from '../../environments/environment';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';


@Injectable()
export class AuthenticationService {

    // it's important to use window.sessionStorage here, because the scope of window.sessionStorage is the browser tab and not the whole browser (which would
    // be the case with window.localStorage).
    readonly storage: Storage = window.sessionStorage;

    isLoggedIn$: Observable<boolean>;

    private isLoggedInSubject: BehaviorSubject<boolean> = new BehaviorSubject( false );
    private receivedTokenAndNavigateToUrl: boolean = false;

    constructor(
        private oAuthService: OAuthService,
        private router: Router,
    ) {
        this.initialize( environment.authentication );

        // This method just tries to parse the token within the url when the auth-server redirects
        // the user back to the web-app. It doesn't initiate the login (this happens only via calls to `login()`).
        this.oAuthService.events.pipe( filter( ( event: OAuthEvent ) => event.type === 'token_received' ) )
            .subscribe( () => {
                this.receivedTokenAndNavigateToUrl = true;
                setTimeout( () => this.navigateToInitialUrl( this.oAuthService.state ), 150 );
            } );
        this.oAuthService.loadDiscoveryDocumentAndTryLogin( { disableOAuth2StateCheck: true } )
            .then( () => {
                this.checkAndPublishIsLoggedIn();
                if ( this.isLoggedIn() && !this.receivedTokenAndNavigateToUrl ) {
                    // Trigger initial routing to show the correct page (because `initialNavigation` has to be set to false in `app-routing.module.ts`).
                    this.navigateToInitialUrl();
                }
            } );

        // TODO: https://manfredsteyer.github.io/angular-oauth2-oidc/angular-oauth2-oidc/docs/additional-documentation/refreshing-a-token-(silent-refresh).html
    }

    public getAccessToken(): string {
        return this.oAuthService.getAccessToken();
    }

    public login(): void {
        this.oAuthService.initImplicitFlow( this.getCurrentUrl() );
    }

    public logoff(): void {
        this.oAuthService.logOut();
        this.isLoggedInSubject.next( false );
    }

    public isLoggedIn(): boolean {
        return this.oAuthService.hasValidIdToken() && this.oAuthService.hasValidAccessToken();
    }



    // private methods
    // ----------------------------------------------------------------------------------------------------------------


    private initialize( settings: AuthConfig ): void {

        this.assertSettingsAreSet( settings );

        // URL of the SPA to redirect the user to after login
        settings.redirectUri = window.location.origin + '/';

        // URL of the SPA to redirect the user to after logout
        settings.postLogoutRedirectUri = window.location.origin + '/logout';

        settings.requireHttps = false;

        this.oAuthService.configure( settings );

        this.oAuthService.tokenValidationHandler = new JwksValidationHandler();

        // use local storage, to support tabs
        this.oAuthService.setStorage( window.localStorage );

        this.isLoggedIn$ = this.isLoggedInSubject.asObservable();
    }


    /**
     * Navigate to the last hit Deep-URL when returning to the application (for example when the user token has expired and the user hit a
     * deep-URL but gets redirected to the identity server which will only redirect to the root-URL, but with this service the original deep-URL gets restored).
     */
    private navigateToInitialUrl( returnUrl?: string ): void {
        this.router.navigateByUrl( returnUrl || this.getCurrentUrl() );
    }

    private getCurrentUrl(): string {

        // if ( this.router ) {
        //     console.log( '[BootstrapService.getCurrentUrl] this.router.url: ' + this.router.url );
        // }

        // this.router.url doesn't work, because Angular routing may still be uninitialized.
        return window.location.pathname + window.location.search + window.location.hash;
    }

    private assertSettingsAreSet( settings: AuthConfig ): void {

        if ( !settings ) {
            throw new Error( 'AuthenticationSettings must be defined!' );
        }

        if ( !settings.clientId ) {
            throw new Error( 'Missing required AuthenticationSettings: "clientId"' );
        }

        if ( !settings.issuer ) {
            throw new Error( 'Missing required AuthenticationSettings: "issuer"' );
        }

        if ( !settings.loginUrl ) {
            throw new Error( 'Missing required AuthenticationSettings: "loginUrl"' );
        }

        if ( !settings.scope ) {
            throw new Error( 'Missing required AuthenticationSettings: "scope"' );
        }
    }

    private checkAndPublishIsLoggedIn(): void {
        const isLoggedIn: boolean = this.isLoggedIn();

        if ( isLoggedIn !== this.isLoggedInSubject.getValue() ) {
            this.isLoggedInSubject.next( isLoggedIn );
        }
    }
}
