import { Component, EventEmitter, Input, Output,        } from '@angular/core';

import { StorageService,                                } from '../../../../core/storage.service';
import { facetAnimations,                               } from './facet.animations';
import { FacetValueChangedEventData, } from './facet.model';
import { VerticalScrollService } from '../../lists/vertical-scroll/vertical-scroll.service';
import { FacetItem } from '../../../model/facetItem';


/**
 * Generates a facet and handles all the things that are common to all facets.
 * The actual content display of the facet is then generated by one of the `<rsp-facet-type-*>`-components.
 *
 * ```html
 * <rsp-facet [facet]="someFacetObject" (valueChanged)="handleValueChange( $event )"></rsp-facet>
 * ```
 *
 */
@Component( {
    selector:    'rsp-facet',
    templateUrl: './facet.component.html',
    styleUrls:   [
        './facet.component.scss',
    ],
    animations:  facetAnimations,
} )
export class FacetComponent {

    @Input()
    set facet( facet: FacetItem ) {
        this._facet = facet;
        this.initIsExpanded();
    }
    get facet(): FacetItem {
        return this._facet;
    }

    @Output() valueChanged: EventEmitter<FacetValueChangedEventData> = new EventEmitter<FacetValueChangedEventData>();

    cssModuleName: string = 'facet';
    isExpanded:    boolean;

    facetType: any = FacetItem.TypeEnum;

    private _facet: FacetItem;

    private storageKeyFor:  { isExpanded: string } = {
        isExpanded: null,
    };


    constructor(
        private storageService: StorageService,
        private verticalScrollService: VerticalScrollService,
    ) {
    }


    /**
     * Toggles the visibility of the facet body.
     */
    toggleFacetExpansion( event: Event ): void {
        if ( this.facet.values.length > 0 ) { // only toggle when there are values
            this.isExpanded = !this.isExpanded;
            this.storageService.set<boolean>( this.storageKeyFor.isExpanded, this.isExpanded );

            event.stopPropagation();
        }
    }

    /**
     * Re-emits the given FacetValue to the parent component (probably <rsp-filters>).
     */
    onFacetValueChanged( facetValueChangedEventData: FacetValueChangedEventData ): void {
        this.valueChanged.emit( facetValueChangedEventData );
    }

    /**
     * Tells the parent component (probably <rsp-filters>) to clear the FacetValue for this facet.
     */
    onRemoveFacetValues( event?: Event ): void {
        // TODO: Still needed? Maybe with tri-state
        // this.valueChanged.emit( { facetId: this.facet.name, facetFilterValue: [], } );

        if ( event && event.stopPropagation ) {
            event.stopPropagation();
        }
    }


    /**
     * Triggers vertical scroll recalculation ( scroll up / down buttons )
     */
    triggerVerticalScrollCalculation(): void {
        this.verticalScrollService.refreshScrollCalculation();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private initIsExpanded(): void {
        this.storageKeyFor.isExpanded = 'listPage.facet.(' + this.facet.name + ').isExpanded';

        let isExpanded: boolean = this.storageService.get<boolean>( this.storageKeyFor.isExpanded );
        if ( isExpanded === null ) {
            isExpanded = false;
        }

        this.isExpanded = isExpanded;
    }
}
