<ng-template #dialogHeader>
    <h2 class="standard-dialog--headline">Export selection to Ariba</h2>
</ng-template>

<ng-template #dialogContent>
    <div
        [rspLoadingIndicator]="isLoading"
        class="standard-dialog--content export-selection-to-ariba-dialog--content"
    >
        <rsp-non-trade-item-selection-list></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #dialogFooter>
    <div class="export-selection-to-ariba-dialog--info">
        You will be notified by E-Mails once your extract and exports are ready to download.
    </div>

    <div class="standard-dialog--footer">
        <rsp-edit-text
            [myFormControl]="nameControl"
            [placeholder]="'Export Name'"
            style="flex: 1"
        ></rsp-edit-text>

        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
            class="export-selection-to-ariba-dialog--footer-button"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            [disabled]="!nameControl.valid || !hasSelectedItems()"
            (clicked)="doAction()"
            class="export-selection-to-ariba-dialog--footer-button"
        >
            Trigger Export
        </rsp-button>
    </div>
</ng-template>
