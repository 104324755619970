<div *ngIf="hasAnyAction" class="action--wrapper">

    <rsp-dropdown-group
        [label]="'Documents'"
    >
        <rsp-dropdown-item
            *ngFor="let action of actions"
            [item]="action"
            [disabled]="action.requiresSelection ? !( hasSelectedItems | async ) : false"
            (click)="selectAction(action)"
        >
            {{ action.label }}
        </rsp-dropdown-item>
    </rsp-dropdown-group>
</div>

<div *ngIf="!hasAnyAction" class="action--wrapper">
    No Actions available.
</div>

<rsp-add-or-edit-document-dialog></rsp-add-or-edit-document-dialog>
<rsp-create-zip-from-files-dialog></rsp-create-zip-from-files-dialog>
