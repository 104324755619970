<div class="tab">

    <div *ngIf="actions.length > 0" class="tab--toolbar">
        <rsp-form-buttons
            [isEditMode]="isEditMode"
            [myFormGroup]="form"
            [disablePrimaryEditButton]="isLoadingSave"
            [actions]="actions"

            (actionButtonClick)="startEditMode()"
            (primaryEditButtonClick)="submitForm()"
            (secondaryEditButtonClick)="endEditMode()"
            (dropDownActionClicked)="triggerAction( $event )"
            class="tab--toolbar-item is-right"
        ></rsp-form-buttons>
    </div>

    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <ng-container *ngIf="clusterDetails">
            <div *ngIf="clusterDetails.note || isEditMode"
                 class="l-two-columns-1200"
            >
                <div class="l-two-columns-1200--span-column">
                    <div class="l-two-columns-1200--column-content-gutter-item">

                        <!-- Note -->
                        <rsp-card [type]="cardType.Note">
                            <rsp-card-headline>Note</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-text-multiline
                                    [value]="clusterDetails.note"
                                    [myFormGroup]="form"
                                    [isEditMode]="isEditMode"
                                    [name]="'note'"
                                ></rsp-display-edit-text-multiline>
                            </rsp-card-content>
                        </rsp-card>

                    </div>
                </div>
            </div>


            <div class="l-two-columns-1200">

                <!-- Left column -->
                <div class="l-two-columns-1200--column">

                    <!-- Concept -->
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>Concept</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-link
                                    *ngIf="!isEditMode"
                                    [type]="'concept'"
                                    [id]="clusterDetails.concept.id"
                                    [useContent]="true"
                                >
                                    {{ clusterDetails.concept.name }}
                                </rsp-link>

                                <ng-container *ngIf="isEditMode">
                                    {{ clusterDetails.concept.name }}
                                </ng-container>
                            </rsp-card-content>
                        </rsp-card>
                    </div>

                    <!-- Name -->
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>Name</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-text
                                    [value]="clusterDetails.name"
                                    [isEditMode]="isEditMode"
                                    [myFormGroup]="form"
                                    [name]="'name'"
                                ></rsp-display-edit-text>
                            </rsp-card-content>
                        </rsp-card>
                    </div>

                </div>

                <!-- Right column -->
                <div class="l-two-columns-1200--column">

                    <!-- Available for ordering -->
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>Available for ordering</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-date-time-range
                                    [isEditMode]="isEditMode"
                                    [myFormGroup]="form?.get('availableForOrdering')"
                                    [datetimeFrom]="clusterDetails.availableForOrderingFrom"
                                    [datetimeUntil]="clusterDetails.availableForOrderingUntil"
                                    [withWeekday]="false"
                                ></rsp-display-edit-date-time-range>
                            </rsp-card-content>
                        </rsp-card>
                    </div>

                    <!-- Keywords -->
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>Keywords</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-tags
                                    *ngIf="!isEditMode"
                                    [tags]="getKeywordsAsTags()"
                                ></rsp-tags>
                                <rsp-edit-keywords
                                    *ngIf="isEditMode"
                                    [myFormControl]="form?.get('keywordIds')"
                                    [keywords]="clusterDetails.keywords"
                                    [keywordsGroupName]="'Concepts & Clusters'"
                                ></rsp-edit-keywords>
                            </rsp-card-content>
                        </rsp-card>
                    </div>

                </div>
            </div>

            <div class="l-two-columns-1200">

                <!-- Left column -->
                <div class="l-two-columns-1200--column">
                    <rsp-display-edit-distribution-channels
                        [clusterDetails]="clusterDetails"
                        [isEditMode]="isEditMode"
                        [myFormGroup]="form?.get('distributionChannels')"
                    ></rsp-display-edit-distribution-channels>
                </div>

                <!-- Right column -->
                <div class="l-two-columns-1200--column">

                    <!-- Retail Segmentation -->
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>Retail Segmentation</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-retail-segmentations
                                    [retailSegmentations]="clusterDetails.retailSegmentations"
                                    [isEditMode]="isEditMode"
                                    [myFormControl]="form?.get('retailSegmentationIds')"
                                ></rsp-display-edit-retail-segmentations>
                            </rsp-card-content>
                        </rsp-card>
                    </div>

                    <!-- Experiences -->
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>Experiences</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-experiences
                                    [experiences]="clusterDetails.experiences"
                                    [isEditMode]="isEditMode"
                                    [myFormControl]="form?.get('experienceIds')"
                                ></rsp-display-edit-experiences>
                            </rsp-card-content>
                        </rsp-card>
                    </div>

                    <!-- Categories -->
                    <div
                        *ngIf="clusterDetails.concept.brand && clusterDetails.concept.subBrand"
                        class="l-two-columns-1200--column-content-gutter-item"
                    >
                        <rsp-card>
                            <rsp-card-headline>Categories</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-cluster-categories
                                    [categories]="clusterDetails.subbrandCategories"
                                    [concept]="clusterDetails.concept"
                                    [isEditMode]="isEditMode"
                                    [myFormControl]="form?.get('subbrandCategoryIds')"
                                ></rsp-display-edit-cluster-categories>
                            </rsp-card-content>
                        </rsp-card>
                    </div>

                </div>

            </div>
        </ng-container>
    </div>

</div>

