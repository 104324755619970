<section class="facets">
    <div class="facets--body">
        <div *ngFor="let selectList of selectLists" class="facets--select_list">
            <rsp-dropdown
                [defaultValue]="selectList.selectedValue"
                [placeholder]="selectList.name"
                [hasFullWidth]="true"
                [allowClear]="true"
                (selected)="onSelectListsChanged( $event, selectList )"
            >
                <rsp-dropdown-item
                    *ngFor="let option of selectList.values"
                    [item]="option"
                >
                    {{ option }}
                </rsp-dropdown-item>
            </rsp-dropdown>
        </div>
        <ng-container *ngIf="facets && facets.length > 0">
            <rsp-facet
                *ngFor="let facet of facets; last as isLast"
                [facet]="facet"
                [class.is-last-facet]="isLast"
                (valueChanged)="onFacetValueChanged( $event )"
            ></rsp-facet>
        </ng-container>
    </div>

    <footer class="facets--footer">
        <rsp-button
            *ngIf="advancedSearchFilterProperties && advancedSearchFilterProperties.length > 0 && advancedSearchFilterTypeOperators && advancedSearchFilterTypeOperators.length > 0"
            [type]="'button'"
            [submodule]="'tertiary'"
            (clicked)="toggleAdvancedSearchVisibility()"
            class="facets--footer-advanced-search-button"
        >
            Advanced Search
            <span *ngIf="!showAdvancedSearch" class="facets--footer-advanced-search-button-open-icon"></span>
            <span *ngIf="showAdvancedSearch" class="facets--footer-advanced-search-button-close-icon"></span>

        </rsp-button>
    </footer>
</section>

<rsp-advanced-search
    *ngIf="advancedSearchFilterProperties && advancedSearchFilterProperties.length > 0 && advancedSearchFilterTypeOperators && advancedSearchFilterTypeOperators.length > 0"
    [advancedSearchFilterGroups]="advancedSearchFilterGroups"
    [advancedSearchFilterProperties]="advancedSearchFilterProperties"
    [advancedSearchFilterTypeOperators]="advancedSearchFilterTypeOperators"
    class="advanced-search"
    [class.is-advanced-search-visible]="!isHidden && showAdvancedSearch"
    (onClose)="hideAdvancedSearch()"
    (valueChanged)="onAdvancedSearchValueChanged( $event )"
>
</rsp-advanced-search>
