import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, } from '@angular/core';

import { ServiceModelItem, } from './service-model-item';
import { CollectionView } from '../tables/collection-view';


/**
 * Displays table of service models inclusive service levels. It has two modes:
 * - Usage on concept (IsAdministrationMode === false) - used on concept, displays service models/levels assigned to the concept.
 *                                                       Allows assign new service level to concept.
 * - Administration (IsAdministrationMode === true)    - allows administration of service models and levels.
 */
@Component( {
    selector:        'rsp-service-models-table',
    templateUrl:     './service-models-table.component.html',
    styleUrls:       [
        './service-models-table.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class ServiceModelsTableComponent implements OnChanges {

    @Input()
    items: Array<ServiceModelItem>;

    @Input()
    isAdministrationMode: boolean;

    @Output()
    saveServiceModelClick: EventEmitter<ServiceModelItem> = new EventEmitter<ServiceModelItem>();

    @Output()
    deleteServiceModelConfirmed: EventEmitter<ServiceModelItem> = new EventEmitter<ServiceModelItem>();

    collectionView: CollectionView<ServiceModelItem> = new CollectionView<ServiceModelItem>();

    constructor(
        private changeDetectionRef: ChangeDetectorRef,
    ) {
        // default sort column
        this.collectionView.sortBy( 'name', true );
    }


    ngOnChanges( changes: SimpleChanges ): void {

        if ( changes.items && this.items !== undefined ) {
            this.collectionView.setSourceItems( this.items ).refresh();
        }
    }


    isTableVisible(): boolean {
        return !this.collectionView.isEmpty();
    }


    addNewServiceModel(): void {
        this.collectionView.items.unshift( ServiceModelItem.createNewServiceModelItem() );
        this.changeDetectionRef.detectChanges();
    }


    onEditServiceModelClick( model: ServiceModelItem, event: Event ): boolean {

        model.activateEditMode();

        event.stopPropagation();
        return false;
    }

    removeServiceModel( model: ServiceModelItem ): void {
        this.deleteServiceModelConfirmed.emit( model );
    }

    onSaveServiceModelClick( model: ServiceModelItem, event: Event ): boolean {

        this.saveServiceModelClick.emit( model );

        event.stopPropagation();
        return false;
    }


    confirmServiceModelUpdated( serviceModelId: string ): void {

        // service model saved, end edit mode for this item
        const editedItem: ServiceModelItem = this.collectionView
                                                 .items
                                                 .find( ( item: ServiceModelItem ) => item.id === serviceModelId );
        editedItem.endEditMode();
        this.changeDetectionRef.detectChanges();
    }


    confirmServiceModelCreated( id: string, additionalId: number ): void {

        // service model has been created on backend -> assign id
        const createdItem: ServiceModelItem = this.collectionView
                                                  .items
                                                  .find( ( item: ServiceModelItem ) => item.additionalId === additionalId );

        createdItem.id = id;
        createdItem.endEditMode();
        this.changeDetectionRef.detectChanges();
    }

    confirmServiceModelDeleted( id: string ): void {

        // service model has been deleted on server, remove it from the items array
        const index: number = this.collectionView
                                  .items
                                  .findIndex( ( item: ServiceModelItem ) => item.id === id );

        if ( index !== -1 ) {
            this.collectionView.items.splice( index, 1 );
            this.changeDetectionRef.detectChanges();
        }
    }


    onCancelServiceModelItemEditModeClick( model: ServiceModelItem, event: Event ): boolean {

        if ( model.id ) {
            model.cancelEditMode();
        }
        else {
            // cancel for new created service model removes it
            const index: number = this.collectionView
                                      .items
                                      .findIndex( ( item: ServiceModelItem ) => item === model );

            if ( index !== -1 ) {
                this.collectionView.items.splice( index, 1 );
            }
        }

        event.stopPropagation();
        return false;
    }

}
