<ng-container *ngIf="isLoggedIn; else loginTemplate">
    <rsp-backdrop></rsp-backdrop>

    <rsp-main-menu></rsp-main-menu>

    <div class="app--container"
         [@appContainerTrigger]="( isMainMenuOpen | async ) ? 'shift' : 'none'">

        <rsp-master-head class="app--header">
            <router-outlet name="secondary-toolbar"></router-outlet>
        </rsp-master-head>

        <main class="app--body">
            <rsp-main-slide-in></rsp-main-slide-in>
            <rsp-main-loading-indicator></rsp-main-loading-indicator>
            <router-outlet></router-outlet>
        </main>

        <footer class="app--footer">
            <rsp-system-info></rsp-system-info>
        </footer>

        <rsp-notification-list></rsp-notification-list>

    </div>

    <rsp-dialog></rsp-dialog>

    <rsp-confirm-dialog></rsp-confirm-dialog>

    <rsp-popup></rsp-popup>
</ng-container>

<ng-template #loginTemplate>
    <rsp-backdrop></rsp-backdrop>
    <rsp-login [showLogoutMessage]="showLogoutMessage"></rsp-login>
    <rsp-dialog></rsp-dialog>
</ng-template>
