import { Injectable, } from '@angular/core';

import { FacetValue } from '../../../model/facetValue';
import { FacetItem } from '../../../model/facetItem';
import { FacetFilterItem } from '../../../model/facetFilterItem';
import { FacetFilterValue } from '../../../model/facetFilterValue';

@Injectable()
export class FacetService {

    /**
     * Takes an array of facets and returns a copy of that array which only contains the active values (currently that means _selected_ values).
     */
    getActiveFacetsFromFacets( facets: FacetItem[], level: number = 0 ): FacetItem[] {
        if ( level === 0 ) {
            // deep copy incoming facets, because we will alter the structure
            facets = JSON.parse( JSON.stringify( facets ) );
        }

        facets = facets.filter( ( facet: FacetItem ) => {
            facet.values = facet.values.filter( ( facetValue: FacetValue ) => {
                if ( Array.isArray( facetValue.facets ) && facetValue.facets.length > 0 ) {
                    return this.getActiveFacetsFromFacets( facetValue.facets, level + 1 ).length > 0;
                }
                else {
                    return facetValue.isSelected;
                }
            } );

            return facet.values.length > 0;
        } );

        return facets;
    }

    getFacetFilterForFacet( facet: FacetItem ): FacetFilterItem | null {
        switch ( facet.type ) {
            case FacetItem.TypeEnum.Multiselect:
                return this.getFacetFilterForMultiselectFacet( facet );

            case FacetItem.TypeEnum.MultiselectHierarchical:
                return this.getFacetFilterForMultiselectHierarchicalFacet( facet );

            case FacetItem.TypeEnum.Singleselect:
                return this.getFacetFilterForSingleselectFacet( facet );

            default:
                throw new Error( `FacetService: Don't know how to getFacetFilterForFacet for facet of type '${ facet.type }'.` );
        }
    }

    getFacetFiltersForFacets( facets: Array<FacetItem> ): Array<FacetFilterItem> {
        let allFacetFilters: Array<FacetFilterItem> = [];
        let facetFilterForCurrentFacet: FacetFilterItem;

        facets.forEach( ( facet: FacetItem ) => {
            facetFilterForCurrentFacet = this.getFacetFilterForFacet( facet );

            if ( facetFilterForCurrentFacet && facetFilterForCurrentFacet.values.length > 0 ) {
                allFacetFilters = allFacetFilters.concat( facetFilterForCurrentFacet );
            }
        } );

        return allFacetFilters;
    }

    getFacetFilterForSingleselectFacet( facet: FacetItem ): FacetFilterItem | null {
        return this.getFacetFilterForSingleseletOrMultiselectFacet( facet );
    }

    getFacetFilterForMultiselectFacet( facet: FacetItem ): FacetFilterItem | null {
        return this.getFacetFilterForSingleseletOrMultiselectFacet( facet );
    }

    getFacetFilterForMultiselectHierarchicalFacet( facet: FacetItem ): FacetFilterItem | null {
        facet = JSON.parse( JSON.stringify( facet ) ); // deep copy input param, so we don't accidentally change it.

        const facetFilter: FacetFilterItem = {
            name:    facet.name,
            values:  [],
        };

        facet.values.forEach( ( facetValue: FacetValue ) => {
            if ( Array.isArray( facetValue.facets ) && facetValue.facets.length > 0 ) {
                facetValue.facets.forEach( ( subFacet: FacetItem ) => {
                    const subFacetFilter: FacetFilterItem = this.getFacetFilterForMultiselectHierarchicalFacet( subFacet );
                    if ( subFacetFilter ) {
                        facetFilter.values.push(
                            {
                                value:   facetValue.value,
                                filters: [ subFacetFilter ],
                            },
                        );
                    }
                } );
            }
            else if ( facetValue.isSelected ) {
                facetFilter.values.push(
                    {
                        value:   facetValue.value,
                        filters: [],
                    },
                );
            }
        } );

        return facetFilter.values.length > 0 ? facetFilter : null;
    }

    countSelectedFacetValues( facet: FacetItem ): number {
        switch ( facet.type ) {
            case FacetItem.TypeEnum.Multiselect:
            case FacetItem.TypeEnum.Singleselect:
                const facetFilter: FacetFilterItem = this.getFacetFilterForFacet( facet );
                return facetFilter ? facetFilter.values.length : 0;

            case FacetItem.TypeEnum.MultiselectHierarchical:
                return this.countSelectedFacetValuesForTypeMultiselectHierarchical( facet );

            default:
                throw new Error( `FacetService: Don't know how to countSelectedFacetValues for facet of type '${ facet.type }'.` );
        }
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private countSelectedFacetValuesForTypeMultiselectHierarchical( facet: FacetItem ): number {
        let count: number = 0;

        facet.values.forEach( ( facetValue: FacetValue ) => {
            const hasSubFacets: boolean = Array.isArray( facetValue.facets ) && facetValue.facets.length > 0;
            if ( !hasSubFacets && facetValue.isSelected ) {
                count += 1;
            }

            if ( hasSubFacets ) {
                facetValue.facets.forEach( ( subFacet: FacetItem ) => {
                    count += this.countSelectedFacetValuesForTypeMultiselectHierarchical( subFacet );
                } );
            }
        } );

        return count;
    }

    private getFacetFilterForSingleseletOrMultiselectFacet( facet: FacetItem ): FacetFilterItem {
        const selectedValues: Array<string> = facet.values
            .filter( ( valueItem: FacetValue ) => valueItem.isSelected )
            .map(    ( valueItem: FacetValue ) => valueItem.value )
        ;


        let returnNull: boolean = true;
        if ( facet.type === FacetItem.TypeEnum.Singleselect ) {
            if ( selectedValues.length > 1 ) {
                throw new Error( 'Singleselect facet must have maximal one selected value!' );
            }
            else if ( selectedValues.length === 1 ) {
                returnNull = false;
            }
        }
        else {
            if ( selectedValues.length > 0 ) {
                returnNull = false;
            }
        }

        if ( returnNull ) {
            return null;
        }
        else {
            return {
                name:   facet.name,
                values: selectedValues.map(
                    ( value: string ): FacetFilterValue => {
                        return {
                            value:   value,
                            filters: [],
                        };
                    },
                ),
            };
        }
    }
}
