<ng-container *ngIf="!isEditMode">
    <ul class="subcomponent-list" *ngIf="subComponents.length > 0; else noSubComponents">
        <li *ngFor="let subcomponent of subComponents">
            {{ subcomponent.number }}
        </li>
    </ul>
    <ng-template #noSubComponents>
        <rsp-not-available></rsp-not-available>
    </ng-template>

</ng-container>

<ng-container *ngIf="isEditMode">
    <div>
        <span
            *ngFor="let subcomponent of subComponents"
            rspKeyword
            [withClearButton]="true"
            (clearClick)="removeSubComponent( subcomponent )"
        >
            {{ subcomponent.number }}
        </span>
    </div>

    <rsp-subcomponent-suggester
        (selected)="addSubComponent( $event )"
        [itemFilter]="subComponents"
    >
    </rsp-subcomponent-suggester>
</ng-container>
