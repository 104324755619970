import { Component, Input, } from '@angular/core';


export const NOT_AVAILABLE: string = 'n / a';


/**
 * Displays information that some data is not available -> n / a
 */
@Component( {
    selector:    'rsp-not-available',
    templateUrl: './not-available.component.html',
    styleUrls:   [
        './not-available.component.scss',
    ],
} )
export class NotAvailableComponent {

    @Input() notAvailable: string;

    constructor() {
        this.notAvailable = NOT_AVAILABLE;
    }
}
