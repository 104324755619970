/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { ClusterInfo } from './clusterInfo';


export interface ConceptSuggestItem {
    description?: string;

    clusters?: Array<ClusterInfo>;

    id?: string;

    name?: string;

    status?: ConceptSuggestItem.StatusEnum;

}

export namespace ConceptSuggestItem {
    'use strict';
    export enum StatusEnum {
        Unpublished,
        Published,
        Active,
        Inactive,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

