import { Component, OnDestroy, TemplateRef, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { CustomValidators } from '../../../../../../../shared/forms/validation/custom-validators';
import { ArticleManagementArticlesService } from '../../../../../../../shared/api';
import { ViewModelItemArticleInfo } from '../../../../../../../shared/model';


@Component( {
    selector:    'rsp-create-article-dialog',
    templateUrl: './create-article-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        './create-article-dialog.component.scss',
    ],
} )
export class CreateArticleDialogComponent implements OnDestroy {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    isLoading: Subscription;

    articleNameControl: UntypedFormControl = new UntypedFormControl( '', [ Validators.required, CustomValidators.stringNotEmpty, Validators.maxLength(73) ] );

    constructor(
        private articlesApi: ArticleManagementArticlesService,
        private dialogService: DialogService,
        private router: Router,
    ) {}

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    doAction(): void {

        if ( this.articleNameControl.valid ) {

            this.isLoading =
                this.articlesApi
                    .articlesCreate( this.articleNameControl.value )
                    .subscribe( ( result: ViewModelItemArticleInfo ) => {
                        this.close();
                        this.router.navigate( [ '/article', result.data.number, ] );
                    } );
        }
    }

    open(): void {
        this.dialogService.openDialog( {
                                           contentTemplate:  this.contentTemplate,
                                           headlineTemplate: this.headlineTemplate,
                                           footerTemplate:   this.footerTemplate,
                                           withBackdrop:     true,
                                       } );
        this.articleNameControl.setValue(null);
        this.articleNameControl.updateValueAndValidity();
    }

    close(): void {
        this.dialogService.closeDialog();
    }
}
