import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MainSlideInService, MainSlideInSize } from '../../../../core/overlay/main-slide-in/main-slide-in.service';
import { UniqueHtmlIdService } from '../../../../core/unique-html-id.service';
import { secondaryToolbarSlideInButtonAnimations } from './secondary-toolbar-slidein-button.animations';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';


export type SecondaryToolbarButtonIcon = 'no-icon' | 'basket' | 'action';


/**
 * Button used inside the secondary-toolbar. It opens/closes overlay containing some actions.
 *
 * ```html
 * <rsp-secondary-toolbar-slidein-button
 *      [label]="'Manage'"
 *      [icon]="'basket'"
 *      [badgeValue]="8"
 * >
 *      <span icon class="icon-manage-baskets"></span>
 * </rsp-secondary-toolbar-slidein-button>
 * ```
 */
@Component( {
    selector:    'rsp-secondary-toolbar-slidein-button',
    templateUrl: './secondary-toolbar-slidein-button.component.html',
    styleUrls:   [
        './secondary-toolbar-slidein-button.component.scss',
    ],
    animations:  secondaryToolbarSlideInButtonAnimations,
} )
export class SecondaryToolbarSlideInButtonComponent implements OnInit, OnDestroy {

    @ViewChild( 'buttonContainer', { static: true } )
    buttonContainer: ElementRef;

    @ViewChild( 'slideInContent', { read: TemplateRef, static: true } )
    template: TemplateRef<any>;

    @Input()
    label: string;

    /**
     * Specifies icon to be displayed.
     */
    @Input()
    icon: SecondaryToolbarButtonIcon = 'no-icon';

    /**
     * Number to be displayed as badge. Only numbers greater then 0 are displayed.
     */
    @Input()
    badgeValue: number;

    /**
     * Specifies the size and position of the main-slide-in. If not provided, 'full' is used.
     */
    @Input()
    slideInSize: MainSlideInSize;


    /**
     * Specifies if Clear-Button should displayed. Clear-Button is visible when #badgeValue is greater then 0.
     */
    @Input()
    showClearButton: boolean;

    /**
     * Event emitted when user clicked the Clear-Button.
     */
    @Output()
    clearButtonClick: EventEmitter<void> = new EventEmitter<void>();


    /**
     * If button is active it means that overlay containing related actions is open.
     */
    isActive: boolean = false;

    uniqueId: string;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();


    constructor(
        private mainSlideInService: MainSlideInService,
        private uniqueHtmlIdService: UniqueHtmlIdService,
    ) {
        this.uniqueId = this.uniqueHtmlIdService.getUniqueHtmlId( 'secondary-toolbar-slidein-button' );
    }

    ngOnInit(): void {

        this.mainSlideInService
            .mainSlideInClosed$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => this.isActive = false );

        this.mainSlideInService
            .mainSlideInOpened$
            .pipe(
                filter( ( uniqueId: string ) => this.uniqueId === uniqueId ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( uniqueId: string ) => this.isActive = true );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    toggleActive(): void {

        if ( !this.isActive ) {
            this.mainSlideInService.openSlideIn( this.uniqueId, this.template, this.slideInSize, this.buttonContainer.nativeElement );
        }
        else {
            this.mainSlideInService.closeSlideIn();
        }
    }

    onClearButtonClick(): void {

        this.clearButtonClick.emit();
    }
}
