<ng-template #dialogHeader>
    <h2 class="standard-dialog--headline">Add selection to Assembly</h2>
</ng-template>

<ng-template #dialogContent>
    <div
        [rspLoadingIndicator]="isLoading"
        class="standard-dialog--content"
    >
        <rsp-non-trade-item-selection-list [nonTradeItemType]="articleType"></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #dialogFooter>
    <div class="standard-dialog--footer">
        <rsp-non-trade-item-suggester
            [type]="assemblyType"
            [placeholder]="'Target Assembly'"
            [displayValue]="form.get('targetAssembly').value ? form.get('targetAssembly').value.number + ' - ' + form.get('targetAssembly').value.name : null"
            (selected)="setTargetAssembly( $event )"
        >
            <rsp-error-messages [formErrors]="form.get('targetAssembly').errors"></rsp-error-messages>
        </rsp-non-trade-item-suggester>

        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
            class="add-selection-to-assembly-dialog--footer-button"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            [disabled]="!form.valid"
            (clicked)="doAction()"
            class="add-selection-to-assembly-dialog--footer-button"
        >
            Add
        </rsp-button>
    </div>
</ng-template>
