import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DetailPagePrevNextNavigationService } from '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.service';
import { UserStoreService } from '../user-store.service';
import { UserService } from '../../../../../shared/ui/user/user.service';
import { UserListItem } from '../../../../../shared/model';

@Injectable()
export class UserPrevNextNavigationService extends DetailPagePrevNextNavigationService<UserListItem> {
    constructor(
        router: Router,
        storeService: UserStoreService,
        private userService: UserService,
    ) {
        super( router, storeService );
    }

    protected getItemIndex( id: string ): number {
        // meh…
        const pseudoItem: any = { id: id, };

        const index: number   = this.storeService.getItemIndex( pseudoItem );

        return index;
    }

    protected createRouterLinkForItem( item: UserListItem ): any[] {
        return [ '/user', item.id, this.lastUrlPart ];
    }
}
