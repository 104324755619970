import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, } from '@angular/forms';
import { sortBy } from 'lodash';

// tslint:disable:max-line-length
import { RegistrationUserData } from '../registration-wizard.component';
import { EditTextComponent } from '../../../../shared/forms/controls/edit-text/edit-text.component';
import { CultureInfo, ModuleGroupInfo } from '../../../../shared/model';
import { UniqueHtmlIdService } from '../../../../core/unique-html-id.service';
import { CustomValidators } from '../../../../shared/forms/validation/custom-validators';
import { UploadedFileInfo } from '../../../../shared/forms/upload/upload-single-file/upload-single-file.component';
import { DisplayEditUserProfileImageComponent } from '../../../../shared/forms/controls/display-edit-user-profile-image/display-edit-user-profile-image.component';
import { MessageType } from '../../../../shared/ui/messages/messages.component';
// tslint:enable:max-line-length

interface MyProfileForm {
    profileImageFileContainerId: string | null | UntypedFormControl;
    phoneNumber:                 string | null | UntypedFormControl;
    moduleGroups:                ModuleGroupInfo[] | UntypedFormControl;
    wantsEmailNotifications:     boolean | UntypedFormControl;
}

@Component( {
    selector:    'rsp-registration-wizard-my-profile',
    templateUrl: './registration-wizard-my-profile.component.html',
    styleUrls:   [
        '../../../../shared/scss/04_layout/two-columns-1200.scss',
        './registration-wizard-my-profile.component.scss',
    ],
} )
export class RegistrationWizardMyProfileComponent implements OnInit {
    @Input() originalUserData: RegistrationUserData;
    @Input() moduleGroups: ModuleGroupInfo[]                         = [];
    @Input() cultures: CultureInfo[]                                 = [];
    @Output() dataChanged: EventEmitter<RegistrationUserData | null> = new EventEmitter();

    form: UntypedFormGroup;
    htmlIdFor: Map<string, string> = new Map();
    messageType: any = MessageType;

    userData: RegistrationUserData;

    constructor(
        private uniqueHtmlIdService: UniqueHtmlIdService,
    ) {
    }

    ngOnInit(): void {
        // deep copy object, because we update stuff in this.userData and updating the input object directly which *might* be problematic for the parent.
        this.userData = JSON.parse( JSON.stringify( this.originalUserData ) );

        this.moduleGroups = sortBy( this.moduleGroups, 'name' );

        this.initForm();
    }

    setFileContainerId( file: UploadedFileInfo | null ): void {
        this.userData.profileImageFileContainerId = file ? file.fileContainerId : null;
        this.userData.profilePreviewImageId       = file ? file.previewImageId : null;
    }

    isModuleGroupAssigned( moduleGroupToCheck: ModuleGroupInfo ): boolean {
        return this.userData.moduleGroups.some( ( moduleGroup: ModuleGroupInfo ) => moduleGroupToCheck.id === moduleGroup.id );
    }

    toggleModuleGroup( moduleGroupToToggle: ModuleGroupInfo ): void {
        const moduleGroupsFormControl: UntypedFormControl = this.form.get( 'moduleGroups' ) as UntypedFormControl;
        const moduleGroups: ModuleGroupInfo[]          = moduleGroupsFormControl.value;

        const moduleGroupIndex: number             = moduleGroups.findIndex( ( moduleGroup: ModuleGroupInfo ) => moduleGroup.id === moduleGroupToToggle.id );
        if ( moduleGroupIndex >= 0 ) {
            moduleGroups.splice( moduleGroupIndex, 1 );
        }
        else {
            moduleGroups.push( moduleGroupToToggle );
        }

        moduleGroupsFormControl.setValue( moduleGroups );
        this.userData.moduleGroups = moduleGroups;
    }

    toggleEmailNotifications(): void {
        const formControl: AbstractControl = this.form.get( 'wantsEmailNotifications' );
        formControl.setValue( !formControl.value );
        this.userData.wantsEmailNotifications = formControl.value;
    }

    private initForm(): void {
        this.htmlIdFor.set( 'profileImageField', this.uniqueHtmlIdService.getUniqueHtmlId( 'profile-image' ) );
        this.htmlIdFor.set( 'phoneNumberField', this.uniqueHtmlIdService.getUniqueHtmlId( 'phone-number' ) );
        this.htmlIdFor.set( 'companyField', this.uniqueHtmlIdService.getUniqueHtmlId( 'company' ) );
        this.htmlIdFor.set( 'locationField', this.uniqueHtmlIdService.getUniqueHtmlId( 'location' ) );

        const formDefinition: MyProfileForm = {
            profileImageFileContainerId: DisplayEditUserProfileImageComponent.buildFormControl( this.userData.profileImageFileContainerId ),
            phoneNumber:                 EditTextComponent.buildFormControl( this.userData.phoneNumber ),
            moduleGroups:                new UntypedFormControl( this.userData.moduleGroups, [ CustomValidators.containsNonEmptyArray ] ),
            wantsEmailNotifications:     new UntypedFormControl( this.userData.wantsEmailNotifications ),
        };

        this.form = new UntypedFormGroup( formDefinition as any );

        this.form.valueChanges.subscribe( ( formData: MyProfileForm ) => {
            if ( this.form.valid ) {
                const emitData: RegistrationUserData = {
                    profileImageFileContainerId: formData.profileImageFileContainerId as string | null,
                    profilePreviewImageId:       this.userData.profilePreviewImageId,
                    phoneNumber:                 formData.phoneNumber as string | null,
                    moduleGroups:                formData.moduleGroups as ModuleGroupInfo[],
                    preferredCulture:            null,
                    wantsEmailNotifications:     formData.wantsEmailNotifications as boolean,
                };

                this.dataChanged.emit( emitData );
            }
            else {
                this.dataChanged.emit( null );
            }
        } );
    }
}
