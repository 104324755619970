import { AfterContentChecked, ElementRef, OnDestroy, OnInit, Renderer2, Directive } from '@angular/core';


/**
 * Abstract base class used by the checkbox and radiobuttons directives.
 */
@Directive()
export abstract class BaseCheckboxRadiobuttonDirective implements OnInit, AfterContentChecked, OnDestroy {

    protected rspWrapperClass: string;
    protected rspWrapperClassOnChecked: string;
    protected inputElement: HTMLInputElement;

    private cssBaseName: string;
    private wrapperElement: HTMLDivElement;


    constructor(
        cssBaseName: string,
        inputElement: ElementRef,
        private renderer: Renderer2,
    ) {

        this.inputElement = inputElement.nativeElement;
        this.cssBaseName  = cssBaseName;
    }

    ngOnInit(): void {
        // build wrapper and wrap the original checkbox/radiobutton with it
        this.wrapperElement = this.renderer.createElement( 'div' );
        if ( this.rspWrapperClass ) {
            this.renderer.addClass( this.wrapperElement, this.rspWrapperClass );
        }
        this.renderer.addClass( this.wrapperElement, this.cssBaseName );
        this.renderer.insertBefore( this.inputElement.parentNode, this.wrapperElement, this.inputElement );
        this.renderer.appendChild( this.wrapperElement, this.inputElement );

        // add fake-checkbox/fake-radiobutton node
        const fakeElement: HTMLSpanElement = this.renderer.createElement( 'span' );
        this.renderer.addClass( fakeElement, `${this.cssBaseName}--fake-${this.cssBaseName}` );
        this.renderer.appendChild( this.wrapperElement, fakeElement );

        // apply new component CSS class to original checkbox/radiobutton
        this.renderer.addClass( this.inputElement, `${this.cssBaseName}--${this.cssBaseName}` );

        // init event handler for WrapperClassOnChecked feature
        if ( this.rspWrapperClassOnChecked ) {
            this.inputElement.addEventListener( 'click', this.setOnCheckedClassByElementState.bind( this ) );
        }
    }

    ngOnDestroy(): void {
        const wrapper: Node = this.inputElement.parentNode;
        const wrapperParent: Node = wrapper.parentNode;

        this.renderer.removeChild( wrapperParent, wrapper );
    }

    ngAfterContentChecked(): void {
        this.setOnCheckedClassByElementState();
    }

    private setOnCheckedClassByElementState(): void {
        if ( !this.rspWrapperClassOnChecked ) {
            return;
        }

        if ( this.inputElement.checked ) {
            this.renderer.addClass( this.wrapperElement, this.rspWrapperClassOnChecked );
        }
        else {
            this.renderer.removeClass( this.wrapperElement, this.rspWrapperClassOnChecked );
        }
    }
}
