<rsp-card>
    <rsp-card-headline>
        <span class="distribution-channel-own-retail--label">Own Retail</span>
        <rsp-edit-distribution-channel
            *ngIf="isEditMode"
            [myFormGroup]="myFormGroup.get('ownRetail')"
            (activeChanged)="isActive = $event"
        ></rsp-edit-distribution-channel>
        <input *ngIf="!isEditMode" rspCheckbox type="checkbox" [checked]="isActive" [disabled]="true">
    </rsp-card-headline>
    <rsp-card-content *ngIf="isActive">
        <div class="store-format-and-store-sub-format--store-format">
            <label class="store-format-and-store-sub-format--section-headline">Store Format</label>
            <div class="store-format-and-store-sub-format--section-content">
                <ng-container *ngIf="!isEditMode; else editStoreFormats">
                    <ng-container *ngIf="clusterDetails.distributionChannelStoreFormats == null || clusterDetails.distributionChannelStoreFormats.length === 0">
                        <rsp-not-available></rsp-not-available>
                    </ng-container>

                    <rsp-tags *ngIf="clusterDetails.distributionChannelStoreFormats.length" [tags]="getStoreFormatsAsTags()" [showEmptyText]="false">
                    </rsp-tags>
                </ng-container>

                <ng-template #editStoreFormats>
                    <rsp-display-edit-store-formats
                        [storeFormats]="clusterDetails.distributionChannelStoreFormats"
                        [myFormControl]="myFormGroup.get('ownRetail').get('storeFormatIds')"
                        [isEditMode]="true"
                    ></rsp-display-edit-store-formats>
                </ng-template>
            </div>
        </div>
        <div class="store-format-and-store-sub-format--store-sub-format">
            <label class="store-format-and-store-sub-format--section-headline">Store Sub-Format</label>
            <div class="store-format-and-store-sub-format--section-content">
                <ng-container *ngIf="!isEditMode; else editStoreSubFormats">
                    <ng-container *ngIf="clusterDetails.storeSubFormats == null || clusterDetails.storeSubFormats.length === 0">
                        <rsp-not-available></rsp-not-available>
                    </ng-container>

                    <rsp-tags *ngIf="clusterDetails.storeSubFormats.length" [tags]="getStoreSubFormatsAsTags()" [showEmptyText]="false">
                    </rsp-tags>
                </ng-container>

                <ng-template #editStoreSubFormats>
                    <rsp-display-edit-store-sub-formats
                        [storeSubFormats]="clusterDetails.storeSubFormats"
                        [myFormControl]="myFormGroup.get('ownRetail').get('storeSubFormatIds')"
                        [isEditMode]="true"
                    ></rsp-display-edit-store-sub-formats>
                </ng-template>
            </div>
        </div>
    </rsp-card-content>
</rsp-card>

