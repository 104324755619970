<div
    class="{{ cssModuleName }}"
    [class.is-facet-disabled]="facet.values.length === 0"
    [class.is-facet-expanded]="isExpanded"
>
    <div class="{{ cssModuleName }}--header" (click)="toggleFacetExpansion($event)">
        <h3 class="{{ cssModuleName }}--header-caption">
            <rsp-facet-name [facetName]="facet.name"></rsp-facet-name>
        </h3>

        <rsp-facet-summary
            [facet]="facet"
            class="{{ cssModuleName }}--header-summary"
        ></rsp-facet-summary>

        <button
            class="{{ cssModuleName }}--header-action"
            (click)="toggleFacetExpansion($event)"
            [@togglerRotated]="isExpanded"
            (@togglerRotated.done)="triggerVerticalScrollCalculation()"
            [disabled]="facet.values.length === 0"
        >
            <span class="{{ cssModuleName }}--header-action-assistive-content">Toggle</span>
        </button>
    </div>

    <div class="{{ cssModuleName }}--body" [ngSwitch]="facet.type" [@bodyExpanded]="isExpanded">
        <rsp-facet-type-singleselect
            *ngSwitchCase="facetType.Singleselect"
            [facet]="facet"
            (valueChanged)="onFacetValueChanged($event)"
        ></rsp-facet-type-singleselect>

        <rsp-facet-type-multiselect
            *ngSwitchCase="facetType.Multiselect"
            [facet]="facet"
            (valueChanged)="onFacetValueChanged($event)"
        ></rsp-facet-type-multiselect>

        <rsp-facet-type-multiselect-hierarchical
            *ngSwitchCase="facetType.MultiselectHierarchical"
            [facet]="facet"
            (valueChanged)="onFacetValueChanged($event)"
        ></rsp-facet-type-multiselect-hierarchical>

        <div *ngSwitchDefault style="background-color: hotpink; font-weight: bold">
            Don't know how to render facet of type <em>{{ facet.type }}</em>!
        </div>
    </div>
</div>
