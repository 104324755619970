import { Component, OnInit, OnDestroy, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription, of, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConceptService, } from '../concept.service';
import { ArticleManagementConceptsService } from '../../../../../shared/api';
import { ConceptDetail, } from '../../../../../shared/model/conceptDetail';
import { KeywordInfo } from '../../../../../shared/model/keywordInfo';
import { Tag, } from '../../../../../shared/ui/tags/tag';
import { NotificationService } from '../../../../../core/overlay/notification/notification.service';
import { ViewModelItemConceptDetail } from '../../../../../shared/model/viewModelItemConceptDetail';
import { CardType } from '../../../../../shared/ui/card/card.component';
import { UpdateConceptDetail } from '../../../../../shared/model/updateConceptDetail';
import { Reply } from '../../../../../shared/model/reply';
import { EditTextComponent } from '../../../../../shared/forms/controls/edit-text/edit-text.component';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { EditKeywordsComponent } from '../../../../../shared/forms/controls/edit-keywords/edit-keywords.component';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { CurrentUserContext } from '../../../../../shared/model';
import { SecondaryToolbarService } from '../../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';


@Component( {
    selector:    'rsp-concept-detail',
    templateUrl: './concept-details.component.html',
    styleUrls:   [
        '../../../../../shared/scss/04_layout/two-columns-1200.scss',
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './concept-details.component.scss',
    ],
} )
export class ConceptDetailsComponent implements OnInit, OnDestroy, HasLoadingIndicator {
    conceptDetails: ConceptDetail;
    isEditMode: boolean = false;
    form: UntypedFormGroup;

    cardType: any = CardType;

    subscription: Subscription;

    isLoadingSave: boolean = false;
    isLoading: Subscription;

    userHasEditRight: boolean;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private conceptsApi: ArticleManagementConceptsService,
        private conceptService: ConceptService,
        private toolbarService: SecondaryToolbarService,
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private currentUserService: CurrentUserService,
    ) {
    }


    ngOnInit(): void {
        this.currentUserService
            .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersConceptDetailsEdit )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( hasRight: boolean ) => {
                this.userHasEditRight = hasRight;
            } );

        // Two entry points:
        //  - of( true ) - first component load
        //  - this.pblService.summary$ - prev/next navigation
        merge( of( true ), this.conceptService.summary$ )
            .pipe(
                loadWithProgressIndicator( () => this.conceptsApi.conceptsGetDetail( this.conceptService.conceptId ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( viewModel: ViewModelItemConceptDetail ) => {
                this.initialize( viewModel );
            } );
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }


    startEditMode(): void {
        this.toolbarService.hidePrevNextNavigation();
        this.router.navigate( [ 'edit' ], { relativeTo: this.route } );
    }


    endEditMode(): void {
        this.toolbarService.showPrevNextNavigation();
        this.router.navigate( [ '../' ], { relativeTo: this.route } );
    }


    submitForm(): void {
        this.isLoadingSave = true;

        const submitData: UpdateConceptDetail = {
            conceptId:   this.conceptDetails.conceptId,
            brandId:     this.form.value.brandId,
            subbrandId:  this.form.value.subbrandId,
            description: this.form.value.description,
            note:        this.form.value.note,
            name:        this.form.value.name,
            keywordIds:  this.form.value.keywordIds,
        };

        this.isLoading =
            this.conceptsApi
                .conceptsUpdateDetails( this.conceptDetails.conceptId, submitData, )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( result: Reply ) => {
                    if ( result.isOk ) {
                        this.conceptService.getAndPublishSummary();
                        this.notificationService.success( 'Concept details have been saved successfully.' );
                        this.endEditMode();
                    }
                } );
    }


    getKeywordsAsTags(): Array<Tag> {

        if ( this.conceptDetails ) {

            const tags: Array<Tag> = [];

            this.conceptDetails
                .keywords
                .map( ( keyword: KeywordInfo ) => {
                    tags.push( new Tag( keyword.name ) );
                } );

            return tags;
        }

        return null;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private initialize( viewModel: ViewModelItemConceptDetail ): void {

        this.isEditMode = this.route.snapshot.data[ 'isEditMode' ] || false;

        this.conceptDetails = viewModel.data;

        if ( this.isEditMode ) {
            this.buildForm();
        }
    }


    private buildForm(): void {
        this.form = this.formBuilder.group( {
            note:        EditTextComponent.buildFormControl( this.conceptDetails.note ),
            name:        EditTextComponent.buildFormControl( this.conceptDetails.name, [ Validators.required ] ),
            description: EditTextComponent.buildFormControl( this.conceptDetails.description ),
            keywordIds:  EditKeywordsComponent.buildFormControl( this.conceptDetails.keywords ),
            brandId:     new UntypedFormControl( this.conceptDetails.brandInfo ? this.conceptDetails.brandInfo.id : null ),
            subbrandId:  new UntypedFormControl( this.conceptDetails.subBrandInfo ? this.conceptDetails.subBrandInfo.id : null ),
        } );
    }
}
