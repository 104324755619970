import { Component, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, } from '@angular/forms';

import { MessageType, MessagesCssSubmodule } from '../../../ui/messages/messages.component';
import { NOT_AVAILABLE } from '../../../ui/not-available/not-available.component';
import { EditTextComponent } from '../edit-text/edit-text.component';

/**
 * Displays text as label (display mode) and as input field (edit mode).
 * In edit mode, the result is added to given FormGroup as property with given name.
 *
 * @example
 *      <rsp-display-edit-text
 *          [myFormGroup]="myForm"
 *          [name]="myName"
 *          ...
 *      ></<rsp-display-edit-text>
 *
 *  myForm.value contains
 *
 *  {
 *      ...
 *      myName: ...,
 *  }
 *
 */
@Component( {
    selector:    'rsp-display-edit-text',
    templateUrl: './display-edit-text.component.html',
    styleUrls:   [
        './display-edit-text.component.scss',
    ],
} )
export class DisplayEditTextComponent {

    @Input() value: string;

    @ViewChild(EditTextComponent) editTextComponent: EditTextComponent;

    /**
     * Optional size of the input field (in number of chars).
     */
    @Input() size: number;

    @Input() myFormGroup: UntypedFormGroup;
    @Input() name: string;
    @Input() isEditMode: boolean;
    @Input() hideValidationMessages: boolean;
    /**
     * Specifies if "n/a" should be displayed when provided value is not empty (null|undefined) in the display mode.
     * @type {boolean}
     */
    @Input() showNotAvailable: boolean = true;
    /**
     * Specifies string which is displayed when given value is empty and [showNotAvailable] is true. Default 'n / a'.
     * @type {string}
     */
    @Input() notAvailableString: string = NOT_AVAILABLE;
    validationMessages: Array<string> = [];

    /**
     * Optional CSS Submodule for <rsp-messages>.
     */
    @Input() validationMessagesCssSubmodule: MessagesCssSubmodule;

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    /**
     * Optional string that is used as HTML `placeholder`-attribute on the input field.
     */
    @Input() placeholder: string;

    /**
     * Event that is triggered when the user pressed enter key while the focus was in the <input>-field.
     */
    @Output() enterPressed: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Event that is triggered when the user pressed the escape key while the focus was in the <input>-field.
     */
    @Output() escapePressed: EventEmitter<null> = new EventEmitter<null>();



    messageType: any = MessageType;


    focusInputField(): void {
        this.editTextComponent.inputField.nativeElement.focus();
    }

}
