import { Injectable, } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { ConceptSummary } from '../../../../shared/model/conceptSummary';
import { ArticleManagementConceptsService } from '../../../../shared/api';
import { ViewModelItemConceptSummary } from '../../../../shared/model/viewModelItemConceptSummary';
import { map } from 'rxjs/operators';


@Injectable()
export class ConceptService {

    summary$: Observable<ConceptSummary>;

    conceptId: string;


    private summary: Subject<ConceptSummary> = new Subject();


    constructor(
        private conceptApi: ArticleManagementConceptsService,
    ) {
        this.summary$ = this.summary.asObservable();
    }


    /**
     * Sets current Concept ID. This ID is used for all API calls.
     */
    setConceptId( conceptId: string ): void {
        this.conceptId = conceptId;
    }


    /**
     * Sets new Summary object and publishes #summary$ observable.
     */
    setSummary( summary: ConceptSummary ): void {
        this.summary.next( summary );
    }


    getSummary(): Observable<ConceptSummary> {

        if ( !this.conceptId ) {
            throw new Error( '#conceptId must be set!' );
        }

        return this.conceptApi
                   .conceptsGetSummary( this.conceptId )
                   .pipe(
                       map( ( result: ViewModelItemConceptSummary ) => {
                           return result.data;
                       } ),
                   );
    }


    /**
     * Gets Summary for current Concept. It also publishes the #summary$ observable.
     */
    getAndPublishSummary(): void {

        if ( !this.conceptId ) {
            throw new Error( '#conceptId must be set!' );
        }

        this.getSummary()
            .subscribe( ( result: ConceptSummary ) => {
                this.setConceptId( result.tile.id );
                this.setSummary( result );
            } );
    }
}
