import { Injectable, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, } from '@angular/router';
import { Observable ,  BehaviorSubject, Subject } from 'rxjs';
import { filter, takeWhile, takeUntil } from 'rxjs/operators';

import { BreadcrumbPart, } from './breadcrumb-part';


@Injectable()
export class BreadcrumbService implements OnDestroy {

    breadcrumbParts$: Observable<Array<BreadcrumbPart>>;

    private breadcrumbParts: BehaviorSubject<Array<BreadcrumbPart>> = new BehaviorSubject( [] );

    private parts: BreadcrumbPart[] = [];

    private isAutoRefreshingConfigured: boolean = false;

    private isAlive: boolean = true;
    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor( private router: Router ) {
        this.breadcrumbParts$ = this.breadcrumbParts.asObservable();
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    public addPart( part: BreadcrumbPart ): void {
        this.parts.push( part );

        this.breadcrumbParts.next( this.parts );
    }

    public replaceLastPart( part: BreadcrumbPart ): void {

        this.parts.pop();
        this.parts.push( part );

        this.breadcrumbParts.next( this.parts );
    }

    public replacePart( part: BreadcrumbPart, index: number ): void {
        this.parts[ index ] = part;
        this.breadcrumbParts.next( this.parts );
    }

    public addOrReplaceLastPart( part: BreadcrumbPart, maxPartsCount: number ): void {
        if ( this.parts.length >= maxPartsCount ) {
            while ( this.parts.length >= maxPartsCount ) {
                this.parts.pop();
            }
        }

        if ( this.parts.length < maxPartsCount ) {
            this.addPart( part );
        }
        else {
            this.replaceLastPart( part );
        }
    }

    public isConfigured(): boolean {
        return this.parts.length > 0;
    }

    /**
     * Activates breadcrumb auto refreshing. Uses router event to update breadcrumb.
     *
     * @param config - mapping between route's url and BreadcrumbPart that should be displayed.
     * @param maxPartsCount
     */
    public enableAutoRefreshing( config: { [ key: string]: BreadcrumbPart }, maxPartsCount: number ): void {

        // don't subscribe to this event more than ones
        if ( !this.isAutoRefreshingConfigured ) {

            this.isAutoRefreshingConfigured = true;

            this.router
                .events
                .pipe(
                    takeWhile( () => this.isAlive ),
                    filter( ( event: any ) => event instanceof NavigationEnd ),
                    takeUntil( this.isDestroyed ),
                )
                .subscribe( ( navigationEnd: NavigationEnd ) => {

                    const url: string = navigationEnd.urlAfterRedirects
                        ? navigationEnd.urlAfterRedirects
                        : navigationEnd.url;

                    const breadcrumbPart: BreadcrumbPart = this.getBreadcrumbPartFromConfig( url, config );

                    if ( breadcrumbPart ) {
                        this.addOrReplaceLastPart( breadcrumbPart, maxPartsCount );
                    }
                    else {
                        // TODO: warning
                    }
                } );
        }
    }

    public disableAutoRefreshing(): void {
        this.isAlive = false;
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private getBreadcrumbPartFromConfig( url: string, config: { [ key: string]: BreadcrumbPart } ): BreadcrumbPart {

        const splitted: Array<string> = url.split( '/' );
        let configKey: string         = '';

        do {
            // f.e. for url: components/edit/multi, searches for BreadcrumbPart using keys:
            // - multi
            // - edit/multi
            // - components/edit/multi

            if ( configKey === '' ) {
                configKey = splitted.pop();
            }
            else {
                configKey = splitted.pop() + '/' + configKey;
            }

            if ( config[ configKey ] ) {
                return config[ configKey ];
            }
        }
        while ( splitted.length > 0 );

        return null;
    }
}
