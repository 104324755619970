<textarea
    rspTextInput
    cdkTextareaAutosize
    cdkAutosizeMinRows="7"
    [rspTextInputWrapperClass]="'display-edit-text-multiline--textarea-wrapper'"
    [withClearButton]="true"
    [formControl]="myFormControl"
    [attr.id]="inputFieldHtmlId"
    [attr.placeholder]="placeholder"
    class="display-edit-text-multiline--textarea"

></textarea>
<rsp-messages
    *ngIf="!hideValidationMessages && validationMessages.length > 0"
    [type]="messageTypeError"
    [messages]="validationMessages"
></rsp-messages>

