import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { UserInfo } from '../../../model/userInfo';
import { UserManagementUsersService } from '../../../api';
import { ViewModelPagedListUserInfo } from '../../../model/viewModelPagedListUserInfo';
import { UserSuggestSearchConfiguration } from '../../../model/userSuggestSearchConfiguration';


/**
 * User Suggester - search users using specified search term.
 */
@Component( {
    selector:    'rsp-user-suggester',
    templateUrl: './user-suggester.component.html',
    styleUrls:   [
        './user-suggester.component.scss',
    ],
} )
export class UserSuggesterComponent {
    /**
     * Specify which types of users are searched (e.g. internal, external or both).
     */
    @Input() userTypes: UserSuggestSearchConfiguration.TypesEnum[] = [
        UserSuggestSearchConfiguration.TypesEnum.External,
        UserSuggestSearchConfiguration.TypesEnum.Internal,
    ];

    /**
     * Specify which state the user account must be in (e.g. active or inactive).
     */
    @Input() userStates: UserSuggestSearchConfiguration.StatesEnum[] = [
        UserSuggestSearchConfiguration.StatesEnum.Active,
    ];

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    /**
     * Text that will be used as placeholder attribute for the `<input>`-field.
     */
    @Input() placeholder: string = 'Search Users';

    /**
     * Text that will be used as "selected" value.
     */
    @Input() displayValue: string;

    /**
     * Optional list of ids to filter when searching.
     */
    @Input() ignoreIds: string[];

    @Output() selected: EventEmitter<UserInfo> = new EventEmitter<UserInfo>();


    items: Array<UserInfo> = [];
    isLoading: Subscription;

    constructor( private usersApi: UserManagementUsersService ) { }

    searchItems( searchTerm: string ): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }

        this.items = [];

        if ( !searchTerm || !searchTerm.trim() ) {
            searchTerm = '*';
        }

        const searchConfiguration: UserSuggestSearchConfiguration = {
            term:      searchTerm,
            page:      1,
            size:      50,
            types:     this.userTypes,
            states:    this.userStates,
            ignoreIds: this.ignoreIds ? this.ignoreIds : undefined,
        };

        this.isLoading =
            this.usersApi
                .usersSuggest( searchConfiguration )
                .subscribe( ( searchResult: ViewModelPagedListUserInfo ) => {
                    this.items = searchResult.data;
                } );
    }

    selectedItem( item: UserInfo ): void {
        this.selected.emit( item );
    }
}
