import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { CustomValidators } from '../../../../../../../shared/forms/validation/custom-validators';
import { EditTextComponent } from '../../../../../../../shared/forms/controls/edit-text/edit-text.component';
import { ArticleManagementConceptsService, } from '../../../../../../../shared/api';
import { ReplyGuid } from '../../../../../../../shared/model/replyGuid';
import { ConceptSuggestItem } from '../../../../../../../shared/model/conceptSuggestItem';


@Component( {
    selector:    'rsp-copy-concept-dialog',
    templateUrl: './copy-concept-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        './copy-concept-dialog.component.scss',
    ],
} )
export class CopyConceptDialogComponent implements OnInit, OnDestroy {
    @ViewChild( 'dialogHeaderTemplate', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContentTemplate', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooterTemplate', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    sourceConcept: ConceptSuggestItem | null = null;

    isLoading: Subscription;

    form: UntypedFormGroup;

    constructor(
        private articleManagementConceptsService: ArticleManagementConceptsService,
        private dialogService: DialogService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.form = new UntypedFormGroup( {
            sourceConceptId: new UntypedFormControl( null, [ Validators.required, ] ),

            // TODO: Needs a ConceptNameUnique validator.
            name: EditTextComponent.buildFormControl( null, [ Validators.required, CustomValidators.stringNotEmpty, ] ),
        } );
    }

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    setSourceConcept( concept: ConceptSuggestItem ): void {
        this.sourceConcept = concept;
        this.form.get( 'sourceConceptId' ).setValue( concept.id );
    }

    doAction(): void {
        if ( this.form.valid ) {
            this.isLoading =
                this.articleManagementConceptsService
                    .conceptsCopyConcept( this.sourceConcept.id, this.form.get( 'name' ).value )
                    .subscribe( ( result: ReplyGuid ) => {
                        this.dialogService.closeDialog();
                        this.router.navigate( [ '/concept', result.value, ] );
                    } );
        }
    }

    open(): void {
        // Component instance is reused, so every time we open the dialog, the state should be reset.
        this.sourceConcept = null;
        this.form.reset();

        this.dialogService.openDialog( {
            headlineTemplate: this.dialogHeaderTemplate,
            contentTemplate:  this.dialogContentTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }
}
