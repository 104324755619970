<rsp-details-page>
    <rsp-concept-cluster-tile
        [item]="clusterTileData"
        [linksToConceptOrCluster]="false"
    ></rsp-concept-cluster-tile>

    <div>
            <rsp-vertical-tabs [tabs]="tabs"></rsp-vertical-tabs>
    </div>
</rsp-details-page>
