<div>
    <span
        *ngFor="let experience of experiences"
        rspKeyword
        [withClearButton]="true"
        (clearClick)="removeExperience( experience )"
    >
        {{ experience.name }}
    </span>
</div>

<rsp-dropdown
    [placeholder]="'Select Experience'"
    [withSuggester]="true"
    [originalItems]="availableExperiences"
    (filteredItems)="setFilteredExperiences( $event )"
    (selected)="addExperience( $event )"
>
    <rsp-dropdown-item *ngFor="let experience of filteredExperiences" [item]="experience">
        {{ experience.name }}
    </rsp-dropdown-item>
</rsp-dropdown>
