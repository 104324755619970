<ng-container *ngIf="!isEditMode
                        && value !== null
                        && value !== undefined">{{ value }}</ng-container>

<ng-container *ngIf="!isEditMode
                        && ( value === null || value === undefined || value === '' )
                        && showNotAvailable">
    <rsp-not-available [notAvailable]="notAvailableString"></rsp-not-available>
</ng-container>

<rsp-edit-text
    *ngIf="isEditMode"
    [isEditMode]="true"
    [myFormControl]="myFormGroup?.get(name)"
    [size]="size"
    [inputFieldHtmlId]="inputFieldHtmlId"
    [hideValidationMessages]="hideValidationMessages"
    [placeholder]="placeholder"
    (enterPressed)="enterPressed.emit( $event )"
    (escapePressed)="escapePressed.emit( $event )"
>
</rsp-edit-text>

