import { ChangeDetectorRef, Component, } from '@angular/core';

import { ConceptClusterTileComponent } from '../concept-cluster-tile/concept-cluster-tile.component';
import { StorageService } from '../../../../../../core/storage.service';
import { ConceptClusterSelectionService } from '../../../shared/concept-cluster-selection.service';
import { ConceptClusterStoreService } from '../../../shared/concept-cluster-store.service';

@Component( {
    selector:    'rsp-concept-cluster-line',
    templateUrl: './concept-cluster-line.component.html',
    styleUrls:   [
        './concept-cluster-line.component.scss',
    ],
} )
export class ConceptClusterLineComponent extends ConceptClusterTileComponent {
    constructor(
        storageService: StorageService,
        conceptSelectionService: ConceptClusterSelectionService,
        conceptStoreService: ConceptClusterStoreService,
        changeDetectorRef: ChangeDetectorRef,
    ) {
        super( storageService, conceptSelectionService, conceptStoreService, changeDetectorRef );
    }
}
