import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { STORAGE_KEYS, StorageService } from '../../../../core/storage.service';

import { BaseListPageWithAlternativeViewsComponent } from '../../../../shared/ui/lists/base-list-page-with-alternative-views.component';
import { ViewSwitcherService } from '../../../../shared/ui/secondary-toolbar/view-switcher/view-switcher.service';
import { MainLoadingIndicatorService } from '../../../../core/main-loading-indicator/main-loading-indicator.service';
import { UserSelectionService } from '../shared/user-selection.service';
import { UserStoreService } from '../shared/user-store.service';
import { UserListItem } from '../../../../shared/model';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';

@Component( {
    selector:    'rsp-user-list-page',
    templateUrl: './user-list-page.component.html',
    styleUrls:   [ './user-list-page.component.scss' ],
} )
export class UserListPageComponent extends BaseListPageWithAlternativeViewsComponent<UserListItem> implements OnInit {
    get storageKeyIsSidebarVisible(): string { return STORAGE_KEYS.users.list.isSidebarVisible; }

    get storageKeyScrollToId(): string { return STORAGE_KEYS.users.list.scrollToId; }

    constructor(
        private toolbarService: SecondaryToolbarService,
        router: Router,
        activatedRoute: ActivatedRoute,
        mainLoadingIndicatorService: MainLoadingIndicatorService,
        selectionService: UserSelectionService,
        storageService: StorageService,
        storeService: UserStoreService,
        viewSwitcherService: ViewSwitcherService,
    ) {
        super(
            viewSwitcherService,
            router,
            activatedRoute,
            storeService,
            selectionService,
            storageService,
            mainLoadingIndicatorService,
        );

        // configure toolbar
        // NOTE: previously it was in ngOnInit, but it throws a ExpressionChangedAfterItHasBeenCheckedError exception.
        // We are not sure why it occurs ... maybe something with named outlet (where toolbar is placed) and change detection.
        // This issue occurs in angular > 4.2.5.
        // https://github.com/angular/angular/issues/17572
        this.toolbarService.showViewSwitcher();
        this.toolbarService.showStoreInfo();
        this.toolbarService.showSortBy();
        this.toolbarService.hidePrevNextNavigation();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }
}
