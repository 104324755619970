import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Observable, Subscription, merge, of, Subject } from 'rxjs';
import { distinctUntilChanged, switchMap, takeUntil, tap } from 'rxjs/operators';

import { NonTradeItemService, } from '../../shared/non-trade-item.service';
import { ArticleManagementArticlesService } from '../../../../../shared/api';
import { ArticleManagementAssembliesService } from '../../../../../shared/api';
import { CurrentNonTradeItem, } from '../../shared/current-non-trade-item.model';
import { NonTradeItemType, } from '../../shared/non-trade-item-type.model';
import { ViewModelListGraphicListItem } from '../../../../../shared/model/viewModelListGraphicListItem';
import { GraphicListItem } from '../../../../../shared/model/graphicListItem';
import { ArticleManagementGraphicsService } from '../../../../../shared/api';
import { FileKindInfo } from '../../../../../shared/model/fileKindInfo';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { NotificationService } from '../../../../../core/overlay/notification/notification.service';
import { CurrentUserContext } from '../../../../../shared/model';
import { SecondaryToolbarService } from '../../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';

interface AccordionItem {
    graphic: GraphicListItem;
    isReferencedGraphic: boolean;
    isOpen: boolean;
    isEditMode: boolean;
}

@Component( {
    selector:    'rsp-non-trade-item-graphics',
    templateUrl: './non-trade-item-graphics.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './non-trade-item-graphics.component.scss',
    ],
} )
export class NonTradeItemGraphicsComponent implements OnInit, OnDestroy {
    accordionItems: Array<AccordionItem> = [];

    isLoading: Subscription;

    itemType: NonTradeItemType;
    itemTypeString: string;

    uploadProcessing: boolean                     = false;
    fileKinds: Array<FileKindInfo>;

    currentItem: CurrentNonTradeItem;

    userHasEditRight: boolean;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private nonTradeItemService: NonTradeItemService,
        private toolbarService: SecondaryToolbarService,
        private articlesApi: ArticleManagementArticlesService,
        private assembliesApi: ArticleManagementAssembliesService,
        private graphicsApi: ArticleManagementGraphicsService,
        private currentUserService: CurrentUserService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {

        // Two entry points:
        //  - of( true ) - first component load
        //  - currentNonTradeItem$ - prev/next navigation
        merge( of( true ), this.nonTradeItemService.currentNonTradeItem$ )
            .pipe(
                distinctUntilChanged(),
                tap( () => this.currentItem = this.nonTradeItemService.getCurrentNonTradeItem() ),
                loadWithProgressIndicator( () => this.getListItemApiObservable(), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( itemContainer: ViewModelListGraphicListItem ) => {

                this.itemType       = this.currentItem.type;
                this.itemTypeString = this.nonTradeItemService.getItemTypeAsString( this.currentItem.type );

                if ( this.itemType === NonTradeItemType.Article ) {
                    this.currentUserService
                        .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesArticleGraphicsEdit )
                        .pipe( takeUntil( this.isDestroyed ) )
                        .subscribe( ( hasRight: boolean ) => {
                            this.userHasEditRight = hasRight;
                        } );
                }
                else {
                    this.currentUserService
                        .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyGraphicsEdit )
                        .pipe( takeUntil( this.isDestroyed ) )
                        .subscribe( ( hasRight: boolean ) => {
                            this.userHasEditRight = hasRight;
                        } );
                }

                this.convertGraphicItemsToAccordionItems( itemContainer.data );
            } );

        this.graphicsApi
            .graphicsGetFileKinds()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( result: Array<FileKindInfo> ) => {
                this.fileKinds = result;
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    removeGraphic( item: AccordionItem ): void {
        this.isLoading =
            this.graphicsApi
                .graphicsDeleteGraphic( item.graphic.id )
                .pipe(
                    switchMap( () => this.getListItemApiObservable() ),
                    takeUntil( this.isDestroyed ),
                )
                .subscribe( ( itemContainer: ViewModelListGraphicListItem ) => {
                    this.convertGraphicItemsToAccordionItems( itemContainer.data );
                    this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
                    this.notificationService.success( 'Graphic was deleted.', 'Deletion successful' );
                } );
    }

    setEditModeForGraphic( event: Event, item: AccordionItem ): void {
        if ( item.isReferencedGraphic ) {
            throw new Error( 'Can not edit referenced graphic!' );
        }

        event.stopPropagation();
        if ( !item.isEditMode && !item.graphic.id ) {
            this.accordionItems.pop();
            return;
        }

        item.isEditMode = !item.isEditMode;
        this.toolbarService.hidePrevNextNavigation();
    }

    endEditMode( event: Event, item: AccordionItem ): void {
        event.stopPropagation();
        item.isEditMode = false;

        if ( !item.graphic.id ) {
            this.accordionItems.pop();
        }
        this.toolbarService.showPrevNextNavigation();
    }

    createGraphic(): void {
        this.accordionItems.push( {
            graphic:             {
                visiblePrintArea: {
                    width:  null,
                    height: null,
                },
                graphicArea:      {
                    width:  null,
                    height: null,
                },
            },
            isReferencedGraphic: false,
            isEditMode:          true,
            isOpen:              true,
        } );

        this.toolbarService.hidePrevNextNavigation();
    }

    hasNewGraphic(): boolean {
        return this.accordionItems.filter( ( accordionItem: AccordionItem ) => !accordionItem.graphic.id ).length === 1;
    }


    savedSuccessful( item: AccordionItem, graphicId?: string ): void {
        this.graphicsApi.graphicsGetListItem( graphicId ? graphicId : item.graphic.id )
            .pipe( takeUntil( this.isDestroyed ) ).subscribe( ( result: GraphicListItem ) => {

            item.graphic.id        = result.id;
            item.graphic.name      = result.name;
            item.graphic.note      = result.note;
            item.graphic.thickness = result.thickness;

            item.graphic.material = {};
            if ( result.material ) {
                item.graphic.material.id   = result.material.id;
                item.graphic.material.name = result.material.name;
            }

            item.graphic.visiblePrintArea = {};
            if ( result.visiblePrintArea ) {
                item.graphic.visiblePrintArea.width  = result.visiblePrintArea.width;
                item.graphic.visiblePrintArea.height = result.visiblePrintArea.height;
            }

            item.graphic.graphicArea = {};
            if ( result.graphicArea ) {
                item.graphic.graphicArea.width  = result.graphicArea.width;
                item.graphic.graphicArea.height = result.graphicArea.height;
            }

            item.graphic.templateFile          = {};
            item.graphic.templateFile.fileKind = {};

            if ( result.templateFile ) {
                item.graphic.templateFile.name                 = result.templateFile.name;
                item.graphic.templateFile.previewImageId       = result.templateFile.previewImageId;
                item.graphic.templateFile.uploadedOn           = result.templateFile.uploadedOn;
                item.graphic.templateFile.id                   = result.templateFile.id;
                item.graphic.templateFile.fileKind.name        = result.templateFile.fileKind.name;
                item.graphic.templateFile.fileKind.id          = result.templateFile.fileKind.id;
                item.graphic.templateFile.fileKind.description = result.templateFile.fileKind.description;
            }
            else {
                delete item.graphic.templateFile;
            }
            item.isEditMode = false;

            this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
            this.notificationService.success( 'Graphic was saved.', 'Update successful' );
            this.toolbarService.showPrevNextNavigation();

        } );
    }

    private getListItemApiObservable(): Observable<ViewModelListGraphicListItem> {
        return this.currentItem.type === NonTradeItemType.Article
            ? this.articlesApi.articlesGetGraphicListItemsByArticleId( this.currentItem.id )
            : this.assembliesApi.assembliesGetAssemblyGraphics( this.currentItem.id );
    }

    private convertGraphicItemsToAccordionItems( items: GraphicListItem[] ): void {
        this.accordionItems = items.map( ( graphic: GraphicListItem ) => {
            return {
                graphic:             graphic,
                isReferencedGraphic: graphic.referencedObject && graphic.referencedObject.id !== this.currentItem.id,
                isEditMode:          false,
                isOpen:              false,
            };
        } );
    }

}
