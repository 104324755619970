import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseStoreService, StoreSearchConfiguration } from '../../../../shared/ui/lists/store/base-store.service';
import { UserManagementUsersService } from '../../../../shared/api';
import { UserListItem } from '../../../../shared/model';
import { ViewModelBrowsingListUserListItem } from '../../../../shared/model';


/**
 * Handles loading and caching for UserListItems.
 */
@Injectable()
export class UserStoreService extends BaseStoreService<UserListItem> {
    constructor(
        private userManagementUsersService: UserManagementUsersService,
    ) {
        super();
    }

    protected getId( item: UserListItem ): string {

        if ( !item ) {
            throw new Error( '[UserStoreService] item must not be null!' );
        }

        return item.id;
    }

    protected loadItemsFromBackend( request: StoreSearchConfiguration ): Subscription {
        return this.userManagementUsersService
                   .usersSearch( request )
                   .subscribe(
                       ( result: ViewModelBrowsingListUserListItem ) => {
                           super.setBackendResult(
                               result.data,
                               result.paging,
                               result.facets,
                               result.selectLists,
                               result.advancedSearchFilterTypeOperators,
                               result.advancedSearchFilterProperties,
                               result.advancedSearchFilterGroups,
                               result.availableSortings,
                               result.usedSortings,
                               result.quickSearchTerm,
                           );
                       },
                       () => {
                           super.unregisterLoadingObservable();
                       } );
    }
}
