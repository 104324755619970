import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { CustomValidators } from '../../../../../../../shared/forms/validation/custom-validators';
import { EditTextComponent } from '../../../../../../../shared/forms/controls/edit-text/edit-text.component';
import { ArticleManagementAssembliesService, } from '../../../../../../../shared/api/index';
import { NonTradeSuggestItem } from '../../../../../../../shared/model/nonTradeSuggestItem';
import { AssemblyInfo } from '../../../../../../../shared/model/assemblyInfo';
import { NonTradeItemSuggestSearchConfiguration } from '../../../../../../../shared/model/nonTradeItemSuggestSearchConfiguration';
import { NonTradeItemService } from '../../../../shared/non-trade-item.service';
import { CurrentNonTradeItem } from '../../../../shared/current-non-trade-item.model';
import { NonTradeItemType } from '../../../../shared/non-trade-item-type.model';


@Component( {
    selector:    'rsp-copy-assembly-dialog',
    templateUrl: './copy-assembly-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        './copy-assembly-dialog.component.scss',
    ],
} )
export class CopyAssemblyDialogComponent implements OnInit, OnDestroy {
    @ViewChild( 'dialogHeaderTemplate', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContentTemplate', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooterTemplate', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    sourceAssembly: NonTradeSuggestItem;
    nonTradeItemSuggestType: NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum = NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum.Assembly;

    isLoading: Subscription;

    form: UntypedFormGroup;

    constructor(
        private articleManagementAssembliesService: ArticleManagementAssembliesService,
        private dialogService: DialogService,
        private nonTradeItemService: NonTradeItemService,
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.form = new UntypedFormGroup( {
            sourceAssemblyId: new UntypedFormControl( null, [ Validators.required, ] ),
            name:             EditTextComponent.buildFormControl( null, [ Validators.required, CustomValidators.stringNotEmpty, ] ),
        } );

        this.nonTradeItemService
            .currentNonTradeItem$
            .subscribe( ( nonTradeItem: CurrentNonTradeItem ) => {
                if ( nonTradeItem && nonTradeItem.type === NonTradeItemType.Assembly ) {
                    this.form.get( 'sourceAssemblyId' ).setValue( nonTradeItem.id );
                    this.sourceAssembly = {
                        id:     nonTradeItem.id,
                        name:   nonTradeItem.name,
                        number: nonTradeItem.number,
                        type:   nonTradeItem.type as any,
                    };
                }
                else {
                    this.reset();
                }
            } );
    }

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    setSourceAssembly( assembly: NonTradeSuggestItem ): void {
        this.sourceAssembly = assembly;
        this.form.get( 'sourceAssemblyId' ).setValue( assembly.id );
    }

    doAction(): void {
        if ( this.form.valid ) {
            this.isLoading =
                this.articleManagementAssembliesService
                    .assembliesCopyAssembly( this.sourceAssembly.id, this.form.get( 'name' ).value )
                    .subscribe( ( result: AssemblyInfo ) => {
                        this.close();
                        this.router.navigate( [ '/assembly', result.number, ] );
                    } );
        }
    }

    open(): void {
        this.dialogService.openDialog( {
            headlineTemplate: this.dialogHeaderTemplate,
            contentTemplate:  this.dialogContentTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
        this.reset();
    }

    private reset(): void {
        this.sourceAssembly = null;
        this.form.reset();
    }
}
