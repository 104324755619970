<div class="tooltip">
    <div
        *ngIf="arrowPosition"
        class="tooltip--arrow is-{{ arrowPosition.edge }}-arrow"
        [style.left]="arrowPosition.x + 'px'"
    ></div>

    <div class="tooltip--content">
        <ng-container
            *ngIf="data?.displayedType === 'string'"
        >
            {{ data?.stringToDisplay }}
        </ng-container>

        <ng-container
            *ngIf="data?.displayedType === 'template'"
        >
            <div [cdkPortalOutlet]="portalInstance"></div>
        </ng-container>
    </div>
</div>
