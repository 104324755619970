<div class="tab">
    <div *ngIf="!isLoading || isLoading.closed"
         class="tab--toolbar"
    >
        <rsp-form-buttons
            *ngIf="changeAction.length > 0"
            [isEditMode]="isEditMode"
            [myFormGroup]="form"
            [disablePrimaryEditButton]="isLoadingSave"
            [actions]="changeAction"
            (actionButtonClick)="startEditMode()"
            (primaryEditButtonClick)="submitForm()"
            (secondaryEditButtonClick)="endEditMode()"
            (dropDownActionClicked)="triggerAction( $event )"
            class="tab--toolbar-item is-right"
        ></rsp-form-buttons>
    </div>

    <div class="tab--content" [rspLoadingIndicator]="isLoading">

        <div *ngIf="articleDetails || assemblyDetails">
            <form *ngIf="isEditMode && changeAction.length > 0; else viewmode;" autocomplete="off">
                <ng-container *ngTemplateOutlet="details"></ng-container>
            </form>

            <ng-template #viewmode>
                <ng-container *ngTemplateOutlet="details"></ng-container>
            </ng-template>
        </div>

        <ng-template #details>

            <!-- ARTICLE -->
            <ng-container *ngIf="isArticle()">

                <!-- Name -->
                <div *ngIf="isEditMode" class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Name</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text
                                        [value]="articleDetails.name"
                                        [myFormGroup]="form"
                                        [isEditMode]="true"
                                        [name]="'name'"
                                        [inputFieldHtmlId]="htmlIdFor.nameField"
                                    ></rsp-display-edit-text>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>


                <!-- Note -->
                <div *ngIf="isEditMode || articleDetails.note" class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card [type]="cardType.Note">
                                <rsp-card-headline><label [for]="htmlIdFor.noteField">Note</label></rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text-multiline
                                        [value]="articleDetails.note"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                        [name]="'note'"
                                        [inputFieldHtmlId]="htmlIdFor.noteField"
                                    ></rsp-display-edit-text-multiline>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>

                <!-- Description -->
                <div *ngIf="isEditMode || articleDetails.description" class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <!-- Description -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline><label [for]="htmlIdFor.descriptionField">Description</label>
                                </rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text-multiline
                                        [value]="articleDetails.description"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                        [name]="'description'"
                                        [inputFieldHtmlId]="htmlIdFor.descriptionField"
                                    ></rsp-display-edit-text-multiline>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>

                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--column">

                        <!-- Additional Article Numbers -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>
                                    <label [for]="htmlIdFor.legacyArticleNumberField">
                                        Legacy Article Number
                                    </label>
                                </rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text
                                        [value]="articleDetails.legacyNumber"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode && articlePermissions.canEditLegacyNumber"
                                        [name]="'legacyArticleNumber'"
                                        [notAvailableString]="'-'"
                                        [validationMessagesCssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                        [inputFieldHtmlId]="htmlIdFor.legacyArticleNumberField"
                                    ></rsp-display-edit-text>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Packaging Unit -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Packaging Unit</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-number
                                        [value]="articleDetails.packagingUnit"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                        [name]="'packagingUnit'"
                                        [inputFieldHtmlId]="htmlIdFor.packagingUnit"
                                    ></rsp-display-edit-number>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Capacities -->
                        <div *ngIf="isEditMode || ( !isEditMode && articleDetails.needsCapacity )"
                             class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Capacities</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-capacities
                                        [capacities]="articleDetails.capacities"
                                        [needsCapacity]="articleDetails.needsCapacity"
                                        [isEditMode]="isEditMode"
                                        [myFormGroup]="form?.get('capacities')"
                                    ></rsp-display-edit-capacities>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Engineering Partner-->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>
                                    <label [for]="htmlIdFor.engineeringPartnerName">
                                        Engineering Partner
                                    </label>
                                </rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text
                                        [value]="articleDetails.engineeringPartnerName"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                        [name]="'engineeringPartnerName'"
                                        [notAvailableString]="'-'"
                                        [validationMessagesCssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                        [inputFieldHtmlId]="htmlIdFor.engineeringPartnerField"
                                    ></rsp-display-edit-text>
                                </rsp-card-content>
                            </rsp-card>



                        </div>
                    </div>

                    <div class="l-two-columns-1200--column">

                        <!-- Applied Brand Logo -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline><label [for]="htmlIdFor.appliedBrandLogoField">Applied Brand
                                    Logo</label></rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-options
                                        [myFormControl]="form?.get('appliedBrandLogoId')"
                                        [isEditMode]="isEditMode"
                                        [defaultOption]="articleDetails.appliedBrandLogo"
                                        [placeholder]="'Select Brand Logo'"
                                        [options]="allAppliedBrandLogos"
                                        [inputFieldHtmlId]="htmlIdFor.appliedBrandLogoField"
                                    ></rsp-display-edit-options>
                                    <rsp-messages
                                        *ngIf="isEditMode && !articleDetails.appliedBrandLogo"
                                        [type]="messageType.Info"
                                        [messages]="[ 'Applied Brand Logo needs to be specified in order to activate the article.' ]"
                                        [cssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-messages>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Category -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline><label [for]="htmlIdFor.categoryField">Category</label></rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-options
                                        [myFormControl]="form?.get('categoryId')"
                                        [isEditMode]="isEditMode"
                                        [defaultOption]="{ id: articleDetails.category, name: articleDetails.category, disabled: false }"
                                        [placeholder]="'Select Category'"
                                        [options]="allCategories"
                                        [inputFieldHtmlId]="htmlIdFor.categoryField"
                                    ></rsp-display-edit-options>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Keywords -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card-keywords
                                [spmGroup]="articleDetails.spmGroup"
                                [spmType]="articleDetails.spmType"
                                [keywords]="articleDetails.keywords"
                                [isEditMode]="isEditMode"
                                [myFormGroup]="form"
                                [isSPMGroupAndTypeEditable]="isSPMGroupAndTypeEditable"
                            >
                            </rsp-card-keywords>
                        </div>

                        <!-- Subcomponents -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Subcomponents</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-subcomponents
                                        [subComponents]="articleDetails.subComponents"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                    ></rsp-display-edit-subcomponents>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>

                </div>

            </ng-container>

            <!-- ASSEMBLY -->
            <ng-container *ngIf="isAssembly()">
                <!-- Name -->
                <div *ngIf="isEditMode" class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Name</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text
                                        [value]="assemblyDetails.name"
                                        [myFormGroup]="form"
                                        [isEditMode]="true"
                                        [name]="'name'"
                                        [inputFieldHtmlId]="htmlIdFor.nameField"
                                    ></rsp-display-edit-text>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>


                <!-- Note -->
                <div *ngIf="isEditMode || assemblyDetails.note" class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card [type]="cardType.Note">
                                <rsp-card-headline>Note</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text-multiline
                                        [value]="assemblyDetails.note"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                        [name]="'note'"
                                    ></rsp-display-edit-text-multiline>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>

                <!-- Description -->
                <div *ngIf="isEditMode || assemblyDetails.description" class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <!-- Description -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Description</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text-multiline
                                        [value]="assemblyDetails.description"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                        [name]="'description'"
                                    ></rsp-display-edit-text-multiline>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>

                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--column">

                        <!-- Capacities -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Capacities</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-not-available *ngIf="!assemblyDetails.capacities?.length"></rsp-not-available>

                                    <ul *ngIf="assemblyDetails.capacities?.length">
                                        <li *ngFor="let capacity of assemblyDetails.capacities">
                                            {{ capacity.pieces }} {{ capacity.type }}
                                        </li>
                                    </ul>

                                    <rsp-messages
                                        *ngIf="isEditMode"
                                        [type]="messageType.Info"
                                        [messages]="[ 'Capacities are calculated from the capacities of the assembly components and can not be edited directly.' ]"
                                        [cssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-messages>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                    </div>

                    <div class="l-two-columns-1200--column">
                        <!-- Keywords -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card-keywords
                                [spmGroup]="assemblyDetails.spmGroup"
                                [spmType]="assemblyDetails.spmType"
                                [keywords]="assemblyDetails.keywords"
                                [isEditMode]="isEditMode"
                                [myFormGroup]="form"
                                [isSPMGroupAndTypeEditable]="false"
                            >
                            </rsp-card-keywords>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- ARTICLE - Dimensions -->
            <ng-container *ngIf="isArticle()">
                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--column">

                        <!-- Assembled Dimensions -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Assembled Dimensions <abbr
                                    title="Width &times; Depth &times; Height">(W &times; D &times; H)</abbr>
                                </rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-width-depth-height
                                        [myFormGroup]="form?.get('assembledDimensions')"
                                        [isEditMode]="isEditMode"
                                        [width]="articleDetails.assembledDimensions?.width"
                                        [height]="articleDetails.assembledDimensions?.height"
                                        [depth]="articleDetails.assembledDimensions?.depth"
                                        [validationMessagesCssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-display-edit-width-depth-height>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Space required (assembled) -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Space required (assembled)</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-number [myFormGroup]="form"
                                                             [value]="articleDetails.assembledDimensions?.space"></rsp-display-edit-number>
                                    <rsp-unit-square-meter
                                        *ngIf="articleDetails.assembledDimensions?.space"
                                    ></rsp-unit-square-meter>
                                    <rsp-messages
                                        *ngIf="isEditMode"
                                        [type]="messageType.Info"
                                        [messages]="[ 'This value is calculated from the Assembled Dimensions field and can not be edited directly.' ]"
                                        [cssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-messages>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Weight (assembled) -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card-weight
                                [weightType]="'assembled'"
                                [weight]="articleDetails.assembledDimensions?.weight"
                                [isEditMode]="isEditMode"
                                [myFormGroup]="form"
                                [inputFieldHtmlId]="htmlIdFor.weightAssembledField"
                            ></rsp-card-weight>
                        </div>
                    </div>

                    <div class="l-two-columns-1200--column">

                        <!-- Packed Dimensions -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Packed Dimensions <abbr title="Width &times; Depth &times; Height">(W
                                    &times; D &times; H)</abbr></rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-width-depth-height
                                        [myFormGroup]="form?.get('packedDimensions')"
                                        [isEditMode]="isEditMode"
                                        [width]="articleDetails.packedDimensions?.width"
                                        [height]="articleDetails.packedDimensions?.height"
                                        [depth]="articleDetails.packedDimensions?.depth"
                                        [validationMessagesCssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-display-edit-width-depth-height>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Volume (packed) -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Volume (packed)</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-number [myFormGroup]="form"
                                                             [value]="articleDetails.packedDimensions?.volume"></rsp-display-edit-number>
                                    <rsp-unit-cubic-meter
                                        *ngIf="articleDetails.packedDimensions?.volume"></rsp-unit-cubic-meter>
                                    <rsp-messages
                                        *ngIf="isEditMode"
                                        [type]="messageType.Info"
                                        [messages]="[ 'This value is calculated from the Packed Dimensions field and can not be edited directly.' ]"
                                        [cssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-messages>
                                </rsp-card-content>
                            </rsp-card>
                        </div>

                        <!-- Weight (packed) -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card-weight
                                [weightType]="'packed'"
                                [weight]="articleDetails.packedDimensions?.weight"
                                [isEditMode]="isEditMode"
                                [myFormGroup]="form"
                                [inputFieldHtmlId]="htmlIdFor.weightPackedField"
                            ></rsp-card-weight>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- ASSEMBLY - Dimensions -->
            <ng-container *ngIf="isAssembly()">

                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--column">

                        <!-- Assembled Dimensions -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Assembled Dimensions <abbr
                                    title="Width &times; Depth &times; Height">(W &times; D &times; H)</abbr>
                                </rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-width-depth-height
                                        [myFormGroup]="form?.get('assembledDimensions')"
                                        [isEditMode]="isEditMode"
                                        [width]="assemblyDetails.assembledDimensions?.width"
                                        [height]="assemblyDetails.assembledDimensions?.height"
                                        [depth]="assemblyDetails.assembledDimensions?.depth"
                                        [validationMessagesCssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-display-edit-width-depth-height>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>

                    <div class="l-two-columns-1200--column">
                        <!-- Space required (assembled) -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Space required (assembled)</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-number [myFormGroup]="form"
                                                             [value]="assemblyDetails.assembledDimensions?.requiredSpace"></rsp-display-edit-number>
                                    <rsp-unit-square-meter
                                        *ngIf="assemblyDetails.assembledDimensions?.requiredSpace"></rsp-unit-square-meter>
                                    <rsp-messages
                                        *ngIf="isEditMode"
                                        [type]="messageType.Info"
                                        [messages]="[ 'This value is calculated from the Assembled Dimensions field and can not be edited directly.' ]"
                                        [cssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-messages>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>

                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--column">
                        <!-- Weight (packed) -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Weight (packed)</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-number [myFormGroup]="form"
                                                             [value]="assemblyDetails.assembledDimensions?.packedWeight"></rsp-display-edit-number>
                                    <rsp-unit-kilogram
                                        *ngIf="assemblyDetails.assembledDimensions?.packedWeight !== null"></rsp-unit-kilogram>
                                    <rsp-messages
                                        *ngIf="isEditMode"
                                        [type]="messageType.Info"
                                        [messages]="[ 'Weight is calculated from the weight of the assembly components and can not be edited directly.' ]"
                                        [cssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-messages>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>

                    <div class="l-two-columns-1200--column">
                        <!-- Weight (assembled) -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Weight (assembled)</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-number [myFormGroup]="form"
                                                             [value]="assemblyDetails.assembledDimensions?.assembledWeight"></rsp-display-edit-number>
                                    <rsp-unit-kilogram
                                        *ngIf="assemblyDetails.assembledDimensions?.assembledWeight !== null"></rsp-unit-kilogram>
                                    <rsp-messages
                                        *ngIf="isEditMode"
                                        [type]="messageType.Info"
                                        [messages]="[ 'Weight is calculated from the weight of the assembly components and can not be edited directly.' ]"
                                        [cssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-messages>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- ARTICLE - Materials, Customs Tariff Number -->
            <ng-container *ngIf="isArticle()">
                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--column">

                        <!-- Materials -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Materials</rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-materials
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                        [materials]="articleDetails.materials"
                                        [materialInfo]="articleDetails.materialInfo"
                                        [materialListMessagesCssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-display-edit-materials>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>

                    <div class="l-two-columns-1200--column">

                        <!-- Customs Tariff Number -->
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline><label [for]="htmlIdFor.customsTariffNumberField">Customs Tariff
                                    Number</label></rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text
                                        [value]="articleDetails.customsTariffNumber"
                                        [myFormGroup]="form"
                                        [isEditMode]="isEditMode"
                                        [size]="20"
                                        [validationMessagesCssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                        [inputFieldHtmlId]="htmlIdFor.customsTariffNumberField"
                                        name="customsTariffNumber"
                                    ></rsp-display-edit-text>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- ASSEMBLY - Customs Tariff Number -->
            <ng-container *ngIf="isAssembly()">

                <!-- Customs Tariff Number -->
                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>Customs Tariff Numbers</rsp-card-headline>
                                <rsp-card-content>
                                    <span *ngIf="!assemblyDetails.customsTariffNumbers?.length">
                                        <rsp-not-available></rsp-not-available>
                                    </span>
                                    {{ assemblyDetails.customsTariffNumbers | joined: ', '}}

                                    <rsp-messages
                                        *ngIf="isEditMode"
                                        [messages]="[ 'Custom tariff numbers are calculated from the custom tariff numbers of the assembly components and can not be edited directly.' ]"
                                        [type]="messageType.Info"
                                        [cssSubmodule]="messagesCssSubmodule.CardOwnLine"
                                    ></rsp-messages>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- ARTICLE - Concept & Cluster -->
            <ng-container *ngIf="isArticle()">
                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card-concept-and-cluster
                                [conceptsAndClusters]="articleDetails.conceptsAndClusters"
                                [currentItemId]="currentItem.id"
                                [currentItemType]="currentItem.type"
                            ></rsp-card-concept-and-cluster>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- ASSEMBLY - Concept & Cluster -->
            <ng-container *ngIf="isAssembly()">
                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card-concept-and-cluster
                                [conceptsAndClusters]="assemblyDetails.conceptsAndClusters"
                                [currentItemId]="currentItem.id"
                                [currentItemType]="currentItem.type"
                            ></rsp-card-concept-and-cluster>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="isArticle()">

                <!-- Required Files -->
                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--span-column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card-required-files
                                [requiredFiles]="articleDetails.requiredFiles"
                                [isEditMode]="isEditMode"
                                [myFormControl]="form?.get( 'requiredFiles' )"
                            ></rsp-card-required-files>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </div>
</div>

<rsp-set-non-trade-item-status-action></rsp-set-non-trade-item-status-action>
