import { Injectable } from '@angular/core';
import CollatorOptions = Intl.CollatorOptions;

import { DateTimeInfo } from '../../date-time/model/dateTimeInfo.model';

/**
 * Contains sorters (sort compare functions) which can be used with CollectionView.
 */
@Injectable()
export class StandardSorters {

    /**
     * Default sort function. Used automatically when <rsp-column-header> is used in the template.
     * Following types are supported:
     * - string (case insensitive)
     * - number
     * - boolean
     * - Date (format comparable)
     * @param value1
     * @param value2
     * @param ascending
     * @returns {number}
     */
    default( value1: any, value2: any, ascending: boolean ): number {

        // null and undefined
        if ( ( value1 === undefined || value1 === null ) && ( value2 !== undefined && value2 !== null ) ) {
            return ascending ? -1 : 1;
        }

        if ( ( value1 !== undefined && value1 !== null ) && ( value2 === undefined || value2 === null ) ) {
            return ascending ? 1 : -1;
        }

        if ( ( value1 === undefined || value1 === null ) && ( value2 === undefined || value2 === null ) ) {
            return 0;
        }


        // string
        if ( typeof value1 === 'string' && typeof value2 === 'string' ) {
            return this.compareStrings( value1, value2, ascending );
        }

        // number
        if ( typeof value1 === 'number' && typeof value2 === 'number' ) {
            return this.compareNumbers( value1, value2, ascending );
        }

        // mixed number and string
        if ( ( typeof value1 === 'number' || typeof value1 === 'string' ) && ( typeof value1 !== typeof value2 ) ) {
            let isNumberValue1: boolean = typeof value1 === 'number';
            let isNumberValue2: boolean = typeof value2 === 'number';

            if ( !isNumberValue1 ) {
                isNumberValue1 = !(isNaN( +value1 ) || +value1 <= 0);
            }

            if ( !isNumberValue2 ) {
                isNumberValue2 = !(isNaN( +value2 ) || +value2 <= 0);
            }

            if ( isNumberValue1 && isNumberValue2 ) {
                return this.compareNumbers( +value1, +value2, ascending );
            } else {
                return this.compareStrings( value1.toString(), value2.toString(), ascending );
            }
        }

        // boolean
        if ( typeof value1 === 'boolean' ) {
            return this.compareBooleans( value1, value2, ascending );
        }

        // Date
        if ( value1 instanceof Date ) {
            return this.compareDates( value1, value2, ascending );
        }

        // Array
        if ( Array.isArray( value1 ) ) {
            return this.compareArrayLengths( value1, value2, ascending );
        }

        return 0;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private compareNumbers( value1: number, value2: number, ascending: boolean ): number {

        if ( ascending ) {
            return value1 - value2;
        }

        // descending
        return value2 - value1;
    }

    private compareStrings( value1: string, value2: string, ascending: boolean ): number {

        const options: CollatorOptions = {
            // caseFirst: 'lower',
        };

        if ( ascending ) {
            return value1.localeCompare( value2, undefined, options );
        }

        return value2.localeCompare( value1, undefined, options );
    }

    private compareBooleans( value1: boolean, value2: boolean, ascending: boolean ): number {

        if ( value1 === value2 ) {
            return 0;
        }

        if ( ascending ) {
            // true before false
            return value1 ? -1 : 1;
        }

        // descending
        // false before true
        return value1 ? 1 : -1;

    }

    private compareDates( value1: Date, value2: Date, ascending: boolean ): number {

        const dateTime1: string = new DateTimeInfo( value1 ).getAsComparable( true, true );
        const dateTime2: string = new DateTimeInfo( value2 ).getAsComparable( true, true );

        return this.compareStrings( dateTime1, dateTime2, ascending );
    }

    private compareArrayLengths( value1: Array<any>, value2: Array<any>, ascending: boolean ): number {
        return this.compareNumbers( value1.length, value2.length, ascending );
    }
}
