<header class="master-head">

    <button type="button"
            class="master-head--main-menu-button"
            (click)="openMainMenu()"
            title="Menu"
    ></button>

    <a
        [routerLink]="[ '/' ]"
        class="master-head--logo-wrapper"
    >
        <img
            src="/assets/rsp_logo_landscape.svg"
            alt="Retail Sourcing Platform"
            class="master-head--logo-wrapper"
        />
    </a>
    <div class="master-head--version">{{ version }}</div>

    <div class="master-head--toolbar-container">

        <div class="master-head--primary-toolbar">

            <rsp-global-search *ngIf="currentUser"></rsp-global-search>

            <rsp-button
                *ngIf="currentUser"
                [href]="[ '/my-profile' ]"
                [submodule]="'seamless'"
                title="My Profile"
                class="master-head--primary-toolbar-item"
            >
                <rsp-user-profile-image
                    [user]="currentUser"
                    [showTooltip]="false"
                ></rsp-user-profile-image>
            </rsp-button>

            <a href="mailto:rsp.support@adidas.com" title="HELP">
                <i class="master-head--primary-toolbar-item_question-mark"></i>
            </a>

        </div>

        <div class="master-head--secondary-toolbar">
            <div class="master-head--secondary-toolbar-content">
                <ng-content></ng-content>
            </div>
        </div>

    </div>

</header>
