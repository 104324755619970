import { Component, OnDestroy, OnInit, Input, ViewChild, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router, } from '@angular/router';
import { Subscription, of, merge, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil, map } from 'rxjs/operators';

import { NonTradeItemService, } from '../../shared/non-trade-item.service';
import { ArticleManagementAssembliesService } from '../../../../../shared/api';
import { AssemblyComponent, } from '../../../../../shared/model/assemblyComponent';
import { Component as SubmitComponent, } from '../../../../../shared/model/component';
import { NonTradeSuggestItem } from '../../../../../shared/model/nonTradeSuggestItem';
import { ViewModelListAssemblyComponent, } from '../../../../../shared/model/viewModelListAssemblyComponent';
import { ComponentListItem, } from './component-list-item.model';
import { NonTradeItemAssemblyService, } from './non-trade-item-assembly.service';
import { NotificationService, } from '../../../../../core/overlay/notification/notification.service';
import { EntityTypeEnum } from '../../../../../shared/model/enum/entity-type.enum';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { CurrentUserContext } from '../../../../../shared/model';
import { SecondaryToolbarService } from '../../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';


@Component( {
    selector:    'rsp-non-trade-item-assembly-components',
    templateUrl: './non-trade-item-assembly-components.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './non-trade-item-assembly-components.component.scss',
    ],
    providers:   [ NonTradeItemAssemblyService ],
} )
export class NonTradeItemAssemblyComponentsComponent implements OnInit, OnDestroy {

    @Input() isMultiEditMode: boolean = false;
    @Input() isEditAllowed: boolean   = true;

    @ViewChild( NgForm, { static: true } ) componentForm: NgForm;

    isLoading: Subscription;

    isEditMode: boolean             = false;
    statusTypeEnum: EntityTypeEnum  = EntityTypeEnum.NonTradeItem;
    items: Array<ComponentListItem> = [];

    saveSubscription: Subscription;

    userHasEditRight: boolean;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private nonTradeItemService: NonTradeItemService,
        private assembliesApi: ArticleManagementAssembliesService,
        private notificationService: NotificationService,
        private nonTradeItemAssemblyService: NonTradeItemAssemblyService,
        private toolbarService: SecondaryToolbarService,
        private router: Router,
        private route: ActivatedRoute,
        private currentUserService: CurrentUserService,
    ) {
    }

    ngOnInit(): void {

        if ( !this.nonTradeItemService.getCurrentNonTradeItem().isAssembly() ) {
            throw Error( 'This page is available only for assembly.' );
        }

        this.currentUserService
            .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyComponentsEdit )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( hasRight: boolean ) => {
                this.userHasEditRight = hasRight;
            } );

        // Two entry points:
        //  - of( true ) - first component load
        //  - currentNonTradeItem$ - prev/next navigation
        merge( of( true ), this.nonTradeItemService.currentNonTradeItem$ )
            .pipe(
                distinctUntilChanged(),
                loadWithProgressIndicator(
                    () => this.assembliesApi
                              .assembliesGetComponents( this.nonTradeItemService.getCurrentId() )
                              .pipe(
                                  map( ( result: ViewModelListAssemblyComponent ) => result.data ),
                                  map( ( items: Array<AssemblyComponent> ) => {
                                      return items.map(
                                          ( assemblyComponent: AssemblyComponent ) =>
                                              ComponentListItem.createFromAssemblyComponent( assemblyComponent ),
                                      );
                                  } ),
                              ),
                    this,
                ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( items: Array<ComponentListItem> ) => {
                this.items      = items;
                this.isEditMode = this.route.snapshot.data[ 'isEditMode' ];
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    startEdit(): void {
        this.toolbarService.hidePrevNextNavigation();
        this.navigateToComponentsEdit();
    }


    saveComponents(): void {
        const components: SubmitComponent[] = [];
        this.items.forEach( ( item: ComponentListItem ) => {

            if ( item.newQuantity > 0 ) {
                components.push( {
                    articleId: item.articleId,
                    quantity:  +item.newQuantity,
                } );
            }
        } );

        this.saveSubscription =
            this.assembliesApi
                .assembliesUpdateComponents( this.nonTradeItemService.getCurrentId(), components )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {

                        // saved
                        // if ( this.isMultiEditMode ) {
                        //     this.nonTradeItemAssemblyComponentsMultiEditService.setCurrentTargetItemAsDone();
                        //     this.navigateToNextAssemblyFromTheMultiEditListOrBackToTheList();
                        // }
                        // else {
                        this.toolbarService.showPrevNextNavigation();
                        this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
                        this.notificationService.success( 'Component list saved.' );
                        this.navigateToComponentsDetails();
                        // }
                    },
                );
    }

    removeComponent( item: ComponentListItem ): void {
        const index: number = this.items.findIndex( ( old: ComponentListItem ) => old.articleId === item.articleId );

        if ( index !== -1 ) {
            this.items.splice( index, 1 );

            this.componentForm.form.markAsTouched();
            this.componentForm.form.markAsDirty();
        }
    }


    cancelEdit(): void {
        this.toolbarService.showPrevNextNavigation();
        this.navigateToComponentsDetails();

        // if ( this.isMultiEditMode ) {
        //     this.nonTradeItemAssemblyComponentsMultiEditService.setCurrentTargetItemAsCancelled();
        //     this.navigateToNextAssemblyFromTheMultiEditListOrBackToTheList();
        // }
        // else {
        // }
    }


    onNonTradeItemSuggestionSelected( item: NonTradeSuggestItem ): void {
        if ( item ) {

            this.componentForm.form.markAsTouched();
            this.componentForm.form.markAsDirty();

            switch ( item.type ) {
                case NonTradeSuggestItem.TypeEnum.Article:
                    const newComponent: ComponentListItem = ComponentListItem.createFromNonTradeSuggestItem( item );

                    this.nonTradeItemAssemblyService.addComponentToComponentList( newComponent, this.items );


                    break;

                case NonTradeSuggestItem.TypeEnum.Assembly:
                    this.nonTradeItemAssemblyService.addAssemblyToComponentList( item.id, this.items );

                    break;

                default:
                    throw new Error( 'Unrecognized Type for Suggest Item' );
            }
        }
    }

    private navigateToComponentsDetails(): void {
        // navigate to assembly/:no/components
        this.router.navigate( [ '../', ], { relativeTo: this.route, } );
    }

    private navigateToComponentsEdit(): void {
        // navigate to assembly/:no/components/edit
        this.router.navigate( [ 'edit', ], { relativeTo: this.route, } );
    }


    // private mergeComponentsForMultiEdit(): void {
    //
    //     const actionType: string = this.nonTradeItemAssemblyComponentsMultiEditService.actionType;
    //
    //     this.nonTradeItemAssemblyComponentsMultiEditService
    //         .sourceItems
    //         .forEach( ( item: NonTradeListItem ) => {
    //
    //             switch ( item.type ) {
    //
    //                 // Article
    //                 case NonTradeSuggestItem.TypeEnum.Article:
    //
    //                     // - add
    //                     if ( actionType === 'add' ) {
    //
    //                         const newComponent: ComponentListItem = ComponentListItem.createFromNonTradeListItem( item );
    //
    //                         this.nonTradeItemAssemblyService.addComponentToComponentList(
    //                             newComponent,
    //                             this.componentItemsWijmo,
    //                         );
    //                     }
    //                     // - remove
    //                     else if ( actionType === 'remove' ) {
    //                         this.nonTradeItemAssemblyService.setComponentAsDeletedInComponentList(
    //                             item.id,
    //                             this.componentItemsWijmo );
    //                     }
    //
    //                     break;
    //
    //                 // Assembly
    //                 case NonTradeSuggestItem.TypeEnum.Assembly:
    //
    //                     // - add
    //                     if ( actionType === 'add' ) {
    //                         this.nonTradeItemAssemblyService.addAssemblyToComponentList(
    //                             item.id,
    //                             this.componentItemsWijmo,
    //                         );
    //                     }
    //                     // - remove
    //                     else if ( actionType === 'remove' ) {
    //                         this.nonTradeItemAssemblyService.setAssemblyComponentsAsDeletedInComponentList(
    //                             item.id,
    //                             this.componentItemsWijmo,
    //                         );
    //                     }
    //
    //                     break;
    //
    //                 default:
    //                     throw new Error( 'Unsupported type: ' + item.type );
    //             }
    //         } );
    // }
    //
    // private navigateToNextAssemblyFromTheMultiEditListOrBackToTheList(): void {
    //
    //     const link: Array<string>
    //             = this.nonTradeItemAssemblyComponentsMultiEditService.getNextNavigationLink();
    //
    //     if ( link ) {
    //         this.router.navigate( link );
    //     }
    //     else {
    //         this.router.navigate( [ '/article', 'list', ] );
    //     }
    // }
}
