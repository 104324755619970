import { Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';

import { FacetValueChangedEventData, } from './facet/facet.model';
import { FacetService,                                  } from './facet/facet.service';
import { VerticalScrollService } from '../lists/vertical-scroll/vertical-scroll.service';
import { FacetItem } from '../../model/facetItem';
import { FacetFilterItem } from '../../model/facetFilterItem';
import { AdvancedSearchFilterTypeOperator } from '../../model/advancedSearchFilterTypeOperator';
import { AdvancedSearchFilterProperty } from '../../model/advancedSearchFilterProperty';
import { AdvancedSearchFilterGroup } from '../../model/advancedSearchFilterGroup';
import { AdvancedSearchService } from './advanced-search/advanced-search.service';
import { SelectListItem } from '../../model';

/**
 * Prints a list of facets and manages them.
 * It also adds a special facet at the beginning which summarizes all the chosen facet values from the other facets.
 *
 * ```html
 * <rsp-filters
 *     facets="[FacetItem]"
 *     isHidden="false"
 *     (facetValuesChanged)="onFacetValuesChanged( $event )"
 *     (advancedSearchValuesChanged)="onAdvancedSearchValuesChanged( $event )"
 * >
 * ```
 */
@Component( {
    selector:    'rsp-filters',
    templateUrl: './filters.component.html',
    styleUrls:   [
        './filters.component.scss',
    ],
} )
export class FiltersComponent implements OnChanges {
    @Output() facetValuesChanged: EventEmitter<FacetFilterItem[]>                    = new EventEmitter<FacetFilterItem[]>();
    @Output() advancedSearchValuesChanged: EventEmitter<AdvancedSearchFilterGroup[]> = new EventEmitter<AdvancedSearchFilterGroup[]>();
    @Output() selectListsChanged: EventEmitter<SelectListItem[]>                     = new EventEmitter<SelectListItem[]>();

    @Input() set facets( facets: Array<FacetItem> ) {

        if ( !facets ) {
            return;
        }

        this._facets = this.sortFacets( facets );

        this.buildFacetFilterValueForFacetByFacets( facets );
        this.verticalScrollService.refreshScrollCalculation();
    }
    get facets(): Array<FacetItem> {
        return this._facets;
    }

    @Input()
    selectLists: Array<SelectListItem>;

    /**
     * contains all types and their possible operators
     */
    @Input() advancedSearchFilterTypeOperators: AdvancedSearchFilterTypeOperator[];

    /**
     * contains all possible filters
     */
    @Input() advancedSearchFilterProperties: AdvancedSearchFilterProperty[];

    /**
     * contains current filter values
     */
    @Input() advancedSearchFilterGroups: AdvancedSearchFilterGroup[];

    @Input() isHidden: boolean = false;

    facetService: FacetService;

    facetOrderForFacetName: { [ facetName: string ]: number } = {
        // Article & Assemblies
        'Brands':                10,
        'Sub-Brands':            20,
        'Categories':            30,
        'Store Formats':         40,
        'Distribution Channels': 50,
        'Concept & Clusters':    60,
        'SPM Classifications':   70,
        'Keywords':              80,
        'Account (Specific)':    90,
        'Country (Specific)':   100,
        'Styles & Artworks':    110,
        'States':               120,
        'Types':                130,

        // Concept & Cluster
        'Experiences':          100,
        'Countries':            110,
        'Brand':                120,
        'Subbrand':             130,
        'Store Formats.':        140,
        'Store Subformats':     150,
        'Retail Segmentations': 160,
        'Wholesalers':          170,
        'Franchisees':          180,

        // Orders
        'Tasks':                 10,

        // General
        'Modification Date':    1000,
        'Updates':              1000,
        'Creation Date':        2000,

    };

    showAdvancedSearch: boolean = false;

    private _facets: Array<FacetItem> = [];


    private facetFilterforFacetName: { [ facetName: string ]: FacetFilterItem } = {};


    constructor(
        facetService: FacetService,
        private verticalScrollService: VerticalScrollService,
        private advancedSearchService: AdvancedSearchService,
    ) {
        this.facetService = facetService;
    }

    ngOnChanges(): void {
        if ( !this.advancedSearchFilterGroups ) {
            this.advancedSearchFilterGroups = []; // convert null to [] so the child components don't have to check for array type all the time.
        }
    }

    /**
     * Takes over the given FacetFilterValues from a <rsp-facet>-component and emits all FacetFilterValues.
     * Eventually already existing FacetFilterValues that were not given given to this function are left untouched.
     */
    onFacetValueChanged( eventData: FacetValueChangedEventData ): void {
        if ( eventData.facetFilter === null ) {
            delete this.facetFilterforFacetName[ eventData.facetName ];
        }
        else {
            this.facetFilterforFacetName[ eventData.facetName ] = eventData.facetFilter;
        }

        // convert facetFilterforFacetName in an array of facetFilters.
        const facetFilters: FacetFilterItem[] = [];
        Object.keys( this.facetFilterforFacetName ).forEach( ( facetName: string ) => {
            facetFilters.push( this.facetFilterforFacetName[ facetName ] );
        } );

        this.facetValuesChanged.emit( facetFilters );
    }

    onSelectListsChanged( value: string, item: SelectListItem ): void {
        item.selectedValue = value;

        this.selectListsChanged.emit( this.selectLists );
    }

    onAdvancedSearchValueChanged( eventData: AdvancedSearchFilterGroup[] ): void {
        this.advancedSearchValuesChanged.emit( eventData );
    }

    toggleAdvancedSearchVisibility(): void {
        this.showAdvancedSearch = !this.showAdvancedSearch;
    }

    hideAdvancedSearch(): void {
        this.showAdvancedSearch = false;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    // Takes a complete list of all facets, converts their selected values into FacetFilterValues and stores them into the internal state var
    // `this.facetFilterforFacetName`.
    // This is needed for when the input facets were manipulated from the outside (for example, when someone makes changes via the chosenFacetValues).
    private buildFacetFilterValueForFacetByFacets( facets: Array<FacetItem> ): void {
        this.facetFilterforFacetName = {};

        const facetFiltersForGivenFacets: Array<FacetFilterItem> = this.facetService.getFacetFiltersForFacets( facets );

        facetFiltersForGivenFacets.forEach( ( facetFilterForGivenFacet: FacetFilterItem ) => {
            this.facetFilterforFacetName[ facetFilterForGivenFacet.name ] = facetFilterForGivenFacet;
        } );
    }

    private sortFacets( facets: Array<FacetItem> ): Array<FacetItem> {
        const sortedFacets: Array<FacetItem> =
                facets.sort( ( a: FacetItem, b: FacetItem ) => {
                        const aSortNumber: number = this.facetOrderForFacetName[ a.name ] || 999;
                        const bSortNumber: number = this.facetOrderForFacetName[ b.name ] || 999;

                        if ( aSortNumber < bSortNumber ) {
                            return -1;
                        }
                        if ( aSortNumber > bSortNumber ) {
                            return 1;
                        }
                        return 0;
                    },
                );

        return sortedFacets;
    }
}
