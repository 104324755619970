<ng-template #headline>
    <h2 class="standard-dialog--headline">Set Status to {{this.statusName}}</h2>
</ng-template>

<ng-template #content>
    <div class="standard-dialog--content" [rspLoadingIndicator]="isLoading">
        <rsp-edit-text
            [myFormControl]="form.get( 'reason' )"
            [placeholder]="'Reason for change'"
        ></rsp-edit-text>
    </div>
</ng-template>

<ng-template #footer>
    <div class="standard-dialog--button" [rspLoadingIndicator]="isLoading">
        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            (clicked)="doAction()"
            [disabled]="!form.valid || !!isLoading"
            style="margin-left: 1em"
        >
            Set Status
        </rsp-button>
    </div>
</ng-template>
