import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Observable, merge, of } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { NonTradeItemService, } from '../../shared/non-trade-item.service';
import { ArticleManagementArticlesService } from '../../../../../shared/api';
import { ArticleManagementAssembliesService } from '../../../../../shared/api';
import { ViewModelListFileContainerWithReference } from '../../../../../shared/model/viewModelListFileContainerWithReference';
import { Reply } from '../../../../../shared/model/reply';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { CurrentUserContext, FileContainerWithReference, FileKindItem } from '../../../../../shared/model';
import { SecondaryToolbarService } from '../../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { FilesComponent } from '../../../../../shared/ui/files/files.component';

@Component( {
    selector:    'rsp-non-trade-item-files',
    templateUrl: '../../../../../shared/ui/files/files.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        '../../../../../shared/ui/files/files.component.scss',
    ],
} )
export class NonTradeItemFilesComponent extends FilesComponent implements OnInit, OnDestroy, HasLoadingIndicator {

    itemIsAssembly: boolean;

    constructor(
        private nonTradeItemService: NonTradeItemService,
        private articlesApi: ArticleManagementArticlesService,
        private assembliesApi: ArticleManagementAssembliesService,
        protected currentUserService: CurrentUserService,
        protected toolbarService: SecondaryToolbarService,
    ) {
        super(
            currentUserService,
            toolbarService,
            FileKindItem.AreasEnum.ArticlesAndAssemblies,
            'NonTradeItem',
            'Article / Assembly',
        );
    }

    fileAdded( newFileContainer: FileContainerWithReference ): void {
        const fileIds: string[] = this.files
                                      .filter( ( fileContainer: FileContainerWithReference ) => {
                                          return fileContainer.referencedObject.id === this.nonTradeItemService.getCurrentId() &&
                                              fileContainer.fileKind.name.indexOf('Sub-Component Drawing *.') === -1;
                                      } )
                                      .map( ( file: FileContainerWithReference ) => file.id );
        fileIds.push( newFileContainer.id );

        this.updateFiles( fileIds );
    }


    fileRemoved( fileId: string ): void {
        const index: number = this.files.findIndex( ( file: FileContainerWithReference ) => file.id === fileId );

        if ( index !== -1 ) {
            this.files.splice( index, 1 );

            this.updateFiles( this.files
                                  .filter( ( fileContainer: FileContainerWithReference ) => {
                                      return fileContainer.referencedObject.id === this.nonTradeItemService.getCurrentId() &&
                                          fileContainer.fileKind.name.indexOf('Sub-Component Drawing *.') === -1;
                                  } ).map( ( file: FileContainerWithReference ) => file.id ) );
        }
    }

    protected load(): void {

        // Two entry points:
        //  - of( true ) - first component load
        //  - currentNonTradeItem$ - prev/next navigation
        merge( of( true ), this.nonTradeItemService.currentNonTradeItem$ )
            .pipe(
                distinctUntilChanged(),
                loadWithProgressIndicator( () => this.loadFiles( this.nonTradeItemService.getCurrentId() ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: ViewModelListFileContainerWithReference ) => {

                this.itemTypeString = this.nonTradeItemService.getCurrentNonTradeItem().typeString;
                this.itemIsAssembly = this.nonTradeItemService.getCurrentNonTradeItem().isAssembly();
                this.referenceType = this.itemIsAssembly ? 'Assembly' : null;

                if ( this.nonTradeItemService.getCurrentNonTradeItem().isArticle() ) {
                    this.currentUserService
                        .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesArticleFilesEdit )
                        .pipe( takeUntil( this.isDestroyed ) )
                        .subscribe( ( hasRight: boolean ) => {
                            this.userHasEditRight = hasRight;
                        } );
                }
                else {
                    this.currentUserService
                        .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyFilesEdit )
                        .pipe( takeUntil( this.isDestroyed ) )
                        .subscribe( ( hasRight: boolean ) => {
                            this.userHasEditRight = hasRight;
                        } );
                }

                this.files = result.data;
            } );
    }

    protected updateFiles( fileIds: string[] ): void {
        const fileApi: Observable<Reply> =
                  this.nonTradeItemService.getCurrentNonTradeItem().isArticle()
                      ? this.articlesApi.articlesUpdateFileContainers( this.nonTradeItemService.getCurrentId(), fileIds )
                      : this.assembliesApi.assembliesUpdateFileContainers( this.nonTradeItemService.getCurrentId(), fileIds );

        this.updatingFiles = fileApi.pipe( takeUntil( this.isDestroyed ) )
                                    .subscribe( () => {
                                        this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
                                    } );
    }

    private loadFiles( id: string ): Observable<ViewModelListFileContainerWithReference> {

        return this.nonTradeItemService.getCurrentNonTradeItem().isArticle()
            ? this.articlesApi.articlesGetArticleFiles( id )
            : this.assembliesApi.assembliesGetAssemblyFiles( id );
    }
}
