/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { AssemblyCapacity } from './assemblyCapacity';
import { AssemblyDimensions } from './assemblyDimensions';
import { ConceptAndCluster } from './conceptAndCluster';
import { KeywordInfo } from './keywordInfo';
import { SPMGroupInfo } from './sPMGroupInfo';
import { SPMTypeInfo } from './sPMTypeInfo';


export interface AssemblyDetail {
    id?: string;

    number?: string;

    name?: string;

    status?: AssemblyDetail.StatusEnum;

    note?: string;

    description?: string;

    capacities?: Array<AssemblyCapacity>;

    distributionChannels?: Array<string>;

    conceptsAndClusters?: Array<ConceptAndCluster>;

    spmGroup?: SPMGroupInfo;

    spmType?: SPMTypeInfo;

    keywords?: Array<KeywordInfo>;

    assembledDimensions?: AssemblyDimensions;

    customsTariffNumbers?: Array<string>;

}

export namespace AssemblyDetail {
    'use strict';
    export enum StatusEnum {
        Unpublished,
        Development,
        ActiveInPreRollout,
        ActiveInRollout,
        ActiveOnDemand,
        Discontinue,
        Inactive,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

