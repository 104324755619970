<div class="display-edit-user-profile-image">
    <rsp-user-profile-image
        [user]="user"
        [showClearButton]="isEditMode"
        (cleared)="clearFileContainer()"
        class="display-edit-user-profile-image--profile-image"
    ></rsp-user-profile-image>

    <rsp-upload-single-file
        *ngIf="isEditMode"
        [fileKinds]="[ profileImageFileKind ]"
        [validFileTypeGroup]="'images'"
        [inputFieldHtmlId]="inputFieldHtmlId"
        (fileUpdated)="onFileChange( $event )"
        class="display-edit-user-profile-image--profile-image-upload"
    >
        <ng-content></ng-content>
    </rsp-upload-single-file>
</div>
