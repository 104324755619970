import { Component, Input, OnChanges, ViewChild } from '@angular/core';

import { FileContainerWithReference } from '../../../../shared/model/fileContainerWithReference';
import { FileExportsDialogComponent } from './file-exports-dialog/file-exports-dialog.component';

@Component( {
    selector:    'rsp-file-exports',
    templateUrl: './file-exports.component.html',
    styleUrls:   [ './file-exports.component.scss' ],
} )
export class FileExportsComponent implements OnChanges {
    @ViewChild( FileExportsDialogComponent, { static: true } ) fileExportsDialog: FileExportsDialogComponent;
    @Input() files: FileContainerWithReference[] = [];
    @Input() type: 'Extract' | 'Export';

    visibleFiles: FileContainerWithReference[] = [];
    readonly showMoreThreshold: number         = 2;

    ngOnChanges(): void {
        this.files
            .sort( ( aArticleExtract: FileContainerWithReference, bArticleExtract: FileContainerWithReference ) => {
                if ( aArticleExtract.uploadedOn.getTime() < bArticleExtract.uploadedOn.getTime() ) {
                    return -1;
                }

                if ( aArticleExtract.uploadedOn.getTime() > bArticleExtract.uploadedOn.getTime() ) {
                    return 1;
                }

                return 0;
            } )
            .reverse();

        this.visibleFiles = ( this.files.length > this.showMoreThreshold + 1 )
            ? this.files.slice( 0, this.showMoreThreshold )
            : this.files;
    }

    showAll(): void {
        this.fileExportsDialog.open();
    }
}
