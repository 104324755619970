<div class="edit-spm-group-and-type--group">
    <label [for]="htmlIdFor.spmGroupField" class="edit-spm-group-and-type--section-headline">
        <abbr title="Single Product Master">SPM</abbr> Group:
    </label>
    <div class="edit-spm-group-and-type--section-content">
        <rsp-dropdown
            [defaultValue]="spmGroupName"
            [placeholder]="'Select SPM Group'"
            [inputFieldHtmlId]="htmlIdFor.spmGroupField"
            [withSuggester]="true"
            [originalItems]="spmGroupOptions"
            (filteredItems)="setFilteredSpmGroupOptions( $event )"
            (selected)="setGroup( $event )"
        >
            <rsp-dropdown-item *ngFor="let groupOption of filteredSpmGroupOptions" [item]="groupOption">
                {{ groupOption.name }}
            </rsp-dropdown-item>
        </rsp-dropdown>
    </div>
</div>

<div class="edit-spm-group-and-type--type">
    <label [for]="htmlIdFor.spmTypeField" class="edit-spm-group-and-type--section-headline">
        <abbr title="Single Product Master">SPM</abbr> Type:
    </label>
    <div class="edit-spm-group-and-type--section-content">
        <rsp-dropdown
            [defaultValue]="spmTypeName"
            [placeholder]="'Select SPM Type'"
            [disabled]="!spmGroupName"
            [inputFieldHtmlId]="htmlIdFor.spmTypeField"
            [withSuggester]="true"
            [originalItems]="spmTypeOptions"
            (filteredItems)="setFilteredSpmTypeOptions( $event )"
            (selected)="setType( $event )"
        >
            <rsp-dropdown-item *ngFor="let typeOption of filteredSpmTypeOptions" [item]="typeOption">
                {{ typeOption.name }}
            </rsp-dropdown-item>
        </rsp-dropdown>
    </div>
</div>
