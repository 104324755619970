/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface ReferencedObject {
    id?: string;

    type?: ReferencedObject.TypeEnum;

    displayValue?: string;

}

export namespace ReferencedObject {
    'use strict';
    export enum TypeEnum {
        Assembly,
        Article,
        Cluster,
        Concept,
        Company,
        ITService,
        PriceBasedLayout,
        SubComponent,
        DistributionCenter,
        User,
        Location,
        Document,
        AdidasSupport,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
}

