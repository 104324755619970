<!--todo loading-->
<rsp-card *ngIf="clusterDetails">
    <rsp-card-headline>Distribution Channels</rsp-card-headline>
    <rsp-card-content>
        <rsp-display-edit-own-retail
            [clusterDetails]="clusterDetails"
            [myFormGroup]="myFormGroup"
            [isEditMode]="isEditMode"
        >
        </rsp-display-edit-own-retail>

        <rsp-display-edit-wholesale
            [clusterDetails]="clusterDetails"
            [myFormGroup]="myFormGroup"
            [isEditMode]="isEditMode"
        >
        </rsp-display-edit-wholesale>

        <rsp-display-edit-franchise
            [clusterDetails]="clusterDetails"
            [myFormGroup]="myFormGroup"
            [isEditMode]="isEditMode"
        ></rsp-display-edit-franchise>

    </rsp-card-content>
</rsp-card>
<rsp-messages
    *ngIf="!hideValidationMessages && validationMessages.length > 0"
    [type]="messageTypeError"
    [messages]="validationMessages"
></rsp-messages>

