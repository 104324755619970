<rsp-card>
    <rsp-card-headline>
        <span class="distribution-channel-franchise--label">Franchise</span>
        <rsp-edit-distribution-channel
            *ngIf="isEditMode"
            [myFormGroup]="myFormGroup.get('franchise')"
            (activeChanged)="isActive = $event"
        ></rsp-edit-distribution-channel>
        <input *ngIf="!isEditMode" rspCheckbox type="checkbox" [checked]="isActive" [disabled]="true">
    </rsp-card-headline>
    <rsp-card-content></rsp-card-content>
</rsp-card>
