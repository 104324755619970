import { AnimationTriggerMetadata, animate, state, style, transition, trigger, } from '@angular/animations';

const animationDuration: string = '0.1s';

export const listPageAnimations: Array<AnimationTriggerMetadata> = [
    trigger( 'fadeInOut', [
        state( 'in',   style( { opacity: 1, } ) ),
        state( 'void', style( { opacity: 0, } ) ),
        transition( ':enter', [ style( { opacity: 0 } ), animate( animationDuration + ' linear' ), ], ),
        transition( ':leave', [ style( { opacity: 1 } ), animate( animationDuration + ' linear' ), ], ),
    ] ),
];
