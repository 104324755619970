import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortingItem } from '../../../model';

import { differenceBy } from 'lodash';

@Component( {
    selector:    'rsp-sort-by',
    templateUrl: './sort-by.component.html',
    styleUrls:   [ './sort-by.component.scss' ],
} )
export class SortByComponent {
    @Input()
    set availableSorting( value: string[] ) { this.setSorting( value ); }
    get availableSorting(): string[] { return this._availableSorting; }

    @Input()
    set activeSorting( value: SortingItem[] ) { this.updateSorting( value ); }
    get activeSorting(): SortingItem[] { return this._activeSorting; }

    @Output() selectedSorting: EventEmitter<SortingItem[]> = new EventEmitter<SortingItem[]>();

    ascending: SortingItem.SortingOrderEnum  = SortingItem.SortingOrderEnum.Ascending;
    descending: SortingItem.SortingOrderEnum = SortingItem.SortingOrderEnum.Descending;

    activatedSorting: SortingItem[];
    unusedSorting: SortingItem[];

    sortingChanged: boolean = false;

    private initialActiveSorting: SortingItem[];
    private _availableSorting: string[];
    private _activeSorting: SortingItem[];

    resetSorting(): void {
        // invoke setter to reinitialize ordered sorting
        this.availableSorting = this._availableSorting;
        this.selectedSorting.emit( null );
    }

    setOrder( item: SortingItem, direction: SortingItem.SortingOrderEnum ): void {
        if ( item.sortingOrder === direction ) {
            item.sortingOrder = null;

            const index: number = this.activatedSorting.findIndex( ( sortItem: SortingItem ) => item.name === sortItem.name );
            this.activatedSorting.splice( index, 1 );

            this.unusedSorting.push( item );
        }
        else if ( !item.sortingOrder || item.sortingOrder !== direction ) {
            item.sortingOrder = direction;

            const index: number = this.unusedSorting.findIndex( ( sortItem: SortingItem ) => item.name === sortItem.name );

            if ( index !== -1 ) {
                this.unusedSorting.splice( index, 1 );

                this.activatedSorting.push( item );
            }
        }
        this.updateSortingChanged();
    }

    activateSorting(): void {
        this.selectedSorting.emit( this.activatedSorting );
    }

    moveUp( item: SortingItem ): void {
        const index: number = this.activatedSorting.findIndex( ( sortItem: SortingItem ) => item.name === sortItem.name );

        if ( index !== -1 ) {
            const newIndex: number = index - 1;

            if ( newIndex >= 0 ) {
                this.moveItem( index, newIndex );
            }
        }
    }

    moveDown( item: SortingItem ): void {
        const index: number = this.activatedSorting.findIndex( ( sortItem: SortingItem ) => item.name === sortItem.name );

        if ( index !== -1 ) {
            const newIndex: number = index + 1;

            if ( newIndex <= this.activatedSorting.length ) {
                this.moveItem( index, newIndex );
            }
        }
    }

    private moveItem( from: number, to: number ): void {
        this.activatedSorting.splice( to, 0, this.activatedSorting.splice( from, 1 )[0] );
        this.updateSortingChanged();
    }

    private setSorting( availableSorting: string[] ): void {
        this._availableSorting = availableSorting;

        this.unusedSorting = availableSorting.map( ( name: string ) => {
            return {
                name:         name,
                sortingOrder: null,
            };
        } );

        this.activatedSorting = [];
    }

    private updateSorting( sorting: SortingItem[] ): void {
        if ( sorting && sorting.length ) {
            this.unusedSorting        = differenceBy( this.unusedSorting, sorting, 'name' );
            this.activatedSorting     = sorting;
            this.initialActiveSorting = JSON.parse( JSON.stringify( sorting ) );
            this.updateSortingChanged();
        }
    }

    private updateSortingChanged(): void {
        this.sortingChanged = false;

        if ( !this.initialActiveSorting || this.initialActiveSorting.length !== this.activatedSorting.length ) {
            this.sortingChanged = true;
        }
        else {
            this.activatedSorting.forEach( ( item: SortingItem, index: number ) => {
                const initialItem: SortingItem = this.initialActiveSorting[ index ];

                if ( !initialItem || item.name !== initialItem.name || item.sortingOrder !== initialItem.sortingOrder ) {
                    this.sortingChanged = true;
                }
            } );
        }

    }
}
