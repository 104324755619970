<ul class="{{ cssModuleName }}">

    <li class="facet-type-select-quick-search" *ngIf="showFilter">
        <input
            rspTextInput
            [rspTextInputWrapperClass]="'facet-type-select-quick-search-wrapper'"
            [withClearButton]="true"
            [placeholder]="'Quick Search'"
            [leftIcon]="'magnifier'"
            [(ngModel)]="filterString"
            (onClear)="filterFacets()">
    </li>
    <li class="{{ cssModuleName }}--item" *ngIf="areBottomFacetValuesVisible && bottomFacetValues.length > 0">
        <div class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level1' : '' }}-show-more">
            <button
                class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level1' : '' }}-show-more-caption"
                type="button"
                (click)="showBottomFacetValues( false )"
            >
                Show less
            </button>
        </div>
    </li>

    <!-- Base template for list items (facet lines) -->
    <ng-template #facetValueTemplateRef let-facetValue="facetValue" let-level="level">
        <li
            class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}"
            [class.is-level-expanded]="type === facetType.MultiselectHierarchical && facetValue.facets && expandStateForValue[ facetValue.value ] && expandStateForValue[ facetValue.value ].isExpanded"
        >
            <div
                class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}-value"
                [class.is-value-selected]="( type === facetType.Singleselect && facetValue === currentValue ) || ( type !== facetType.Singleselect && facetValue.isSelected )"
                (click)="onValueClick( facetValue, $event )"
                tabindex="0"
            >
                <label
                    *ngIf="type === facetType.MultiselectHierarchical && level > 0 && facetValue.facets.length > 0 "
                    class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}-value-subvalue-state"
                >
                    <input
                        rspCheckbox
                        [rspCheckboxIsIndeterminate]="subValueSelectedStateForValue[ facetValue.value ] === 'indeterminate'"
                        [rspCheckboxWrapperClass]="cssModuleName + '--item' + ( type === facetType.MultiselectHierarchical ? '-level' + level : '' ) + '-value-subvalue-state-checkbox'"
                        [checked]="subValueSelectedStateForValue[ facetValue.value ] === 'all' ? true : subValueSelectedStateForValue[ facetValue.value ] === 'none' ? false : null"
                        (change)="onSubvalueStateCheckboxClick( facetValue, $event )"
                        type="checkbox"
                    >
                    <span class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}-value-subvalue-state-assistive-content">
                        Toggle all / none
                    </span>
                </label>

                <div class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}-value-caption">
                    <span class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}-value-caption-name">
                        {{ facetValue.value | camelCaseToSpace }}
                    </span>
                    <span class="{{ cssModuleName }}--item-count">({{ facetValue.count }})</span>
                </div>

                <rsp-facet-summary
                    *ngIf="type === facetType.MultiselectHierarchical && facetValue.facets.length > 0 "
                    [facet]="facetValue.facets"
                    class="{{ cssModuleName }}--item-level{{ level }}-value-summary"
                ></rsp-facet-summary>

                <div
                    *ngIf="!( type === facetType.MultiselectHierarchical && facetValue.facets.length > 0 )"
                    class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}-value-action"
                >
                    <input
                        *ngIf="type === facetType.Singleselect"
                        type="radio"
                        [name]="_facet.name"
                        [value]="facetValue.value"
                        [checked]="facetValue === currentValue"
                        [attr.aria-label]="facetValue.value"
                        (click)="onValueChanged( facetValue )"
                        class="{{ cssModuleName }}--item-value-action-assistive-content"
                    >

                    <input
                        *ngIf="type === facetType.Multiselect || type === facetType.MultiselectHierarchical"
                        type="checkbox"
                        [name]="_facet.name"
                        [value]="facetValue.value"
                        [(ngModel)]="facetValue.isSelected"
                        (ngModelChange)="onValueChanged( facetValue )"
                        [attr.aria-label]="facetValue.value"
                        class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}-value-action-assistive-content"
                    >
                </div>

                <button
                    *ngIf="type === facetType.MultiselectHierarchical && facetValue.facets.length > 0"
                    (click)="toggleFacetValueExpansion( facetValue, $event )"
                    [@togglerRotated]="expandStateForValue[ facetValue.value ] && expandStateForValue[ facetValue.value ].isExpanded"
                    (@togglerRotated.done)="triggerVerticalScrollCalculation()"
                    class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level' + level : '' }}-value-action"
                >
                    <span class="{{ cssModuleName }}--item-level{{ level }}-value-action-assistive-content">Toggle</span>
                </button>
            </div>

            <ul
                *ngIf="type === facetType.MultiselectHierarchical && facetValue.facets.length > 0"
                [@bodyExpanded]="expandStateForValue[ facetValue.value ] && expandStateForValue[ facetValue.value ].isExpanded"
                (@bodyExpanded.done)="triggerVerticalScrollCalculation()"
                class="{{ cssModuleName }}--subvalue-list"
            >
                <ng-template
                    *ngFor="let facetValue of topFacetSubValuesForValue[ facetValue.value ]"
                    [ngTemplateOutlet]="facetValueTemplateRef"
                    [ngTemplateOutletContext]="{ facetValue: facetValue, level: level + 1 }"
                ></ng-template>

                <li
                    *ngIf="!areBottomFacetSubValuesVisibleForValue[ facetValue.value ] && bottomFacetSubValuesForValue[ facetValue.value ] && bottomFacetSubValuesForValue[ facetValue.value ].length > 0"
                    class="{{ cssModuleName }}--item-level{{ level }}"
                >
                    <div class="{{ cssModuleName }}--item-level{{ level + 1 }}-show-more">
                        <button
                            class="{{ cssModuleName }}--item-level{{ level + 1 }}-show-more-caption"
                            type="button"
                            (click)="showBottomFacetSubValuesForValue( facetValue )"
                        >
                            Show more ({{ bottomFacetSubValuesForValue[ facetValue.value ].length }})
                        </button>
                    </div>
                </li>

                <ng-container *ngIf="areBottomFacetSubValuesVisibleForValue[ facetValue.value ]">
                    <ng-template
                        *ngFor="let facetValue of bottomFacetSubValuesForValue[ facetValue.value ]"
                        [ngTemplateOutlet]="facetValueTemplateRef"
                        [ngTemplateOutletContext]="{ facetValue: facetValue, level: level + 1 }"
                    ></ng-template>
                </ng-container>
            </ul>
        </li>
    </ng-template>

    <!-- Calling base template with topFacetValues -->
    <ng-template
        *ngFor="let facetValue of topFacetValues"
        [ngTemplateOutlet]="facetValueTemplateRef"
        [ngTemplateOutletContext]="{ facetValue: facetValue, level: 1 }"
    ></ng-template>

    <!-- more button (in case there are not visible bottomFacetValues) -->
    <li class="{{ cssModuleName }}--item" *ngIf="!areBottomFacetValuesVisible && bottomFacetValues.length > 0">
        <div class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level1' : '' }}-show-more">
            <button
                class="{{ cssModuleName }}--item{{ type === facetType.MultiselectHierarchical ? '-level1' : '' }}-show-more-caption"
                type="button"
                (click)="showBottomFacetValues()"
            >
                Show more ({{ bottomFacetValues.length }})
            </button>
        </div>
    </li>

    <!-- Calling base template with bottomFacetValues -->
    <ng-container *ngIf="areBottomFacetValuesVisible">
        <ng-template
            *ngFor="let facetValue of bottomFacetValues"
            [ngTemplateOutlet]="facetValueTemplateRef"
            [ngTemplateOutletContext]="{ facetValue: facetValue, level: 1 }"
        ></ng-template>
    </ng-container>
</ul>
