import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive( {
    selector: '[rspDrop]',
} )
export class DropDirective {
    @Output() dropped: EventEmitter<FileList>   = new EventEmitter<FileList>();
    @Output() dragged: EventEmitter<boolean>    = new EventEmitter<boolean>();
    @Output() draggedEnd: EventEmitter<boolean> = new EventEmitter<boolean>();


    @HostListener( 'drag dragstart dragend dragenter', [ '$event' ] )
    listenTo( event: Event ): void {
        this.preventAndStop( event );
    }

    @HostListener( 'dragover', [ '$event' ] )
    public onDragOver( event: Event ): void {
        this.dragged.emit();
        this.preventAndStop( event );
    }

    @HostListener( 'dragleave', [ '$event' ] )
    public onDragLeave( event: Event ): void {
        this.draggedEnd.emit();
        this.preventAndStop( event );
    }

    @HostListener( 'drop', [ '$event' ] )
    public onDrop( event: Event ): void {
        this.dropped.emit( this.getDataTransferObject( event ).files );
        this.preventAndStop( event );
    }


    private preventAndStop( event: Event ): void {
        event.preventDefault();
        event.stopPropagation();
    }

    private getDataTransferObject(event: Event | any): DataTransfer {
        return event.dataTransfer ? event.dataTransfer : event.originalEvent.dataTransfer;
    }
}
