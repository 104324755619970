import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject ,  Subscription ,  combineLatest } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';


/**
 * Controls MainLoadingIndicator component.
 */
@Injectable()
export class MainLoadingIndicatorService {

    isVisible$: Observable<boolean>;

    private isVisible: BehaviorSubject<boolean>     = new BehaviorSubject( false );
    private observables: Array<Observable<boolean>> = [];
    private subscription: Subscription;


    constructor() {
        this.isVisible$ = this.isVisible.asObservable();
    }


    /**
     * Registers Observable<boolean> which specifies if loading indicator should be displayed. F.e. you can use conceptStoreService.loading$ for this.
     *
     * @param {Observable<boolean>} observable
     */
    registerObservable( observable: Observable<boolean> ): void {

        if ( !this.isRegistered( observable ) ) {

            this.observables.push( observable );

            this.refreshSubscription();
        }
    }

    /**
     * Unregisters observable.
     *
     * @param {Observable<boolean>} observable
     */
    unregisterObservable( observable: Observable<boolean> ): void {

        const index: number = this.getObservableIndex( observable );

        if ( index !== -1 ) {

            this.observables.splice( index, 1 );

            this.refreshSubscription();
        }
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------
    /**
     * Shows loading indicator.
     */
    private show(): void {
        this.isVisible.next( true );
    }

    /**
     * Hides loading indicator.
     */
    private hide(): void {
        this.isVisible.next( false );
    }

    private isRegistered( observable: Observable<boolean> ): boolean {
        return this.getObservableIndex( observable ) !== -1;
    }

    private getObservableIndex( observable: Observable<boolean> ): number {
        return this.observables.findIndex( ( item: Observable<boolean> ) => item === observable );
    }

    private refreshSubscription(): void {

        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }

        if ( this.observables.length > 0 ) {

            this.subscription =
                combineLatest( this.observables )
                    .pipe( debounceTime( 200 ), distinctUntilChanged() )
                    .subscribe( ( results: Array<boolean> ) => {

                        if ( results.some( ( result: boolean ) => result === true ) ) {
                            this.show();
                        }
                        else {
                            this.hide();
                        }
                    } );
        }
        else {
            this.hide();
        }
    }
}
