import { Component, Input, OnInit } from '@angular/core';

import { kebabCase } from 'lodash';

export enum MessageType {
    Error,
    Warn,
    Info,
}

export enum MessagesCssSubmodule {
    None,
    CardOwnLine, // for use in <rsp-card> when the validation message should be on its own line, inheriting the vertical rythm from <rsp-card>
    Transparent, // useful when the parent component draws the background-color and the border when the input is invalid
    TransparentEditMaterial, // same as Transparent but with special styling for <rsp-edit-material>
}

/**
 * Display small message blocks. Usually used near form fields. Not to be confused with notifications.
 *
 * @example
 *     <!-- Default -->
 *     <rsp-messages
 *         [type]="MessageType.Info"
 *         [messages]="[ 'This value is wrong…', '…and ugly too.', ]"
 *     ></rsp-messages>
 *
 *     <!-- CSS submodule applied: -->
 *     <rsp-messages
 *         [type]="MessageType.Info"
 *         [messages]="[ 'This value is wrong…', '…and ugly too.', ]"
 *         [cssSubmodule]="MessagesCssSubmodule.CardOwnLine"
 *     ></rsp-messages>
 */

@Component( {
    selector:    'rsp-messages',
    templateUrl: './messages.component.html',
    styleUrls:   [
        './messages.component.scss',
    ],
} )
export class MessagesComponent implements OnInit {
    public cssTypeClassName: string;
    public readonly cssBaseModuleName: string = 'messages';
    public cssModuleName: string = this.cssBaseModuleName;

    @Input() messages: Array<string> = [];

    @Input()
    set type( type: MessageType ) { this.setType( type ); }
    get type(): MessageType { return this._type; }

    @Input()
    set cssSubmodule( value: MessagesCssSubmodule ) { this.setSubmodule( value ); }
    get cssSubmodule(): MessagesCssSubmodule { return this._cssSubmodule; }

    private _type: MessageType;
    private _cssSubmodule: MessagesCssSubmodule;

    ngOnInit(): void {
        if ( this.type === undefined || this.type === null ) {
            this.type = MessageType.Info;
        }
    }

    private setType( type: MessageType ): void {
        this._type = type;
        this.cssTypeClassName = 'is-type-' + kebabCase( MessageType[ type ] );
    }

    private setSubmodule( value: MessagesCssSubmodule ): void {
        this.cssModuleName = this.cssBaseModuleName + ( value ? '_' + kebabCase( MessagesCssSubmodule[ value ] ) : '' );
        this._cssSubmodule = value;
    }
}
