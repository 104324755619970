/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface SortOrderQuery {
    name?: string;

    sortOrder?: SortOrderQuery.SortOrderEnum;

}

export namespace SortOrderQuery {
    'use strict';
    export enum SortOrderEnum {
        Ascending,
        Descending,
    }
    export namespace SortOrderEnum {
        export function toString( enumValue: SortOrderEnum ): string {
            return SortOrderEnum[ enumValue ];
        }
    }
}

