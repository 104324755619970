import { ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { EntityTypeEnum } from '../../../../../../shared/model/enum/entity-type.enum';
import { SelectableItemComponent } from '../../../../../../shared/ui/lists/tile/selectable-item.component';
import { ConceptOrClusterItem } from '../../../../../../shared/model/conceptOrClusterItem';
import { ConceptClusterSelectionService } from '../../../shared/concept-cluster-selection.service';
import { ConceptClusterStoreService } from '../../../shared/concept-cluster-store.service';
import { STORAGE_KEYS } from '../../../../../../core/storage-keys';
import { StorageService } from '../../../../../../core/storage.service';
import { ConceptTile } from '../../../../../../shared/model/conceptTile';
import { ClusterTile } from '../../../../../../shared/model/clusterTile';

@Component( {
    selector:    'rsp-concept-cluster-tile',
    templateUrl: './concept-cluster-tile.component.html',
    styleUrls:   [
        './concept-cluster-tile.component.scss',
    ],
} )
export class ConceptClusterTileComponent extends SelectableItemComponent<ConceptOrClusterItem> implements OnInit, OnChanges {

    @ViewChild('myTile') domElement: ElementRef;

    /**
     * Should the tile be a link to the [concept|cluster] detail page?
     */
    @Input() linksToConceptOrCluster: boolean = true;

    type: EntityTypeEnum;

    typeEnum: any = EntityTypeEnum;

    routerLink: Array<any> = [];

    isHover: boolean = false;

    conceptOrCluster: ConceptTile | ClusterTile;


    constructor(
        private storageService: StorageService,
        conceptSelectionService: ConceptClusterSelectionService,
        conceptStoreService: ConceptClusterStoreService,
        changeDetectorRef: ChangeDetectorRef,
    ) {
        super( conceptSelectionService, conceptStoreService, changeDetectorRef );
    }


    ngOnInit(): void {

        if ( !this.item ) {
            throw new Error( 'no item given' );
        }

        super.ngOnInit();
    }


    ngOnChanges( changes: SimpleChanges ): void {

        if ( changes.item ) {
            // [item] changed
            this.initialize();
        }
    }


    @HostListener( 'mouseover' )
    onMouseOver(): void {
        this.isHover = true;
    }


    @HostListener( 'mouseleave' )
    onMouseLeave(): void {
        this.isHover = false;
    }


    setIndex(): void {
        this.storageService.set<string>( STORAGE_KEYS.concepts.list.scrollToId, this.conceptOrCluster.id, );
    }


    isCheckboxVisible(): boolean {
        return this.isSelected || this.isHover;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private initialize(): void {

        this.type = this.item.type === ConceptOrClusterItem.TypeEnum.Concept ? EntityTypeEnum.Concept : EntityTypeEnum.Cluster;

        if ( this.type === EntityTypeEnum.Concept ) {
            this.conceptOrCluster = this.item.concept;
        }
        else {
            this.conceptOrCluster = this.item.cluster;
        }

        this.routerLink = [
            '/' + ConceptOrClusterItem.TypeEnum.toString( this.item.type ).toLowerCase(),
            this.conceptOrCluster.id,
        ];
    }
}
