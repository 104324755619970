import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component( {
    selector:        'rsp-registration-wizard-section-content',
    templateUrl:     './registration-wizard-section-content.component.html',
    styleUrls:       [ './registration-wizard-section-content.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class RegistrationWizardSectionContentComponent {
}
