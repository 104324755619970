<div class="tab">

    <div class="tab--toolbar">
        <rsp-global-filter
            *ngIf="collectionView.sourceItems.length"
            [collectionView]="collectionView"
            class="tab--toolbar-item is-left"
        ></rsp-global-filter>

                <rsp-edit-text
            class="tab--toolbar-item is-right"
            [myFormControl]="form.get( 'reason' )"
            [placeholder]="'Reason for change'"
        ></rsp-edit-text>

        <rsp-non-trade-item-suggester
            *ngIf="userHasEditRight"
            [placeholder]="'Add Article / Assembly'"
            [itemFilter]="itemIds"
            (selected)="addNonTradeItem( $event )"
            class="tab--toolbar-item right"
        ></rsp-non-trade-item-suggester>
    </div>

    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <rsp-no-content *ngIf="isLoading.closed && collectionView.sourceItems.length === 0">
            This cluster has no articles/assemblies.
        </rsp-no-content>

        <table
            *ngIf="collectionView.sourceItems.length"
            #stickyContainer
            class="articles-assemblies-table"
        >
            <thead class="articles-assemblies-table--head">
                <tr
                    rspFixTableRow
                    [rspFixTableRowContainer]="stickyContainer"
                >
                    <th class="articles-assemblies-table--head-cell"></th>
                    <th class="articles-assemblies-table--head-cell">
                        <rsp-column-header
                            [collectionView]="collectionView"
                            [propertyName]="'number'"
                        >N°</rsp-column-header>
                    </th>
                    <th class="articles-assemblies-table--head-cell">
                        <rsp-column-header
                            [collectionView]="collectionView"
                            [propertyName]="'name'"
                        >Name</rsp-column-header>
                    </th>
                    <th class="articles-assemblies-table--head-cell is-centered">
                        <rsp-column-header
                            [collectionView]="collectionView"
                            [propertyName]="'status'"
                        >Status</rsp-column-header>
                    </th>
                    <th *ngIf="userHasEditRight" class="articles-assemblies-table--head-cell"></th>
                </tr>
            </thead>
            <tbody class="articles-assemblies-table--body">
                <tr *ngFor="let item of collectionView.items" class="articles-assemblies-table--body-row">
                    <td class="articles-assemblies-table--body-cell is-centered">
                        <rsp-image
                            [fileId]="item.nonTradeItem.previewImageId"
                            [height]="'65'"
                            [alt]="item.nonTradeItem.number + ' ' + item.nonTradeItem.name"
                        ></rsp-image>
                    </td>
                    <td class="articles-assemblies-table--body-cell">
                        <rsp-link [type]="typeEnumToString(item.nonTradeItem.type).toLowerCase()" [id]="item.nonTradeItem.number"></rsp-link>
                    </td>
                    <td class="articles-assemblies-table--body-cell">
                        {{item.nonTradeItem.name}}
                    </td>
                    <td class="articles-assemblies-table--body-cell is-centered">
                        <rsp-status [type]="statusTypeEnum" [status]="item.nonTradeItem.status" [displayMode]="'badge'"></rsp-status>
                    </td>
                    <td *ngIf="userHasEditRight" class="articles-assemblies-table--body-cell">
                        <rsp-remove-with-reason
                            [title]="'remove article / assembly'"
                            [text]="'Do you really want to remove this article / assembly?'"
                            (confirmClicked)="removeItem( $event, item )"
                        ></rsp-remove-with-reason>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
