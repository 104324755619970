<rsp-dropdown
    [defaultValue]="displayValue"
    [isLoading]="isLoading"
    [placeholder]="placeholder"
    [withSuggester]="true"
    [allowClear]="true"
    (searchTermChanged)="searchItems($event)"
    (selected)="selectedItem($event)"
>
    <rsp-dropdown-item *ngFor="let item of items" [item]="item">
        <div class="simple-list--item-image" >
            <rsp-image
                [height]="40"
                [width]="57"
                [fileId]="item.previewImageId"
                [alt]="item.number + ' - ' + item.name"
                [alignImg]="true"
            ></rsp-image>
        </div>
        <div class="simple-list--item-name-and-number-container">
            <span class="simple-list--item-number" >{{item.number}}</span>
            <span
                title="{{item.name}}"
                class="simple-list--item-name"
            >
                {{item.name}}
            </span>
        </div>
        <div class="simple-list--item-status">
            <rsp-status
                [displayMode]="'badge'"
                [status]="item.status"
                [type]="statusTypeEnum"
            ></rsp-status>
        </div>
    </rsp-dropdown-item>
</rsp-dropdown>
<ng-content></ng-content>
