<rsp-dropdown
    [isLoading]="isLoading"
    [placeholder]="placeholder"
    [withSuggester]="true"
    [allowClear]="true"
    [inputFieldHtmlId]="inputFieldHtmlId"
    [defaultValue]="displayValue"
    (searchTermChanged)="searchItems( $event )"
    (selected)="selectedItem( $event )"
>
    <rsp-dropdown-item *ngFor="let item of items" [item]="item">
        <rsp-user-name [user]="item"></rsp-user-name>
    </rsp-dropdown-item>
</rsp-dropdown>
<ng-content></ng-content>
