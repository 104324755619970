import { AfterViewInit, Component, ElementRef, Input, NgZone, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject, fromEvent, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import * as moment from 'moment';
import * as Pikaday from 'pikaday';
import { PikadayOptions } from 'pikaday';

import { ElementVisibilityService } from '../../../../core/element-visibility.service';
import { ApplicationTimeService } from '../../../../core/application-time.service';
import { DateTimeDisplayFormat } from '../../../ui/date-time/model/dateTimeDisplayFormat.enum';

@Component( {
    selector:    'rsp-edit-date',
    templateUrl: 'edit-date.component.html',
    styles:      [ `.date-input {
        width: 100%;
    }` ],
    providers:   [ ElementVisibilityService ],
} )
export class EditDateComponent implements AfterViewInit, OnChanges, OnDestroy {
    @ViewChild( 'pikadayInput', { static: true } ) pikadayInput: ElementRef;

    @Input() myFormGroup: UntypedFormGroup;
    @Input() name: string;

    /**
     * For edit mode: Should past dates be selectable?
     * Set to `false` for edit cases, for add-cases the default `true` should be good.
     */
    @Input() disablePastDates: boolean;

    @Input() minDate: Date;
    @Input() maxDate: Date;

    /**
     * For edit mode: Should the input field have the clear-x-button?
     */
    @Input() withClearButton: boolean = true;

    /**
     * For edit mode: Optional placeholder text.
     */
    @Input() placeholder: string;

    /**
     * Optional, defaults to HumanReadable.
     * @see DateTimeComponent
     */
    @Input() format: string | DateTimeDisplayFormat = DateTimeDisplayFormat.HumanReadable;

    /**
     *
     */
    @Input() disabled: boolean;


    pikaday: Pikaday;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    private datePickerOptions: PikadayOptions = {};
    private scrollContainer: HTMLElement;
    private scrollSubscription: Subscription;

    constructor(
        private zone: NgZone,
        private elementVisibilityService: ElementVisibilityService,
    ) { }

    ngOnChanges(): void {
        this.initFormat();
        this.initFirstDayOfWeek();
        this.initDisablePastDates();

        this.initPikaday();
    }

    ngAfterViewInit(): void {
        this.initPikaday();

        this.registerInputVisibilityObserver();
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        this.pikaday.destroy();

        this.elementVisibilityService.unregister( this.pikadayInput.nativeElement );
    }

    preventScrolling( event: KeyboardEvent ): boolean {
        event.preventDefault();

        return false;
    }

    clearDate(): void {
        this.myFormGroup.get( this.name ).reset();
    }

    private initPikaday(): void {
        if ( this.pikaday ) {
            this.pikaday.destroy();
        }

        const options: PikadayOptions = {
            field:    this.pikadayInput.nativeElement,
            onSelect: ( date: Date ) => {
                this.myFormGroup.get( this.name ).setValue( date );
            },
            onOpen:   () => this.registerScrollObserver(),
            onClose:  () => this.scrollSubscription.unsubscribe(),
        };

        Object.assign( options, this.datePickerOptions );

        this.pikaday = new Pikaday( options );

        if ( this.minDate ) {
            this.pikaday.setMinDate( this.minDate );
        }

        if ( this.maxDate ) {
            this.pikaday.setMaxDate( this.maxDate );
        }

        this.setPikadayValue();
    }

    private registerInputVisibilityObserver(): void {
        this.elementVisibilityService
            .getObservableForElement( this.pikadayInput.nativeElement )
            .pipe(
                filter( () => this.pikaday.isVisible() ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( isVisible: boolean ) => {
                if ( !isVisible ) {
                    this.pikaday.hide();
                }
            } );

        this.elementVisibilityService.register( this.pikadayInput.nativeElement );
    }

    private registerScrollObserver(): void {
        this.scrollContainer = this.findNextScrollContainer( this.pikadayInput.nativeElement );

        this.zone.runOutsideAngular( () => {
            this.scrollSubscription =
                fromEvent( this.scrollContainer, 'scroll' )
                    .pipe(
                        filter( () => this.pikaday.isVisible() ),
                        map( () => this.scrollContainer.scrollTop ),
                        distinctUntilChanged(),
                        takeUntil( this.isDestroyed ),
                    )
                    .subscribe( () => {
                        this.pikaday.adjustPosition();
                    } );
        } );
    }

    private setPikadayValue(): void {
        const formValue: any = this.myFormGroup.get( this.name ).value;
        if ( formValue instanceof Date || typeof formValue === 'undefined' || formValue === null ) {
            this.pikaday.setDate( formValue );
        }
        else {
            throw new Error( `DisplayEditDateComponent: Given date must be a Date-object.` );
        }
    }

    private findNextScrollContainer( sourceElement: any ): HTMLElement {
        let scrollContainerNode: HTMLElement = sourceElement;

        do {
            scrollContainerNode = scrollContainerNode.parentElement as HTMLElement;

            if ( scrollContainerNode ) {
                const overflowValue: string = window.getComputedStyle( scrollContainerNode ).overflowY;
                if ( overflowValue === 'scroll' || overflowValue === 'auto' ) {
                    break;
                }
            }
        } while ( scrollContainerNode.parentElement );

        return scrollContainerNode;
    }

    private initDisablePastDates(): void {
        if ( this.disablePastDates ) {
            const current: Date            = ApplicationTimeService.current();
            const currentWithoutTime: Date = new Date( current.getFullYear(), current.getMonth(), current.getDate() );
            const dateValue: Date          = this.myFormGroup.get( this.name ).value;
            let dateValueWithoutTime: Date;

            if ( dateValue && dateValue instanceof Date ) {
                dateValueWithoutTime = new Date( dateValue.getFullYear(), dateValue.getMonth(), dateValue.getDate() );
            }

            this.datePickerOptions.disableDayFn = ( disabledDate: Date ) => {
                const disabledDateWithoutTime: Date = new Date( disabledDate.getFullYear(), disabledDate.getMonth(), disabledDate.getDate() );

                if (
                    dateValueWithoutTime && dateValueWithoutTime.getTime() === disabledDateWithoutTime.getTime()
                    || currentWithoutTime.getTime() <= disabledDateWithoutTime.getTime()
                ) {
                    return false;
                }
                return true;
            };
        }
    }

    private initFirstDayOfWeek(): void {
        const locale: moment.Locale = moment.localeData( navigator.language );
        if ( locale ) {
            const firstDayOfWeekIndex: number = locale.firstDayOfWeek();
            if ( isFinite( firstDayOfWeekIndex ) ) {
                this.datePickerOptions.firstDay = firstDayOfWeekIndex;
            }
        }
    }

    private initFormat(): void {
        if ( this.format === DateTimeDisplayFormat.Comparable || this.format === 'Comparable' ) {
            this.datePickerOptions.format = 'YYYY-MM-DD';
        }
        else { // HumanReadable
            this.datePickerOptions.format = 'DD MMM YYYY'; // Not exactly what we specified in <rsp-datetime>, but will do for now.
        }
    }
}
