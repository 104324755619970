/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { BrandInfo } from './brandInfo';
import { ConceptInfo } from './conceptInfo';
import { FileContainerItem } from './fileContainerItem';
import { KeywordInfo } from './keywordInfo';


export interface DocumentDetails {
    id?: string;

    fileContainerItem?: FileContainerItem;

    concept?: ConceptInfo;

    brand?: BrandInfo;

    keywords?: Array<KeywordInfo>;

    visibleFor?: DocumentDetails.VisibleForEnum;

}

export namespace DocumentDetails {
    'use strict';
    export enum VisibleForEnum {
        All,
        Intern,
        Extern,
    }
    export namespace VisibleForEnum {
        export function toString( enumValue: VisibleForEnum ): string {
            return VisibleForEnum[ enumValue ];
        }
    }
}

