<a
    #myTile
    class="non-trade-item-line"
    [class.is-non-trade-item-line-checked]="isSelected"
    [routerLink]="routerLink"
    (click)="setIndex()"
>
    <div
        *ngIf="isSelectionAvailable | async"
        class="non-trade-item-line--checkbox-container"
    >
        <input
            id="{{ item?.id }}"
            type="checkbox"
            rspCheckbox
            [rspCheckboxWrapperClass]="'non-trade-item-line--checkbox-wrapper'"
            [checked]="isSelected"
            (click)="toggleSelection( $event )"
        >
    </div>

    <div class="non-trade-item-line--image-container">
        <rsp-image
            [fileId]="item.previewImageId"
            [alt]="item.number + ' - ' + item.name"
            [height]="65"
            [width]="100"
            [alignImg]="true"
        ></rsp-image>
    </div>

    <strong class="non-trade-item-line--number">
        {{ item.number }}
    </strong>

    <div class="non-trade-item-line--name">
        {{ item.name }}
    </div>

    <rsp-status
        class="non-trade-item-line--status"
        [displayMode]="'badge'"
        [status]="item.status"
        [type]="typeEnum"
    ></rsp-status>

</a>
