import { Component, Input, } from '@angular/core';

import { Capacity } from '../../../../../../shared/model/capacity';
import { NOT_AVAILABLE } from '../../../../../../shared/ui/not-available/not-available.component';
import { UntypedFormGroup } from '@angular/forms';


/**
 * Displays article capacities as a list (display mode) and as input form (edit mode).
 * In edit mode, the result is added to given FormGroup as property with given name.
 * Result is an array and contains only capacities with not null/0 values.
 *
 * @example
 *
 *      <rsp-display-edit-capacities
 *                      [capacities]="capacityArray"
 *                      [formGroup]="myForm"
 *                      [isEditMode]="isEditMode"
 *      ></rsp-display-edit-capacities>
 *
 * myForm.value contains:
 *
 *  {
 *      ...,
 *      needsCapacity: true|false,
 *      capacities: [
 *          { capacityTypeId, count, multiplier },
 *          { capacityTypeId, count, multiplier },
 *          { capacityTypeId, count, multiplier },
 *      ],
 *  }
 *
 */
@Component(
    {
        selector: 'rsp-display-edit-capacities',
        templateUrl: './display-edit-capacities.component.html',
        styleUrls:   [
            './display-edit-capacities.component.scss',
        ],
    },
)
export class DisplayEditCapacitiesComponent {
    @Input() isEditMode: boolean;
    /**
     * Specifies if "n/a" should be displayed when provided value is not empty (null|undefined) in the display mode.
     * @type {boolean}
     */
    @Input() showNotAvailable: boolean = true;

    /**
     * Specifies string which is displayed when given value is empty and [showNotAvailable] is true. Default 'n / a'.
     * @type {string}
     */
    @Input() notAvailableString: string = NOT_AVAILABLE;

    @Input() myFormGroup: UntypedFormGroup;

    /**
     * List of capacities to be displayed. In edit mode they are used to fill input fields.
     */
    @Input() capacities: Array<Capacity>;

    @Input() needsCapacity: boolean;

}
