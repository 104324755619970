<rsp-dropdown-group [label]="'Sort by'">
    <table class="sort-by--table">
        <thead hidden>
            <tr>
                <th>Name</th>
                <th>Ascending</th>
                <th>Descending</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let sorting of activatedSorting; let i = index">
                <td class="sort-by--table-cell">{{ sorting.name }}</td>
                <td class="sort-by--table-cell">
                    <span
                        [class.is-active]="sorting.sortingOrder === ascending"
                        (click)="setOrder(sorting, ascending)"
                        class="sort-by--sort-up"
                        title="Ascending"
                    ></span>
                </td>
                <td class="sort-by--table-cell">
                    <span
                        [class.is-active]="sorting.sortingOrder === descending"
                        (click)="setOrder(sorting, descending)"
                        class="sort-by--sort-down"
                        title="Descending"
                    ></span>
                </td>
                <td class="sort-by--table-cell">
                    <div class="sort-by--move-buttons">
                        <span
                            [style.visibility]="i > 0 ? 'visible' : 'hidden'"
                            (click)="moveUp( sorting )"
                            class="sort-by--move-up"
                            title="move up"
                        ></span>
                        <span
                            [style.visibility]="i < activatedSorting.length - 1 ? 'visible' : 'hidden'"
                            class="sort-by--move-down"
                            title="move down"
                            (click)="moveDown( sorting )"
                        ></span>
                    </div>
                </td>
            </tr>
            <tr *ngFor="let sorting of unusedSorting">
                <td class="sort-by--table-cell">{{ sorting.name }}</td>
                <td class="sort-by--table-cell">
                    <span
                        [class.is-active]="sorting.sortingOrder === ascending"
                        (click)="setOrder(sorting, ascending)"
                        class="sort-by--sort-up"
                        title="Ascending"
                    ></span>
                </td>
                <td class="sort-by--table-cell">
                    <span
                        [class.is-active]="sorting.sortingOrder === descending"
                        (click)="setOrder(sorting, descending)"
                        class="sort-by--sort-down"
                        title="Descending"
                    ></span>
                </td>
                <td class="sort-by--table-cell">
                </td>
            </tr>
        </tbody>
    </table>
    <div class="sort-by--buttons">
        <rsp-button
            [disabled]="!sortingChanged || activatedSorting.length === 0"
            [submodule]="'primary-light'"
            (clicked)="activateSorting()"
        >
            SORT
        </rsp-button>
        <rsp-button
            *ngIf="activatedSorting.length > 0"
            [submodule]="'primary-light'"
            (clicked)="resetSorting()"
        >
            RESET
        </rsp-button>
    </div>

</rsp-dropdown-group>

