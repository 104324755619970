import { ChangeDetectorRef, Input, OnDestroy, OnInit, Component } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';

import { BaseSelectionService } from '../base-selection.service';
import { BaseStoreService } from '../store/base-store.service';


/**
 * Base class for all selectable components.
 * It needs an implementation of SelectionService and StoreService to process selection.
 * Selectable component displays object of type T, it is used also for selection.
 *
 * @example
 *
 *      class MyObject { ... }
 *
 *      class MyObjectSelectionService extends BaseSelectionService<MyObject> {
 *          ...
 *      }
 *
 *      class MyObjectStoreService extends BaseStoreService<MyObject> {
 *          ...
 *      }
 *
 *      class MyObjectTileComponent extends SelectableItemComponent<MyObject> {
 *
 *          constructor(
 *              myObjectSelectionService: MyObjectSelectionService,
 *              myObjectStoreService: MyObjectStoreService,
 *          ) {
 *              super( myObjectSelectionService, myObjectStoreService );
 *          }
 *      }
 *
 */
@Component({
    template: '',
})
export abstract class SelectableItemComponent<T> implements OnInit, OnDestroy {

    /**
     * Object which should be displayed as a tile.
     */
    @Input() item: T;

    /**
     * Specifies if tile is selected.
     */
    isSelected: boolean = false;

    isSelectionAvailable: Observable<boolean>;


    private subscription: Subscription;

    constructor(
        private selectionService: BaseSelectionService<T>,
        private storeService: BaseStoreService<T>,
        protected changeDetectorRef: ChangeDetectorRef,
    ) {
        this.isSelectionAvailable = this.selectionService.isSelectionAvailable$;
    }


    /**
     * Toggles tile selection. Handles simple and range (shift pressed) selection.
     */
    toggleSelection( event: MouseEvent ): boolean {

        if ( !event.shiftKey ) {
            // simple selection
            this.selectionService.toggleSelection( this.item );
        }
        else {
            // range selection (with shift key)
            this.handleRangeSelection();
        }

        // setTimeout is needed, because the checkbox will not get checked when clicked without it.
        setTimeout( () => {
            const checkbox: HTMLInputElement = event.target as HTMLInputElement;
            checkbox.checked = !checkbox.checked;
        } );

        event.stopPropagation();
        return false;
    }



    /**
     * Initializes component. If inheriting component has own ngOnInit() method, it must call super.ngOnInit().
     * Otherwise it will be called automatically.
     */
    ngOnInit(): void {

        if ( !this.item ) {
            throw new Error( 'no item given' );
        }

        // refresh tile selection when selection changed
        this.subscription
            = this.selectionService
                  .selectedItems$
                  .subscribe( () => {
                      this.isSelected = this.selectionService.isSelected( this.item );
                      this.changeDetectorRef.markForCheck();
                  } );
    }


    /**
     * Clean up. If inheriting component has own ngOnDestroy() method, it must call super.ngOnDestroy().
     * Otherwise it will be called automatically.
     */
    ngOnDestroy(): void {

        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private handleRangeSelection(): void {

        const fromItem: T = this.selectionService.getLastSelectedItem();
        const toItem: T   = this.item;

        if ( fromItem && toItem ) {

            const itemsToSelect: Array<T> = this.storeService.getItemsRange( fromItem, toItem );

            if ( itemsToSelect && itemsToSelect.length > 0 ) {
                this.selectionService.addRangeToSelection( itemsToSelect );
            }
        }
    }
}
