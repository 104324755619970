import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';

import { UserService } from '../../../../shared/ui/user/user.service';
import { UserSummary } from '../../../../shared/model';
import { map } from 'rxjs/operators';

@Injectable()
export class UserSummaryResolver implements Resolve<UserSummary> {

    constructor( private userService: UserService ) {
    }

    resolve( route: ActivatedRouteSnapshot ): Observable<UserSummary> | Observable<any> {
        const userId: string = route.params[ 'id' ];

        this.userService.setUserId( userId );

        return this.userService
                   .getSummary()
                   .pipe(
                       map( ( result: UserSummary ) => {

                           if ( result ) {
                               return result;
                           }

                           // not found - webapi-service redirects to object-not-found page
                           return of( null );
                       } ),
                   );
    }
}
