<div [formGroup]="group" class="material">
    <input
        rspTextInput
        [textAlignedRight]="true"
        [formControl]="group.get('percentage')"
        [withClearButton]="true"
        type="text"
        size="3"
    >
    <span class="material--percentage">
        %
    </span>

    <rsp-material-suggester
        [displayValue]="group.get('name').value"
        [filterIds]="filterIds"
        (selected)="selectedItem( $event )"

        class="material--suggester"
    >
    </rsp-material-suggester>


    <rsp-button
        *ngIf="group.get('name').value"
        (click)="triggerRemove()"
        [submodule]="'seamless'"
        title="remove material"
        class="material--remove"
    >
    </rsp-button>
</div>

<rsp-messages
    [type]="messageTypeError"
    [messages]="validationMessages"
    [cssSubmodule]="validationMessagesCssSubmodule"
></rsp-messages>
