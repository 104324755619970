import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, CanDeactivate } from '@angular/router';
import { Observable ,  Subject } from 'rxjs';

import { ConfirmDialogService } from '../overlay/confirm-dialog/confirm-dialog.service';

export abstract class InterruptNavigation {
    abstract hasChanges: boolean;

    navigateAway$: Observable<boolean>;

    private navigateAway: Subject<boolean> = new Subject<boolean>();

    protected constructor(
        private confirmDialogService: ConfirmDialogService,
    ) {
        this.navigateAway$ = this.navigateAway.asObservable();
    }

    openConfirmNavigation(): void {
        this.confirmDialogService.openConfirmDialog( {
            anchorElement:   null,
            text:            'Do you really want to leave this page? Changes will be lost!',
            confirmCallback: () => this.navigateAway.next( true ),
            cancelCallback:  () => this.navigateAway.next( false ),
        } );
    }
}


@Injectable()
export class InterruptNavigationGuard implements CanDeactivate<InterruptNavigation> {
    canDeactivate( component: InterruptNavigation, next: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | boolean {
        if ( component.hasChanges ) {
            component.openConfirmNavigation();
            return component.navigateAway$;
        }
        else {
            return true;
        }
    }
}
