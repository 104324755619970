import { OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { BaseListPageComponent } from './base-list-page.component';
import { ViewKind } from '../secondary-toolbar/view-switcher/view-switcher.component';
import { MainLoadingIndicatorService } from '../../../core/main-loading-indicator/main-loading-indicator.service';
import { StorageService } from '../../../core/storage.service';
import { BaseSelectionService } from './base-selection.service';
import { BaseStoreService } from './store/base-store.service';
import { ViewSwitcherService } from '../secondary-toolbar/view-switcher/view-switcher.service';
import { Component } from '@angular/core';


/**
 * Base class for all list-page-components containing alternative views (Tile and List).
 */
@Component({
    template: '',
})
export abstract class BaseListPageWithAlternativeViewsComponent<T> extends BaseListPageComponent<T> implements OnInit, OnDestroy {

    activeViewKind: Observable<ViewKind>;
    viewKind: any = ViewKind;

    constructor(
        private viewSwitcherService: ViewSwitcherService,
        router: Router,
        activatedRoute: ActivatedRoute,
        storeService: BaseStoreService<T>,
        selectionService: BaseSelectionService<T>,
        storageService: StorageService,
        mainLoadingIndicatorService: MainLoadingIndicatorService,
    ) {
        super(
            router,
            activatedRoute,
            storeService,
            selectionService,
            storageService,
            mainLoadingIndicatorService,
        );

        // observable for template
        this.activeViewKind = this.viewSwitcherService.activeViewKind$;
    }


    ngOnInit(): void {
        super.ngOnInit();
    }


    ngOnDestroy(): void {
        super.ngOnDestroy();
    }
}
