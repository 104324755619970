import { Injectable } from '@angular/core';
import { BaseSelectionService } from '../../../../shared/ui/lists/base-selection.service';
import { ConceptOrClusterItem } from '../../../../shared/model/conceptOrClusterItem';
import { ConceptClusterService } from './concept-cluster.service';

/**
 * Handles selection of concepts and clusters. Provides an observable property (selectedItems$) which can be used by observers
 * which want to be notified when selection has been changed.
 */
@Injectable()
export class ConceptClusterSelectionService extends BaseSelectionService<ConceptOrClusterItem> {

    constructor( private conceptClusterService: ConceptClusterService ) {
        super();
    }

    protected getId( item: ConceptOrClusterItem ): string {
        if ( !item ) {
            throw new Error( '[ConceptClusterSelectionService] item must not be null!' );
        }

        return this.conceptClusterService.getConceptOrClusterIdFromConceptOrClusterItem( item );
    }
}
