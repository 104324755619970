import { Component, } from '@angular/core';
import { Location } from '@angular/common';

import { BreadcrumbService, } from './breadcrumb.service';
import { BreadcrumbPart, } from './breadcrumb-part';
import { Observable } from 'rxjs';


/**
 * Displays Breadcrumb in the function bar.
 *
 * Breadcrumb parts can be created directly in the html template (use <rsp-breadcrumb-part> component)
 * or with BreadcrumbService.
 *
 */
@Component( {
    selector:    'rsp-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls:   [
        './breadcrumb.component.scss',
    ],
} )
export class BreadcrumbComponent {

    breadcrumbParts: Observable<BreadcrumbPart[]>;

    constructor(
        breadcrumbService: BreadcrumbService,
        private location: Location,
    ) {

        this.breadcrumbParts = breadcrumbService.breadcrumbParts$;
    }

    goBack(): void {
        this.location.back();
    }
}
