import { Component, EventEmitter, Input, Output, } from '@angular/core';

import { FileKindInfo } from '../../../model/fileKindInfo';
import { ProgressBarBackgroundColor } from '../../../ui/loading-indicators/progress-linear/progress-linear.component';
import { FileTypeGroup } from '../../../ui/drop-zone/drop-zone.component';
import { FileUploadedInfo } from '../file-upload/file-upload.component';

export interface UploadedFileInfo {
    fileContainerId: string | null;
    fileName: string;
    previewImageId: string;
}

/**
 * Uploads a single file to the server. Can create a new file container in the backend or update an existing one.
 *
 * Use like this:
 *
 * ```html
 *
 *     Uploaded file should create a new file container (in this case for an artwork):
 *     <rsp-upload-single-file
 *         [fileKinds]="[ { id: 'da0e0c8b-5f18-1699-241b-5527ff53edba', name: 'Artwork' } ]"
 *         [progressBarBackgroundColor]="'lightest-gray'"
 *         [validFileTypeGroup]="'images'"
 *         (fileUpdated)="doSomethingWithUploadedFile( $event )"
 *     >
 *         click here or drop file
 *     </rsp-upload-single-file>
 *
 *     Uploaded file should create a new version for an existing file container:
 *     <rsp-upload-single-file
 *         [fileContainerId]="fileContainerId"
 *         [progressBarBackgroundColor]="'lightest-gray'"
 *         [validFileTypeGroup]="'images'"
 *         (fileUpdated)="doSomethingWithUploadedFile( $event )"
 *     >
 *         click here or drop file
 *     </rsp-upload-single-file>
 * ```
 */
@Component( {
    selector:    'rsp-upload-single-file',
    templateUrl: './upload-single-file.component.html',
    styleUrls:   [
        './upload-single-file.component.scss',
    ],
} )
export class UploadSingleFileComponent {
    @Input() fileContainerId: string;

    @Input()
    set fileKinds( value: FileKindInfo[] ) {

        if ( value === null
            || typeof value === 'undefined'
            || value.length === 0
            || ( value.length === 1 && !value[0] ) ) {
            return;
        }

        if ( value.length === 1 ) {
            this.fileKindId = value[0].id;
        }
        else {
            // TODO: file kinds as dropdown selection
            throw new Error( 'Not implemented exception ');
        }
    }

    /**
     * Set this to a value that makes an empty progress bar visible on the background color of your current context.
     */
    @Input() progressBarBackgroundColor: ProgressBarBackgroundColor = 'super-lightest-gray';

    /**
     * Only allow certain file types to be uploaded.
     */
    @Input() validFileTypeGroup: FileTypeGroup;

    @Input() inputFieldHtmlId: string;

    /**
     * When the file was uploaded a full UploadedFileInfo object is emited.
     * If upload was canceled the `property` is `null`, but the parent components can still use the `fileName`.
     * If file was removed, `null` is emitted.
     */
    @Output() fileUpdated: EventEmitter<UploadedFileInfo | null> = new EventEmitter();

    file: File;
    fileKindId: string;

    storeFile( files: File[] ): void {
        if ( !this.fileContainerId && !this.fileKindId ) {
            throw new Error( this.constructor.name + ': no fileContainerId or fileKindId given!' );
        }

        if ( Array.isArray( files ) && files.length > 0 ) {
            this.file = files[ 0 ];
        }
    }

    removeFile(): void {
        this.file = undefined;
        this.fileUpdated.emit( null );
    }

    fileUploaded( info: FileUploadedInfo | null ): void {
        this.fileUpdated.emit(
            info ?
                {
                    fileContainerId: info.fileContainerId,
                    previewImageId:  info.previewImageId,
                    fileName:        this.file.name,
                }
                : null,
        );
        this.file = undefined;
    }
}
