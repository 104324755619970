<div #scrollbarMeasure class="scrollbar-measure"></div>
<rsp-button
    *ngIf="scrollable && showButtons && !scrolledToTop"
    [@enterAnimation]="scrollable && showButtons && !scrolledToTop"

    [submodule]="'seamless'"
    (click)="scrollUp()"
    class="vertical-scroll--up" title="scroll up"
></rsp-button>
<div #scrollContainer class="vertical-scroll">
    <div #heightContainer>
        <ng-content></ng-content>
    </div>
</div>
<rsp-button
    *ngIf="scrollable && showButtons && !scrolledToBottom"
    [@enterAnimation]="scrollable && showButtons && !scrolledToBottom"

    [submodule]="'seamless'"
    (click)="scrollDown()"
    class="vertical-scroll--down"
    title="scroll down"
></rsp-button>
