import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { RadioButtonGroupItem } from '../../../radio-button-group/radio-button-group.model';
import { EnumConverterService, EnumValue } from '../../../../utils/enum/enum-converter.service';
import { AdvancedSearchFilterGroup as AdvancedSearchFilterQueryGroup } from '../../../../model/advancedSearchFilterGroup';
import { AdvancedSearchFilter, AdvancedSearchFilterField, AdvancedSearchFilterGroup, AdvancedSearchService } from '../advanced-search.service';

type FilterGroupId = number;

@Component( {
    selector:    'rsp-advanced-search-filter-group',
    templateUrl: './advanced-search-filter-group.component.html',
    styleUrls:   [ './advanced-search-filter-group.component.scss', ],
} )
export class AdvancedSearchFilterGroupComponent implements OnChanges {
    @Input() id: FilterGroupId;
    @Input() filterGroup: AdvancedSearchFilterGroup;
    @Input() filterFields: AdvancedSearchFilterField[];
    @Input() noRemove: boolean = false;

    @Output() onRemove: EventEmitter<FilterGroupId> = new EventEmitter();
    @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();

    matcherOptions: RadioButtonGroupItem[] = EnumConverterService
        .convertToEnumValueList( AdvancedSearchFilterQueryGroup.GroupOperatorEnum, 'index' )
        .map( ( enumValue: EnumValue ) => {
            const radioButtonGroupItem: RadioButtonGroupItem = {
                id:      enumValue.index,
                caption: enumValue.index === AdvancedSearchFilterQueryGroup.GroupOperatorEnum.And ? 'all'  :
                         enumValue.index === AdvancedSearchFilterQueryGroup.GroupOperatorEnum.Or  ? 'any'  :
                         enumValue.index === AdvancedSearchFilterQueryGroup.GroupOperatorEnum.Not ? 'none' : enumValue.name,
            };
            return radioButtonGroupItem;
        } );

    constructor( private advancedSearchService: AdvancedSearchService ) {
    }

    ngOnChanges(): void {
        if ( this.filterGroup.filters.length === 0 ) {
            this.addFilter();
        }
    }

    addFilter(): void {
        const defaultFilter: AdvancedSearchFilter = this.advancedSearchService.getDefaultFilter( this.filterFields );

        this.filterGroup.filters.push( defaultFilter );
    }

    removeFilter( index: number ): void {
        this.filterGroup.filters.splice( index, 1 );

        if ( this.filterGroup.filters.length === 0 ) {
            this.addFilter();
        }
    }

    setFilterGroupOperator( operator: AdvancedSearchFilterQueryGroup.GroupOperatorEnum ): void {
        this.filterGroup.groupOperator = operator;
    }

    emitRemoveEvent(): void {
        this.onRemove.emit( this.id );
    }

    emitSubmitEvent(): void {
        this.onSubmit.emit();
    }
}
