/* tslint:disable:max-line-length */
import { NgModule, } from '@angular/core';
import { RouterModule, Routes, } from '@angular/router';

import { RspUserGuard, } from '../../core/guards/rsp-user.guard';
import { AccessRightGuard } from '../../core/guards/access-right.guard';

import { UserListPageComponent } from './users/user-list/user-list-page.component';
import { UserToolbarComponent } from './users/user-toolbar/user-toolbar.component';
import { UserDetailsPageComponent } from './users/user/user-details-page.component';
import { UserSummaryResolver } from './users/user/user-summary.resolver';
import { UserDetailsComponent } from '../../shared/ui/user/user-details/user-details.component';
import { UserDistributionChannelsComponent } from '../../shared/ui/user/user-distribution-channels/user-distribution-channels.component';
import { UserConceptsComponent } from '../../shared/ui/user/user-concepts/user-concepts.component';
import { UserNameService } from '../../shared/ui/user-name/user-name.service';
import { IdentityServerLoginGuard } from '../../core/guards/identity-server-login.guard';
import { CurrentUserContext, UserSummary } from '../../shared/model';
/* tslint:enable:max-line-length */

export function getUserPageTitle( routeData: { userSummary: UserSummary } ): string {
    return `User ${ UserNameService.format( routeData.userSummary.tile ) }`;
}

const USER_MANAGEMENT_ROUTES: Routes = [
    {
        path:             '',
        canActivate:      [ IdentityServerLoginGuard, RspUserGuard, ],
        canActivateChild: [ IdentityServerLoginGuard, RspUserGuard, ],
        children:         [
            {
                path:             'users',
                canActivate:      [ AccessRightGuard, ],
                canActivateChild: [ AccessRightGuard, ],
                data:             { accessRight: CurrentUserContext.AccessRightsEnum.UsersBrowse, },
                children:         [
                    // User Toolbar
                    { path: '', component: UserToolbarComponent, outlet: 'secondary-toolbar' },

                    // Routes
                    { path: '', component: UserListPageComponent, },
                ],
            },
            {
                path:             'user',
                canActivate:      [ AccessRightGuard, ],
                canActivateChild: [ AccessRightGuard, ],
                data:             {
                    pageTitle: null,
                    accessRight: CurrentUserContext.AccessRightsEnum.UsersBrowse, // titlePart of pathPart will be provided by ':id'-route.
                },
                children:         [
                    // User Toolbar
                    { path: '', component: UserToolbarComponent, outlet: 'secondary-toolbar' },

                    {
                        path:      ':id',
                        component: UserDetailsPageComponent,
                        resolve:   {
                            userSummary: UserSummaryResolver,
                        },
                        data:      {
                            pageTitle: getUserPageTitle,
                        },
                        children:  [
                            { path: '', redirectTo: 'details', pathMatch: 'full', },
                            {
                                path:     'details',
                                children: [
                                    {
                                        path:            '',
                                        component:       UserDetailsComponent,
                                        data: {
                                            userEditContext: 'user-management',
                                        },
                                    },
                                    {
                                        path:        'edit',
                                        component:   UserDetailsComponent,
                                        canActivate: [ AccessRightGuard, ],
                                        data:        {
                                            accessRight:     CurrentUserContext.AccessRightsEnum.UsersDetailsEdit,
                                            isEditMode:      true,
                                            userEditContext: 'user-management',
                                        },
                                    },
                                ],
                            },
                            {
                                path:     'distribution-channels',
                                children: [
                                    {
                                        path:      '',
                                        component: UserDistributionChannelsComponent,
                                        data: {
                                            userEditContext: 'user-management',
                                        },
                                    },
                                    {
                                        path:        'edit',
                                        component:   UserDistributionChannelsComponent,
                                        canActivate: [ AccessRightGuard, ],
                                        data: {
                                            accessRight:     CurrentUserContext.AccessRightsEnum.UsersDistributionChannelsEdit,
                                            isEditMode:      true,
                                            userEditContext: 'user-management',
                                        },
                                    },
                                ],
                            },
                            {
                                path:     'concepts',
                                children: [
                                    {
                                        path:      '',
                                        component: UserConceptsComponent,
                                        data: {
                                            userEditContext: 'user-management',
                                        },
                                    },
                                    {
                                        path:        'edit',
                                        component:   UserConceptsComponent,
                                        canActivate: [ AccessRightGuard, ],
                                        data: {
                                            accessRight:     CurrentUserContext.AccessRightsEnum.UsersConceptsEdit,
                                            isEditMode:      true,
                                            userEditContext: 'user-management',
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];


@NgModule( {
    imports: [
        RouterModule.forChild( USER_MANAGEMENT_ROUTES ),
    ],
    exports: [
        RouterModule,
    ],
} )
export class UserManagementRoutingModule {
}
