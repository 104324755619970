<!-- header -->
<ng-template #dialogHeaderTemplate>
    <div class="standard-dialog--headline">
        <h2>Service Models</h2>
    </div>
</ng-template>

<!-- content -->
<ng-template #dialogContentTemplate>
    <div
        [rspLoadingIndicator]="isLoading"
        class="standard-dialog--content edit-concept-service-models-assignment-dialog"
    >

        <table class="service-models-table">
            <thead>
                <tr class="service-models-table--head-row">
                    <th class="service-models-table--head-cell">
                        <rsp-column-header
                            [collectionView]="collectionView"
                            [propertyName]="'name'"
                        >Service</rsp-column-header>
                    </th>
                    <th class="service-models-table--head-cell"></th>
                    <th class="service-models-table--head-cell">Level</th>
                </tr>
            </thead>
            <tbody>
                <tr class="service-models-table--body-row"
                    *ngFor="let item of collectionView.items"
                >
                    <td class="service-models-table--body-cell">{{ item.name }}</td>
                    <td class="service-models-table--body-cell_info">
                        <rsp-info-tooltip *ngIf="isInfoTooltipVisible( item )">
                            <div *ngFor="let serviceLevel of item.serviceLevelItems"
                                 [ngClass]="{ 'is-level-not-available': !serviceLevel.isLevelAvailableFor( item.assignedLevelGroupValue ) }"
                            >
                                {{ serviceLevel.name }}
                            </div>
                        </rsp-info-tooltip>
                    </td>
                    <td class="service-models-table--body-cell_levels is-right">
                        <rsp-dropdown
                            [withSuggester]="false"
                            [defaultValue]="item.assignedLevelGroupName"
                            [placeholder]="'Select Level'"
                            (selected)="onServiceLevelGroupSelected( $event, item )"
                        >
                            <rsp-dropdown-item
                                *ngFor="let option of item.serviceLevelGroupOptions"
                                [item]="option"
                            >{{ option.name }}</rsp-dropdown-item>
                        </rsp-dropdown>
                    </td>
                </tr>
            </tbody>
        </table>

    </div>
</ng-template>

<!-- footer -->
<ng-template #dialogFooterTemplate>
    <div class="standard-dialog--footer">

        <rsp-button
            [submodule]="'secondary'"
            (clicked)="closeDialog()"
        >Cancel</rsp-button>

        <rsp-button
            [submodule]="'primary'"
            (clicked)="saveServiceModels()"
            class="edit-concept-service-models-assignment-dialog--button-save"
        >Save</rsp-button>

    </div>
</ng-template>
