<div class="tab">

    <div class="tab--toolbar">
        <rsp-global-filter
            *ngIf="collectionView.sourceItems.length > 0"
            [collectionView]="collectionView"
            class="tab--toolbar-item is-left"
        ></rsp-global-filter>
    </div>

    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <rsp-no-content *ngIf="isLoading.closed && collectionView.sourceItems.length === 0">
            This concept does not hold any clusters.
        </rsp-no-content>

        <table
            *ngIf="collectionView.sourceItems.length > 0"
            #stickyContainer
            class="concept-clusters-table"
        >
            <thead class="concept-clusters-table--head">
            <tr
                rspFixTableRow
                [rspFixTableRowContainer]="stickyContainer"
            >
                <th class="concept-clusters-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'name'"
                    >Name</rsp-column-header>
                </th>
                <th class="concept-clusters-table--head-cell is-centered">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'status'"
                    >Status</rsp-column-header>
                </th>
                <th class="concept-clusters-table--head-cell is-centered">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'articlesAndAssemblies'"
                    >Articles / Assemblies</rsp-column-header>
                </th>
                <th class="concept-clusters-table--head-cell is-centered">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'availableForOrderingFrom'"
                    >Orderable From</rsp-column-header>
                </th>
                <th class="concept-clusters-table--head-cell is-centered">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'availableForOrderingto'"
                    >Orderable To</rsp-column-header>
                </th>
            </tr>
            </thead>
            <tbody class="concept-clusters-table--body">
            <tr *ngFor="let item of collectionView.items" class="concept-clusters-table--body-row">
                <td class="concept-clusters-table--body-cell">
                    <rsp-cluster-link
                        [linkText]="item.cluster.name"
                        [clusterId]="item.cluster.id"
                    ></rsp-cluster-link>
                </td>
                <td class="concept-clusters-table--body-cell is-centered">
                    <rsp-status [status]="item.cluster.status" [type]="statusTypeEnum" [displayMode]="'badge'"></rsp-status>
                </td>
                <td class="concept-clusters-table--body-cell is-centered">
                    <rsp-cluster-article-assembly-count
                        [articleCount]="item.cluster.articles"
                        [assemblyCount]="item.cluster.assemblies"
                        [clusterId]="item.cluster.id"
                    ></rsp-cluster-article-assembly-count>
                </td>
                <td class="concept-clusters-table--body-cell is-centered">
                    <nur-date-time [datetime]="item.cluster.availableForOrderingFrom"></nur-date-time>
                </td>
                <td class="concept-clusters-table--body-cell is-centered">
                    <nur-date-time [datetime]="item.cluster.availableForOrderingUntil"></nur-date-time>
                </td>
            </tr>
            </tbody>
        </table>
    </div>

</div>
