import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RegistrationUserData } from '../registration-wizard.component';
import { DistributionChannelListItem } from '../../../../shared/model';
import { DistributionChannelSelection } from '../../../../shared/ui/distribution-channels/distribution-channels.component';

@Component( {
    selector:    'rsp-registration-wizard-distribution-channels',
    templateUrl: './registration-wizard-distribution-channels.component.html',
    styleUrls:   [ './registration-wizard-distribution-channels.component.scss' ],
} )
export class RegistrationWizardDistributionChannelsComponent implements OnInit {
    @Input() userData: RegistrationUserData;
    @Input() distributionChannels: DistributionChannelListItem[];
    @Output() dataChanged: EventEmitter<RegistrationUserData | null> = new EventEmitter();

    selectedDistributionChannels: DistributionChannelListItem[] = [];

    ngOnInit(): void {
        this.selectedDistributionChannels = JSON.parse( JSON.stringify( this.userData.distributionChannels ) );
    }

    setSelectedDistributionChannels( distributionChannelSelection: DistributionChannelSelection ): void {
        this.selectedDistributionChannels = distributionChannelSelection.selectedDistributionChannels;

        if ( distributionChannelSelection.isSelectionValid ) {
            this.dataChanged.emit( {
                distributionChannels: this.selectedDistributionChannels,
            } );
        }
        else {
            this.dataChanged.emit( null );
        }
    }
}
