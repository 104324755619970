<div class="tab">
    <div class="tab--toolbar">
        <rsp-form-buttons
            *ngIf="hasEditRight"
            [disablePrimaryEditButton]="!isValid"
            [isEditMode]="isEditMode"
            (actionButtonClick)="startEditMode()"
            (primaryEditButtonClick)="saveDistributionChannels()"
            (secondaryEditButtonClick)="endEditMode()"
            class="tab--toolbar-item is-right"
        ></rsp-form-buttons>
    </div>

    <div
        *ngIf="allDistributionChannels && userDistributionChannels"
        [rspLoadingIndicator]="isLoading"
        class="tab--content"
    >
        <rsp-distribution-channels
            [distributionChannels]="allDistributionChannels"
            [selectedDistributionChannels]="userDistributionChannels"
            [isEditMode]="isEditMode"
            [isRequired]="true"
            (selectionChanged)="setDistributionChannelsToSave( $event )"
        ></rsp-distribution-channels>
    </div>
</div>
