/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface AdvancedSearchFilterProperty {
    name?: string;

    type?: AdvancedSearchFilterProperty.TypeEnum;

    values?: Array<string>;

}

export namespace AdvancedSearchFilterProperty {
    'use strict';
    export enum TypeEnum {
        String,
        Numeric,
        Date,
        Enum,
        Boolean,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
}

