import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ArticleManagementArticlesService, ArticleManagementAssembliesService } from '../../../../../shared/api';
import { ArticleCapacity } from '../../../../../shared/model/articleCapacity';
import { Reply } from '../../../../../shared/model/reply';

import { UpdateArticle } from '../../../../../shared/model/updateArticle';
import { UpdateAssemblyDetails } from '../../../../../shared/model/updateAssemblyDetails';
import { CapacityFormItem } from '../../shared/forms/display-edit-capacities/edit-capacities.component';
import { ArticleMaterialComponent } from '../../../../../shared/model/articleMaterialComponent';
import { MaterialFormGroup } from '../../shared/forms/display-edit-materials/display-edit-materials.component';


/**
 * Supports NonTradeItemDetailsComponent to do his job.
 */
@Injectable()
export class NonTradeItemDetailsService {

    constructor( private articleApi: ArticleManagementArticlesService,
                 private assembliesApi: ArticleManagementAssembliesService ) {
    }

    /**
     * Prepares submit data and saves article details.
     *
     * @param articleId
     * @param formValue
     * @returns {Observable<Object>}
     */
    saveArticleDetails( articleId: string, formValue: any ): Observable<Reply> {

        const submitData: UpdateArticle
                = this.prepareArticleDetailsUpdateData( formValue );

        return this.articleApi.articlesUpdateDetail( articleId, submitData );
    }

    /**
     * Prepares submit data and saves assembly details.
     *
     * @param assemblyId
     * @param formValue
     * @returns {Observable<Object>}
     */
    saveAssemblyDetails( assemblyId: string, formValue: any ): Observable<Reply> {

        const submitData: UpdateAssemblyDetails
                = this.prepareAssemblyDetailsUpdateData( formValue );

        return this.assembliesApi.assembliesUpdateDetails( assemblyId, submitData );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private prepareArticleDetailsUpdateData( formValue: any ): UpdateArticle {

        this.assertObjectHasProperty( formValue, 'name' );
        this.assertObjectHasProperty( formValue, 'note' );
        this.assertObjectHasProperty( formValue, 'description' );
        this.assertObjectHasProperty( formValue, 'legacyArticleNumber' );
        this.assertObjectHasProperty( formValue, 'assembledDimensions' );
        this.assertObjectHasProperty( formValue, 'assembledDimensionsWeight' );
        this.assertObjectHasProperty( formValue, 'packedDimensions' );
        this.assertObjectHasProperty( formValue, 'packedDimensionsWeight' );
        this.assertObjectHasProperty( formValue, 'customsTariffNumber' );
        this.assertObjectHasProperty( formValue, 'capacities' );
        this.assertObjectHasProperty( formValue.capacities, 'needsCapacity' );
        this.assertObjectHasProperty( formValue, 'packagingUnit' );
        this.assertObjectHasProperty( formValue, 'engineeringPartnerName' );
        this.assertObjectHasProperty( formValue, 'requiredFiles' );


        const result: UpdateArticle = {

            appliedBrandLogoId: formValue.appliedBrandLogoId,
            category: formValue.categoryId,

            name:        formValue.name,

            note:        formValue.note,
            description: formValue.description,

            legacyArticleNumber: formValue.legacyArticleNumber,

            needsCapacity: formValue.capacities.needsCapacity,
            capacities:    this.prepareCapacities( formValue.capacities.capacityItems ),

            subComponentIds: formValue.subComponentIds,

            keywordIds: formValue.keywordIds,

            engineeringPartnerName: formValue.engineeringPartnerName,

            materialInfo: formValue.materialInfo,
            materials:    this.prepareMaterials( formValue.materials ),

            spmGroupId: formValue.spmGroupId,
            spmTypeId:  formValue.spmTypeId,

            packagingUnit: formValue.packagingUnit,

            articleDimensions: {
                assembledDimensions: {
                    width:  formValue.assembledDimensions.width,
                    height: formValue.assembledDimensions.height,
                    depth:  formValue.assembledDimensions.depth,
                    weight: formValue.assembledDimensionsWeight,
                },
                packedDimensions:    {
                    width:  formValue.packedDimensions.width,
                    height: formValue.packedDimensions.height,
                    depth:  formValue.packedDimensions.depth,
                    weight: formValue.packedDimensionsWeight,
                },
            },

            customsTariffNumber: formValue.customsTariffNumber,

            requiredFiles: formValue.requiredFiles,
        };

        return result;
    }

    private prepareCapacities( capacityItems: CapacityFormItem[]): ArticleCapacity[] {
        return capacityItems.filter( ( item: CapacityFormItem ) => {
            return item.pieces && parseInt( item.pieces, 10 ) !== 0;
        } ).map( ( item: CapacityFormItem ) => {
            return {
                capacityTypeId: item.capacityTypeId,
                pieces:         parseInt( item.pieces, 10 ),
            };
        } );
    }

    private prepareMaterials( materials?: MaterialFormGroup[] ): ArticleMaterialComponent[] {
        if ( !materials ) {
            return [];
        }

        return materials.filter( ( material: MaterialFormGroup ) => material.name )
                        .map( ( material: MaterialFormGroup ) => { return { materialId: material.materialId, percentage: material.percentage }; } );
    }


    private prepareAssemblyDetailsUpdateData( formValue: any ): UpdateAssemblyDetails {

        this.assertObjectHasProperty( formValue, 'note' );
        this.assertObjectHasProperty( formValue, 'name' );
        this.assertObjectHasProperty( formValue, 'spmGroupId' );
        this.assertObjectHasProperty( formValue, 'spmTypeId' );
        this.assertObjectHasProperty( formValue, 'description' );
        this.assertObjectHasProperty( formValue, 'assembledDimensions' );

        const result: UpdateAssemblyDetails = {

            name:        formValue.name,
            note:        formValue.note,
            description: formValue.description,

            spmGroupId: formValue.spmGroupId,
            spmTypeId:  formValue.spmTypeId,

            keywordIds: formValue.keywordIds,

            width:  formValue.assembledDimensions.width,
            height: formValue.assembledDimensions.height,
            depth:  formValue.assembledDimensions.depth,
        };

        return result;
    }

    /**
     * TODO: move this method to shared/utils.
     *
     * Checks if specified object has property with given name. If not throws user readable exception.
     * @param object
     * @param propertyName
     */
    private assertObjectHasProperty( object: any, propertyName: string ): void {

        if ( !object ) {
            throw new Error( 'Object must be defined!' );
        }

        if ( !object.hasOwnProperty( propertyName ) ) {
            throw new Error( 'Object must have property with name: ' + propertyName );
        }
    }
}
