/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface ModuleAccessRightItem {
    id?: string;

    name?: string;

    assigned?: boolean;

    type?: ModuleAccessRightItem.TypeEnum;

}

export namespace ModuleAccessRightItem {
    'use strict';
    export enum TypeEnum {
        ArticlesAndAssembliesBrowse,
        ArticlesAndAssembliesExtract,
        ArticlesAndAssembliesFilesExport,
        ArticlesAndAssembliesArticleCreate,
        ArticlesAndAssembliesArticleDetailsEdit,
        ArticlesAndAssembliesArticleGraphicsEdit,
        ArticlesAndAssembliesArticleFilesEdit,
        ArticlesAndAssembliesArticleStylesAndArtworksEdit,
        ArticlesAndAssembliesArticleSPMDetailsEdit,
        ArticlesAndAssembliesArticleLifeCycleStatusEdit,
        ArticlesAndAssembliesAssemblyCreate,
        ArticlesAndAssembliesAssemblyDetailsEdit,
        ArticlesAndAssembliesAssemblyGraphicsEdit,
        ArticlesAndAssembliesAssemblyFilesEdit,
        ArticlesAndAssembliesAssemblyComponentsEdit,
        ArticlesAndAssembliesAssemblyLifeCycleStatusEdit,
        ArticlesAndAssembliesFilterLifeCycleStatusUnpublished,
        ArticlesAndAssembliesFilterLifeCycleStatusDevelopment,
        ArticlesAndAssembliesFilterLifeCycleStatusActiveInPreRollout,
        ArticlesAndAssembliesFilterLifeCycleStatusActiveInRollout,
        ArticlesAndAssembliesFilterLifeCycleStatusActiveOnDemand,
        ArticlesAndAssembliesFilterLifeCycleStatusDiscontinue,
        ArticlesAndAssembliesFilterLifeCycleStatusInactive,
        ConceptsAndClustersBrowse,
        ConceptsAndClustersConceptCreate,
        ConceptsAndClustersConceptDetailsEdit,
        ConceptsAndClustersConceptStoresEdit,
        ConceptsAndClustersConceptServiceLevelsEdit,
        ConceptsAndClustersConceptFilesEdit,
        ConceptsAndClustersClusterCreate,
        ConceptsAndClustersClusterDetailsEdit,
        ConceptsAndClustersClusterLifeCycleStatusEdit,
        ConceptsAndClustersClusterArticleAssemblyAssignmentsEdit,
        ConceptsAndClustersClusterFilesEdit,
        ConceptsAndClustersFilterLifeCycleStatusUnpublished,
        ConceptsAndClustersFilterLifeCycleStatusPublished,
        ConceptsAndClustersFilterLifeCycleStatusActive,
        ConceptsAndClustersFilterLifeCycleStatusInactive,
        ConceptsAndClustersFilterAvailableToOrder,
        UsersBrowse,
        UsersDetailsEdit,
        UsersLifeCycleStatusEdit,
        UsersDistributionChannelsEdit,
        UsersCountriesEdit,
        UsersConceptsEdit,
        UserEditOwnConcepts,
        AdministrationIsAdministrator,
        AdministrationIsConceptAdmin,
        ReportingAccessDatabase,
        DocumentsBrowse,
        DocumentsCreate,
        DocumentsEdit,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
}

