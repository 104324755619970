/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { CreateServiceModel } from '../model/createServiceModel';
import { Reply } from '../model/reply';
import { ReplyGuid } from '../model/replyGuid';
import { ServiceModelItem } from '../model/serviceModelItem';
import { UpdateServiceModel } from '../model/updateServiceModel';
import { UpdateServiceModelServiceLevels } from '../model/updateServiceModelServiceLevels';
import { ViewModelPagedListServiceModelItem } from '../model/viewModelPagedListServiceModelItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class ArticleManagementServiceModelsService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serviceModelsCreate(command: CreateServiceModel, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public serviceModelsCreate(command: CreateServiceModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public serviceModelsCreate(command: CreateServiceModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public serviceModelsCreate(command: CreateServiceModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling serviceModelsCreate.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/servicemodels`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Delete
     * 
     * @param serviceModelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serviceModelsDelete(serviceModelId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public serviceModelsDelete(serviceModelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public serviceModelsDelete(serviceModelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public serviceModelsDelete(serviceModelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (serviceModelId === null || serviceModelId === undefined) {
            throw new Error('Required parameter serviceModelId was null or undefined when calling serviceModelsDelete.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Reply>(
                `${this.basePath}/api/v1/articlemanagement/servicemodels/${encodeURIComponent(String(serviceModelId))}`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Get Service Model Item
     * 
     * @param serviceModelId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serviceModelsGetServiceModel(serviceModelId: string, observe?: 'body', reportProgress?: boolean): Observable<ServiceModelItem>;
    public serviceModelsGetServiceModel(serviceModelId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ServiceModelItem>>;
    public serviceModelsGetServiceModel(serviceModelId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ServiceModelItem>>;
    public serviceModelsGetServiceModel(serviceModelId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (serviceModelId === null || serviceModelId === undefined) {
            throw new Error('Required parameter serviceModelId was null or undefined when calling serviceModelsGetServiceModel.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (serviceModelId !== undefined) {
            queryParameters = queryParameters.set('serviceModelId', <any> serviceModelId);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ServiceModelItem>(
                `${this.basePath}/api/v1/articlemanagement/servicemodels/view/item`,
                
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Get Service Model Items
     * 
     * @param page 
     * @param size 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serviceModelsGetServiceModels(page?: number, size?: number, observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListServiceModelItem>;
    public serviceModelsGetServiceModels(page?: number, size?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListServiceModelItem>>;
    public serviceModelsGetServiceModels(page?: number, size?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListServiceModelItem>>;
    public serviceModelsGetServiceModels(page?: number, size?: number, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (page !== undefined) {
            queryParameters = queryParameters.set('page', <any> page);
        }
        if (size !== undefined) {
            queryParameters = queryParameters.set('size', <any> size);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelPagedListServiceModelItem>(
                `${this.basePath}/api/v1/articlemanagement/servicemodels/view/items`,
                
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serviceModelsUpdate(command: UpdateServiceModel, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public serviceModelsUpdate(command: UpdateServiceModel, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public serviceModelsUpdate(command: UpdateServiceModel, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public serviceModelsUpdate(command: UpdateServiceModel, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling serviceModelsUpdate.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/servicemodels`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Service Levels
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public serviceModelsUpdateServiceLevels(command: UpdateServiceModelServiceLevels, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public serviceModelsUpdateServiceLevels(command: UpdateServiceModelServiceLevels, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public serviceModelsUpdateServiceLevels(command: UpdateServiceModelServiceLevels, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public serviceModelsUpdateServiceLevels(command: UpdateServiceModelServiceLevels, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling serviceModelsUpdateServiceLevels.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/servicemodels/servicelevels`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
