/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { BrowsingConfiguration } from '../model/browsingConfiguration';
import { CreateArticleAssemblyFilesExport } from '../model/createArticleAssemblyFilesExport';
import { CreateBySearchArticleAssemblyFilesExportData } from '../model/createBySearchArticleAssemblyFilesExportData';
import { NonTradeItemSuggestSearchConfiguration } from '../model/nonTradeItemSuggestSearchConfiguration';
import { Reply } from '../model/reply';
import { ReplyInt64 } from '../model/replyInt64';
import { ViewModelBrowsingListNonTradeListItem } from '../model/viewModelBrowsingListNonTradeListItem';
import { ViewModelPagedListNonTradeSuggestItem } from '../model/viewModelPagedListNonTradeSuggestItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class ArticleManagementNonTradeItemsService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * CreateArticleAssemblyFilesExport
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nonTradeItemsCreateArticleAssemblyFilesExport(command: CreateArticleAssemblyFilesExport, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public nonTradeItemsCreateArticleAssemblyFilesExport(command: CreateArticleAssemblyFilesExport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public nonTradeItemsCreateArticleAssemblyFilesExport(command: CreateArticleAssemblyFilesExport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public nonTradeItemsCreateArticleAssemblyFilesExport(command: CreateArticleAssemblyFilesExport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling nonTradeItemsCreateArticleAssemblyFilesExport.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/nontradeitems/create-article-assembly-files-export`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * CreateBySearchArticleAssemblyFilesExport
     * 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nonTradeItemsCreateBySearchArticleAssemblyFilesExport(data: CreateBySearchArticleAssemblyFilesExportData, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public nonTradeItemsCreateBySearchArticleAssemblyFilesExport(data: CreateBySearchArticleAssemblyFilesExportData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public nonTradeItemsCreateBySearchArticleAssemblyFilesExport(data: CreateBySearchArticleAssemblyFilesExportData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public nonTradeItemsCreateBySearchArticleAssemblyFilesExport(data: CreateBySearchArticleAssemblyFilesExportData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling nonTradeItemsCreateBySearchArticleAssemblyFilesExport.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/nontradeitems/create-by-search-article-assembly-files-export`,
                data,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Searches non-trade-items using data from Elastic Search. Search options (configurable blocks, fields and                  aggregations)                  are preconfigured. Result ViewModelPagedList additional contains aggregations.
     * 
     * @param request 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nonTradeItemsSearch(request: BrowsingConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelBrowsingListNonTradeListItem>;
    public nonTradeItemsSearch(request: BrowsingConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelBrowsingListNonTradeListItem>>;
    public nonTradeItemsSearch(request: BrowsingConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelBrowsingListNonTradeListItem>>;
    public nonTradeItemsSearch(request: BrowsingConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling nonTradeItemsSearch.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelBrowsingListNonTradeListItem>(
                `${this.basePath}/api/v1/articlemanagement/nontradeitems/search`,
                request,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * SizeForFiles
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nonTradeItemsSizeForFiles(command: CreateArticleAssemblyFilesExport, observe?: 'body', reportProgress?: boolean): Observable<ReplyInt64>;
    public nonTradeItemsSizeForFiles(command: CreateArticleAssemblyFilesExport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyInt64>>;
    public nonTradeItemsSizeForFiles(command: CreateArticleAssemblyFilesExport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyInt64>>;
    public nonTradeItemsSizeForFiles(command: CreateArticleAssemblyFilesExport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling nonTradeItemsSizeForFiles.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyInt64>(
                `${this.basePath}/api/v1/articlemanagement/nontradeitems/size-for-files`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * SizeForFilesBySearch
     * 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nonTradeItemsSizeForFilesBySearch(data: CreateBySearchArticleAssemblyFilesExportData, observe?: 'body', reportProgress?: boolean): Observable<ReplyInt64>;
    public nonTradeItemsSizeForFilesBySearch(data: CreateBySearchArticleAssemblyFilesExportData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyInt64>>;
    public nonTradeItemsSizeForFilesBySearch(data: CreateBySearchArticleAssemblyFilesExportData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyInt64>>;
    public nonTradeItemsSizeForFilesBySearch(data: CreateBySearchArticleAssemblyFilesExportData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling nonTradeItemsSizeForFilesBySearch.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyInt64>(
                `${this.basePath}/api/v1/articlemanagement/nontradeitems/size-for-files-by-search`,
                data,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Searches non-trade-items using data from Elastic Search.
     * 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public nonTradeItemsSuggest(configuration: NonTradeItemSuggestSearchConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListNonTradeSuggestItem>;
    public nonTradeItemsSuggest(configuration: NonTradeItemSuggestSearchConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListNonTradeSuggestItem>>;
    public nonTradeItemsSuggest(configuration: NonTradeItemSuggestSearchConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListNonTradeSuggestItem>>;
    public nonTradeItemsSuggest(configuration: NonTradeItemSuggestSearchConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (configuration === null || configuration === undefined) {
            throw new Error('Required parameter configuration was null or undefined when calling nonTradeItemsSuggest.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelPagedListNonTradeSuggestItem>(
                `${this.basePath}/api/v1/articlemanagement/nontradeitems/suggest`,
                configuration,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
