<ng-template #dialogHeaderTemplate>
    <h2 class="standard-dialog--headline">Copy a Concept</h2>
</ng-template>

<ng-template #dialogContentTemplate>
    <div
        [rspLoadingIndicator]="isLoading"
        class="standard-dialog--content"
        style="display: flex"
    >
        <div class="copy-concept-dialog--form">
            <rsp-concept-suggester
                [displayValue]="sourceConcept?.name"
                (selected)="setSourceConcept( $event )"
            >
                <rsp-error-messages [formErrors]="form.get('sourceConceptId').errors"></rsp-error-messages>
            </rsp-concept-suggester>

            <span class="copy-concept-dialog--arrow">→</span>

            <rsp-display-edit-text
                [isEditMode]="true"
                [myFormGroup]="form"
                [name]="'name'"
                [placeholder]="'Concept name'"
            ></rsp-display-edit-text>
        </div>
    </div>
</ng-template>

<ng-template #dialogFooterTemplate>
    <div class="standard-dialog--footer">
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'button'"
            [submodule]="'primary'"
            [disabled]="!form.valid"
            (clicked)="doAction()"
            class="copy-concept-dialog--submit-button"
        >
            Copy
        </rsp-button>
    </div>
</ng-template>
