import { Component, ViewChild, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { ConceptClusterSelectionService } from '../../shared/concept-cluster-selection.service';
import { MainSlideInService } from '../../../../../core/overlay/main-slide-in/main-slide-in.service';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { EnumConverterService, EnumValue } from '../../../../../shared/utils/enum/enum-converter.service';
import { Action } from '../../../../../shared/ui/lists/actions/list-actions.model';
import { CreateConceptDialogComponent } from './actions/create-concept-dialog/create-concept-dialog.component';
import { CreateClusterDialogComponent } from '../../../shared/actions/create-cluster-dialog/create-cluster-dialog.component';
import { CopyConceptDialogComponent } from './actions/copy-concept-dialog/copy-concept-dialog.component';
import { CurrentUserContext } from '../../../../../shared/model';

import { flatten } from 'lodash';

enum ConceptOrClusterItemActions {
    CreateConcept,
    CreateCluster,
    CopyCluster,
}

// Note: The order in this enum is order in UI.
enum ConceptClusterActionGroup {
    Concept,
    Cluster,
}

namespace ConceptClusterActionGroup {
    export function toString( enumValue: ConceptClusterActionGroup ): string {
        return ConceptClusterActionGroup[ enumValue ];
    }
}

export interface ConceptClusterAction extends Action {
    dialog: ConceptOrClusterItemActions;
}

/**
 * Handles actions for concept cluster list.
 */
@Component( {
    selector:    'rsp-concept-cluster-list-actions',
    templateUrl: './concept-cluster-list-actions.component.html',
    styleUrls:   [
        './concept-cluster-list-actions.component.scss',
    ],
} )
export class ConceptClusterListActionsComponent implements OnDestroy {
    @ViewChild( CreateConceptDialogComponent, { static: true } ) createConceptDialog: CreateConceptDialogComponent;
    @ViewChild( CreateClusterDialogComponent, { static: true } ) createClusterDialog: CreateClusterDialogComponent;
    @ViewChild( CopyConceptDialogComponent, { static: true } ) copyClusterDialog: CopyConceptDialogComponent;

    /**
     * Actions definition.
     */
    actionGroups: string[]                                                  = [];
    actionsForGroup: Map<ConceptClusterActionGroup, ConceptClusterAction[]> = new Map();
    hasAnyAction: boolean                                                   = false;

    hasSelectedItems: Observable<boolean>;

    isAlive: boolean = true;

    constructor(
        private conceptClusterSelectionService: ConceptClusterSelectionService,
        private mainSlideInService: MainSlideInService,
        private currentUserService: CurrentUserService,
    ) {
        this.hasSelectedItems = this.conceptClusterSelectionService.hasSelectedItems$.pipe( distinctUntilChanged() );
        this.buildActions();
    }

    ngOnDestroy(): void {
        this.isAlive = false;
    }

    selectAction( action: ConceptClusterAction ): void {
        switch ( action.dialog ) {
            case ConceptOrClusterItemActions.CreateConcept:
                this.createConceptDialog.open();
                break;

            case ConceptOrClusterItemActions.CreateCluster:
                this.createClusterDialog.open();
                break;

            case ConceptOrClusterItemActions.CopyCluster:
                this.copyClusterDialog.open();
                break;

            default:
                break;
        }

        this.closeSlideIn();
    }

    closeSlideIn(): void {
        this.mainSlideInService.closeSlideIn();
    }

    private buildActions(): void {
        this.actionGroups = [];

        EnumConverterService.convertToEnumValueList( ConceptClusterActionGroup, 'index' ).forEach( ( enumValue: EnumValue ) => {
            this.actionGroups.push( enumValue.name );
            this.actionsForGroup.set( enumValue.index, [] );
        } );

        this.currentUserService
            .getCurrentUserAccessRightSet()
            .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersConceptCreate ) ) {
                    this.actionsForGroup.get( ConceptClusterActionGroup.Concept ).push( {
                        label:             'Create Concept',
                        dialog:            ConceptOrClusterItemActions.CreateConcept,
                        requiresSelection: false,
                    } );

                    this.actionsForGroup.get( ConceptClusterActionGroup.Concept ).push( {
                        label:             'Copy a Concept',
                        dialog:            ConceptOrClusterItemActions.CopyCluster,
                        requiresSelection: false,
                    } );

                    this.hasAnyAction = true;
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterCreate ) ) {
                    this.actionsForGroup.get( ConceptClusterActionGroup.Cluster ).push( {
                        label:             'Create Cluster',
                        dialog:            ConceptOrClusterItemActions.CreateCluster,
                        requiresSelection: false,
                    } );

                    this.hasAnyAction = true;
                }

                // set selection available by count of items which require selection
                this.conceptClusterSelectionService.setSelectionAvailable(
                    flatten(
                        Array.from( this.actionsForGroup.values() ) as ConceptClusterAction[][],
                    ).filter( ( item: ConceptClusterAction ) => item.requiresSelection ).length > 0,
                );
            } );
    }
}
