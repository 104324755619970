import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StorageService, STORAGE_KEYS } from '../../../../core/storage.service';
import { NonTradeListItem } from '../../../../shared/model/nonTradeListItem';
import { BreadcrumbService } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb.service';
import { NonTradeItemStoreService } from '../shared/non-trade-item-store.service';
import { MainLoadingIndicatorService } from '../../../../core/main-loading-indicator/main-loading-indicator.service';
import { NonTradeItemSelectionService } from '../shared/non-trade-item-selection.service';
import { ViewSwitcherService } from '../../../../shared/ui/secondary-toolbar/view-switcher/view-switcher.service';
import { BaseListPageWithAlternativeViewsComponent } from '../../../../shared/ui/lists/base-list-page-with-alternative-views.component';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';


// This is mostly the same as ConceptListPageComponent. Should we do a base class or something like that?
@Component( {
    selector:    'rsp-non-trade-item-list-page',
    templateUrl: './non-trade-item-list-page.component.html',
    providers:   [
        BreadcrumbService,
    ],
    styleUrls:   [
        './non-trade-item-list-page.component.scss',
    ],
} )
export class NonTradeItemListPageComponent extends BaseListPageWithAlternativeViewsComponent<NonTradeListItem> implements OnInit {

    get storageKeyIsSidebarVisible(): string { return STORAGE_KEYS.nonTradeItems.list.isSidebarVisible; }

    get storageKeyScrollToId(): string { return STORAGE_KEYS.nonTradeItems.list.scrollToId; }


    // TODO: Remove nonTradeItem-prefix of member variables so one could more easily compare let's say non-trade-item-list-page vs. concept-cluster-list-page.
    constructor(
        private toolbarService: SecondaryToolbarService,
        router: Router,
        activatedRoute: ActivatedRoute,
        mainLoadingIndicatorService: MainLoadingIndicatorService,
        selectionService: NonTradeItemSelectionService,
        storageService: StorageService,
        storeService: NonTradeItemStoreService,
        viewSwitcherService: ViewSwitcherService,
    ) {
        super(
            viewSwitcherService,
            router,
            activatedRoute,
            storeService,
            selectionService,
            storageService,
            mainLoadingIndicatorService,
        );

        // configure toolbar
        // NOTE: previously it was in ngOnInit, but it throws a ExpressionChangedAfterItHasBeenCheckedError exception.
        // We are not sure why it occurs ... maybe something with named outlet (where toolbar is placed) and change detection.
        // This issue occurs in angular > 4.2.5.
        // https://github.com/angular/angular/issues/17572
        this.toolbarService.showViewSwitcher();
        this.toolbarService.showStoreInfo();
        this.toolbarService.showSortBy();
        this.toolbarService.hidePrevNextNavigation();
    }


    ngOnInit(): void {
        super.ngOnInit();
    }
}
