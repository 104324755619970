<rsp-supplementary-caption>{{ headline }}</rsp-supplementary-caption>

<ul class="simple-list">
    <li *ngFor="let item of items | async" class="simple-list--item">
        <div class="simple-list--item-image">
            <rsp-image
                [height]="40"
                [width]="57"
                [fileId]="item.previewImageId"
                [alt]="item.number + ' - ' + item.name"
                [alignImg]="true"
            ></rsp-image>
        </div>
        <div class="simple-list--item-name-and-number-container">
            <span class="simple-list--item-number" >{{item.number}}</span>
            <span
                title="{{item.name}}"
                class="simple-list--item-name"
            >{{item.name}}</span>
        </div>
        <div class="simple-list--item-status">
            <rsp-status
                [displayMode]="'badge'"
                [status]="item.status"
                [type]="statusTypeEnum"
            ></rsp-status>
        </div>
        <rsp-remove
            [title]="'Remove item'"
            [text]="'Do you really want to remove this item from selection?'"
            (confirmClicked)="removeItemFromSelection( item )"
            class="simple-list--item-remove"
        >
        </rsp-remove>
    </li>
</ul>
