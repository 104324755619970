/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface UserInfo {
    id?: string;

    firstName?: string;

    familyName?: string;

    email?: string;

    type?: UserInfo.TypeEnum;

}

export namespace UserInfo {
    'use strict';
    export enum TypeEnum {
        Internal,
        External,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
}

