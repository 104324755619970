import { Injectable } from '@angular/core';
import { DisplayEditOption } from '../../forms/controls/display-edit-options/display-edit-options.component';
import { RadioButtonGroupItem } from '../../ui/radio-button-group/radio-button-group.model';
import { StringConverterService } from '../string/string-converter.service';

export interface EnumValue {
    index: number;
    name: string;
}

interface RspModelEnum {
    toString( index: number ): string;
}

@Injectable()
export class EnumConverterService {

    /**
     * Transforms something like
     * `enum { FooBar, Bar, Baz }`
     * into `[ { index: 1, name: 'Bar' }, { index: 2, name: 'Baz' }, { index: 0, name: 'Foo Bar' } ]`.
     *
     * Useful if you want to generate options (like radio button group options) from an enum.
     */
    static convertToEnumValueList( theEnum: RspModelEnum, sortBy: 'index' | 'name' = 'name' ): EnumValue[] {
        return Object
            .keys( theEnum )
            .map( ( enumIndex: string ) => parseInt( enumIndex, 10 ) )
            .filter( ( enumIndex: number ) => isFinite( enumIndex ) )
            .map( ( enumIndex: number ) => {
                return {
                    index: enumIndex,
                    name:  EnumConverterService.getHumanReadableEnumValue( theEnum, enumIndex ),
                };
            } )
            .sort( ( aEnumValue: EnumValue, bEnumValue: EnumValue ) => {
                if ( sortBy === 'name' ) {
                    if ( aEnumValue.name < bEnumValue.name ) {
                        return -1;
                    }
                    if ( aEnumValue.name > bEnumValue.name ) {
                        return 1;
                    }
                }
                else if ( sortBy === 'index' ) {
                    if ( aEnumValue.index < bEnumValue.index ) {
                        return -1;
                    }
                    if ( aEnumValue.index > bEnumValue.index ) {
                        return 1;
                    }
                }

                return 0;
            } );
    }

    /**
     * The same as convertToEnumValueList but saves the you the additional map to a `DisplayEditOption`.
     */
    static convertToDisplayEditOptions( theEnum: RspModelEnum, sortBy: 'index' | 'name' = 'name' ): DisplayEditOption[] {
        return EnumConverterService
            .convertToEnumValueList( theEnum, sortBy )
            .map( ( enumValue: EnumValue ) => {
                return {
                    id:   enumValue.index,
                    name: enumValue.name,
                };
            } );
    }

    /**
     * The same as convertToEnumValueList but saves you the additional map to a 'RadioButtonGroupItem'
     */
    static convertToRadioButtonGroupItems( theEnum: RspModelEnum, sortBy: 'index' | 'name' = 'name' ): RadioButtonGroupItem[] {
        return EnumConverterService.convertToEnumValueList( theEnum, sortBy )
                                   .map( ( enumValue: EnumValue ) => {
                                       return {
                                           id:      enumValue.index,
                                           caption: enumValue.name,
                                       };
                                   } );
    }

    /**
     * NOTE: JF 2018-01-03 - this method works correctly only with autogenerated enums, because they have toString() method.
     *                       Example from the documentation does not work!
     *                       We could improve it and replace
     *
     *                                      const enumValue: string = theEnum.toString( enumIndex );
     *
     *                                      with:
     *
     *                                      const enumValue: string = theEnum[ enumIndex ];
     *
     *
     * Transforms a certain enum value into a user friendly string representation.
     *
     * ```
     *     enum DeveloperEnum {
     *         JacekFidot,
     *         KonstantinJaeger,
     *         PhilippSoehnlein,
     *     };
     *     EnumConverterService.getHumanReadableEnumValue( DeveloperEnum, DeveloperEnum.JacekFidot ); // returns 'Jacek Fidot'
     * ```
     */
    static getHumanReadableEnumValue( theEnum: RspModelEnum, enumIndex: number ): string {
        const enumValue: string = theEnum.toString( enumIndex );

        return  StringConverterService.splitCamelcaseWithSpace( enumValue );
    }

    /**
     * The opposite of getHumanReadableEnumValue():
     *
     * ```
     *     enum DeveloperEnum {
     *         JacekFidot,
     *         KonstantinJaeger,
     *         PhilippSoehnlein,
     *     };
     *
     *     const name: string = EnumConverterService.getHumanReadableEnumValue( DeveloperEnum, DeveloperEnum.JacekFidot );
     *     EnumConverterService.getIndexFromHumanReadableEnumValue( DeveloperEnum, name ); // return 0
     *
     * ```
     */
    static getIndexFromHumanReadableEnumValue( theEnum: RspModelEnum, humanReadableValue: string ): number {
        const enumValueList: EnumValue[] = EnumConverterService.convertToEnumValueList( theEnum, 'index' );
        return enumValueList.findIndex( ( enumValue: EnumValue ) => humanReadableValue === enumValue.name );
    }
}
