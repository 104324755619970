<ol class="progress-step-view">
    <li
        *ngFor="let step of steps; let index = index"
        [attr.title]="!showStepNames ? ( index + 1 ) + '. ' + step : null"
        [class.is-progress-step-view-step-active]="index + 1 <= currentStep"
        class="progress-step-view--step"
    >
        <span class="progress-step-view--step-number"></span>
        <span
            *ngIf="showStepNames"
            class="progress-step-view--step-name"
        >{{ step }}</span>
    </li>
</ol>
