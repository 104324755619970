import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ArticleManagementMaterialsService } from '../../../../../shared/api';
import { ViewModelPagedListMaterialInfo } from '../../../../../shared/model/viewModelPagedListMaterialInfo';
import { MaterialInfo } from '../../../../../shared/model/materialInfo';

@Component( {
    selector:    'rsp-material-suggester',
    templateUrl: './material-suggester.component.html',
    styleUrls:   [ './material-suggester.component.scss' ],
} )
export class MaterialSuggesterComponent {
    @Input() displayValue: string;
    @Input() filterIds: Array<string>;
    @Input() withClear: boolean = false;

    @Output() selected: EventEmitter<MaterialInfo> = new EventEmitter();

    items: Array<MaterialInfo> = [];

    isLoading: Subscription;

    constructor(
        private materialsApi: ArticleManagementMaterialsService,
    ) { }

    searchMaterial( searchTerm: string ): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }

        if ( !searchTerm || !searchTerm.trim() ) {
            searchTerm = '*';
        }

        this.isLoading =
            this.materialsApi.materialsSuggestMaterialsForArticles( {
                term:      searchTerm,
                ignoreIds: this.filterIds && this.filterIds.length ? this.filterIds : undefined,
                page:      1,
                size:      50,
            } )
                .subscribe( ( searchResult: ViewModelPagedListMaterialInfo ) => {
                    this.items = searchResult.data;
                } );
    }


    selectedItem( info: MaterialInfo ): void {
        this.selected.emit( info );
    }
}
