import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable , Subject, Subscription ,  merge ,  forkJoin, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../../../core/overlay/notification/notification.service';
import { UserEditContext } from '../user.model';
import { UserService } from '../user.service';
import { ConceptListItem, Reply, UpdateUserConcepts, ViewModelListConceptListItem } from '../../../model/index';
import { UserManagementCurrentUserService, UserManagementUsersService } from '../../../api/index';
import { SecondaryToolbarService } from '../../secondary-toolbar/secondary-toolbar.service';
import { loadWithProgressIndicator } from '../../../utils/rxjs-extensions/load-with-progress-indicator.extension';
import { CurrentUserContext } from '../../../model';
import { CurrentUserService } from '../../../../core/current-user.service';

@Component( {
    selector:    'rsp-user-concepts',
    templateUrl: './user-concepts.component.html',
    styleUrls:   [
        '../../../scss/05_module/detail-page-tab.scss',
        './user-concepts.component.scss',
    ],
} )
export class UserConceptsComponent implements OnDestroy, OnInit {
    mode: UserEditContext;

    allConcepts: ConceptListItem[];
    userConcepts: ConceptListItem[];
    conceptsToSave: ConceptListItem[] | null = null;
    isEditMode: boolean                      = false;
    hasEditRight: boolean                    = true;
    noConceptsMessage: string;

    isLoading: Subscription;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private userService: UserService,
        private currentUserService: CurrentUserService,
        private toolbarService: SecondaryToolbarService,
        private notificationService: NotificationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private userManagementUsersService: UserManagementUsersService,
        private userManagementCurrentUserService: UserManagementCurrentUserService,
    ) {
    }

    ngOnInit(): void {
        this.mode              = this.activatedRoute.snapshot.data.userEditContext;
        this.noConceptsMessage = `No concepts assigned to ${ this.mode === 'my-profile' ? 'you' : 'this user' }, yet.`;

        if ( this.mode === 'user-management' ) {
            this.currentUserService
                .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.UsersConceptsEdit )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( hasRight: boolean ) => {
                    this.hasEditRight = hasRight;
                } );
        }
        else if ( this.mode === 'my-profile' ) {
            this.currentUserService
                .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.UserEditOwnConcepts )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( hasRight: boolean ) => {
                    this.hasEditRight = hasRight;
                } );
        }

        // Two entry points:
        //  - of( true ) - first component load
        //  - summary$              - prev/next navigation
        merge( of( true ), this.userService.summary$ )
            .pipe(
                loadWithProgressIndicator(
                    () => forkJoin(
                        ... (
                            this.mode === 'my-profile'
                                ? [
                                    this.userManagementCurrentUserService.currentUserGetConceptsAssignable(),
                                    this.userManagementCurrentUserService.currentUserGetConcepts(),
                                ]
                                : [
                                    this.userManagementUsersService.usersGetConceptsAssignable(),
                                    this.userManagementUsersService.usersGetConcepts( this.userService.userId ),
                                ]
                        ),
                    ),
                    this,
                ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: ViewModelListConceptListItem[] ) => {
                this.initialize( result[ 0 ].data, result[ 1 ].data );
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    startEditMode( doNavigate: boolean = true ): void {
        this.isEditMode = true;
        this.toolbarService.hidePrevNextNavigation();
        this.conceptsToSave = this.userConcepts;

        if ( doNavigate ) {
            this.router.navigate( [ 'edit' ], { relativeTo: this.activatedRoute } );
        }
    }

    endEditMode(): void {
        this.isEditMode = false;
        this.toolbarService.showPrevNextNavigation();
        this.conceptsToSave = null;
        this.router.navigate( [ '../' ], { relativeTo: this.activatedRoute } );
    }

    setConceptsToSave( conceptsToSave: ConceptListItem[] ): void {
        this.conceptsToSave = conceptsToSave;
    }

    saveConcepts(): void {
        const command: UpdateUserConcepts = {
            userId:   this.userService.userId,
            concepts: this.conceptsToSave.map( ( concept: ConceptListItem ) => concept.id ),
        };

        const backendCall: Observable<Reply> = this.mode === 'my-profile'
            ? this.userManagementCurrentUserService.currentUserUpdateConcepts( command )
            : this.userManagementUsersService.usersUpdateConcepts( this.userService.userId, command );

        this.isLoading = backendCall
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                const message: string =
                          this.conceptsToSave.length > 0
                              ? (this.mode === 'my-profile' ? 'You now have' : 'This user now has') + ' ' + this.conceptsToSave.length +
                              ' concept' + (this.conceptsToSave.length !== 1 ? 's' : '') + ' assigned.'
                              : (this.mode === 'my-profile' ? 'You don\'t' : 'This user now doesn\'t') + ' have any concepts assigned anymore.';

                this.notificationService.success( message, 'Concepts updated' );

                this.userService.getAndPublishSummary();

                this.userConcepts = this.conceptsToSave;

                this.endEditMode();
            } );
    }

    private initialize( allConcepts: ConceptListItem[], userConcepts: ConceptListItem[] ): void {
        this.allConcepts  = allConcepts;
        this.userConcepts = userConcepts;

        if ( this.activatedRoute.snapshot.data[ 'isEditMode' ] ) {
            const doNavigate: boolean = false; // we are already on the edit route, don't navigate again
            this.startEditMode( doNavigate );
        }
    }
}
