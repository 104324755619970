<rsp-registration-wizard-section-headline [isDone]="selectedDistributionChannels.length">
    Distribution Channels
</rsp-registration-wizard-section-headline>

<rsp-registration-wizard-section-content>
    <rsp-distribution-channels
        [distributionChannels]="distributionChannels"
        [selectedDistributionChannels]="selectedDistributionChannels"
        [isEditMode]="true"
        [isRequired]="true"
        (selectionChanged)="setSelectedDistributionChannels( $event )"
    ></rsp-distribution-channels>
</rsp-registration-wizard-section-content>
