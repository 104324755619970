import { Injectable } from '@angular/core';

export interface OverlayPositionCoordinates {
    top: number;
    left: number;
    aboveTriggerElement: boolean;
}

@Injectable()
export class OverlayPositionService {

    getOverlayPosition( overlayElement: HTMLElement, triggerElement: HTMLElement ): OverlayPositionCoordinates {
        const viewportRect: ClientRect       = window.document.documentElement.getBoundingClientRect();
        const overlayRect: ClientRect        = overlayElement.getBoundingClientRect();
        const triggerElementRect: ClientRect = triggerElement.getBoundingClientRect();

        let top: number                  = 0;
        let aboveTriggerElement: boolean = false;

        if ( viewportRect.height - triggerElementRect.bottom > overlayRect.height ) {
            // below the trigger element
            top = triggerElementRect.bottom;
        }
        else {
            // above the trigger element
            top                 = triggerElementRect.top - overlayRect.height;
            aboveTriggerElement = true;
        }

        let left: number = triggerElementRect.left;

        if ( triggerElementRect.left + overlayRect.width > viewportRect.width ) {
            left = triggerElementRect.right - overlayRect.width;
        }

        return {
            top:                 top,
            left:                left,
            aboveTriggerElement: aboveTriggerElement,
        };
    }


    getCenteredPosition( element: HTMLElement ): OverlayPositionCoordinates {
        const viewPortHeight: number = document.documentElement.getBoundingClientRect().height;
        const viewPortWidth: number  = document.documentElement.getBoundingClientRect().width;
        const elementHeight: number    = element.getBoundingClientRect().height;
        const elementWidth: number     = element.getBoundingClientRect().width;

        return {
            top:                 viewPortHeight > elementHeight ? ( viewPortHeight - elementHeight ) / 2 : 0,
            left:                viewPortWidth > elementWidth ? ( viewPortWidth - elementWidth ) / 2 : 0,
            aboveTriggerElement: false,
        };
    }
}
