import { Component, Input, } from '@angular/core';
import { ClusterDetail } from '../../../../../../../shared/model/clusterDetail';
import { UntypedFormGroup } from '@angular/forms';
import { DistributionChannelListItem } from '../../../../../../../shared/model/distributionChannelListItem';

@Component( {
    selector:    'rsp-display-edit-wholesale',
    templateUrl: './display-edit-wholesale.component.html',
    styleUrls:   [
        './display-edit-wholesale.component.scss',
    ],
} )
export class DisplayEditWholesaleComponent {
    @Input()
    set clusterDetails( value: ClusterDetail ) { this.setClusterDetails( value ); }
    get clusterDetails(): ClusterDetail { return this._clusterDetails; }

    @Input() myFormGroup: UntypedFormGroup;
    @Input() isEditMode: boolean = false;

    isActive: boolean = false;

    private _clusterDetails: ClusterDetail;

    private setClusterDetails( value: ClusterDetail ): void {
        this._clusterDetails = value;
        this.isActive = value.distributionChannels.findIndex( ( item: DistributionChannelListItem ) => item.name === 'Wholesale' ) !== -1;
    }
}
