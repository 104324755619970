import { ArticleInfo } from '../../model/articleInfo';
import { AssemblyInfo } from '../../model/assemblyInfo';
import { SPMGroupInfo } from '../../model/sPMGroupInfo';

type StatusType = ArticleInfo.StatusEnum | AssemblyInfo.StatusEnum;


export class NonTradeItemStatus {
    static isNonTradeItemReadyToOrder( status: StatusType ): boolean {
        return status === ArticleInfo.StatusEnum.ActiveInRollout || status === ArticleInfo.StatusEnum.ActiveInPreRollout
            || status === ArticleInfo.StatusEnum.ActiveOnDemand || status === ArticleInfo.StatusEnum.Discontinue;
    }

    static isNonTradeItemReadyToForecast( status: StatusType, spmGroup: SPMGroupInfo | SPMGroupInfo[] ): boolean {
        const validSPMGroup: boolean =
                  Array.isArray( spmGroup )
                      ? !!( spmGroup as Array<SPMGroupInfo> ).find( ( group: SPMGroupInfo ) => !group || this.isSpmGroupValid( group.name ) )
                      : !spmGroup || this.isSpmGroupValid( spmGroup.name );

        return status === ArticleInfo.StatusEnum.ActiveInRollout && validSPMGroup;
    }

    private static isSpmGroupValid( name: string ): boolean {
        return name === 'Sales Support Items';
    }
}
