<rsp-registration-wizard-section-headline [isDone]="selectedConcepts.length > 0">
    Concepts
</rsp-registration-wizard-section-headline>

<rsp-registration-wizard-section-content>
    <rsp-concepts
        [concepts]="concepts"
        [selectedConcepts]="selectedConcepts"
        [isEditMode]="true"
        (selectionChanged)="setSelectedConcepts( $event )"
    ></rsp-concepts>
</rsp-registration-wizard-section-content>
