<rsp-dropdown
    [isLoading]="isLoading"
    [placeholder]="'Search subcomponents'"
    [withSuggester]="true"
    (searchTermChanged)="searchItems( $event )"
    (selected)="selectedItem( $event )"
>
    <rsp-dropdown-item *ngFor="let item of items" [item]="item">
        {{ item.number }}
        <span *ngIf="!item.id" class="subcomponent-new-item">(add as new)</span>
    </rsp-dropdown-item>
</rsp-dropdown>
<ng-content></ng-content>
