<div class="confirm--backdrop"></div>
<div #dialog class="confirm--dialog" >

    <div #target></div>
    <div *ngIf="withReason" class="confirm--dialog-buttons">
        <rsp-edit-text
            [size]="40"
            [myFormControl]="form.get( 'reason' )"
            [placeholder]="'Reason for change'"
        ></rsp-edit-text>
    </div>
    <div class="confirm--dialog-buttons">
        <rsp-button [submodule]="'secondary'" (clicked)="clickCancel()" class="confirm--dialog-buttons-with-margin">
            {{config?.cancelButtonCaption || 'Cancel'}}
        </rsp-button>
        <rsp-button [submodule]="'primary'" (clicked)="clickConfirm( form.get( 'reason' ).value )">
            {{config?.confirmButtonCaption || 'Ok'}}
        </rsp-button>
    </div>
</div>
