<div class="tab">
    <div class="tab--toolbar">

    </div>

    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <div class="l-two-columns-1200">
            <div class="l-two-columns-1200--column">
                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline><abbr title="Single Product Master">SPM</abbr> Group</rsp-card-headline>
                        <rsp-card-content>
                            <ng-container *ngIf="spmDetails?.spmGroup">
                                {{ spmDetails?.spmGroup?.productGroupCode }} - {{ spmDetails?.spmGroup?.name }} ({{
                                spmDetails?.spmGroup?.abbreviation }})
                            </ng-container>
                            <ng-container *ngIf="!spmDetails?.spmGroup">
                                <rsp-not-available></rsp-not-available>
                            </ng-container>
                        </rsp-card-content>
                    </rsp-card>
                </div>

                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline><abbr title="Single Product Master">SPM</abbr> Type</rsp-card-headline>
                        <rsp-card-content>
                            <ng-container *ngIf="spmDetails?.spmType">
                                {{ spmDetails?.spmType?.productTypeCode }} - {{ spmDetails?.spmType?.name }} ({{
                                spmDetails?.spmType?.abbreviation }})
                            </ng-container>
                            <ng-container *ngIf="!spmDetails?.spmType">
                                <rsp-not-available></rsp-not-available>
                            </ng-container>
                        </rsp-card-content>
                    </rsp-card>
                </div>

                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline><abbr title="Single Product Master">SPM</abbr> Brand</rsp-card-headline>
                        <rsp-card-content>
                            <ng-container *ngIf="spmDetails?.spmBrand?.name">
                                {{ spmDetails?.spmBrand?.code }} - {{ spmDetails?.spmBrand?.name }}
                            </ng-container>
                            <ng-container *ngIf="!spmDetails?.spmBrand?.name">
                                <rsp-not-available></rsp-not-available>
                            </ng-container>
                        </rsp-card-content>
                    </rsp-card>
                </div>

                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline>Last Transfer</rsp-card-headline>
                        <rsp-card-content>
                            <nur-date-time
                                *ngIf="spmDetails?.lastTransfer"
                                [datetime]="spmDetails?.lastTransfer"
                                [format]="'HumanReadable'"
                            ></nur-date-time>

                            <rsp-not-available *ngIf="!spmDetails?.lastTransfer"></rsp-not-available>
                        </rsp-card-content>
                    </rsp-card>
                </div>
            </div>

            <div class="l-two-columns-1200--column">
                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline>Group Model Number</rsp-card-headline>
                        <rsp-card-content>{{ spmDetails?.groupModelNumber }}</rsp-card-content>
                    </rsp-card>
                </div>

                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline>Active in <abbr title="Single Product Master">SPM</abbr></rsp-card-headline>
                        <rsp-card-content>{{ spmDetails?.activeInSPM ? 'Yes' : 'No' }}</rsp-card-content>
                    </rsp-card>
                </div>

                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline>Order Locked?</rsp-card-headline>
                        <rsp-card-content>{{ spmDetails?.orderLocked ? 'Yes' : 'No' }}</rsp-card-content>
                    </rsp-card>
                </div>
            </div>
        </div>
    </div>
</div>
