import { Injectable, } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, } from '@angular/router';
import { Observable, } from 'rxjs';

import { NonTradeItemType } from '../shared/non-trade-item-type.model';
import { NonTradeItemService } from '../shared/non-trade-item.service';
import { ArticleSummary } from '../../../../shared/model/articleSummary';
import { AssemblySummary } from '../../../../shared/model/assemblySummary';
import { map } from 'rxjs/operators';


@Injectable()
export class NonTradeItemSummaryResolver implements Resolve<ArticleSummary>, Resolve<AssemblySummary> {


    constructor(
        private nonTradeItemService: NonTradeItemService,
    ) {
    }

    resolve( route: ActivatedRouteSnapshot ): Observable<ArticleSummary> | Observable<AssemblySummary> {

        // article or assembly number
        const itemNumber: string = route.params[ 'no' ];

        // 'article' or 'assembly'. Set in app.routes as route data.
        const itemType: NonTradeItemType = <NonTradeItemType> route.data[ 'itemType' ];


        switch ( itemType ) {

            case NonTradeItemType.Article:
                return this.nonTradeItemService
                           .getArticleSummaryByNumber( itemNumber )
                           .pipe(
                               map( ( result: ArticleSummary ) => {

                                   if ( result ) {
                                       return result;
                                   }

                                   return null;
                               } ),
                           );

            case NonTradeItemType.Assembly:
                return this.nonTradeItemService
                           .getAssemblySummaryByNumber( itemNumber )
                           .pipe(
                               map( ( result: AssemblySummary ) => {

                                   if ( result ) {
                                       return result;
                                   }

                                   return null;
                               } ),
                           );

            default:
                throw new Error( 'Unsupported itemType: \'' + itemType + '\'. Please check route configuration.' );
        }
    }
}
