import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Subscription, of, merge, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ArticleManagementArticlesService } from '../../../../../shared/api/articleManagementArticles.service';
import { ViewModelListArticleAssembly } from '../../../../../shared/model/viewModelListArticleAssembly';
import { ArticleAssembly } from '../../../../../shared/model/articleAssembly';
import { CollectionView } from '../../../../../shared/ui/tables/collection-view';
import { StandardFilters } from '../../../../../shared/ui/tables/filters/standard-filters';
import { NonTradeItemService, } from '../../shared/non-trade-item.service';
import { EntityTypeEnum } from '../../../../../shared/model/enum/entity-type.enum';
import { ArticleAssemblyCollectionViewItem } from './article-assembly-collection-view-item.model';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';


@Component( {
    selector:    'rsp-non-trade-item-article-assembly-usage',
    templateUrl: './non-trade-item-article-assembly-usage.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './non-trade-item-article-assembly-usage.component.scss',
    ],
} )
export class NonTradeItemArticleAssemblyUsageComponent implements OnInit, OnDestroy, HasLoadingIndicator {


    collectionView: CollectionView<ArticleAssemblyCollectionViewItem> = new CollectionView<ArticleAssemblyCollectionViewItem>();
    isLoading: Subscription;

    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.NonTradeItem;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private nonTradeItemService: NonTradeItemService,
        private articlesApi: ArticleManagementArticlesService,
        private standardFilters: StandardFilters,
    ) {
    }

    ngOnInit(): void {

        this.initializeTableFeatures();

        // Two entry points:
        //  - of( true ) - first component load
        //  - currentNonTradeItem$ - prev/next navigation
        merge( of( true ), this.nonTradeItemService.currentNonTradeItem$ )
            .pipe(
                distinctUntilChanged(),
                loadWithProgressIndicator( () => this.articlesApi.articlesGetArticleAssemblies( this.nonTradeItemService.getCurrentId() ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( items: ViewModelListArticleAssembly ) => {

                this.collectionView
                    .setSourceItems(
                        items.data.map( ( articleAssembly: ArticleAssembly ) => {
                            return new ArticleAssemblyCollectionViewItem( articleAssembly );
                        } ),
                    )
                    .refresh();
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }


    private initializeTableFeatures(): void {

        // filter function
        this.collectionView.setGlobalFilterFunction( ( item: ArticleAssemblyCollectionViewItem, filter: string ) => {

            if ( typeof filter === 'undefined' || filter === null ) {
                return true;
            }

            if ( this.standardFilters.contains( item.name, filter )
                || this.standardFilters.contains( item.number, filter )
                || this.standardFilters.contains( item.qunatity, filter )
                || this.standardFilters.contains( item.status, filter ) ) {
                return true;
            }

            return false;
        } );
    }

}
