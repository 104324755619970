import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ArticleRequiredFile, RequiredFile } from '../../../../../../shared/model';
import { CollectionView, SortDescriptor } from '../../../../../../shared/ui/tables/collection-view';

@Component( {
    selector:        'rsp-card-required-files',
    templateUrl:     './card-required-files.component.html',
    styleUrls:       [ './card-required-files.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class CardRequiredFilesComponent implements OnChanges {
    @Input() requiredFiles: ArticleRequiredFile[] = [];
    @Input() myFormControl!: UntypedFormControl;
    @Input() isEditMode: boolean = false;

    public collectionView: CollectionView<ArticleRequiredFile> = new CollectionView();

    public static buildFormControl( requiredFiles: ArticleRequiredFile[] ): UntypedFormControl {
        const formControlValue: RequiredFile[] = CardRequiredFilesComponent.mapArticleRequiredFileToRequiredFile( requiredFiles );

        return new UntypedFormControl( formControlValue );
    }

    public ngOnChanges( changes: SimpleChanges ): void {
        if ( changes.requiredFiles ) {
            this.collectionView.setSourceItems( this.requiredFiles );

            const sortings: SortDescriptor<ArticleRequiredFile>[] = this.collectionView.getSortDescriptors();
            if ( sortings.length === 0 ) {
                this.collectionView.sortBy( 'fileKindName', true );
            }
            this.collectionView.refresh();
        }
    }

    public updateFormControl(): void {
        this.myFormControl.setValue( CardRequiredFilesComponent.mapArticleRequiredFileToRequiredFile( this.requiredFiles ) );
    }

    public toggleIsRequiredAfterRowClick( event: MouseEvent, item: ArticleRequiredFile ): void {
        if ( ( event.target as HTMLElement ).nodeName !== 'input' ) {
            item.isRequired = !item.isRequired;
            this.updateFormControl();
        }
    }

    private static mapArticleRequiredFileToRequiredFile( requiredFiles: ArticleRequiredFile[] ): RequiredFile[] {
        return requiredFiles.map( ( requiredFile: ArticleRequiredFile ) => {
            return {
                fileKindId: requiredFile.fileKindId,
                isRequired: requiredFile.isRequired,
            };
        } );
    }
}
