<rsp-edit-retail-segmentations
    *ngIf="isEditMode"
    [retailSegmentations]="retailSegmentations"
    [myFormControl]="myFormControl"
>
</rsp-edit-retail-segmentations>


<ng-container *ngIf="!isEditMode && ( retailSegmentations == null || retailSegmentations?.length === 0 )">
    - ALL -
</ng-container>

<rsp-tags *ngIf="!isEditMode && retailSegmentations?.length" [tags]="getRetailSegmentationsAsTags()" [showEmptyText]="false">
</rsp-tags>
