import { Component, Input, } from '@angular/core';

@Component( {
    selector:    'rsp-cluster-article-assembly-count',
    templateUrl: './cluster-article-assembly-count.component.html',
} )
export class ClusterArticleAssemblyCountComponent {

    /**
     * Count of articles assigned to cluster.
     */
    @Input() articleCount: number;

    /**
     * Count of assemblies assigned to cluster.
     */
    @Input() assemblyCount: number;

    /**
     * Cluster id.
     */
    @Input() clusterId: string;


    isLinkVisible(): boolean {
        return this.articleCount > 0
            || this.assemblyCount > 0;
    }
}
