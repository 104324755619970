import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Optional, Output, Renderer2, } from '@angular/core';
import { AbstractControl, NgControl, } from '@angular/forms';

import { Observable ,  Subscription ,  fromEvent, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';


@Directive( {
    selector: '[rspTextInput]',
} )
export class TextInputDirective implements OnInit, OnDestroy {
    @Input() rspTextInputWrapperClass: string | string[];
    @Input() formControl: AbstractControl;
    @Input() leftIcon: 'magnifier' | 'calendar';
    @Input() textAlignedRight: boolean = false;

    @Input()
    set withClearButton( value: boolean ) {
        this.setWithClearButton( value );
    }

    get withClearButton(): boolean {
        return this._withClearButton;
    }

    @Input()
    set withSelectMenuIcon( value: boolean ) {
        this.setWithSelectMenuIcon( value );
    }

    get withSelectMenuIcon(): boolean {
        return this._withSelectMenuIcon;
    }

    @Input()
    set disabled( value: boolean ) {
        this.setDisabled( value );
    }

    get disabled(): boolean {
        return this._disabled;
    }

    @Output() onClear: EventEmitter<void> = new EventEmitter<void>();
    @Output() onEnter: EventEmitter<void> = new EventEmitter<void>();

    private clearButton: HTMLElement;
    private leftIconElement: HTMLElement;
    private selectMenuIcon: HTMLElement;
    private wrapperElement: HTMLElement;
    private isFocused: boolean         = false;
    private subscription: Subscription = new Subscription();

    private _disabled: boolean;
    private _withSelectMenuIcon: boolean;
    private _withClearButton: boolean = false;

    constructor(
        private hostElement: ElementRef,
        private renderer: Renderer2,
        @Optional() private control: NgControl,
    ) {
    }

    @Input()
    set isSelectMenuVisible( value: boolean ) {
        // only toggle if icon is already created
        if ( this.selectMenuIcon ) {
            this.toggleSelectMenuIcon( value );
        }
    }

    @HostListener( 'keyup', [ '$event' ] ) onKeyUp( event: KeyboardEvent ): void {
        if ( !(event.metaKey || event.ctrlKey || event.altKey) && event.key === 'Enter' ) {
            this.onEnter.emit();
        }
    }

    ngOnInit(): void {
        if ( this.leftIcon && this.textAlignedRight && this.withClearButton ) {
            throw new Error( `${this.constructor.name}: wrong configuration. Text can not be aligned right, if left icon & clear button are shown.` );
        }

        if ( this.withSelectMenuIcon && this.withClearButton ) {
            throw new Error( `${this.constructor.name}: wrong configuration. Either select menu icon or clear button can be active.` );
        }

        if ( this.withClearButton || this.leftIcon || this.withSelectMenuIcon ) {
            this.initWrapper();

            if ( this.withClearButton && !this.clearButton ) {
                this.initClearButton();
            }
        }
        else {
            this.renderer.addClass( this.hostElement.nativeElement, 'text-input_without-clear-button' );
        }

        if ( this.textAlignedRight ) {
            this.renderer.addClass( this.hostElement.nativeElement, 'has-text-aligned-right' );
        }

        if ( this.hostElement.nativeElement.value && this.hostElement.nativeElement.value !== '' ) {
            this.addHasValueClass();
        }

        // disabled can be set via [disabled]-input and via formControl disabled state.
        if ( this.formControl && this.formControl.disabled ) {
            this.disabled = true;
        }

        this.determineSelectMenuVisibility( !this.disabled );
        this.determineClearButtonVisibility( !this.disabled );

        this.subscription.add(
            fromEvent( this.hostElement.nativeElement, 'keyup' )
                .pipe( map( () => this.hostElement.nativeElement.value !== '' ) )
                .subscribe( ( hasValue: boolean ) => {
                    this.handleKeyUp( hasValue );
                } ),
        );
    }

    ngOnDestroy(): void {
        if ( this.wrapperElement ) {
            this.renderer.removeChild( this.wrapperElement.parentElement, this.wrapperElement );
        }
        this.subscription.unsubscribe();
    }

    private handleKeyUp( hasValue: boolean ): void {
        if ( hasValue ) {
            if ( this.withClearButton ) {
                this.showClearButton();
            }
            this.addHasValueClass();
        }
        else {
            if ( this.withClearButton ) {
                this.hideClearButton();
            }
            this.removeHasValueClass();
        }
    }

    private initWrapper(): void {
        if ( this.wrapperElement ) {
            return;
        }

        this.renderer.addClass( this.hostElement.nativeElement, 'text-input--input' );

        // create and insert wrapper element
        this.wrapperElement = this.renderer.createElement( 'div' );
        this.renderer.addClass( this.wrapperElement, 'text-input--wrapper' );

        if ( this.rspTextInputWrapperClass ) {
            const classNames: string[] = Array.isArray( this.rspTextInputWrapperClass ) ? this.rspTextInputWrapperClass : [ this.rspTextInputWrapperClass ];
            classNames.forEach( ( className: string ) => {
                this.renderer.addClass( this.wrapperElement, className );
            } );
        }

        this.renderer.insertBefore( this.hostElement.nativeElement.parentNode, this.wrapperElement, this.hostElement.nativeElement );
        this.renderer.appendChild( this.wrapperElement, this.hostElement.nativeElement );


        if ( this.leftIcon ) {
            this.createLeftIcon( this.leftIcon );
            this.renderer.appendChild( this.wrapperElement, this.leftIconElement );

            this.renderer.addClass( this.hostElement.nativeElement, 'has-icon-space-left' );
        }

        this.addFocusEventListeners();
    }

    private initClearButton(): void {
        this.createClearButton();

        this.renderer.appendChild( this.wrapperElement, this.clearButton );

        this.addClearButtonEventListeners();

        // add text-alignment-specific classes
        if ( this.textAlignedRight ) {
            this.renderer.addClass( this.hostElement.nativeElement, 'has-icon-space-left' );
        }
        else {
            this.renderer.addClass( this.hostElement.nativeElement, 'has-icon-space-right' );
            this.renderer.addClass( this.clearButton, 'is-clear-button-right' );
        }
    }

    private createLeftIcon( icon: string ): void {
        this.leftIconElement = this.renderer.createElement( 'span' );
        this.renderer.addClass( this.leftIconElement, 'text-input--' + icon + '-icon' );
    }

    private createClearButton(): void {
        this.clearButton = this.renderer.createElement( 'button' );
        this.renderer.addClass( this.clearButton, 'text-input--clear-button' );
        this.renderer.setAttribute( this.clearButton, 'type', 'button' );
        this.renderer.setAttribute( this.clearButton, 'tabindex', '-1' );

        this.renderer.listen( this.clearButton, 'click', ( event: Event ) => {
            this.hostElement.nativeElement.value = '';

            this.removeHasValueClass();

            if ( this.formControl ) {
                this.formControl.setValue( null );
                this.formControl.markAsDirty();
            }

            if ( this.control ) {
                this.control.control.setValue( null );
                this.control.control.markAsDirty();
            }

            const changeEvent: Event = new Event('change', { bubbles: true, cancelable: true } );
            this.hostElement.nativeElement.dispatchEvent( changeEvent );

            // the dispatched change event seems not to work, so the onClear EventEmitter is needed too
            this.onClear.emit();

            this.hostElement.nativeElement.focus();

            // TODO: not yet the best solution, it does not work when user click the button outside icon
            event.stopPropagation();
            return false;
        } );
    }

    private addClearButtonEventListeners(): void {
        this.subscription.add(
            merge(
                fromEvent( this.wrapperElement, 'mouseover' ),
                fromEvent( this.wrapperElement, 'mouseout' ),
            )
                .pipe(
                    map( ( event: MouseEvent ) => event.type ),
                    distinctUntilChanged(),
                )
                .subscribe( ( type: string ) => {
                    if ( type === 'mouseover' && this.hostElement.nativeElement.value && this.hostElement.nativeElement.value !== '' ) {
                        this.showClearButton();
                    } else if ( type === 'mouseout' && !this.isFocused ) {
                        this.hideClearButton();
                    }
                } ),
        );
    }


    private addFocusEventListeners(): void {
        const focusListener: Observable<FocusEvent> = fromEvent( this.hostElement.nativeElement, 'focus' );
        const blurListener: Observable<FocusEvent>  = fromEvent( this.hostElement.nativeElement, 'blur' );

        this.subscription.add(
            merge( focusListener, blurListener )
                .pipe(
                    map( ( event: FocusEvent ) => event.type ),
                )
                .subscribe( ( type: string ) => {
                    this.isFocused = type === 'focus';

                    if ( this.leftIcon ) {
                        this.toggleActiveLeftIconClass();
                    }
                } ),
        );

        if ( this.withClearButton ) {
            this.subscription.add(
                focusListener
                    .pipe(
                        filter( () => {
                            return this.hostElement.nativeElement.value && this.hostElement.nativeElement.value !== '';
                        } ),
                    )
                    .subscribe( () => {
                        this.showClearButton();
                    } ),
            );

            this.subscription.add(
                blurListener.pipe( debounceTime( 200 ) ) // needed, because focus is lost, as soon as "clear" is clicked
                            .subscribe( () => {
                                this.hideClearButton();
                            } ),
            );
        }
    }

    private toggleSelectMenuIcon( isVisible: boolean ): void {
        if ( isVisible ) {
            this.renderer.addClass( this.selectMenuIcon, 'is-select-menu-active' );
        }
        else {
            this.renderer.removeClass( this.selectMenuIcon, 'is-select-menu-active' );
        }
    }

    private toggleActiveLeftIconClass(): void {
        if ( this.leftIconElement ) {
            if ( this.isFocused ) {
                this.renderer.addClass( this.leftIconElement, 'is-input-active' );
            } else {
                this.renderer.removeClass( this.leftIconElement, 'is-input-active' );
            }
        }
    }


    private addHasValueClass(): void {
        this.renderer.addClass( this.hostElement.nativeElement, 'has-text-input-value' );
    }

    private removeHasValueClass(): void {
        this.renderer.removeClass( this.hostElement.nativeElement, 'has-text-input-value' );
    }

    private showClearButton(): void {
        this.renderer.addClass( this.clearButton, 'is-clear-button-visible' );

        this.determineClearButtonColor();
    }

    private determineClearButtonColor(): void {
        if ( this.hostElement.nativeElement.classList.contains( 'ng-dirty' ) && this.hostElement.nativeElement.classList.contains( 'ng-invalid' ) ) {
            this.renderer.addClass( this.clearButton, 'is-clear-button-invalid' );
        }
        else {
            this.renderer.removeClass( this.clearButton, 'is-clear-button-invalid' );
        }
    }

    private hideClearButton(): void {
        if (this.clearButton) {
            this.renderer.removeClass( this.clearButton, 'is-clear-button-visible' );
            this.renderer.removeClass( this.clearButton, 'is-clear-button-invalid' );
            this.determineClearButtonColor();
        }
    }

    private setWithClearButton( value: boolean ): void {
        this._withClearButton = value;

        if ( value && !this.clearButton ) {
            this.initWrapper();

            this.initClearButton();
        }

        this.determineClearButtonVisibility( value );
    }


    private setWithSelectMenuIcon( value: boolean ): void {
        this._withSelectMenuIcon = value;

        if ( value && !this.selectMenuIcon ) {
            this.initWrapper();

            this.selectMenuIcon = this.renderer.createElement( 'span' );
            this.renderer.addClass( this.selectMenuIcon, 'text-input--select-menu-icon' );

            this.renderer.appendChild( this.wrapperElement, this.selectMenuIcon );

            this.renderer.addClass( this.hostElement.nativeElement, 'has-icon-space-right' );
        }

        this.determineSelectMenuVisibility( value );
    }


    private setDisabled( value: boolean | string ): void {
        // value can be empty string (e.g. <input type="text" disabled>)
        const isDisabled: boolean = typeof value === 'string' ? value !== 'false' : value;
        this._disabled            = isDisabled;

        this.renderer.setProperty( this.hostElement.nativeElement, 'disabled', isDisabled );

        this.determineClearButtonVisibility( !isDisabled );
        this.determineSelectMenuVisibility( !isDisabled );
    }

    private determineClearButtonVisibility( visible: boolean ): void {
        if ( this.clearButton ) {
            this.renderer.setStyle( this.clearButton, 'display', visible ? 'block' : 'none' );
        }
    }

    private determineSelectMenuVisibility( visible: boolean ): void {
        if ( this.selectMenuIcon ) {
            this.renderer.setStyle( this.selectMenuIcon, 'display', visible ? 'block' : 'none' );
        }
    }
}

