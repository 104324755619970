<div *ngIf="hasAnyAction" class="non-trade-item-list-actions">
    <ng-container *ngFor="let groupName of actionGroups; let groupIndex = index">
        <rsp-dropdown-group
            *ngIf="actionsForGroup.get( groupIndex ).length > 0"
            [label]="groupName"
        >
            <rsp-dropdown-item
                *ngFor="let action of actionsForGroup.get( groupIndex )"
                [item]="action"
                [disabled]="action.requiresSelection
                                ? !hasSelectedItems
                                : action.selectedArticlesRequired
                                    ? !hasSelectedArticles
                                    : action.selectedReadyToOrderRequired
                                        ? !hasReadyToOrderItems
                                        : false"
                (selectedItem)="selectAction( action )"
            >
                {{ action.label }}
            </rsp-dropdown-item>
        </rsp-dropdown-group>
    </ng-container>
</div>

<div *ngIf="!hasAnyAction" class="non-trade-item-list-actions">
    <rsp-dropdown-group
        [label]="'No actions available.'"
    >
    </rsp-dropdown-group>
</div>

<rsp-create-article-dialog></rsp-create-article-dialog>
<rsp-create-assembly-dialog></rsp-create-assembly-dialog>
<rsp-create-article-from-excel-dialog></rsp-create-article-from-excel-dialog>
<rsp-create-assembly-from-excel-dialog></rsp-create-assembly-from-excel-dialog>
<rsp-copy-assembly-dialog></rsp-copy-assembly-dialog>
<rsp-create-cluster-dialog></rsp-create-cluster-dialog>
<rsp-add-or-remove-cluster-dialog></rsp-add-or-remove-cluster-dialog>
<rsp-set-engineering-partner-action></rsp-set-engineering-partner-action>
<rsp-set-article-status-action></rsp-set-article-status-action>
<rsp-set-assembly-status-action></rsp-set-assembly-status-action>
<rsp-add-selection-to-assembly-dialog></rsp-add-selection-to-assembly-dialog>
<rsp-remove-selection-from-assembly-dialog></rsp-remove-selection-from-assembly-dialog>
<rsp-export-selection-to-excel-dialog></rsp-export-selection-to-excel-dialog>
<rsp-export-search-result-to-excel-dialog></rsp-export-search-result-to-excel-dialog>
<rsp-export-search-result-to-ariba-dialog></rsp-export-search-result-to-ariba-dialog>
<rsp-export-article-assembly-files-dialog></rsp-export-article-assembly-files-dialog>
<rsp-export-selection-to-ariba-dialog></rsp-export-selection-to-ariba-dialog>
