import { Component, Input, Output, EventEmitter, } from '@angular/core';
import { Subscription, } from 'rxjs';

import { ArticleManagementConceptsService } from '../../../api';
import { ConceptSuggestItem } from '../../../model/conceptSuggestItem';
import { EntityTypeEnum, } from '../../../model/enum/entity-type.enum';
import { ViewModelPagedListConceptSuggestItem } from '../../../model/viewModelPagedListConceptSuggestItem';

@Component( {
    selector:    'rsp-concept-suggester',
    templateUrl: './concept-suggester.component.html',
    styleUrls:   [ './concept-suggester.component.scss' ],
} )
export class ConceptSuggesterComponent {

    /**
     * Event fired when user select one element from the suggestion list.
     * 'sourceItem' used for creation of SuggestionItem will be used as event's parameter.
     */
    @Output() selected: EventEmitter<ConceptSuggestItem> = new EventEmitter();

    @Input() itemFilter: Array<string> = [];

    @Input() displayValue: string;

    @Input() inputFieldHtmlId: string;

    items: ConceptSuggestItem[] = [];

    isLoading: Subscription;

    conceptTypeEnum: EntityTypeEnum = EntityTypeEnum.Concept;

    constructor(
        private conceptsApi: ArticleManagementConceptsService,
    ) { }

    searchItems( searchTerm: string ): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }

        if ( !searchTerm || !searchTerm.trim() ) {
            this.items = [];
            return;
        }

        this.isLoading =
            this.conceptsApi.conceptsSuggest( {
                term:            searchTerm,
                ignoreIds:       this.itemFilter.length ? this.itemFilter : undefined,
                page:            1,
                size:            50,
                respectClusters: false,
            } )
                .subscribe( ( searchResult: ViewModelPagedListConceptSuggestItem ) => {
                    this.items = searchResult.data;
                } );
    }

    selectedItem( item: ConceptSuggestItem ): void {
        this.selected.emit( item );
    }
}
