<ng-template #innerTileTemplateRef let-conceptOrCluster="conceptOrCluster">
    <article class="concept-cluster-tile--inner">
        <div *ngIf="( isSelectionAvailable | async ) && isCheckboxVisible()">
            <input
                rspCheckbox
                [rspCheckboxWrapperClass]="'concept-cluster-tile--checkbox-wrapper'"
                [checked]="isSelected"
                (click)="toggleSelection( $event )"
                id="{{ conceptOrCluster.id }}"
                type="checkbox"
            >
        </div>

        <h3 class="concept-cluster-tile--name-and-status">
            <div class="concept-cluster-tile--name-wrapper">
                <span class="concept-cluster-tile--name">
                    {{ conceptOrCluster.name }}
                </span>
            </div>

            <div class="concept-cluster-tile--status-wrapper">
                <rsp-status
                    [displayMode]="'badge'"
                    [status]="conceptOrCluster.status"
                    [type]="type"
                    class="concept-cluster-tile--status"
                ></rsp-status>
            </div>
        </h3>

        <h4 class="concept-cluster-tile--type">

            <em
                *ngIf="type === typeEnum.Cluster"
                class="concept-cluster-tile--concept-name"
            >
                {{ conceptOrCluster.concept.name }}
            </em>
        </h4>

        <div class="concept-cluster-tile--additional-data">

            <ng-container *ngIf="type === typeEnum.Concept">
                {{ conceptOrCluster.clusters }} cluster{{ conceptOrCluster.clusters !== 1 ? 's' : '' }}
            </ng-container>
            <ng-container *ngIf="type === typeEnum.Cluster">
                <span [ngClass]="{'concept-cluster-tile--not-available': !conceptOrCluster.isAvailableToOrder }">
                    available to order
                </span>
                <br>
                <ng-container *ngIf="conceptOrCluster.articles && conceptOrCluster.assemblies">
                {{ conceptOrCluster.articles }} article{{ conceptOrCluster.articles !== 1 ? 's' : '' }}
                <br>{{ conceptOrCluster.assemblies }} assembl{{ conceptOrCluster.assemblies !== 1 ? 'ies' : 'y' }}
                </ng-container>
            </ng-container>
        </div>
    </article>
</ng-template>

<a
    *ngIf="linksToConceptOrCluster"
    #myTile
    [class]="'concept-cluster-tile is-concept-select-mode-default'"
    [class.is-concept-cluster-tile-checked]="isSelected"
    [routerLink]="routerLink"
    (click)="setIndex()"
>
    <ng-template
        [ngTemplateOutlet]="innerTileTemplateRef"
        [ngTemplateOutletContext]="{ conceptOrCluster: conceptOrCluster }"
    ></ng-template>
</a>

<div
    *ngIf="!linksToConceptOrCluster"
    #myTile
    [class]="'concept-cluster-tile is-concept-select-mode-default is-link-deactivated'"
    [class.is-concept-cluster-tile-checked]="isSelected"
    (click)="setIndex()"
>
    <ng-template
        [ngTemplateOutlet]="innerTileTemplateRef"
        [ngTemplateOutletContext]="{ conceptOrCluster: conceptOrCluster }"
    ></ng-template>
</div>
