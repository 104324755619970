<rsp-list-page
    [isSidebarVisible]="isSidebarVisible"
    [facets]="facets | async"
    [chosenValuesFacet]="chosenValuesFacet | async"
    [advancedSearchFilterTypeOperators]="advancedSearchFilterTypeOperators | async"
    [advancedSearchFilterProperties]="advancedSearchFilterProperties | async"
    [advancedSearchFilterGroups]="advancedSearchFilterGroups | async"
    [usedQuickSearchTerm]="usedQuickSearchTerm | async"
    (quickSearchReset)="onQuickSearchReset()"
    (facetValuesChanged)="onFacetValuesChanged( $event )"
    (advancedSearchValuesChanged)="onAdvancedSearchValueChanged( $event )"
    (filtersReset)="onFilterReset()"
    (sidebarVisibilityChanged)="onSidebarVisibilityChanged( $event )"
>
    <rsp-virtual-scroll
        [items]="items | async"
        [columnCount]="( activeViewKind | async ) === viewKind.Table ? 'auto' : 1"
        [scrollToValue]="scrollToId"
        (update)="visibleItems = $event"
        (scrolledToBottom)="onScrolledToBottom()"
    >

        <rsp-no-content *ngIf="(items | async).length === 0 && !isLoading">
            Your search did not match any users.
        </rsp-no-content>

        <ng-container *ngIf="(items | async).length > 0">
            <rsp-virtual-scroll-item *ngFor="let item of visibleItems;">
                <ng-container *ngIf="( activeViewKind | async ) === viewKind.Table">
                    <rsp-user-tile [item]="item"></rsp-user-tile>
                </ng-container>

                <ng-container *ngIf="( activeViewKind | async ) === viewKind.List">
                    <rsp-user-line [item]="item"></rsp-user-line>
                </ng-container>
            </rsp-virtual-scroll-item>
        </ng-container>
    </rsp-virtual-scroll>
</rsp-list-page>
