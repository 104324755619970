import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Directive( {
    selector: '[rspConvertPasteData]',
} )
export class ConvertPasteDataDirective {
    @Input() formControl: UntypedFormControl;

    constructor(
        private element: ElementRef,
    ) {
        if ( this.element.nativeElement.localName !== 'input' ) {
            throw new Error( this.constructor.name + ': Can only be used on INPUT elements.' );
        }
    }

    @HostListener( 'paste', [ '$event' ] )
    onPaste( event: Event ): void {
        this.handlePaste( event );
    }

    private handlePaste( event: Event ): void {
        event.preventDefault();
        event.stopPropagation();

        const data: DataTransfer = (<ClipboardEvent> event).clipboardData || (<any> window).clipboardData;

        if ( data ) {
            this.element.nativeElement.value = data.getData( 'Text' ).replace(/(?:\r\n|\n\r|\r|\n)/g, ' ');

            if ( this.formControl ) {
                this.formControl.setValue( this.element.nativeElement.value );
            }
        }
    }
}
