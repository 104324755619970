import { ElementRef, EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';


export interface PopupConfig {

    /**
     * Template reference that should be rendered as popup.
     */
    template: TemplateRef<any>;

    /**
     * Element which triggers popup to be open/closed.
     */
    triggerElement: ElementRef;

    /**
     * Offset which will be added to (when popup is above the trigger element)
     * or substituted from (when under the trigger element) the popup position.
     */
    offsetY: number;

    /**
     * Autoclose popup when clicked outside of popup element
     */
    autoClose: boolean;
}


/**
 * Opens/closes dropdown popups. Used together with PopupComponent.
 */
@Injectable()
export class PopupService {

    /**
     * Notifies subscribers when popup's config has been changed.
     */
    popupConfig$: Observable<PopupConfig>;

    /**
     * Notifies subscribers when popup has been closed.
     */
    popupClosed$: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

    /**
     * Notifies subscribers when popup has been opened.
     */
    popupOpened$: EventEmitter<ElementRef> = new EventEmitter<ElementRef>();

    /**
     * Notifies subscribers when popup position calculation is needed
     */
    calculatePosition$: Observable<boolean>;

    /**
     * Notifies subscribers when user clicked outside of popup and not the trigger element, parameter is event.target and triggerElement
     */
    clickedOutside$: Observable<{ target: HTMLElement, triggerElement: ElementRef }>;


    private refreshPosition: Subject<boolean>                                            = new Subject();
    private popupConfig: Subject<PopupConfig>                                            = new Subject<PopupConfig>();
    private clickedOutside: Subject<{ target: HTMLElement, triggerElement: ElementRef }> = new Subject();


    constructor() {
        this.popupConfig$       = this.popupConfig.asObservable();
        this.calculatePosition$ = this.refreshPosition.asObservable();
        this.clickedOutside$    = this.clickedOutside.asObservable();
    }


    /**
     * Opens popup.
     *
     * @param templateRef - template which will be put into <rsp-popup> component.
     * @param triggerElement - element which triggers popup open/close (f.e. Dropdown-Button).
     */
    openOverlay( templateRef: TemplateRef<any>, triggerElement: ElementRef, offsetY: number = 0, autoClose: boolean = true ): void {

        this.popupConfig.next( { template: templateRef, triggerElement: triggerElement, offsetY: offsetY, autoClose: autoClose } );
    }

    /**
     * Closes popup.
     */
    closeOverlay(): void {

        this.popupConfig.next( null );
    }

    /**
     * Triggers recalculation of popup position
     */
    autoPositionOverlay(): void {
        this.refreshPosition.next( true );
    }

    /**
     * Trigger clickedOutside event with target and triggerElement as parameters
     */
    notifyClickOutside( target: HTMLElement, triggerElement: ElementRef ): void {
        this.clickedOutside.next( { target: target, triggerElement: triggerElement } );
    }
}
