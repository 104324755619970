import {
    Directive,
    OnChanges,
    Input,
    ElementRef,
    ComponentRef,
    ViewContainerRef,
    ComponentFactoryResolver,
    ComponentFactory,
    TemplateRef,
} from '@angular/core';
import { Subscription, of, } from 'rxjs';
import { LoadingComponent } from './loading.component';
import { delay } from 'rxjs/operators';

@Directive( {
    selector: '[rspLoadingIndicator]',
} )
export class LoadingIndicatorDirective implements OnChanges {
    /* tslint:disable:no-input-rename */
    @Input( 'rspLoadingIndicator' ) subscription: Subscription;
    /* tslint:enable:no-input-rename */

    @Input() loadingIndicatorTemplate: TemplateRef<any>;

    private component: ComponentRef<LoadingComponent>;

    constructor(
        private el: ElementRef,
        private viewContainer: ViewContainerRef,
        private componentFactoryResolver: ComponentFactoryResolver,
    ) { }


    ngOnChanges(): void {
        if ( this.subscription && ( !this.component || this.viewContainer.indexOf( this.component.hostView ) === -1 ) ) {

            const factory: ComponentFactory<LoadingComponent> = this.componentFactoryResolver.resolveComponentFactory( LoadingComponent );

            of( true ).pipe( delay( 200 ) ).subscribe( () => {
                this.component = this.viewContainer.createComponent( factory );

                const width: number = this.el.nativeElement.getBoundingClientRect().width;
                if ( width ) {
                    this.component.instance.width = width;
                }

                if ( this.loadingIndicatorTemplate ) {
                    this.component.instance.hideSpinner = true;
                    this.component.instance.templateTarget.createEmbeddedView( this.loadingIndicatorTemplate );
                }

                this.subscription.add( () => {
                    this.viewContainer.clear();
                    this.component.destroy();
                } );
            } );
        }
    }
}
