<div *ngIf="isVisible"
     (rspClickOutside)="closeMenu( $event )"
     class="main-menu"
     @mainMenuTrigger
>

    <!-- close button -->
    <rsp-button
        class="main-menu--close-button"
        [submodule]="'seamless'"
        (click)="closeMenu()"
    ></rsp-button>

    <!-- logo -->
    <div class="main-menu--logo">
        <img class="main-menu--logo-image"
             src="/assets/adidas_company_logo_Wp.svg"
             alt="adidas Logo"
        />
    </div>

    <!-- navigation -->
    <nav class="main-menu--navigation">
        <ul class="main-menu--navigation-wrapper">

            <li
                *ngFor="let mainMenuEntry of mainMenuEntries"
                [class]="mainMenuEntry.description === 'Forecasts' ? 'main-menu--navigation-item-disabled' : 'main-menu--navigation-item'"
            >
                <a
                    [routerLink]="[ mainMenuEntry.url ]"
                    [routerLinkActive]="'is-active'"
                    (click)="closeMenu()"
                >
                    {{mainMenuEntry.description}}
                </a>
            </li>
        </ul>
    </nav>

    <!-- logout -->
    <rsp-button
        class="main-menu--logout-button"
        [submodule]="'primary'"
        (clicked)="logout()"
    >Logout</rsp-button>
    <div class="main-menu--footer">
        <span (click)="openImprint()">Imprint</span>
        <span (click)="openLegalNotice()">Legal Notice</span>
    </div>
</div>

<rsp-imprint-dialog></rsp-imprint-dialog>
<rsp-legal-notice-dialog></rsp-legal-notice-dialog>
