import {
    AfterViewInit,
    Directive, ElementRef, HostBinding, Input, NgZone, OnDestroy, OnInit,
    Renderer2,
} from '@angular/core';

import { fromEvent, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { WindowEventService } from '../../../core/window-event.service';
import { ScrollContainerService } from './scroll-container.service';
import { ElementVisibilityService } from '../../../core/element-visibility.service';

type OverflowValue = 'scroll' | 'visible' | 'hidden' | 'auto' | 'inherit' | 'initial' | 'unset';

/**
 * Tries to emulate `position: sticky`-behaviour.
 * (See https://html5-demos.appspot.com/static/css/sticky.html for demo).
 *
 * This is intended to mostly work for / with
 * - horizontal scrolling
 * - fixed table headers
 * - layouts using 04_layout/flex-table.scss
 * - containers with `overflowY: scroll | auto;`
 * - all browsers the same way (there's no detection strategy involved that would give `position: sticky`-capable
 * browsers the native-treatment and others the polyfill version)
 *
 * An additional CSS class can be applied when the state changes to *sticked*, using the
 * `[rspPositionStickyIsStickyCssClass]`-binding.
 *
 * ```
 * <section style="overflow-y: scroll;">
 *     <h1>Some headline</h1>
 *     <table class="l-table" #stickyContainer>
 *         <thead class="l-table--head" rspPositionSticky [rspPositionStickyContainer]="stickyContainer">
 *             <tr class="l-table--head-row">
 *                 <th class="l-table--head-cell">x</th>
 *                 <th class="l-table--head-cell">y</th>
 *             </tr>
 *         </thead>
 *         <tbody class="l-table--body">
 *             <tr class="l-table--body-row">
 *                 <td class="l-table--body-cell">1</td>
 *                 <td class="l-table--body-cell">2</td>
 *             </tr>
 *             <tr class="l-table--body-row">
 *                 <td class="l-table--body-cell">2</td>
 *                 <td class="l-table--body-cell">4</td>
 *             </tr>
 *             <!-- … -->
 *         </tbody>
 *     </table>
 * </section>
 * ```
 *
 */
@Directive( {
    selector:  '[rspPositionSticky]',
    providers: [ ElementVisibilityService ],
} )
export class PositionStickyDirective implements AfterViewInit, OnInit, OnDestroy {
    scrollContainerNode: HTMLElement;
    scrollContainerIdentifier: string;

    stickyNode: HTMLElement;
    stickyContainerNode: HTMLElement;
    stickyNodePlaceHolder: HTMLElement;

    isStickyCssClassName: string = 'is-sticky';


    @Input()
    set rspPositionStickyContainer( containerNode: HTMLElement ) { this.stickyContainerNode = containerNode; }

    @Input()
    set rspPositionStickyIsStickyCssClass( className: string ) { this.isStickyCssClassName = className; }

    @Input()
    set rspPositionStickyScrollContainerIdentifier( identifier: string) { this.scrollContainerIdentifier = identifier; }

    set isSticky( isSticky: boolean ) { isSticky ? this.stick() : this.unstick(); }
    get isSticky(): boolean { return this._isSticky; }

    @HostBinding( 'style.position' )   protected stickyNodePosition: 'fixed';
    @HostBinding( 'style.top' )        protected stickyNodeTop: string;
    @HostBinding( 'style.left' )       protected stickyNodeLeft: string;
    @HostBinding( 'style.width' )      protected stickyNodeWidth: string;

    protected isStickyContainerVisible: boolean;
    protected _isSticky: boolean = false;

    protected isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        directiveElement: ElementRef,
        protected zone: NgZone,
        protected renderer: Renderer2,
        protected windowEventService: WindowEventService,
        protected scrollContainerService: ScrollContainerService,
        protected elementVisibilityService: ElementVisibilityService,
    ) {
        this.stickyNode = directiveElement.nativeElement;
    }

    ngOnInit(): void {
        if ( this.scrollContainerIdentifier ) {
            this.scrollContainerNode = this.scrollContainerService.getScrollContainerNode( this.scrollContainerIdentifier );
        }
        if ( !this.scrollContainerNode ) {
            this.scrollContainerNode = this.getScrollContainerNode( this.stickyNode );

            if ( this.scrollContainerIdentifier ) {
                console.warn('ScrollContainerIdentifier was given but not found. Fallback to' +
                             ' getScrollContainerNode()' );
            }
        }

        this.zone.runOutsideAngular(() => {
            this.windowEventService.resized$.subscribe( () => {
                if ( this.isSticky ) {
                        this.applyDimensionsToStickyNode();
                        this.onBeforeStick();
                }
            } );
        });

        this.zone.runOutsideAngular(() => {
            fromEvent( this.scrollContainerNode, 'scroll' )
                .pipe(
                    filter( () => this.isStickyContainerVisible ),
                    takeUntil( this.isDestroyed ),
                )
                .subscribe( () => {
                    this.onScroll();
                } );
        });
    }

    onScroll(): void {
        const scrollContainerBoundingClientRect: ClientRect = this.scrollContainerNode.getBoundingClientRect();
        const stickyContainerBoundingClientRect: ClientRect = this.stickyContainerNode.getBoundingClientRect();
        const stickyNodeHeight: number                      = this.stickyNode.offsetHeight;
        const stickyContainerHeight: number                 = stickyContainerBoundingClientRect.bottom - stickyContainerBoundingClientRect.top;

        const scrollContainerTopEdgeCoordinate: number = scrollContainerBoundingClientRect.top;
        const topEdgeCoordinate: number                = stickyContainerBoundingClientRect.top;
        const bottomEdgeCoordinate: number             =
            stickyContainerBoundingClientRect.top +
            stickyContainerHeight -
            stickyNodeHeight +
            parseFloat( window.getComputedStyle( this.stickyNode ).marginBottom )
        ;

        if (
            scrollContainerTopEdgeCoordinate > topEdgeCoordinate &&
            scrollContainerTopEdgeCoordinate < bottomEdgeCoordinate
        ) {
            this.isSticky = true;
        }
        else {
            this.isSticky = false;
        }
    }

    ngAfterViewInit(): void {
        this.elementVisibilityService
            .getObservableForElement( this.stickyContainerNode )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( isVisible: boolean ) => {
                this.isStickyContainerVisible = isVisible;
            } );

        this.elementVisibilityService.register( this.stickyContainerNode );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        this.elementVisibilityService.unregister( this.stickyContainerNode );
    }

    protected stick(): void {
        if ( this._isSticky ) {
            return;
        }

        this.zone.run(() => {
            const oldScrollTop: number = this.scrollContainerNode.scrollTop;

            this.applyDimensionsToStickyNode();
            this.addPlaceholderNode();
            this.onBeforeStick();

            // apply positioning
            this.stickyNodePosition = 'fixed';

            const newScrollTop: number = this.scrollContainerNode.scrollTop;

            // oldScrollTop & newScrollTop should be equal, but chrome seems to do things differently, so for smooth scroll behaviour, scrollTop is adjusted
            if ( oldScrollTop !== newScrollTop ) {
                this.scrollContainerNode.scrollTop = oldScrollTop;
            }

            // apply isSticky CSS class if demanded
            if ( this.isStickyCssClassName ) {
                this.renderer.addClass( this.stickyNode, this.isStickyCssClassName );
            }

            // update internal state
            this._isSticky = true;
        });
    }

    protected onBeforeStick(): void {
        // this is a placeholder hook method that can be implemented by child classes.
    }

    protected onBeforeUnstick(): void {
        // this is a placeholder hook method that can be implemented by child classes.
    }

    protected unstick(): void {
        if ( !this.isSticky ) {
            return;
        }

        this.zone.run(() => {
            this.onBeforeUnstick();

            this.stickyNodePosition = null;
            this.stickyNodeWidth    = null;
            this.stickyNodeTop      = null;
            this.stickyNodeLeft     = null;
            this.renderer.removeChild( this.stickyNodePlaceHolder.parentNode, this.stickyNodePlaceHolder );
            this.stickyNodePlaceHolder = null;

            if ( this.isStickyCssClassName ) {
                this.renderer.removeClass( this.stickyNode, this.isStickyCssClassName );
            }

            this._isSticky = false;
        });
    }

    private getScrollContainerNode( sourceNode: HTMLElement ): HTMLElement {
        let scrollContainerNode: HTMLElement = sourceNode;
        let overflowValue: OverflowValue;

        do {
            scrollContainerNode = scrollContainerNode.parentNode as HTMLElement;
            if ( scrollContainerNode ) {
                overflowValue = window.getComputedStyle( scrollContainerNode ).overflowY as OverflowValue;
                if ( overflowValue === 'scroll' || overflowValue === 'auto' ) {
                    break;
                }
            }
        } while ( scrollContainerNode.parentNode );

        return scrollContainerNode;
    }

    private applyDimensionsToStickyNode(): void {
        const scrollContainerNodeBoundingClientRect: ClientRect = this.scrollContainerNode.getBoundingClientRect();
        const sourceNodeBoundingClientRect: ClientRect          = ( this.stickyNodePlaceHolder || this.stickyNode ).getBoundingClientRect();

        this.stickyNodeTop   = ( scrollContainerNodeBoundingClientRect.top - parseFloat( window.getComputedStyle( this.stickyNode ).marginTop ) ) + 'px';
        this.stickyNodeLeft  = sourceNodeBoundingClientRect.left + 'px';
        this.stickyNodeWidth = ( sourceNodeBoundingClientRect.right - sourceNodeBoundingClientRect.left ) + 'px';
    }

    private addPlaceholderNode(): void {
        this.stickyNodePlaceHolder = this.stickyNode.cloneNode( true ) as HTMLElement;

        // TODO Also set margin, box-sizing, position & display?
        // this.renderer.setStyle( this.stickyNodePlaceHolder, 'marginTop', window.getComputedStyle( this.stickyNode ).marginTop );
        // this.renderer.setStyle( this.stickyNodePlaceHolder, 'marginBottom', window.getComputedStyle( this.stickyNode ).marginBottom );

        this.renderer.insertBefore( this.stickyNode.parentNode, this.stickyNodePlaceHolder, this.stickyNode );
    }
}
