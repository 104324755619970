<ng-template #headline>
    <h2 class="standard-dialog--headline">Set Status for selected Articles</h2>
</ng-template>

<ng-template #content>
    <div class="standard-dialog--content">
        <rsp-non-trade-item-selection-list [nonTradeItemType]="articleType"></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #footer>
    <div class="standard-dialog--button">
        <rsp-dropdown
            [placeholder]="'Select Status'"
            [myFormControl]="form.get( 'status' )"
            (selected)="setStatus( $event )"
        >
            <rsp-dropdown-item [item]="'activeInPreRollout'">
                <rsp-status class="status" [status]="activeInPreRollout" [type]="statusEnumType" [displayMode]="'badge'"></rsp-status> Active In Pre Rollout
            </rsp-dropdown-item>
            <rsp-dropdown-item [item]="'activeInRollout'">
                <rsp-status class="status" [status]="activeInRollout" [type]="statusEnumType" [displayMode]="'badge'"></rsp-status> Active In Rollout
            </rsp-dropdown-item>
            <rsp-dropdown-item [item]="'discontinue'">
                <rsp-status class="status" [status]="discontinue" [type]="statusEnumType" [displayMode]="'badge'"></rsp-status> Discontinue
            </rsp-dropdown-item>
            <rsp-dropdown-item [item]="'activeOnDemand'">
                <rsp-status class="status" [status]="activeOnDemand" [type]="statusEnumType" [displayMode]="'badge'"></rsp-status>
                Active On Demand
            </rsp-dropdown-item>
            <rsp-dropdown-item [item]="'inactive'">
                <rsp-status class="status" [status]="inactive" [type]="statusEnumType" [displayMode]="'badge'"></rsp-status> Inactive
            </rsp-dropdown-item>
        </rsp-dropdown>

        <rsp-edit-text
            [myFormControl]="form.get( 'reason' )"
            [placeholder]="'Reason for change'"
        ></rsp-edit-text>

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            (clicked)="doAction()"
            [disabled]="!form.valid || isProcessing"
            style="margin-left: 1em"
        >
            Apply
        </rsp-button>
    </div>
</ng-template>
