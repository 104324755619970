<div class="document-line">

    <!-- checkbox -->
    <div
        *ngIf="isSelectionAvailable | async"
        class="document-line--checkbox-container"
    >
        <input
            id="{{ item.id }}"
            type="checkbox"
            rspCheckbox
            [rspCheckboxWrapperClass]="'document-line--checkbox-wrapper'"
            [checked]="isSelected"
            (click)="toggleSelection( $event )"
        >
    </div>

    <div class="document-line--image-container">
        <rsp-image
            [fileId]="item.previewImageId"
            [alt]="item.name + ' - ' + item.fileKind.name"
            [height]="65"
            [width]="100"
            [alignImg]="true"
        ></rsp-image>
    </div>

    <div class="document-line--attribute-container">
        <div class="document-line--attribute-column-1">

            <div class="document-line--attribute-container">
                <!-- file kind -->
                <div class="document-line--attribute-column-1">
                    <div class="document-line--file-kind" [title]="item.fileKind?.description">
                        <rsp-supplementary-caption>File Kind</rsp-supplementary-caption>
                        {{ item.fileKind?.name }}
                    </div>
                </div>

                <!-- file group -->
                <div class="document-line--attribute-column-2">
                    <div *ngIf="item.keywords.length > 0" class="document-line--file-kind">
                        <rsp-supplementary-caption>Group</rsp-supplementary-caption>
                        {{ item.keywords[0] }}
                    </div>
                </div>
            </div>

            <!-- file name -->
            <div class="document-line--file-name">
                <rsp-supplementary-caption>File Name</rsp-supplementary-caption>
                <rsp-download-link [fileGuid]="item.id">
                    {{ item.name }}
                </rsp-download-link>
            </div>
        </div>

        <div class="document-line--attribute-column-2">
            <!-- brand -->
            <div class="document-line--brand">
                <rsp-supplementary-caption>Brand</rsp-supplementary-caption>
                {{ item.brand?.name }}
                <rsp-not-available *ngIf="!item.brand"></rsp-not-available>
            </div>

            <!-- concept -->
            <div class="document-line--concept">
                <rsp-supplementary-caption>Concept</rsp-supplementary-caption>
                {{ item.concept?.name }}
                <rsp-not-available *ngIf="!item.concept"></rsp-not-available>
            </div>
        </div>

        <div class="document-line--attribute-column-3">
            <!-- visible for -->
            <div class="document-line--visible-for">
                <rsp-supplementary-caption>Visible For</rsp-supplementary-caption>
                {{ getVisibleForEnumAsString( item.visibleFor ) }}
            </div>

            <!-- uploaded on  -->
            <div class="document-line--uploaded-on">
                <rsp-supplementary-caption>Uploaded On</rsp-supplementary-caption>
                <nur-date-time [datetime]="item.uploadedOn"
                               format="Comparable"
                               [withTime]="false"
                ></nur-date-time>
            </div>
        </div>
    </div>

    <ng-container *ngIf="userHasEditRight">
        <rsp-pencil-button
            [style.visibility]="!isEditable( item ) ? 'hidden' : null"
            (click)="editClick.emit( item )"
            class="document-line--edit-button"
            title="Edit Document"
        ></rsp-pencil-button>

        <rsp-remove
            [style.visibility]="!isDeletable( item ) ? 'hidden' : null"
            [text]="'Do you really want to delete this document?'"
            [title]="'Delete Document'"
            (confirmClicked)="deleteDocumentConfirmed.emit( item )"
        ></rsp-remove>
    </ng-container>


</div>
