import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HttpEvent, HttpEventType, HttpResponse, } from '@angular/common/http';
import { Observable ,  Subscription } from 'rxjs';

import { FileManagementFilesService } from '../../../api/fileManagementFiles.service';
import { ReplyListGuid } from '../../../model/replyListGuid';
import { NotificationService } from '../../../../core/overlay/notification/notification.service';
import { MessageType } from '../../../ui/messages/messages.component';
import { ProgressBarBackgroundColor } from '../../../ui/loading-indicators/progress-linear/progress-linear.component';
import { ViewModelListFileContainerItem } from '../../../model/viewModelListFileContainerItem';
import { FileContainerItem } from '../../../model/fileContainerItem';

export interface FileUploadedInfo {
    fileContainerId: string;
    previewImageId: string;
}

export interface ReplyViewModelListFileContainerItem {
    value?: ViewModelListFileContainerItem;
    isOk?: boolean;
    isError?: boolean;
    message?: string;
}

@Component( {
    selector:    'rsp-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls:   [
        './file-upload.component.scss',
    ],
} )
export class FileUploadComponent implements OnInit {
    @Input() file: File;
    @Input() fileContainerId: string;
    @Input() fileKindId: string;
    @Input() progressBarBackgroundColor: ProgressBarBackgroundColor = 'lightest-gray';

    @Output() remove: EventEmitter<void> = new EventEmitter();
    @Output() fileUploaded: EventEmitter<FileUploadedInfo | null> = new EventEmitter();

    // initial values set by ngOnInit calling this.reset()
    progress: number;
    isUploading: boolean;
    isComplete: boolean;
    isFailed: boolean;

    messageTypeError: MessageType = MessageType.Error;

    private subscription: Subscription;
    constructor(
        private filesApi: FileManagementFilesService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.reset();
        this.fileUpload(); // start upload right away with given file object
    }

    fileUpload( event?: Event ): void {
        this.isUploading = true;

        const apiCall: Observable<HttpEvent<ReplyListGuid|ViewModelListFileContainerItem>> =
                  this.fileKindId
                      ? this.filesApi.fileContainersUploadMultipleFileContainers( this.fileKindId, this.file, null, null, null, null, 'events', true )
                      : this.filesApi.fileContainersUploadMultipleFileVersions( this.fileContainerId, this.file, null, null, null, null, 'events', true );

        this.subscription =
            apiCall.subscribe(
                ( result: HttpEvent<any> ) => {
                    if ( result.type === HttpEventType.UploadProgress ) {
                        this.progress = Math.round( 100 * result.loaded / result.total );
                    }
                    else if ( result instanceof HttpResponse ) {
                        if ( this.fileKindId ) {
                            const fileInfo: FileContainerItem = (result.body as ReplyViewModelListFileContainerItem).value.data[ 0 ];

                            this.fileUploaded.emit( {
                                fileContainerId: fileInfo.id,
                                previewImageId:  fileInfo.previewImageId,
                            } );
                        }
                        else {
                            const previewImageId: string = ( result as HttpResponse<ReplyListGuid> ).body.value[ 0 ];

                            this.fileUploaded.emit( {
                                fileContainerId: this.fileContainerId,
                                previewImageId:  previewImageId,
                            } );
                        }
                    }
                },
                () => { // error callback, like when we aren't authorized anymore (401). The oncomplete callback doesn't get called then.
                    this.isFailed    = true;
                    this.isComplete  = true;
                    this.isUploading = false;
                },
                () => {
                    this.isComplete  = true;
                    this.isUploading = false;
                },
            );


        if ( event ) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    cancelFileUpload( event?: Event ): void {
        if ( this.subscription ) {
            this.reset();
            this.fileUploaded.emit( null );

            this.notificationService.warning(
                'Upload canceled',
                'You canceled the upload',
            );
        }

        if ( event ) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    triggerRemove(): void {
        this.reset();
        this.remove.emit();
    }

    private reset(): void {
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }

        this.progress    = 0;
        this.isUploading = false;
        this.isFailed    = false;
        this.isComplete  = false;
    }
}
