<div class="advanced-search--close-button-wrapper">
    <rsp-button
        [type]="'button'"
        [submodule]="'tertiary'"
        (clicked)="emitCloseEvent()"
        class="advanced-search--close-button"
    >
        <span class="advanced-search--close-button-caption">Close</span>
        <span class="advanced-search--close-button-icon"></span>
    </rsp-button>
</div>

<div class="advanced-search--filters">
    <ng-container *ngFor="let filterGroup of filterGroups; let index = index; let isLast = last">
        <rsp-advanced-search-filter-group
            [id]="index"
            [filterGroup]="filterGroup"
            [filterFields]="filterFields"
            [noRemove]="filterGroups.length === 1"
            (onRemove)="removeFilterGroup( index )"
            (onSubmit)="applyFilters()"
        ></rsp-advanced-search-filter-group>

        <hr
            *ngIf="!isLast"
            class="advanced-search--filter-separator"
        >
    </ng-container>
</div>

<footer class="advanced-search--buttons">
    <rsp-button
        [type]="'button'"
        [submodule]="'tertiary'"
        (clicked)="addEmptyFilterGroup()"
        class="advanced-search--buttons-add"
    >
        Add Group
    </rsp-button>

    <rsp-button
        [type]="'button'"
        [submodule]="'secondary'"
        (clicked)="resetFilters()"
        class="advanced-search--buttons-reset"
    >
        Reset
    </rsp-button>

    <rsp-button
        [type]="'button'"
        [submodule]="'primary'"
        (clicked)="applyFilters()"
        class="advanced-search--buttons-apply"
    >
        Search
    </rsp-button>
</footer>
