import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DetailPagePrevNextNavigationService } from '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.service';
import { NonTradeItemStoreService } from '../../shared/non-trade-item-store.service';
import { NonTradeListItem } from '../../../../../shared/model/nonTradeListItem';

@Injectable()
export class NonTradeItemPrevNextNavigationService extends DetailPagePrevNextNavigationService<NonTradeListItem> {
    constructor(
        router: Router,
        nonTradeItemStoreService: NonTradeItemStoreService,
    ) {
        super( router, nonTradeItemStoreService );
    }

    protected getItemIndex( id: string ): number {
        const pseudoItem: any = { id: id }; // meh…
        const index: number   = this.storeService.getItemIndex( pseudoItem );

        return index;
    }

    protected createRouterLinkForItem( item: NonTradeListItem ): any[] {
        const itemTypeAsString: string = NonTradeListItem.TypeEnum.toString( item.type ).toLowerCase();

        return [ '/' + itemTypeAsString, item.number, this.lastUrlPart ];
    }
}
