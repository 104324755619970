/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface NonTradeSuggestItem {
    type?: NonTradeSuggestItem.TypeEnum;

    id?: string;

    number?: string;

    name?: string;

    packagingUnit?: number;

    previewImageId?: string;

    status?: NonTradeSuggestItem.StatusEnum;

    hasStyles?: boolean;

    isReadyToOrder?: boolean;

}

export namespace NonTradeSuggestItem {
    'use strict';
    export enum TypeEnum {
        Article,
        Assembly,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
    export enum StatusEnum {
        Unpublished,
        Development,
        ActiveInPreRollout,
        ActiveInRollout,
        ActiveOnDemand,
        Discontinue,
        Inactive,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

