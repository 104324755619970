import { OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { BaseStoreService } from './store/base-store.service';
import { BaseSelectionService } from './base-selection.service';
import { VirtualScrollComponent } from './virtual-scroll/virtual-scroll.component';
import {
    AdvancedSearchFilterGroup,
    AdvancedSearchFilterProperty,
    AdvancedSearchFilterTypeOperator,
    FacetFilterItem,
    FacetItem,
    SelectListItem,
} from '../../model';
import { MainLoadingIndicatorService } from '../../../core/main-loading-indicator/main-loading-indicator.service';
import { StorageService } from '../../../core/storage.service';
import { Component } from '@angular/core';


/**
 * Base class for all list-page-components.
 */
@Component({
    template: '',
})
export abstract class BaseListPageComponent<T> implements OnInit, OnDestroy {

    @ViewChild(VirtualScrollComponent) virtualScrollComponent: VirtualScrollComponent;

    isSidebarVisible: boolean;

    scrollToId: string;

    items: Observable<Array<T>>;
    visibleItems: Array<T>;

    facets: Observable<Array<FacetItem>>;
    selectLists: Observable<Array<SelectListItem>>;
    chosenValuesFacet: Observable<Array<FacetItem>>;
    facetFilters: Observable<Array<FacetFilterItem>>;
    usedQuickSearchTerm: Observable<string>;
    advancedSearchFilterTypeOperators: Observable<Array<AdvancedSearchFilterTypeOperator>>;
    advancedSearchFilterProperties: Observable<Array<AdvancedSearchFilterProperty>>;
    advancedSearchFilterGroups: Observable<Array<AdvancedSearchFilterGroup>>;

    abstract get storageKeyIsSidebarVisible(): string;

    abstract get storageKeyScrollToId(): string;

    isLoading: boolean = true;

    protected isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        protected activatedRoute: ActivatedRoute,
        private storeService: BaseStoreService<T>,
        private selectionService: BaseSelectionService<T>,
        private storageService: StorageService,
        protected mainLoadingIndicatorService: MainLoadingIndicatorService,
    ) {
        // observable for template
        this.items                             = this.storeService.items$;
        this.facets                            = this.storeService.facets$;
        this.selectLists                       = this.storeService.selectLists$;
        this.chosenValuesFacet                 = this.storeService.chosenValuesFacet$;
        this.facetFilters                      = this.storeService.facetFilters$;
        this.usedQuickSearchTerm               = this.storeService.usedQuickSearchTerm$;
        this.advancedSearchFilterTypeOperators = this.storeService.advancedSearchFilterTypeOperators$;
        this.advancedSearchFilterProperties    = this.storeService.advancedSearchFilterProperties$;
        this.advancedSearchFilterGroups        = this.storeService.advancedSearchFilterGroups$;

        if ( this.selectionService ) {
            this.selectionService.clearLastSelectedItem();
        }


        this.storeService
            .sortByChanged$
            .pipe(
                filter( () => !!this.virtualScrollComponent ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                // scroll to top if sort by is changed
                this.virtualScrollComponent.scrollToTop();
                this.resetScrollToId();
            } );
    }


    ngOnInit(): void {

        // unregister loading observable in case of error to hide loading indicator
        this.storeService
            .unregisterLoading$
            .pipe(
                filter( ( value: boolean ) => value ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                this.mainLoadingIndicatorService.unregisterObservable( this.storeService.loading$ );
            } );

        // main loading indicator show be active when StoreService loads data from backend
        this.mainLoadingIndicatorService.registerObservable( this.storeService.loading$ );

        // sidebar visibility
        let isSidebarVisible: boolean | null = this.storageService.get<boolean>( this.storageKeyIsSidebarVisible );
        if ( isSidebarVisible === null ) {
            isSidebarVisible = true;
        }
        this.isSidebarVisible = isSidebarVisible;

        this.scrollToId = this.storageService.get<string>( this.storageKeyScrollToId );


        this.mainLoadingIndicatorService
            .isVisible$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( isLoading: boolean ) => this.isLoading = isLoading );


        this.activatedRoute
            .queryParams
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( params: Params ) => {
                this.reloadListWithParams( params );
            } );
    }


    /**
     * NOTE: if inheriting class hast its own ngOnDestroy() method, it must call super.ngOnDestroy().
     */
    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        this.mainLoadingIndicatorService.unregisterObservable( this.storeService.loading$ );
    }


    /**
     * List has been scrolled to the bottom. Next page will be loaded.
     */
    onScrolledToBottom(): void {

        this.resetScrollToId();
        this.storeService.loadNextPage();
    }


    /**
     * Facets have been changed.
     */
    onFacetValuesChanged( filterItems: FacetFilterItem[], additionalQueryParams?: Params ): void {
        this.setFilter( 'facets', filterItems, additionalQueryParams );
    }


    /**
     * Advanced Search have been changed.
     */
    onAdvancedSearchValueChanged( advancedSearchFilterGroups: AdvancedSearchFilterGroup[], additionalQueryParams?: Params ): void {
        this.setFilter( 'advanced-search', advancedSearchFilterGroups, additionalQueryParams );
    }

    /**
     * Selects Lists have been changed
     */
    onSelectListsChanged( selectLists: SelectListItem[] ): void {
        this.setFilter( 'select-lists', selectLists );
    }


    /**
     * Clears facets and advanced-search filters.
     */
    onFilterReset(): void {
        this.setFilter( 'all', [] );
    }

    onQuickSearchReset(): void {
        const navigationExtras: NavigationExtras = {
            queryParams: {},
        };
        // merge all query params
        Object.assign( navigationExtras.queryParams, this.activatedRoute.snapshot.queryParams );

        delete navigationExtras.queryParams.qs;

        // the route change will trigger the update, because there's a subscription on the query params.
        this.router.navigate( [], navigationExtras );
    }


    /**
     * Facet Sidebar visibility has been toggled.
     */
    onSidebarVisibilityChanged( isVisible: boolean ): void {

        this.isSidebarVisible = isVisible;
        this.storageService.set<boolean>( this.storageKeyIsSidebarVisible, this.isSidebarVisible );

        // Collapsing/expanding can change virtual-scroll item size, the view must be rebuild.
        // We have to wait till animation is ready.
        if ( this.virtualScrollComponent ) {
            setTimeout(
                () => {
                    this.virtualScrollComponent.rebuild( false );
                },
                500,
            );
        }
    }


    /**
     * Reload list using current route params.
     */
    reloadList(): void {

        const params: Params = this.activatedRoute.snapshot.queryParams;
        this.reloadListWithParams( params );
    }


    /**
     *  Override this method to manipulate query params
     */
    processQueryParams(
        queryParams: Params,
        filterType: 'facets' | 'advanced-search' | 'select-lists' | 'all',
        filters: FacetFilterItem[] | AdvancedSearchFilterGroup[] | SelectListItem[],
    ): Params {
        return queryParams;
    }

    // protected methods
    // ----------------------------------------------------------------------------------------------------------------
    protected setFilter(
        filterType: 'facets' | 'advanced-search' | 'select-lists' | 'all',
        filters: FacetFilterItem[] | AdvancedSearchFilterGroup[] | SelectListItem[],
        additionalQueryParams?: Params,
    ): void {

        // change url - add facets as query params
        const navigationExtras: NavigationExtras = {
            queryParams: {},
        };

        // merge all query params
        Object.assign( navigationExtras.queryParams, this.activatedRoute.snapshot.queryParams );

        if ( filterType !== 'all' ) {
            if ( filters.length > 0 ) {
                navigationExtras.queryParams[ filterType ] = JSON.stringify( filters );
            }
            else {
                delete navigationExtras.queryParams[ filterType ];
            }

            if ( additionalQueryParams ) {
                // merge query params
                navigationExtras.queryParams = { ...navigationExtras.queryParams, ...additionalQueryParams };
            }
        }
        else {
            delete navigationExtras.queryParams[ 'select-lists' ];
            delete navigationExtras.queryParams[ 'facets' ];
            delete navigationExtras.queryParams[ 'advanced-search' ];
        }

        navigationExtras.queryParams = this.processQueryParams( navigationExtras.queryParams, filterType, filters );

        // the route change will trigger the facet update, because there's a subscription on the query params.
        this.router.navigate( [], navigationExtras );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private reloadListWithParams( params: Params ): void {
        // scroll to top to prevent infinite scrolling
        if ( this.virtualScrollComponent ) {
            this.virtualScrollComponent.scrollToTop();
            this.resetScrollToId();
        }


        // set facets and advanced search params and load firs page again
        this.storeService
            .setFacetFilterValues( JSON.parse( params.facets || '[]' ) )
            .setAdvancedSearchValues( JSON.parse( params[ 'advanced-search' ] || '[]' ) )
            .setSelectListsValues( JSON.parse( params[ 'select-lists' ] || '[]' ) )
            .setQuickSearch( params.qs )
            .setSortBy( JSON.parse( params.sorting || '[]' ) )
            .loadFirstPage( true );
    }


    private resetScrollToId(): void {
        this.scrollToId = null;
        this.storageService.set<string>( this.storageKeyScrollToId, null );
    }

}
