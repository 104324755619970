<div class="view-switcher">

    <span class="view-switcher--label">
        View:
    </span>

    <rsp-button
        [submodule]="'seamless'"
        [class.is-active]="( active | async ) === viewKind.Table"
        (click)="changeViewKind( viewKind.Table )"
        class="view-switcher--table-button"
        title="Table"
    ></rsp-button>

    <rsp-button
        [submodule]="'seamless'"
        [class.is-active]="( active | async ) === viewKind.List"
        (click)="changeViewKind( viewKind.List )"
        class="view-switcher--list-button"
        title="List"
    ></rsp-button>

</div>
