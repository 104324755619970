import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { IdentityServerLoginGuard } from '../../core/guards/identity-server-login.guard';
import { RspUserGuard } from '../../core/guards/rsp-user.guard';

const DASHBOARD_ROUTES: Routes = [
    {
        path:             'dashboard',
        canActivate:      [ IdentityServerLoginGuard, RspUserGuard ],
        canActivateChild: [ IdentityServerLoginGuard, RspUserGuard ],
        component:        DashboardComponent,
    },
];

@NgModule( {
    imports:   [ RouterModule.forChild( DASHBOARD_ROUTES ) ],
    exports:   [ RouterModule ],
    providers: [],
} )
export class DashboardRoutingModule {
}

