/* tslint:disable:max-line-length */
import { NgModule                                   } from '@angular/core';
import { CommonModule, CurrencyPipe, I18nPluralPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule           } from '@angular/forms';
import { RouterModule                               } from '@angular/router';
import { OverlayModule                              } from '@angular/cdk/overlay';
import { PortalModule                               } from '@angular/cdk/portal';
import { TextFieldModule                            } from '@angular/cdk/text-field';

import { DateTimeModule                             } from '@nureg/angular-date-time';

import { DisplayEditExperiencesComponent            } from './forms/controls/display-edit-experiences/display-edit-experiences.component';
import { EditExperiencesComponent                   } from './forms/controls/display-edit-experiences/edit-experiences.component';

import { ButtonComponent,                            } from './ui/button/button.component';
import { ClickOutsideDirective,                      } from './ui/click-outside/click-outside.directive';
import { CardComponent                               } from './ui/card/card.component';
import { DetailsPageComponent,                       } from './ui/layout/details-page/details-page.component';
import { DropZoneComponent                           } from './ui/drop-zone/drop-zone.component';
import { DropDirective                               } from './ui/drop-zone/drop.directive';
import { DropdownComponent,                          } from './ui/dropdown/dropdown.component';
import { DropdownItemComponent,                      } from './ui/dropdown/dropdown-item.component';
import { EmptyComponent,                             } from './ui/empty/empty.component';
import { FiltersComponent,                           } from './ui/filters/filters.component';
import { FacetComponent,                             } from './ui/filters/facet/facet.component';
import { FacetNameComponent,                         } from './ui/filters/facet/facet-name/facet-name.component';
import { FacetService,                               } from './ui/filters/facet/facet.service';
import { FacetSummaryComponent                       } from './ui/filters/facet/facet-summary/facet-summary.component';
import { FacetTypeChosenValuesComponent,             } from './ui/filters/facet/facet-type-chosen-values/facet-type-chosen-values.component';
import { FacetTypeSelectComponent,                   } from './ui/filters/facet/facet-type-select/facet-type-select.component';
import { FacetTypeSingleselectComponent,             } from './ui/filters/facet/facet-type-select/facet-type-singleselect/facet-type-singleselect.component';
import { FacetTypeMultiselectComponent,              } from './ui/filters/facet/facet-type-select/facet-type-multiselect/facet-type-multiselect.component';
import { FacetTypeMultiselectHierarchicalComponent,  } from './ui/filters/facet/facet-type-select/facet-type-multiselect-hierarchical/facet-type-multiselect-hierarchical.component';
import { FileUploadComponent                         } from './forms/upload/file-upload/file-upload.component';
import { FileTableComponent,                         } from './ui/lists/file-table/file-table.component';
import { FileVersionDialogComponent,                 } from './ui/lists/file-version-dialog/file-version-dialog.component';
import { ImageComponent,                             } from './ui/image/image.component';
import { DisplayEditGraphicDimensionsComponent,      } from './forms/controls/display-edit-graphic-dimensions/display-edit-graphic-dimensions.component';
import { MessagesComponent,                          } from './ui/messages/messages.component';
import { BreadcrumbComponent,                        } from './ui/navigation/breadcrumb/breadcrumb.component';
import { BreadcrumbPartComponent,                    } from './ui/navigation/breadcrumb/breadcrumb-part/breadcrumb-part.component';
import { ClusterLinkComponent,                       } from './ui/navigation/cluster-link/cluster-link.component';
import { LinkComponent,                              } from './ui/navigation/link/link.component';
import { VerticalTabsComponent,                      } from './ui/navigation/vertical-tabs/vertical-tabs.component';
import { OverlayComponent,                           } from './ui/overlay/overlay.component';
import { StatusComponent,                            } from './ui/status/status.component';
import { KeywordSuggesterComponent,                  } from './ui/suggester/keyword-suggester/keyword-suggester.component';
import { TagsComponent,                              } from './ui/tags/tags.component';
import { TimelineComponent,                          } from './ui/timeline/timeline.component';
import { UserBarButtonComponent,                     } from './ui/user-bar-button/user-bar-button.component';
import { UserBarButtonNotificationComponent,         } from './ui/user-bar-button/user-bar-button-notification/user-bar-button-notification.component';
import { FormatNumberService,                        } from './utils/number/format-number.service';
import { JoinedPipe,                                 } from './utils/string/joined.pipe';
import { PluralService,                              } from './utils/string/plural.service';
import { QueryParamService,                          } from './utils/url/query-param.service';
import { NoContentComponent                          } from './ui/no-content/no-content.component';
import { NotAvailableComponent,                      } from './ui/not-available/not-available.component';
import { LoadingIndicatorDirective,                  } from './ui/loading-indicators/loading-indicator/loading-indicator.directive';
import { KeyValueComponent,                          } from './ui/key-value/key-value.component';
import { EditKeywordsComponent,                      } from './forms/controls/edit-keywords/edit-keywords.component';
import { ValidationMessagesService,                  } from './forms/validation/validation-messages.service';
import { EditFormButtonsComponent,                   } from './forms/edit-form-buttons/edit-form-buttons.component';
import { DisplayEditListMultipleChoiceComponent      } from './forms/controls/display-edit-list-multiple-choice/display-edit-list-multiple-choice.component';
import { DisplayEditNumberComponent,                 } from './forms/controls/display-edit-number/display-edit-number.component';
import { DisplayEditTextComponent,                   } from './forms/controls/display-edit-text/display-edit-text.component';
import { DisplayEditTextMultilineComponent,          } from './forms/controls/display-edit-text-multiline/display-edit-text-multiline.component';
import { DisplayEditWidthDepthHeightComponent,       } from './forms/controls/display-edit-width-height-depth/display-edit-width-depth-height.component';
import { DisplayEditOptionsComponent,                } from './forms/controls/display-edit-options/display-edit-options.component';
import { NewlinePipe,                                } from './utils/string/newline2br.pipe';
import { UnitMillimeterComponent                     } from './ui/units/unit-millimeter/unit-millimeter.component';
import { UnitKilogramComponent                       } from './ui/units/unit-kilogram/unit-kilogram.component';
import { UnitSquareMeterComponent                    } from './ui/units/unit-square-meter/unit-square-meter.component';
import { UnitCubicMeterComponent                     } from './ui/units/unit-cubic-meter/unit-cubic-meter.component';
import { UploadSingleFileComponent                   } from './forms/upload/upload-single-file/upload-single-file.component';
import { CardHeadlineComponent                       } from './ui/card/card-headline/card-headline.component';
import { CardContentComponent                        } from './ui/card/card-content/card-content.component';
import { DisplayEditDateTimeRangeComponent           } from './forms/controls/display-edit-date-time-range/display-edit-date-time-range.component';
import { ScrollDirective                             } from './ui/scroll/scroll.directive';
import { ColumnFilterTextComponent                   } from './ui/tables/controls/column-filter-text/column-filter-text.component';
import { ColumnHeaderComponent                       } from './ui/tables/controls/column-header/column-header.component';
import { GlobalFilterComponent                       } from './ui/tables/controls/global-filter/global-filter.component';
import { StandardFilters                             } from './ui/tables/filters/standard-filters';
import { StandardSorters                             } from './ui/tables/sorters/standard-sorters';
import { TransformService                            } from './utils/string/transform.service';
import { ScrollService                               } from './ui/scroll/scroll.service';
import { PositionStickyDirective } from './ui/scroll/position-sticky.directive';
import { FixTableRowDirective } from './ui/scroll/fix-table-row.directive';
import { TextInputDirective } from './ui/input/text-input.directive';
import { CheckboxDirective } from './ui/checkbox-and-radiobutton/checkbox.directive';
import { RadioButtonGroupComponent } from './ui/radio-button-group/radio-button-group.component';
import { DropdownButtonComponent } from './ui/dropdown-button/dropdown-button.component';
import { DropdownButtonItemComponent } from './ui/dropdown-button/dropdown-button-item.component';
import { KeywordDirective } from './ui/keywords/keyword.directive';
import { ZeroOrPositiveNumberValidationDirective, PositiveNumberValidationDirective } from './forms/validation/number-validation.directive';
import { RadioButtonDirective } from './ui/checkbox-and-radiobutton/radio-button.directive';
import { ProgressSpinnerComponent, SpinnerComponent } from './ui/loading-indicators/progress-spinner/progress-spinner.component';
import { AccordionComponent } from './ui/accordion/accordion.component';
import { AccordionGroupComponent } from './ui/accordion/accordion-group/accordion-group.component';
import { DropdownGroupComponent } from './ui/dropdown/dropdown-group.component';
import { VerticalScrollComponent } from './ui/lists/vertical-scroll/vertical-scroll.component';
import { VerticalScrollService } from './ui/lists/vertical-scroll/vertical-scroll.service';
import { CapitalizePipe } from './utils/string/capitalize.pipe';
import { SecondaryToolbarButtonComponent } from './ui/secondary-toolbar/secondary-toolbar-button/secondary-toolbar-button.component';
import { BadgeCounterComponent } from './ui/badge-counter/badge-counter.component';
import { ProgressLinearComponent } from './ui/loading-indicators/progress-linear/progress-linear.component';
import { ViewSwitcherComponent } from './ui/secondary-toolbar/view-switcher/view-switcher.component';
import { ViewSwitcherService } from './ui/secondary-toolbar/view-switcher/view-switcher.service';
import { DownloadLinkComponent } from './ui/download-link/download-link.component';
import { VirtualScrollComponent } from './ui/lists/virtual-scroll/virtual-scroll.component';
import { VirtualScrollItemComponent } from './ui/lists/virtual-scroll/virtual-scroll-item/virtual-scroll-item.component';
import { SecondaryToolbarSlideInButtonComponent } from './ui/secondary-toolbar/secondary-toolbar-slidein-button/secondary-toolbar-slidein-button.component';
import { EditTextComponent } from './forms/controls/edit-text/edit-text.component';
import { EditMultilineComponent } from './forms/controls/edit-multiline/edit-multiline.component';
import { EditDateTimeRangeComponent } from './forms/controls/edit-date-time-range/edit-date-time-range.component';
import { IntegerValidationDirective } from './forms/validation/integer-validation.directive';
import { ErrorMessagesComponent } from './forms/error-messages/error-messages.component';
import { ListPageComponent } from './ui/layout/list-page/list-page.component';
import { SwitchDirective } from './ui/switch/switch.directive';
import { CultureDisplayDirective } from './ui/culture-display/culture-display.directive';
import { UserSuggesterComponent } from './ui/suggester/user-suggester/user-suggester.component';
import { RemoveComponent } from './ui/remove/remove.component';
import { ComponentTableComponent } from './ui/lists/component-table/component-table.component';
import { NonTradeItemSuggesterComponent } from './ui/suggester/non-trade-item-suggester/non-trade-item-suggester.component';
import { DistributionChannelSelectListComponent } from './ui/select-list/distribution-channel-select-list/distribution-channel-select-list.component';
import { VirtualArticleNumberComponent } from './ui/virtual-article-number/virtual-article-number.component';
import { ProgressStepViewComponent } from './ui/progress-step-view/progress-step-view.component';
import { ObjectPathService } from './utils/object/object-path.service';
import { WorkflowProgressBarComponent } from './ui/workflow-progress-bar/workflow-progress-bar.component';
import { IconButtonComponent } from './ui/button/icon-button/icon-button.component';
import { CurrencySuggesterComponent } from './ui/suggester/currency-suggester/currency-suggester.component';
import { CurrencyNameWithAbbreviationPipe } from './utils/currency/currency-name-with-abbreviation.pipe';
import { EnumConverterService } from './utils/enum/enum-converter.service';
import { DisplayEditDateComponent } from './forms/controls/display-edit-date/display-edit-date.component';
import { UserNameComponent } from './ui/user-name/user-name.component';
import { ConceptSuggesterComponent } from './ui/suggester/concept-suggester/concept-suggester.component';
import { ScrollContainerService } from './ui/scroll/scroll-container.service';
import { ScrollContainerDirective } from './ui/scroll/scroll-container.directive';
import { SupplementaryCaptionComponent } from './ui/supplementary-caption/supplementary-caption.component';
import { AdvancedSearchComponent } from './ui/filters/advanced-search/advanced-search.component';
import { AdvancedSearchFilterGroupComponent } from './ui/filters/advanced-search/advanced-search-filter-group/advanced-search-filter-group.component';
import { AdvancedSearchFilterGroupFilterComponent } from './ui/filters/advanced-search/advanced-search-filter-group/advanced-search-filter-group-filter/advanced-search-filter-group-filter.component';
import { DisplayEditMonthYearComponent } from './forms/controls/display-edit-month-year/display-edit-month-year.component';
import { DisplayEditMonthYearRangeComponent } from './forms/controls/display-edit-month-year-range/display-edit-month-year-range.component';
import { AdvancedSearchService } from './ui/filters/advanced-search/advanced-search.service';
import { InfoTooltipComponent } from './ui/info-tooltip/info-tooltip.component';
import { TooltipComponent } from './ui/tooltip/tooltip.component';
import { TooltipDirective } from './ui/tooltip/tooltip.directive';
import { TooltipService } from './ui/tooltip/tooltip.service';
import { UserNameService } from './ui/user-name/user-name.service';
import { TaskTodoComponent } from './ui/task-todo/task-todo.component';
import { ClusterSuggesterComponent } from '../features/article-management/non-trade-items/shared/cluster-suggester/cluster-suggester.component';
import { EditNumberWithStepComponent } from './forms/controls/edit-number-with-step/edit-number-with-step.component';
import { UserProfileImageComponent } from './ui/user-profile-image/user-profile-image.component';
import { ServiceModelsTableComponent } from './ui/service-models-table/service-models-table.component';
import { DisplayEditUserProfileImageComponent } from './forms/controls/display-edit-user-profile-image/display-edit-user-profile-image.component';
import { DisplayEditPhoneNumberComponent } from './forms/controls/display-edit-phone-number/display-edit-phone-number.component';
import { DistributionChannelsComponent } from './ui/distribution-channels/distribution-channels.component';
import { UserTileComponent } from './ui/user/user-tile/user-tile.component';
import { UserService } from './ui/user/user.service';
import { UserDistributionChannelsComponent } from './ui/user/user-distribution-channels/user-distribution-channels.component';
import { ConceptsComponent } from './ui/concepts/concepts.component';
import { UserConceptsComponent } from './ui/user/user-concepts/user-concepts.component';
import { UserDetailsComponent } from './ui/user/user-details/user-details.component';
import { DisplayEditCultureComponent } from './forms/controls/display-edit-culture/display-edit-culture.component';
import { UserModuleGroupsDialogComponent } from './ui/user/user-details/user-module-groups-dialog/user-module-groups-dialog.component';
import { SortByComponent } from './ui/secondary-toolbar/sort-by/sort-by.component';
import { CamelCaseToSpacePipe } from './utils/string/camel-case-to-space.pipe';
import { CreateZipFromFilesDialogComponent } from './ui/lists/create-zip-from-files-dialog/create-zip-from-files-dialog.component';
import { ConvertPasteDataDirective } from './ui/input/convert-paste-data.directive';
import { UserNamePipe } from './ui/user/user-name.pipe';
import { EditDateComponent } from './forms/controls/display-edit-date/edit-date.component';
import { SubComponentSuggesterComponent } from './ui/subcomponent-suggester/subcomponent-suggester.component';
import { LegalNoticeDialogComponent } from './ui/legal-notice-dialog/legal-notice-dialog.component';
import { ImprintDialogComponent } from './ui/imprint-dialog/imprint-dialog.component';
import { RemoveWithReasonComponent } from './ui/remove-with-reason/remove-with-reason.component';
import { StoreInfoComponent } from './ui/secondary-toolbar/store-info/store-info.component';


/* tslint:enable:max-line-length */


const DECLARATIONS: any = [
    // Components
    AccordionComponent,
    AccordionGroupComponent,
    AdvancedSearchComponent,
    AdvancedSearchFilterGroupComponent,
    AdvancedSearchFilterGroupFilterComponent,
    BadgeCounterComponent,
    BreadcrumbComponent,
    BreadcrumbPartComponent,
    ButtonComponent,
    ClusterLinkComponent,
    ColumnFilterTextComponent,
    ColumnHeaderComponent,
    ConceptSuggesterComponent,
    ServiceModelsTableComponent,
    CardComponent,
    CardContentComponent,
    CardHeadlineComponent,
    ClusterSuggesterComponent,
    ComponentTableComponent,
    CurrencySuggesterComponent,
    DetailsPageComponent,
    DisplayEditDateComponent,
    EditDateComponent,
    DisplayEditDateTimeRangeComponent,
    DisplayEditListMultipleChoiceComponent,
    DisplayEditMonthYearComponent,
    DisplayEditMonthYearRangeComponent,
    DisplayEditNumberComponent,
    DisplayEditOptionsComponent,
    DisplayEditPhoneNumberComponent,
    DisplayEditTextComponent,
    DisplayEditExperiencesComponent,
    DisplayEditTextMultilineComponent,
    DisplayEditUserProfileImageComponent,
    DisplayEditWidthDepthHeightComponent,
    DistributionChannelsComponent,
    DistributionChannelSelectListComponent,
    DownloadLinkComponent,
    DropdownButtonComponent,
    DropdownButtonItemComponent,
    DropdownComponent,
    DropdownItemComponent,
    DropdownGroupComponent,
    DropZoneComponent,
    EditDateTimeRangeComponent,
    EditFormButtonsComponent,
    EditKeywordsComponent,
    EditMultilineComponent,
    EditTextComponent,
    EditExperiencesComponent,
    EmptyComponent,
    ErrorMessagesComponent,
    FacetComponent,
    FacetNameComponent,
    FacetSummaryComponent,
    FacetTypeChosenValuesComponent,
    FacetTypeMultiselectComponent,
    FacetTypeMultiselectHierarchicalComponent,
    FacetTypeSelectComponent,
    FacetTypeSingleselectComponent,
    FiltersComponent,
    FileUploadComponent,
    FileTableComponent,
    FileVersionDialogComponent,
    GlobalFilterComponent,
    DisplayEditGraphicDimensionsComponent,
    InfoTooltipComponent,
    ImageComponent,
    KeyValueComponent,
    KeywordSuggesterComponent,
    LinkComponent,
    ListPageComponent,
    MessagesComponent,
    NoContentComponent,
    NonTradeItemSuggesterComponent,
    NotAvailableComponent,
    OverlayComponent,
    ProgressLinearComponent,
    ProgressSpinnerComponent,
    ProgressStepViewComponent,
    RadioButtonGroupComponent,
    RemoveComponent,
    RemoveWithReasonComponent,
    SecondaryToolbarButtonComponent,
    SecondaryToolbarSlideInButtonComponent,
    SpinnerComponent,
    StatusComponent,
    StoreInfoComponent,
    SupplementaryCaptionComponent,
    SubComponentSuggesterComponent,
    TagsComponent,
    TimelineComponent,
    UnitCubicMeterComponent,
    UnitKilogramComponent,
    UnitMillimeterComponent,
    UnitSquareMeterComponent,
    UserBarButtonComponent,
    UserBarButtonNotificationComponent,
    UserNameComponent,
    UserProfileImageComponent,
    UserSuggesterComponent,
    UploadSingleFileComponent,
    VerticalScrollComponent,
    VerticalTabsComponent,
    ViewSwitcherComponent,
    VirtualArticleNumberComponent,
    VirtualScrollComponent,
    VirtualScrollItemComponent,
    WorkflowProgressBarComponent,
    IconButtonComponent,
    TooltipComponent,
    TaskTodoComponent,
    EditNumberWithStepComponent,
    UserTileComponent,
    UserDistributionChannelsComponent,
    ConceptsComponent,
    UserConceptsComponent,
    UserDetailsComponent,
    DisplayEditCultureComponent,
    UserModuleGroupsDialogComponent,
    SortByComponent,
    CreateZipFromFilesDialogComponent,
    LegalNoticeDialogComponent,
    ImprintDialogComponent,

    // Directives
    CheckboxDirective,
    ClickOutsideDirective,
    CultureDisplayDirective,
    DropDirective,
    FixTableRowDirective,
    KeywordDirective,
    LoadingIndicatorDirective,
    PositiveNumberValidationDirective,
    ZeroOrPositiveNumberValidationDirective,
    IntegerValidationDirective,
    PositionStickyDirective,
    ScrollContainerDirective,
    RadioButtonDirective,
    ScrollDirective,
    SwitchDirective,
    TextInputDirective,
    TooltipDirective,
    ConvertPasteDataDirective,

    // Pipes
    CapitalizePipe,
    JoinedPipe,
    NewlinePipe,
    CurrencyNameWithAbbreviationPipe,
    CamelCaseToSpacePipe,
    UserNamePipe,
];



@NgModule( {
    imports: [
        // Modules: Angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        // Angular Material CDK
        OverlayModule,
        PortalModule,
        TextFieldModule,
        DateTimeModule,
    ],
    declarations: [
        DECLARATIONS,
    ],
    exports: [
        // Modules: Angular
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        DECLARATIONS,
        DateTimeModule,
    ],
    providers: [
        // Services
        AdvancedSearchService,
        EnumConverterService,
        FacetService,
        FormatNumberService,
        PluralService,
        ObjectPathService,
        QueryParamService,
        StandardFilters,
        StandardSorters,
        ValidationMessagesService,
        VerticalScrollService,
        ViewSwitcherService,
        TooltipService,
        TransformService,
        ScrollService,
        ScrollContainerService,
        UserNameService,
        UserService,
        // Pipes
        CapitalizePipe,
        CurrencyPipe,
        I18nPluralPipe,
        CurrencyNameWithAbbreviationPipe,
        CamelCaseToSpacePipe,
        UserNamePipe,
    ],
} )

export class SharedModule {}

