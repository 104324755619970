import { Injectable, } from '@angular/core';
import { ApplicationTimeService } from './application-time.service';



@Injectable()
export class WebApiService {

    private HEADER_CACHE_CONTROL: string     = 'Cache-Control';
    private HEADER_PRAGMA: string            = 'Pragma';
    private HEADER_IF_MODIFIED_SINCE: string = 'If-Modified-Since';

    // private methods
    // ----------------------------------------------------------------------------------------------------------------


    // TODO: use this in an interceptor
    private appendTimestamp( url: string ): string {

        // append timestamp to url (analog to jQuery.ajax( ... cache: false ... ) => GET .../companies?_=1234567

        let appendix: string = '';

        if ( url ) {

            if ( url.includes( '?' ) ) {
                appendix += '&';
            }
            else {
                appendix += '?';
            }

            appendix += '_=' + ApplicationTimeService.current().getTime();
        }

        return url + appendix;
    }
}


