<div class="tab">
    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <ng-container *ngIf="isLoading.closed && styles.length === 0; then noContentMessage else stylesList">
        </ng-container>
    </div>
</div>

<ng-template #stylesList>
    <div>

    <ng-container *ngIf="styles.length > 0">
        <rsp-accordion class="non-trade-item-styles-and-artworks--styles">
        <rsp-accordion-group
            *ngFor="let style of styles"
            [isOpen]="areDetailsVisibleOfStyleId[ style.id ]"
            (isOpened)="areDetailsVisibleOfStyleId[ style.id ] = $event"
        >
            <ng-container accordion-group-heading>
                <div class="non-trade-item-styles-and-artworks--style-line">
                    <div
                        *ngIf="style.id.substr( 0, 4 ) !== 'new-'"
                        class="non-trade-item-styles-and-artworks--style-line-number"
                    >
                        <rsp-supplementary-caption>N°</rsp-supplementary-caption>
                        {{ style.number }}
                    </div>

                    <div
                        *ngIf="!areDetailsVisibleOfStyleId[ style.id ]"
                        class="non-trade-item-styles-and-artworks--style-line-name-and-active"
                    >
                        <div class="non-trade-item-styles-and-artworks--style-line-name">
                            <rsp-supplementary-caption>Style Name</rsp-supplementary-caption>
                            {{ style.name }}
                        </div>

                        <div
                            class="non-trade-item-styles-and-artworks--style-line-active"
                        >
                            <rsp-supplementary-caption>Style is active</rsp-supplementary-caption>
                            <input
                                rspSwitch
                                [checked]="style.isActive"
                                [disabled]="true"
                                [style.visibility]="isEditModeForStyleId[ style.id ] ? 'hidden' : null"
                                type="checkbox"
                            >
                        </div>
                    </div>

                    <div
                        *ngIf="!areDetailsVisibleOfStyleId[ style.id ]"
                        class="non-trade-item-styles-and-artworks--style-line-cultures"
                    >
                        <rsp-supplementary-caption>Cultures</rsp-supplementary-caption>

                        <rsp-not-available *ngIf="style.cultures.length === 0"></rsp-not-available>

                        <rsp-language-keyword
                            *ngFor="let styleCulture of style.cultures"
                            [language]="styleCulture.culture"
                            class="non-trade-item-styles-and-artworks--style-line-cultures-culture"
                        ></rsp-language-keyword>
                    </div>

                    <div
                        *ngIf="areDetailsVisibleOfStyleId[ style.id ] && userHasEditRight"
                        class="non-trade-item-styles-and-artworks--style-line-edit"
                    >
                        <div
                            *ngIf="!isEditModeForStyleId[ style.id ]"
                            class="non-trade-item-styles-and-artworks--style-line-button-wrapper"
                        >
                            <rsp-button [submodule]="'seamless'" (click)="activateEditModeForStyle( style, $event )">
                                <span class="non-trade-item-styles-and-artworks--style-line-edit-button-caption">
                                    <span class="non-trade-item-styles-and-artworks--style-line-edit-button-caption-assistive">
                                        Edit
                                    </span>
                                </span>
                            </rsp-button>
                        </div>

                        <div
                            class="non-trade-item-styles-and-artworks--style-line-button-wrapper"
                            [class.is-hidden]="!isEditModeForStyleId[ style.id ]"
                        >
                            <rsp-button [submodule]="'secondary'" (click)="deactivateEditModeForStyle( style, $event )">
                                Cancel
                            </rsp-button>
                        </div>
                    </div>

                    <div
                        *ngIf="areDetailsVisibleOfStyleId[ style.id ] && userHasEditRight"
                        class="non-trade-item-styles-and-artworks--style-line-remove"
                    >
                        <div
                            *ngIf="!isEditModeForStyleId[ style.id ]"
                            class="non-trade-item-styles-and-artworks--style-line-button-wrapper"
                        >
                            <rsp-remove
                                [text]="'Do you really want to remove this style?'"
                                [title]="'remove style'"
                                (confirmClicked)="removeStyle( style )"
                            >
                            </rsp-remove>
                        </div>

                        <div
                            class="non-trade-item-styles-and-artworks--style-line-button-wrapper"
                            [class.is-hidden]="!isEditModeForStyleId[ style.id ]"
                        >
                            <rsp-button
                                [submodule]="'primary'"
                                [disabled]="!formGroupForStyleId[ style.id ].valid"
                                (click)="saveStyle( style, $event )"
                            >
                                Save
                            </rsp-button>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="l-two-columns-1200" *ngIf="areDetailsVisibleOfStyleId[ style.id ]">
                <div class="l-two-columns-1200--column">
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>
                                <label [for]="htmlIdForStyleId[ style.id ].styleNameField">Style Name</label>
                            </rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-text
                                    [value]="style.name"
                                    [myFormGroup]="formGroupForStyleId[ style.id ]"
                                    [isEditMode]="isEditModeForStyleId[ style.id ]"
                                    [name]="'name'"
                                    [inputFieldHtmlId]="htmlIdForStyleId[ style.id ].styleNameField"
                                ></rsp-display-edit-text>
                            </rsp-card-content>
                        </rsp-card>
                    </div>
                </div>
                <div class="l-two-columns-1200--column">
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>
                                <label [for]="htmlIdForStyleId[ style.id ].styleIsActiveField">Style Active?</label>
                            </rsp-card-headline>
                            <rsp-card-content>
                                <input
                                    rspSwitch
                                    [checked]="style.isActive"
                                    [formControl]="formGroupForStyleId[ style.id ].get( 'isActive' )"
                                    [id]="htmlIdForStyleId[ style.id ].styleIsActiveField"
                                    type="checkbox"
                                >
                            </rsp-card-content>
                        </rsp-card>
                    </div>
                </div>
            </div>

            <div class="l-two-columns-1200">
                <div class="l-two-columns-1200--column">
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>
                                <label [for]="htmlIdForStyleId[ style.id ].styleDescriptionField">Style Description</label>
                            </rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-text-multiline
                                    [value]="style.description"
                                    [myFormGroup]="formGroupForStyleId[ style.id ]"
                                    [isEditMode]="isEditModeForStyleId[ style.id ]"
                                    [name]="'description'"
                                    [inputFieldHtmlId]="htmlIdForStyleId[ style.id ].styleDescriptionField"
                                ></rsp-display-edit-text-multiline>
                            </rsp-card-content>
                        </rsp-card>
                    </div>
                </div>
                <div class="l-two-columns-1200--column">
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <rsp-card>
                            <rsp-card-headline>
                                <label [for]="htmlIdForStyleId[ style.id ].styleContactField">Style Contact</label>
                            </rsp-card-headline>
                            <rsp-card-content>
                                <ng-container *ngIf="!isEditModeForStyleId[ style.id ]">
                                    <rsp-user-name
                                        *ngIf="style.contact"
                                        [user]="style.contact"
                                    ></rsp-user-name>
                                    <rsp-not-available *ngIf="!style.contact"></rsp-not-available>
                                </ng-container>

                                <ng-container *ngIf="isEditModeForStyleId[ style.id ]">
                                    <rsp-user-suggester
                                        [displayValue]="formGroupForStyleId[ style.id ].get( 'contact' ).value ? userNameService.format( formGroupForStyleId[ style.id ].get( 'contact' ).value, true ) : undefined"
                                        [inputFieldHtmlId]="htmlIdForStyleId[ style.id ].styleContactField"
                                        (selected)="setContactInFormGroup( formGroupForStyleId[ style.id ], $event )"
                                    >
                                        <rsp-error-messages
                                            *ngIf="formGroupForStyleId[ style.id ].get( 'contact' ).errors"
                                            [formErrors]="formGroupForStyleId[ style.id ].get( 'contact' ).errors"
                                        ></rsp-error-messages>
                                    </rsp-user-suggester>
                                </ng-container>
                            </rsp-card-content>
                        </rsp-card>
                    </div>
                </div>
            </div>

            <rsp-card-languages
                [isEditMode]="isEditModeForStyleId[ style.id ]"
                [languages]="allCultures"
                [selectedLanguages]="culturesForStyle.get( style.id )"
                [statusForLanguage]="statusForLanguageForStyle.get( style.id )"
                [cultureIdsFormControl]="formGroupForStyleId[ style.id ].get( 'cultureIds' )"
            ></rsp-card-languages>

            <ng-container *ngFor="let artwork of style.artworks; let i = index; let isLastArtwork = last">
                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>
                                    <label [for]="htmlIdForStyleId[ style.id ][ artwork.id ].artworkPreviewField">Artwork Preview</label>
                                </rsp-card-headline>
                                <rsp-card-content>
                                    <div class="non-trade-item-styles-and-artworks--artwork-image-wrapper">
                                        <rsp-image
                                            [fileId]="imageIdForArtworkId[ artwork.id ] || artwork.image?.previewImageId"
                                            [height]="90"
                                            alt=""
                                        ></rsp-image>

                                        <rsp-upload-single-file
                                            *ngIf="isEditModeForStyleId[ style.id ] && artwork.image && artwork.image.id"
                                            [fileContainerId]="artwork.image.id"
                                            [validFileTypeGroup]="'images'"
                                            (fileUpdated)="updateImageForArtwork( artwork, $event )"
                                            class="non-trade-item-styles-and-artworks--artwork-image-upload"
                                        >
                                            click here or drop file
                                        </rsp-upload-single-file>

                                        <rsp-upload-single-file
                                            *ngIf="isEditModeForStyleId[ style.id ] && (!artwork.image || !artwork.image.id)"
                                            [fileKinds]="[ { id: 'da0e0c8b-5f18-1699-241b-5527ff53edba', name: 'Artwork' } ]"
                                            [validFileTypeGroup]="'images'"
                                            (fileUpdated)="setImageForArtwork( style, artwork, $event )"
                                            class="non-trade-item-styles-and-artworks--artwork-image-upload"
                                        >
                                            click here or drop file
                                        </rsp-upload-single-file>
                                    </div>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                    <div class="l-two-columns-1200--column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>
                                    <label [for]="htmlIdForStyleId[ style.id ][ artwork.id ].artworkCatchphraseField">Artwork Catchphrase</label>
                                </rsp-card-headline>
                                <rsp-card-content>
                                    <rsp-display-edit-text-multiline
                                        [value]="artwork.catchPhrase"
                                        [myFormGroup]="formGroupForStyleId[ style.id ]"
                                        [isEditMode]="isEditModeForStyleId[ style.id ]"
                                        [name]="'artworks.' + i + '.catchphrase'"
                                        [inputFieldHtmlId]="htmlIdForStyleId[ style.id ][ artwork.id ].artworkCatchphraseField"
                                    ></rsp-display-edit-text-multiline>
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>

                <div class="l-two-columns-1200">
                    <div class="l-two-columns-1200--column">
                        <div class="l-two-columns-1200--column-content-gutter-item">
                            <rsp-card>
                                <rsp-card-headline>
                                    <label [for]="htmlIdForStyleId[ style.id ][ artwork.id ].artworkHasTranslationsField">Has Translations?</label>
                                </rsp-card-headline>
                                <rsp-card-content>
                                    <input
                                        rspSwitch
                                        [formControl]="formGroupForStyleId[ style.id ].get( 'artworks.' + i + '.hasTranslations' )"
                                        [id]="htmlIdForStyleId[ style.id ][ artwork.id ].artworkHasTranslationsField"
                                        type="checkbox"
                                    >
                                </rsp-card-content>
                            </rsp-card>
                        </div>
                    </div>
                </div>

                <p
                    *ngIf="isEditModeForStyleId[ style.id ]"
                    class="non-trade-item-styles-and-artworks--show-translation-status-link-wrapper"
                >
                    <rsp-remove
                        [title]="'remove artwork'"
                        [text]="'Do you really want to remove this artwork?'"
                        [useButton]="true"
                        class="non-trade-item-styles-and-artworks--add-style-button"
                        (confirmClicked)="removeArtwork( artwork.id, style, i )"
                    ></rsp-remove>
                </p>
            </ng-container>

            <div
                *ngIf="isEditModeForStyleId[ style.id ]"
                class="non-trade-item-styles-and-artworks--add-first-artwork-wrapper"
            >
                <rsp-button
                    [submodule]="'primary'"
                    (clicked)="addEmptyArtworkToStyle( style )"
                >Add artwork</rsp-button>
            </div>
        </rsp-accordion-group>
    </rsp-accordion>
    </ng-container>

    <footer
        class="non-trade-item-styles-and-artworks--footer"
        *ngIf="styles.length > 0"
    >
        <rsp-button
            *ngIf="userHasEditRight"
            [submodule]="'primary'"
            (clicked)="addEmptyStyle()"
            class="non-trade-item-styles-and-artworks--add-style-button"
        >
            Add Style
        </rsp-button>
    </footer>
    </div>
</ng-template>

<ng-template #artworkTranslationStatusDialog let-translationStates="translationStates">
    <div class="translation-status-dialog">
        <div *ngFor="let state of translationStates" class="translation-status-dialog--state">
            <h2 class="translation-status-dialog--state-name">{{ state.name }}</h2>

            <p class="translation-status-dialog--translations">
                <ng-container *ngIf="state.translations.length === 0">
                    None.
                </ng-container>

                <ng-container *ngIf="state.translations.length > 0">
                    <rsp-language-keyword
                        *ngFor="let translation of state.translations"
                        [language]="translation"
                        [status]="state.id"
                        class="translation-status-dialog--translation"
                    ></rsp-language-keyword>
                </ng-container>
            </p>
        </div>
    </div>
</ng-template>

<ng-template #noContentMessage>
    <rsp-no-content>This article does not have styles, yet.</rsp-no-content>

    <footer *ngIf="userHasEditRight" class="non-trade-item-styles-and-artworks--footer">
        <rsp-button
            [submodule]="'primary'"
            (clicked)="addEmptyStyle()"
            class="non-trade-item-styles-and-artworks--add-style-button"
        >Add Style</rsp-button>
    </footer>
</ng-template>
