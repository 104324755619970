<div
    [class.no-select]="shiftPressed"
    [class.columns-auto]="columnCount === 'auto'"
    [class.columns-one]="columnCount === 1"
    [class.columns-two]="columnCount === 2"
    [class.columns-three]="columnCount === 3"
    [class.columns-four]="columnCount === 4"
    [class.columns-five]="columnCount === 5"
    (keydown)="onKeyDown($event)"
    (keyup)="onKeyUp($event)"
    rspScroll
    (scrolledToBottom)="onScrolledToBottom()"
    class="virtual-scroll"
>
    <div [style.height.px]="totalHeight" class="virtual-scroll--scroll-spacer"></div>
    <div
        [style.transform]="'translateY(' + topPadding + 'px)'"
        class="virtual-scroll--container"
    >
        <ng-content></ng-content>
    </div>
</div>
