import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseStoreService, StoreSearchConfiguration } from '../../../../shared/ui/lists/store/base-store.service';
import { FileManagementFilesService } from '../../../../shared/api';
import { FileContainerTile, ViewModelBrowsingListFileContainerTile } from '../../../../shared/model';

/**
 * Handles loading and caching for DocumentViewModel.
 */
@Injectable()
export class DocumentStoreService extends BaseStoreService<FileContainerTile> {

    constructor(
        private documentsApi: FileManagementFilesService,
    ) {
        super();
    }


    protected getId( item: FileContainerTile ): string {

        if ( !item ) {
            throw new Error( ' [DocumentStoreService] item must not be null!' );
        }

        return item.id;
    }

    protected loadItemsFromBackend( request: StoreSearchConfiguration ): Subscription {

        return this.documentsApi
                   .fileContainersSearch( request )
                   .subscribe(
                       ( result: ViewModelBrowsingListFileContainerTile ) => {
                           super.setBackendResult(
                               result.data,
                               result.paging,
                               result.facets,
                               result.selectLists,
                               null, // result.advancedSearchFilterTypeOperators,
                               null, // result.advancedSearchFilterProperties,
                               null, // result.advancedSearchFilterGroups,
                               null, // result.availableSortings,
                               null, // result.usedSortings,
                               result.quickSearchTerm,
                           );
                       },
                       () => {
                           super.unregisterLoadingObservable();
                       } );
    }
}
