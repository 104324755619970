import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { ValidationMessagesService } from '../../validation/validation-messages.service';
import { MessageType } from '../../../ui/messages/messages.component';
import { NOT_AVAILABLE } from 'src/app/shared/ui/not-available/not-available.component';
import { uniq } from 'lodash';

@Component( {
    selector:    'rsp-edit-text',
    templateUrl: './edit-text.component.html',
    styleUrls:   [
        './edit-text.component.scss',
    ],
} )
export class EditTextComponent implements OnInit, OnDestroy {

    @ViewChild( 'inputField', { static: true } ) inputField: ElementRef;

    @Input() inputFieldHtmlId: string;

    @Input()
    set myFormControl( control: UntypedFormControl ) {
        this.setMyFormControl( control );
    }

    get myFormControl(): UntypedFormControl {
        return this._myFormControl;
    }

    @Input() myFormGroup: UntypedFormGroup;

    @Input() textAlignedRight: boolean = false;
    @Input() adjustSize: boolean       = false;

    /**
     * Specify a size (in number of chars) for the input field. If not given, the size will be calculated (and updated) by the current value.
     */
    @Input() size: number;

    /**
     * Event that is triggered when the user pressed enter key while the focus was in the <input>-field.
     */
    @Output() enterPressed: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Event that is triggered when the user pressed the escape key while the focus was in the <input>-field.
     */
    @Output() escapePressed: EventEmitter<void> = new EventEmitter<null>();

    /**
     * Specified if control should be displayed on display or edit mode.
     */
    @Input() isEditMode: boolean;

    /**
     * Specifies if "n/a" should be displayed when provided value is not empty (null|undefined) in the display mode.
     * @type {boolean}
     */
    @Input() showNotAvailable: boolean = true;

    /**
     * Specifies string which is displayed when given value is empty and [showNotAvailable] is true. Default 'n / a'.
     * @type {string}
     */
    @Input() notAvailableString: string = NOT_AVAILABLE;


    /**
     * Suppress the display validation messages. Useful when the parent component wants to make usage of the `validationMessagesChanged`-event and display the
     * validation messages by itself.
     * Must be implemented by each child classes template.
     */
    @Input() hideValidationMessages: boolean = false;

    /**
     * Optional text for the HTML `placeholder` attribute.
     */
    @Input() placeholder: string;

    /**
     * Is emitted when a display-edit-component gets invalid and provides the validation messages to the parent component.
     */
    @Output() validationMessagesChanged: EventEmitter<string[]> = new EventEmitter();


    set validationMessages( validationMessages: Array<string> ) { this.setValidationMessages( validationMessages ); }
    get validationMessages(): Array<string> { return this._validationMessages; }

    fallbackSize: number;
    messageTypeError: MessageType = MessageType.Error;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();
    private _validationMessages: Array<string> = [];

    private _myFormControl: UntypedFormControl;
    private subscription: Subscription;

    constructor(
        private validationMessagesService: ValidationMessagesService,
    ) {
    }

    /**
     *
     * @param {string} value
     * @param {Array<ValidatorFn>} validators
     * @returns {FormControl}
     */
    static buildFormControl( value: string, validators?: Array<ValidatorFn> ): UntypedFormControl {
        return new UntypedFormControl( value, validators );
    }

    ngOnInit(): void {
        this.startEditMode();
        this.updateSize();
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    handleKeyupEvent( event: KeyboardEvent ): void {
        if ( !(event.metaKey || event.ctrlKey || event.altKey) && event.key === 'Enter' ) {
            this.enterPressed.emit( this.myFormControl.value );
        }

        if ( !(event.metaKey || event.ctrlKey || event.altKey) && event.key === 'Escape' ) {
            this.escapePressed.emit();
        }
    }


    startEditMode(): void {
        if ( this.isEditMode && !this.myFormControl && !this.myFormGroup ) {
            throw new Error( 'Attribute "myFormControl" or "myFormGroup" must be set!' );
        }

        if (
            this.validationMessagesService.hasControlRequiredConstraint( this.myFormControl )
            || this.validationMessagesService.hasControlRequiredConstraint( this.myFormGroup )
        ) {
            this.validationMessages = ( this.validationMessagesService.getValidationMessages( { 'required': true } ) );
        }
    }


    setValidationMessages( validationMessages: Array<string> ): void {
        this._validationMessages = validationMessages;
        this.validationMessagesChanged.emit( validationMessages );
    }

    updateValidationMessages( subFormGroupOrFormControl: UntypedFormGroup | UntypedFormControl ): void {
        // clear previous error message (if any)
        this.validationMessages = [];

        // check if we can exit early (because there will be no validation messages when nothing is dirty and everything is valid)
        if ( !this.isFormGroupOrFormControlDirty( subFormGroupOrFormControl ) ||
            this.isFormGroupOrFormControlValid( subFormGroupOrFormControl ) ) {
            return;
        }

        // collect validation errors and messages
        const validationMessages: Array<string> = [];


        if ( subFormGroupOrFormControl instanceof UntypedFormGroup ) {
            // add error messages of each formControl to validationMessages
            Object
                .keys( subFormGroupOrFormControl.controls )
                .filter(  ( controlName: string ) => subFormGroupOrFormControl.controls[ controlName ].errors )
                .map(     ( controlName: string ) => subFormGroupOrFormControl.controls[ controlName ].errors )
                .forEach( ( errors: ValidationErrors ) => validationMessages.push( ... this.validationMessagesService.getValidationMessages( errors ) ) )
            ;

            // add error messages for formGroup
            validationMessages.push( ... this.validationMessagesService.getValidationMessages( subFormGroupOrFormControl.errors ) );
        }
        else {
            const nonCustomErrors: { [ errorId: string ]: any } = {};
            Object
                .keys( subFormGroupOrFormControl.errors )
                .forEach( ( errorId: string ) => nonCustomErrors[ errorId ] = subFormGroupOrFormControl.errors[ errorId ] );

            validationMessages.push( ... this.validationMessagesService.getValidationMessages( nonCustomErrors ) );
        }

        this.validationMessages = uniq( validationMessages );

        if ( !this.validationMessages.length && this.validationMessagesService.hasControlRequiredConstraint( subFormGroupOrFormControl )) {
            this.validationMessages = ( this.validationMessagesService.getValidationMessages( { 'required': true } ) );
        }
    }

    private setMyFormControl( control: UntypedFormControl ): void {
        this._myFormControl = control;

        // when reinitializing the formcontrol, the subscription is manually unsubscribed
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }

        this.subscription =
            this.myFormControl
                .valueChanges
                // in case of "clear" (via text-input directive) the changes seem to be delayed, so small delay is needed
                .pipe(
                    debounceTime( 1 ),
                    takeUntil( this.isDestroyed ),
                )
                .subscribe( () => {
                    this.onValueChanged();
                } );
    }

    private updateSize(): void {
        if ( this.adjustSize && (this.size === null || this.size === undefined) ) {
            this.fallbackSize = (this.myFormControl.value + '').length || 1;
        }
    }


    private onValueChanged(): void {
        if ( !this.myFormControl ) {
            return;
        }

        this.updateSize();

        this.updateValidationMessages( this.myFormControl );
    }

    private isFormGroupOrFormControlDirty( subFormGroupOrFormControl: UntypedFormGroup | UntypedFormControl ): boolean {
        let isDirty: boolean;
        if ( subFormGroupOrFormControl instanceof UntypedFormGroup ) {
            isDirty = Object
                .keys( subFormGroupOrFormControl.controls )
                .some( ( formControlName: string ) => subFormGroupOrFormControl.controls[ formControlName ].dirty );
        }
        else {
            isDirty = subFormGroupOrFormControl.dirty;
        }

        return isDirty;
    }

    private isFormGroupOrFormControlValid( subFormGroupOrFormControl: UntypedFormGroup | UntypedFormControl ): boolean {
        let isValid: boolean;
        if ( subFormGroupOrFormControl instanceof UntypedFormGroup ) {
            isValid = Object
                .keys( subFormGroupOrFormControl.controls )
                .every( ( formControlName: string ) => subFormGroupOrFormControl.controls[ formControlName ].valid );

            if ( !subFormGroupOrFormControl.valid ) {
                isValid = false;
            }
        }
        else {
            isValid = subFormGroupOrFormControl.valid;
        }

        return isValid;
    }

}
