import { Component, Input, OnInit, } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, } from '@angular/forms';

import { CapacityTypeInfo } from '../../../../../../shared/model/capacityTypeInfo';
import { ValidationMessagesService } from '../../../../../../shared/forms/validation/validation-messages.service';
import { Capacity } from '../../../../../../shared/model/capacity';
import { CustomValidators } from '../../../../../../shared/forms/validation/custom-validators';
import { MessageType } from '../../../../../../shared/ui/messages/messages.component';

export interface CapacityFormItem {
    capacityTypeId: string;
    name: string;
    pieces: string;
}


@Component({
    selector: 'rsp-edit-capacities',
    templateUrl: './edit-capacities.component.html',
    styleUrls: [
        './display-edit-capacities.component.scss',
    ],
} )
export class EditCapacitiesComponent implements OnInit {
    @Input() capacities: Array<Capacity>;
    @Input() myFormGroup: UntypedFormGroup;

    messageTypeError: MessageType = MessageType.Error;

    validationMessagesR: Map<string, Array<string>> = new Map();

    constructor(
        private validationMessagesService: ValidationMessagesService,
    ) { }

    static buildFormGroup( capacityTypeOptions: CapacityTypeInfo[], capacities: Capacity[], needsCapacity: boolean ): UntypedFormGroup {
        if ( !capacityTypeOptions ) {
            throw Error( 'No capacity type options given!' );
        }
        const formGroup: UntypedFormGroup = new UntypedFormGroup( {
            needsCapacity: new UntypedFormControl( needsCapacity ),
        } );

        const array: UntypedFormArray = new UntypedFormArray( [] );
        capacityTypeOptions.forEach( ( info: CapacityTypeInfo ) => {
            const item: Capacity = capacities.find( ( capacity: Capacity ) => capacity.name === info.name );
            array.push(
                new UntypedFormGroup( { // CapacityFormItem structure
                    capacityTypeId: new UntypedFormControl( info.id ),
                    name:           new UntypedFormControl( info.name ),
                    pieces:          new UntypedFormControl( item ? item.pieces : null, [ CustomValidators.zeroOrPositiveNumber, CustomValidators.isInteger ] ),
                } ),
            );
        });
        formGroup.addControl( 'capacityItems', array );

        return formGroup;
    }

    ngOnInit(): void {
        const formArray: UntypedFormArray = this.myFormGroup.get('capacityItems') as UntypedFormArray;
        formArray.controls.forEach( ( control: AbstractControl ) => {
            const typeId: string = control.get('capacityTypeId').value;

            control.valueChanges.subscribe( () => {
                const formGroup: UntypedFormGroup  = control as UntypedFormGroup;
                const errors: Array<string> = [];

                Object.keys( formGroup.controls ).forEach( ( name: string ) => {
                    errors.push( ...this.validationMessagesService.getValidationMessages( formGroup.controls[ name ].errors ) );
                } );

                if ( errors.length ) {
                    this.validationMessagesR.set( typeId, errors );
                }
                else if ( this.validationMessagesR.get( typeId ) ) {
                    this.validationMessagesR.delete( typeId );
                }
            } );
        } );
    }
}
