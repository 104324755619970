<!--
  preserveAspectRatio of xMidYMid meet as the center of the viewport is the circle's
  center. The center of the circle will remain at the center of the rsp-progress-spinner
  element containing the SVG.
-->
<svg viewBox="0 0 100 100"
     preserveAspectRatio="xMidYMid meet">
    <path #path
          [style.strokeWidth]="strokeWidth"
    ></path>
</svg>
