import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { DisplayEditBaseComponent } from '../../../../../../shared/forms/controls/shared/display-edit-base.component';
import { MasterDataBrandsService } from '../../../../../../shared/api';
import { ValidationMessagesService } from '../../../../../../shared/forms/validation/validation-messages.service';
import { SubbrandCategoryInfo } from '../../../../../../shared/model/subbrandCategoryInfo';
import { ViewModelListSubbrandCategoryInfo } from '../../../../../../shared/model/viewModelListSubbrandCategoryInfo';
import { ConceptListItem } from '../../../../../../shared/model/conceptListItem';
import { OptionItem } from '../../../../../../shared/model/optionItem';


@Component( {
    selector:    'rsp-edit-cluster-categories',
    templateUrl: './edit-cluster-categories.component.html',
} )
export class EditClusterCategoriesComponent extends DisplayEditBaseComponent implements OnInit {
    @Input() concept: ConceptListItem;
    @Input() categories: OptionItem[];

    filteredCategoryOptions: OptionItem[];

    categoryOptions: SubbrandCategoryInfo[]         = [];
    originalCategoryOptions: SubbrandCategoryInfo[] = [];

    constructor(
        validationMessagesService: ValidationMessagesService,
        private brandsApi: MasterDataBrandsService,
    ) {
        super( validationMessagesService );
    }

    ngOnInit(): void {
        this.startEditMode();
        if ( this.concept.brand && this.concept.subBrand ) {
            this.brandsApi
                .brandsGetSubbrandCategoryList( this.concept.brand.id, this.concept.subBrand.id )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( result: ViewModelListSubbrandCategoryInfo ) => {
                    this.originalCategoryOptions = result.data;
                    this.updateCategoryOptions();
                } );
        }
    }

    setCategory( category: SubbrandCategoryInfo ): void {
        const newCategories: OptionItem[] = this.categories;
        newCategories.push( {
            name: category.name,
            id:   category.id,
        } );
        this.categories = newCategories;
        this.updateCategoryOptions();
    }

    removeCategory( category: OptionItem ): void {
        const index: number = this.categories.findIndex( ( old: OptionItem ) => { return old.id === category.id; } );

        if ( index !== -1 ) {
            this.categories.splice(index, 1);
        }

        this.updateCategoryOptions();
    }

    setFilteredCategoryOptions( items: OptionItem[] ): void {
        this.filteredCategoryOptions = items;
    }

    private updateCategoryOptions(): void {
        this.categoryOptions =
            this.originalCategoryOptions.filter( ( item: SubbrandCategoryInfo ) => {
                return this.categories.findIndex( ( current: OptionItem ) => { return item.id === current.id; } ) === -1;
            } );
        this.myFormControl.setValue( this.categories.map( ( item: OptionItem ) => item.id ) );
    }
}
