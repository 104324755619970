import { ChangeDetectionStrategy, Component, Input, OnChanges, } from '@angular/core';

import { UserInfo } from '../../model/userInfo';
import { UserNameService } from './user-name.service';

/**
 * Unified place to print user names always the same way.
 *
 * ```
 * <rsp-user-name [user]="someUserInfoObjectWithFirstName"></rsp-user-name>                         // → Behrends, Maike
 * <rsp-user-name [user]="someUserInfoObjectWithOutFirstName"></rsp-user-name>                      // → Abid
 * <rsp-user-name [user]="someUserInfoObjectWithOutFirstName" [withCompany]="true"></rsp-user-name> // → Behrends, Maike (NUREG GmbH)
 * ```
 */
@Component( {
    selector:        'rsp-user-name',
    template:        '{{formattedUserName}}',
    styles:          [ `
        :host {
            display: inline;
        }
    ` ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class UserNameComponent implements OnChanges {
    @Input() user: UserInfo;

    formattedUserName: string;

    constructor(
        private userNameService: UserNameService,
    ) {}

    ngOnChanges(): void {
        this.formattedUserName = this.userNameService.format( this.user );
    }
}
