import { Component, EventEmitter, } from '@angular/core';

import { StorageService, } from '../../../../../core/storage.service';
import { FacetValue, } from '../../../../model/facetValue';
import { FacetItem, } from '../../../../model/facetItem';
import { facetAnimations, } from '../facet.animations';
import {
    FacetTypeSelectComponent as FacetTypeSelectComponentInterface,
    FacetTypeSelect, SplitFacetValues,
} from './facet-type-select.model';
import { VerticalScrollService } from '../../../lists/vertical-scroll/vertical-scroll.service';
import { FacetValueChangedEventData } from '../facet.model';
import Fuse from 'fuse.js';


/**
 * This is the base component for all facets that fall under the select-category (e.g. Multiselect, Singleselect etc.).
 * Dont' use this component directly, it's only meant to be a base class!
 *
 * # Implementing a child class
 * A child class needs:
 *
 * * to implement `FacetTypeSelectComponentInterface`
 * * extend this base class
 * * set `this.type`
 * * set `this.cssModuleName`
 * * implement it's own `onValueChanged()` method which emits the `FacetValue`(s)
 *
 * It should use `wjBase.Ng2Utils.getTypeAnnotation` to take over values from the @Component decorator of this parent
 * class. This does currently not work in unit tests, see
 * http://wijmo.com/topic/wjbase-ng2utils-doesnt-work-in-unit-tests/.
 * A basic implementation of this is still visible in the comments of `FacetTypeMultiselectComponent`.
 * Maybe we don't need wjBase.Ng2Utils for that, because Angular 2.3 has a certain amount of support for inheriting
 * decorator stuff from a parent component: https://github.com/angular/angular/commit/f5c8e09
 */
@Component( {
    selector:    'rsp-facet-type-select',
    templateUrl: './facet-type-select.component.html',

    /* styles have to be defined by child class, because there's no way of merging styleUrls (because something in the
       chain will transform them into the `styles`-array) and therefore the child class would have to use `styles`
       instead of `styleURLs`, too.
       However, there's facet-type-select.component.scss which provides a mixing for all the base styles.
    */
    // styleUrls: [],

    /* We can't use @Input, because our child classes probably will use
       `wjBase.Ng2Utils.getTypeAnnotation( FacetTypeSelectComponent, Component ) ).inputs` to get the list of inputs,
       which isn't filled when we use only the @Input decorator.
     */
    inputs: [ 'facet' ], // tslint:disable-line:no-inputs-metadata-property

    /* We can't use @Output, because our child classes probably will use
       `wjBase.Ng2Utils.getTypeAnnotation( FacetTypeSelectComponent, Component ) ).outputs` to get the list of inputs,
       which isn't filled when we use only the @Output decorator.
     */
    outputs: [ 'valueChanged' ], // tslint:disable-line:no-outputs-metadata-property

    animations: facetAnimations,
} )
export class FacetTypeSelectComponent implements FacetTypeSelectComponentInterface {
    type: FacetTypeSelect = null; // has to be overwritten by child class
    cssModuleName: string = null; // has to be overwritten by child class

    valueChanged: EventEmitter<FacetValueChangedEventData> = new EventEmitter<FacetValueChangedEventData>();

    topFacetValues: Array<FacetValue | FacetItem>    = [];
    bottomFacetValues: Array<FacetValue | FacetItem> = [];
    areBottomFacetValuesVisible: boolean             = false;
    showFilter: boolean                              = false;
    facetType: any                                   = FacetItem.TypeEnum;

    set facet( facet: FacetItem ) {
        this.setFacet( facet );
    }

    get facet(): FacetItem {
        return this._facet;
    }

    set filterString( value: string ) {
        this.storageService.set<string>( this.storageKeyFor[ 'filterString' ], value );
        this._filterString = value;
        this.filterFacets( value );
    }

    get filterString(): string {
        return this._filterString;
    }

    /**
     * The threshold number which describes how many facet values we will show until we display a "show more" button.
     */
    readonly numberOfTopFacetValues: number = 10;

    protected storageKeyFor: { [ key: string ]: string } = {};

    protected _facet: FacetItem;

    protected fuzzySearch: Fuse<any>;
    protected fuzzySearchOptions: Fuse.IFuseOptions<any> = {
        keys:      [ 'value', 'facets.values.value' ],
        threshold: 0.4,
        distance:  100,
    };

    protected _topFacetValues: Array<FacetValue | FacetItem>    = [];
    protected _bottomFacetValues: Array<FacetValue | FacetItem> = [];
    protected _filterString: string;

    constructor(
        protected storageService: StorageService,
        protected verticalScrollService: VerticalScrollService,
    ) {
    }


    /**
     * Break the facet values into to groups: The ones that are shown at first (topFacetValues), the others that are
     * hidden behind the "show more"-button (bottomFacetValues).
     */
    splitFacetValues(
        facetValues: Array<FacetValue | FacetItem>,
        numberOfTopFacetValues: number = this.numberOfTopFacetValues,
    ): SplitFacetValues {
        if ( numberOfTopFacetValues <= 0 ) {
            numberOfTopFacetValues = facetValues.length;
        }

        return {
            topFacetValues:    facetValues.slice( 0, numberOfTopFacetValues ),
            bottomFacetValues: facetValues.slice( numberOfTopFacetValues ),
        };
    }

    /**
     * Shows all facet values, including the one hidden behind the "show more"-button.
     */
    showBottomFacetValues( state: boolean = true ): void {
        this.areBottomFacetValuesVisible = state;
        this.storageService.set<boolean>( this.storageKeyFor[ 'areBottomFacetValuesVisible' ], state );

        // give the browser a moment, to expand the list
        setTimeout( () => {
            this.verticalScrollService.refreshScrollCalculation();
        } );
    }

    onValueClick( facetValue: FacetValue, event: MouseEvent ): void {
        throw new Error( `${this.constructor.name}: onValueClick() has to be implemented by child class!` );
    }

    /**
     * Emits the new facetValues to the parent component.
     * Has to be overwritten in child class.
     */
    onValueChanged( value: FacetValue ): void {
        throw new Error( `${this.constructor.name}: onValueChanged() has to be implemented by child class!` );
    }

    filterFacets( value: string ): void {
        if ( value ) {
            const splitFacetValues: SplitFacetValues =
                      this.splitFacetValues(
                          this.fuzzySearch.search( value )
                              .map( ( searchResult: Fuse.FuseResult<any> ) => searchResult.item ) as FacetValue[] | FacetItem[],
                          this.numberOfTopFacetValues );
            this.topFacetValues                      = splitFacetValues.topFacetValues;
            this.bottomFacetValues                   = splitFacetValues.bottomFacetValues;
        } else {
            this.topFacetValues    = this._topFacetValues;
            this.bottomFacetValues = this._bottomFacetValues;
        }
    }

    /**
     * Triggers vertical scroll recalculation ( scroll up / down buttons )
     */
    triggerVerticalScrollCalculation(): void {
        this.verticalScrollService.refreshScrollCalculation();
    }

    /**
     * Use this to set the facet. It's here so a child class can use it (which doesn't work with setters).
     */
    protected setFacet( facet: FacetItem ): void {
        this._facet = facet;
        this.initStorageKeys();
        this.initAreBottomFacetValuesVisible();
        this.initSplitFacetValues();
        this.initFuzzySearch();
        this.filterString = this.storageService.get<string>( this.storageKeyFor[ 'filterString' ] );
    }

    protected getStorageKeyPrefix(): string {
        return 'listPage.facet.(' + this._facet.name + ').';
    }

    protected initStorageKeys(): void {
        const keyPrefix: string                             = this.getStorageKeyPrefix();
        this.storageKeyFor[ 'areBottomFacetValuesVisible' ] = keyPrefix + 'areBottomFacetValuesVisible';
        this.storageKeyFor[ 'filterString' ]                = keyPrefix + 'filterString';
    }

    protected initAreBottomFacetValuesVisible(): void {
        let areBottomFacetValuesVisible: boolean =
                this.storageService.get<boolean>( this.storageKeyFor[ 'areBottomFacetValuesVisible' ] );
        if ( areBottomFacetValuesVisible === null ) {
            areBottomFacetValuesVisible = false;
        }
        this.areBottomFacetValuesVisible = areBottomFacetValuesVisible;
    }

    protected initSplitFacetValues(): void {
        const splitFacetValues: SplitFacetValues =
                  this.splitFacetValues( this._facet.values, this.numberOfTopFacetValues );

        this.topFacetValues    = splitFacetValues.topFacetValues;
        this.bottomFacetValues = splitFacetValues.bottomFacetValues;
    }

    protected initFuzzySearch(): void {
        this._bottomFacetValues = this.bottomFacetValues;
        this._topFacetValues    = this.topFacetValues;
        this.showFilter         = this._bottomFacetValues.length > 0;
        this.fuzzySearch        = new Fuse( this._topFacetValues.concat( this._bottomFacetValues ), this.fuzzySearchOptions );
    }
}
