<div class="list-page">
    <header class="list-page--header">
        <div class="list-page--header-buttons">
            <rsp-button
                [type]="'button'"
                [submodule]="'seamless'"
                (click)="toggleSidebarVisibility()"
                class="list-page--header-show-filters-button"
            >
                {{ isSidebarVisible ? 'Hide' : 'Show' }} Filters
            </rsp-button>

            <rsp-button
                *ngIf="isSidebarVisible"
                [style.visibility]="( chosenFilters.facets && chosenFilters.facets.length > 0 || chosenFilters.advancedSearchFilterGroups && chosenFilters.advancedSearchFilterGroups.length > 0 ) ? 'visible' : 'hidden'"
                [type]="'button'"
                [submodule]="'seamless'"
                [@fadeInOut]
                (click)="resetFilters()"
                class="list-page--header-reset-filters-button"
            >
                Reset Filters
            </rsp-button>
        </div>

        <rsp-facet-type-chosen-values
            #chosenFilters
            [facets]="chosenValuesFacet"
            [advancedSearchFilterGroups]="advancedSearchFilterGroups"
            [usedQuickSearchTerm]="usedQuickSearchTerm"
            (valueChanged)="onFacetValuesChanged( $event )"
            (clearAdvancedSearch)="onAdvancedSearchValuesChanged( [] )"
            (clearQuickSearch)="onQuickSearchCleared()"
            class="list-page--header-chosen-facets"
        ></rsp-facet-type-chosen-values>
    </header>

    <div class="list-page--content-area">
        <rsp-filters
            class="list-page--sidebar"
            [facets]="facets"
            [selectLists]="selectLists"
            [advancedSearchFilterTypeOperators]="advancedSearchFilterTypeOperators"
            [advancedSearchFilterProperties]="advancedSearchFilterProperties"
            [advancedSearchFilterGroups]="advancedSearchFilterGroups"
            [class.is-sidebar-visible]="isSidebarVisible"
            [isHidden]="!isSidebarVisible"
            (facetValuesChanged)="onFacetValuesChanged( $event )"
            (advancedSearchValuesChanged)="onAdvancedSearchValuesChanged( $event )"
            (selectListsChanged)="onSelectListsChanged( $event )"
        ></rsp-filters>

        <section class="list-page--list">
            <ng-content></ng-content>
        </section>
    </div>
</div>

