/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { UserModuleGroupInfo } from './userModuleGroupInfo';


export interface UserDetails {
    id?: string;

    globalId?: string;

    firstName?: string;

    familyName?: string;

    email?: string;

    phoneNumber?: string;

    profilePreviewImageId?: string;

    profileFileContainerId?: string;

    lastLoginOn?: Date;

    activatedOn?: Date;

    registeredOn?: Date;

    type?: UserDetails.TypeEnum;

    status?: UserDetails.StatusEnum;

    moduleGroups?: Array<UserModuleGroupInfo>;

}

export namespace UserDetails {
    'use strict';
    export enum TypeEnum {
        Internal,
        External,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
    export enum StatusEnum {
        Active,
        Inactive,
        Registered,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

