<input
    #pikadayInput
    rspTextInput
    [leftIcon]="'calendar'"
    [withClearButton]="withClearButton"
    [rspTextInputWrapperClass]="'date-input'"
    [readonly]="true"
    [attr.disabled]="disabled || null"
    [placeholder]="placeholder || ''"
    (keydown.arrowUp)="preventScrolling( $event )"
    (keydown.arrowDown)="preventScrolling( $event )"
    (onClear)="clearDate()"
    type="text"
    class="date-input"
>

<rsp-error-messages [formErrors]="myFormGroup.get( name ).errors"></rsp-error-messages>
