import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import {
    BrandInfo, ConceptInfo, CreateDocument, DocumentDetails, FileContainerItem, FileContainerTile, FileKindInfo, KeywordInfo, KeywordListItem,
    UpdateDocument,
} from '../../../../../shared/model';


export class DocumentForm extends UntypedFormGroup {

    constructor() {

        super( {
            documentId:    new UntypedFormControl(),
            fileContainer: new UntypedFormGroup( {
                id:             new UntypedFormControl( null, Validators.required ),
                name:           new UntypedFormControl(),
                previewImageId: new UntypedFormControl(),
            } ),
            fileKind:      new UntypedFormGroup( {
                id:   new UntypedFormControl(),
                name: new UntypedFormControl(),
            } ),
            brand:         new UntypedFormGroup( {
                id:   new UntypedFormControl(),
                name: new UntypedFormControl(),
            } ),
            concept:       new UntypedFormGroup( {
                id:   new UntypedFormControl(),
                name: new UntypedFormControl(),
            } ),
            visibleFor:    new UntypedFormControl( FileContainerTile.VisibleForEnum.All ),
            keywords:      new UntypedFormGroup( {
                id:   new UntypedFormControl(),
                name: new UntypedFormControl(),
            } ),
        } );
    }


    // factory methods
    // ----------------------------------------------------------------------------------------------------------------

    static buildFormForCreate(): DocumentForm {

        const resultForm: DocumentForm = new DocumentForm();

        resultForm.setVisibleFor( FileContainerTile.VisibleForEnum.All );

        return resultForm;
    }

    static buildFormForEdit( item: DocumentDetails ): DocumentForm {

        const resultForm: DocumentForm = new DocumentForm();

        resultForm.setDocumentId( item ? item.id : null );
        resultForm.setFileKind( item && item.fileContainerItem ? item.fileContainerItem.fileKind : null );
        resultForm.setFileContainer( item ? item.fileContainerItem : null );
        resultForm.setBrand( item ? item.brand : null );
        resultForm.setConcept( item ? item.concept : null );
        resultForm.setVisibleFor( item ? item.visibleFor : FileContainerTile.VisibleForEnum.All );
        resultForm.setGroup( item ? item.keywords : null );

        return resultForm;
    }


    // converter methods
    // ----------------------------------------------------------------------------------------------------------------

    getAsCreateDocument(): CreateDocument {

        return {
            fileContainerId: this.getFileContainer() ? this.getFileContainer().id : null,
            brandId:         this.getBrand() ? this.getBrand().id : null,
            conceptId:       this.getConcept() ? this.getConcept().id : null,
            visibleFor:      this.getVisibleFor(),
            keywords:        this.getGroup() ? [this.getGroup().id] : null,
        };
    }

    getAsUpdateDocument(): UpdateDocument {

        return {
            documentId: this.getDocumentId(),
            brandId:    this.getBrand() ? this.getBrand().id : null,
            conceptId:  this.getConcept() ? this.getConcept().id : null,
            visibleFor: this.getVisibleFor(),
            keywords:   this.getGroup() ? [this.getGroup().id] : null,
        };
    }


    // getters and setters
    // ----------------------------------------------------------------------------------------------------------------

    setDocumentId( value: string ): void {
        this.get( 'documentId' ).setValue( value );
    }

    getDocumentId(): string {
        return this.get( 'documentId' ).value;
    }

    setFileContainer( value: FileContainerItem ): void {
        this.get( 'fileContainer.id' ).setValue( value ? value.id : null );
        this.get( 'fileContainer.name' ).setValue( value ? value.name : null );
        this.get( 'fileContainer.previewImageId' ).setValue( value ? value.previewImageId : null );
    }

    getFileContainer(): FileContainerItem {
        const result: FileContainerItem = this.get( 'fileContainer' ).value;

        if ( result && result.id ) {
            return result;
        }

        return null;
    }

    setFileKind( value: FileKindInfo ): void {
        this.get( 'fileKind.id' ).setValue( value ? value.id : null );
        this.get( 'fileKind.name' ).setValue( value ? value.name : null );

        this.setFileContainer( null );
    }

    getFileKind(): FileKindInfo {
        const result: FileKindInfo = this.get( 'fileKind' ).value;

        if ( result && result.id ) {
            return result;
        }

        return null;
    }

    setBrand( value: BrandInfo ): void {
        this.get( 'brand.id' ).setValue( value ? value.id : null );
        this.get( 'brand.name' ).setValue( value ? value.name : null );
    }

    getBrand(): BrandInfo {
        const result: BrandInfo = this.get( 'brand' ).value;

        if ( result && result.id ) {
            return result;
        }

        return null;
    }

    setConcept( value: ConceptInfo ): void {
        this.get( 'concept.id' ).setValue( value ? value.id : null );
        this.get( 'concept.name' ).setValue( value ? value.name : null );
    }

    getConcept(): ConceptInfo {
        const result: ConceptInfo = this.get( 'concept' ).value;

        if ( result && result.id ) {
            return result;
        }

        return null;
    }

    setGroup( value: KeywordListItem[]): void {
        this.get( 'keywords.id' ).setValue( value[0] ? value[0].id : null );
        this.get( 'keywords.name' ).setValue( value[0] ? value[0].name : null );
    }

    getGroup(): KeywordListItem {
        const result: KeywordListItem = this.get( 'keywords' ).value;

        if ( result && result.id ) {
            return result;
        }

        return null;
    }

    setVisibleFor( value: FileContainerTile.VisibleForEnum ): void {
        this.get( 'visibleFor' ).setValue( value );
    }

    getVisibleFor(): FileContainerTile.VisibleForEnum {
        return this.get( 'visibleFor' ).value;
    }
}
