<div class="rsp-timeline">
    <div *ngFor="let item of data">
        <h2 class="rsp-timeline--year">{{item.year}}</h2>
        <div class="rsp-timeline--year-content">
            <div *ngFor="let day of item.days" class="rsp-timeline--event-day-content">
                <h3 class="rsp-timeline--event-day">
                    <span class="rsp-timeline--event-day-weekday">{{day.weekday}}</span>
                    <span class="rsp-timeline--event-day-month">{{day.month}}</span>
                    <span class="rsp-timeline--event-day-day">{{day.day}}</span>
                </h3>
                <div *ngFor="let event of day.events">
                    <h4 class="rsp-timeline--event-time"><time [attr.datetime]="event.datetime">{{event.time}}</time></h4>
                    <div class="rsp-timeline--event-text">
                        <strong>{{event.area}}</strong> {{event.action}} <em>{{event.field}}</em> by {{event.user}}.
                        <a class="rsp-timeline--event-detail-toggle" (click)="event.detailsVisible = !event.detailsVisible">{{!event.detailsVisible ? 'Show' : 'Hide'}} details</a>
                    </div>
                    <div class="rsp-timeline--event-details" *ngIf="event.detailsVisible">
                        <table>
                            <thead>
                                <tr>
                                    <th>Old Value</th>
                                    <th>New Value</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let detail of event.details">
                                    <td>{{detail.oldValue}}</td>
                                    <td>{{detail.newValue}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
           </div>
        </div>
    </div>
</div>
