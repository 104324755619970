import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, } from '@angular/forms';
import { takeWhile, takeUntil } from 'rxjs/operators';
import { Subject, merge } from 'rxjs';

import { GraphicDimensions, } from '../../../model/graphicDimensions';
import { ValidationMessagesService } from '../../validation/validation-messages.service';
import { MessageType } from '../../../ui/messages/messages.component';


@Component( {
    selector:    'rsp-display-edit-graphic-dimensions',
    templateUrl: './display-edit-graphic-dimensions.component.html',
} )
export class DisplayEditGraphicDimensionsComponent implements OnInit, OnDestroy {
    @Input() dimensions: GraphicDimensions;

    @Input() isEditMode: boolean;

    @Input()
    set myFormGroup( formGroup: UntypedFormGroup ) {
        this.setFormGroup( formGroup );
    }

    get myFormGroup(): UntypedFormGroup {
        return this._myFormGroup;
    }

    @Input() namePrefix: string;

    validationMessages: String[]  = [];
    messageTypeError: MessageType = MessageType.Error;

    private _myFormGroup: UntypedFormGroup;
    private isDestroyed: Subject<boolean> = new Subject<boolean>();


    constructor( private validationMessageService: ValidationMessagesService ) {
    }

    ngOnInit(): void {
        // in case of "new graphic" this component initializes in edit mode, so value change detection needs to be initialized here
        if ( this.myFormGroup && this.namePrefix ) {
            this.initFormValueChangeDetection();
        }
    }
    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    private setFormGroup( formGroup: UntypedFormGroup ): void {
        this._myFormGroup = formGroup;
        if ( formGroup && this.namePrefix ) {
            // in case of "edit graphic" the value change detection is initialized, as soon, as a formgroup is defined
            this.initFormValueChangeDetection();
        }
    }

    private initFormValueChangeDetection(): void {
        const widthControl: UntypedFormControl  = this.myFormGroup.get( this.namePrefix + 'Width' ) as UntypedFormControl;
        const heightControl: UntypedFormControl = this.myFormGroup.get( this.namePrefix + 'Height' ) as UntypedFormControl;

        merge(
            widthControl.valueChanges,
            heightControl.valueChanges,
        )
            .pipe(
                takeWhile( () => this.isEditMode ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                this.validationMessages = [];
                this.validationMessages.push( ...this.validationMessageService.getValidationMessages( widthControl.errors ) );
                this.validationMessages.push( ...this.validationMessageService.getValidationMessages( heightControl.errors ) );
            } );
    }

}
