<div class="secondary-toolbar">

    <div class="secondary-toolbar--left-area">

        <!-- Actions -->
        <ng-container *ngIf="isSelectionAvailable | async">
            <rsp-secondary-toolbar-button
                [icon]="'selection'"
                [badgeValue]="( selectedItems | async )?.length"
                (clearButtonClick)="clearSelection()"
            >
            </rsp-secondary-toolbar-button>

            <div class="secondary-toolbar--separator"></div>
        </ng-container>

        <span [hidden]="!actions.hasAnyAction">
            <rsp-secondary-toolbar-slidein-button
                [label]="'Actions'"
                [slideInSize]="{ width: '350px' }"
            >
                <rsp-document-actions-list #actions></rsp-document-actions-list>
            </rsp-secondary-toolbar-slidein-button>
        </span>

    </div>

    <div class="secondary-toolbar--right-area">

        <!-- StoreInfo  -->
        <rsp-store-info
            *ngIf="isStoreInfoVisible | async"
            class="secondary-toolbar--right-area-item"
            [storeInfo]="storeInfo | async"
        ></rsp-store-info>

    </div>

</div>
