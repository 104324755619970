<!-- header -->
<ng-template #dialogHeaderTemplate>
    <h2 class="standard-dialog--headline">
        <ng-container *ngIf="mode === 'add'">Add Document</ng-container>
        <ng-container *ngIf="mode === 'edit'">Edit Document</ng-container>
    </h2>
</ng-template>


<!-- content -->
<ng-template #dialogContentTemplate>

    <div [rspLoadingIndicator]="isLoading"
         class="standard-dialog--content add-or-edit-document-dialog">

        <div class="add-or-edit-document-dialog--column">

            <!-- brand -->
            <rsp-card>
                <rsp-card-headline>Brand</rsp-card-headline>
                <rsp-card-content>

                    <rsp-dropdown
                        [defaultValue]="form.getBrand()?.name"
                        [placeholder]="'Select Brand'"
                        [withSuggester]="true"
                        [originalItems]="brands"
                        (filteredItems)="setFilteredBrands( $event )"
                        (selected)="setBrand( $event )"
                    >
                        <rsp-dropdown-item *ngFor="let brandOption of filteredBrands" [item]="brandOption">
                            {{ brandOption.name }}
                        </rsp-dropdown-item>
                    </rsp-dropdown>

                </rsp-card-content>
            </rsp-card>

            <!-- concept -->
            <rsp-card>
                <rsp-card-headline>Concept</rsp-card-headline>
                <rsp-card-content>

                    <rsp-concept-suggester
                        [displayValue]="form.getConcept()?.name"
                        (selected)="setConcept( $event )"
                    ></rsp-concept-suggester>

                </rsp-card-content>
            </rsp-card>

            <!-- visible for -->
            <rsp-card>
                <rsp-card-headline>Visible For</rsp-card-headline>
                <rsp-card-content>

                    <rsp-radio-button-group
                        [name]="'visibleFor'"
                        [values]="visibleForValues"
                        [selectedValue]="form.getVisibleFor()"
                        (selectedValueChange)="setVisibleFor( $event )"
                    ></rsp-radio-button-group>

                </rsp-card-content>
            </rsp-card>

        </div>

        <div class="add-or-edit-document-dialog--column">

            <!-- brand -->
            <rsp-card>
                <rsp-card-headline>Group</rsp-card-headline>
                <rsp-card-content>

                    <rsp-dropdown
                        [defaultValue]="form.getGroup()?.name"
                        [placeholder]="'Select Group'"
                        [withSuggester]="true"
                        [originalItems]="groups"
                        (filteredItems)="setFilteredGroups( $event )"
                        (selected)="setGroup( $event )"
                    >
                        <rsp-dropdown-item *ngFor="let groupOption of filteredGroups" [item]="groupOption">
                            {{ groupOption.name }}
                        </rsp-dropdown-item>
                    </rsp-dropdown>

                </rsp-card-content>
            </rsp-card>

            <rsp-card>
                <rsp-card-headline>File</rsp-card-headline>
                <rsp-card-content>
                    <!-- file kind -->
                    <div *ngIf="mode === 'edit'">
                        {{ form.getFileKind()?.name }}
                    </div>
                    <rsp-dropdown
                        *ngIf="mode === 'add'"
                        [defaultValue]="form.getFileKind()?.name"
                        [placeholder]="'Select Filekind'"
                        [withSuggester]="true"
                        [originalItems]="fileKinds"
                        (filteredItems)="setFilteredFileKinds( $event )"
                        (selected)="setFileKind( $event )"
                    >
                        <rsp-dropdown-item *ngFor="let fileKind of filteredFileKinds" [item]="fileKind">
                            {{fileKind.name}}
                        </rsp-dropdown-item>
                    </rsp-dropdown>

                    <!-- upload file -->
                    <rsp-upload-single-file
                        class="add-or-edit-document-dialog--upload-area"
                        [style.visibility]="!form.getFileKind() ? 'hidden' : null"
                        [fileContainerId]="getContainerId()"
                        [fileKinds]="getContainerId() ? null : [ form.getFileKind() ]"
                        (fileUpdated)="setFile( $event )"
                    >
                        click here or drop file
                    </rsp-upload-single-file>

                </rsp-card-content>
            </rsp-card>

            <!-- file name -->
            <rsp-card *ngIf="form.getFileContainer()">
                <rsp-card-headline>File Name</rsp-card-headline>
                <rsp-card-content>

                    <div class="add-or-edit-document-dialog--file-name"
                         [title]="form.getFileContainer().name"
                    >
                        {{ form.getFileContainer().name }}
                    </div>

                </rsp-card-content>
            </rsp-card>

            <!-- preview image -->
            <rsp-card *ngIf="form.getFileContainer()">
                <rsp-card-headline>Preview</rsp-card-headline>
                <rsp-card-content>

                    <div class="add-or-edit-document-dialog--image-container">
                        <rsp-image
                            [fileId]="form.getFileContainer()?.previewImageId"
                            [alt]="form.getFileContainer()?.name"
                            [height]="100"
                            [width]="140"
                            [alignImg]="true"
                        ></rsp-image>
                    </div>

                </rsp-card-content>
            </rsp-card>

        </div>
    </div>

</ng-template>


<!-- footer -->
<ng-template #dialogFooterTemplate>

    <div class="standard-dialog--footer">

        <rsp-form-buttons
            [isEditMode]="true"
            [myFormGroup]="form"
            (secondaryEditButtonClick)="closeDialog()"
            (primaryEditButtonClick)="submitForm()"
        ></rsp-form-buttons>

    </div>

</ng-template>
