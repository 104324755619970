<div class="l-rsp-system-info-without-toolbar {{cssModuleName}}" *ngIf="isVisible">
    <div class="{{cssModuleName}}--system-content, l-rsp-system-info-without-toolbar--system-content">
        <p class="{{cssModuleName}}--system-name">{{ systemName }}</p>
        <button
            class="{{cssModuleName}}--hide-button {{cssModuleName}}--info-list-link"
            title="Go away, I need to take screenshots."
            (click)="hideSystemInfo()"
        >
            Hide System Info
        </button>
    </div>

    <dl class="{{cssModuleName}}--info-list l-rsp-system-info-without-toolbar--info-list">
        <!-- TODO:
        <dt class="rsp-system-info--info-list-key"><abbr title="Database">DB:</abbr></dt>
        <dd class="rsp-system-info--info-list-value">rsp_development@localhost</dd>
        <dt class="rsp-system-info--info-list-key">Branch:</dt>
        <dd class="rsp-system-info--info-list-value"><a class="rsp-system-info--info-list-link"
                                                    href="https://youtrack.nureg.de/issues/RSP?q=%23RSP-7915+"
                                                    target="_blank">M/#7915_order_enhancements</a></dd>
        <dt class="rsp-system-info--info-list-key">Last commit:</dt>
        <dd class="rsp-system-info--info-list-value"><a class="rsp-system-info--info-list-link"
                                                    href="http://git.nureg.intra/cgi-bin/gitweb/gitweb.cgi?p=;a=commit;h=443ce7aa7effea75dde1740655f02e1a1bb41444"
                                                    target="_blank">443ce7aa7e (Fri Aug 5 11:44:35 2016)</a></dd>
        <dt class="rsp-system-info--info-list-key">Last server update:</dt>
        <dd class="rsp-system-info--info-list-value">Fri Aug 5 11:52:32 2016
        </dd>
        -->
    </dl>
</div>
