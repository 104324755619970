<ng-template #headline>
    <h2 class="standard-dialog--headline">{{ caption }}</h2>
</ng-template>

<ng-template #content>
    <div class="standard-dialog--content" [rspLoadingIndicator]="isLoading">
        <rsp-non-trade-item-selection-list></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #footer>
    <div *ngIf="currentList.length" class="standard-dialog--content">
        <rsp-supplementary-caption>Selected Clusters</rsp-supplementary-caption>
        <ul class="simple-list">
            <li *ngFor="let item of currentList" class="simple-list--item">
                <div class="simple-list--item-container-with-headline">
                    <div class="simple-list--item-headline">
                        {{item.concept.name}}
                    </div>

                    <span>
                        {{item.name}}
                    </span>
                </div>
                <div class="simple-list--item-status">
                    <rsp-status
                        [displayMode]="'badge'"
                        [status]="item.status"
                        [type]="statusTypeEnum"
                    ></rsp-status>
                </div>
                <rsp-remove
                    [title]="'Remove cluster'"
                    [text]="'Do you really want to remove this cluster from selection?'"
                    (confirmClicked)="removeFromList( item )"
                    class="simple-list--item-remove"
                >
                </rsp-remove>

            </li>
        </ul>
    </div>
    <div class="standard-dialog--button">
        <rsp-cluster-suggester
            (selected)="addToList($event)"
            [itemFilter]="filterList"
            style="flex: 1"
        >
            <rsp-error-messages [formErrors]="form.get('clusterIds').errors"></rsp-error-messages>
        </rsp-cluster-suggester>
    </div>
    <div class="standard-dialog--button">
        <rsp-edit-text
            class="reason"
            [myFormControl]="form.get( 'reason' )"
            [placeholder]="'Reason for change'"
        ></rsp-edit-text>
    </div>
    <div class="standard-dialog--button">

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            (clicked)="doAction()"
            [disabled]="!form.valid"
        >
            {{ getActionButtonLabel() }}
        </rsp-button>
    </div>
</ng-template>
