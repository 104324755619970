/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { BrandInfo } from './brandInfo';
import { ConceptInfo } from './conceptInfo';
import { FileKindInfo } from './fileKindInfo';
import { ReferencedObject } from './referencedObject';
import { UserInfo } from './userInfo';


export interface FileContainerTile {
    id?: string;

    name?: string;

    fileKind?: FileKindInfo;

    uploadedOn?: Date;

    uploadedBy?: UserInfo;

    previewImageId?: string;

    referencedObject?: ReferencedObject;

    concept?: ConceptInfo;

    brand?: BrandInfo;

    keywords?: Array<string>;

    visibleFor?: FileContainerTile.VisibleForEnum;

}

export namespace FileContainerTile {
    'use strict';
    export enum VisibleForEnum {
        All,
        Intern,
        Extern,
    }
    export namespace VisibleForEnum {
        export function toString( enumValue: VisibleForEnum ): string {
            return VisibleForEnum[ enumValue ];
        }
    }
}

