/**
 * Contains store status informations like, paging infos, count etc.
 *
 */
export class StoreInfo {

    /**
     * Count of already loaded (cached) items.
     */
    count: number;

    /**
     * Total items count on the server, according to the current search params (facets).
     */
    totalCount: number;

    /**
     * Specifies if there are more items that can be loaded from server.
     */
    hasNextPage: boolean;


    constructor( count: number, totalCount: number, hasNextPage: boolean ) {
        this.count       = count;
        this.totalCount  = totalCount;
        this.hasNextPage = hasNextPage;
    }
}
