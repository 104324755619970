import { NgModule } from '@angular/core';

/* tslint:disable:max-line-length */
import { SharedModule } from '../../shared/shared.module';
import { RegistrationRoutingModule } from './registration-routing.module';
import { RegistrationWizardComponent } from './registration-wizard/registration-wizard.component';
import { RegistrationWizardMyProfileComponent } from './registration-wizard/registration-wizard-my-profile/registration-wizard-my-profile.component';
import { RegistrationWizardDistributionChannelsComponent } from './registration-wizard/registration-wizard-distribution-channels/registration-wizard-distribution-channels.component';
import { RegistrationWizardSectionHeadlineComponent } from './registration-wizard/registration-wizard-section-headline/registration-wizard-section-headline.component';
import { RegistrationWizardSectionContentComponent } from './registration-wizard/registration-wizard-section-content/registration-wizard-section-content.component';
import { RegistrationWizardConceptsComponent } from './registration-wizard/registration-wizard-concepts/registration-wizard-concepts.component';
/* tslint:enable:max-line-length */

@NgModule( {
    imports:      [
        RegistrationRoutingModule,
        SharedModule,
    ],
    declarations: [
        RegistrationWizardComponent,
        RegistrationWizardMyProfileComponent,
        RegistrationWizardDistributionChannelsComponent,
        RegistrationWizardSectionHeadlineComponent,
        RegistrationWizardSectionContentComponent,
        RegistrationWizardConceptsComponent,
    ],
} )
export class RegistrationModule {
}
