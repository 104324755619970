import { Component, EventEmitter, Input, Output } from '@angular/core';

import { FacetItem } from '../../../model/facetItem';
import { FacetFilterItem } from '../../../model/facetFilterItem';
import { listPageAnimations } from './list-page.animations';
import { AdvancedSearchFilterGroup } from '../../../model/advancedSearchFilterGroup';
import { AdvancedSearchFilterTypeOperator } from '../../../model/advancedSearchFilterTypeOperator';
import { AdvancedSearchFilterProperty } from '../../../model/advancedSearchFilterProperty';
import { SelectListItem } from '../../../model';

/**
 * Renders a list-/browse-page with facet sidebar, show-/hide-sidebar button, reset-filter button, chosenValues facet and a slot for the actual list.
 *
 * ```html
 *      <rsp-list-page
 *          [isSidebarVisible]="isSidebarVisible"
 *          [facets]="facets | async"
 *          [chosenValuesFacet]="chosenValuesFacet | async"
 *          (facetValuesChanged)="onFacetValuesChanged( $event )"
 *          (advancedSearchValuesChanged)="onAdvancedSearchValuesChanged( $event )"
 *          (sidebarVisibilityChanged)="onSidebarVisibilityChanged( $event )"
 *      >
 *          <!-- Your list goes here. -->
 *      </rsp-list-page>
 * ```
 */
@Component( {
    selector:    'rsp-list-page',
    templateUrl: './list-page.component.html',
    styleUrls:   [
        './list-page.component.scss',
    ],
    animations: listPageAnimations,
} )
export class ListPageComponent {
    @Input() facets:            FacetItem[];
    @Input() chosenValuesFacet: FacetItem[];
    @Input() selectLists:       SelectListItem[];
    @Input() advancedSearchFilterTypeOperators: AdvancedSearchFilterTypeOperator[];
    @Input() advancedSearchFilterProperties: AdvancedSearchFilterProperty[];
    @Input() advancedSearchFilterGroups: AdvancedSearchFilterGroup[];
    @Input() usedQuickSearchTerm: string;
    @Input() isSidebarVisible:  boolean;

    @Output() facetValuesChanged:          EventEmitter<FacetFilterItem[]>           = new EventEmitter<FacetFilterItem[]>();
    @Output() advancedSearchValuesChanged: EventEmitter<AdvancedSearchFilterGroup[]> = new EventEmitter<AdvancedSearchFilterGroup[]>();
    @Output() selectListsChanged:          EventEmitter<SelectListItem[]>            = new EventEmitter();
    @Output() filtersReset:                EventEmitter<void>                        = new EventEmitter<void>();
    @Output() quickSearchReset:            EventEmitter<void>                        = new EventEmitter<void>();
    @Output() sidebarVisibilityChanged:    EventEmitter<boolean>                     = new EventEmitter<boolean>();

    toggleSidebarVisibility(): void {
        this.isSidebarVisible = !this.isSidebarVisible;

        this.sidebarVisibilityChanged.emit( this.isSidebarVisible );
    }

    onFacetValuesChanged( filterItems: FacetFilterItem[] ): void {
        this.facetValuesChanged.emit( filterItems );
    }

    onAdvancedSearchValuesChanged( advancedSearchFilterGroups: AdvancedSearchFilterGroup[] ): void {
        this.advancedSearchValuesChanged.emit( advancedSearchFilterGroups );
    }

    onSelectListsChanged( selectLists: SelectListItem[] ): void {
        this.selectListsChanged.emit( selectLists.filter( ( item: SelectListItem ) => !!item.selectedValue ) );
    }

    onQuickSearchCleared(): void {
        this.quickSearchReset.emit();
    }

    resetFilters(): void {
        this.filtersReset.emit();
    }
}
