import { Component, } from '@angular/core';
import { Router } from '@angular/router';

import { DetailPagePrevNextNavigationComponent }
    from '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component';
import { ConceptClusterPrevNextNavigationService } from './concept-cluster-prev-next-navigation.service';
import { ConceptOrClusterItem } from '../../../../../shared/model/conceptOrClusterItem';


/**
 * Allows navigation to prev/next item from the ConceptClusterStorage.
 */
@Component( {
    selector:    'rsp-concept-cluster-prev-next-navigation',
    templateUrl: '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component.html',
    styleUrls:   [
        '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component.scss',
    ],
} )
export class ConceptClusterPrevNextNavigationComponent extends DetailPagePrevNextNavigationComponent<ConceptOrClusterItem> {
    constructor(
        router: Router,
        conceptClusterPrevNextNavigationService: ConceptClusterPrevNextNavigationService,
    ) {
        super( router, conceptClusterPrevNextNavigationService );
    }
}
