import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';

import { CurrentUserService } from '../../../core/current-user.service';

import {
    CurrentUserContext,
    FileContainerWithReference,
    ViewModelListFileContainerWithReference,
} from '../../../shared/model';
import {
    UserManagementCurrentUserService,
} from '../../../shared/api';
import { DialogService } from '../../../core/overlay/dialog/dialog.service';

export interface Media {
    title: string;
    src: string;
    type: string;
}

type ForkJoinResult = [
    ViewModelListFileContainerWithReference,
    CurrentUserContext,
];

@Component( {
    selector:    'rsp-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls:   [
        './dashboard.component.scss',
        '../../../shared/scss/05_module/standard-dialog.scss',
    ],
} )
export class DashboardComponent implements OnInit, OnDestroy {
    /*tslint:disable:no-any*/
    @ViewChild( 'dialogHeadline', { read: TemplateRef, static: true } ) public headlineTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContent', { read: TemplateRef, static: true } ) public contentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooter', { read: TemplateRef, static: true } ) public footerTemplate: TemplateRef<any>;
    /*tslint:enable:no-any*/

    public form: UntypedFormGroup;

    public user: CurrentUserContext;
    public extracts: FileContainerWithReference[]    = [];
    public fileExports: FileContainerWithReference[] = [];

    public isLoading: Subscription;

    public userHasArticleExtractRight: boolean = false;

    public quickAccessItems: Array<{ description: string; url: string }> = [];

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    public constructor(
        private currentUserService: CurrentUserService,
        private userManagementUsersService: UserManagementCurrentUserService,
        private dialogService: DialogService,
    ) {
    }

    public ngOnInit(): void {
        this.currentUserService
            .getCurrentUserAccessRightSet()
            .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                this.userHasArticleExtractRight = accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesExtract );

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesBrowse ) ) {
                    this.quickAccessItems.push( {
                        description: 'Articles & Assemblies',
                        url:         '/articles-and-assemblies',
                    } );
                }
                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersBrowse ) ) {
                    this.quickAccessItems.push( {
                        url:         '/concepts-and-clusters',
                        description: 'Concepts & Clusters',
                    } );
                }

            } );

        const apiCalls: Array<Observable<ViewModelListFileContainerWithReference | CurrentUserContext >> = [
            this.userManagementUsersService.currentUserGetFiles(),
            this.currentUserService.getCurrentUser(),
        ];

        this.isLoading =
            forkJoin( apiCalls )
                .subscribe(
                    ( result:
                          ForkJoinResult,
                    ) => {
                        const exportsResult: FileContainerWithReference[]  = [];
                        const extractsResult: FileContainerWithReference[] = [];

                        const [ fileContainers, userContext  ]
                                  : ForkJoinResult
                                  = result;

                        fileContainers.data.forEach( ( file: FileContainerWithReference ) => {
                            if ( file.fileKind.name === 'Files Export' || file.fileKind.name === 'Article Assembly Files Export' ) {
                                exportsResult.push( file );
                            }
                            else {
                                extractsResult.push( file );
                            }
                        } );

                        this.extracts    = extractsResult;
                        this.fileExports = exportsResult;

                        this.user = userContext;

                    } );
    }

    public ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    public closeDialog(): void {
        this.dialogService.closeDialog();
    }

}
