<ng-template #dialogHeader>
    <h2 class="standard-dialog--headline">Remove selection from Assembly</h2>
</ng-template>

<ng-template #dialogContent>
    <div
        [rspLoadingIndicator]="isLoading"
        class="standard-dialog--content remove-selection-from-assembly-dialog--content"
    >
        <rsp-non-trade-item-selection-list [nonTradeItemType]="articleType"></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #dialogFooter>
    <div class="standard-dialog--footer">
        <rsp-non-trade-item-suggester
            [type]="assemblyType"
            [placeholder]="'Assembly to remove components from'"
            [displayValue]="form.get('targetAssembly').value ? form.get('targetAssembly').value.number + ' - ' + form.get('targetAssembly').value.name : null"
            (selected)="setAssemblyToRemoveComponentsFrom( $event )"
            class="remove-selection-from-assembly-dialog--assembly-suggester"
        >
            <rsp-error-messages [formErrors]="form.get('targetAssembly').errors"></rsp-error-messages>
            <rsp-messages
                [type]="messageType"
                [messages]="messages"
            ></rsp-messages>
        </rsp-non-trade-item-suggester>

        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
            class="remove-selection-from-assembly-dialog--footer-button"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            [disabled]="!( form.valid && messageType === messageTypeEnum.Info )"
            (clicked)="doAction()"
            class="remove-selection-from-assembly-dialog--footer-button"
        >
            Remove
        </rsp-button>
    </div>
</ng-template>
