import { Component, EventEmitter, Input, Output } from '@angular/core';

import { CultureInfo } from '../../../../../../shared/model/cultureInfo';

@Component( {
    selector:    'rsp-language-keyword',
    templateUrl: './language-keyword.component.html',
    styleUrls:   [ './language-keyword.component.scss' ],
} )
export class LanguageKeywordComponent {
    @Input() language: CultureInfo;
    @Input() hasClearButton: boolean = false;

    @Output() clearClick: EventEmitter<CultureInfo> = new EventEmitter();


    onClearClick( language: CultureInfo ): void {
        this.clearClick.emit( language );
    }
}
