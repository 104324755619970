<div class="tab">
    <div class="tab--toolbar">
        <rsp-non-trade-item-suggester
            *ngIf="isEditMode && userHasEditRight"
            (selected)="onNonTradeItemSuggestionSelected( $event )"
            class="tab--toolbar-item is-left"
        ></rsp-non-trade-item-suggester>

        <rsp-form-buttons
            *ngIf="userHasEditRight"
            [isEditMode]="isEditMode"
            [myFormGroup]="componentForm"
            [disablePrimaryEditButton]="saveSubscription"
            (actionButtonClick)="startEdit()"
            (primaryEditButtonClick)="saveComponents()"
            (secondaryEditButtonClick)="cancelEdit()"
            class="tab--toolbar-item is-right"
        ></rsp-form-buttons>
    </div>

<div class="tab--content" [rspLoadingIndicator]="isLoading">

        <rsp-no-content *ngIf="isLoading && isLoading.closed && items.length === 0">
            This assembly has no components.
        </rsp-no-content>



        <form #componentForm="ngForm" [rspLoadingIndicator]="saveSubscription" style="height: auto;">
            <table
                *ngIf="isLoading && isLoading.closed && items.length"
                #stickyContainer
                class="components-table"
            >
                <thead class="components-table--head">
                    <tr
                        rspFixTableRow
                        [rspFixTableRowContainer]="stickyContainer"
                    >
                        <th class="components-table--head-cell"></th>
                        <th class="components-table--head-cell">Article N°</th>
                        <th class="components-table--head-cell">Name</th>
                        <th class="components-table--head-cell is-centered ">Status</th>
                        <th class="components-table--head-cell is-right-aligned">Quantity</th>
                        <th *ngIf="isEditMode && userHasEditRight" class="components-table--head-cell is-right-aligned">New Quantity</th>
                        <th *ngIf="isEditMode && userHasEditRight" class="components-table--head-cell is-centered ">Remove</th>
                    </tr>
                </thead>
                <tbody class="components-table--body">
                    <tr *ngFor="let item of items" class="components-table--body-row">
                        <td class="is-centered components-table--body-cell">
                            <rsp-image
                                fileId="{{ item.previewImageId }}"
                                alt="{{ item.number }} {{ item.name }}"
                                height="65"
                            ></rsp-image>
                        </td>
                        <td class="components-table--body-cell">
                            <rsp-link
                                [type]="'article'"
                                [id]="item.number">
                            </rsp-link>
                        </td>
                        <td class="components-table--body-cell">{{item.name}}</td>
                        <td class="components-table--body-cell is-centered">
                            <rsp-status status="{{ item.status }}" [type]="statusTypeEnum" [displayMode]="'badge'"></rsp-status>
                        </td>
                        <td class="components-table--body-cell is-right-aligned">{{item.quantity}}</td>
                        <td *ngIf="isEditMode && userHasEditRight" class="components-table--body-cell is-right-aligned">
                            <input
                                rspTextInput
                                rspZeroOrPositiveNumberValidation
                                rspIntegerValidation
                                [withClearButton]="true"
                                [textAlignedRight]="true"
                                [(ngModel)]="item.newQuantity"
                                [name]="'newQuantity_' + item.articleId"
                                size="4"
                                type="text"
                            >
                            <div *ngIf="!componentForm.form.get('newQuantity_' + item.articleId)?.valid">
                                <rsp-error-messages
                                    [formErrors]="componentForm.form.get('newQuantity_' + item.articleId)?.errors"
                                ></rsp-error-messages>
                            </div>
                        </td>
                        <td *ngIf="isEditMode && userHasEditRight" class="components-table--body-cell is-centered">
                            <rsp-remove
                                [title]="'remove component'"
                                [text]="'Do you really want to remove this component?'"
                                (confirmClicked)="removeComponent( item )"
                            ></rsp-remove>
                        </td>
                    </tr>
                </tbody>
            </table>

        </form>

    </div>
</div>
