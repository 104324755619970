import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subscription, of, merge, from as fromPromise, Subject, forkJoin, Observable } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { ArticleManagementArticlesService } from '../../../../../shared/api';
import { NonTradeItemService, } from '../../shared/non-trade-item.service';
import { RadioButtonGroupItem } from '../../../../../shared/ui/radio-button-group/radio-button-group.model';
import { UniqueHtmlIdService } from '../../../../../core/unique-html-id.service';
import { StyleCulture } from '../../../../../shared/model/styleCulture';
import { ArtworkItem } from '../../../../../shared/model/artworkItem';
import { DialogConfig, DialogService } from '../../../../../core/overlay/dialog/dialog.service';
import { PrepressProduction } from '../../../../../shared/model/prepressProduction';
import { CultureInfo } from '../../../../../shared/model/cultureInfo';
import { EditTextComponent } from '../../../../../shared/forms/controls/edit-text/edit-text.component';
import { Reply } from '../../../../../shared/model/reply';
import { NotificationService } from '../../../../../core/overlay/notification/notification.service';
import { UpdateStyle } from '../../../../../shared/model/updateStyle';
import { UserInfo } from '../../../../../shared/model/userInfo';
import { MasterDataCulturesService } from '../../../../../shared/api';
import { ViewModelListCultureInfo } from '../../../../../shared/model/viewModelListCultureInfo';
import { UpdateArtwork } from '../../../../../shared/model/updateArtwork';
import { CreateArtwork } from '../../../../../shared/model/createArtwork';
import { CreateArticleStyle } from '../../../../../shared/model/createArticleStyle';
import { ReplyGuid } from '../../../../../shared/model/replyGuid';
import { FileManagementFilesService } from '../../../../../shared/api/fileManagementFiles.service';
import { FileContainerWithReference } from '../../../../../shared/model/fileContainerWithReference';
import { UploadedFileInfo } from '../../../../../shared/forms/upload/upload-single-file/upload-single-file.component';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { ArtworkCulture, CurrentUserContext, StyleItem } from '../../../../../shared/model';
import { ViewModelListStyleItem } from '../../../../../shared/model/viewModelListStyleItem';
import { ValidationMessagesService } from '../../../../../shared/forms/validation/validation-messages.service';
import { UserNameService } from '../../../../../shared/ui/user-name/user-name.service';
import { loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { StringConverterService } from '../../../../../shared/utils/string/string-converter.service';


interface TranslationState {
    id: number;
    name: string;
}

interface ShowTranslationStatusDialogTranslationState extends TranslationState {
    translations: CultureInfo[];
}

@Component( {
    selector:    'rsp-non-trade-item-styles-and-artworks',
    templateUrl: './non-trade-item-styles-and-artworks.component.html',
    styleUrls:   [
        '../../../../../shared/scss/04_layout/two-columns-1200.scss',
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './non-trade-item-styles-and-artworks.component.scss',
    ],
} )
export class NonTradeItemStylesAndArtworksComponent implements OnInit, OnDestroy {
    @ViewChild( 'artworkTranslationStatusDialog', { read: TemplateRef, static: true } ) artworkTranslationStatusDialog: TemplateRef<any>;

    isLoading: Subscription = new Subscription();

    styles: Array<StyleItem>                                                     = [];
    statusForLanguageForStyle: Map<string, Map<string, StyleCulture.StatusEnum>> = new Map();

    htmlIdFor: { [ key: string ]: string }                                                                           = {};
    htmlIdForStyleId: { [ styleId: string ]: { [ fieldName: string ]: string | { [ artworkId: string ]: string } } } = {};
    isEditModeForStyleId: { [ styleId: string ]: boolean }                                                           = {};
    formGroupForStyleId: { [ styleId: string ]: UntypedFormGroup }                                                          = {};
    styleValidationMessages: { [ styleId: string ]: Array<string> }                                                  = {};
    areDetailsVisibleOfStyleId: { [ styleId: string ]: boolean }                                                     = {};
    imageIdForArtworkId: { [ artworkId: string ]: string }                                                           = {};

    toBeDeletedArtworkIds: string[] = [];

    selectedStyleIds: Set<string> = new Set();

    allCultures: CultureInfo[];
    culturesForStyle: Map<string, StyleCulture[]> = new Map();

    userHasEditRight: boolean;

    masterStatusEnumToString: ( value: number ) => string = ArtworkItem.MasterPrepressProductionStatusEnum.toString;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private articlesApi: ArticleManagementArticlesService,
        private masterDataCulturesApi: MasterDataCulturesService,
        private nonTradeItemService: NonTradeItemService,
        private uniqueHtmlIdService: UniqueHtmlIdService,
        private formBuilder: UntypedFormBuilder,
        private articleManagementArticlesApi: ArticleManagementArticlesService,
        private notificationService: NotificationService,
        public userNameService: UserNameService,
        private validationMessagesService: ValidationMessagesService,
        private filesApi: FileManagementFilesService,
        private currentUserService: CurrentUserService,
    ) {
        this.htmlIdFor.selectAllCheckbox = this.uniqueHtmlIdService.getUniqueHtmlId( 'select-all-checkbox' );
    }

    ngOnInit(): void {
        this.currentUserService
            .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesArticleStylesAndArtworksEdit )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( hasRight: boolean ) => {
                this.userHasEditRight = hasRight;
            } );

        // Two entry points:
        //  - of( true ) - first component load
        //  - currentNonTradeItem$ - prev/next navigation
        merge( of( true ), this.nonTradeItemService.currentNonTradeItem$ )
            .pipe(
                distinctUntilChanged(),
                loadWithProgressIndicator( () => this.articlesApi.articlesGetStyles( this.nonTradeItemService.getCurrentId() ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( listStyle: ViewModelListStyleItem ) => {

                this.postProcessViewModelListStyleData( listStyle.data );
                this.styles = listStyle.data.sort( ( a: StyleItem, b: StyleItem ) => {
                    return a.number < b.number ? -1 : 1;
                } );

                this.statusForLanguageForStyle.clear();

                this.styles.forEach( ( style: StyleItem ) => {
                    if ( !this.statusForLanguageForStyle.has( style.id ) ) {
                        this.statusForLanguageForStyle.set( style.id, new Map() );
                    }

                    this.culturesForStyle.set( style.id, style.cultures );

                    const languageStatusMap: Map<string, StyleCulture.StatusEnum> = this.statusForLanguageForStyle.get( style.id );
                    style.cultures.forEach( ( styleCulture: StyleCulture ) => {
                        languageStatusMap.set( styleCulture.culture.code, styleCulture.status );
                    } );
                } );

            } );


        this.masterDataCulturesApi
            .culturesGetList()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( result: ViewModelListCultureInfo ) => {
                this.allCultures = result.data;
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    activateEditModeForStyle( style: StyleItem, event?: MouseEvent ): void {
        this.isEditModeForStyleId[ style.id ] = true;

        this.formGroupForStyleId[ style.id ].get( 'isActive' ).enable();
        style.artworks.forEach( ( artwork: ArtworkItem, index: number ) => {
            this.formGroupForStyleId[ style.id ].get( `artworks.${ index }.hasTranslations` ).enable();
        } );

        const contactFormControl: AbstractControl = this.formGroupForStyleId[ style.id ].get( 'contact' );

        this.styleValidationMessages[ style.id ] = this.validationMessagesService.getValidationMessages( contactFormControl.errors );

        contactFormControl.valueChanges.pipe( takeUntil( this.isDestroyed ) ).subscribe( () => {
            this.styleValidationMessages[ style.id ] = [];
            this.styleValidationMessages[ style.id ] = this.validationMessagesService.getValidationMessages( contactFormControl.errors );
        } );

        // if accordion isn't open, let event bubble on, so it will open. It it's already open stop event propagation, because the click will bubble to the
        // accordion component and close the accordion.
        if ( event && this.areDetailsVisibleOfStyleId[ style.id ] ) {
            event.stopPropagation();
        }

    }

    deactivateEditModeForStyle( style: StyleItem, event?: MouseEvent ): void {
        this.isEditModeForStyleId[ style.id ] = false;

        this.removeUnsavedNewArtworks( style );
        if ( style.id.match( /^new-/ ) ) {
            this.removeUnsavedNewStyles( style );
        }
        else {
            this.culturesForStyle.set(
                style.id,
                style.cultures.slice(), // create shallow copy of array, to trigger angular change detection on @Input() of <rsp-card-langauages>
            );

            this.formGroupForStyleId[ style.id ].get( 'isActive' ).disable();
            style.artworks.forEach( ( artwork: ArtworkItem, index: number ) => {
                this.formGroupForStyleId[ style.id ].get( `artworks.${ index }.hasTranslations` ).disable();
            } );
        }

        if ( event ) {
            event.stopPropagation(); // don't change accordion open-state
        }
    }

    saveStyle( style: StyleItem, event: MouseEvent ): void {
        const tasks: Promise<Reply | ReplyGuid>[] = style.id.match( /^new-/ ) ?
            [ this.createNewStyle( style ) ] as Promise<ReplyGuid>[] :
            [ this.updateStyle( style ), ... this.createUpdateOrDeleteArtworks( style ) ]
        ;

        this.isLoading =
            fromPromise( Promise.all( tasks ) )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( resolvedValues: Array<Reply | ReplyGuid | ReplyGuid[]> ) => {
                    // flatten first (there must be a way to use `reduce` for this, but today I'm too dumb to come up with a solution.
                    const responses: Array<Reply | ReplyGuid> = [];
                    resolvedValues.forEach( ( resolvedValue: Reply | ReplyGuid | ReplyGuid[] ) => {
                        if ( Array.isArray( resolvedValue ) ) {
                            responses.concat( resolvedValue );
                        }
                        else {
                            responses.push( resolvedValue );
                        }
                    } );

                    const notOkResponses: Array<Reply | ReplyGuid> = responses.filter( ( response: Reply | ReplyGuid ) => !response.isOk );

                    if ( notOkResponses.length === 0 ) {
                        this.notificationService.success( 'Saved successfully.' );

                        this.isEditModeForStyleId[ style.id ] = false;
                        setTimeout(
                            () => {
                                this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
                            },
                            1500 );
                    }
                    else {
                        this.notificationService.error( notOkResponses.map( ( response: Reply ) => response.message ).join( '\n\n' ) );
                    }
                } )
        ;

        event.stopPropagation(); // stop, to not toggle accordion by clicking save button
    }

    removeStyle( styleToRemove: StyleItem ): void {

        const articleId: string = this.nonTradeItemService.getCurrentId();

        this.isLoading = this.articleManagementArticlesApi
                             .articlesDeleteStyle( styleToRemove.id, articleId, )
                             .pipe( takeUntil( this.isDestroyed ) )
                             .subscribe( ( result: Reply ) => {
                                 if ( result.isOk ) {
                                     this.notificationService.success( 'Style removed successfully.' );

                                     // update internal data
                                     const indexOfStyleToRemove: number = this.styles.findIndex( ( style: StyleItem ) => styleToRemove.id === style.id );
                                     this.styles.splice( indexOfStyleToRemove, 1 );
                                     this.culturesForStyle.delete( styleToRemove.id );
                                 }
                                 else {
                                     this.notificationService.error( result.message );
                                 }
                             } )
        ;
    }

    setContactInFormGroup( formGroup: UntypedFormGroup, contact: UserInfo ): void {
        formGroup.get( 'contact' ).setValue( contact );
    }

    addEmptyStyle(): void {
        const id: string = 'new-' + this.styles.length;

        const incompletePseudoStyle: StyleItem = {
            id:          id,
            name:        null,
            description: null,
            isActive:    false,
            contact:     null,
            artworks:    [],
            cultures:    [],
        };
        this.styles.push( incompletePseudoStyle );
        this.culturesForStyle.set( id, [] );

        this.postProcessViewModelListStyleData( [ incompletePseudoStyle ] );
        this.areDetailsVisibleOfStyleId[ id ] = true;
        this.activateEditModeForStyle( incompletePseudoStyle );
    }

    addEmptyArtworkToStyle( style: StyleItem ): void {
        let counter: number = style.artworks.filter( ( artwork: ArtworkItem ) => artwork.id.match( /^new-/ ) ).length;
        counter++;

        const incompletePseudoArtwork: ArtworkItem = {
            id:          'new-' + counter,
            catchPhrase: '',
            image:       {},
        };

        // add to FormGroup
        (this.formGroupForStyleId[ style.id ].get( 'artworks' ) as UntypedFormArray).push(
            this.formBuilder.group( {
                id:              new UntypedFormControl( incompletePseudoArtwork.id, [ Validators.required ] ),
                catchphrase:     EditTextComponent.buildFormControl( incompletePseudoArtwork.catchPhrase, [ Validators.required ] ),
                fileContainerId: new UntypedFormControl( null ),
                hasTranslations: new UntypedFormControl(
                    style.cultures.length > 0, // TODO: Backend will deliver value soon, see RSP-18511.
                    [ Validators.required ],
                ),
            } ),
        );

        // add to style.artworks
        style.artworks.push( incompletePseudoArtwork );

        // add HTMLIds
        this.htmlIdForStyleId[ style.id ][ incompletePseudoArtwork.id ] = {
            artworkPreviewField:     this.uniqueHtmlIdService.getUniqueHtmlId( 'artwork-preview', ),
            artworkCatchphraseField: this.uniqueHtmlIdService.getUniqueHtmlId( 'artwork-catchphrase', ),
        };
    }

    updateImageForArtwork( artwork: ArtworkItem, previewImage: UploadedFileInfo ): void {
        this.imageIdForArtworkId[ artwork.id ] = previewImage.previewImageId;
    }

    setImageForArtwork( style: StyleItem, artwork: ArtworkItem, fileContainer: UploadedFileInfo ): void {
        this.filesApi
            .fileContainersGetFileContainer( fileContainer.fileContainerId )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( fileInfo: FileContainerWithReference ) => {
                const artworkFormArray: UntypedFormArray = this.formGroupForStyleId[ style.id ].get( 'artworks' ) as UntypedFormArray;
                for ( let index: number = 0; index < artworkFormArray.length; index++ ) {
                    const control: AbstractControl = artworkFormArray.at( index );
                    if ( control.get( 'id' ).value === artwork.id ) {
                        control.get( 'fileContainerId' ).setValue( fileInfo.id );
                        break;
                    }
                }

                this.imageIdForArtworkId[ artwork.id ] = fileInfo.previewImageId;
            } );
    }

    removeArtwork( artworkId: string, style: StyleItem, index: number ): void {
        const formArray: UntypedFormArray = this.formGroupForStyleId[ style.id ].get( 'artworks' ) as UntypedFormArray;
        formArray.removeAt( index );

        delete this.htmlIdForStyleId[ style.id ][ artworkId ];

        const found: number = style.artworks.findIndex( ( item: ArtworkItem ) => item.id === artworkId );
        if ( found !== -1 ) {
            style.artworks.splice( found, 1 );
        }

        if ( !artworkId.match( /^new-/ ) ) {
            this.toBeDeletedArtworkIds.push( artworkId );
        }
    }


    private postProcessViewModelListStyleData( styles: Array<StyleItem> ): void {
        styles
            .sort( ( aStyle: StyleItem, bStyle: StyleItem ) => {
                if ( aStyle.name.toLowerCase() < bStyle.name.toLowerCase() ) {
                    return -1;
                }

                if ( aStyle.name.toLowerCase() > bStyle.name.toLowerCase() ) {
                    return 1;
                }

                return 0;
            } )
            .forEach( ( style: StyleItem ) => {
                // sort cultures alphabetically
                style.cultures.sort( ( aCulture: StyleCulture, bCulture: StyleCulture ) => {
                    if ( aCulture.culture.code < bCulture.culture.code ) {
                        return -1;
                    }

                    if ( aCulture.culture.code > bCulture.culture.code ) {
                        return 1;
                    }

                    return 0;
                } );

                // fill isEditModeForStyleId
                if ( !this.isEditModeForStyleId.hasOwnProperty( style.id ) ) {
                    this.isEditModeForStyleId[ style.id ] = false;
                }

                // fill areDetailsVisibleOfStyleId
                if ( !this.areDetailsVisibleOfStyleId.hasOwnProperty( style.id ) ) {
                    this.areDetailsVisibleOfStyleId[ style.id ] = false;
                }

                const isActiveFormControl: UntypedFormControl = new UntypedFormControl( style.isActive );
                isActiveFormControl.disable();

                // create formGroup for this style
                this.formGroupForStyleId[ style.id ] = this.formBuilder.group( {
                    name:        EditTextComponent.buildFormControl( style.name, [ Validators.required ] ),
                    description: EditTextComponent.buildFormControl( style.description ),
                    isActive:    isActiveFormControl,
                    contact:     new UntypedFormControl( style.contact, [ Validators.required ] ),
                    cultureIds:  new UntypedFormControl( style.cultures.map( ( styleCulture: StyleCulture ) => styleCulture.culture.id ) ),
                    artworks:    this.formBuilder.array(
                        style.artworks.map( ( artwork: ArtworkItem ) => {
                            const hasTranslationsFormControl: UntypedFormControl = new UntypedFormControl(
                                artwork.hasTranslations,
                                [ Validators.required ],
                            );
                            hasTranslationsFormControl.disable();

                            return this.formBuilder.group( {
                                id:              new UntypedFormControl( artwork.id, [ Validators.required ] ),
                                fileContainerId: new UntypedFormControl( artwork.image ? artwork.image.id : null ),
                                catchphrase:     EditTextComponent.buildFormControl( artwork.catchPhrase, [ Validators.required ] ),
                                hasTranslations: hasTranslationsFormControl,
                            } );
                        } ),
                    ),
                } );

                // create HTML-IDs for this style
                this.htmlIdForStyleId[ style.id ] = {
                    styleNameField:        this.uniqueHtmlIdService.getUniqueHtmlId( 'style-name', ),
                    styleDescriptionField: this.uniqueHtmlIdService.getUniqueHtmlId( 'style-description', ),
                    styleContactField:     this.uniqueHtmlIdService.getUniqueHtmlId( 'style-contact', ),
                    styleIsActiveField:    this.uniqueHtmlIdService.getUniqueHtmlId( 'style-is-active', ),
                };

                style.artworks.forEach( ( artwork: ArtworkItem ) => {
                    if ( !this.htmlIdForStyleId[ style.id ].hasOwnProperty( artwork.id ) ) {
                        this.htmlIdForStyleId[ style.id ][ artwork.id ] = {
                            artworkPreviewField:         this.uniqueHtmlIdService.getUniqueHtmlId( 'artwork-preview', ),
                            artworkCatchphraseField:     this.uniqueHtmlIdService.getUniqueHtmlId( 'artwork-catchphrase', ),
                            artworkHasTranslationsField: this.uniqueHtmlIdService.getUniqueHtmlId( 'artwork-has-translations', ),
                        };
                    }
                } );
            } )
        ;
    }

    private createNewStyle( style: StyleItem ): Promise<ReplyGuid[]> {

        const articleId: string = this.nonTradeItemService.getCurrentId();

        const createStyleCommand: CreateArticleStyle = {
            articleId:   articleId,
            name:        this.formGroupForStyleId[ style.id ].get( 'name' ).value,
            description: this.formGroupForStyleId[ style.id ].get( 'description' ).value,
            contactId:   this.formGroupForStyleId[ style.id ].get( 'contact' ).value.id,
            isActive:    this.formGroupForStyleId[ style.id ].get( 'isActive' ).value,
            cultureIds:  this.formGroupForStyleId[ style.id ].get( 'cultureIds' ).value,
        };

        return this.articleManagementArticlesApi
                   .articlesCreateStyle( articleId, createStyleCommand )
                   .toPromise()
                   .then( ( result: ReplyGuid ): any => {
                       const artworksFormGroup: UntypedFormArray = this.formGroupForStyleId[ style.id ].get( 'artworks' ) as UntypedFormArray;
                       if ( result.isOk ) {
                           if ( artworksFormGroup.length > 0 ) {
                               const superPromise: Promise<ReplyGuid[]> = Promise.all(
                                   [
                                       Promise.resolve( result ),
                                       ... this.createUpdateOrDeleteArtworks( style, result.value ),
                                   ],
                               );
                               return superPromise;
                           }
                           else {
                               return Promise.resolve( result );
                           }
                       }
                       else {
                           return Promise.reject( result );
                       }
                   } )
            ;
    }

    private updateStyle( style: StyleItem ): Promise<Reply> {

        const articleId: string = this.nonTradeItemService.getCurrentId();

        const updateStyleCommand: UpdateStyle = {
            styleId:     style.id,
            name:        this.formGroupForStyleId[ style.id ].get( 'name' ).value,
            description: this.formGroupForStyleId[ style.id ].get( 'description' ).value,
            isActive:    this.formGroupForStyleId[ style.id ].get( 'isActive' ).value,
            contactId:   this.formGroupForStyleId[ style.id ].get( 'contact' ).value.id,
            cultureIds:  this.formGroupForStyleId[ style.id ].get( 'cultureIds' ).value,
        };

        return this.articleManagementArticlesApi.articlesUpdateStyle( updateStyleCommand, articleId, style.id ).toPromise();
    }

    private createUpdateOrDeleteArtworks( style: StyleItem, newStyleId?: string ): Promise<Reply | ReplyGuid>[] {

        const saveArtworkObservables: Promise<Reply | ReplyGuid>[] = [];

        const articleId: string = this.nonTradeItemService.getCurrentId();

        const artworksFormGroup: UntypedFormArray = this.formGroupForStyleId[ style.id ].get( 'artworks' ) as UntypedFormArray;
        for ( let index: number = 0; index < artworksFormGroup.length; index++ ) {
            const artworkFormGroup: AbstractControl = artworksFormGroup.at( index );

            const artworkId: string        = artworkFormGroup.get( 'id' ).value;
            const catchphrase: string      = artworkFormGroup.get( 'catchphrase' ).value;
            const fileContainerId: string  = artworkFormGroup.get( 'fileContainerId' ).value;
            const hasTranslations: boolean = artworkFormGroup.get( 'hasTranslations' ).value;
            if ( artworkId.match( /^new-/ ) ) {
                const createArtworkCommand: CreateArtwork = {
                    styleId:         newStyleId || style.id,
                    catchphrase:     catchphrase,
                    fileContainerId: fileContainerId,
                    hasTranslations: hasTranslations,
                };

                saveArtworkObservables.push(
                    this.articleManagementArticlesApi.articlesCreateArtwork( articleId, newStyleId || style.id, createArtworkCommand ).toPromise(),
                );
            }
            else {
                const updateArtworkCommand: UpdateArtwork = {
                    artworkId:       artworkId,
                    catchphrase:     catchphrase,
                    fileContainerId: fileContainerId,
                    hasTranslations: hasTranslations,
                };

                saveArtworkObservables.push(
                    this.articleManagementArticlesApi
                        .articlesUpdateArtwork(
                            articleId,
                            style.id,
                            updateArtworkCommand.artworkId,
                            updateArtworkCommand,
                        )
                        .toPromise(),
                );
            }
        }

        this.toBeDeletedArtworkIds.forEach( ( id: string ) => {
            saveArtworkObservables.push(
                this.articleManagementArticlesApi.articlesDeleteArtwork( articleId, newStyleId || style.id, id ).toPromise(),
            );
        } );

        return saveArtworkObservables;
    }

    private removeUnsavedNewStyles( style: StyleItem ): void {
        this.styles.splice( this.styles.indexOf( style ), 1 );

        this.culturesForStyle.delete( style.id );

        delete this.htmlIdForStyleId[ style.id ];
        delete this.isEditModeForStyleId[ style.id ];
        delete this.formGroupForStyleId[ style.id ];
        delete this.areDetailsVisibleOfStyleId[ style.id ];
    }

    private removeUnsavedNewArtworks( style: StyleItem ): void {
        const artworkIndexesToRemove: number[] = [];
        style.artworks.forEach( ( artwork: ArtworkItem, index: number ) => {
            if ( artwork.id.match( /^new-/ ) ) {
                artworkIndexesToRemove.push( index );
            }

            delete this.imageIdForArtworkId[ artwork.id ];
        } );

        artworkIndexesToRemove.reverse().forEach( ( artworkIndex: number ) => {
            // remove fields from FormGroup
            (this.formGroupForStyleId[ style.id ].get( 'artworks' ) as UntypedFormArray).removeAt( artworkIndex );

            // remove from style.artworks
            style.artworks.splice( artworkIndex, 1 );
        } );

        // remove from HTMLIds
        Object.keys( this.htmlIdForStyleId[ style.id ] )
              .filter( ( key: string ) => key.match( /^new-/ ) )
              .forEach( ( keyToRemove: string ) => {
                  delete this.htmlIdForStyleId[ style.id ][ keyToRemove ];
              } )
        ;
    }
}
