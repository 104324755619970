import { Component, } from '@angular/core';
import { Router } from '@angular/router';

import { DetailPagePrevNextNavigationComponent }
    from '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component';
import { NonTradeItemPrevNextNavigationService } from './non-trade-item-prev-next-navigation.service';
import { NonTradeListItem } from '../../../../../shared/model/nonTradeListItem';


/**
 * Allows navigation to prev/next item from the NonTradeItemStore.
 */
@Component( {
    selector:    'rsp-non-trade-item-prev-next-navigation',
    templateUrl: '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component.html',
    styleUrls:   [
        '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.component.scss',
    ],
} )
export class NonTradeItemPrevNextNavigationComponent extends DetailPagePrevNextNavigationComponent<NonTradeListItem> {
    constructor(
        router: Router,
        nonTradeItemPrevNextNavigationService: NonTradeItemPrevNextNavigationService,
    ) {
        super( router, nonTradeItemPrevNextNavigationService );
    }
}
