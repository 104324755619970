import { Injectable } from '@angular/core';

@Injectable()
export class ScrollContainerService {

    scrollContainerNodes: Map<string, HTMLElement> = new Map<string, HTMLElement>();

    setScrollContainerNode( identifier: string, node: HTMLElement ): void {
        if ( this.scrollContainerNodes.has( identifier ) ) {
            console.warn('Scroll container was already defined for ', identifier);
        }
        this.scrollContainerNodes.set( identifier, node );
    }

    getScrollContainerNode(identifier: string): HTMLElement {
        return this.scrollContainerNodes.get( identifier );
    }

    removeScrollContainerNode( identifier: string): boolean {
        return this.scrollContainerNodes.delete( identifier );
    }

}
