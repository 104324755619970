<ng-template #dialogHeader>
    <h2 class="standard-dialog--headline">Export search result to Excel</h2>
</ng-template>

<ng-template #dialogContent>
    <div class="export-search-result-to-excel-dialog--content export-search-result-to-excel-dialog--info">
        <div class="export-search-result-to-excel-dialog--sheets-headline">Include Sheets:</div>
        <label *ngFor="let sheet of sheetsEnumValues" class="export-search-result-to-excel-dialog--sheets">
            <input rspCheckbox type="checkbox" [value]="sheet.index" [(ngModel)]="sheet.checked" [checked]="sheet.checked">
            {{ sheet.name | camelCaseToSpace }}
        </label>
    </div>

    <div class="export-search-result-to-excel-dialog--info">
        <ng-container *ngIf="(storeInfo | async)?.totalCount > 3000">
            <strong>ATTENTION:</strong>
            <p><i>You requested to export {{(storeInfo | async)?.totalCount}} items.</i></p>
            <p><i>Your extract will be large and take a significant amount of time to generate.</i></p>
            <p><i>In addition Images will be excluded from this extract.</i></p>
            <p><i>Images are only included for extracts containing up to 3000 items.</i></p>
            <br>
        </ng-container>
        <p>You will be notified by E-Mail once your extract is ready to download.</p>
    </div>

    <div class="standard-dialog--button" [rspLoadingIndicator]="isLoading">
        <rsp-edit-text
            [myFormControl]="nameControl"
            [placeholder]="'Export Name'"
            style="flex: 1"
        ></rsp-edit-text>
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
            class="export-search-result-to-excel-dialog--footer-button"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            [disabled]="!nameControl.valid || !hasCheckedSheets()"
            (clicked)="doAction()"
            class="export-search-result-to-excel-dialog--footer-button"
        >
            Trigger Export
        </rsp-button>
    </div>
</ng-template>

<ng-template #dialogFooter>
</ng-template>
