<div *ngIf="visibleNotifications.length" class="notification-list">
    <ul class="notification-list--list">
        <li
            *ngFor="let notification of visibleNotifications;"
            [class]="'notification-list--list-item ' + notification.className"
        >
            <rsp-notification-item
                [config]="notification"
                (closed)="removeNotification( notification.notificationId )"
            ></rsp-notification-item>
        </li>
    </ul>
    <span
        *ngIf="moreCount"
        class="notification-list--more"
    > {{ moreCount }} more...</span>

</div>


