import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, HostListener } from '@angular/core';
import { Router } from '@angular/router';

import { NonTradeListItem } from '../../../../../../shared/model/nonTradeListItem';
import { EntityTypeEnum } from '../../../../../../shared/model/enum/entity-type.enum';
import { StorageService, STORAGE_KEYS } from '../../../../../../core/storage.service';
import { NonTradeItemSelectionService } from '../../../shared/non-trade-item-selection.service';
import { NonTradeItemStoreService } from '../../../shared/non-trade-item-store.service';
import { SelectableItemComponent } from '../../../../../../shared/ui/lists/tile/selectable-item.component';
import { CurrentUserService } from '../../../../../../core/current-user.service';


/**
 * Represents one tile in the non-trade-item tile list.w
 */
@Component( {
    selector:        'rsp-non-trade-item-tile',
    templateUrl:     './non-trade-item-tile.component.html',
    styleUrls:       [
        './non-trade-item-tile.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class NonTradeItemTileComponent extends SelectableItemComponent<NonTradeListItem> implements OnInit {
    type: string;

    typeEnum: EntityTypeEnum = EntityTypeEnum.NonTradeItem;

    routerLink: Array<any> = [];

    detailsPageUrl: string;

    isHover: boolean = false;

    constructor(
        private router: Router,
        private storageService: StorageService,
        private currentUserService: CurrentUserService,
        nonTradeItemSelectionService: NonTradeItemSelectionService,
        nonTradeItemStoreService: NonTradeItemStoreService,
        changeDetectorRef: ChangeDetectorRef,
    ) {
        super( nonTradeItemSelectionService, nonTradeItemStoreService, changeDetectorRef );
    }

    ngOnInit(): void {

        if ( !this.item ) {
            throw new Error( 'no item given' );
        }

        super.ngOnInit();

        const itemTypeAsString: string = NonTradeListItem.TypeEnum.toString( this.item.type ).toLowerCase();

        this.routerLink = [ '/' + itemTypeAsString, this.item.number, ];

        this.detailsPageUrl = this.router.serializeUrl( this.router.createUrlTree( this.routerLink ) );

    }


    @HostListener( 'mouseover' )
    onMouseOver(): void {
        this.isHover = true;
    }

    @HostListener( 'mouseleave' )
    onMouseLeave(): void {
        this.isHover = false;
    }

    setIndex(): void {
        this.storageService.set<string>( STORAGE_KEYS.nonTradeItems.list.scrollToId, this.item.id );
    }

    isCheckboxVisible(): boolean {
        return this.isSelected || this.isHover;
    }
}
