<div class="file-table--buttons">
    <div
        *ngIf="exportToZip && !collectionView.isEmpty()"
        class="file-table--buttons-export"
    >
        <label class="file-table--buttons-export-toggle">
            <input
                rspCheckbox
                [rspCheckboxIsIndeterminate]="areSomeFilesSelected()"
                [checked]="areAllFilesSelected()"
                (change)="toggleAllSelection()"
                type="checkbox"
            >
            Select all
        </label>
        <rsp-button
            [submodule]="'primary'"
            [disabled]="!selectedFiles.length"
            (clicked)="zipSelectedFiles()"
        >
            Download Selection
        </rsp-button>
    </div>
    <rsp-button
        *ngIf="!newFile && !readOnly"
        [submodule]="'primary'"
        class="file-table--buttons-add"
        (clicked)="createFile()"
    >
        Add File
    </rsp-button>
</div>

<table
    *ngIf="collectionView && collectionView.sourceItems.length > 0 || newFile"
    class="file-table" #stickyContainer>
    <thead class="file-table--head">
    <tr
        rspFixTableRow
        [rspFixTableRowContainer]="stickyContainer"
    >
        <th *ngIf="exportToZip" class="file-table--head-cell"></th>
        <th *ngIf="!hidePreview" class="file-table--head-cell"></th>
        <th class="file-table--head-cell" *ngIf="referenceType">
            <rsp-column-header
                [collectionView]="collectionView"
                [propertyName]="'reference'"
            >{{ referenceHeader }}
            </rsp-column-header>
        </th>
        <th class="file-table--head-cell">
            <rsp-column-header
                [collectionView]="collectionView"
                [propertyName]="'fileKind'"
            >Filekind
            </rsp-column-header>
        </th>
        <th class="file-table--name file-table--head-cell">
            <rsp-column-header
                [collectionView]="collectionView"
                [propertyName]="'name'"
            >Name
            </rsp-column-header>
        </th>
        <th class="is-centered file-table--head-cell">
            <rsp-column-header
                [collectionView]="collectionView"
                [propertyName]="'uploadedOn'"
            >Uploaded at
            </rsp-column-header>
        </th>
        <th *ngIf="!readOnly" class="is-centered file-table--head-cell">Edit</th>
        <th *ngIf="!readOnly" class="is-centered file-table--head-cell">Remove</th>
    </tr>
    </thead>
    <tbody class="file-table--body">
    <tr *ngIf="newFile" class="file-table--body-row">
        <td *ngIf="exportToZip" class="file-table--body-cell">
        </td>
        <td *ngIf="!hidePreview" class="file-table--body-cell">
            <rsp-image
                [fileId]="1"
                alt="new file"
                height="65"
            ></rsp-image>
        </td>
        <td *ngIf="referenceType" class="file-table--body-cell"></td>
        <td class="file-table--body-cell" colspan="4">
            <div style="display: flex; flex-direction: row; align-items: center">
                <rsp-dropdown
                    [defaultValue]="selectedFileKind?.name"
                    [placeholder]="'Select Filekind'"
                    [withSuggester]="true"
                    [originalItems]="fileKinds"
                    [allowClear]="false"
                    (filteredItems)="setFilteredFileKinds( $event )"
                    (selected)="setFileKind( $event )"
                >
                    <rsp-dropdown-item *ngFor="let fileKind of filteredFileKinds" [item]="fileKind">
                        {{fileKind.name}}
                    </rsp-dropdown-item>
                </rsp-dropdown>
                <div *ngIf="selectedFileKind" style="width: 50%; margin-left: 10px;">
                    <rsp-upload-single-file
                        [fileKinds]="[selectedFileKind]"
                        (fileUpdated)="addFile( $event )"
                    >
                        click here or drop file
                    </rsp-upload-single-file>
                </div>
            </div>
        </td>
        <td class="is-centered file-table--body-cell">
            <rsp-remove
                [text]="'Do you really want to cancel file creation?'"
                [title]="'Cancel file creation'"
                (confirmClicked)="endCreateFile()"
            ></rsp-remove>
        </td>
    </tr>
    <tr
        *ngFor="let item of collectionView.items"
        class="file-table--body-row"
    >
        <td *ngIf="exportToZip" class="file-table--body-cell">
            <input
                rspCheckbox
                [checked]="isFileSelected( item.file )"
                (click)="toggleSelection( item.file )"
                type="checkbox"
            >
        </td>
        <td *ngIf="!hidePreview" class="file-table--body-cell">
            <div class="file-table--image-container">
                <rsp-image
                    fileId="{{ item.file.previewImageId }}"
                    alt="{{ item.file.name }} - {{ item.file.fileKind.name }}"
                    [alignImg]="true"
                    height="65"
                    width="100"
                ></rsp-image>
            </div>
        </td>
        <td
            *ngIf="referenceType"
            class="file-table--body-cell"
        >
            <ng-container *ngIf="referenceEnumToString( item.file.referencedObject.type ) !== referenceType || ignoreSelfReference; else selfDisplay">
                <rsp-link *ngIf="!referenceNoLink"
                          [type]="referenceEnumToString( item.file.referencedObject.type )"
                          [id]="useIdForReferenceLink ? item.file.referencedObject.id : item.file.referencedObject.displayValue"
                          [useContent]="useIdForReferenceLink"
                >
                    <ng-container *ngIf="useIdForReferenceLink">{{item.file.referencedObject.displayValue}}</ng-container>
                </rsp-link>
                <ng-container *ngIf="referenceNoLink">
                    {{item.file.referencedObject.displayValue}}
                </ng-container>
            </ng-container>
            <ng-template #selfDisplay>
                {{item.file.referencedObject.displayValue}}<br>({{ referenceSelfDisplay }})
            </ng-template>
        </td>
        <td class="file-table--body-cell">
            {{ item.file.fileKind.name }}
        </td>
        <td class="file-table--body-cell file-table--name-cell">
            <rsp-download-link
                *ngIf="!editMode.has( item.file.id ) && !anonymousDownload"
                [fileGuid]="item.file.id"
            >
                {{ item.file.name }}
            </rsp-download-link>
            <rsp-download-link
                *ngIf="!editMode.has( item.file.id ) && anonymousDownload"
                [fileGuidAnonymous]="item.file.id"
            >
                {{ item.file.name }}
            </rsp-download-link>

            <rsp-upload-single-file
                *ngIf="editMode.has( item.file.id )"
                [fileContainerId]="item.file.id"
                (fileUpdated)="updateRow( item.file )"
            >
                click here or drop file
            </rsp-upload-single-file>

        </td>
        <td class="is-centered file-table--body-cell">
            <nur-date-time [datetime]="item.file.uploadedOn"
                           format="Comparable"
                           [withTime]="false"
            ></nur-date-time>
            <ng-container
                *ngIf="!editMode.has( item.file.id )"
            >
                <br>
                <a style="cursor: pointer"
                   (click)="showFileVersionDialog( item.file.id )"
                >Show History</a>
            </ng-container>
        </td>
        <td *ngIf="!readOnly" class="is-centered file-table--body-cell">
                <span
                    *ngIf="!referenceType || referenceEnumToString( item.file.referencedObject.type ) === referenceType || ignoreSelfReference"
                    (click)="setEditModeForFile( item.file )"
                    [title]="editMode.has( item.file.id ) ? 'abort edit' : 'edit file'"
                    [class]="editMode.has( item.file.id ) ? 'edit-icons--cancel-edit' : 'edit-icons--edit'"
                ></span>
        </td>
        <td *ngIf="!readOnly" class="is-centered file-table--body-cell">
            <rsp-remove
                *ngIf="!referenceType || referenceEnumToString( item.file.referencedObject.type ) === referenceType || ignoreSelfReference"
                [text]="'Do you really want to remove this file?'"
                [title]="'remove file'"
                (confirmClicked)="removeFile( item.file.id )"
            ></rsp-remove>
        </td>
    </tr>
    </tbody>
</table>



<rsp-create-zip-from-files-dialog></rsp-create-zip-from-files-dialog>
<rsp-file-version-dialog></rsp-file-version-dialog>
