import { Injectable } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { QueryParamService } from '../shared/utils/url/query-param.service';
import { MainSlideInService } from './overlay/main-slide-in/main-slide-in.service';
import { MainLoadingIndicatorService } from './main-loading-indicator/main-loading-indicator.service';
import { filter } from 'rxjs/operators';


/**
 * Contains methods called during bootstrapping process.
 *
 */
@Injectable()
export class BootstrapService {

    constructor(
        private router: Router,
        private queryParamService: QueryParamService,
        private mainSlideInService: MainSlideInService,
        private mainLoadingIndicatorService: MainLoadingIndicatorService,
    ) {
    }


    /**
     * Initializes tracking of QueryParams.
     * Each time the route changes, query params are saved with QueryParamService.
     * We can use it later, when the used navigates back, to put the last used parameters as paging, filtering.
     */
    initializeQueryParamsTracking(): void {

        this.router
            .events
            .pipe(
                filter( ( event: any ) => event instanceof NavigationEnd ),
            )
            .subscribe( ( event: NavigationEnd ) => this.queryParamService.addUrl( event.url ) );
    }



    /**
     * Initializes Main-Slide in.
     * Each time navigation starts, we want to close main-slidein.
     */
    initializeMainSlideIn(): void {

        this.router
            .events
            .pipe(
                filter( ( event: any ) => event instanceof NavigationStart ),
            )
            .subscribe( () => this.mainSlideInService.closeSlideIn() );
    }


    /**
     * Initializes Main-Loading-Indicator for every route change.
     * It handles also the case when resolver needs some time to get data from the backend.
     */
    initializeMainLoadingIndicator(): void {
        const navigationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>( false );

        this.router
            .events
            .pipe(
                filter( ( event: any ) =>
                    event instanceof NavigationStart
                    || event instanceof NavigationEnd
                    || event instanceof NavigationCancel
                    || event instanceof NavigationError ),
            )
            .subscribe( ( event: any ) => {
                navigationSubject.next( event instanceof NavigationStart );
            } );

        this.mainLoadingIndicatorService.registerObservable( navigationSubject.asObservable() );
    }
}
