import { Directive, ElementRef, HostListener, Input, OnDestroy, TemplateRef } from '@angular/core';
import { Subscription, Subject, merge, fromEvent } from 'rxjs';
import { takeWhile, takeUntil } from 'rxjs/operators';

import { TooltipConfig, TooltipService } from './tooltip.service';
import { TooltipRef } from './tooltip-ref.model';


export type TooltipTriggerType = 'mouseover' | 'click';

/**
 * Directive which displays tooltip. Tooltip content can be a string or a TemplateRef.
 *
 * Usage:
 *
 *  Text tooltip:
 *      <span [rspTooltip]="'Text tooltip'">Hover me!</span>
 *      <span [rspTooltip]="'Text tooltip'" [rspTooltipTrigger]="'click'" >Click me!</span>
 *
 *  Template tooltip:
 *      <span [rspTooltip]="tooltipTemplate">Hover me!</span>
 *
 *      <ng-template #tooltipTemplate>
 *          <div>Tooltip from template</div>
 *      </ng-template>
 */
@Directive( {
    selector: '[rspTooltip]',
} )
export class TooltipDirective implements OnDestroy {

    /* tslint:disable:no-input-rename */

    /**
     * Content (string or TemplateRef) to be displayed as Tooltip.
     */
    @Input( 'rspTooltip' ) tooltipContent: string | TemplateRef<any>;

    /**
     * Trigger which shows the tooltip: 'mouseover' (default) or 'click'
     */
    @Input( 'rspTooltipTrigger' ) trigger: TooltipTriggerType = 'mouseover';


    @Input( 'rspTooltipConfig' ) config: TooltipConfig = null;

    /* tslint:enable:no-input-rename */


    private tooltipRef: TooltipRef = null;

    private mouseLeaveSubscription: Subscription;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();


    constructor(
        private elementRef: ElementRef,
        private tooltipService: TooltipService,
    ) {
    }

    ngOnDestroy(): void {
        this.hideTooltip();
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    @HostListener( 'mouseenter' )
    onMouseEnter(): void {
        this.handleOnMouseEnter();
    }


    @HostListener( 'click', [ '$event.target' ] )
    onClick(): void {
        this.handleOnClick();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private handleOnMouseEnter(): void {
        if ( this.trigger === 'mouseover' && !this.isTooltipVisible() ) {
            this.showTooltip();
        }
    }

    private handleOnClick(): void {
        if ( this.trigger === 'click' ) {
            if ( this.isTooltipVisible() ) {
                this.hideTooltip();
            }
            else {
                this.showTooltip();
            }
        }
    }

    private isTooltipVisible(): boolean {

        return this.tooltipRef !== null && this.tooltipRef !== undefined;
    }

    private showTooltip(): void {

        this.tooltipRef = this.tooltipService.show( this.tooltipContent, this.elementRef, this.config );

        this.initMouseLeaveBehaviour();
    }

    private hideTooltip(): void {

        if ( this.tooltipRef ) {
            this.tooltipRef.close();

            if ( this.mouseLeaveSubscription ) {
                this.mouseLeaveSubscription.unsubscribe();
            }


            this.tooltipRef = null;
        }
    }

    private initMouseLeaveBehaviour(): void {
        if ( this.trigger === 'mouseover' ) {
            this.mouseLeaveSubscription =
                merge(
                    fromEvent( this.tooltipRef.overlayRef.overlayElement, 'mouseleave' ),
                    fromEvent( this.tooltipRef.anchorElement.nativeElement, 'mouseleave' ),
                )
                    .pipe( takeWhile( () => this.tooltipRef ? true : false ), takeUntil( this.isDestroyed ) )
                    .subscribe( ( event: MouseEvent ) => {
                        if ( !(
                            this.tooltipRef.overlayRef.overlayElement.contains( event.relatedTarget as Node ) ||
                            this.tooltipRef.anchorElement.nativeElement.contains( event.relatedTarget as Node )
                        ) ) {
                            this.hideTooltip();
                        }
                    } );
        }
    }
}
