<ng-container *ngIf="!isEditMode">
    <nur-date-time
        *ngIf="myFormGroup.get( name ).value"
        [datetime]="myFormGroup.get( name ).value"
        [format]="format"
        [withWeekday]="withWeekday"
        [withTime]="withTime"
        [withSeconds]="withSeconds"
        [withTimezone]="withTimezone"
        [withYear]="false"
    ></nur-date-time>

    <rsp-not-available *ngIf="!myFormGroup.get( name ).value"></rsp-not-available>
</ng-container>

<rsp-edit-date
    *ngIf="isEditMode"
    [placeholder]="placeholder"
    [withClearButton]="withClearButton"
    [myFormGroup]="myFormGroup"
    [format]="format"
    [name]="name"
    [disablePastDates]="disablePastDates"
    [minDate]="minDate"
    [maxDate]="maxDate"
    [disabled]="disabled"
></rsp-edit-date>
