import { Directive, ElementRef, EventEmitter, Input, AfterViewInit, Output, Renderer2 } from '@angular/core';


/**
 * Converts html element containing text (f.e. span) to a keyword.
 *
 * ## Only tag ##
 *
 * ```html
 *  <span rspKeyword>Keyword Description</span>
 * ```
 *
 * ## Tag with clear button ##
 *
 * ```html
 *  <span
 *      rspKeyword
 *      [withClearButton]="true"
 *      (clearClick)="callback()"
 *  ></span>
 * ```
 */
@Directive( {
    selector: '[rspKeyword]',
} )
export class KeywordDirective implements AfterViewInit {
    @Input() submodule: 'bold' | '' | null | undefined;

    @Input()
    set withClearButton( value: boolean ) {
        this.setWithClearButton( value );
    }
    get withClearButton(): boolean {
        return this._withClearButton;
    }

    @Output() clearClick: EventEmitter<void> = new EventEmitter<void>();

    private _withClearButton: boolean;

    private buttonElement: HTMLElement;


    constructor(
        private hostElement: ElementRef,
        private renderer: Renderer2,
    ) {
    }

    ngAfterViewInit(): void {
        this.renderer.addClass( this.hostElement.nativeElement, this.getCssModuleName() );
        this.setWithClearButton(this._withClearButton);
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setWithClearButton( value: boolean ): void {
        if ( value ) {
            this.createAndAppendClearButton();
            this.renderer.addClass( this.hostElement.nativeElement, this.getCssModuleName() + '--with-clear-button' );
        }
        else {
            this.removeClearButton();
            this.renderer.removeClass( this.hostElement.nativeElement, this.getCssModuleName() + '--with-clear-button' );
        }

        this._withClearButton = value;
    }

    private createAndAppendClearButton(): void {
        if ( !this.buttonElement ) {
            // create: <button class="tag-close-button"></button>
            this.buttonElement = this.renderer.createElement( 'button' );
            this.renderer.setAttribute( this.buttonElement, 'type', 'button' );
            this.renderer.addClass( this.buttonElement, this.getCssModuleName() + '--close-button' );

            // click event
            this.renderer.listen( this.buttonElement, 'click', ( event: Event ) => {

                this.clearClick.emit();

                event.stopPropagation();
                return false;
            } );
        }

        // append to host element
        this.renderer.removeClass( this.buttonElement, 'keyword--close-button' );
        this.renderer.addClass( this.buttonElement, this.getCssModuleName() + '--close-button' );
        this.renderer.appendChild( this.hostElement.nativeElement, this.buttonElement );
    }

    private removeClearButton(): void {
        if ( this.buttonElement ) {
            this.renderer.removeChild( this.hostElement.nativeElement, this.buttonElement );
        }
    }

    private getCssModuleName(): string {
        return 'keyword' + ( this.submodule ? '_' + this.submodule : '' );
    }
}
