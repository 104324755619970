import { ChangeDetectorRef, Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { TooltipRef } from './tooltip-ref.model';
import { TOOLTIP_DATA } from './tooltip.token';
import { TooltipConfig, TooltipData } from './tooltip.service';


// Keycode for ESCAPE
const ESCAPE: number = 27;


interface TooltipArrowPosition {
    // on which edge of the overlay does the arrow sit?
    edge: 'top' | 'bottom' | 'left' | 'right';

    // horizontal center coordinate of arrow
    x: number | undefined;

    // vertical center coordinate of arrow
    y: number | undefined;
}

/**
 * Portal Component. Dynamically instantiated and displayed in PortalHost (CDK Overlay) by the TooltipService.
 * Renders tooltip bubble with provided content. Content can be a string or a TemplateRef.
 */
@Component( {
    selector:    'rsp-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls:   [ './tooltip.component.scss', ],
} )
export class TooltipComponent implements OnInit, OnDestroy {
    arrowPosition: TooltipArrowPosition;

    portalInstance: TemplatePortal<any>;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        @Inject( TOOLTIP_DATA ) public data: TooltipData,
        private tooltipRef: TooltipRef,
        private changeDetectorRef: ChangeDetectorRef,
    ) {

        if ( data.displayedType === 'template' && data.templateToDisplay ) {
            this.portalInstance = new TemplatePortal( data.templateToDisplay, null, data.templateContext );
        }
    }

    ngOnInit(): void {
        this.data.positionStrategy.positionChanges
            .pipe(
                distinctUntilChanged(),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                this.arrowPosition = this.getArrowPosition(
                    this.tooltipRef.overlayRef.overlayElement,
                    this.tooltipRef.anchorElement.nativeElement,
                    this.tooltipRef.config,
                );

                // Needed for hover-trigger of tooltip. Otherwise no arrow is shown (template doesn't get redrawn by Angular).
                this.changeDetectorRef.detectChanges();
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    private getArrowPosition(
        overlayElement: HTMLElement,
        anchorElement: HTMLElement,
        tooltipConfig: TooltipConfig,
    ): TooltipArrowPosition | undefined {
        const overlayClientRect: ClientRect = overlayElement.getBoundingClientRect();
        const anchorClientRect: ClientRect  = anchorElement.getBoundingClientRect();

        if ( overlayClientRect.left === 0 && overlayClientRect.top === 0 ) {
            // looks like the overlay wasn't rendered completely yet
            return;
        }

        const arrowPosition: TooltipArrowPosition = {
            x:    undefined,
            y:    undefined,
            edge: overlayClientRect.top > anchorClientRect.top ? 'top' :
                      overlayClientRect.top < anchorClientRect.top ? 'bottom' : null, // TODO: implement 'left' and 'right'
        };

        const overlayOffsetX: number = isFinite( tooltipConfig.tooltipOffsetX ) ? tooltipConfig.tooltipOffsetX : 0;
        if ( (arrowPosition.edge === 'top' || arrowPosition.edge === 'bottom') ) {
            // arrow has to be at top (or bottom) and …
            if ( overlayClientRect.left - overlayOffsetX === anchorClientRect.left ) {
                // … left of overlay
                arrowPosition.x = anchorClientRect.width / 2;
            }
            else if ( overlayClientRect.right === anchorClientRect.right ) {
                // … right of overlay
                arrowPosition.x = overlayClientRect.width - anchorClientRect.width / 2;
            }
            else { // TODO This is a bit optimistic, isn't it?
                // … center of overlay
                arrowPosition.x = anchorClientRect.left - overlayClientRect.left + anchorClientRect.width / 2;
            }

            if ( overlayOffsetX ) {
                arrowPosition.x = (arrowPosition.x || 0) + Math.abs( tooltipConfig.tooltipOffsetX || 0 );
            }
        }
        else {
            // TODO Implement left and right
        }

        return arrowPosition;
    }

    @HostListener( 'document:keydown', [ '$event' ] )
    private handleKeydown( event: KeyboardEvent ): void {

        // close tooltip on ESCAPE
        if ( event.keyCode === ESCAPE && this.tooltipRef ) {
            this.tooltipRef.close();
        }
    }
}
