<ng-container *ngIf="collectionView.isEmpty() && noConceptsMessage">
    <rsp-no-content>{{ noConceptsMessage }}</rsp-no-content>
</ng-container>

<ng-container *ngIf="!collectionView.isEmpty()">
    <rsp-global-filter
        [collectionView]="collectionView"
        [placeholder]="'Quick search concepts'"
        class="concepts--global-filter"
    ></rsp-global-filter>

    <table class="concepts-table" #stickyContainer>
        <thead class="concepts-table--head">
            <tr
                rspFixTableRow
                [rspFixTableRowContainer]="stickyContainer"
            >
                <th
                    *ngIf="isEditMode"
                    class="concepts-table--head-cell"
                >
                    <span class="concepts-table--head-cell-content_selected-column">Is selected?</span>
                </th>
                <th class="concepts-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'name'"
                    >Name</rsp-column-header>
                </th>
                <th class="concepts-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'description'"
                    >Description</rsp-column-header>
                </th>
                <th class="concepts-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'brand'"
                    >Brand</rsp-column-header>
                </th>
                <th class="concepts-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'subBrand'"
                    >Subbrand</rsp-column-header>
                </th>
                <th class="concepts-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'status'"
                    >Status</rsp-column-header>
                </th>
            </tr>
        </thead>
        <tbody class="concepts-table--body">
            <tr
                *ngFor="let item of collectionView.items"
                (click)="toggleConceptSelection( item.concept, $event )"
                class="concepts-table--body-row"
            >
                <td
                    *ngIf="isEditMode"
                    class="concepts-table--body-cell"
                >
                    <input
                        rspCheckbox
                        [id]="'concept-checkbox-' + item.id"
                        [checked]="isConceptSelected( item.concept )"
                        (change)="toggleConceptSelection( item.concept, $event )"
                        type="checkbox"
                    >
                </td>
                <td class="concepts-table--body-cell">
                    <label [for]="'concept-checkbox-' + item.id">
                        {{ item.name }}
                    </label>
                </td>
                <td class="concepts-table--body-cell">{{ item.description }}</td>
                <td class="concepts-table--body-cell">{{ item.brand }}</td>
                <td class="concepts-table--body-cell">{{ item.subBrand }}</td>
                <td class="concepts-table--body-cell">
                    <rsp-status
                        [status]="item.status"
                        [type]="clusterEntityType"
                    ></rsp-status>
                </td>
            </tr>
        </tbody>
    </table>
</ng-container>
