<ng-container *ngIf="!isEditMode
                        && value !== null
                        && value !== undefined">
    <ng-container *ngFor="let item of splittedValue; let isLast = last">
        {{ item }}<ng-container *ngIf="!isLast"><br></ng-container>
    </ng-container>
</ng-container>
<ng-container *ngIf="!isEditMode
                        && ( value === null || value === undefined || value === '' )
                        && showNotAvailable">
    <rsp-not-available [notAvailable]="notAvailableString"></rsp-not-available>
</ng-container>

<rsp-edit-multiline
    *ngIf="isEditMode"
    [inputFieldHtmlId]="inputFieldHtmlId"
    [myFormControl]="myFormGroup.get(name)"
>
</rsp-edit-multiline>

