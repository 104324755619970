import { NgModule } from '@angular/core';

/* tslint:disable:max-line-length */
import { MyProfileRoutingModule } from './my-profile-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { MyProfilePageComponent } from './my-profile-page/my-profile-page.component';
import { CurrentUserSummaryResolver } from './my-profile-page/current-user-summary.resolver';
/* tslint:enable:max-line-length */

@NgModule( {
    imports:      [
        MyProfileRoutingModule,
        SharedModule,
    ],
    declarations: [
        MyProfilePageComponent,
    ],
    providers: [
        CurrentUserSummaryResolver,
    ],
} )
export class MyProfileModule {
}
