/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { AddArticlesAndAssembliesToClusters } from '../model/addArticlesAndAssembliesToClusters';
import { ClusterSuggestSearchConfiguration } from '../model/clusterSuggestSearchConfiguration';
import { CreateClusterData } from '../model/createClusterData';
import { RemoveArticlesAndAssembliesFromClusters } from '../model/removeArticlesAndAssembliesFromClusters';
import { Reply } from '../model/reply';
import { ReplyGuid } from '../model/replyGuid';
import { UpdateClusterDetail } from '../model/updateClusterDetail';
import { ViewModelItemClusterDetailClusterDetailChangeability } from '../model/viewModelItemClusterDetailClusterDetailChangeability';
import { ViewModelItemClusterSummary } from '../model/viewModelItemClusterSummary';
import { ViewModelListClusterNonTradeItem } from '../model/viewModelListClusterNonTradeItem';
import { ViewModelListFileContainerWithReference } from '../model/viewModelListFileContainerWithReference';
import { ViewModelPagedListClusterSuggestItem } from '../model/viewModelPagedListClusterSuggestItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class ArticleManagementClustersService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Add articles and/or assemblies to one or multiple clusters
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersAddArticlesAndAssembliesToClusters(command: AddArticlesAndAssembliesToClusters, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public clustersAddArticlesAndAssembliesToClusters(command: AddArticlesAndAssembliesToClusters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public clustersAddArticlesAndAssembliesToClusters(command: AddArticlesAndAssembliesToClusters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public clustersAddArticlesAndAssembliesToClusters(command: AddArticlesAndAssembliesToClusters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling clustersAddArticlesAndAssembliesToClusters.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/clusters/AddArticlesAndAssembliesToClusters`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Create new Cluster providing the id of the concept within which to create the new Cluster with the given                  Name and optionally associate the new cluster with assemblies and/or articles those ids were provided
     * 
     * @param data 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersCreateCluster(data: CreateClusterData, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public clustersCreateCluster(data: CreateClusterData, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public clustersCreateCluster(data: CreateClusterData, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public clustersCreateCluster(data: CreateClusterData, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (data === null || data === undefined) {
            throw new Error('Required parameter data was null or undefined when calling clustersCreateCluster.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/clusters/CreateCluster`,
                data,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetDetail
     * 
     * @param clusterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersGetDetail(clusterId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemClusterDetailClusterDetailChangeability>;
    public clustersGetDetail(clusterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemClusterDetailClusterDetailChangeability>>;
    public clustersGetDetail(clusterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemClusterDetailClusterDetailChangeability>>;
    public clustersGetDetail(clusterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling clustersGetDetail.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemClusterDetailClusterDetailChangeability>(
                `${this.basePath}/api/v1/articlemanagement/clusters/${encodeURIComponent(String(clusterId))}/detail`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Returns a list of {RSP.ViewModel.Models.FileManagement.FileContainerWithReference}
     * 
     * @param clusterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersGetFileContainers(clusterId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListFileContainerWithReference>;
    public clustersGetFileContainers(clusterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListFileContainerWithReference>>;
    public clustersGetFileContainers(clusterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListFileContainerWithReference>>;
    public clustersGetFileContainers(clusterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling clustersGetFileContainers.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListFileContainerWithReference>(
                `${this.basePath}/api/v1/articlemanagement/clusters/${encodeURIComponent(String(clusterId))}/files`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetNonTradeItems
     * 
     * @param clusterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersGetNonTradeItems(clusterId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListClusterNonTradeItem>;
    public clustersGetNonTradeItems(clusterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListClusterNonTradeItem>>;
    public clustersGetNonTradeItems(clusterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListClusterNonTradeItem>>;
    public clustersGetNonTradeItems(clusterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling clustersGetNonTradeItems.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListClusterNonTradeItem>(
                `${this.basePath}/api/v1/articlemanagement/clusters/${encodeURIComponent(String(clusterId))}/nontradeitems`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetSummary
     * 
     * @param clusterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersGetSummary(clusterId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemClusterSummary>;
    public clustersGetSummary(clusterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemClusterSummary>>;
    public clustersGetSummary(clusterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemClusterSummary>>;
    public clustersGetSummary(clusterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling clustersGetSummary.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemClusterSummary>(
                `${this.basePath}/api/v1/articlemanagement/clusters/${encodeURIComponent(String(clusterId))}/summary`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Publish Cluster
     * 
     * @param clusterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersPublishCluster(clusterId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public clustersPublishCluster(clusterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public clustersPublishCluster(clusterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public clustersPublishCluster(clusterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling clustersPublishCluster.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/clusters/${encodeURIComponent(String(clusterId))}/publish`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Remove articles and/or assemblies from one or multiple clusters
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersRemoveArticlesAndAssembliesFromClusters(command: RemoveArticlesAndAssembliesFromClusters, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public clustersRemoveArticlesAndAssembliesFromClusters(command: RemoveArticlesAndAssembliesFromClusters, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public clustersRemoveArticlesAndAssembliesFromClusters(command: RemoveArticlesAndAssembliesFromClusters, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public clustersRemoveArticlesAndAssembliesFromClusters(command: RemoveArticlesAndAssembliesFromClusters, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling clustersRemoveArticlesAndAssembliesFromClusters.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/clusters/RemoveArticlesAndAssembliesFromClusters`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Suggests (searches) a non pageable list of short concept descriptor items.                  Please do not confuse the term suggest with the suggest mechanism in Elasticsearch.
     * 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersSuggest(configuration: ClusterSuggestSearchConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListClusterSuggestItem>;
    public clustersSuggest(configuration: ClusterSuggestSearchConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListClusterSuggestItem>>;
    public clustersSuggest(configuration: ClusterSuggestSearchConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListClusterSuggestItem>>;
    public clustersSuggest(configuration: ClusterSuggestSearchConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (configuration === null || configuration === undefined) {
            throw new Error('Required parameter configuration was null or undefined when calling clustersSuggest.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelPagedListClusterSuggestItem>(
                `${this.basePath}/api/v1/articlemanagement/clusters/suggest`,
                configuration,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Unpublish Cluster
     * 
     * @param clusterId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersUnpublishCluster(clusterId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public clustersUnpublishCluster(clusterId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public clustersUnpublishCluster(clusterId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public clustersUnpublishCluster(clusterId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling clustersUnpublishCluster.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/clusters/${encodeURIComponent(String(clusterId))}/unpublish`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Updates cluster&#39;s details.
     * 
     * @param clusterId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersUpdateDetail(clusterId: string, command: UpdateClusterDetail, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public clustersUpdateDetail(clusterId: string, command: UpdateClusterDetail, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public clustersUpdateDetail(clusterId: string, command: UpdateClusterDetail, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public clustersUpdateDetail(clusterId: string, command: UpdateClusterDetail, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling clustersUpdateDetail.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling clustersUpdateDetail.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/clusters/${encodeURIComponent(String(clusterId))}/detail`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Files
     * 
     * @param clusterId 
     * @param fileContainerIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public clustersUpdateFileContainers(clusterId: string, fileContainerIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public clustersUpdateFileContainers(clusterId: string, fileContainerIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public clustersUpdateFileContainers(clusterId: string, fileContainerIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public clustersUpdateFileContainers(clusterId: string, fileContainerIds: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (clusterId === null || clusterId === undefined) {
            throw new Error('Required parameter clusterId was null or undefined when calling clustersUpdateFileContainers.');
        }
        if (fileContainerIds === null || fileContainerIds === undefined) {
            throw new Error('Required parameter fileContainerIds was null or undefined when calling clustersUpdateFileContainers.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/clusters/${encodeURIComponent(String(clusterId))}/files`,
                fileContainerIds,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
