<div [class.main-slide-in--backdrop]="isVisible" (click)="clickedBackdrop( $event )" #backdrop>
    <div
        class="main-slide-in"
        [ngStyle]="inlineStyleAttributes"
        [@slideIn]="isVisible ? 'in' : 'out'"
        (@slideIn.start)="onSlideInAnimationStart()"
        (@slideIn.done)="onSlideInAnimationDone( $event )"
    >
        <ng-container #target></ng-container>
    </div>
</div>

