<ng-template #moduleGroupDialogHeadline>
    <h2 class="standard-dialog--headline">
        {{ userEditMode === 'my-profile' ? 'Request additional Module Groups' : 'Change Module Groups' }}
    </h2>
</ng-template>

<ng-template #moduleGroupDialogContent>
    <div
        [rspLoadingIndicator]="isSaving"
        class="standard-dialog--content"
    >
        <section class="user-module-groups-dialog--assigned-module-groups">
            <h3 class="user-module-groups-dialog--section-headline">Assigned module groups</h3>

            <ol
                *ngIf="assignedModuleGroups.length > 0"
                class="user-module-groups-dialog--module-groups-list"
            >
                <li *ngFor="let moduleGroup of assignedModuleGroups">
                    <label
                        class="user-module-groups-dialog--module-group-checkbox-wrapper"
                        [class.is-module-group-waiting-for-approval]="moduleGroup.isWaitingForApproval"
                    >
                        <input
                            rspCheckbox
                            [checked]="selectedModuleGroups.has( moduleGroup.id )"
                            [disabled]="userEditMode === 'my-profile' && selectedModuleGroups.has( moduleGroup.id )"
                            (change)="toggleModuleGroupSelection( moduleGroup.id )"
                            type="checkbox"
                        >

                        {{ moduleGroup.name }}

                        <ng-container *ngIf="moduleGroup.isWaitingForApproval">
                            (waiting for approval)
                        </ng-container>
                    </label>
                </li>
            </ol>

            <p *ngIf="assignedModuleGroups.length === 0">
                No module groups assigned, yet.
            </p>
        </section>

        <section class="user-module-groups-dialog--available-module-groups">
            <h3 class="user-module-groups-dialog--section-headline">Other available module groups</h3>

            <ng-container *ngIf="availableModuleGroups.length > 0">
                <rsp-messages
                    *ngIf="userEditMode === 'my-profile'"
                    [type]="messageTypeEnum.Info"
                    [messages]="[ 'Adding additional module groups needs to be approved by our support team.' ]"
                ></rsp-messages>

                <ol class="user-module-groups-dialog--module-groups-list">
                    <li *ngFor="let moduleGroup of availableModuleGroups">
                        <label class="user-module-groups-dialog--module-group-checkbox-wrapper">
                            <input
                                rspCheckbox
                                [checked]="selectedModuleGroups.has( moduleGroup.id )"
                                (change)="toggleModuleGroupSelection( moduleGroup.id )"
                                type="checkbox"
                            >

                            {{ moduleGroup.name }}
                        </label>
                    </li>
                </ol>
            </ng-container>

            <p *ngIf="availableModuleGroups.length === 0">
                No other module groups available.
            </p>
        </section>
    </div>
</ng-template>

<ng-template #moduleGroupDialogFooter>
    <div class="standard-dialog--footer">
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="closeDialog()"
            class="standard-dialog--button"
        >Cancel</rsp-button>

        <rsp-button
            [type]="'button'"
            [submodule]="'primary'"
            [disabled]="!( !isSaving || ( isSaving && isSaving.closed ) )"
            [isLoading]="!( !isSaving || ( isSaving && isSaving.closed ) )"
            (clicked)="saveModuleGroups()"
            class="standard-dialog--button"
        >
            {{ userEditMode === 'my-profile' ? 'Request' : 'Save' }}
        </rsp-button>
    </div>
</ng-template>
