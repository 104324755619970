import { ClusterNonTradeItem } from '../../../../../shared/model';
import StatusEnum = ClusterNonTradeItem.StatusEnum;

export class ClusterNonTradeItemCollectionViewItem {

    nonTradeItem: ClusterNonTradeItem;

    get number(): string {
        return this.nonTradeItem.number;
    }

    get name(): string {
        return this.nonTradeItem.name;
    }

    get status(): string {
        return StatusEnum.toString(this.nonTradeItem.status);
    }

    constructor(clusterNonTradeItem: ClusterNonTradeItem) {
        this.nonTradeItem = clusterNonTradeItem;
    }

}
