/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface UserListItem {
    id?: string;

    globalId?: string;

    firstName?: string;

    familyName?: string;

    profilePreviewImageId?: string;

    type?: UserListItem.TypeEnum;

    status?: UserListItem.StatusEnum;

}

export namespace UserListItem {
    'use strict';
    export enum TypeEnum {
        Internal,
        External,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
    export enum StatusEnum {
        Active,
        Inactive,
        Registered,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

