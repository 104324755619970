import {
    Component, Input, ElementRef, OnInit, Output, EventEmitter, OnDestroy, AfterContentInit, QueryList, ContentChildren, ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';

import { ContainerDimension, ScrollService } from '../../scroll/scroll.service';
import { VirtualScrollItemComponent } from './virtual-scroll-item/virtual-scroll-item.component';
import { ScrollDirective } from '../../scroll/scroll.directive';
import { WindowEventService } from '../../../../core/window-event.service';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

export type ScrollToCompareFunction = ( scrollToValue: any, item: any ) => boolean;

/**
 *  This component implements virtual scrolling. For the calculations of the height of the scrollbar and the scroll offset (needed for translateY style) the
 *  item size is needed. The size is defined in scss (height, padding, ...), so we need a workaround here.
 *  For the calculation of the item size, only one item is emitted after setItems is called. This item will be hidden, so there is no flashing in the browser.
 *  After this item is rendered, its size is available in ngAfterContentInit.
 *  The virtual scroll calculation is triggered by scroll events, so as a workaround, we trigger a new event manually, after the size calculation have been
 *  made, so all possible items will be shown in the browser.
 *
 *  Summarized:
 *
 *  # Initialization
 *  - setItems() is called first, item size not known, emit #update event with one item
 *  - VirtualScrollItemComponent renders its content with style "visibility = hidden"
 *  - after content is rendered (only one hidden item), VirtualScrollComponent's lifecycle method ngAfterContentInit() is called
 *  - item height is set, hidden element is made visible and component emits #update event with all items that should be displayed in current viewport
 *
 */
@Component( {
    selector:    'rsp-virtual-scroll',
    templateUrl: './virtual-scroll.component.html',
    styleUrls:   [
        './virtual-scroll.component.scss',
    ],
} )
export class VirtualScrollComponent implements OnInit, OnDestroy, AfterContentInit {

    @ContentChildren( VirtualScrollItemComponent ) children: QueryList<VirtualScrollItemComponent>;

    @ViewChild( ScrollDirective, { static: true } ) scrollDirective: ScrollDirective;

    @Input() scrollToValue: any;

    @Input() scrollToCompareFunction: ScrollToCompareFunction = VirtualScrollComponent.defaultScrollToCompareFunction;

    /**
     * Columns count is needed for calculation, how many items are visible in the viewport.
     * If set to 'auto' css media queries are used to set number of columns.
     * Number value specifies constant number of column.
     */
    @Input()
    set columnCount( value: number | 'auto' ) {
        this.setColumnCount( value );
    }

    get columnCount(): number | 'auto' {
        return this._columnCount;
    }

    @Input()
    set items( value: Array<any> ) {
        this.setItems( value );
    }

    get items(): Array<any> {
        return this._items;
    }

    @Output() update: EventEmitter<Array<any>>     = new EventEmitter();
    @Output() scrolledToBottom: EventEmitter<void> = new EventEmitter<void>();

    shiftPressed: boolean = false;

    totalHeight: number = 0;
    topPadding: number  = 0;


    private sizeOfItem: number            = null;
    private columns: number;
    private itemsInViewPort: number;
    private currentRow: number            = 0;
    private firstVisibleItemIndex: number = 0;

    private _items: Array<any>;
    private _columnCount: number | 'auto' = 'auto';

    private subscription: Subscription = new Subscription();


    constructor(
        private element: ElementRef,
        private scrollService: ScrollService,
        private windowsEventService: WindowEventService,
    ) {
    }


    /**
     *  Default function used to find item, component should scroll to.
     *  It assumes that item has 'id' property.
     */
    static defaultScrollToCompareFunction: ScrollToCompareFunction = ( scrollToValue: any, item: any ) => {

        if ( item && item.hasOwnProperty( 'id' ) ) {
            return item[ 'id' ] === scrollToValue;
        }

        return false;
    }

    ngOnInit(): void {

        this.subscription.add(
            // scroll container has been resized
            this.scrollService
                .scrollContainerDimension
                .pipe( debounceTime( 200 ) )
                .subscribe( ( dimensions: ContainerDimension ) => {

                    if ( this.isInitialized() ) {

                        this.calculateBaseData( dimensions );

                        this.calculateItems();
                    }
                } ),
        );

        this.subscription.add(
            // container has been scrolled
            this.scrollService
                .scrollOffset
                .pipe(
                    map( ( offset: number ) => this.calculateRowNumber( offset ) ),
                    distinctUntilChanged(),
                )
                .subscribe( ( row: number ) => {

                    this.currentRow = row;

                    this.firstVisibleItemIndex = this.currentRow * this.columns;

                    this.calculateItems();
                } ),
        );
    }

    ngAfterContentInit(): void {

        if ( !this.children ) {
            return;
        }

        if ( this.children.length ) {
            this.initialize();
        }

        this.subscription.add(
            this.children
                .changes
                .subscribe( () => {
                    this.initialize();
                } ) );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    onKeyUp( event: KeyboardEvent ): void {

        if ( event.shiftKey ) {
            this.shiftPressed = false;
        }
    }

    onKeyDown( event: KeyboardEvent ): void {

        if ( event.shiftKey ) {
            this.shiftPressed = true;
        }
    }

    isInitialized(): boolean {
        return this.sizeOfItem !== null;
    }

    onScrolledToBottom(): void {
        this.scrolledToBottom.emit();
    }


    scrollToTop(): void {
        this.scrollDirective.setScrollTop( 0 );
    }

    scrollToItem( index: number ): void {

        const scrollTopForItem: number = this.calculateTopForItem( index );
        this.scrollDirective.setScrollTop( scrollTopForItem );
    }

    rebuild( scrollTo: boolean = true ): void {

        if ( this.isInitialized() ) {

            this.calculateBaseData();

            if ( scrollTo ) {
                // scroll causes that scrollOffset event is fired and item are recalculated
                this.scrollToItem( this.firstVisibleItemIndex );
            }
        }
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setItems( value: Array<any> ): void {

        this._items = value;

        if ( value && value.length ) {

            if ( !this.isInitialized() ) {

                // emit just one item to calculate item size (see ngAfterContentInit)
                this.update.emit( this.items.slice( 0, 1 ) );
                return;
            }

            // calculate new total height
            this.setTotalHeight();

            this.calculateItems();
        }
    }

    private setColumnCount( value: number | 'auto' ): void {

        this._columnCount = value;

        if ( this.isInitialized() ) {

            // Column count has changed (f.e. when user switches between Table and List view). First the new component must be rendered, then
            // we can take new size of item. This is why setTimeout() is used.
            setTimeout( () => {

                this.rebuild();
            } );
        }
    }

    private calculateBaseData( dimensions?: ContainerDimension ): void {

        if ( !dimensions ) {
            dimensions = this.scrollService.scrollContainerDimension.getValue();
        }

        this.setSizeOfItem();

        this.setNumberOfColumns( dimensions );

        this.setTotalHeight();
    }

    private initialize( force: boolean = false ): void {

        if ( this.children.length && (force || !this.isInitialized()) ) {

            // trigger new calculation of items (see subscription in ngOnInit)
            // setTimeout is needed, because setSizeOfItem is called in ngAfterContentInit, where the change detection has been made already
            // to rerender the content, a new round of change detection is needed
            setTimeout( () => {

                this.calculateBaseData();

                // the single item is initially hidden, so it needs to be shown
                const item: VirtualScrollItemComponent = this.children.first as VirtualScrollItemComponent;
                item.show();

                this.calculateItems();

                // scroll to calculated offset after calculation of new items has been triggered
                this.scrollTo();
            } );
        }
    }

    private scrollTo(): void {

        if ( this.scrollToCompareFunction !== null
            && this.scrollToValue !== null
            && this.items
            && this.items.length ) {

            const index: number = this.items.findIndex( ( item: any ) => this.scrollToCompareFunction( this.scrollToValue, item ) );

            if ( index !== -1 ) {
                const topValue: number = this.calculateTopForItem( index );
                this.scrollDirective.setScrollTop( topValue );
            }
        }
    }

    private setSizeOfItem(): void {

        this.sizeOfItem = this.children.first.container.nativeElement.offsetHeight;
    }

    private setNumberOfColumns( dimensions: ContainerDimension ): void {

        if ( this.columnCount === 'auto' ) {
            // see virtual-scroll.component.scss for the ":before content" information
            this.columns = parseInt(
                window.getComputedStyle( this.element.nativeElement, ':before' )
                      .getPropertyValue( 'content' )
                      .replace( /"/g, '' )
                      .replace( /'/g, '' ),
                10,
            );
        }
        else {
            this.columns = this.columnCount;
        }

        // NOTE: fix for firefox
        // How to reproduce this issue:
        // - navigate from the list of NonTradeItems into details
        // - go back on the list
        // - check the error in log (this.children.first is undefined)
        //
        // In this case items are loaded from the cache (NontradeItemStore), probably some timing issue.
        // Scrolling container height is 0 and itemsInViewPort will also be 0. So the view will be populated will 0 items.
        // As workaround we trigger Windows-Resize event, which causes that scrollService refreshes scroll container dimensions.
        if ( dimensions.height === 0 ) {
            this.windowsEventService.notifyWindowResized();
            dimensions.height = this.scrollService.scrollContainerDimension.getValue().height || 1500;
        }

        this.itemsInViewPort = Math.ceil( dimensions.height / this.sizeOfItem ) * this.columns;
    }

    private setTotalHeight(): void {
        this.totalHeight = Math.ceil( this.items.length / this.columns ) * this.sizeOfItem;
    }

    private calculateItems(): void {

        if ( !this.sizeOfItem ) {
            return;
        }

        const visibleRows: number = this.itemsInViewPort / this.columns;
        const isTop: boolean      = this.currentRow <= visibleRows;
        const start: number       = isTop ? 0 : (this.columns * this.currentRow) - this.itemsInViewPort;

        this.topPadding = isTop ? 0 : (this.currentRow - visibleRows) * this.sizeOfItem;

        if ( this.items ) {
            this.update.emit( this.items.slice( start, start + this.itemsInViewPort * 3 ) );
        }
    }

    private calculateRowNumber( offset: number ): number {
        const x: number = 0;
        // const x: number = this.sizeOfItem / 2;
        return this.sizeOfItem ? Math.ceil( (offset + x) / this.sizeOfItem ) : 0;
    }

    private calculateTopForItem( index: number ): number {
        return Math.ceil( (index + 1) / this.columns ) * this.sizeOfItem - this.sizeOfItem;
    }
}
