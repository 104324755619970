import { Component, Output, EventEmitter, Input, } from '@angular/core';
import { Subscription, } from 'rxjs';

import { KeywordInfo, } from '../../../model/keywordInfo';
import { MasterDataKeywordsService } from '../../../api';
import { ViewModelPagedListKeywordInfo, } from '../../../model/viewModelPagedListKeywordInfo';

@Component( {
    selector:    'rsp-keyword-suggester',
    templateUrl: './keyword-suggester.component.html',
    styleUrls:   [ './keyword-suggester.component.scss' ],
} )
export class KeywordSuggesterComponent {
    @Input() itemFilter: string[] = [];

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    /**
     * Group Name, used to filter keywords on the backend. F.e. only Articles or Concept keywords.
     */
    @Input() groupName: string;

    @Input() placeholder: string = 'Search Keywords';

    @Output() selected: EventEmitter<KeywordInfo> = new EventEmitter<KeywordInfo>();

    items: KeywordInfo[] = [];

    isLoading: Subscription;

    constructor(
        private keywordsApi: MasterDataKeywordsService,
    ) { }

    searchItems( searchTerm: string ): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }

        this.items = [];
        if ( !searchTerm || !searchTerm.trim() ) {
            searchTerm = '*';
        }

        this.isLoading =
            this.keywordsApi.keywordsSuggest( {
                term:         searchTerm,
                keywordGroup: this.groupName,
                ignoreIds:    this.itemFilter.length ? this.itemFilter : undefined,
                page:         1,
                size:         50,
            } )
                .subscribe( ( searchResult: ViewModelPagedListKeywordInfo ) => {
                    this.items = searchResult.data;
                } );
    }

    selectedItem( item: KeywordInfo ): void {
        this.selected.emit( item );
    }
}
