import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StoreInfo } from '../../../../shared/ui/lists/store/store-info.model';
import { NonTradeItemStoreService } from '../shared/non-trade-item-store.service';
import { NonTradeItemSelectionService } from '../shared/non-trade-item-selection.service';
import { NonTradeListItem } from '../../../../shared/model/nonTradeListItem';
import { MainSlideInService } from '../../../../core/overlay/main-slide-in/main-slide-in.service';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { ToolbarBaseComponent } from '../../../../shared/ui/secondary-toolbar/toolbar-base.component';

/**
 * Non-Trade-Item function toolbar. Placed in master-head as secondary-toolbar.
 */
@Component( {
    selector:    'rsp-non-trade-items-toolbar',
    templateUrl: './non-trade-items-toolbar.component.html',
    styleUrls:   [
        '../../../../shared/scss/05_module/secondary-toolbar.scss',
        './non-trade-items-toolbar.component.scss',
    ],
} )
export class NonTradeItemsToolbarComponent extends ToolbarBaseComponent implements OnInit {

    storeInfo: Observable<StoreInfo>;

    selectedItems: Observable<Array<NonTradeListItem>>;

    isSelectionAvailable: Observable<boolean>;

    constructor(
        protected storeService: NonTradeItemStoreService,
        private selectionService: NonTradeItemSelectionService,
        toolbarService: SecondaryToolbarService,
        router: Router,
        activatedRoute: ActivatedRoute,
        mainSlideInService: MainSlideInService,
    ) {
        super( storeService, router, activatedRoute, mainSlideInService, toolbarService );

        this.storeInfo            = this.storeService.storeInfo$;
        this.selectedItems        = this.selectionService.selectedItems$;
        this.isSelectionAvailable = this.selectionService.isSelectionAvailable$;

    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    clearSelection(): void {
        this.selectionService.clearSelection();
    }
}
