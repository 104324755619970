import { Component,   } from '@angular/core';

import { environment, } from '../../../environments/environment';

@Component( {
  selector:    'rsp-system-info',
  templateUrl: './system-info.component.html',
  styleUrls:   [
      './system-info.component.scss',
  ],
} )
export class SystemInfoComponent {
    /* tslint:disable:no-unused-variable */ // only used in template
    systemName: string  = environment.id;
    /* tslint:enable:no-unused-variable */

    isVisible:  boolean = environment.id !== 'live';

    cssModuleName: string = 'rsp-system-info_' + this.systemName;

    public hideSystemInfo(): void {
        this.isVisible = false;
        // TODO: Call rsp-table-tools.max-height
    }
}
