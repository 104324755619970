import { Component, Input, } from '@angular/core';

import { Tag, } from './tag';
import { NOT_AVAILABLE, } from '../not-available/not-available.component';


/**
 * Displays list of tags. Tag can be marked as High-Prio-Tag which will be highlighted.
 */
@Component( {
    selector:    'rsp-tags',
    templateUrl: './tags.component.html',
    styleUrls:   [
        './tags.component.scss',
    ],
} )
export class TagsComponent {

    /**
     * List of Tags to be displayed.
     */
    @Input() tags: Array<Tag>;

    /**
     * If true, text will be shown when there are no tags to display.
     *
     * @type {boolean}
     */
    @Input() showEmptyText: boolean = true;

    /**
     * Text visible when there are no tags to display.
     * @type {string}
     */
    @Input() emptyText: string = NOT_AVAILABLE;
}
