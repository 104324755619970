import { ChangeDetectionStrategy, Component, Input, } from '@angular/core';

import { UserInfo } from '../../../../shared/model';

@Component( {
    selector:        'rsp-welcome-user',
    templateUrl:     './welcome-user.component.html',
    styleUrls:       [ './welcome-user.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class WelcomeUserComponent {
    @Input() user: UserInfo;
}
