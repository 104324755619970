<rsp-image
    *ngIf="user.profilePreviewImageId"
    [fileId]="user.profilePreviewImageId"
    [width]="200"
    [height]="200"
    [alt]="'Profile image of ' + userName"
    [class]="'user-profile-image--image'"
    class="user-profile-image--image-wrapper"
    title="{{ userName }}"
></rsp-image>
<rsp-x-button
    *ngIf="showClearButton && user.profilePreviewImageId"
    class="user-profile-image--clear-button"
    title="remove profile image"
    (click)="clear()"
></rsp-x-button>

<abbr
    *ngIf="!user.profilePreviewImageId"
    [attr.title]="showTooltip ? userName : null"
    [class.no-cursor-change]="!showTooltip"
    class="user-profile-image--initials"
>
    {{ userInitials }}
</abbr>
