import { Directive, ElementRef, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Observable ,  Subscription ,  fromEvent } from 'rxjs';

import { ContainerDimension, ScrollService } from './scroll.service';
import { WindowEventService } from '../../../core/window-event.service';
import { distinctUntilChanged, filter } from 'rxjs/operators';


@Directive( {
    selector: '[rspScroll]',
} )
export class ScrollDirective implements OnInit, OnDestroy {

    /**
     * Event occurs when scrolling container has been scrolled to the bottom.
     */
    @Output() scrolledToBottom: EventEmitter<number> = new EventEmitter<number>();

    private subscription: Subscription = new Subscription();

    constructor(
        private element: ElementRef,
        private eventService: WindowEventService,
        private scrollService: ScrollService,
    ) {
    }


    ngOnInit(): void {
        this.setScrollContainerDimensions();

        this.scrollService.scrollOffset.next( 0 ); // reset subject on init

        const scroll$: Observable<number> = fromEvent( this.element.nativeElement, 'scroll' );

        this.subscription.add(
            this.eventService.resized$.subscribe( () => {
                this.setScrollContainerDimensions();
            } ),
        );

        this.subscription.add(
            scroll$.subscribe( () => {
                this.scrollService.scrollOffset.next( this.element.nativeElement.scrollTop );
            } ),
        );

        this.subscription.add(
            scroll$
                .pipe(
                    distinctUntilChanged(),
                    filter( () => {
                        const height: number       = this.element.nativeElement.getBoundingClientRect().height;
                        const scrollHeight: number = this.element.nativeElement.scrollHeight;

                        return this.element.nativeElement.scrollTop > (scrollHeight - height - (scrollHeight / 6));
                    } ),
                )
                .subscribe( () => {
                    this.scrolledToBottom.emit( this.element.nativeElement.scrollTop );
                } ),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


    setScrollTop( value: number ): void {
        setTimeout( () => {
            this.element.nativeElement.scrollTop = value;
        } );
    }

    private setScrollContainerDimensions(): void {

        const height: number = this.element.nativeElement.getBoundingClientRect().height;
        const width: number  = this.element.nativeElement.getBoundingClientRect().width;

        const currentDimensions: ContainerDimension = this.scrollService.scrollContainerDimension.getValue();

        if ( currentDimensions.height !== height || currentDimensions.width !== width ) {

            this.scrollService
                .scrollContainerDimension
                .next( {
                    width:  width,
                    height: height,
                } );
        }
    }
}
