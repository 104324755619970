import { Injectable, TemplateRef } from '@angular/core';
import { Subject ,  BehaviorSubject ,  Observable } from 'rxjs';

export interface DialogConfig {
    contentTemplate: TemplateRef<any>;
    headlineTemplate?: TemplateRef<any>;
    footerTemplate?: TemplateRef<any>;
    withBackdrop: boolean;
    anchorElement?: HTMLElement;
    templateData?: any;
    cancelCallback?(): void;
}

/**
 * To spawn new dialogs, integrate this service into your component. The content of the dialog has to be provided to `openDialog` via a templateRef, so in your
 * template do something like this:
 *
 *     ```
 *     <ng-container *ngFor="let record of records">
 *         <rsp-button click="openRecordDialog( record )">Open dialog</rsp-button>
 *     </ng-container>
 *
 *     <ng-template #recordDialogTemplate let-record="record">
 *         I'm the content of your dialog.
 *         And I may also have access to data given to me via `templateData`: {{ record | json }}
 *     </ng-template>
 *     ```
 */
@Injectable()
export class DialogService {

    dialogConfig$: Observable<DialogConfig>;
    isDialogVisible$: Observable<boolean>;

    private dialogConfig: Subject<DialogConfig>       = new Subject();
    private isDialogVisible: BehaviorSubject<boolean> = new BehaviorSubject( false );

    constructor() {
        this.dialogConfig$    = this.dialogConfig.asObservable();
        this.isDialogVisible$ = this.isDialogVisible.asObservable();
    }

    openDialog( dialogConfig: DialogConfig ): void {
        this.dialogConfig.next( dialogConfig );
        this.isDialogVisible.next( true );
    }

    closeDialog(): void {
        this.isDialogVisible.next( false );
    }
}
