<ng-template #dialogHeader>
    <h2 class="standard-dialog--headline">Export Article & Assembly Files from {{ fromSelection ? 'Selection' : 'Search Result' }}</h2>
</ng-template>

<ng-template #dialogContent>
    <div class="standard-dialog--content" [rspLoadingIndicator]="isLoading">
        Include Files:
        <label *ngFor="let fileKind of fileKinds" class="export-article-assembly-files-dialog--file-kind">
            <input rspCheckbox type="checkbox" [value]="fileKind.id" [(ngModel)]="fileKind.checked" [checked]="fileKind.checked">
            {{ fileKind.name }}
        </label>

        <rsp-edit-text
            [myFormControl]="nameControl"
            [placeholder]="'Export Name'"
            class="export-article-assembly-files-dialog--file-name"
        ></rsp-edit-text>
        <p *ngIf="!isTooLarge">
            You will be notified by E-Mail once your extract is ready to download.
        </p>
        <ng-container *ngIf="isTooLarge">
            <p><strong>ATTENTION: File export is limited 3 GB.</strong></p>
            <p><strong>Please refine your {{ fromSelection ? 'selection' : 'search' }}.</strong></p>
        </ng-container>
    </div>
</ng-template>

<ng-template #dialogFooter>
    <div class="standard-dialog--footer" [rspLoadingIndicator]="isLoading">
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
            class="standard-dialog--button"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            [disabled]="!hasCheckedFileKinds() || !nameControl.valid || isTooLarge"
            (clicked)="triggerCheck()"
            class="standard-dialog--button"
        >
            Trigger Export
        </rsp-button>
    </div>
</ng-template>
