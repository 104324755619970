import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../../../../../../core/overlay/notification/notification.service';
import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { NonTradeListItem } from '../../../../../../../shared/model/nonTradeListItem';
import { MessageType } from '../../../../../../../shared/ui/messages/messages.component';
import { NonTradeItemSelectionService } from '../../../../shared/non-trade-item-selection.service';
import { ReportingExtractService } from '../../../../../../../shared/api';
import { CustomValidators } from '../../../../../../../shared/forms/validation/custom-validators';
import * as moment from 'moment-timezone';
import { CreateNonTradeItemExtract } from '../../../../../../../shared/model';

interface Sheet {
    index: number;
    name: string;
    checked: boolean;
}
@Component( {
    selector:    'rsp-export-selection-to-excel-dialog',
    templateUrl: './export-selection-to-excel-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        './export-selection-to-excel-dialog.component.scss',
    ],
} )
export class ExportSelectionToExcelDialogComponent implements OnDestroy {
    @ViewChild( 'dialogHeader', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContent', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooter', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    nameControl: UntypedFormControl = new UntypedFormControl( '', [ Validators.required, CustomValidators.isValidFilename, CustomValidators.stringNotEmpty ] );

    messageType: MessageType;
    messages: string[] = [];

    sheetsEnumToString: ( value: number ) => string = CreateNonTradeItemExtract.SheetsEnum.toString;

    sheetsEnumKeys: any[] = Object.keys(CreateNonTradeItemExtract.SheetsEnum)
                                  .filter( ( k: any ) => typeof CreateNonTradeItemExtract.SheetsEnum[k as any] === 'number');
    sheetsEnumValues: Sheet[] = this.sheetsEnumKeys.map(( k: any )  => {
        const index: number = parseInt( CreateNonTradeItemExtract.SheetsEnum[ k as any ], 10 );

        return {
            index:   index,
            name:    this.sheetsEnumToString( index ),
            checked: false,
        };
    } );

    isLoading: Subscription;
    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private reportingExtractService: ReportingExtractService,
    ) {
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    doAction(): void {
        if ( this.nameControl.valid ) {
            this.isLoading =
                this.reportingExtractService
                    .extractCreate( {
                        fileName:    this.nameControl.value as string,
                        articleIds:  this.nonTradeItemSelectionService
                                         .getSelectedArticles()
                                         .map( ( article: NonTradeListItem ) => {
                                             return article.id;
                                         } ),
                        assemblyIds: this.nonTradeItemSelectionService
                                         .getSelectedAssemblies()
                                         .map( ( assembly: NonTradeListItem ) => {
                                             return assembly.id;
                                         } ),
                        userTimeZoneInfo: moment.tz.guess(),
                        sheets:           this.sheetsEnumValues
                                              .filter( ( item: Sheet ) => item.checked )
                                              .map( ( item: Sheet ) => item.index ),
                    } )
                    .pipe( takeUntil( this.isDestroyed ) )
                    .subscribe( () => {
                        this.notificationService.success(
                            'Extract generation triggered.',
                            'Article / Assembly Extract ',
                        );
                        this.close();
                    } );
        }
    }

    open(): void {
        this.nameControl.setValue( null );
        this.dialogService.openDialog( {
            contentTemplate:  this.dialogContentTemplate,
            headlineTemplate: this.dialogHeaderTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }

    hasSelectedItems(): boolean {
        return this.nonTradeItemSelectionService.getSelectedItems().length > 0;
    }

    hasCheckedSheets(): boolean {
        return this.sheetsEnumValues.filter( ( item: Sheet ) => item.checked ).length > 0;
    }
}
