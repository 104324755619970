<div class="edit-brand-and-subbrand--brand">
    <label [for]="htmlIdFor.brand" class="edit-brand-and-subbrand--section-headline">Brand</label>
    <div class="edit-brand-and-subbrand--section-content">
        <rsp-dropdown
            [defaultValue]="brandName"
            [placeholder]="'Select Brand'"
            [inputFieldHtmlId]="htmlIdFor.brand"
            [withSuggester]="true"
            [originalItems]="brands"
            [allowClear]="true"
            (filteredItems)="setFilteredBrands( $event )"
            (selected)="setBrand( $event )"
        >
            <rsp-dropdown-item *ngFor="let brandOption of filteredBrands" [item]="brandOption">
                {{ brandOption.name }}
            </rsp-dropdown-item>
        </rsp-dropdown>
    </div>
</div>

<div class="edit-brand-and-subbrand--subbrand">
    <label [for]="htmlIdFor.subBrand" class="edit-brand-and-subbrand--section-headline">Sub-Brand</label>
    <div class="edit-brand-and-subbrand--section-content">
        <rsp-dropdown
            [defaultValue]="subBrandName"
            [placeholder]="subBrands.length ? 'Select Sub-Brand' : 'No Sub-Brands available'"
            [inputFieldHtmlId]="htmlIdFor.subBrand"
            [disabled]="!subBrands.length"
            [withSuggester]="true"
            [originalItems]="subBrands"
            [allowClear]="true"
            (filteredItems)="setFilteredSubBrands( $event )"
            (selected)="setSubBrand( $event )"
        >
            <rsp-dropdown-item *ngFor="let subBrandOption of filteredSubBrands" [item]="subBrandOption">
                {{ subBrandOption.name }}
            </rsp-dropdown-item>
        </rsp-dropdown>
    </div>
</div>
