import { Injectable, } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, } from '@angular/router';
import { Observable, of } from 'rxjs';

import { ClusterService } from './cluster.service';
import { ClusterSummary } from '../../../../shared/model/clusterSummary';
import { map } from 'rxjs/operators';


@Injectable()
export class ClusterSummaryResolver implements Resolve<ClusterSummary> {

    constructor( private clusterService: ClusterService ) {
    }

    resolve( route: ActivatedRouteSnapshot ): Observable<ClusterSummary> | Observable<any> {

        const clusterId: string = route.params[ 'id' ];

        this.clusterService.setClusterId( clusterId );

        return this.clusterService
                   .getSummary()
                   .pipe(
                       map( ( result: ClusterSummary ) => {

                           if ( result ) {
                               return result;
                           }

                           // not found - webapi-service redirects to object-not-found page
                           return of( null );
                       } ),
                   );
    }
}
