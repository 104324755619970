import { Component, Input, OnInit, } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { DisplayEditBaseComponent } from '../shared/display-edit-base.component';
import { ValidationMessagesService } from '../../validation/validation-messages.service';
import { UniqueHtmlIdService } from '../../../../core/unique-html-id.service';
import { CustomValidators } from '../../validation/custom-validators';
import { MessagesCssSubmodule, MessageType } from '../../../ui/messages/messages.component';
import { Dimension, HtmlIdForField, SizeForField, } from './display-edit-width-depth-height.model';


/**
 * Displays dimensions as Width x Height x Depth. It also supports edit mode.
 * In edit mode, the result is added to given FormGroup as a sub FormGroup
 * and contains 'width', 'height' and 'depth' properties.
 * In edit mode the input fields grow with their values (but they have a minimum width, too).
 *
 * @example
 *      <rsp-display-edit-width-depth-height
 *              [myFormGroup]="myForm"
 *              [name]="'myDimensions'"
 *              ...
 *      ></rsp-display-edit-width-depth-height>
 *
 *  myForm.value contains
 *
 *      {
 *          ...
 *          myDimensions: {
 *              width: ...,
 *              height: ...,
 *              depth: ...,
 *          },
 *          ...
 *      }
 *
 * TODO: if necessary add support for different units -> mm, cm, m etc.
 */
@Component( {
    selector:    'rsp-display-edit-width-depth-height',
    templateUrl: './display-edit-width-depth-height.component.html',
    styleUrls:   [
        './display-edit-width-depth-height.component.scss',
    ],
} )
export class DisplayEditWidthDepthHeightComponent extends DisplayEditBaseComponent implements OnInit {
    @Input()
    set width( width: string ) {
        this.setValue( 'width', width );
    }

    get width(): string {
        return this._width;
    }

    @Input()
    set height( height: string ) {
        this.setValue( 'height', height );
    }

    get height(): string {
        return this._height;
    }

    @Input()
    set depth( depth: string ) {
        this.setValue( 'depth', depth );
    }

    get depth(): string {
        return this._depth;
    }

    readonly minSize: number           = 3;
             htmlIdFor: HtmlIdForField = {};
             sizeFor: SizeForField     = {
                 widthField:  this.minSize,
                 heightField: this.minSize,
                 depthField:  this.minSize,
             };

    /**
     * Optional CSS Submodule for <rsp-messages>.
     */
    @Input() validationMessagesCssSubmodule: MessagesCssSubmodule;

    messageTypeError: MessageType = MessageType.Error;

    private _width: string;
    private _height: string;
    private _depth: string;

    constructor(
        validationMessagesService: ValidationMessagesService,
        private uniqueHtmlIdService: UniqueHtmlIdService,
    ) {
        super( validationMessagesService );
    }

    static buildFormGroup( width: number, height: number, depth: number ): UntypedFormGroup {
        return new UntypedFormGroup( {
            width:  new UntypedFormControl( width ? width.toString() : null, [ CustomValidators.isInteger, CustomValidators.positiveNumber ] ),
            height: new UntypedFormControl( height ? height.toString() : null, [ CustomValidators.isInteger, CustomValidators.positiveNumber ] ),
            depth:  new UntypedFormControl( depth ? depth.toString() : null, [ CustomValidators.isInteger, CustomValidators.positiveNumber ] ),
        } );
    }

    ngOnInit(): void {
        this.setHtmlIds();

        if ( this.isEditMode ) {
            this.startEditMode();

            this.myFormGroup
                .valueChanges
                .pipe(
                    debounceTime( 1 ),
                    takeUntil( this.isDestroyed ),
                )
                .subscribe( ( changes: { width: string, height: string, depth: string, } ) => {
                    Object.keys( changes ).forEach( ( dimension: Dimension ) => {
                        this.updateSize( dimension, changes[ dimension ] );
                    } );
                    this.updateValidationMessages( this.myFormGroup );
                } );
        }
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------
    private setValue( type: Dimension, value: string ): void {
        this[ '_' + type ] = value;

        this.updateSize( type, value );
    }

    private updateSize( type: Dimension, value: string ): void {
        const valueLength: number      = (value === null || value === undefined) ? 0 : (value + '').length;
        this.sizeFor[ type + 'Field' ] = valueLength < this.minSize ? this.minSize : valueLength;
    }


    private setHtmlIds(): void {
        this.htmlIdFor[ 'widthField' ]  = this.uniqueHtmlIdService.getUniqueHtmlId( 'width-field' );
        this.htmlIdFor[ 'heightField' ] = this.uniqueHtmlIdService.getUniqueHtmlId( 'height-field' );
        this.htmlIdFor[ 'depthField' ]  = this.uniqueHtmlIdService.getUniqueHtmlId( 'depth-field' );
    }
}
