<ng-template #dialogHeaderTemplate>
    <h2 class="standard-dialog--headline">My {{ type }}s</h2>
</ng-template>

<ng-template #dialogContentTemplate>
    <div class="standard-dialog--content">
        <rsp-global-filter [collectionView]="fileTable.collectionView"></rsp-global-filter>

        <rsp-file-table
            #fileTable
            [exportToZip]="false"
            [files]="files"
            [readOnly]="true"
            [anonymousDownload]="true"
        ></rsp-file-table>
    </div>
</ng-template>

<ng-template #dialogFooterTemplate>
    <div class="standard-dialog--footer">
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
        >
            Close
        </rsp-button>
    </div>
</ng-template>
