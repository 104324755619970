<!-- header -->
<ng-template #dialogHeaderTemplate>
    <h2 class="standard-dialog--headline">
        File Version History
    </h2>
</ng-template>

<!-- content -->
<ng-template #dialogContentTemplate>

    <div [rspLoadingIndicator]="isLoading"
         class="standard-dialog--content">

        <table
            *ngIf="collectionView && collectionView.sourceItems.length > 0"
            class="file-table" #stickyContainer>
            <thead class="file-table--head">
            <tr
                rspFixTableRow
                [rspFixTableRowContainer]="stickyContainer"
            >
                <th class="file-table--head-cell"></th>
                <th class="file-table--name file-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'name'"
                    >Name</rsp-column-header>
                </th>
                <th class="file-table--name file-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'versionNumber'"
                    >Version</rsp-column-header>
                </th>
                <th class="is-centered file-table--head-cell">
                    <rsp-column-header
                        [collectionView]="collectionView"
                        [propertyName]="'uploadedOn'"
                    >Uploaded at</rsp-column-header>
                </th>
            </tr>
            </thead>
            <tbody class="file-table--body">
            <tr
                *ngFor="let item of collectionView.items"
                class="file-table--body-row"
            >
                <td class="file-table--body-cell">
                    <div class="file-table--image-container">
                        <rsp-image
                            fileId="{{ item.file.previewImageId }}"
                            alt="{{ item.file.name }}"
                            [alignImg]="true"
                            height="65"
                            width="100"
                        ></rsp-image>
                    </div>
                </td>
                <td class="file-table--body-cell file-table--name-cell">
                    <rsp-download-link
                        [fileGuid]="fileContainerId"
                        [versionGuid]="item.file.id"
                    >
                        {{ item.file.name }}
                    </rsp-download-link>
                </td>
                <td class="file-table--body-cell">
                    {{ item.file.versionNumber }}
                </td>
                <td class="is-centered file-table--body-cell">
                    <nur-date-time [datetime]="item.file.uploadedOn"
                                  format="Comparable"
                                  [withTime]="false"
                    ></nur-date-time>
                </td>
            </tr>
            </tbody>
        </table>

    </div>

</ng-template>


<!-- footer -->
<ng-template #dialogFooterTemplate>

</ng-template>
