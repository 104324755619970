import { Component, Input, OnDestroy } from '@angular/core';
import { Observable, Subscription, } from 'rxjs';

import { ConceptAndCluster } from '../../../../../../shared/model/conceptAndCluster';
import { ConceptWithClustersItem } from './concept-with-clusters-item.model';
import { NonTradeItemType } from '../../../shared/non-trade-item-type.model';
import { ViewModelListClusterInfo } from '../../../../../../shared/model/viewModelListClusterInfo';
import { ArticleManagementArticlesService } from '../../../../../../shared/api';
import { ArticleManagementAssembliesService } from '../../../../../../shared/api';
import { EntityTypeEnum } from '../../../../../../shared/model/enum/entity-type.enum';


/**
 * Displays concept & clusters where article/assembly is used.
 * Clusters are lazy-loaded.
 */
@Component( {
    selector:    'rsp-card-concept-and-cluster',
    templateUrl: './card-concept-and-cluster.component.html',
    styleUrls:   [
        './card-concept-and-cluster.component.scss',
    ],
} )
export class CardConceptAndClusterComponent implements OnDestroy {

    @Input() currentItemId: string;
    @Input() currentItemType: NonTradeItemType;

    @Input()
    set conceptsAndClusters( value: Array<ConceptAndCluster> ) {
        this.setConceptsAndClusters( value );
    }

    items: Array<ConceptWithClustersItem> = [];

    conceptStatusEnumType: any = EntityTypeEnum.Concept;
    clusterStatusEnumType: any = EntityTypeEnum.Cluster;


    private subscription: Subscription;


    constructor(
        private articlesApi: ArticleManagementArticlesService,
        private assembliesApi: ArticleManagementAssembliesService,
    ) {
    }

    ngOnDestroy(): void {
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }

    toggleItem( item: ConceptWithClustersItem ): void {

        item.isExpanded = !item.isExpanded;

        if ( !item.clusters ) {

            // get concept's clusters from server
            item.isLoading = true;

            const api: Observable<ViewModelListClusterInfo> =
                      this.currentItemType === NonTradeItemType.Article
                          ? this.articlesApi.articlesGetClusters( this.currentItemId, item.conceptId )
                          : this.assembliesApi.assembliesGetClusters( this.currentItemId, item.conceptId );

            this.subscription = api.subscribe(
                ( clusterInfo: ViewModelListClusterInfo ) => {
                    item.clusters  = clusterInfo.data;
                    item.isLoading = false;
                },
            );
        }
    }

    getConceptUsageInfo( item: ConceptWithClustersItem ): string {
        return `${ this.getCurrentItemName() } is in ${ item.usedClusterCount } / ${ item.availableClusterCount } clusters`;
    }

    getCurrentItemName(): string {
        if ( this.currentItemType === NonTradeItemType.Article ) {
            return 'article';
        }
        else if ( this.currentItemType === NonTradeItemType.Assembly ) {
            return 'assembly';
        }

        return '';
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setConceptsAndClusters( value: Array<ConceptAndCluster> ): void {

        this.items = [];

        if ( !value ) {
            return;
        }

        // map ConceptAndCluster to ConceptWithClustersItem
        value.map( ( item: ConceptAndCluster ) => {
            this.items.push( new ConceptWithClustersItem( item ) );
        } );
    }
}
