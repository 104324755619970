import { ConceptCluster } from '../../../../../shared/model';

export class ConceptClusterCollectionViewItem {

    cluster: ConceptCluster;

    get name(): string {
        return this.cluster.name;
    }

    get availableForOrderingFrom(): Date {
        return this.cluster.availableForOrderingFrom;
    }

    get availableForOrderingUntil(): Date {
        return this.cluster.availableForOrderingUntil;
    }

    get articles(): number {
        return this.cluster.articles;
    }

    get assemblies(): number {
        return this.cluster.assemblies;
    }

    get articlesAndAssemblies(): number {
        return this.cluster.articles + this.cluster.assemblies;
    }

    get status(): string {
        return ConceptCluster.StatusEnum.toString( this.cluster.status );
    }

    constructor( conceptCluster: ConceptCluster ) {
        this.cluster = conceptCluster;
    }
}
