import { Component, Input, OnInit, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

import { ExperienceInfo } from '../../../model/experienceInfo';
import { MasterDataExperiencesService } from '../../../api/index';
import { ViewModelListExperienceInfo } from '../../../model';

@Component( {
    selector:    'rsp-edit-experiences',
    templateUrl: './edit-experiences.component.html',
    styleUrls:   [ './display-edit-experiences.component.scss' ],
} )
export class EditExperiencesComponent implements OnInit {
    @Input() experiences: ExperienceInfo[];
    @Input() isEditMode: boolean;
    @Input() myFormControl: UntypedFormControl;

    isLoading: Subscription;

    availableExperiences: ExperienceInfo[] = [];
    filteredExperiences:  ExperienceInfo[] = [];

    private loadedExperiences:    ExperienceInfo[] = [];

    constructor( private experienceApi: MasterDataExperiencesService ) {}

    addExperience( experience: ExperienceInfo ): void {
        const newExperiences: ExperienceInfo[] = this.experiences;

        newExperiences.push( experience );

        this.experiences = newExperiences;

        this.updateExperiences();
    }

    ngOnInit(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }

        this.isLoading =
            this.experienceApi
                .experiencesGetActive()
                .subscribe( ( result: ViewModelListExperienceInfo ) => {
                    this.loadedExperiences = result.data;
                    this.updateExperiences();
                } );
    }

    removeExperience( experience: ExperienceInfo ): void {
        const index: number = this.experiences.findIndex( ( old: ExperienceInfo ) => { return old.id === experience.id; } );

        if ( index !== -1 ) {
            this.experiences.splice(index, 1);
        }

        this.updateExperiences();
    }

    setFilteredExperiences( experiences: ExperienceInfo[] ): void {
        this.filteredExperiences = experiences.filter( ( experience: ExperienceInfo ) => {
            return !this.getExperienceIds().find( ( experienceId: string ) => {
                return experience.id === experienceId;
            } );
        });
    }

    private updateExperiences(): void {
        this.myFormControl.setValue( this.getExperienceIds() );
        this.availableExperiences = this.loadedExperiences.filter( ( experience: ExperienceInfo ) => {
            return !this.getExperienceIds().find( ( experienceId: string ) => {
                return experience.id === experienceId;
            } );
        });
    }

    private getExperienceIds(): string[] {
        return this.experiences.map( ( experience: ExperienceInfo ) => experience.id );
    }
}
