import { Component, } from '@angular/core';

@Component({
    selector:    'rsp-non-trade-item-history',
    templateUrl: './non-trade-item-history.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './non-trade-item-history.component.scss',
    ],
})
export class NonTradeItemHistoryComponent {
    /* tslint:disable:no-new-date */

    // MockData
    data: any = [
        {
            year: 2016,
            days: [
                {
                    weekday: 'Fri',
                    month: 'Jul',
                    day: '29',
                    events: [
                        {
                            datetime: new Date(),
                            time: '10:02',
                            area: 'Article',
                            action: 'Updated',
                            field: 'Description',
                            user: 'Joerg Kidalla',
                            details: [
                                {
                                    oldValue: 'this is old description',
                                    newValue: 'new description',
                                },

                            ],
                        },
                        {
                            datetime: new Date(),
                            time: '14:02',
                            area: 'Supply Chain',
                            action: 'Deleted',
                            field: 'Scenario 2016-02',
                            user: 'Maike Behrends',
                        },
                    ],
                },
                {
                    weekday: 'Tue',
                    month: 'Jul',
                    day: '12',
                    events: [
                        {
                            datetime: new Date(),
                            time: '10:02',
                            area: 'Article',
                            action: 'Updated',
                            field: 'Description',
                            user: 'Joerg Kidalla',
                        },
                        {
                            datetime: new Date(),
                            time: '14:02',
                            area: 'Supply Chain',
                            action: 'Deleted',
                            field: 'Scenario 2016-02',
                            user: 'Maike Behrends',
                        },
                    ],
                },
            ],
        },
        {
            year: 2015,
            days: [
                {
                    weekday: 'Thu',
                    month: 'Dec',
                    day: '05',
                    events: [
                        {
                            datetime: new Date(),
                            time: '14:02',
                            area: 'Supply Chain',
                            action: 'Deleted',
                            field: 'Scenario 2016-02',
                            user: 'Maike Behrends',
                        },
                    ],
                },
            ],
        },
    ];

    /* tslint:enable:no-new-date */

//   constructor() {}

}
