<div class="tab">
    <div class="tab--toolbar">
        <rsp-global-filter
            *ngIf="collectionView.sourceItems.length > 0"
            class="tab--toolbar-item is-left"
            [collectionView]="collectionView"
        ></rsp-global-filter>
    </div>

<div class="tab--content" [rspLoadingIndicator]="isLoading">

        <rsp-no-content *ngIf="isLoading && isLoading.closed && collectionView.sourceItems.length === 0">
            This article is not part of any assemblies.
        </rsp-no-content>

        <div *ngIf="collectionView.sourceItems.length">
            <table
                #stickyContainer
                class="assemblies-table"
            >
                <thead class="assemblies-table--head">
                    <tr
                        rspFixTableRow
                        [rspFixTableRowContainer]="stickyContainer"
                    >
                        <th class="assemblies-table--head-cell"></th>
                        <th class="assemblies-table--head-cell">
                            <rsp-column-header
                                [collectionView]="collectionView"
                                [propertyName]="'number'"
                            >Assembly N°</rsp-column-header>
                        </th>
                        <th class="assemblies-table--head-cell">
                            <rsp-column-header
                                [collectionView]="collectionView"
                                [propertyName]="'name'"
                            >Name</rsp-column-header>
                        </th>
                        <th class="assemblies-table--head-cell is-centered">
                            <rsp-column-header
                                [collectionView]="collectionView"
                                [propertyName]="'status'"
                            >Status</rsp-column-header>
                        </th>
                        <th class="assemblies-table--head-cell is-right-aligned">
                            <rsp-column-header
                                [collectionView]="collectionView"
                                [propertyName]="'quantity'"
                            >Quantity</rsp-column-header>
                        </th>
                    </tr>
                </thead>
                <tbody class="assemblies-table--body">
                    <tr *ngFor="let item of collectionView.items" class="assemblies-table--body-row">
                        <td class="assemblies-table--body-cell">
                            <rsp-image
                                fileId="{{ item.articleAssembly.previewImageId }}"
                                alt="{{ item.articleAssembly.number }} {{ item.articleAssembly.name }}"
                                height="65"
                            ></rsp-image>
                        </td>
                        <td class="assemblies-table--body-cell">
                            <rsp-link
                                [type]="'assembly'"
                                [id]="item.articleAssembly.number">
                            </rsp-link>
                        </td>
                        <td class="assemblies-table--body-cell">
                            {{item.articleAssembly.name}}
                        </td>
                        <td class="assemblies-table--body-cell is-centered">
                            <rsp-status status="{{ item.articleAssembly.status }}" [type]="statusTypeEnum" [displayMode]="'badge'"></rsp-status>
                        </td>
                        <td class="assemblies-table--body-cell is-right-aligned">
                            {{item.articleAssembly.quantity}}
                        </td>
                    </tr>
                </tbody>
            </table>
    </div>
</div>
</div>

