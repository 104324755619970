<div *ngIf="hasAnyAction" class="concept-cluster-list-actions">
    <ng-container *ngFor="let groupName of actionGroups; let groupIndex = index">
        <rsp-dropdown-group
            *ngIf="actionsForGroup.get( groupIndex ).length > 0"
            [label]="groupName"
        >
            <rsp-dropdown-item
                *ngFor="let action of actionsForGroup.get( groupIndex )"
                [item]="action"
                [disabled]="action.requiresSelection ? !( hasSelectedItems | async ) : false"
                (selectedItem)="selectAction( action )"
            >
                {{ action.label }}
            </rsp-dropdown-item>
        </rsp-dropdown-group>
    </ng-container>
</div>

<div *ngIf="!hasAnyAction" class="concept-cluster-list-actions">
    <rsp-dropdown-group
        [label]="'No actions available.'"
    >
    </rsp-dropdown-group>
</div>

<rsp-create-concept-dialog></rsp-create-concept-dialog>
<rsp-create-cluster-dialog></rsp-create-cluster-dialog>
<rsp-copy-concept-dialog></rsp-copy-concept-dialog>
