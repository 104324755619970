import { EventEmitter, Injectable, TemplateRef } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';


export type MainSlideInSize =
    'full' |
    {
        width?: number | string,
        height?: number | string,
        top?: number | string,
        bottom?: number | string,
        left?: number | string,
        right?: number | string,
    };

export interface MainSlideInConfig {

    uniqueId: string;

    /**
     * Template reference that should be rendered in slide-in.
     */
    template: TemplateRef<any>;

    size: MainSlideInSize;

    anchorElement: HTMLElement;
}


@Injectable()
export class MainSlideInService {

    /**
     * Notifies subscribers when config has been changed.
     */
    config$: Observable<MainSlideInConfig>;

    /**
     * Notifies subscribers when main-slide-in closed.
     */
    mainSlideInClosed$: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Notifies subscribers when main-slide-in opened.
     */
    mainSlideInOpened$: EventEmitter<string> = new EventEmitter<string>();


    private config: Subject<MainSlideInConfig> = new Subject<MainSlideInConfig>();


    constructor() {
        this.config$ = this.config.asObservable();
    }


    /**
     * Opens Slide-In and renders given template as content.
     */
    openSlideIn(
        uniqueId: string,
        templateRef: TemplateRef<any>,
        size: MainSlideInSize = null,
        anchorElement: HTMLElement = null,
    ): void {
        this.config.next( { uniqueId: uniqueId, template: templateRef, size: size, anchorElement: anchorElement } );
    }

    /**
     * Closes Slide-In.
     */
    closeSlideIn(): void {
        this.config.next( null );
    }
}
