<rsp-card>
    <rsp-card-headline><label [for]="inputFieldHtmlId">Weight ({{ weightType }})</label></rsp-card-headline>
    <rsp-card-content>
        <rsp-display-edit-number
            [myFormGroup]="myFormGroup"
            [name]="fieldName"
            [value]="weight"
            [isEditMode]="isEditMode"
            [inputFieldHtmlId]="inputFieldHtmlId"
        >
            <rsp-unit-kilogram *ngIf="(weight !== undefined && weight !== null) || isEditMode"></rsp-unit-kilogram>
        </rsp-display-edit-number>
    </rsp-card-content>
</rsp-card>
