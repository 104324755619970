/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { AdvancedSearchFilterItem } from './advancedSearchFilterItem';


export interface AdvancedSearchFilterGroup {
    groupOperator?: AdvancedSearchFilterGroup.GroupOperatorEnum;

    filters?: Array<AdvancedSearchFilterItem>;

}

export namespace AdvancedSearchFilterGroup {
    'use strict';
    export enum GroupOperatorEnum {
        And,
        Or,
        Not,
    }
    export namespace GroupOperatorEnum {
        export function toString( enumValue: GroupOperatorEnum ): string {
            return GroupOperatorEnum[ enumValue ];
        }
    }
}

