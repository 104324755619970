import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { Subscription } from 'rxjs';

import { DisplayEditBaseComponent } from '../../../../../../../../shared/forms/controls/shared/display-edit-base.component';
import { ValidationMessagesService } from '../../../../../../../../shared/forms/validation/validation-messages.service';
import { OptionItem } from '../../../../../../../../shared/model/optionItem';
import { MasterDataDistributionChannelStoreFormatsService } from '../../../../../../../../shared/api';
import { DistributionChannelStoreFormatListItem } from '../../../../../../../../shared/model';
import { ViewModelListDistributionChannelStoreFormatListItem } from '../../../../../../../../shared/model';

@Component( {
    selector:    'rsp-display-edit-store-formats',
    templateUrl: './display-edit-store-formats.component.html',
    styleUrls:   [
        './display-edit-store-formats.component.scss',
    ],
} )
export class DisplayEditStoreFormatsComponent extends DisplayEditBaseComponent implements OnInit, OnDestroy {
    @Input() storeFormats: OptionItem[];

    storeFormatOptions: DistributionChannelStoreFormatListItem[]         = [];
    originalStoreFormatOptions: DistributionChannelStoreFormatListItem[] = [];

    filteredStoreFormatOptions: DistributionChannelStoreFormatListItem[];

    private subscription: Subscription;


    constructor(
        validationMessagesService: ValidationMessagesService,
        private storeFormatsApi: MasterDataDistributionChannelStoreFormatsService,
    ) {
        super( validationMessagesService );
    }

    ngOnInit(): void {
        this.subscription =
            this.storeFormatsApi
                .distributionChannelStoreFormatsGetList()
                .subscribe( ( result: ViewModelListDistributionChannelStoreFormatListItem ) => {
                    this.originalStoreFormatOptions = result.data;
                    this.updateStoreFormatOptions();
                } );
    }


    ngOnDestroy(): void {
        if ( this.subscription ) {
            this.subscription.unsubscribe();
        }
    }


    setStoreFormat( storeFormat: DistributionChannelStoreFormatListItem ): void {
        const newStoreFormats: OptionItem[] = this.storeFormats;
        newStoreFormats.push( {
            name: storeFormat.name,
            id:   storeFormat.id,
        } );
        this.storeFormats = newStoreFormats;
        this.updateStoreFormatOptions();
    }

    removeStoreFormat( storeSubFormat: DistributionChannelStoreFormatListItem ): void {
        const index: number = this.storeFormats.findIndex( ( old: OptionItem ) => { return old.id === storeSubFormat.id; } );

        if ( index !== -1 ) {
            this.storeFormats.splice(index, 1);
        }

        this.updateStoreFormatOptions();
    }

    setFilteredStoreFormatOptions( items: DistributionChannelStoreFormatListItem[] ): void {
        this.filteredStoreFormatOptions = items;
    }

    private updateStoreFormatOptions(): void {
        this.storeFormatOptions =
            this.originalStoreFormatOptions.filter( ( item: DistributionChannelStoreFormatListItem ) => {
                return this.storeFormats.findIndex( ( current: DistributionChannelStoreFormatListItem ) => { return item.id === current.id; } ) === -1;
            } );

        this.myFormControl.setValue( this.storeFormats.map( ( item: OptionItem ) => item.id ) );
    }
}
