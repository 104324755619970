import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';

import { SelectableItemComponent } from '../../../../../shared/ui/lists/tile/selectable-item.component';
import { CurrentUserContext, FileContainerTile, ReferencedObject } from '../../../../../shared/model';
import { DocumentSelectionService } from '../../shared/document-selection.service';
import { DocumentStoreService } from '../../shared/document-store.service';
import { CurrentUserService } from '../../../../../core/current-user.service';


@Component( {
    selector:        'rsp-document-line',
    templateUrl:     './document-line.component.html',
    styleUrls:       [ './document-line.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class DocumentLineComponent extends SelectableItemComponent<FileContainerTile> implements OnInit {

    @Output() editClick: EventEmitter<FileContainerTile> = new EventEmitter<FileContainerTile>();

    @Output() deleteDocumentConfirmed: EventEmitter<FileContainerTile> = new EventEmitter<FileContainerTile>();

    userHasEditRight: boolean;

    constructor(
        selectionService: DocumentSelectionService,
        storeService: DocumentStoreService,
        changeDetectionRef: ChangeDetectorRef,
        private currentUserService: CurrentUserService,
    ) {
        super( selectionService, storeService, changeDetectionRef );
    }


    ngOnInit(): void {
        super.ngOnInit();
        this.currentUserService
            .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.DocumentsEdit )
            .subscribe( ( hasRight: boolean ) => {
                this.userHasEditRight = hasRight;
            } );

    }


    getVisibleForEnumAsString( value: FileContainerTile.VisibleForEnum ): string {
        return FileContainerTile.VisibleForEnum[ value ];
    }

    isEditable( item: FileContainerTile ): boolean {
        // TODO: document permission check DocumentsEdit
        return this.isDocument( item );
    }

    isDeletable( item: FileContainerTile ): boolean {
        // TODO: document permission check DocumentsEdit
        return this.isDocument( item );
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    /**
     * Document section contains Documents and FileContainers. This method checks if specified FileContainerTile
     * is a document.
     */
    private isDocument( item: FileContainerTile ): boolean {
        return item.referencedObject && item.referencedObject.type === ReferencedObject.TypeEnum.Document;
    }

}
