import { Component, Input, OnInit, } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, ValidationErrors } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';

import { UniqueHtmlIdService } from '../../../../../../core/unique-html-id.service';
import { ArticleMaterial, } from '../../../../../../shared/model/articleMaterial';
import { MaterialInfo, } from '../../../../../../shared/model/materialInfo';
import { MaterialValidator } from './display-edit-materials.validator';
import { MessagesCssSubmodule, MessageType } from '../../../../../../shared/ui/messages/messages.component';

import { isEmpty, uniq } from 'lodash';
import { ArticleMaterialComponent } from '../../../../../../shared/model/articleMaterialComponent';

export interface MaterialFormGroup extends ArticleMaterialComponent {
    name: string;
}

@Component( {
    selector:    'rsp-display-edit-materials',
    templateUrl: './display-edit-materials.component.html',
    styleUrls:   [
        './display-edit-materials.component.scss',
    ],
} )
export class DisplayEditMaterialsComponent implements OnInit {

    @Input() isEditMode: boolean;

    @Input() materialInfo: string;
    @Input() myFormGroup: UntypedFormGroup;

    /**
     * Optional CSS Submodule for the <rsp-display-edit-validation-messages> of the whole materials list.
     */
    @Input() materialListMessagesCssSubmodule: MessagesCssSubmodule;

    materialListValidationMessages: Array<string> = [];

    editMaterialMessagesCssSubmodule: typeof MessagesCssSubmodule = MessagesCssSubmodule;

    public validationMessagesForMaterial: Array<string> = [];
    public messageTypeError: MessageType = MessageType.Error;

    public materialInfoHtmlId: string = '';

    private _materials: Array<ArticleMaterial> = [];

    @Input()
    get materials(): Array<ArticleMaterial> {
        return this._materials;
    }
    set materials( value: Array<ArticleMaterial> ) {
        this._materials = value;

        if ( this.myFormGroup ) {
            this.initFormControls( value );
        }
    }

    constructor(
        private formBuilder: UntypedFormBuilder,
        private uniqueHtmlIdService: UniqueHtmlIdService,
    ) {}

    ngOnInit(): void {
        this.materialInfoHtmlId = this.uniqueHtmlIdService.getUniqueHtmlId( 'material-info' );
    }

    initMaterial( articleMaterial?: ArticleMaterial ): UntypedFormGroup {
        return this.formBuilder.group(
            {
                name:       [ articleMaterial ? articleMaterial.name        : '', ],
                percentage: [ articleMaterial ? articleMaterial.percentage  : '', MaterialValidator.validatePercentage ],
                materialId: [ articleMaterial ? articleMaterial.materialId  : '' ],
            },
            {
                validator:  MaterialValidator.validateMaterialGroup,
            },
        );
    }


    addMaterial( articleMaterial?: ArticleMaterial ): void {
        const control: UntypedFormArray = <UntypedFormArray> this.myFormGroup.controls[ 'materials' ];
        const materialCtrl: UntypedFormGroup = this.initMaterial( articleMaterial );

        control.push(materialCtrl);
    }


    updateMaterial( materialSuggestItem: MaterialInfo, control: AbstractControl ): void {
        control.get( 'name' ).setValue( materialSuggestItem ? materialSuggestItem.name : null );
        control.get( 'materialId' ).setValue( materialSuggestItem ? materialSuggestItem.id : null );
        control.get( 'name' ).markAsDirty();
    }

    removeMaterial( index: number ): void {
        const control: UntypedFormArray = <UntypedFormArray> this.myFormGroup.controls[ 'materials' ];
        control.removeAt( index );
        control.markAsDirty();
    }


    // FormArray allows just one validator, so this one has to do more than one thing
    validate( formArray: UntypedFormArray ): ValidationErrors | null {
        if ( !formArray.length ) {
            return null;
        }

        const errors: ValidationErrors = {};

        let percentage: number = 0;

        formArray.controls.forEach( ( formGroup: UntypedFormGroup ) => {
            percentage += +formGroup.get( 'percentage' ).value;
        } );

        if ( percentage > 100 ) {
            errors[ 'percentageSum' ] = true;
        }

        const values: Array<string> =
            formArray.controls
                     .filter( ( formGroup: UntypedFormGroup ) => { return formGroup.get( 'percentage' ).value; })
                     .map( ( formGroup: UntypedFormGroup ) => { return formGroup.get( 'name' ).value; } );

        if ( values.length !== uniq( values ).length ) {
            errors[ 'typeNotUnique' ] = true;
        }

        return !isEmpty( errors ) ? errors : null;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private checkAddOrRemoveMaterial(): void {
        const emptyRows: MaterialFormGroup[] =
                  this.myFormGroup
                      .controls[ 'materials' ]
                      .value
                      .filter( ( materialGroup: MaterialFormGroup ) => !materialGroup.name && !materialGroup.percentage );

        if ( !emptyRows.length ) {
            this.addMaterial();
        }
        else if ( emptyRows.length > 1 ) {
            let index: number = -1;
            this.myFormGroup
                .controls[ 'materials' ]
                .value
                .forEach( ( materialGroup: MaterialFormGroup, materialIndex: number ) => {
                    if ( !materialGroup.name && !materialGroup.percentage ) {
                        index = materialIndex;
                    }
                } );

            if ( index !== -1 ) {
                this.removeMaterial( index );
            }
        }
    }

    private initFormControls( articleMaterials: Array<ArticleMaterial> ): void {
        if ( this.myFormGroup.get( 'materials' ) ) {
            this.myFormGroup.removeControl( 'materials' );
        }

        const control: UntypedFormArray = this.formBuilder.array( [], this.validate );
        this.myFormGroup.addControl( 'materials', control );


        // in case of "clear" (via text-input directive) the changes seem to be delayed, so small delay is needed
        control.valueChanges.pipe( debounceTime(1) ).subscribe( () => {

            this.materialListValidationMessages = [];

            if ( control && control.dirty && !control.valid && control.errors ) {

                if ( control.errors[ 'percentageSum' ] ) {
                    this.materialListValidationMessages.push( 'Percentage sum should not be more than 100' );
                }
                if ( control.errors[ 'typeNotUnique' ] ) {
                    this.materialListValidationMessages.push( 'same material more than once defined' );
                }
            }

            this.checkAddOrRemoveMaterial();
        });

        if ( articleMaterials && articleMaterials.length ) {
            articleMaterials.forEach( (articleMaterial: ArticleMaterial ) => {
                this.addMaterial( articleMaterial );
            } );
        }
        this.addMaterial();
    }

}
