import { Component, Input, OnInit, } from '@angular/core';
import { StyleCulture } from '../../../../../../shared/model/styleCulture';
import { CultureInfo } from '../../../../../../shared/model/cultureInfo';
import { UntypedFormControl } from '@angular/forms';

@Component( {
    selector:    'rsp-card-languages',
    templateUrl: './card-languages.component.html',
    styleUrls:   [
        './card-languages.component.scss',
    ],
} )
export class CardLanguagesComponent implements OnInit {

    @Input() isEditMode: boolean = false;

    @Input() cultureIdsFormControl: UntypedFormControl;

    @Input()
    set languages( languages: CultureInfo[] ) {
        this.setLanguages( languages );
    }

    get languages(): CultureInfo[] {
        return this._languages;
    }

    @Input() selectedLanguages: StyleCulture[];

    // getter is available as this.getSelectedLanguages, but can not be used here directly, because it returns CultureInfo[] and TypeScript complains when the
    // getter returns a different type than the setter takes.

    @Input() statusForLanguage: Map<string, StyleCulture.StatusEnum | null> = new Map();

    unusedLanguages: CultureInfo[] = [];

    filteredUnusedLanguages: CultureInfo[];

    _languages: CultureInfo[]         = [];
    _selectedLanguages: CultureInfo[] = [];

    ngOnInit(): void {
        this.setSelectedLanguages( this.selectedLanguages.map( ( styleCulture: StyleCulture ) => styleCulture.culture ) );
    }

    deselectLanguage( languageToDeselect: CultureInfo ): void {
        const selectedLanguages: CultureInfo[] = this.getSelectedLanguages();
        const index: number = selectedLanguages.findIndex( ( selectedLanguage: CultureInfo ) => languageToDeselect.id === selectedLanguage.id );
        selectedLanguages.splice( index, 1 );

        if ( this.statusForLanguage ) {
            this.statusForLanguage.delete( languageToDeselect.code );
        }

        this.setSelectedLanguages( selectedLanguages ); // execute triggers

        this.calculateUnusedLanguages();
    }

    selectLanguage( languageToSelect: CultureInfo ): void {

        const selectedLanguages: CultureInfo[] = this.getSelectedLanguages();
        selectedLanguages.push(
            this.languages.find( ( language: CultureInfo ) => language.id === languageToSelect.id ),
        );

        if ( !this.statusForLanguage ) {
            this.statusForLanguage = new Map();
        }

        this.statusForLanguage.set( languageToSelect.code, null ); // new languages have no state yet.

        this.setSelectedLanguages( selectedLanguages ); // execute triggers
    }

    selectAllLanguages(): void {
        this.filteredUnusedLanguages.forEach( ( language: CultureInfo ) => {
            this.selectLanguage( language );
        } );
        this.calculateUnusedLanguages();
    }

    setFilteredUnusedLanguages( items: CultureInfo[] ): void {
        this.filteredUnusedLanguages = items;
    }

    private setLanguages( languages: CultureInfo[] ): void {

        if ( languages ) {
            this._languages = JSON.parse( JSON.stringify( languages ) );
        }
        else {
            this._languages = null;
        }

        this.calculateUnusedLanguages();
    }

    private setSelectedLanguages( languages: CultureInfo[] ): void {
        this._selectedLanguages = JSON.parse( JSON.stringify( languages ) );
        this._selectedLanguages.sort( ( aLanguage: CultureInfo, bLanguage: CultureInfo ) => {
            if ( aLanguage.code < bLanguage.code ) {
                return -1;
            }

            if ( aLanguage.code > bLanguage.code ) {
                return 1;
            }

            return 0;
        } );

        this.calculateUnusedLanguages();

        this.cultureIdsFormControl.setValue( this._selectedLanguages.map( ( language: CultureInfo ) => language.id ) );
    }

    private getSelectedLanguages(): CultureInfo[] {
        return this._selectedLanguages;
    }

    private calculateUnusedLanguages(): void {
        const allLanguages: CultureInfo[]      = this.languages;
        const selectedLanguages: CultureInfo[] = this.getSelectedLanguages();

        const unusedLanguages: CultureInfo[] = [];

        if ( allLanguages ) {
            allLanguages.forEach(
                ( language: CultureInfo ) => {
                    const isLanguageSelected: boolean = selectedLanguages.findIndex(
                        ( selectedlanguage: CultureInfo ) => selectedlanguage.id === language.id,
                    ) > -1;

                    if ( !isLanguageSelected ) {
                        unusedLanguages.push( language );
                    }
                },
            );
        }

        this.unusedLanguages = unusedLanguages;
    }
}
