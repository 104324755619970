<div class="l-two-columns-1200" [rspLoadingIndicator]="isLoading">

    <!-- Left column -->
    <div class="l-two-columns-1200--column">
        <rsp-card>
            <rsp-card-headline>
                <label [for]="htmlIdFor['nameField']">Name</label>
            </rsp-card-headline>
            <rsp-card-content>
                <rsp-display-edit-text
                    [value]="graphic.name"
                    [myFormGroup]="form"
                    [isEditMode]="isEditMode"
                    [name]="'name'"
                    [inputFieldHtmlId]="htmlIdFor['nameField']"
                ></rsp-display-edit-text>
            </rsp-card-content>
        </rsp-card>
        <rsp-card>
            <rsp-card-headline>Graphic Preview</rsp-card-headline>
            <rsp-card-content>
                <ng-container *ngIf="!isEditMode">
                    <ng-container *ngIf="graphic.templateFile; else noTemplateFile">
                        <p>
                            Uploaded on
                            <nur-date-time
                                [datetime]="graphic.templateFile.uploadedOn"
                                [format]="'Comparable'"
                                [withTime]="true"
                                [withTimezone]="true"
                            ></nur-date-time>
                        </p>
                        <div class="graphics--preview-container">
                            <rsp-image
                                [alt]="graphic.templateFile.name"
                                [fileId]="graphic.templateFile?.previewImageId"
                                [height]="130"
                                [width]="200"
                                [alignImg]="true"
                            >
                            </rsp-image>
                        </div>
                        <rsp-download-link
                            [fileGuid]="graphic.templateFile.id"
                            class="graphics--download"
                        >
                            {{graphic.templateFile.name}}
                        </rsp-download-link>
                    </ng-container>
                    <ng-template #noTemplateFile>
                         -
                    </ng-template>
                </ng-container>

                <div *ngIf="isEditMode && previewImageId" class="graphics--preview-container">
                    <rsp-image
                        [fileId]="previewImageId"
                        [alignImg]="true"
                        [alt]="'Graphic Preview'"
                        [height]="130"
                        [width]="200"
                    >
                    </rsp-image>
                </div>

                <rsp-upload-single-file
                    *ngIf="isEditMode"
                    [fileKinds]="fileKinds"
                    (fileUpdated)="setTemplateFile( $event )"
                >
                    Drag and drop file here or simply click...
                </rsp-upload-single-file>
            </rsp-card-content>
        </rsp-card>
        <rsp-card>
            <rsp-card-headline>
                <label [for]="htmlIdFor['noteField']">Note</label>
            </rsp-card-headline>
            <rsp-card-content>
                <rsp-display-edit-text-multiline
                    [isEditMode]="isEditMode"
                    [value]="graphic.note"
                    [myFormGroup]="form"
                    [name]="'note'"
                    [inputFieldHtmlId]="htmlIdFor['noteField']"
                >
                </rsp-display-edit-text-multiline>
            </rsp-card-content>
        </rsp-card>
    </div>

    <!-- Right column -->
    <div class="l-two-columns-1200--column">
        <rsp-card>
            <rsp-card-headline>
                Article Graphic Area (W/H)
            </rsp-card-headline>
            <rsp-card-content>
                <rsp-display-edit-graphic-dimensions
                    [isEditMode]="isEditMode"
                    [myFormGroup]="form"
                    [namePrefix]="'graphicAreaDimensions'"
                    [dimensions]="graphic.graphicArea"
                ></rsp-display-edit-graphic-dimensions>
            </rsp-card-content>
        </rsp-card>
        <rsp-card>
            <rsp-card-headline>Visual Graphic Area (W/H)</rsp-card-headline>
            <rsp-card-content>
                <rsp-display-edit-graphic-dimensions
                    [isEditMode]="isEditMode"
                    [myFormGroup]="form"
                    [namePrefix]="'visiblePrintAreaDimensions'"
                    [dimensions]="graphic.visiblePrintArea"
                ></rsp-display-edit-graphic-dimensions>
            </rsp-card-content>
        </rsp-card>
        <rsp-card>
            <rsp-card-headline>Material / Thickness</rsp-card-headline>
            <rsp-card-content>
                <div class="graphics--material">
                    <ng-container *ngIf="!isEditMode">
                        {{graphic.material?.name || '-'}}
                    </ng-container>
                    <rsp-material-suggester
                        *ngIf="isEditMode"
                        [displayValue]="materialName"
                        (selected)="selectedMaterial( $event )"
                    >
                    </rsp-material-suggester>
                    <rsp-display-edit-number
                        [value]="graphic.thickness"
                        [myFormGroup]="form"
                        [isEditMode]="isEditMode"
                        [name]="'thickness'"
                        [hideValidationMessages]="true"
                        class="graphics--material-thickness"
                    >
                    </rsp-display-edit-number>
                    <rsp-unit-millimeter *ngIf="graphic.thickness || isEditMode" class="graphics--material-unit"></rsp-unit-millimeter>
                </div>
                <rsp-messages *ngIf="isEditMode" [messages]="thicknessValidationMessages" [type]="messageTypeError"></rsp-messages>
            </rsp-card-content>
        </rsp-card>
    </div>
</div>

