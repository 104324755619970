<div class="tab">
    <div class="tab--toolbar">
        <rsp-form-buttons
            *ngIf="actions.length > 0"
            [isEditMode]="isEditMode"
            [myFormGroup]="form"
            [actions]="actions"
            [disablePrimaryEditButton]="isLoadingSave"
            (actionButtonClick)="startEditMode()"
            (primaryEditButtonClick)="submitEditForm()"
            (secondaryEditButtonClick)="endEditMode( true )"
            (dropDownActionClicked)="triggerAction( $event )"
            class="tab--toolbar-item is-right"
        ></rsp-form-buttons>
    </div>

    <div
        *ngIf="userDetails"
        [rspLoadingIndicator]="isLoading"
        class="tab--content"
    >
        <section class="user-details--readonly-data">
            <div class="user-details--readonly-data-explanation-area">
                <rsp-info-tooltip [tooltipConfig]="{ tooltipPosition: { tooltipX: 'end', tooltipY: 'top' } }">
                    <p class="user-details--tooltip-content">
                        Information here is maintained in the adidas internal user management system and therefore can not be changed.
                    </p>
                </rsp-info-tooltip>
            </div>

            <dl class="user-details--readonly-data-list">
                <div class="user-details--readonly-data-list-item">
                    <dt><rsp-supplementary-caption>Global <abbr>ID</abbr></rsp-supplementary-caption></dt>
                    <dd class="user-details--readonly-data-list-item-value">{{ userDetails.globalId }}</dd>
                </div>

                <div class="user-details--readonly-data-list-item">
                    <dt><rsp-supplementary-caption>First Name</rsp-supplementary-caption></dt>
                    <dd class="user-details--readonly-data-list-item-value">{{ userDetails.firstName }}</dd>
                </div>

                <div class="user-details--readonly-data-list-item">
                    <dt><rsp-supplementary-caption>Family Name</rsp-supplementary-caption></dt>
                    <dd class="user-details--readonly-data-list-item-value">{{ userDetails.familyName }}</dd>
                </div>

                <div class="user-details--readonly-data-list-item">
                    <dt><rsp-supplementary-caption>E-Mail</rsp-supplementary-caption></dt>
                    <dd class="user-details--readonly-data-list-item-value">{{ userDetails.email }}</dd>
                </div>

                <div class="user-details--readonly-data-list-item">
                    <dt><rsp-supplementary-caption>Last Login</rsp-supplementary-caption></dt>
                    <dd class="user-details--readonly-data-list-item-value">
                        <nur-date-time [datetime]="userDetails.lastLoginOn" [withTime]="true" [format]="'HumanReadable'"></nur-date-time>
                    </dd>
                </div>

                <div class="user-details--readonly-data-list-item">
                    <dt><rsp-supplementary-caption>Registered since</rsp-supplementary-caption></dt>
                    <dd class="user-details--readonly-data-list-item-value">
                        <nur-date-time [datetime]="userDetails.registeredOn" [withTime]="true" [format]="'HumanReadable'"></nur-date-time>
                    </dd>
                </div>

                <div class="user-details--readonly-data-list-item">
                    <dt><rsp-supplementary-caption>Status</rsp-supplementary-caption></dt>
                    <dd class="user-details--readonly-data-list-item-value">
                        <rsp-status
                            [status]="userDetails.status"
                            [type]="statusEnumType"
                            [displayMode]="'text-and-badge'"
                        ></rsp-status>
                    </dd>
                </div>
            </dl>
        </section>

        <div class="l-two-columns-1200">
            <div class="l-two-columns-1200--column">
                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline>
                            <label *ngIf="isEditMode" [for]="htmlIdFor.get( 'profile-image-field' )">Profile image</label>
                            <ng-container *ngIf="!isEditMode">Profile image</ng-container>
                        </rsp-card-headline>
                        <rsp-card-content>
                            <rsp-display-edit-user-profile-image
                                [isEditMode]="isEditMode"
                                [myFormControl]="isEditMode ? form.get( 'profileImageFileId' ) : null"
                                [user]="userDetails"
                                [inputFieldHtmlId]="htmlIdFor.get( 'profile-image-field' )"
                                (fileUpdated)="setProfileImageFileContainerId( $event )"
                            >
                                click here or drop file to upload a new profile image
                            </rsp-display-edit-user-profile-image>
                        </rsp-card-content>
                    </rsp-card>

                    <rsp-card>
                        <rsp-card-headline>
                            <label *ngIf="isEditMode" [for]="htmlIdFor.get( 'module-groups-fieldset' )">Module groups</label>
                            <ng-container *ngIf="!isEditMode">Module groups</ng-container>

                            <rsp-info-tooltip>
                                <p class="user-details--tooltip-content">These module groups define the access rights.</p>
                            </rsp-info-tooltip>
                        </rsp-card-headline>
                        <rsp-card-content>
                            <ol
                                class="user-details--module-groups-list"
                                *ngIf="userDetails.moduleGroups.length > 0"
                            >
                                <li
                                    *ngFor="let moduleGroup of userDetails.moduleGroups"
                                    class="user-details--module-group"
                                    [class.is-waiting-for-approval]="moduleGroup.isWaitingForApproval"
                                >
                                    {{ moduleGroup.name }}

                                    <ng-container *ngIf="moduleGroup.isWaitingForApproval">
                                        (waiting for approval)
                                    </ng-container>
                                </li>
                            </ol>

                            <p *ngIf="userDetails.moduleGroups.length === 0">
                                No module groups assigned, yet.
                            </p>

                            <rsp-button
                                *ngIf="mode === 'my-profile' && isEditMode"
                                [type]="'button'"
                                (clicked)="openModuleGroupDialog()"
                            >
                                Request additional module groups
                            </rsp-button>
                        </rsp-card-content>
                    </rsp-card>
                </div>
            </div>

            <div class="l-two-columns-1200--column">
                <div class="l-two-columns-1200--column-content-gutter-item">
                    <rsp-card>
                        <rsp-card-headline>
                            <label *ngIf="isEditMode" [for]="htmlIdFor.get( 'phone-number-field' )">Phone</label>
                            <ng-container *ngIf="!isEditMode">Phone</ng-container>
                        </rsp-card-headline>
                        <rsp-card-content>
                            <rsp-display-edit-phone-number
                                [myFormGroup]="form"
                                [name]="'phoneNumber'"
                                [value]="form.get( 'phoneNumber' ).value"
                                [isEditMode]="isEditMode"
                                [inputFieldHtmlId]="htmlIdFor.get( 'phone-number-field' )"
                            ></rsp-display-edit-phone-number>
                        </rsp-card-content>
                    </rsp-card>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #deleteUserConfirmDialogHeadline>
    <div class="standard-dialog--headline">
        Confirm User Deletion
    </div>
</ng-template>

<ng-template #deleteUserConfirmDialogContent>
    <div class="standard-dialog--content">
        Do you really want to delete the user
        <em><rsp-user-name [user]="userDetails"></rsp-user-name></em>?
    </div>
</ng-template>

<ng-template #deleteUserConfirmDialogFooter>
    <div class="standard-dialog--footer">
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="closeUserDeleteConfirmDialog()"
            class="standard-dialog--button"
        >Cancel</rsp-button>

        <rsp-button
            [type]="'button'"
            [submodule]="'primary'"
            [disabled]="isLoadingDelete"
            [isLoading]="isLoadingDelete"
            (clicked)="deleteUser()"
            class="standard-dialog--button"
        >Delete</rsp-button>
    </div>
</ng-template>

<rsp-user-module-groups-dialog
    *ngIf="userDetails && allModuleGroups"
    #moduleGroupsDialog
    [userModuleGroups]="userDetails.moduleGroups"
    [allModuleGroups]="allModuleGroups"
    [userEditMode]="mode"
    [userId]="userDetails.id"
    (changeModuleGroups)="updateUserModuleGroups( $event )"
></rsp-user-module-groups-dialog>
