import { Component,                   } from '@angular/core';

import { StorageService,              } from '../../../../../../core/storage.service';
import { FacetValue,                  } from '../../../../../model/facetValue';
import { FacetTypeSelectComponent,    } from '../facet-type-select.component';
import {
    FacetTypeSelectComponent as FacetTypeSelectComponentInterface,
    FacetTypeSelect,
} from '../facet-type-select.model';
import { FacetService,                } from '../../facet.service';
import { facetAnimations,             } from '../../facet.animations';
import { VerticalScrollService } from '../../../../lists/vertical-scroll/vertical-scroll.service';
import { FacetItem } from '../../../../../model/facetItem';
import { FacetFilterItem } from '../../../../../model/facetFilterItem';


/**
 * Generates a facet for the `Multiselect`-type.
 *
 * ```html
 * <rsp-facet-type-multiselect facet="Facet" (valueChanged)="yourOnValueChanged()"></rsp-facet-type-multiselect>
 * ```
 */
// The commented stuff is my example implementation of inheriting stuff from the @Component decorator of the parent
// component. This works, but not in unit tests, see http://wijmo.com/topic/wjbase-ng2utils-doesnt-work-in-unit-tests/
@Component( {
    selector:    'rsp-facet-type-multiselect',
    templateUrl: '../facet-type-select.component.html',
    // template:  ( <Component> wjBase.Ng2Utils.getTypeAnnotation( FacetTypeSelectComponent, Component ) ).template,
    styleUrls:   [
        './facet-type-multiselect.component.scss',
    ],

    inputs:     [ 'facet' ], // tslint:disable-line:no-inputs-metadata-property
    outputs:    [ 'valueChanged' ], // tslint:disable-line:no-outputs-metadata-property
    animations: facetAnimations,
} )
export class FacetTypeMultiselectComponent extends FacetTypeSelectComponent implements FacetTypeSelectComponentInterface {

    type:          FacetTypeSelect = FacetItem.TypeEnum.Multiselect;
    cssModuleName: string          = 'facet-type-multiselect';


    constructor(
        storageService: StorageService,
        verticalScrollService: VerticalScrollService,
        private facetService: FacetService,
    ) {
        super( storageService, verticalScrollService );
    }

    onValueClick( facetValue: FacetValue, event: MouseEvent ): void {
        facetValue.isSelected = !facetValue.isSelected;
        this.onValueChanged( facetValue );
    }

    onValueChanged( changedValue: FacetValue ): void {
        // no need to do something with changedValue, because [ngModel] in the template already updated this.facet for us.
        const facetFilter: FacetFilterItem = this.facetService.getFacetFilterForMultiselectFacet( this.facet );

        this.valueChanged.emit( { facetName: this.facet.name, facetFilter: facetFilter, } );
    }

}
