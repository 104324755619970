import { Component, OnDestroy, ViewChild } from '@angular/core';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AddOrRemoveClusterDialogComponent } from './actions/add-or-remove-cluster-dialog/add-or-remove-cluster-dialog.component';
import { CreateClusterDialogComponent } from '../../../shared/actions/create-cluster-dialog/create-cluster-dialog.component';
import { CreateAssemblyDialogComponent } from './actions/create-assembly-dialog/create-assembly-dialog.component';
import { NonTradeItemSelectionService } from '../../shared/non-trade-item-selection.service';
import { NonTradeListItem } from '../../../../../shared/model/nonTradeListItem';
import { SetEngineeringPartnerDialogComponent } from './actions/set-engineering-partner-dialog/set-engineering-partner-dialog.component';
import { MainSlideInService } from '../../../../../core/overlay/main-slide-in/main-slide-in.service';
import { CreateArticleDialogComponent } from './actions/create-article-dialog/create-article-dialog.component';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { EnumConverterService, EnumValue } from '../../../../../shared/utils/enum/enum-converter.service';
import { Action } from '../../../../../shared/ui/lists/actions/list-actions.model';
import { CopyAssemblyDialogComponent } from './actions/copy-assembly-dialog/copy-assembly-dialog.component';
import { AddSelectionToAssemblyDialogComponent } from './actions/add-selection-to-assembly-dialog/add-selection-to-assembly-dialog.component';
import { RemoveSelectionFromAssemblyDialogComponent } from './actions/remove-selection-from-assembly-dialog/remove-selection-from-assembly-dialog.component';
import { ExportSelectionToExcelDialogComponent } from './actions/export-selection-to-excel-dialog/export-selection-to-excel-dialog.component';
import { ExportSearchResultToExcelDialogComponent } from './actions/export-search-result-to-excel-dialog/export-search-result-to-excel-dialog.component';
import { ExportSearchResultToAribaDialogComponent } from './actions/export-search-result-to-ariba-dialog/export-search-result-to-ariba-dialog.component';
import { CurrentUserContext } from '../../../../../shared/model';
import { ExportArticleAssemblyFilesDialogComponent } from './actions/export-article-assembly-files-dialog/export-article-assembly-files-dialog.component';

import { flatten } from 'lodash';
import { CreateAssemblyFromExcelDialogComponent } from './actions/create-assembly-from-excel-dialog/create-assembly-from-excel-dialog.component';
import { SetArticleStatusDialogComponent } from './actions/set-article-status-dialog/set-article-status-dialog.component';
import { SetAssemblyStatusDialogComponent } from './actions/set-assembly-status-dialog/set-assembly-status-dialog.component';
import { ExportSelectionToAribaDialogComponent } from './actions/export-selection-to-ariba-dialog/export-selection-to-ariba-dialog.component';
import {
    CreateArticleFromExcelDialogComponent,
} from './actions/create-article-from-excel-dialog/create-article-from-excel-dialog.component';

enum NonTradeListItemActions {
    CreateArticle,
    CreateArticleFromExcel,
    CreateAssembly,
    CreateAssemblyFromSelection,
    CreateAssemblyFromExcel,
    CreateCluster,
    CreateClusterFromSelection,
    AddSelectionToAssembly,
    AddSelectionToCluster,
    RemoveSelectionFromCluster,
    RemoveSelectionFromAssembly,
    SetEngineeringPartnerForSelectedArticles,
    SetArticleStatusForSelectedArticles,
    SetAssemblyStatusForeSelectedAssemblies,
    CopyAssembly,
    ExportSelectionToExcel,
    ExportSearchResultToExcel,
    ExportSearchResultToAriba,
    ExportSelectionToAriba,
    ExportSelectionArticleAssemblyFiles,
    ExportSearchResultArticleAssemblyFiles,
}

// Note: The order in this enum is order in UI.
enum NonTradeItemsActionGroup {
    Export,
    Article,
    Assembly,
    Cluster,
}

namespace NonTradeItemsActionGroup {
    export function toString( enumValue: NonTradeItemsActionGroup ): string {
        return NonTradeItemsActionGroup[ enumValue ];
    }
}

export interface NonTradeItemAction extends Action {
    dialog: NonTradeListItemActions;
    selectedArticlesRequired?: boolean;
    selectedReadyToOrderRequired?: boolean;
}

/**
 * Handles actions for non-trade-item list.
 */
@Component( {
    selector:    'rsp-non-trade-item-actions-list',
    templateUrl: './non-trade-item-actions-list.component.html',
    styleUrls:   [
        './non-trade-item-actions-list.component.scss',
    ],
} )
export class NonTradeItemActionsListComponent implements OnDestroy {
    @ViewChild( CreateArticleDialogComponent, { static: true } ) createArticleDialog: CreateArticleDialogComponent;
    // tslint:disable-next-line:max-line-length
    @ViewChild( CreateArticleFromExcelDialogComponent, { static: true } ) importArticleMetadataFromExcelDialog: CreateArticleFromExcelDialogComponent;
    @ViewChild( CreateAssemblyDialogComponent, { static: true } ) createAssemblyDialog: CreateAssemblyDialogComponent;
    @ViewChild( CreateAssemblyFromExcelDialogComponent, { static: true } ) createAssemblyFromExcelDialog: CreateAssemblyFromExcelDialogComponent;
    @ViewChild( CopyAssemblyDialogComponent, { static: true } ) copyAssemblyDialog: CopyAssemblyDialogComponent;
    @ViewChild( CreateClusterDialogComponent, { static: true } ) createClusterDialog: CreateClusterDialogComponent;
    @ViewChild( AddOrRemoveClusterDialogComponent, { static: true } ) addOrRemoveClusterDialog: AddOrRemoveClusterDialogComponent;
    @ViewChild( SetEngineeringPartnerDialogComponent, { static: true } ) setEngineeringPartnerDialog: SetEngineeringPartnerDialogComponent;
    @ViewChild( SetArticleStatusDialogComponent, { static: true } ) setArticleStatusDialog: SetArticleStatusDialogComponent;
    @ViewChild( SetAssemblyStatusDialogComponent, { static: true } ) setAssemblyStatusDialog: SetAssemblyStatusDialogComponent;
    @ViewChild( AddSelectionToAssemblyDialogComponent, { static: true } ) addSelectionToAssemblyDialog: AddSelectionToAssemblyDialogComponent;
    @ViewChild( RemoveSelectionFromAssemblyDialogComponent, { static: true } ) removeSelectionFromAssemblyDialog: RemoveSelectionFromAssemblyDialogComponent;
    @ViewChild( ExportSelectionToExcelDialogComponent, { static: true } ) exportSelectionToExcelDialog: ExportSelectionToExcelDialogComponent;
    @ViewChild( ExportSearchResultToExcelDialogComponent, { static: true } ) exportSearchResultToExcelDialog: ExportSearchResultToExcelDialogComponent;
    @ViewChild( ExportSearchResultToAribaDialogComponent, { static: true } ) exportSearchResultToAribaDialog: ExportSearchResultToAribaDialogComponent;
    @ViewChild( ExportSelectionToAribaDialogComponent, { static: true } ) exportSelectionToAribaDialog: ExportSelectionToAribaDialogComponent;
    @ViewChild( ExportArticleAssemblyFilesDialogComponent, { static: true } ) exportArticleAssemblyFilesDialog: ExportArticleAssemblyFilesDialogComponent;

    /**
     * Actions definition.
     */
    actionGroups: string[]                                               = [];
    actionsForGroup: Map<NonTradeItemsActionGroup, NonTradeItemAction[]> = new Map();
    hasAnyAction: boolean                                                = false;

    hasSelectedItems: boolean;
    hasSelectedArticles: boolean;
    hasReadyToOrderItems: boolean;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private mainSlideInService: MainSlideInService,
        private currentUserService: CurrentUserService,
    ) {
        this.nonTradeItemSelectionService
            .selectedItems$
            .pipe(
                distinctUntilChanged(),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( items: NonTradeListItem[] ) => {
                this.hasSelectedItems     = items.length > 0;
                this.hasSelectedArticles  = items.filter( ( item: NonTradeListItem ) => item.type === NonTradeListItem.TypeEnum.Article ).length > 0;
                this.hasReadyToOrderItems = items.filter( ( item: NonTradeListItem ) => item.isReadyToOrder ).length > 0;
            } );

        this.buildActions();
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    selectAction( action: NonTradeItemAction ): void {
        switch ( action.dialog ) {
            case NonTradeListItemActions.CreateArticle:
                this.createArticleDialog.open();
                break;

            case NonTradeListItemActions.CreateArticleFromExcel:
                this.importArticleMetadataFromExcelDialog.open();
                break;

            case NonTradeListItemActions.SetEngineeringPartnerForSelectedArticles:
                this.setEngineeringPartnerDialog.open();
                break;

            case NonTradeListItemActions.SetArticleStatusForSelectedArticles:
                this.setArticleStatusDialog.open();
                break;

            case NonTradeListItemActions.SetAssemblyStatusForeSelectedAssemblies:
                this.setAssemblyStatusDialog.open();
                break;

            case NonTradeListItemActions.CreateAssembly:
                this.createAssemblyDialog.open();
                break;

            case NonTradeListItemActions.CreateAssemblyFromExcel:
                this.createAssemblyFromExcelDialog.open();
                break;

            case NonTradeListItemActions.CopyAssembly:
                this.copyAssemblyDialog.open();
                break;

            case NonTradeListItemActions.CreateAssemblyFromSelection:
                this.createAssemblyDialog.open( true );
                break;

            case NonTradeListItemActions.AddSelectionToAssembly:
                this.addSelectionToAssemblyDialog.open();
                break;

            case NonTradeListItemActions.RemoveSelectionFromAssembly:
                this.removeSelectionFromAssemblyDialog.open();
                break;

            case NonTradeListItemActions.CreateCluster:
                this.createClusterDialog.open();
                break;

            case NonTradeListItemActions.CreateClusterFromSelection:
                this.createClusterDialog.open( true );
                break;

            case NonTradeListItemActions.AddSelectionToCluster:
                this.addOrRemoveClusterDialog.open();
                break;

            case NonTradeListItemActions.RemoveSelectionFromCluster:
                this.addOrRemoveClusterDialog.open( true );
                break;

            case NonTradeListItemActions.ExportSelectionToExcel:
                this.exportSelectionToExcelDialog.open();
                break;

            case NonTradeListItemActions.ExportSearchResultToExcel:
                this.exportSearchResultToExcelDialog.open();
                break;

            case NonTradeListItemActions.ExportSearchResultToAriba:
                this.exportSearchResultToAribaDialog.open();
                break;

            case NonTradeListItemActions.ExportSelectionToAriba:
                this.exportSelectionToAribaDialog.open();
                break;

            case NonTradeListItemActions.ExportSelectionArticleAssemblyFiles:
                this.exportArticleAssemblyFilesDialog.open( true );
                break;

            case NonTradeListItemActions.ExportSearchResultArticleAssemblyFiles:
                this.exportArticleAssemblyFilesDialog.open( );
                break;

            default:
                break;
        }

        this.closeSlideIn();
    }

    closeSlideIn(): void {
        this.mainSlideInService.closeSlideIn();
    }

    private buildActions(): void {
        this.actionGroups = [];

        EnumConverterService.convertToEnumValueList( NonTradeItemsActionGroup, 'index' ).forEach( ( enumValue: EnumValue ) => {
            this.actionGroups.push( enumValue.name );
            this.actionsForGroup.set( enumValue.index, [] );
        } );

        this.currentUserService
            .getCurrentUserAccessRightSet()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesExtract ) ) {
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Export ).push( {
                        label:             'Export selection to Excel',
                        dialog:            NonTradeListItemActions.ExportSelectionToExcel,
                        requiresSelection: true,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Export ).push( {
                        label:             'Export search result to Excel',
                        dialog:            NonTradeListItemActions.ExportSearchResultToExcel,
                        requiresSelection: false,
                    } );
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Export ).push( {
                        label:             'Export search result to Ariba',
                        dialog:            NonTradeListItemActions.ExportSearchResultToAriba,
                        requiresSelection: false,
                    } );
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Export ).push( {
                        label:             'Export selection to Ariba',
                        dialog:            NonTradeListItemActions.ExportSelectionToAriba,
                        requiresSelection: true,
                    } );
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesFilesExport ) ) {
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Export ).push( {
                        label:             'Export Article & Assembly Files from Selection',
                        dialog:            NonTradeListItemActions.ExportSelectionArticleAssemblyFiles,
                        requiresSelection: true,
                    } );
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Export ).push( {
                        label:             'Export Article & Assembly Files from Search Result',
                        dialog:            NonTradeListItemActions.ExportSearchResultArticleAssemblyFiles,
                        requiresSelection: false,
                    } );
                    this.hasAnyAction = true;
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesArticleCreate ) ) {
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Article ).push( {
                        label:             'Create Article',
                        dialog:            NonTradeListItemActions.CreateArticle,
                        requiresSelection: false,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Article ).push( {
                        label:             'Create or update Articles from Excel',
                        dialog:            NonTradeListItemActions.CreateArticleFromExcel,
                        requiresSelection: false,
                    } );

                    this.hasAnyAction = true;
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesArticleDetailsEdit ) ) {
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Article ).push( {
                        label:                    'Set Engineering Partner for selected Articles',
                        dialog:                   NonTradeListItemActions.SetEngineeringPartnerForSelectedArticles,
                        requiresSelection:        false,
                        selectedArticlesRequired: true,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Article ).push( {
                        label:                    'Set Status for selected Articles',
                        dialog:                   NonTradeListItemActions.SetArticleStatusForSelectedArticles,
                        requiresSelection:        false,
                        selectedArticlesRequired: true,
                    } );

                    this.hasAnyAction = true;
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyCreate ) ) {
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Assembly ).push( {
                        label:             'Create Assembly',
                        dialog:            NonTradeListItemActions.CreateAssembly,
                        requiresSelection: false,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Assembly ).push( {
                        label:             'Create or update Assemblies from Excel',
                        dialog:            NonTradeListItemActions.CreateAssemblyFromExcel,
                        requiresSelection: false,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Assembly ).push( {
                        label:             'Create Assembly from selection',
                        dialog:            NonTradeListItemActions.CreateAssemblyFromSelection,
                        requiresSelection: true,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Assembly ).push( {
                        label:             'Copy an Assembly',
                        dialog:            NonTradeListItemActions.CopyAssembly,
                        requiresSelection: false,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Assembly ).push( {
                        label:                    'Set Status for selected Assemblies',
                        dialog:                   NonTradeListItemActions.SetAssemblyStatusForeSelectedAssemblies,
                        requiresSelection:        false,
                    } );

                    this.hasAnyAction = true;
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyComponentsEdit ) ) {
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Assembly ).push( {
                        label:                    'Add selection to Assembly',
                        dialog:                   NonTradeListItemActions.AddSelectionToAssembly,
                        requiresSelection:        false,
                        selectedArticlesRequired: true,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Assembly ).push( {
                        label:                    'Remove selection from Assembly',
                        dialog:                   NonTradeListItemActions.RemoveSelectionFromAssembly,
                        requiresSelection:        false,
                        selectedArticlesRequired: true,
                    } );

                    this.hasAnyAction = true;
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterCreate ) ) {
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Cluster ).push( {
                        label:             'Create Cluster',
                        dialog:            NonTradeListItemActions.CreateCluster,
                        requiresSelection: false,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Cluster ).push( {
                        label:             'Create Cluster from selection',
                        dialog:            NonTradeListItemActions.CreateClusterFromSelection,
                        requiresSelection: true,
                    } );

                    this.hasAnyAction = true;
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterArticleAssemblyAssignmentsEdit ) ) {
                    this.actionsForGroup.get( NonTradeItemsActionGroup.Cluster ).push( {
                        label:             'Add selection to Cluster',
                        dialog:            NonTradeListItemActions.AddSelectionToCluster,
                        requiresSelection: true,
                    } );

                    this.actionsForGroup.get( NonTradeItemsActionGroup.Cluster ).push( {
                        label:             'Remove selection from Cluster',
                        dialog:            NonTradeListItemActions.RemoveSelectionFromCluster,
                        requiresSelection: true,
                    } );

                    this.hasAnyAction = true;
                }

                // set selection available by count of actionGroups where an item requiresSelection
                this.nonTradeItemSelectionService.setSelectionAvailable(
                    flatten( Array.from( this.actionsForGroup.values() ) as NonTradeItemAction[][] )
                        .filter( ( item: NonTradeItemAction ) => item.requiresSelection || item.selectedArticlesRequired || item.selectedReadyToOrderRequired )
                        .length > 0,
                );
            } );
    }

}
