/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { AssemblyInfo } from '../model/assemblyInfo';
import { Component } from '../model/component';
import { CreateAssembly } from '../model/createAssembly';
import { GraphicDetailsDto } from '../model/graphicDetailsDto';
import { Reply } from '../model/reply';
import { ReplyGuid } from '../model/replyGuid';
import { UpdateAssemblyDetails } from '../model/updateAssemblyDetails';
import { ViewModelItemAssemblyDetailAssemblyDetailChangeability } from '../model/viewModelItemAssemblyDetailAssemblyDetailChangeability';
import { ViewModelItemAssemblySummary } from '../model/viewModelItemAssemblySummary';
import { ViewModelListAssemblyComponent } from '../model/viewModelListAssemblyComponent';
import { ViewModelListClusterInfo } from '../model/viewModelListClusterInfo';
import { ViewModelListFileContainerWithReference } from '../model/viewModelListFileContainerWithReference';
import { ViewModelListGraphicListItem } from '../model/viewModelListGraphicListItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class ArticleManagementAssembliesService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * CopyAssembly
     * 
     * @param assemblyId 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesCopyAssembly(assemblyId: string, name: string, observe?: 'body', reportProgress?: boolean): Observable<AssemblyInfo>;
    public assembliesCopyAssembly(assemblyId: string, name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssemblyInfo>>;
    public assembliesCopyAssembly(assemblyId: string, name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssemblyInfo>>;
    public assembliesCopyAssembly(assemblyId: string, name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesCopyAssembly.');
        }
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling assembliesCopyAssembly.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined) {
            queryParameters = queryParameters.set('name', <any> name);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<AssemblyInfo>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/copy`,
                null,
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * CreateAssembly                  Create Assembly with mandatory Name                  Optionally Add flattend and summarized List of Components by Articles and Assemblies
     * 
     * @param createAssembly 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesCreateAssembly(createAssembly: CreateAssembly, observe?: 'body', reportProgress?: boolean): Observable<AssemblyInfo>;
    public assembliesCreateAssembly(createAssembly: CreateAssembly, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<AssemblyInfo>>;
    public assembliesCreateAssembly(createAssembly: CreateAssembly, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<AssemblyInfo>>;
    public assembliesCreateAssembly(createAssembly: CreateAssembly, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (createAssembly === null || createAssembly === undefined) {
            throw new Error('Required parameter createAssembly was null or undefined when calling assembliesCreateAssembly.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<AssemblyInfo>(
                `${this.basePath}/api/v1/articlemanagement/assemblies`,
                createAssembly,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Creates new graphic and assigns it to the article.
     * 
     * @param assemblyId 
     * @param details 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesCreateGraphic(assemblyId: string, details: GraphicDetailsDto, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public assembliesCreateGraphic(assemblyId: string, details: GraphicDetailsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public assembliesCreateGraphic(assemblyId: string, details: GraphicDetailsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public assembliesCreateGraphic(assemblyId: string, details: GraphicDetailsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesCreateGraphic.');
        }
        if (details === null || details === undefined) {
            throw new Error('Required parameter details was null or undefined when calling assembliesCreateGraphic.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/graphics`,
                details,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetAssemblyIdByNumber
     * 
     * @param assemblyNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesGetArticleIdByNumber(assemblyNumber: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public assembliesGetArticleIdByNumber(assemblyNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public assembliesGetArticleIdByNumber(assemblyNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public assembliesGetArticleIdByNumber(assemblyNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyNumber === null || assemblyNumber === undefined) {
            throw new Error('Required parameter assemblyNumber was null or undefined when calling assembliesGetArticleIdByNumber.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (assemblyNumber !== undefined) {
            queryParameters = queryParameters.set('assemblyNumber', <any> assemblyNumber);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/GetAssemblyIdByNumber`,
                
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetAssemblyDetail
     * 
     * @param assemblyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesGetAssemblyDetail(assemblyId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemAssemblyDetailAssemblyDetailChangeability>;
    public assembliesGetAssemblyDetail(assemblyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemAssemblyDetailAssemblyDetailChangeability>>;
    public assembliesGetAssemblyDetail(assemblyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemAssemblyDetailAssemblyDetailChangeability>>;
    public assembliesGetAssemblyDetail(assemblyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesGetAssemblyDetail.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemAssemblyDetailAssemblyDetailChangeability>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/detail`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetAssemblyFiles
     * 
     * @param assemblyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesGetAssemblyFiles(assemblyId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListFileContainerWithReference>;
    public assembliesGetAssemblyFiles(assemblyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListFileContainerWithReference>>;
    public assembliesGetAssemblyFiles(assemblyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListFileContainerWithReference>>;
    public assembliesGetAssemblyFiles(assemblyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesGetAssemblyFiles.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListFileContainerWithReference>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/files`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetAssemblyGraphics
     * 
     * @param assemblyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesGetAssemblyGraphics(assemblyId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListGraphicListItem>;
    public assembliesGetAssemblyGraphics(assemblyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListGraphicListItem>>;
    public assembliesGetAssemblyGraphics(assemblyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListGraphicListItem>>;
    public assembliesGetAssemblyGraphics(assemblyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesGetAssemblyGraphics.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListGraphicListItem>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/graphics`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetAssemblySummary
     * 
     * @param assemblyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesGetAssemblySummary(assemblyId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemAssemblySummary>;
    public assembliesGetAssemblySummary(assemblyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemAssemblySummary>>;
    public assembliesGetAssemblySummary(assemblyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemAssemblySummary>>;
    public assembliesGetAssemblySummary(assemblyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesGetAssemblySummary.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemAssemblySummary>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/summary`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetClusters
     * 
     * @param assemblyId 
     * @param conceptId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesGetClusters(assemblyId: string, conceptId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListClusterInfo>;
    public assembliesGetClusters(assemblyId: string, conceptId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListClusterInfo>>;
    public assembliesGetClusters(assemblyId: string, conceptId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListClusterInfo>>;
    public assembliesGetClusters(assemblyId: string, conceptId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesGetClusters.');
        }
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling assembliesGetClusters.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListClusterInfo>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/concepts/${encodeURIComponent(String(conceptId))}/clusters`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetComponents
     * 
     * @param assemblyId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesGetComponents(assemblyId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListAssemblyComponent>;
    public assembliesGetComponents(assemblyId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListAssemblyComponent>>;
    public assembliesGetComponents(assemblyId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListAssemblyComponent>>;
    public assembliesGetComponents(assemblyId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesGetComponents.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListAssemblyComponent>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/components`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateComponents
     * 
     * @param assemblyId 
     * @param components 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesUpdateComponents(assemblyId: string, components: Array<Component>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public assembliesUpdateComponents(assemblyId: string, components: Array<Component>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public assembliesUpdateComponents(assemblyId: string, components: Array<Component>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public assembliesUpdateComponents(assemblyId: string, components: Array<Component>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesUpdateComponents.');
        }
        if (components === null || components === undefined) {
            throw new Error('Required parameter components was null or undefined when calling assembliesUpdateComponents.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/components`,
                components,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateDetail
     * 
     * @param assemblyId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesUpdateDetails(assemblyId: string, command: UpdateAssemblyDetails, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public assembliesUpdateDetails(assemblyId: string, command: UpdateAssemblyDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public assembliesUpdateDetails(assemblyId: string, command: UpdateAssemblyDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public assembliesUpdateDetails(assemblyId: string, command: UpdateAssemblyDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesUpdateDetails.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling assembliesUpdateDetails.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/detail`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateFileContainers
     * 
     * @param assemblyId 
     * @param fileContainerIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesUpdateFileContainers(assemblyId: string, fileContainerIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public assembliesUpdateFileContainers(assemblyId: string, fileContainerIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public assembliesUpdateFileContainers(assemblyId: string, fileContainerIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public assembliesUpdateFileContainers(assemblyId: string, fileContainerIds: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyId === null || assemblyId === undefined) {
            throw new Error('Required parameter assemblyId was null or undefined when calling assembliesUpdateFileContainers.');
        }
        if (fileContainerIds === null || fileContainerIds === undefined) {
            throw new Error('Required parameter fileContainerIds was null or undefined when calling assembliesUpdateFileContainers.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/${encodeURIComponent(String(assemblyId))}/files`,
                fileContainerIds,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdatePublishedStatusForAssemblies
     * 
     * @param isPublished 
     * @param assemblyIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public assembliesUpdatePublishedStatusForAssemblies(isPublished: boolean, assemblyIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public assembliesUpdatePublishedStatusForAssemblies(isPublished: boolean, assemblyIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public assembliesUpdatePublishedStatusForAssemblies(isPublished: boolean, assemblyIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public assembliesUpdatePublishedStatusForAssemblies(isPublished: boolean, assemblyIds: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (isPublished === null || isPublished === undefined) {
            throw new Error('Required parameter isPublished was null or undefined when calling assembliesUpdatePublishedStatusForAssemblies.');
        }
        if (assemblyIds === null || assemblyIds === undefined) {
            throw new Error('Required parameter assemblyIds was null or undefined when calling assembliesUpdatePublishedStatusForAssemblies.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (isPublished !== undefined) {
            queryParameters = queryParameters.set('isPublished', <any> isPublished);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/assemblies/UpdatePublishedStatusForAssemblies`,
                assemblyIds,
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
