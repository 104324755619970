<ol
    class="distribution-channels--list"
    [class.is-readonly]="!isEditMode"
>
    <li
        *ngFor="let distributionChannel of distributionChannels"
        [class.is-selected]="isDistributionChannelSelected( distributionChannel )"
        class="distribution-channels--channel"
    >
        <label class="distribution-channels--channel-inner">
            <span class="distribution-channels--channel-name">{{ distributionChannel.name }}</span>

            <input
                rspCheckbox
                [rspCheckboxWrapperClass]="'distribution-channels--channel-checkbox'"
                [checked]="isDistributionChannelSelected( distributionChannel )"
                [disabled]="!isEditMode"
                (change)="toggleDistributionChannelSelection( distributionChannel )"
                type="checkbox"
            >
        </label>
    </li>
</ol>
<rsp-messages
    *ngIf="isEditMode && isRequired && (!selectedDistributionChannels || !selectedDistributionChannels.length)"
    [messages]="['At least one distribution channel is required']"
    [type]="messageTypeError"
></rsp-messages>
