<div class="tab">
    <div *ngIf="userHasEditRight" class="tab--toolbar">
        <rsp-button
            [disabled]="hasNewGraphic()"
            [submodule]="'primary'"
            (clicked)="createGraphic()"
            class="tab--toolbar-item is-right"
        >
            Add new graphic
        </rsp-button>
    </div>

    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <rsp-no-content *ngIf="isLoading?.closed && accordionItems.length === 0">
            This {{ itemTypeString }} does not hold any graphics.
        </rsp-no-content>

        <div *ngIf="accordionItems.length >= 0">

            <rsp-accordion [closeOthers]="false">
                <rsp-accordion-group
                    #accordionGroup
                    *ngFor="let accordionItem of accordionItems"
                    [isOpen]="accordionItem.isOpen"
                    (isOpened)="accordionItem.isOpen = $event"
                >
                    <ng-container accordion-group-heading>
                        <div *ngIf="!accordionGroup.isOpen">
                            <span *ngIf="accordionItem.isReferencedGraphic" class="graphics--headline-reference">
                                {{accordionItem.graphic.referencedObject.displayValue}}
                            </span>

                            <ng-container *ngIf="accordionItem.isReferencedGraphic"> - </ng-container>

                            {{ accordionItem.graphic.name }}
                        </div>
                        <div *ngIf="accordionGroup.isOpen && userHasEditRight && accordionItem.isReferencedGraphic === false" class="graphics--edit">
                            <rsp-form-buttons
                                [isEditMode]="accordionItem.isEditMode"
                                [myFormGroup]="graphicEdit.form"
                                [singleActionPencil]="true"
                                [disablePrimaryEditButton]="uploadProcessing"
                                (primaryEditButtonClick)="graphicEdit.triggerSave( $event )"
                                (actionButtonClick)="setEditModeForGraphic( $event, accordionItem )"
                                (secondaryEditButtonClick)="endEditMode( $event, accordionItem )"
                            ></rsp-form-buttons>
                            <rsp-remove
                                *ngIf="accordionItem.graphic.id"
                                [text]="'Do you really want to remove this graphic?'"
                                [title]="'remove graphic'"
                                (confirmClicked)="removeGraphic( accordionItem )"
                                class="graphics--edit-remove"
                            ></rsp-remove>
                        </div>
                    </ng-container>


                    <rsp-non-trade-item-graphic-edit
                        #graphicEdit
                        [graphic]="accordionItem.graphic"
                        [nonTradeItem]="currentItem"
                        [fileKinds]="fileKinds"
                        [isEditMode]="accordionItem.isEditMode"
                        (updatedSuccessful)="savedSuccessful( accordionItem ); uploadProcessing = false;"
                        (createdSuccessful)="savedSuccessful( accordionItem, $event ); uploadProcessing = false;"
                    ></rsp-non-trade-item-graphic-edit>
                </rsp-accordion-group>

            </rsp-accordion>
        </div>
    </div>
</div>
