<rsp-dropdown
    [isLoading]="isLoading"
    [placeholder]="'Search Clusters'"
    [withSuggester]="true"
    [allowClear]="true"
    [defaultValue]="displayValue"
    [disabled]="disabled"
    (searchTermChanged)="searchItems($event)"
    (selected)="selectedItem($event)"
>
        <rsp-dropdown-item *ngFor="let item of items" [item]="item" >
            <div class="cluster-item">
                <div class="cluster-item--name">
                    <span class="cluster-item--name-headline">
                        {{item.concept.name}}
                    </span>
                    <span>
                        {{item.name}}
                    </span>
                </div>
                <rsp-status
                    [displayMode]="'badge'"
                    [status]="item.status"
                    [type]="statusTypeEnum"
                ></rsp-status>
            </div>
        </rsp-dropdown-item>
</rsp-dropdown>
<ng-content></ng-content>
