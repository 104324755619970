import { Component, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';
import { merge, Subject } from 'rxjs';

import { ConceptSummary, } from '../../../../shared/model/conceptSummary';
import { BreadcrumbService, } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb.service';
import { BreadcrumbPart, } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb-part';
import { Tab, } from '../../../../shared/ui/navigation/vertical-tabs/tab';
import { ConceptService, } from './concept.service';
import { EntityTypeEnum } from '../../../../shared/model/enum/entity-type.enum';
import { ConceptOrClusterItem } from '../../../../shared/model/conceptOrClusterItem';
import { ConceptClusterPrevNextNavigationService } from '../shared/concept-cluster-prev-next-navigation/concept-cluster-prev-next-navigation.service';
import { QueryParamService } from '../../../../shared/utils/url/query-param.service';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';


@Component( {
    selector:    'rsp-concept-details-page',
    providers:   [ BreadcrumbService, ],
    templateUrl: './concept-details-page.component.html',
    styleUrls:   [
        './concept-details-page.component.scss',
    ],
} )
export class ConceptDetailsPageComponent implements OnInit, OnDestroy {

    conceptSummary: ConceptSummary;
    conceptTileData: ConceptOrClusterItem;
    tabs: Array<Tab> = [];

    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.Concept;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private conceptService: ConceptService,
        private toolbarService: SecondaryToolbarService,
        private prevNextNavigationService: ConceptClusterPrevNextNavigationService,
        private queryParamService: QueryParamService,
    ) {
        // configure toolbar
        // NOTE: previously it was in ngOnInit, but it throws a ExpressionChangedAfterItHasBeenCheckedError exception.
        // We are not sure why it occurs ... maybe something with named outlet (where toolbar is placed) and change detection.
        // This issue occurs in angular > 4.2.5.
        // https://github.com/angular/angular/issues/17572
        this.toolbarService.hideViewSwitcher();
        this.toolbarService.hideStoreInfo();
        this.toolbarService.hideSortBy();
        this.toolbarService.showPrevNextNavigation();

        this.configureBreadcrumb();
    }


    ngOnInit(): void {

        // Two entry points:
        //  - of( true ) - first component load
        //  - this.pblService.summary$ - prev/next navigation
        merge( this.activatedRoute.data, this.conceptService.summary$ )
            .pipe(
                map( ( data: { conceptSummary: ConceptSummary } | ConceptSummary ) => this.fetchSummary( data ) ),
                distinctUntilChanged(),   // prev/next navigation triggers both events. Take only one.
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( summary: ConceptSummary ) => {

                if ( summary ) {

                    this.conceptSummary = summary;

                    this.conceptTileData = {
                        type:    ConceptOrClusterItem.TypeEnum.Concept,
                        concept: this.conceptSummary.tile,
                    };

                    this.breadcrumbService.replacePart( { label: this.conceptSummary.tile.name }, 1 );

                    this.createTabs();

                    this.conceptService.setSummary( summary );  // updates tab content during prev/next navigation

                    this.prevNextNavigationService.setCurrentItem( this.conceptSummary.tile.id );
                }
            } );
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
        this.breadcrumbService.disableAutoRefreshing();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private configureBreadcrumb(): void {
        const config: { [ key: string ]: BreadcrumbPart } = {};
        config[ 'details' ]                               = { label: 'Details', };
        config[ 'clusters' ]                              = { label: 'Clusters', };
        config[ 'service-levels' ]                        = { label: 'Service Levels', };
        config[ 'files' ]                                 = { label: 'Files', };
        config[ 'history' ]                               = { label: 'History', };

        this.breadcrumbService.addPart( {
            label:      'Concepts & Clusters',
            link:       [ '/concepts-and-clusters', ],
            linkParams: this.queryParamService.getQueryParamsFor( '/concepts-and-clusters' ),
        } );

        this.breadcrumbService.addPart( { label: 'Concept' } ); // will be replaced by summary data

        this.breadcrumbService.enableAutoRefreshing( config, 3 );
    }


    private createTabs(): void {

        if ( !this.conceptSummary ) {
            throw new Error( 'conceptSummary is null' );
        }

        this.tabs = [];

        // Details
        if ( Tab.shouldTabBeVisible( this.conceptSummary.detailNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'details', ],
                label: 'Details',
            } );
        }

        // Clusters
        if ( Tab.shouldTabBeVisible( this.conceptSummary.clustersNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'clusters', ],
                label: 'Clusters',
                tag:   Tab.createTagFromNumber( this.conceptSummary.clustersNavigationItem ),
            } );
        }

        // Service Levels
        if ( Tab.shouldTabBeVisible( this.conceptSummary.serviceLevelsNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'service-levels', ],
                label: 'Service Levels',
                tag:   Tab.createTagFromNumber( this.conceptSummary.serviceLevelsNavigationItem ),
            } );
        }

        // Files
        if ( Tab.shouldTabBeVisible( this.conceptSummary.filesNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'files', ],
                label: 'Files',
                tag:   Tab.createTagFromNumber( this.conceptSummary.filesNavigationItem ),
            } );
        }

        // History
        // if ( Tab.shouldTabBeVisible( this.conceptSummaryData.historyNavigationItem ) ) {
        //     this.tabs.push( {
        //         link:  [ 'history', ],
        //         label: 'History',
        //     } );
        // }
    }


    private fetchSummary( data: { conceptSummary: ConceptSummary } | ConceptSummary ): ConceptSummary {

        if ( !data ) {
            return null;
        }

        if ( data.hasOwnProperty( 'conceptSummary' ) ) {

            // data is { conceptSummary: .... }
            return (<{ conceptSummary: ConceptSummary }> data).conceptSummary;

        }

        // data is ConceptSummary
        return data as ConceptSummary;
    }
}
