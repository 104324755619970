<input
    #search
    rspConvertPasteData
    [formControl]="formControl"
    (keyup.Enter)="triggerSearch( search.value )"
    (keydown)="onKeydown( $event )"
    (focus)="onFocus()"
    type="text"
    placeholder="Search in RSP"
    class="global-search--input"
>
<rsp-button
    #magnifier
    [class.is-available]="categorySearchAvailable && formControl.valid"
    [class.has-focus]="magnifier.hasFocus"
    [submodule]="'seamless'"
    [attr.title]="formControl.valid ? ( categorySearchAvailable ? 'Trigger quick search' : 'Open search results' ) : null"
    (click)="clickMagnifier( search.value )"
    title="Search"
    class="global-search--magnifier-icon"
></rsp-button>

<ng-template #searchResultTemplate>
    <div
        #popupContent
        [rspLoadingIndicator]="isLoading"
        class="global-search--popup"
    >
        <ng-container *ngIf="searchResult && searchResult.length > 0; else noResults">
            <rsp-dropdown-group
                [label]="'Search Results'"
            >
                <rsp-dropdown-item
                    *ngFor="let category of searchResult"
                    [item]="category"
                    (selectedItem)="navigateTo( category )"
                >
                    <span class="global-search--popup-count">
                        {{ category.count }}
                    </span>
                    {{ category.label }}
                </rsp-dropdown-item>
            </rsp-dropdown-group>
            <div>
                <ul class="global-search--help">
                    <li>+ signifies AND operation</li>
                    <li>| signifies OR operation</li>
                    <li>- negates a single token</li>
                    <li>" wraps a number of tokens to signify a phrase for searching</li>
                    <li>* at the end of a term signifies a prefix query</li>
                    <li>( and ) signify precedence</li>
                </ul>
            </div>
        </ng-container>

        <ng-template #noResults>
            <rsp-dropdown-group *ngIf="!isLoading || isLoading.closed" [label]="'No Search Results'"></rsp-dropdown-group>
            <div>
                <ul class="global-search--help">
                    <li>+ signifies AND operation</li>
                    <li>| signifies OR operation</li>
                    <li>- negates a single token</li>
                    <li>" wraps a number of tokens to signify a phrase for searching</li>
                    <li>* at the end of a term signifies a prefix query</li>
                    <li>( and ) signify precedence</li>
                </ul>
            </div>
        </ng-template>
    </div>
</ng-template>
