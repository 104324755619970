import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { NotificationService } from '../../../../../../../core/overlay/notification/notification.service';
import { NonTradeItemSelectionService } from '../../../../shared/non-trade-item-selection.service';
import { NonTradeItemSuggestSearchConfiguration } from '../../../../../../../shared/model/nonTradeItemSuggestSearchConfiguration';
import { NonTradeListItem } from '../../../../../../../shared/model/nonTradeListItem';
import { NonTradeSuggestItem } from '../../../../../../../shared/model/nonTradeSuggestItem';
import { ArticleManagementAssembliesService } from '../../../../../../../shared/api/articleManagementAssemblies.service';
import { ViewModelListAssemblyComponent } from '../../../../../../../shared/model/viewModelListAssemblyComponent';
import { AssemblyComponent } from '../../../../../../../shared/model/assemblyComponent';
import { Component as ComponentDto } from '../../../../../../../shared/model/component';
import { Reply } from '../../../../../../../shared/model/reply';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component( {
    selector:    'rsp-add-selection-to-assembly-dialog',
    templateUrl: './add-selection-to-assembly-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        './add-selection-to-assembly-dialog.component.scss',
    ],
} )
export class AddSelectionToAssemblyDialogComponent implements OnDestroy {
    @ViewChild( 'dialogHeader', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContent', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooter', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    form: UntypedFormGroup;
    isLoading: Subscription;
    articleType: NonTradeListItem.TypeEnum                                    = NonTradeListItem.TypeEnum.Article;
    assemblyType: NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum = NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum.Assembly;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private articleManagementAssembliesService: ArticleManagementAssembliesService,
    ) {
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    setTargetAssembly( assemblySuggestItem: NonTradeSuggestItem ): void {
        this.form.get( 'targetAssembly' ).setValue( assemblySuggestItem );
    }

    doAction(): void {
        const targetAssembly: NonTradeSuggestItem = this.form.get( 'targetAssembly' ).value;

        this.isLoading = this.articleManagementAssembliesService
                             .assembliesGetComponents( targetAssembly.id )
                             .pipe(
                                 map( ( viewModelListAssemblyComponent: ViewModelListAssemblyComponent ) => viewModelListAssemblyComponent.data ),
                                 switchMap( ( oldComponents: AssemblyComponent[] ) => {
                                     const newComponents: ComponentDto[] = oldComponents
                                         .map( ( component: AssemblyComponent ) => {
                                             return {
                                                 articleId: component.id,
                                                 quantity:  component.quantity,
                                             };
                                         } );

                                     this.nonTradeItemSelectionService.getSelectedArticles()
                                         .filter( ( article: NonTradeListItem ) => {
                                             return !newComponents.some( ( component: ComponentDto ) => component.articleId === article.id );
                                         } )
                                         .forEach( ( newArticle: NonTradeListItem ) => {
                                             newComponents.push( {
                                                 articleId: newArticle.id,
                                                 quantity:  1,
                                             } );
                                         } );

                                     return this.articleManagementAssembliesService.assembliesUpdateComponents( targetAssembly.id, newComponents );
                                 } ),
                                 takeUntil( this.isDestroyed ),
                             )
                             .subscribe( ( response: Reply ) => {
                                 this.notificationService.success(
                                     `Components were added to assembly ${ targetAssembly.number } - ${ targetAssembly.name }.`,
                                     'Components updated',
                                 );
                                 this.close();
                             } );
    }

    open(): void {
        this.form = new UntypedFormGroup( {
            targetAssembly: new UntypedFormControl( null, Validators.required ),
        } );

        this.dialogService.openDialog( {
            contentTemplate:  this.dialogContentTemplate,
            headlineTemplate: this.dialogHeaderTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }
}
