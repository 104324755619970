import { ClusterInfo } from '../../../../../../shared/model/clusterInfo';
import { ConceptAndCluster } from '../../../../../../shared/model/conceptAndCluster';

import ConceptStatusEnum = ConceptAndCluster.ConceptStatusEnum;

/**
 * Represents one collapsable item in the Concept & Cluster list.
 */
export class ConceptWithClustersItem {

    isExpanded: boolean = false;

    conceptId: string;

    conceptName: string;

    conceptStatus: ConceptStatusEnum;

    usedClusterCount: number;

    availableClusterCount: number;

    clusters: Array<ClusterInfo>;

    isLoading: boolean;

    constructor( conceptAndCluster: ConceptAndCluster ) {

        this.conceptId             = conceptAndCluster.conceptId;
        this.conceptName           = conceptAndCluster.conceptName;
        this.conceptStatus         = conceptAndCluster.conceptStatus;
        this.usedClusterCount      = conceptAndCluster.usedClusterCount;
        this.availableClusterCount = conceptAndCluster.availableClusterCount;
    }
}
