<div class="welcome-user">
    <rsp-user-profile-image
        [user]="user"
        class="welcome-user--profile-image"
    ></rsp-user-profile-image>

    <div class="welcome-user--text">
        <span class="welcome-user--text-salutation">Hello {{ user.firstName }}</span>
        <span class="welcome-user--text-welcome">welcome back!</span>
    </div>
</div>
