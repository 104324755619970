import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subject, Subscription, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { ReportingExtractService } from '../../../../../../../shared/api';
import { MessageType } from '../../../../../../../shared/ui/messages/messages.component';
import { NotificationService } from '../../../../../../../core/overlay/notification/notification.service';
import { NonTradeItemStoreService } from '../../../../shared/non-trade-item-store.service';
import { AdvancedSearchFilterTypeOperator } from '../../../../../../../shared/model/advancedSearchFilterTypeOperator';
import { AdvancedSearchFilterProperty } from '../../../../../../../shared/model/advancedSearchFilterProperty';
import { FacetItem } from '../../../../../../../shared/model/facetItem';
import { AdvancedSearchFilterGroup } from '../../../../../../../shared/model/advancedSearchFilterGroup';
import { UntypedFormControl, Validators } from '@angular/forms';
import { CustomValidators } from '../../../../../../../shared/forms/validation/custom-validators';
import { StoreInfo } from '../../../../../../../shared/ui/lists/store/store-info.model';
import * as moment from 'moment-timezone';
import { CreateNonTradeItemExtract } from '../../../../../../../shared/model';

interface Sheet {
    index: number;
    name: string;
    checked: boolean;
}

@Component( {
    selector:    'rsp-export-search-result-to-excel-dialog',
    templateUrl: './export-search-result-to-excel-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        './export-search-result-to-excel-dialog.component.scss',
    ],
} )
export class ExportSearchResultToExcelDialogComponent implements OnDestroy {
    @ViewChild( 'dialogHeader', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContent', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooter', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    nameControl: UntypedFormControl = new UntypedFormControl( '', [ Validators.required, CustomValidators.isValidFilename, CustomValidators.stringNotEmpty ] );

    messageType: MessageType;
    messages: string[] = [];

    isLoading: Subscription;

    sheetsEnumToString: ( value: number ) => string = CreateNonTradeItemExtract.SheetsEnum.toString;

    sheetsEnumKeys: any[]     = Object.keys( CreateNonTradeItemExtract.SheetsEnum )
                                      .filter( ( k: any ) => typeof CreateNonTradeItemExtract.SheetsEnum[ k as any ] === 'number' );
    sheetsEnumValues: Sheet[] = this.sheetsEnumKeys.map( ( k: any ) => {
        const index: number = parseInt( CreateNonTradeItemExtract.SheetsEnum[ k as any ], 10 );

        return {
            index:   index,
            name:    this.sheetsEnumToString( index ),
            checked: false,
        };
    } );

    facets: Array<FacetItem>;
    chosenValuesFacet: Array<FacetItem>;
    advancedSearchFilterTypeOperators: Array<AdvancedSearchFilterTypeOperator>;
    advancedSearchFilterProperties: Array<AdvancedSearchFilterProperty>;
    advancedSearchFilterGroups: Array<AdvancedSearchFilterGroup>;

    storeInfo: Observable<StoreInfo>;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private storeService: NonTradeItemStoreService,
        private reportingExtractService: ReportingExtractService,
    ) {
        this.storeInfo = storeService.storeInfo$;
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    doAction(): void {
        if ( this.nameControl.valid ) {
            this.isLoading =
                this.reportingExtractService
                    .extractCreateBySearch(
                        {
                            fileName:         this.nameControl.value,
                            userTimeZoneInfo: moment.tz.guess(),
                            configuration:    {
                                sortings:                   [],
                                advancedSearchFilterGroups: this.storeService.getAdvancedSearchFilterGroups(),
                                filters:                    this.storeService.getFacetFilters(),
                                quickSearchTerm:            this.storeService.getQuickSearch(),
                                size:                       50,
                                page:                       1,
                            },
                            sheets:           this.sheetsEnumValues
                                                  .filter( ( item: Sheet ) => item.checked )
                                                  .map( ( item: Sheet ) => item.index ),
                        },
                    )
                    .pipe( takeUntil( this.isDestroyed ) )
                    .subscribe( () => {
                        this.notificationService.success( 'Extract generation triggered.', 'Article / Assembly Extract ' );
                        this.close();
                    } );
        }
    }

    open(): void {
        this.nameControl.setValue( null );
        this.dialogService.openDialog( {
            contentTemplate:  this.dialogContentTemplate,
            headlineTemplate: this.dialogHeaderTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }

    hasCheckedSheets(): boolean {
        return this.sheetsEnumValues.filter( ( item: Sheet ) => item.checked ).length > 0;
    }
}
