import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SubComponentSuggestItem } from '../../../../../../shared/model/subComponentSuggestItem';
import { SubComponentListItem } from '../../../../../../shared/model/subComponentListItem';

@Component( {
    selector:    'rsp-display-edit-subcomponents',
    templateUrl: './display-edit-subcomponents.component.html',
    styleUrls:   [
        './display-edit-subcomponents.component.scss',
    ],
} )
export class DisplayEditSubComponentsComponent implements OnInit {

    @Input() myFormGroup: UntypedFormGroup;
    @Input() isEditMode: boolean;

    private _subComponents: Array<SubComponentListItem>;

    @Input( 'subComponents' )
    get subComponents(): Array<SubComponentListItem> {
        return this._subComponents || [];
    }

    set subComponents( value: Array<SubComponentListItem> ) {
        this._subComponents = value;
    }

    ngOnInit(): void {
        if (this.myFormGroup ) {
            this.myFormGroup.setControl( 'subComponentIds', new UntypedFormControl() );
            this.updateCurrentSubComponentIds();
        }
    }

    addSubComponent( subcomponentSuggestItem?: SubComponentSuggestItem ): void {
        if ( subcomponentSuggestItem ) {
            this.subComponents.push( {
                id:       subcomponentSuggestItem.id,
                number:   subcomponentSuggestItem.number,
                hasFiles: subcomponentSuggestItem.hasFiles,
            } );
            this.updateCurrentSubComponentIds();
        }
    }

    removeSubComponent( articleSubComponent: SubComponentListItem ): void {
        const index: number = this.subComponents.findIndex( ( old: SubComponentListItem ) => {
            return old.id === articleSubComponent.id;
        } );

        if ( index !== -1 ) {
            this.subComponents.splice( index, 1 );
            this.updateCurrentSubComponentIds();
        }
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private updateCurrentSubComponentIds(): void {
        this.myFormGroup.get( 'subComponentIds' ).setValue(
            this.subComponents.map( ( articleSubComponent: SubComponentListItem ) => {
                return articleSubComponent.id;
            } ),
        );
    }
}
