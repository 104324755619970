import { Directive, Input, ElementRef, Renderer2, NgZone } from '@angular/core';

import { PositionStickyDirective } from './position-sticky.directive';
import { WindowEventService } from '../../../core/window-event.service';
import { ScrollContainerService } from './scroll-container.service';
import { ElementVisibilityService } from '../../../core/element-visibility.service';

/**
 * Fixes table rows at the top of a given container when they would scroll out of the viewport. Similar to (and also based on) `PositionStickyDirective` but
 * works for `<tr>` elements.
 *
 * An additional CSS class can be applied when the state changes to *sticked*, using the `[rspFixTableRowIsFixedCssClass]`-binding.
 *
 * ```
 * <section style="overflow-y: scroll;">
 *     <table #stickyContainer>
 *         <tbody #tbody1>
 *             <tr
 *                 rspFixTableRow
 *                 [rspFixTableRowContainer]="tbody1"
 *             >
 *                 <td>1</td>
 *                 <td>2</td>
 *             </tr>
 *             <tr>
 *                 <td>2</td>
 *                 <td>4</td>
 *             </tr>
 *             <!-- … -->
 *         </tbody>
 *         <tbody #tbody2>
 *             <tr
 *                 rspFixTableRow
 *                 [rspFixTableRowContainer]="tbody2"
 *                 [rspFixTableRowIsFixedCssClass]="'is-table-fixed'"
 *             >
 *                 <td>1</td>
 *                 <td>2</td>
 *             </tr>
 *             <tr>
 *                 <td>2</td>
 *                 <td>4</td>
 *             </tr>
 *             <!-- … -->
 *         </tbody>
 *     </table>
 * </section>
 * ```
 *
 */

@Directive( {
    selector:  '[rspFixTableRow]',
    providers: [ ElementVisibilityService ],
} )
export class FixTableRowDirective extends PositionStickyDirective {
    @Input()
    set rspFixTableRowContainer( containerNode: HTMLElement ) { this.stickyContainerNode = containerNode; }
    get rspFixTableRowContainer(): HTMLElement { return this.stickyContainerNode; }

    @Input()
    set rspFixTableRowIsFixedCssClass( className: string ) { this.isStickyCssClassName = className; }
    get rspFixTableRowIsFixedCssClass(): string { return this.isStickyCssClassName; }

    @Input()
    set rspFixTableRowScrollContainerIdentifier( identifier: string) { this.scrollContainerIdentifier = identifier; }

    constructor(
        directiveElement: ElementRef,
        protected zone: NgZone,
        protected scrollContainerService: ScrollContainerService,
        protected renderer: Renderer2,
        protected windowEventService: WindowEventService,
        protected elementVisibilityService: ElementVisibilityService,
    ) {
        super( directiveElement, zone, renderer, windowEventService, scrollContainerService, elementVisibilityService );
    }

    protected onBeforeStick(): void {
        if ( this.stickyNode.nodeName.toLowerCase() === 'tr' ) {
            const stickyNodeCellNodes: Array<HTMLTableCellElement> = Array.prototype.slice.call( this.stickyNode.querySelectorAll( 'th, td' ) );

            const tableCellNodes: Array<HTMLTableCellElement> = Array.prototype.slice.call( this.stickyNodePlaceHolder.querySelectorAll( 'th, td' ) );
            let tableCellNodesWidths: Array<string>           =
                    tableCellNodes.map( ( cellNode: HTMLTableCellElement ) => window.getComputedStyle( cellNode ).width );

            const widthSum: number =
                      tableCellNodesWidths.map( ( pixel: string ) => parseFloat( pixel ) )
                                          .reduce( ( sum: number, width: number ) => sum + width, 0 );

            const placeHolderWidth: number = this.stickyNodePlaceHolder.getBoundingClientRect().width;

            if ( Math.ceil( widthSum ) !== placeHolderWidth && Math.floor( widthSum ) !== placeHolderWidth ) {
                tableCellNodesWidths = tableCellNodes.map( ( cellNode: HTMLTableCellElement ) => cellNode.getBoundingClientRect().width + 'px' );
            }

            stickyNodeCellNodes.forEach( ( cellNode: HTMLTableCellElement, index: number ) => {
                this.renderer.setStyle( cellNode, 'width', tableCellNodesWidths[ index ] );
            } );
        }
        else {
            console.warn( `${ this.constructor.name }: Your [rspFixTableRowContainer] isn't a <tr>.` );
        }
    }

    protected onBeforeUnstick(): void {
        if ( this.stickyNode.nodeName.toLowerCase() === 'tr' ) {
            const stickyNodeCellNodes: Array<HTMLTableCellElement> = Array.prototype.slice.call( this.stickyNode.querySelectorAll( 'th, td' ) );

            stickyNodeCellNodes.forEach( ( cellNode: HTMLTableCellElement, index: number ) => {
                this.renderer.removeStyle( cellNode, 'width');
            } );
        }
        else {
            console.warn( `${ this.constructor.name }: Your [rspFixTableRowContainer] isn't a <tr>.` );
        }
    }
}
