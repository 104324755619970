import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseStoreService } from '../../../../shared/ui/lists/store/base-store.service';
import { NonTradeListItem } from '../../../../shared/model';
import { ArticleManagementNonTradeItemsService } from '../../../../shared/api';
import { BrowsingConfiguration } from '../../../../shared/model';
import { ViewModelBrowsingListNonTradeListItem } from '../../../../shared/model';


// TODO: rename "store" to "storage"

/**
 * Handles loading and caching for NonTradeItems.
 */
@Injectable()
export class NonTradeItemStoreService extends BaseStoreService<NonTradeListItem> {
    constructor(
        private nonTradeItemApi: ArticleManagementNonTradeItemsService,
    ) {
        super();
    }

    protected getId( item: NonTradeListItem ): string {

        if ( !item ) {
            throw new Error( '[NonTradeItemStoreService] item must not be null!' );
        }

        return item.id;
    }

    protected loadItemsFromBackend( request: BrowsingConfiguration ): Subscription {

        return this.nonTradeItemApi
            .nonTradeItemsSearch( request )
                   .subscribe(
                       ( result: ViewModelBrowsingListNonTradeListItem ) => {
                           super.setBackendResult(
                               result.data,
                               result.paging,
                               result.facets,
                               result.selectLists,
                               result.advancedSearchFilterTypeOperators,
                               result.advancedSearchFilterProperties,
                               result.advancedSearchFilterGroups,
                               result.availableSortings,
                               result.usedSortings,
                               result.quickSearchTerm,
                           );
                       },
                       () => {
                           super.unregisterLoadingObservable();
                       } );
    }
}
