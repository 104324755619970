import { NonTradeItemType, } from './non-trade-item-type.model';
import { ArticleSummary } from '../../../../shared/model/articleSummary';
import { AssemblySummary } from '../../../../shared/model/assemblySummary';
import { NonTradeItemRoutingData } from '../non-trade-item/non-trade-item-details-page.component';

/**
 * Collects information about currently displayed non-trade-item.
 */
export class CurrentNonTradeItem {

    /**
     * Article or Assembly id.
     */
    get id(): string { return this.getId(); }

    /**
     * Article or Assembly name.
     */
    get name(): string { return this.getName(); }

    /**
     * Article or Assembly number.
     */
    get number(): string { return this.getNumber(); }

    /**
     * non-trade-item's type.
     */
    type: NonTradeItemType;

    /**
     * String representation of NonTradeItemType enum.
     */
    get typeString(): string { return this.getTypeAsString(); }

    articleSummary: ArticleSummary;

    assemblySummary: AssemblySummary;

    isMultiAction: boolean;


    static createFromRoutingData( routingData: NonTradeItemRoutingData ): CurrentNonTradeItem {

        const result: CurrentNonTradeItem = new CurrentNonTradeItem();

        result.type            = routingData.itemType;
        result.articleSummary  = routingData.articleSummary;
        result.assemblySummary = routingData.assemblySummary;
        result.isMultiAction   = false;

        return result;
    }

    static createFromArticleSummary( articleSummary: ArticleSummary ): CurrentNonTradeItem {

        const result: CurrentNonTradeItem = new CurrentNonTradeItem();

        result.type            = NonTradeItemType.Article;
        result.articleSummary  = articleSummary;
        result.assemblySummary = null;
        result.isMultiAction   = false;

        return result;
    }

    static createFromAssemblySummary( assemblySummary: AssemblySummary ): CurrentNonTradeItem {

        const result: CurrentNonTradeItem = new CurrentNonTradeItem();

        result.type            = NonTradeItemType.Assembly;
        result.articleSummary  = null;
        result.assemblySummary = assemblySummary;
        result.isMultiAction   = false;

        return result;
    }


    isArticle(): boolean {
        return this.type === NonTradeItemType.Article;
    }


    isAssembly(): boolean {
        return this.type === NonTradeItemType.Assembly;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private getId(): string {

        if ( this.type === NonTradeItemType.Article && this.articleSummary ) {
            return this.articleSummary.id;
        }

        if ( this.type === NonTradeItemType.Assembly && this.assemblySummary ) {
            return this.assemblySummary.id;
        }

        return null;
    }

    private getName(): string {

        if ( this.type === NonTradeItemType.Article && this.articleSummary ) {
            return this.articleSummary.name;
        }

        if ( this.type === NonTradeItemType.Assembly && this.assemblySummary ) {
            return this.assemblySummary.name;
        }

        return null;
    }

    private getNumber(): string {

        if ( this.type === NonTradeItemType.Article && this.articleSummary ) {
            return this.articleSummary.number;
        }

        if ( this.type === NonTradeItemType.Assembly && this.assemblySummary ) {
            return this.assemblySummary.number;
        }

        return null;
    }


    private getTypeAsString(): string {

        if ( this.type === NonTradeItemType.Article ) {
            return 'Article';
        }
        if ( this.type === NonTradeItemType.Assembly ) {
            return 'Assembly';
        }
    }
}
