import { Component, Input, Output, EventEmitter, ElementRef, ViewChild, } from '@angular/core';

@Component( {
    selector: 'rsp-dropdown-item',
    template: `
        <div
            #itemContainer
            (click)="selectItem()"
            class="dropdown--list-item"
            [class.is-dropdown-item-focused]="isFocused"
            [class.is-dropdown-item-disabled]="disabled"
        >
            <ng-content></ng-content>
        </div>
`,
    styleUrls: [
        './dropdown-item.component.scss',
        './dropdown.scss',
    ],
} )
export class DropdownItemComponent {
    @ViewChild( 'itemContainer', { static: true } ) itemContainer: ElementRef;
    @Input() item: any;
    @Input() disabled: boolean;
    @Output() selectedItem: EventEmitter<any> = new EventEmitter<any>();

    isFocused: boolean  = false;

    selectItem(): void {
        if ( !this.disabled ) {
            this.selectedItem.emit( this.item );
        }
    }
}
