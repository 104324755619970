import { Injectable } from '@angular/core';
import { BehaviorSubject ,  Observable ,  Subject } from 'rxjs';


/**
 * Controls @see BackDropComponent.
 */
@Injectable()
export class BackdropService {

    /**
     * Informs that backdrop should be open/close.
     */
    isBackdropVisible$: Observable<boolean>;

    /**
     * Informs that user has clicked on backdrop.
     * TODO: probably not needed
     */
    backdropClicked$: Observable<void>;


    private isBackdropVisible: BehaviorSubject<boolean>   = new BehaviorSubject( false );
    private backdropClicked: Subject<void>                = new Subject<void>();


    constructor() {
        this.isBackdropVisible$   = this.isBackdropVisible.asObservable();
        this.backdropClicked$     = this.backdropClicked.asObservable();
    }


    /**
     * Shows backdrop which covers the whole browser viewport.
     */
    showBackdrop(): void {
        this.isBackdropVisible.next( true );
    }

    /**
     * Hides backdrop.
     */
    hideBackdrop(): void {
        this.isBackdropVisible.next( false );
    }

    /**
     * Notifies subscribers of @see backdropClicked$ that backdrop has been clicked.
     */
    emitBackdropClickedEvent(): void {
        this.backdropClicked.next();
    }
}
