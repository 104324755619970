import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { TransformService } from '../../shared/utils/string/transform.service';
import { map } from 'rxjs/operators';

@Injectable()
export class TransformResponseDateInterceptor implements HttpInterceptor {

    constructor(
        private transformService: TransformService,
    ) { }

    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        return next.handle( req ).pipe(
            map( ( event: HttpEvent<any> ) => {
                if ( event instanceof HttpResponse ) {
                    const response: HttpResponse<any> = <HttpResponse<any>> event;

                    if ( response.body ) {
                        return response.clone( { body: this.transformService.transformWithDate( JSON.stringify( response.body ) ) } );
                    }
                    else {
                        return response;
                    }
                }
                else {
                    return event;
                }
            } ),
        );
    }
}
