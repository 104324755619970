/**
 * Represents one tag. Used by @see { @link TagsComponent }.
 */
export class Tag {

    /**
     * Text displayed as tag content.
     */
    label: string;

    /**
     * High prio tags have another styling. // not anymore - delete this? maybe TODO
     * @type {boolean}
     */
    isHighPrioTag: boolean = false;

    constructor( label: string, isHighPrioTag: boolean = false ) {
        this.label         = label;
        this.isHighPrioTag = isHighPrioTag;
    }
}
