import { Component, Input, } from '@angular/core';
import { OptionItem } from '../../../../../../shared/model/optionItem';
import { Tag } from '../../../../../../shared/ui/tags/tag';
import { UntypedFormControl } from '@angular/forms';

@Component( {
    selector:    'rsp-display-edit-retail-segmentations',
    templateUrl: './display-edit-retail-segmentations.component.html',
    styleUrls:   [
        './display-edit-retail-segmentations.component.scss',
    ],
} )
export class DisplayEditRetailSegmentationsComponent {
    @Input() retailSegmentations: Array<OptionItem>;
    @Input() myFormControl: UntypedFormControl;
    @Input() isEditMode: boolean;


    getRetailSegmentationsAsTags(): Array<Tag> {
        if ( this.retailSegmentations ) {

            const tags: Array<Tag> = [];

            this.retailSegmentations.map( ( retailSegmentation: OptionItem ) => {
                tags.push( new Tag( retailSegmentation.name ) );
            } );

            return tags;
        }

        return null;
    }

}
