import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';

import { AuthenticationService } from '../authentication.service';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {
    private HEADER_AUTHORIZATION: string = 'Authorization';

    constructor(
        private injector: Injector,
    ) { }

    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        if ( !req.url.includes( environment.backendBaseUrl ) ) { // TODO: remove this when https://github.com/angular/angular/issues/18224 is fixed
            return next.handle( req );
        }

        const authenticationService: AuthenticationService = this.injector.get( AuthenticationService );

        const reqWithAuth: HttpRequest<any> =
                  req.clone( { headers: req.headers.set( this.HEADER_AUTHORIZATION, 'Bearer ' + authenticationService.getAccessToken() ) } );

        return next.handle( reqWithAuth );
    }
}
