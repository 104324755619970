<rsp-card>
    <rsp-card-headline>Cultures</rsp-card-headline>
    <rsp-card-content>
        <rsp-language-keyword
            *ngFor="let language of _selectedLanguages"
            [language]="language"
            [status]="statusForLanguage.get( language.code )"
            [hasClearButton]="isEditMode"
            (clearClick)="deselectLanguage( language )"
        ></rsp-language-keyword>

        <rsp-not-available
            *ngIf="!isEditMode && _selectedLanguages.length === 0"
            class="card-languages--no-language"
        ></rsp-not-available>

        <div *ngIf="isEditMode">
            <rsp-dropdown
                *ngIf="unusedLanguages.length > 0"
                [withSuggester]="true"
                [defaultValue]="null"
                [placeholder]="'Add another culture'"
                [originalItems]="unusedLanguages"
                (filteredItems)="setFilteredUnusedLanguages( $event )"
                (selected)="selectLanguage( $event )"
            >
                <rsp-dropdown-item *ngFor="let language of filteredUnusedLanguages" [item]="language">
                    {{ language.code }}
                    {{ language.name ? ' – ' + language.name : '' }}
                </rsp-dropdown-item>
            </rsp-dropdown>

            <rsp-button *ngIf="unusedLanguages.length > 0" (clicked)="selectAllLanguages()">Add all</rsp-button>
        </div>
    </rsp-card-content>
</rsp-card>
