import { Directive, ElementRef, Renderer2, Input } from '@angular/core';
import { BaseCheckboxRadiobuttonDirective } from './base-checkbox-radiobutton.directive';

/**
 * Transforms a standard `<input type="checkbox">` to our styled checkboxes.
 *
 * Usage:
 * ```
 *     <input
 *         rspCheckbox
 *         [rspCheckboxWrapperClass]="'an-additional-class-if-needed'"
 *         [rspCheckboxWrapperClassOnChecked]="'an-additional-optional-state-class-that-is-added-to-the-wrapper-when-the-checkbox-is-checked'"
 *         [rspCheckboxIsIndeterminate]="false"
 *         type="checkbox"
 *     >
 * ```
 *
 * Final markup for the above example will be:
 * ```
 * <div class="an-additional-class-if-needed checkbox">
 *     <input class="checkbox--checkbox" type="checkbox">
 *     <span class="checkbox--fake-checkbox"></span>
 * </div>
 * ```
 *
 * Any additional attributes provided to the original `<input type="checkbox">` will be preserved.
 */
@Directive( {
    selector: '[rspCheckbox]',
} )
export class CheckboxDirective extends BaseCheckboxRadiobuttonDirective {

    @Input()
    set rspCheckboxWrapperClass( value: string ) {
        this.rspWrapperClass = value;
    }

    @Input()
    set rspCheckboxWrapperClassOnChecked( value: string ) {
        this.rspWrapperClassOnChecked = value;
    }

    @Input()
    set rspCheckboxIsIndeterminate( value: boolean ) {
        this.inputElement.indeterminate = value;
    }

    constructor(
        inputElement: ElementRef,
        renderer: Renderer2,
    ) {
        super( 'checkbox', inputElement, renderer );
    }
}
