import { AnimationTriggerMetadata, animate, state, style, transition, trigger, } from '@angular/animations';

const animationDuration: string = '0.1s';

export const secondaryToolbarSlideInButtonAnimations: Array<AnimationTriggerMetadata> = [

    trigger( 'rotateButtonTrigger', [
        state( '0', style( { transform: 'rotate(0deg)',  } ) ),
        state( '1', style( { transform: 'rotate(180deg)', } ) ),
        transition( '1 => 0', animate( animationDuration + ' linear' ) ),
        transition( '0 => 1', animate( animationDuration + ' linear' ) ),
    ] ),

];

