<div class="secondary-toolbar">

    <div class="secondary-toolbar--left-area">
        <!--<rsp-secondary-toolbar-button-->
            <!--[icon]="'selection'"-->
            <!--[badgeValue]="( selectedItems | async )?.length"-->
            <!--(clearButtonClick)="clearSelection()"-->
        <!--&gt;-->
        <!--</rsp-secondary-toolbar-button>-->

        <!--<div class="secondary-toolbar&#45;&#45;separator"></div>-->

        <!--<rsp-secondary-toolbar-slidein-button-->
            <!--[label]="'Actions'"-->
            <!--[slideInSize]="{ width: '350px' }"-->
        <!--&gt;-->
            <!--&lt;!&ndash;<rsp-user-actions-list></rsp-user-actions-list>&ndash;&gt;-->
        <!--</rsp-secondary-toolbar-slidein-button>-->


        <div *ngIf="isSortByVisible | async" class="secondary-toolbar--sort-by">
            <span [class]="sortingDirection === 'Descending' ? 'secondary-toolbar--sort-down' : 'secondary-toolbar--sort-up'" [title]="sortingDirection"></span>

            <div class="secondary-toolbar--separator"></div>

            <rsp-secondary-toolbar-slidein-button
                [label]="sortingLabel"
                [title]="sortingTitle"
                [slideInSize]="{ width: '250px' }"
            >
                <rsp-sort-by
                    [availableSorting]="availableSorting | async"
                    [activeSorting]="activeSorting"
                    (selectedSorting)="setSorting( $event )"
                ></rsp-sort-by>
            </rsp-secondary-toolbar-slidein-button>
        </div>

    </div>

    <div class="secondary-toolbar--right-area">
        <!-- ViewSwitcher - visible only on the list page -->
        <rsp-view-switcher
            *ngIf="isViewSwitcherVisible | async"
            class="secondary-toolbar--right-area-item"
        ></rsp-view-switcher>

        <!-- StoreInfo - visible only on the list page -->
        <rsp-store-info
            *ngIf="isStoreInfoVisible | async"
            class="secondary-toolbar--right-area-item"
            [storeInfo]="storeInfo | async"
        ></rsp-store-info>

        <!-- PrevNextNavigation - visible only on the details page -->
        <rsp-user-prev-next-navigation
            *ngIf="isPrevNextNavigationVisible | async"
            class="secondary-toolbar--right-area-item"
        ></rsp-user-prev-next-navigation>
    </div>
</div>
