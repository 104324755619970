<ng-container *ngIf="!isEditMode && value != null">
    {{ value }}
    <ng-template [ngTemplateOutlet]="content"></ng-template>
</ng-container>
<ng-container *ngIf="!isEditMode && value == null && showNotAvailable">
    <rsp-not-available [notAvailable]="notAvailableString"></rsp-not-available>
</ng-container>

<rsp-edit-text
    *ngIf="isEditMode"
    [textAlignedRight]="true"
    [myFormControl]="myFormGroup.get(name)"
    [size]="size"
    [inputFieldHtmlId]="inputFieldHtmlId"
    [adjustSize]="true"
    [hideValidationMessages]="hideValidationMessages"
    [placeholder]="placeholder"
>
    <ng-template [ngTemplateOutlet]="content"></ng-template>
</rsp-edit-text>


<ng-template #content>
    <ng-content></ng-content>
</ng-template>
