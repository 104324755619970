import { Injectable, } from '@angular/core';
import { BehaviorSubject, } from 'rxjs';

import { NonTradeSuggestItem, } from '../../../../../shared/model/nonTradeSuggestItem';
import { TargetItem, } from './target-item.model';
import { NonTradeListItem, } from '../../../../../shared/model/nonTradeListItem';

import { cloneDeep } from 'lodash';

@Injectable()
export class NonTradeItemAssemblyComponentsMultiEditService {

    targetItems: Array<TargetItem>          = [];
    sourceItems: Array<NonTradeListItem>    = [];
    actionType: string;
    processedCount: BehaviorSubject<number> = new BehaviorSubject<number>( 0 );

    constructor() {
        // TODO: remove this later
        // // put some test data
        // this.targetItems = [
        //     new TargetItem(
        //         '0c8503f3-2224-a739-fa54-249400693df1',
        //         'A-01075',
        //         'Fitting Room Mirror SET - B ***',
        //         '3b6b1000-b37f-1d78-946b-7e874e0eff4f',
        //         [ '/assembly', 'A-01075', 'components', 'edit', 'multi', ] ),
        //
        //     new TargetItem(
        //         '977dafd8-1dea-729d-2b4f-326d78375892',
        //         'A-01666',
        //         'Fitting Room Set',
        //         '9c09e430-a6dd-1da2-9998-77b14212a980',
        //         [ '/assembly', 'A-01666', 'components', 'edit', 'multi', ] ),
        //
        //     new TargetItem(
        //         'b9215b2e-bad2-fb78-770c-f61bc884f185',
        //         'A-00535',
        //         'Fitting Room Equipment SET-A ***',
        //         '6b684190-8b67-c71b-a608-3bb6f7700080',
        //         [ '/assembly', 'A-00535', 'components', 'edit', 'multi', ] ),
        // ];
        //
        // this.actionType = 'add';
        //
        // this.sourceItems = [
        //     {
        //         id:             { value: 'f7d17eb1-1a37-3c6f-519b-343e99dc726c', },
        //         number:         { value: 'F03570', },
        //         name:           { value: 'Small Seat - Orange', },
        //         status:         { value: 'Active', },
        //         previewImageId: { value: '47a683ab-909a-88c4-54d1-7c68f3573e3e', },
        //         type:           { value: ViewModelPropertyNonTradeItemType.ValueEnum.Article, },
        //     },
        // ];
    }


    registerForMultiEdit( targetAssemblies: Array<NonTradeSuggestItem>,
                          sourceNonTradeItems: Array<NonTradeListItem>,
                          actionType: string ): void {

        // clear first
        this.targetItems = [];

        // add target items
        targetAssemblies.forEach( ( item: NonTradeSuggestItem ) => {

            // already exists?
            const index: number = this.targetItems.findIndex(
                ( ( targetItem: TargetItem ) => targetItem.id === item.id ),
            );

            if ( index === -1 ) {
                this.targetItems.push(
                    new TargetItem(
                        item.id,
                        item.number,
                        item.name,
                        item.previewImageId,
                        [ '/assembly', item.number, 'components', 'edit', 'multi', ],
                    ),
                );
            }
        } );

        // add source items
        this.sourceItems = cloneDeep( sourceNonTradeItems );

        this.actionType = actionType;
    }

    setTargetItemAsSelected( id: string ): void {

        this.targetItems.forEach( ( item: TargetItem ) => {
            item.isSelected = ( item.id === id );
        } );

    }

    setCurrentTargetItemAsCancelled(): void {

        this.targetItems.forEach( ( item: TargetItem ) => {
            if ( item.isSelected ) {

                item.isDone      = false;
                item.isCancelled = true;
                this.refreshProcessedCount();
            }
        } );
    }

    setCurrentTargetItemAsDone(): void {

        this.targetItems.forEach( ( item: TargetItem ) => {
            if ( item.isSelected ) {

                item.isCancelled = false;
                item.isDone      = true;
                this.refreshProcessedCount();
            }
        } );
    }

    getNextNavigationLink(): Array<string> {

        const nextNotFinishedItem: TargetItem = this.getNextNotFinishedTargetItem();

        if ( nextNotFinishedItem ) {
            return nextNotFinishedItem.link;
        }

        return null;
    }

    getNavigationLinkToFirstItem(): Array<string> {

        if ( this.targetItems.length > 0 ) {
            return this.targetItems[ 0 ].link;
        }

        return null;
    }

    isCurrentTargetItemAlreadyDone(): boolean {
        const currentItem: TargetItem = this.getCurrentTargetItem();

        if ( currentItem ) {
            return currentItem.isDone;
        }

        return true;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private refreshProcessedCount(): void {
        let current: number = 0;

        this.targetItems.forEach( ( item: TargetItem ) => {

            if ( item.isCancelled
                || item.isDone ) {
                current++;
            }
        } );

        this.processedCount.next( current );
    }

    private getCurrentTargetItem(): TargetItem {
        const index: number = this.targetItems.findIndex( ( item: TargetItem ) => item.isSelected );

        if ( index !== -1 ) {
            return this.targetItems[ index ];
        }

        return null;
    }

    private getNextNotFinishedTargetItem(): TargetItem {

        // selected item
        const index: number = this.targetItems.findIndex( ( item: TargetItem ) => item.isSelected );

        if ( index === -1 ) {

            // no selected item? Take first not finished item
            const firstNotFinished: TargetItem = this.targetItems.find( ( item: TargetItem ) => !item.isFinished );

            if ( firstNotFinished ) {
                return firstNotFinished;
            }
        }

        if ( index !== -1 ) {

            // search from current selected to the end
            let nextNotFinished: TargetItem = this.targetItems.find(
                ( item: TargetItem ) => !item.isFinished,
                index + 1,
            );

            if ( !nextNotFinished ) {
                // search from the start
                nextNotFinished = this.targetItems.find( ( item: TargetItem ) => !item.isFinished );
            }

            if ( nextNotFinished ) {
                return nextNotFinished;
            }
        }

        return null;
    }
}
