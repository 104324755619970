/**
 * Contains all data needed by <rsp-datetime> component to render DateTime as html.
 */
export class DateTimeInfo {

    static months: Array<string> = [ 'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December', ];

    static weekdays: Array<string> = [ 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', ];

    readonly dateSeparator: string     = '-';
    readonly timeSeparator: string     = ':';
    readonly datetimeSeparator: string = ', ';  // separates date and time


    /**
     * Original Date object, source for all data.
     */
    date: Date;

    year: string;
    month: string;
    monthName: string;
    day: string;
    weekday: string;
    hours: string;
    minutes: string;
    seconds: string;
    ampm: string;
    timezoneOffset: string;

    constructor( datetime: Date ) {

        if ( !datetime ) { throw new Error( '"datetime" is null' ); }

        const month: number = datetime.getMonth();
        let hours: number = datetime.getHours();

        this.date           = datetime;
        this.year           = datetime.getFullYear().toString();
        this.month          = this.formatNumber( month + 1 );
        this.monthName      = DateTimeInfo.months[ month ];
        this.day            = this.formatNumber( datetime.getDate() );
        this.weekday        = DateTimeInfo.weekdays[ datetime.getDay() ];
        this.ampm           = hours >= 12 ? 'pm' : 'am';
        this.minutes        = this.formatNumber( datetime.getMinutes() );
        this.seconds        = this.formatNumber( datetime.getSeconds() );
        this.timezoneOffset = this.formatGMT( datetime.getTimezoneOffset() );

        hours      = hours % 12;
        hours      = hours ? hours : 12; // the hour '0' should be '12'
        this.hours = this.formatNumber( hours );
    }


    /**
     * Formats datetime using format: yyyy-MM-dd, h:mm:ss am/pm GTM+-00
     */
    getAsComparable( withTime: boolean = false,
                     withSeconds: boolean = false,
                     withTimezone: boolean = true ): string {

        // yyyy-MM-dd
        let result: string = this.year
            + this.dateSeparator
            + this.month
            + this.dateSeparator
            + this.day;


        if ( withTime ) {

            // h:mm[:ss]
            result += this.getTime( withSeconds );

            // am / pm
            result += ' ' + this.ampm;

            // timezone
            if ( withTimezone ) {
                result += ' ' + this.timezoneOffset;
            }
        }

        return result;
    }

    /**
     * Formats datetime using format: Saturday 24 December 2016, 02:00:00 pm GMT+2
     */
    getAsHumanReadable( withTime: boolean = false,
                        withSeconds: boolean = false,
                        withTimezone: boolean = true,
                        withWeekday: boolean = true,
                        withMonth: boolean = true,
                        withYear: boolean = true ): string {

        let result: string = '';

        // weekday
        if ( withWeekday ) {
            result += this.weekday + ' ';
        }

        // day month-name year
        result += this.date.getDate();

        if ( withMonth ) {
            result += ' ' + this.monthName;
        }

        if ( withYear ) {
            result += ' ' + this.year;
        }

        if ( withTime ) {

            // h:mm[:ss]
            result += this.getTime( withSeconds );

            // am / pm
            result += ' ' + this.ampm;

            // timezone
            if ( withTimezone ) {
                result += ' ' + this.timezoneOffset;
            }
        }

        return result;
    }


    getAsISO( withTime: boolean = false ): string {

        if ( withTime ) {
            return this.date.toISOString();
        }
        else {
            return this.year
                + '-'
                + this.month
                + '-'
                + this.day;
        }
    }

    getTime( withSeconds: boolean ): string {

        let result: string = '';

        // h:mm[:ss]
        result += this.datetimeSeparator
            + this.hours
            + this.timeSeparator
            + this.minutes;

        if ( withSeconds ) {
            result += this.timeSeparator + this.seconds;
        }

        return result;
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private formatNumber( value: number ): string {

        if ( value > 0 && value < 10 ) {
            return '0' + value;
        }

        return value + '';
    }

    private formatGMT( timezoneOffset: number ): string {

        const converted: number = ( timezoneOffset / 60 ) * -1;
        let result: string    = 'GMT';

        if ( converted !== 0 ) {
            result += converted > 0 ? '+' : ' ';
            result += converted;
        }

        return result;
    }
}
