/**
 * An enum which lists some of our domain entities. Useful for method params which can work with e.g. Styles and Translations and don't want to work with a
 * magic string (like `myFunction( type: 'style' | 'translation' ) {}`).
 */
export enum EntityTypeEnum {
    NonTradeItem,
    Concept,
    Cluster,
    Location,
    PriceBasedLayout,
    PrepressProduction,
    BusinessCommitment,
    User,
}
