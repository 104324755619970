<div
    *ngIf="files.length === 0"
    class="file-exports--tile-wrapper"
>
    <div class="file-exports--tile">
        <span
            class="file-exports--tile-icon"
            [class.is-zip]="type === 'Extract'"
            [class.is-excel]="type === 'Export'"
        ></span>
        <div>
            <rsp-supplementary-caption class="file-exports--tile-headline">
                <ng-container *ngIf="type === 'Extract'">
                    Extract Selection To Excel
                </ng-container>
                <ng-container *ngIf="type === 'Export'">
                    Export File Selection
                </ng-container>
            </rsp-supplementary-caption>
            <div class="file-exports--tile-summary">
                <ng-container *ngIf="type === 'Extract'">
                    Select <a [routerLink]="['/articles-and-assemblies']">Articles & Assemblies</a> and use export action in toolbar.
                </ng-container>
                <ng-container *ngIf="type === 'Export'">
                    Select files or documents and use "download selection".
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ol
    *ngIf="files.length > 0"
    class="file-exports--tile-wrapper"
>
    <li
        *ngFor="let file of visibleFiles"
        class="file-exports--tile"
    >

        <span
            class="file-exports--tile-icon"
            [class.is-zip]="file.fileKind.name === 'Files Export' || file.fileKind.name === 'Article Assembly Files Export'"
            [class.is-excel]="file.fileKind.name !== 'Files Export' && file.fileKind.name !== 'Article Assembly Files Export'"
        ></span>

        <div class="file-exports--tile-summary-container">
            <rsp-supplementary-caption class="file-exports--tile-headline">Download {{ file.fileKind.name }}</rsp-supplementary-caption>
            <rsp-download-link
                [fileGuidAnonymous]="file.id"
                class="file-exports--tile-summary"
            >
                <div class="file-exports--tile-summary">
                    <div class="file-exports--tile-summary-name">
                        {{ file.name }}
                    </div>
                    <div class="file-exports--tile-summary-date">
                        (<nur-date-time
                            [datetime]="file.uploadedOn"
                            [format]="'RelativeApproximation'"
                            [withTime]="true"
                            [withTimezone]="false"
                        ></nur-date-time>)
                    </div>
                </div>
            </rsp-download-link>
        </div>
    </li>

    <li
        *ngIf="visibleFiles.length < files.length"
        class="file-exports--tile"
    >
        <rsp-button
            [type]="'button'"
            [submodule]="'seamless'"
            (click)="showAll()"
            class="file-exports--tile-summary"
        >
            Show all…
        </rsp-button>
    </li>
</ol>

<rsp-file-exports-dialog [files]="files" [type]="type"></rsp-file-exports-dialog>
