/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { ArtworkCulture } from '../model/artworkCulture';
import { CreateArticleStyle } from '../model/createArticleStyle';
import { CreateArtwork } from '../model/createArtwork';
import { GraphicDetailsDto } from '../model/graphicDetailsDto';
import { ModelObject } from '../model/modelObject';
import { Reply } from '../model/reply';
import { ReplyGuid } from '../model/replyGuid';
import { StartArticleFilesBatchUpload } from '../model/startArticleFilesBatchUpload';
import { UpdateArticle } from '../model/updateArticle';
import { UpdateArticleSPMState } from '../model/updateArticleSPMState';
import { UpdateArticlesEngineeringPartner } from '../model/updateArticlesEngineeringPartner';
import { UpdateArtwork } from '../model/updateArtwork';
import { UpdateStyle } from '../model/updateStyle';
import { ViewModelItemArticleDetailArticleDetailChangeability } from '../model/viewModelItemArticleDetailArticleDetailChangeability';
import { ViewModelItemArticleInfo } from '../model/viewModelItemArticleInfo';
import { ViewModelItemArticleSPMDetails } from '../model/viewModelItemArticleSPMDetails';
import { ViewModelItemArticleSummary } from '../model/viewModelItemArticleSummary';
import { ViewModelItemItemsWithFileInfo } from '../model/viewModelItemItemsWithFileInfo';
import { ViewModelListArticleAssembly } from '../model/viewModelListArticleAssembly';
import { ViewModelListArtworkItem } from '../model/viewModelListArtworkItem';
import { ViewModelListBrandLogoInfo } from '../model/viewModelListBrandLogoInfo';
import { ViewModelListClusterInfo } from '../model/viewModelListClusterInfo';
import { ViewModelListFileContainerWithReference } from '../model/viewModelListFileContainerWithReference';
import { ViewModelListGraphicListItem } from '../model/viewModelListGraphicListItem';
import { ViewModelListStyleItem } from '../model/viewModelListStyleItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class ArticleManagementArticlesService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesCreate(name: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemArticleInfo>;
    public articlesCreate(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemArticleInfo>>;
    public articlesCreate(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemArticleInfo>>;
    public articlesCreate(name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling articlesCreate.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined) {
            queryParameters = queryParameters.set('name', <any> name);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<ViewModelItemArticleInfo>(
                `${this.basePath}/api/v1/articlemanagement/articles`,
                null,
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Create Artwork
     * 
     * @param articleId 
     * @param styleId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesCreateArtwork(articleId: string, styleId: string, command: CreateArtwork, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public articlesCreateArtwork(articleId: string, styleId: string, command: CreateArtwork, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public articlesCreateArtwork(articleId: string, styleId: string, command: CreateArtwork, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public articlesCreateArtwork(articleId: string, styleId: string, command: CreateArtwork, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesCreateArtwork.');
        }
        if (styleId === null || styleId === undefined) {
            throw new Error('Required parameter styleId was null or undefined when calling articlesCreateArtwork.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling articlesCreateArtwork.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles/${encodeURIComponent(String(styleId))}/artworks`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Creates new graphic and assigns it to the article.
     * 
     * @param articleId 
     * @param details 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesCreateGraphic(articleId: string, details: GraphicDetailsDto, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public articlesCreateGraphic(articleId: string, details: GraphicDetailsDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public articlesCreateGraphic(articleId: string, details: GraphicDetailsDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public articlesCreateGraphic(articleId: string, details: GraphicDetailsDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesCreateGraphic.');
        }
        if (details === null || details === undefined) {
            throw new Error('Required parameter details was null or undefined when calling articlesCreateGraphic.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/graphics`,
                details,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Create Style
     * 
     * @param articleId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesCreateStyle(articleId: string, command: CreateArticleStyle, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public articlesCreateStyle(articleId: string, command: CreateArticleStyle, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public articlesCreateStyle(articleId: string, command: CreateArticleStyle, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public articlesCreateStyle(articleId: string, command: CreateArticleStyle, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesCreateStyle.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling articlesCreateStyle.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Delete Artwork
     * 
     * @param articleId 
     * @param styleId 
     * @param artworkId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesDeleteArtwork(articleId: string, styleId: string, artworkId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesDeleteArtwork(articleId: string, styleId: string, artworkId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesDeleteArtwork(articleId: string, styleId: string, artworkId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesDeleteArtwork(articleId: string, styleId: string, artworkId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesDeleteArtwork.');
        }
        if (styleId === null || styleId === undefined) {
            throw new Error('Required parameter styleId was null or undefined when calling articlesDeleteArtwork.');
        }
        if (artworkId === null || artworkId === undefined) {
            throw new Error('Required parameter artworkId was null or undefined when calling articlesDeleteArtwork.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles/${encodeURIComponent(String(styleId))}/artworks/${encodeURIComponent(String(artworkId))}`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Delete Style
     * 
     * @param styleId 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesDeleteStyle(styleId: string, articleId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesDeleteStyle(styleId: string, articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesDeleteStyle(styleId: string, articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesDeleteStyle(styleId: string, articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (styleId === null || styleId === undefined) {
            throw new Error('Required parameter styleId was null or undefined when calling articlesDeleteStyle.');
        }
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesDeleteStyle.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles/${encodeURIComponent(String(styleId))}`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetArticleAssemblies
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetArticleAssemblies(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListArticleAssembly>;
    public articlesGetArticleAssemblies(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListArticleAssembly>>;
    public articlesGetArticleAssemblies(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListArticleAssembly>>;
    public articlesGetArticleAssemblies(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetArticleAssemblies.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListArticleAssembly>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/assemblies`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetArticleDetail
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetArticleDetail(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemArticleDetailArticleDetailChangeability>;
    public articlesGetArticleDetail(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemArticleDetailArticleDetailChangeability>>;
    public articlesGetArticleDetail(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemArticleDetailArticleDetailChangeability>>;
    public articlesGetArticleDetail(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetArticleDetail.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemArticleDetailArticleDetailChangeability>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/detail`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetFileContainersByArticleId
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetArticleFiles(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListFileContainerWithReference>;
    public articlesGetArticleFiles(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListFileContainerWithReference>>;
    public articlesGetArticleFiles(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListFileContainerWithReference>>;
    public articlesGetArticleFiles(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetArticleFiles.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListFileContainerWithReference>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/files`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetArticleIdByNumber
     * 
     * @param articleNumber 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetArticleIdByNumber(articleNumber: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public articlesGetArticleIdByNumber(articleNumber: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public articlesGetArticleIdByNumber(articleNumber: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public articlesGetArticleIdByNumber(articleNumber: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleNumber === null || articleNumber === undefined) {
            throw new Error('Required parameter articleNumber was null or undefined when calling articlesGetArticleIdByNumber.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (articleNumber !== undefined) {
            queryParameters = queryParameters.set('articleNumber', <any> articleNumber);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<string>(
                `${this.basePath}/api/v1/articlemanagement/articles/GetArticleIdByNumber`,
                
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetArticleInfoById
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetArticleInfoById(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemArticleInfo>;
    public articlesGetArticleInfoById(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemArticleInfo>>;
    public articlesGetArticleInfoById(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemArticleInfo>>;
    public articlesGetArticleInfoById(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetArticleInfoById.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemArticleInfo>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/info`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetArticleSPMDetails
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetArticleSPMDetails(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemArticleSPMDetails>;
    public articlesGetArticleSPMDetails(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemArticleSPMDetails>>;
    public articlesGetArticleSPMDetails(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemArticleSPMDetails>>;
    public articlesGetArticleSPMDetails(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetArticleSPMDetails.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemArticleSPMDetails>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/spmdetails`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetSummary
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetArticleSummary(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemArticleSummary>;
    public articlesGetArticleSummary(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemArticleSummary>>;
    public articlesGetArticleSummary(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemArticleSummary>>;
    public articlesGetArticleSummary(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetArticleSummary.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemArticleSummary>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/summary`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Get Artworks
     * 
     * @param articleId 
     * @param styleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetArtworks(articleId: string, styleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListArtworkItem>;
    public articlesGetArtworks(articleId: string, styleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListArtworkItem>>;
    public articlesGetArtworks(articleId: string, styleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListArtworkItem>>;
    public articlesGetArtworks(articleId: string, styleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetArtworks.');
        }
        if (styleId === null || styleId === undefined) {
            throw new Error('Required parameter styleId was null or undefined when calling articlesGetArtworks.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListArtworkItem>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles/${encodeURIComponent(String(styleId))}/artworks`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetBrandLogoInfosByArticleId
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetBrandLogoOptionsByArticleId(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListBrandLogoInfo>;
    public articlesGetBrandLogoOptionsByArticleId(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListBrandLogoInfo>>;
    public articlesGetBrandLogoOptionsByArticleId(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListBrandLogoInfo>>;
    public articlesGetBrandLogoOptionsByArticleId(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetBrandLogoOptionsByArticleId.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListBrandLogoInfo>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/brandlogos`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetClusters
     * 
     * @param articleId 
     * @param conceptId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetClusters(articleId: string, conceptId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListClusterInfo>;
    public articlesGetClusters(articleId: string, conceptId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListClusterInfo>>;
    public articlesGetClusters(articleId: string, conceptId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListClusterInfo>>;
    public articlesGetClusters(articleId: string, conceptId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetClusters.');
        }
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling articlesGetClusters.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListClusterInfo>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/concepts/${encodeURIComponent(String(conceptId))}/clusters`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetFilesFromServer
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetFilesFromServer(observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemItemsWithFileInfo>;
    public articlesGetFilesFromServer(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemItemsWithFileInfo>>;
    public articlesGetFilesFromServer(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemItemsWithFileInfo>>;
    public articlesGetFilesFromServer(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemItemsWithFileInfo>(
                `${this.basePath}/api/v1/articlemanagement/articles/get_files_from_server`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Lists all graphics assigned to the article.
     * 
     * @param articleid Article id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetGraphicListItemsByArticleId(articleid: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListGraphicListItem>;
    public articlesGetGraphicListItemsByArticleId(articleid: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListGraphicListItem>>;
    public articlesGetGraphicListItemsByArticleId(articleid: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListGraphicListItem>>;
    public articlesGetGraphicListItemsByArticleId(articleid: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleid === null || articleid === undefined) {
            throw new Error('Required parameter articleid was null or undefined when calling articlesGetGraphicListItemsByArticleId.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListGraphicListItem>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleid))}/graphics`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetReadyStyles
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetReadyStyles(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListStyleItem>;
    public articlesGetReadyStyles(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListStyleItem>>;
    public articlesGetReadyStyles(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListStyleItem>>;
    public articlesGetReadyStyles(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetReadyStyles.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListStyleItem>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/ready-styles`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Get Styles
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetStyles(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListStyleItem>;
    public articlesGetStyles(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListStyleItem>>;
    public articlesGetStyles(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListStyleItem>>;
    public articlesGetStyles(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetStyles.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListStyleItem>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Get Virtual Article Numbers
     * 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesGetVirtualNumbers(articleId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<string>>;
    public articlesGetVirtualNumbers(articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<string>>>;
    public articlesGetVirtualNumbers(articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<string>>>;
    public articlesGetVirtualNumbers(articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesGetVirtualNumbers.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<Array<string>>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/virtual/numbers`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * ImportFilesFromServer
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesImportFilesFromServer(command: StartArticleFilesBatchUpload, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesImportFilesFromServer(command: StartArticleFilesBatchUpload, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesImportFilesFromServer(command: StartArticleFilesBatchUpload, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesImportFilesFromServer(command: StartArticleFilesBatchUpload, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling articlesImportFilesFromServer.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/import_files_from_server`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * RequestPrepressProduction
     * 
     * @param articleId 
     * @param styleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesRequestPrepressProduction(articleId: string, styleId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesRequestPrepressProduction(articleId: string, styleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesRequestPrepressProduction(articleId: string, styleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesRequestPrepressProduction(articleId: string, styleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesRequestPrepressProduction.');
        }
        if (styleId === null || styleId === undefined) {
            throw new Error('Required parameter styleId was null or undefined when calling articlesRequestPrepressProduction.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles/${encodeURIComponent(String(styleId))}/request-prepress-productions`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * RequestPrepressProductionFiles
     * 
     * @param artworkCultures 
     * @param articleId 
     * @param styleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesRequestPrepressProductionFiles(artworkCultures: Array<ArtworkCulture>, articleId: string, styleId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesRequestPrepressProductionFiles(artworkCultures: Array<ArtworkCulture>, articleId: string, styleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesRequestPrepressProductionFiles(artworkCultures: Array<ArtworkCulture>, articleId: string, styleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesRequestPrepressProductionFiles(artworkCultures: Array<ArtworkCulture>, articleId: string, styleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (artworkCultures === null || artworkCultures === undefined) {
            throw new Error('Required parameter artworkCultures was null or undefined when calling articlesRequestPrepressProductionFiles.');
        }
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesRequestPrepressProductionFiles.');
        }
        if (styleId === null || styleId === undefined) {
            throw new Error('Required parameter styleId was null or undefined when calling articlesRequestPrepressProductionFiles.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles/${encodeURIComponent(String(styleId))}/request-prepress-production-files`,
                artworkCultures,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateArticlesEngineeringPartner
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesUpdateArticlesEngineeringPartner(command: UpdateArticlesEngineeringPartner, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public articlesUpdateArticlesEngineeringPartner(command: UpdateArticlesEngineeringPartner, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public articlesUpdateArticlesEngineeringPartner(command: UpdateArticlesEngineeringPartner, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public articlesUpdateArticlesEngineeringPartner(command: UpdateArticlesEngineeringPartner, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling articlesUpdateArticlesEngineeringPartner.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/articles/UpdateArticlesEngineeringPartner`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Artwork
     * 
     * @param articleId 
     * @param styleId 
     * @param artworkId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesUpdateArtwork(articleId: string, styleId: string, artworkId: string, command: UpdateArtwork, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesUpdateArtwork(articleId: string, styleId: string, artworkId: string, command: UpdateArtwork, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesUpdateArtwork(articleId: string, styleId: string, artworkId: string, command: UpdateArtwork, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesUpdateArtwork(articleId: string, styleId: string, artworkId: string, command: UpdateArtwork, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesUpdateArtwork.');
        }
        if (styleId === null || styleId === undefined) {
            throw new Error('Required parameter styleId was null or undefined when calling articlesUpdateArtwork.');
        }
        if (artworkId === null || artworkId === undefined) {
            throw new Error('Required parameter artworkId was null or undefined when calling articlesUpdateArtwork.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling articlesUpdateArtwork.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles/${encodeURIComponent(String(styleId))}/artworks/${encodeURIComponent(String(artworkId))}`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Detail
     * 
     * @param articleId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesUpdateDetail(articleId: string, command: UpdateArticle, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesUpdateDetail(articleId: string, command: UpdateArticle, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesUpdateDetail(articleId: string, command: UpdateArticle, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesUpdateDetail(articleId: string, command: UpdateArticle, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesUpdateDetail.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling articlesUpdateDetail.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/detail`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Files
     * 
     * @param articleId 
     * @param fileContainerIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesUpdateFileContainers(articleId: string, fileContainerIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesUpdateFileContainers(articleId: string, fileContainerIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesUpdateFileContainers(articleId: string, fileContainerIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesUpdateFileContainers(articleId: string, fileContainerIds: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesUpdateFileContainers.');
        }
        if (fileContainerIds === null || fileContainerIds === undefined) {
            throw new Error('Required parameter fileContainerIds was null or undefined when calling articlesUpdateFileContainers.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/files`,
                fileContainerIds,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateSPMState
     * 
     * @param command 
     * @param articleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesUpdateSPMState(command: UpdateArticleSPMState, articleId: string, observe?: 'body', reportProgress?: boolean): Observable<ModelObject>;
    public articlesUpdateSPMState(command: UpdateArticleSPMState, articleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelObject>>;
    public articlesUpdateSPMState(command: UpdateArticleSPMState, articleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelObject>>;
    public articlesUpdateSPMState(command: UpdateArticleSPMState, articleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling articlesUpdateSPMState.');
        }
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesUpdateSPMState.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<ModelObject>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/UpdateSPMState`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Status
     * 
     * @param articleId 
     * @param status 
     * @param reason 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesUpdateStatus(articleId: string, status: string, reason: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesUpdateStatus(articleId: string, status: string, reason: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesUpdateStatus(articleId: string, status: string, reason: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesUpdateStatus(articleId: string, status: string, reason: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesUpdateStatus.');
        }
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling articlesUpdateStatus.');
        }
        if (reason === null || reason === undefined) {
            throw new Error('Required parameter reason was null or undefined when calling articlesUpdateStatus.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined) {
            queryParameters = queryParameters.set('status', <any> status);
        }
        if (reason !== undefined) {
            queryParameters = queryParameters.set('reason', <any> reason);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/status`,
                null,
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateStatusForArticles
     * 
     * @param status 
     * @param reason 
     * @param articleIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesUpdateStatusForArticles(status: string, reason: string, articleIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesUpdateStatusForArticles(status: string, reason: string, articleIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesUpdateStatusForArticles(status: string, reason: string, articleIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesUpdateStatusForArticles(status: string, reason: string, articleIds: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (status === null || status === undefined) {
            throw new Error('Required parameter status was null or undefined when calling articlesUpdateStatusForArticles.');
        }
        if (reason === null || reason === undefined) {
            throw new Error('Required parameter reason was null or undefined when calling articlesUpdateStatusForArticles.');
        }
        if (articleIds === null || articleIds === undefined) {
            throw new Error('Required parameter articleIds was null or undefined when calling articlesUpdateStatusForArticles.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (status !== undefined) {
            queryParameters = queryParameters.set('status', <any> status);
        }
        if (reason !== undefined) {
            queryParameters = queryParameters.set('reason', <any> reason);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/UpdateStatusForArticles`,
                articleIds,
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Style
     * 
     * @param command 
     * @param articleId 
     * @param styleId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public articlesUpdateStyle(command: UpdateStyle, articleId: string, styleId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public articlesUpdateStyle(command: UpdateStyle, articleId: string, styleId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public articlesUpdateStyle(command: UpdateStyle, articleId: string, styleId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public articlesUpdateStyle(command: UpdateStyle, articleId: string, styleId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling articlesUpdateStyle.');
        }
        if (articleId === null || articleId === undefined) {
            throw new Error('Required parameter articleId was null or undefined when calling articlesUpdateStyle.');
        }
        if (styleId === null || styleId === undefined) {
            throw new Error('Required parameter styleId was null or undefined when calling articlesUpdateStyle.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/articles/${encodeURIComponent(String(articleId))}/styles/${encodeURIComponent(String(styleId))}`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
