<ng-container *ngIf="!isEditMode
                        && defaultValue !== null
                        && defaultValue !== undefined">{{ defaultValue }}</ng-container>
<ng-container *ngIf="!isEditMode
                        && ( defaultValue === null || defaultValue === undefined || defaultValue === '' )
                        && showNotAvailable">
    <rsp-not-available [notAvailable]="notAvailableString"></rsp-not-available>
</ng-container>
<ng-container *ngIf="isEditMode">
    <rsp-dropdown
        [defaultValue]="defaultValue"
        [placeholder]="placeholder"
        [inputFieldHtmlId]="inputFieldHtmlId"
        [allowClear]="true"
        [withSuggester]="true"
        [originalItems]="options"
        (filteredItems)="setFilteredOptions( $event )"
        (selected)="setOption( $event )"
    >
        <rsp-dropdown-item *ngFor="let option of filteredOptions" [item]="option">
            {{option.name}}
        </rsp-dropdown-item>
    </rsp-dropdown>
</ng-container>

<rsp-messages
    *ngIf="!hideValidationMessages && validationMessages.length > 0"
    [type]="messageTypeError"
    [messages]="validationMessages"
></rsp-messages>


