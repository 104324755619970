<rsp-card>
    <rsp-card-headline>Required Files</rsp-card-headline>
    <rsp-card-content>
        <table
            *ngIf="collectionView && collectionView.sourceItems.length > 0"
            class="required-files-table"
        >
            <thead>
                <tr>
                    <th class="required-files-table--head-cell">
                        <rsp-column-header
                            [collectionView]="collectionView"
                            [propertyName]="'fileKindName'"
                        >
                            File Kind
                        </rsp-column-header>
                    </th>
                    <th class="required-files-table--head-cell">
                        <rsp-column-header
                            [collectionView]="collectionView"
                            [propertyName]="'fileExists'"
                        >
                            Is Available?
                        </rsp-column-header>
                    </th>
                    <th class="required-files-table--head-cell">
                        <rsp-column-header
                            [collectionView]="collectionView"
                            [propertyName]="'isRequired'"
                        >
                            Is Required?
                        </rsp-column-header>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    *ngFor="let item of collectionView.sourceItems"
                    (click)="toggleIsRequiredAfterRowClick( $event, item )"
                >
                    <td class="required-files-table--body-cell">{{ item.fileKindName }}</td>
                    <td class="required-files-table--body-cell">
                        <input
                            rspCheckbox
                            [checked]="item.fileExists"
                            type="checkbox"
                            disabled
                        />
                    </td>
                    <td class="required-files-table--body-cell">
                        <input
                            rspCheckbox
                            [disabled]="!isEditMode"
                            [(ngModel)]="item.isRequired"
                            (ngModelChange)="updateFormControl()"
                            type="checkbox"
                        />
                    </td>
                </tr>
            </tbody>
        </table>
    </rsp-card-content>
</rsp-card>
