import { Component, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConceptService, } from '../concept.service';
import { ArticleManagementConceptsService } from '../../../../../shared/api';
import { ConceptServiceModelItem, CurrentUserContext, ViewModelListConceptServiceModelItem, } from '../../../../../shared/model';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { ServiceModelItem } from '../../../../../shared/ui/service-models-table/service-model-item';
import { CurrentUserService } from '../../../../../core/current-user.service';


@Component( {
    selector:    'rsp-concept-service-levels',
    templateUrl: './concept-service-levels.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './concept-service-levels.component.scss', ],
} )
export class ConceptServiceLevelsComponent implements OnInit, OnDestroy, HasLoadingIndicator {

    serviceModels: Array<ServiceModelItem>;
    isLoading: Subscription;

    userHasEditRight: boolean;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private currentUserService: CurrentUserService,
        private conceptsApi: ArticleManagementConceptsService,
        private conceptService: ConceptService,
    ) {
    }

    ngOnInit(): void {
        this.currentUserService
            .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersConceptServiceLevelsEdit )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( hasRight: boolean ) => {
                this.userHasEditRight = hasRight;
            } );

        // Two entry points:
        //  - of( true ) - first component load
        //  - this.pblService.summary$ - prev/next navigation
        merge( of( true ), this.conceptService.summary$ )
            .pipe(
                loadWithProgressIndicator( () => this.conceptsApi.conceptsGetServiceLevels( this.conceptService.conceptId ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: ViewModelListConceptServiceModelItem ) => {
                this.serviceModels = this.convertToServiceModelItems( result.data );
            } );
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }


    onEditClick(): void {
        this.router.navigate( [ './edit' ], { relativeTo: this.activatedRoute } );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private convertToServiceModelItems( items: Array<ConceptServiceModelItem> ): Array<ServiceModelItem> {

        if ( !items ) {
            return null;
        }

        return items.map( ( item: ConceptServiceModelItem ) => ServiceModelItem.createFromConceptServiceModelItem( item ) );
    }
}
