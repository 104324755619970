<a
    class="user-line"
    [class.is-non-trade-item-line-checked]="isSelected"
    [routerLink]="[ '/user', item.id ]"
    (click)="setIndex()"
>

    <label
        [for]="item.id"
        class="user-line--name"
    >
        <span class="user-line--first-name">{{ item.firstName }}</span>
        {{ item.familyName }}
    </label>

    <div
        class="user-line--company"
        [class.is-internal-user]="item.type === userTypeEnum.Internal"
    >
        {{ userType }}
    </div>

    <div class="user-line--global-id">
        {{ item.globalId }}
    </div>

    <div class="user-line--status">
        <rsp-status
            [status]="item.status"
            [type]="statusEnumType"
            [displayMode]="'tag'"
        ></rsp-status>
    </div>
</a>
