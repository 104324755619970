import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';


/**
 * Centralizes all window events.
 */
@Injectable()
export class WindowEventService {

    /**
     * Occurs when window has been resized.
     */
    resized$: Observable<boolean>;

    /**
     * Occurs when window has been focused;
     */
    focused$: Observable<boolean>;

    /**
     * Occurs when window has been blurred.
     */
    blurred$: Observable<boolean>;


    private resized: Subject<boolean> = new Subject();
    private focused: Subject<boolean> = new Subject();
    private blurred: Subject<boolean> = new Subject();


    constructor() {
        this.resized$ = this.resized.asObservable();
        this.focused$ = this.focused.asObservable();
        this.blurred$ = this.blurred.asObservable();
    }

    notifyWindowResized(): void {
        this.resized.next( true );
    }

    notifyWindowFocused(): void {
        this.focused.next( true );
    }

    notifyWindowBlurred(): void {
        this.blurred.next( true );
    }
}
