import { Input, Output, EventEmitter, OnDestroy, Component, } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, } from '@angular/forms';
import { Subject } from 'rxjs';
import { uniq } from 'lodash';

import { NOT_AVAILABLE } from '../../../ui/not-available/not-available.component';
import { ValidationMessagesService } from '../../validation/validation-messages.service';


/**
 * Base class for all display-edit-* components.
 */
@Component({
    template: '',
})
export abstract class DisplayEditBaseComponent implements OnDestroy {

    @Input() myFormControl: UntypedFormControl;
    @Input() myFormGroup: UntypedFormGroup;

    /**
     * Specified if control should be displayed on display or edit mode.
     */
    @Input() isEditMode: boolean;

    /**
     * Specifies if "n/a" should be displayed when provided value is not empty (null|undefined) in the display mode.
     * @type {boolean}
     */
    @Input() showNotAvailable: boolean = true;

    /**
     * Specifies string which is displayed when given value is empty and [showNotAvailable] is true. Default 'n / a'.
     * @type {string}
     */
    @Input() notAvailableString: string = NOT_AVAILABLE;


    /**
     * Suppress the display validation messages. Useful when the parent component wants to make usage of the `validationMessagesChanged`-event and display the
     * validation messages by itself.
     * Must be implemented by each child classes template.
     */
    @Input() hideValidationMessages: boolean = false;

    /**
     * Optional text for the HTML `placeholder` attribute.
     */
    @Input() placeholder: string;

    /**
     * Is emitted when a display-edit-component gets invalid and provides the validation messages to the parent component.
     */
    @Output() validationMessagesChanged: EventEmitter<string[]> = new EventEmitter();

    protected isDestroyed: Subject<boolean> = new Subject<boolean>();

    set validationMessages( validationMessages: Array<string> ) { this.setValidationMessages( validationMessages ); }
    get validationMessages(): Array<string> { return this._validationMessages; }
    private _validationMessages: Array<string> = [];


    constructor( protected validationMessagesService: ValidationMessagesService ) {
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    startEditMode(): void {
        if ( this.isEditMode && !this.myFormControl && !this.myFormGroup ) {
            throw new Error( 'Attribute "myFormControl" or "myFormGroup" must be set!' );
        }

        if (
            this.validationMessagesService.hasControlRequiredConstraint( this.myFormControl )
            || this.validationMessagesService.hasControlRequiredConstraint( this.myFormGroup )
        ) {
            this.validationMessages = ( this.validationMessagesService.getValidationMessages( { 'required': true } ) );
        }
    }


    setValidationMessages( validationMessages: Array<string> ): void {
        this._validationMessages = validationMessages;
        this.validationMessagesChanged.emit( validationMessages );
    }

    updateValidationMessages( subFormGroupOrFormControl: UntypedFormGroup | UntypedFormControl ): void {
        // clear previous error message (if any)
        this.validationMessages = [];

        // check if we can exit early (because there will be no validation messages when nothing is dirty and everything is valid)
        if ( !this.isFormGroupOrFormControlDirty( subFormGroupOrFormControl ) ||
              this.isFormGroupOrFormControlValid( subFormGroupOrFormControl ) ) {
            return;
        }

        // collect validation errors and messages
        const validationMessages: Array<string> = [];


        if ( subFormGroupOrFormControl instanceof UntypedFormGroup ) {
            // add error messages of each formControl to validationMessages
            Object
                .keys( subFormGroupOrFormControl.controls )
                .filter(  ( controlName: string ) => subFormGroupOrFormControl.controls[ controlName ].errors )
                .map(     ( controlName: string ) => subFormGroupOrFormControl.controls[ controlName ].errors )
                .forEach( ( errors: ValidationErrors ) => validationMessages.push( ... this.validationMessagesService.getValidationMessages( errors ) ) )
            ;

            // add error messages for formGroup
            validationMessages.push( ... this.validationMessagesService.getValidationMessages( subFormGroupOrFormControl.errors ) );
        }
        else {
            const nonCustomErrors: { [ errorId: string ]: any } = {};
            Object
                .keys( subFormGroupOrFormControl.errors )
                .forEach( ( errorId: string ) => nonCustomErrors[ errorId ] = subFormGroupOrFormControl.errors[ errorId ] );

            validationMessages.push( ... this.validationMessagesService.getValidationMessages( nonCustomErrors ) );
        }

        this.validationMessages = uniq( validationMessages );

        if ( !this.validationMessages.length && this.validationMessagesService.hasControlRequiredConstraint( subFormGroupOrFormControl )) {
            this.validationMessages = ( this.validationMessagesService.getValidationMessages( { 'required': true } ) );
        }
    }

    private isFormGroupOrFormControlDirty( subFormGroupOrFormControl: UntypedFormGroup | UntypedFormControl ): boolean {
        let isDirty: boolean;
        if ( subFormGroupOrFormControl instanceof UntypedFormGroup ) {
            isDirty = Object
                .keys( subFormGroupOrFormControl.controls )
                .some( ( formControlName: string ) => subFormGroupOrFormControl.controls[ formControlName ].dirty );
        }
        else {
            isDirty = subFormGroupOrFormControl.dirty;
        }

        return isDirty;
    }

    private isFormGroupOrFormControlValid( subFormGroupOrFormControl: UntypedFormGroup | UntypedFormControl ): boolean {
        let isValid: boolean;
        if ( subFormGroupOrFormControl instanceof UntypedFormGroup ) {
            isValid = Object
                .keys( subFormGroupOrFormControl.controls )
                .every( ( formControlName: string ) => subFormGroupOrFormControl.controls[ formControlName ].valid );

            if ( !subFormGroupOrFormControl.valid ) {
                isValid = false;
            }
        }
        else {
            isValid = subFormGroupOrFormControl.valid;
        }

        return isValid;
    }

}
