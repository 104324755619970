import { Component, EventEmitter, Input, Output } from '@angular/core';

import { DistributionChannelListItem } from '../../model';
import { MessageType } from '../messages/messages.component';

export interface DistributionChannelSelection {
    selectedDistributionChannels: DistributionChannelListItem[];
    isSelectionValid: boolean;
}

@Component( {
    selector:    'rsp-distribution-channels',
    templateUrl: './distribution-channels.component.html',
    styleUrls:   [ './distribution-channels.component.scss', ],
} )
export class DistributionChannelsComponent {
    @Input() distributionChannels: DistributionChannelListItem[];
    @Input() selectedDistributionChannels: DistributionChannelListItem[] = [];
    @Input() isEditMode: boolean = false;
    @Input() isRequired: boolean;

    @Output() selectionChanged: EventEmitter<DistributionChannelSelection> = new EventEmitter();

    messageTypeError: MessageType = MessageType.Error;

    toggleDistributionChannelSelection( distributionChannelToToggle: DistributionChannelListItem ): void {
        const index: number = this.selectedDistributionChannels.findIndex(
            ( selectedDistributionChannel: DistributionChannelListItem ) => distributionChannelToToggle.id === selectedDistributionChannel.id,
        );

        if ( index >= 0 ) {
            this.selectedDistributionChannels.splice( index, 1 );
        }
        else {
            this.selectedDistributionChannels.push( distributionChannelToToggle );
        }

        this.selectionChanged.emit( {
            selectedDistributionChannels: this.selectedDistributionChannels,
            isSelectionValid:             this.selectedDistributionChannels.length > 0,
        } );
    }

    isDistributionChannelSelected( distributionChannel: DistributionChannelListItem ): boolean {
        return this.selectedDistributionChannels.some(
            ( selectedDistributionChannel: DistributionChannelListItem ) => distributionChannel.id === selectedDistributionChannel.id,
        );
    }
}
