import { Component, OnInit, Output, EventEmitter, Input, } from '@angular/core';
import { Subscription,                                   } from 'rxjs';

import { ArticleManagementNonTradeItemsService } from '../../../api';
import { NonTradeSuggestItem,                   } from '../../../model/nonTradeSuggestItem';
import { ViewModelPagedListNonTradeSuggestItem, } from '../../../model/viewModelPagedListNonTradeSuggestItem';
import { EntityTypeEnum } from '../../../model/enum/entity-type.enum';
import { NonTradeItemSuggestSearchConfiguration } from '../../../model/nonTradeItemSuggestSearchConfiguration';

@Component( {
    selector:    'rsp-non-trade-item-suggester',
    templateUrl: './non-trade-item-suggester.component.html',
    styleUrls:   [
        '../../../scss/05_module/simple-list.scss',
        './non-trade-item-suggester.component.scss',
    ],
} )
export class NonTradeItemSuggesterComponent implements OnInit {

    /**
     * Event fired when user select one element from the suggestion list.
     * 'sourceItem' used for creation of SuggestionItem will be used as event's parameter.
     */
    @Output() selected: EventEmitter<NonTradeSuggestItem> = new EventEmitter<NonTradeSuggestItem>();

    @Input() placeholder: string;

    @Input() itemFilter: string[] = [];

    @Input() stateFilter: NonTradeItemSuggestSearchConfiguration.StatesEnum[] = [];

    @Input() displayValue: string;

    @Input() type: NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum;

    /**
     * true: find only items, which are ready to order
     * false: find only items, which are NOT ready to order
     * undefined: find all items
     */
    @Input() isReadyToOrder: boolean;

    items: NonTradeSuggestItem[] = [];

    isLoading: Subscription;

    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.NonTradeItem;


    constructor( private nonTradeItemApi: ArticleManagementNonTradeItemsService ) {
    }

    ngOnInit(): void {

        if ( !this.placeholder ) {
            if ( this.type ) {
                this.placeholder = 'Search ' + NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum.toString( this.type );
            }
            else {
                this.placeholder = 'Search Article / Assembly';
            }
        }

    }

    searchItems( searchTerm: string ): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }

        if ( !searchTerm || !searchTerm.trim() ) {
            this.items = [];

            return;
        }

        this.isLoading =
            this.nonTradeItemApi.nonTradeItemsSuggest( {
                term:             searchTerm,
                page:             1,
                size:             50,
                ignoreIds:        this.itemFilter.length ? this.itemFilter : undefined,
                states:           this.stateFilter.length ? this.stateFilter : undefined,
                nonTradeItemType: this.type,
                isReadyToOrder:   this.isReadyToOrder,
            } )
                .subscribe( ( searchResult: ViewModelPagedListNonTradeSuggestItem ) => {
                    this.items = searchResult.data;
                } );
    }


    selectedItem( item: NonTradeSuggestItem ): void {
        if ( item ) {
            this.selected.emit( item );
        }
        else {
            this.selected.emit( null );
        }
    }

}
