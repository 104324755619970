import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil, tap } from 'rxjs/operators';

import { DetailPagePrevNextNavigationService } from './detail-page-prev-next-navigation.service';
import { DetailPagePrevNextNavigationConfig } from './detail-page-prev-next-navigation-config.model';

@Component({
    template: '',
})
export abstract class DetailPagePrevNextNavigationComponent<T> implements OnInit, OnDestroy {

    config: Observable<DetailPagePrevNextNavigationConfig>;

    nextButtonClicks: Subject<void> = new Subject<void>();
    prevButtonClicks: Subject<void> = new Subject<void>();


    private prevButtonClicks$: Observable<void>;
    private nextButtonClicks$: Observable<void>;

    private itemsToSkipCount: number = 0;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private prevNextNavigationService: DetailPagePrevNextNavigationService<T>,
    ) {
        this.config = this.prevNextNavigationService.config$;

        this.prevButtonClicks$ = this.prevButtonClicks.asObservable();
        this.nextButtonClicks$ = this.nextButtonClicks.asObservable();
    }


    ngOnInit(): void {

        // component is just created, use current url to set LastUrlPart.
        // From now, when user navigates inside the details area, it will be
        // updated automatically (see registerRouteEventsObserver()).
        const lastPart: string = this.getLastPartFromUrl( this.router.url );
        this.prevNextNavigationService.setLastUrlPart( lastPart );

        this.registerRouteEventsObserver();

        this.registerPrevNextButtonsObservers();
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private registerRouteEventsObserver(): void {

        // TODO: not the perfect solution, works only for routes like "article/F06695/details". Routes as "article/F06695/details/edit" cause an error.

        // observe route changes and save last url part
        this.router
            .events
            .pipe(
                filter( ( event: any ) => event instanceof NavigationEnd ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( navigationEnd: NavigationEnd ) => {

                const url: string = navigationEnd.urlAfterRedirects
                    ? navigationEnd.urlAfterRedirects
                    : navigationEnd.url;

                const lastPart: string = this.getLastPartFromUrl( url );

                this.prevNextNavigationService.setLastUrlPart( lastPart );

                this.itemsToSkipCount = 0;
            } );
    }


    private getLastPartFromUrl( url: string ): string {
        const splitted: Array<string> = url.split( '/' );
        return splitted.pop();
    }


    private registerPrevNextButtonsObservers(): void {

        // User click refreshes "x of y" label, but route changes occures after some debounceTime().

        this.prevButtonClicks$
            .pipe(
                tap( () => this.itemsToSkipCount-- ),
                debounceTime( 300 ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                this.prevNextNavigationService.navigateToPrevItem( -this.itemsToSkipCount );    // make itemsToSkipCount positive
            } );

        this.nextButtonClicks$
            .pipe(
                tap( () => this.itemsToSkipCount++ ),
                debounceTime( 300 ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                this.prevNextNavigationService.navigateToNextItem( this.itemsToSkipCount );
            } );
    }
}
