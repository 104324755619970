import { Injectable, TemplateRef, } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { NotificationItemConfig } from './notification-item/notification-item.component';

export enum NotificationType {
    Error,
    Info,
    Success,
    Warning,
}
export interface NotificationItem extends NotificationItemConfig {
    notificationId: number;
    className: string;
}

/**
 * Handles messages/errors/warnings displayed user.
 */
@Injectable()
export class NotificationService {
    notification$: Observable<NotificationItem>;
    closeNotification$: Observable<number|null>;

    private notification: Subject<NotificationItem> = new Subject();
    private closeNotification: Subject<number|null> = new Subject();
    private index: number                           = 0;

    constructor() {
        this.notification$      = this.notification.asObservable();
        this.closeNotification$ = this.closeNotification.asObservable();
    }

    /**
     * Displays success message.
     *
     */
    success( displayValue: string | TemplateRef<any>, title: string = 'Action successful', autoClose: boolean = true ): number {
        return this.generateNotification( displayValue, title, autoClose, NotificationType.Success );
    }

    /**
     * Displays warning message.
     *
     */
    warning( displayValue: string | TemplateRef<any>, title: string = 'Warning', autoClose: boolean = true ): number {
        return this.generateNotification( displayValue, title, autoClose, NotificationType.Warning );
    }

    /**
     * Displays error message.
     *
     */
    error( displayValue: string | TemplateRef<any>, title: string = 'Action failed', autoClose: boolean = true ): number {
        return this.generateNotification( displayValue, title, autoClose, NotificationType.Error );
    }

    /**
     * Displays info message.
     *
     */
    info( displayValue: string | TemplateRef<any>, title: string = 'Info', autoClose: boolean = true ): number {
        return this.generateNotification( displayValue, title, autoClose, NotificationType.Info );
    }


    /**
     * trigger close for given notificationId or all notifications
     *
     */
    close( notificationId?: number ): void {
        this.closeNotification.next( notificationId );
    }

    /**
     * returned notificationId can be used, to close the notification
     * when used with template, the notificationId will be provided as templateData
     */
    private generateNotification( displayValue: string | TemplateRef<any>, title: string, autoClose: boolean, type: NotificationType ): number {
        const notificationId: number = ++this.index;

        this.notification.next( {
            notificationId: notificationId,
            className:      this.getBackgroundClass( type ),
            title:          title,
            autoClose:      autoClose,
            text:           this.getText( displayValue ),
            template:       this.getTemplate( displayValue ),
            templateData:   { notificationId: notificationId, },
        } );

        return notificationId;
    }


    private getText( displayValue: string | TemplateRef<any> ): string {
        return typeof displayValue === 'string' ? displayValue : undefined;
    }

    private getTemplate( displayValue: string | TemplateRef<any> ): TemplateRef<any> {
        return typeof displayValue !== 'string' ? displayValue : undefined;
    }

    private getBackgroundClass( type: NotificationType ): string {
        switch ( type ) {
            case NotificationType.Error:
                return 'is-notification-error';
            case NotificationType.Info:
                return 'is-notification-info';
            case NotificationType.Success:
                return 'is-notification-success';
            case NotificationType.Warning:
                return 'is-notification-warning';
            default:
                return 'is-notification-info';
        }
    }
}
