<rsp-dropdown
    [placeholder]="'Choose Field'"
    [defaultValue]="filter.name"
    [withSuggester]="true"
    [originalItems]="filterFields"
    (filteredItems)="filterFilterFields( $event )"
    (selected)="fieldSelected( $event.name )"
    class="advanced-search-filter-group-filter--field-select"
>
    <rsp-dropdown-item
        *ngFor="let filterField of filteredFilterFields"
        [item]="filterField"
    >
        {{ filterField.name }}
    </rsp-dropdown-item>
</rsp-dropdown>

<rsp-dropdown
    [defaultValue]="filter.operatorInfo.name"
    [disabled]="filter.type === typeEnum.Enum || filter.type === typeEnum.Boolean"
    (selected)="operatorSelected( $event )"
    class="advanced-search-filter-group-filter--operator-select"
>
    <rsp-dropdown-item
        *ngFor="let operator of filter.operators"
        [item]="operator"
    >
        {{ operator.name }}
    </rsp-dropdown-item>
</rsp-dropdown>

<ng-container
    *ngIf="!isRangeOperator( filter.operator )"
>
    <input
        *ngIf="filter.type === typeEnum.String || filter.type === typeEnum.Numeric"
        rspTextInput
        [withClearButton]="true"
        [(ngModel)]="filter.value"
        [rspTextInputWrapperClass]="'advanced-search-filter-group-filter--value-input'"
        (onEnter)="emitSubmitEvent()"
        class="advanced-search-filter-group-filter--text-input"
        type="text"
    >

    <rsp-display-edit-date
        *ngIf="filter.type === typeEnum.Date"
        [isEditMode]="true"
        [myFormGroup]="dateValueForm"
        [name]="'value'"
        [disablePastDates]="false"
        class="advanced-search-filter-group-filter--value-input"
    ></rsp-display-edit-date>

    <rsp-dropdown
        *ngIf="filter.type === typeEnum.Enum"
        [defaultValue]="filter.value ? filter.value : ''"
        [placeholder]="''"
        [allowClear]="true"
        [hasFullWidth]="true"
        (selected)="filterValueOptionChanged( $event )"
        class="advanced-search-filter-group-filter--value-input"
    >
        <rsp-dropdown-item
            *ngFor="let option of filterValueOptions"
            [item]="option"
        >
            {{ option }}
        </rsp-dropdown-item>
    </rsp-dropdown>

    <rsp-dropdown
        *ngIf="filter.type === typeEnum.Boolean"
        [defaultValue]="filter.value ? filter.value : ''"
        [placeholder]="''"
        [allowClear]="true"
        [hasFullWidth]="true"
        (selected)="filterValueOptionChanged( $event )"
        class="advanced-search-filter-group-filter--value-input"
    >
        <rsp-dropdown-item
            *ngFor="let option of [ 'true', 'false' ]"
            [item]="option"
        >
            {{ option }}
        </rsp-dropdown-item>
    </rsp-dropdown>
</ng-container>


<div
    *ngIf="isRangeOperator( filter.operator )"
    class="advanced-search-filter-group-filter--value-input"
>
    <div
        *ngIf="filter.type === typeEnum.String || filter.type === typeEnum.Numeric"
        class="advanced-search-filter-group-filter--value-input-range"
    >
        <input
            rspTextInput
            [withClearButton]="true"
            [(ngModel)]="filter.range.from"
            [rspTextInputWrapperClass]="'advanced-search-filter-group-filter--value-input-range-from'"
            (onEnter)="emitSubmitEvent()"
            class="advanced-search-filter-group-filter--value-input-range-from-input"
            type="text"
        >

        <span class="advanced-search-filter-group-filter--value-input-range-separator">to</span>

        <input
            rspTextInput
            [withClearButton]="true"
            [(ngModel)]="filter.range.until"
            [rspTextInputWrapperClass]="'advanced-search-filter-group-filter--value-input-range-until'"
            (onEnter)="emitSubmitEvent()"
            class="advanced-search-filter-group-filter--value-input-range-until-input"
            type="text"
        >
    </div>

    <rsp-display-edit-date-time-range
        *ngIf="filter.type === typeEnum.Date"
        [myFormGroup]="dateRangeForm"
        [isEditMode]="true"
        [datetimeFrom]="filter.range.from"
        [datetimeUntil]="filter.range.until"
        [format]="'HumanReadable'"
        [withWeekday]="false"
        [withTime]="false"
        [disablePastFromDates]="false"
        [disablePastUntilDates]="false"
        class="advanced-search-filter-group-filter--date-time-range"
    ></rsp-display-edit-date-time-range>
</div>

<rsp-x-button
    [style.visibility]="noRemove ? 'hidden' : 'visible'"
    (clicked)="emitRemoveEvent()"
    title="remove filter"
    class="advanced-search-filter-group-filter--delete-button"
>
    Remove filter
</rsp-x-button>
