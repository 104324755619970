import { ServiceLevelItem, } from './service-level-item';
import { ConceptServiceModelItem, ServiceModelItem as ServiceModelItemApi } from '../../model';
import { ServiceLevelItem as ServiceLevelItemApi } from '../../model';
import { ConceptServiceLevel } from '../../model';
import { ApplicationTimeService } from '../../../core/application-time.service';

/**
 * Represents Service Model, collapsable row in the Concept Service level table.
 */
export class ServiceModelItem {

    id: string;

    // id used for new created service models
    additionalId: number;


    /**
     * Specifies if item is collapsed/expanded.
     */
    isCollapsed: boolean = true;

    name: string;

    assignedLevel: number;

    get assignedLevelLabel(): string {
        return 'Level ' + this.assignedLevel;
    }

    levelGroup1IsAvailable: boolean;

    levelGroup2IsAvailable: boolean;

    levelGroup3IsAvailable: boolean;

    levelGroup4IsAvailable: boolean;

    levelGroup5IsAvailable: boolean;

    levelGroup6IsAvailable: boolean;

    serviceLevelItems: Array<ServiceLevelItem>;

    isInEditMode: boolean = false;

    private originItem: ServiceModelItem = null;

    constructor(
        id: string,
        name: string,
        assignedLevel: number,
        levelGroup1IsAvailable: boolean,
        levelGroup2IsAvailable: boolean,
        levelGroup3IsAvailable: boolean,
        levelGroup4IsAvailable: boolean,
        levelGroup5IsAvailable: boolean,
        levelGroup6IsAvailable: boolean,
        serviceLevelItems: Array<ServiceLevelItem>,
    ) {
        this.id                     = id;
        this.name                   = name;
        this.assignedLevel          = assignedLevel;
        this.levelGroup1IsAvailable = levelGroup1IsAvailable;
        this.levelGroup2IsAvailable = levelGroup2IsAvailable;
        this.levelGroup3IsAvailable = levelGroup3IsAvailable;
        this.levelGroup4IsAvailable = levelGroup4IsAvailable;
        this.levelGroup5IsAvailable = levelGroup5IsAvailable;
        this.levelGroup6IsAvailable = levelGroup6IsAvailable;
        this.serviceLevelItems      = serviceLevelItems;

        // generate unique id
        this.additionalId = ApplicationTimeService.current().getTime();

        // copy origin version of this object. We need it later, when user cancel edit.
        this.saveCurrentObjectStateToOrigin();
    }

    /**
     * Creates new empty ServiceModelItem.
     */
    static createNewServiceModelItem(): ServiceModelItem {
        const resultItem: ServiceModelItem = new ServiceModelItem(
            null,
            '',
            null,
            false,
            false,
            false,
            false,
            false,
            false,
            [],
        );

        resultItem.activateEditMode();

        return resultItem;
    }


    /**
     * Creates ServiceModelItem from ConceptServiceModelItem returned by the backend.
     */
    static createFromConceptServiceModelItem( item: ConceptServiceModelItem ): ServiceModelItem {

        let serviceLevels: Array<ServiceLevelItem> = [];

        if ( item.serviceLevels ) {
            serviceLevels
                = item.serviceLevels
                      .map( ( conceptServiceLevel: ConceptServiceLevel ) => ServiceLevelItem.createFromConceptServiceLevel( conceptServiceLevel ) );
        }

        return new ServiceModelItem(
            null,   // TODO: do we need an id?
            item.name,
            item.assignedLevelGroup,
            item.levelGroup1IsAvailable,
            item.levelGroup2IsAvailable,
            item.levelGroup3IsAvailable,
            item.levelGroup4IsAvailable,
            item.levelGroup5IsAvailable,
            item.levelGroup6IsAvailable,
            serviceLevels,
        );
    }


    /**
     * Creates ServiceModelItem from object with same name (?!) returned by the backend.
     */
    static createFromApiServiceModelItem( item: ServiceModelItemApi ): ServiceModelItem {

        let serviceLevels: Array<ServiceLevelItem> = [];

        if ( item.serviceLevels ) {
            serviceLevels = item.serviceLevels
                                .map( ( serviceLevel: ServiceLevelItemApi ) => ServiceLevelItem.createFromApiServiceLevelItem( serviceLevel ) );
        }

        return new ServiceModelItem(
            item.id,
            item.name,
            null,
            item.levelGroup1IsAvailable,
            item.levelGroup2IsAvailable,
            item.levelGroup3IsAvailable,
            item.levelGroup4IsAvailable,
            item.levelGroup5IsAvailable,
            item.levelGroup6IsAvailable,
            serviceLevels,
        );
    }


    toggleCollapsing(): void {
        this.isCollapsed = !this.isCollapsed;
    }

    activateEditMode(): void {
        this.saveCurrentObjectStateToOrigin();
        this.isInEditMode = true;
        this.isCollapsed  = false;
    }

    endEditMode(): void {
        this.isInEditMode = false;
        this.originItem   = null;
    }

    cancelEditMode(): void {
        this.restoreFromOrigin();
        this.originItem = null;
        this.endEditMode();
    }


    /**
     * Adds new empty service level to the list.
     */
    addNewServiceLevel(): void {
        this.serviceLevelItems.unshift( ServiceLevelItem.createNewServiceLevel() );
    }


    /**
     * Removes specified service level item from the list.
     */
    removeServiceLevel( level: ServiceLevelItem ): void {

        const index: number = this.serviceLevelItems
                                  .findIndex( ( item: ServiceLevelItem ) => item === level );

        if ( index !== -1 ) {
            this.serviceLevelItems.splice( index, 1 );
        }
        else {
            console.error( 'Service Model does not contain specified level!' );
        }
    }


    /**
     * Specifies if service model is valid and can be saved.
     */
    isValid(): boolean {
        return this.name !== ''
            && this.serviceLevelItems
                   .findIndex( ( level: ServiceLevelItem ) => !level.isValid() ) === -1;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private saveCurrentObjectStateToOrigin(): void {
        this.originItem = JSON.parse( JSON.stringify( this ) );
    }

    private restoreFromOrigin(): void {
        this.id                     = this.originItem.id;
        this.name                   = this.originItem.name;
        this.levelGroup1IsAvailable = this.originItem.levelGroup1IsAvailable;
        this.levelGroup2IsAvailable = this.originItem.levelGroup2IsAvailable;
        this.levelGroup3IsAvailable = this.originItem.levelGroup3IsAvailable;
        this.levelGroup4IsAvailable = this.originItem.levelGroup4IsAvailable;
        this.levelGroup5IsAvailable = this.originItem.levelGroup5IsAvailable;
        this.levelGroup6IsAvailable = this.originItem.levelGroup6IsAvailable;
        this.serviceLevelItems      = this.originItem.serviceLevelItems;
    }
}
