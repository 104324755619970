<ng-template #headline>
    <h2 class="standard-dialog--headline">{{ caption }}</h2>
</ng-template>

<ng-template #content>
    <div *ngIf="progress" [ngClass]="'standard-dialog--content'">
        <rsp-download-link [staticFileName]="'Retail Hardware Metadata 2.17.xlsx'" *ngIf="!file && !isImporting && !isDone">
            Download Template
        </rsp-download-link>
        <h1 *ngIf="isImporting">
            Importing, please wait...
        </h1>
        <h1 *ngIf="isDone">
            Importing, please wait... done!
        </h1>
        <ul class="progress-list">
            <li *ngFor="let progressInfo of progress">
                {{ progressInfo }}
            </li>
        </ul>
    </div>
    <div
        [ngClass]="'standard-dialog--content'"
        [rspLoadingIndicator]="isLoading"
    >
        <rsp-drop-zone
            *ngIf="!file && !isImporting && !isDone"
            class="upload-single-file--drop-zone-wrapper"
            [multiple]="false"
            [validFileTypeGroup]="'spreadsheets'"
            (filesChanged)="importAssembliesFromFile( $event )"
        >
            <div class="upload-single-file--drop-zone">
                click here or drop file to import assemblies
            </div>
        </rsp-drop-zone>
        <rsp-button
            *ngIf="isDone"
            [type]="'submit'"
            [submodule]="'primary'"
            (clicked)="close()"
        >
            Close
        </rsp-button>
    </div>
</ng-template>

<ng-template #footer>

</ng-template>

