import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MainMenuService } from '../main-menu/main-menu.service';
import { CurrentUserService } from '../current-user.service';
import { CurrentUserContext } from '../../shared/model';
import { VERSION } from '../../../environments/version';


@Component( {
    selector:    'rsp-master-head',
    templateUrl: './master-head.component.html',
    styleUrls:   [
        './master-head.component.scss',
    ],
} )
export class MasterHeadComponent implements OnInit, OnDestroy {
    currentUser: CurrentUserContext | null;

    version: string = 'v' + VERSION;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private mainMenuService: MainMenuService,
        private currentUserService: CurrentUserService,
    ) {
    }

    ngOnInit(): void {
        this.currentUserService
            .getCurrentUser()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( user: CurrentUserContext | null ) => {
                this.currentUser = user;
        } );

        this.currentUserService
            .profilePreviewImageId$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( fileId: string ) => {
                if ( this.currentUser ) {
                    this.currentUser.profilePreviewImageId = fileId;
                    this.currentUser                       = Object.assign( {}, this.currentUser );
                }
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    openMainMenu(): void {
        this.mainMenuService.open();
    }

}
