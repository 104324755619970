import { Component, Input, OnInit, OnChanges, } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, } from '@angular/forms';

import { UniqueHtmlIdService } from '../../../../../../core/unique-html-id.service';
import { MasterDataSPMService } from '../../../../../../shared/api';
import { ViewModelListSPMGroupInfo } from '../../../../../../shared/model/viewModelListSPMGroupInfo';
import { ViewModelListSPMTypeInfo } from '../../../../../../shared/model/viewModelListSPMTypeInfo';
import { SPMGroupInfo, } from '../../../../../../shared/model/sPMGroupInfo';
import { SPMTypeInfo, } from '../../../../../../shared/model/sPMTypeInfo';

@Component( {
    selector:    'rsp-edit-spm-group-and-type',
    templateUrl: './edit-spm-group-and-type.component.html',
    styleUrls:   [
        './edit-spm-group-and-type.component.scss',
    ],
} )
export class EditSpmGroupAndTypeComponent implements OnInit, OnChanges {
    @Input() myFormGroup: UntypedFormGroup;
    @Input() spmGroup: SPMGroupInfo;
    @Input() spmType: SPMTypeInfo;

    spmGroupIdControl: UntypedFormControl;
    spmTypeIdControl: UntypedFormControl;

    spmGroupName: string;
    spmTypeName: string;

    spmGroupOptions: SPMGroupInfo[] = [];
    spmTypeOptions: SPMTypeInfo[]   = [];

    filteredSpmGroupOptions: SPMGroupInfo[];
    filteredSpmTypeOptions: SPMTypeInfo[];

    htmlIdFor: { spmGroupField: string, spmTypeField: string };

    constructor(
        private spmApi: MasterDataSPMService,
        private uniqueHtmlIdService: UniqueHtmlIdService,
    ) {}

    ngOnInit(): void {
        this.htmlIdFor = {
            spmGroupField: this.uniqueHtmlIdService.getUniqueHtmlId( 'spm-group' ),
            spmTypeField:  this.uniqueHtmlIdService.getUniqueHtmlId( 'spm-field' ),
        };

        this.spmApi.spmGetGroupOptions().subscribe( ( data: ViewModelListSPMGroupInfo ) => {
            this.spmGroupOptions = data.data;
        });
    }

    ngOnChanges(): void {
        // the controls are created in non-trade-item-details
        this.spmGroupIdControl = this.myFormGroup.get('spmGroupId') as UntypedFormControl;
        this.spmTypeIdControl = this.myFormGroup.get('spmTypeId') as UntypedFormControl;

        if ( this.spmGroup ) {
            this.spmGroupIdControl.setValue( this.spmGroup.id );
            this.spmGroupName = this.spmGroup.name;
            this.setTypes( this.spmGroup.id );
        }
        if ( this.spmType ) {
            this.spmTypeIdControl.setValue( this.spmType.id );
            this.spmTypeName = this.spmType.name;
        }
    }

    setGroup( groupOption: SPMGroupInfo ): void {
        if ( this.spmGroupIdControl.value !== groupOption.id ) {
            this.spmTypeIdControl.reset();
            this.spmTypeName = '';
            this.setTypes( groupOption.id );
        }

        this.spmGroupName = groupOption.name;
        this.spmGroupIdControl.setValue( groupOption.id );
    }

    setType( typeOption: SPMTypeInfo ): void {
        this.spmTypeName = typeOption.name;
        this.spmTypeIdControl.setValue( typeOption.id );
    }

    setFilteredSpmGroupOptions( items: SPMGroupInfo[] ): void {
        this.filteredSpmGroupOptions = items;
    }

    setFilteredSpmTypeOptions( items: SPMTypeInfo[] ): void {
        this.filteredSpmTypeOptions = items;
    }

    private setTypes( groupId: string ): void {
        this.spmApi.spmGetTypeOptions( groupId ).subscribe( ( data: ViewModelListSPMTypeInfo ) => {
            this.spmTypeOptions = data.data;
        } );
    }
}
