/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { CultureInfo } from './cultureInfo';


export interface StyleCulture {
    culture?: CultureInfo;

    status?: StyleCulture.StatusEnum;

    virtualArticleNumber?: string;

}

export namespace StyleCulture {
    'use strict';
    export enum StatusEnum {
        Planned,
        Requested,
        InProgress,
        InApproval,
        Ready,
        Inactive,
        Rejected,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

