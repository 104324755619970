<ng-template #dialogHeaderTemplate>
    <h2 class="standard-dialog--headline">Copy an Assembly</h2>
</ng-template>

<ng-template #dialogContentTemplate>
    <div
        [rspLoadingIndicator]="isLoading"
        class="standard-dialog--content"
    >
        <div class="copy-assembly-dialog--form">
            <rsp-non-trade-item-suggester
                [type]="nonTradeItemSuggestType"
                [displayValue]="sourceAssembly ? sourceAssembly.number + ' - ' + sourceAssembly.name : null"
                (selected)="setSourceAssembly( $event )"
            >
                <rsp-error-messages
                    *ngIf="form.get('sourceAssemblyId').errors"
                    [formErrors]="form.get('sourceAssemblyId').errors"
                ></rsp-error-messages>
            </rsp-non-trade-item-suggester>

            <span class="copy-assembly-dialog--arrow">→</span>

            <rsp-edit-text
                [myFormControl]="form.get('name')"
                [placeholder]="'Assembly name'"
            ></rsp-edit-text>
        </div>
    </div>
</ng-template>

<ng-template #dialogFooterTemplate>
    <div class="standard-dialog--footer">
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'button'"
            [submodule]="'primary'"
            [disabled]="!form.valid"
            (clicked)="doAction()"
            class="copy-assembly-dialog--submit-button"
        >
            Copy
        </rsp-button>
    </div>
</ng-template>
