<ng-template #headline>
    <h2 class="standard-dialog--headline">{{ caption }}</h2>
</ng-template>

<ng-template #content>
    <div
        [ngClass]="fromSelection ? 'standard-dialog--content' : 'standard-dialog--button'"
        [rspLoadingIndicator]="isLoading"
    >
        <rsp-non-trade-item-selection-list *ngIf="fromSelection; else createTemplate"></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #footer>
    <div *ngIf="fromSelection" class="standard-dialog--button"  [rspLoadingIndicator]="isLoading">
        <ng-template [ngTemplateOutlet]="createTemplate" ></ng-template>
    </div>
</ng-template>

<ng-template #createTemplate>

    <rsp-concept-suggester
        [displayValue]="form.get('concept').value?.name"
        (selected)="setConcept($event)"
        style="margin-right:1em"
    >
        <rsp-error-messages
            *ngIf="form.get('concept').errors; let errors"
            [formErrors]="errors"
        >
        </rsp-error-messages>
    </rsp-concept-suggester>

    <rsp-edit-text
        [myFormControl]="form.get('clusterName')"
        [placeholder]="'Cluster name'"
    >
    </rsp-edit-text>

    <rsp-button
        [type]="'submit'"
        (clicked)="doAction()"
        [disabled]="!form.valid"
        [submodule]="'primary'"
        style="margin-left:1em"
    >
        Create
    </rsp-button>
</ng-template>
