import { Component, } from '@angular/core';

/**
 * This is an empty component that does purposely nothing.
 * Why is it even here? Sometimes we need a component referenced somewhere (Routes for example), but we do the rendering
 * differently.
 */
@Component( {
    selector: 'rsp-empty',
    template: '',
} )
export class EmptyComponent {}
