import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable, Subscription, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { NotificationService } from '../../../../../../../core/overlay/notification/notification.service';
import { ArticleManagementNonTradeItemsService, FileManagementFileKindsService } from '../../../../../../../shared/api';
import { NonTradeItemSelectionService } from '../../../../shared/non-trade-item-selection.service';
import { NonTradeListItem } from '../../../../../../../shared/model/nonTradeListItem';
import { FileKindInfo } from '../../../../../../../shared/model/fileKindInfo';
import { CustomValidators } from '../../../../../../../shared/forms/validation/custom-validators';
import { CreateArticleAssemblyFilesExport, CreateBySearchArticleAssemblyFilesExportData, FileKindItem } from '../../../../../../../shared/model';
import { NonTradeItemStoreService } from '../../../../shared/non-trade-item-store.service';

import { flatten } from 'lodash';
import { Reply } from '../../../../../../../shared/model/reply';
import { StoreInfo } from '../../../../../../../shared/ui/lists/store/store-info.model';

interface FileKindItemSelection {
    id: string;
    checked: boolean;
    name: string;
}

@Component( {
    selector:    'rsp-export-article-assembly-files-dialog',
    templateUrl: './export-article-assembly-files-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        './export-article-assembly-files-dialog.component.scss',
    ],
} )
export class ExportArticleAssemblyFilesDialogComponent implements OnDestroy {
    @ViewChild( 'dialogHeader', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContent', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooter', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    nameControl: UntypedFormControl = new UntypedFormControl( '', [ Validators.required, CustomValidators.isValidFilename, CustomValidators.stringNotEmpty ] );

    isLoading: Subscription;

    fileKinds: FileKindItemSelection[] = [];

    fromSelection: boolean;

    storeInfo: Observable<StoreInfo>;

    isTooLarge: boolean = false;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private nonTradeItemsApi: ArticleManagementNonTradeItemsService,
        private fileKindsApi: FileManagementFileKindsService,
        private storeService: NonTradeItemStoreService,
    ) {
        this.storeInfo = storeService.storeInfo$;
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    open( createFromSelection: boolean = false ): void {
        this.isTooLarge = false;
        this.fromSelection = createFromSelection;

        this.dialogService.openDialog( {
            contentTemplate:  this.dialogContentTemplate,
            headlineTemplate: this.dialogHeaderTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        } );

        this.isLoading =
            forkJoin(
                this.fileKindsApi.fileKindsGetFileKindsByArea( FileKindItem.AreasEnum.toString( FileKindItem.AreasEnum.ArticlesAndAssemblies ) ),
                this.fileKindsApi.fileKindsGetFileKindsByArea( FileKindItem.AreasEnum.toString( FileKindItem.AreasEnum.ArticleGraphics ) ),
                this.fileKindsApi.fileKindsGetFileKindsByArea( FileKindItem.AreasEnum.toString( FileKindItem.AreasEnum.ArticleStylesAndArtworks ) ),
            )
                      .pipe( takeUntil( this.isDestroyed ) )
                      .subscribe( ( result: FileKindInfo[][] ) => {
                          this.fileKinds = flatten( result ).map( ( item: FileKindInfo ) => {
                              return {
                                  id:      item.id,
                                  name:    item.name,
                                  checked: false,
                              };
                          } );
                      } );
    }

    hasCheckedFileKinds(): boolean {
        return this.fileKinds.filter( ( item: FileKindItemSelection ) => item.checked ).length > 0;
    }

    close(): void {
        this.isLoading.unsubscribe();
        this.dialogService.closeDialog();
    }

    triggerCheck(): void {
        let apiCall: Observable<Reply>;
        // let checkCall: Observable<ReplyInt64>;

        const fileKindIds: string[] = this.fileKinds.filter( ( item: FileKindItemSelection ) => item.checked ).map( ( item: FileKindItem ) => item.id );

        if ( this.fromSelection ) {
            const command: CreateArticleAssemblyFilesExport = {
                name:        this.nameControl.value,
                articleIds:  this.nonTradeItemSelectionService.getSelectedArticles().map( ( item: NonTradeListItem ) => item.id ),
                assemblyIds: this.nonTradeItemSelectionService.getSelectedAssemblies().map( ( item: NonTradeListItem ) => item.id ),
                fileKindIds: fileKindIds,
            };

            apiCall = this.nonTradeItemsApi.nonTradeItemsCreateArticleAssemblyFilesExport( command );
            // checkCall = this.nonTradeItemsApi.nonTradeItemsSizeForFiles( command );
        }
        else {
            const command: CreateBySearchArticleAssemblyFilesExportData = {
                name:                  this.nameControl.value,
                fileKindIds:           fileKindIds,
                browsingConfiguration: {
                    sortings:                   [],
                    advancedSearchFilterGroups: this.storeService.getAdvancedSearchFilterGroups(),
                    filters:                    this.storeService.getFacetFilters(),
                    quickSearchTerm:            this.storeService.getQuickSearch(),
                    size:                       100000,
                    page:                       1,
                },
            };
            apiCall = this.nonTradeItemsApi.nonTradeItemsCreateBySearchArticleAssemblyFilesExport( command );
            // checkCall = this.nonTradeItemsApi.nonTradeItemsSizeForFilesBySearch( command );
        }

        // if ( this.fromSelection ) {
        //     this.isLoading =
        //         checkCall.pipe( takeUntil( this.isDestroyed ) )
        //                  .subscribe( ( reply: ReplyInt64 ) => {
        //                      if ( (reply.value / 1024 / 1024) > 3000 ) {
        //                          this.isTooLarge = true;
        //                      } else {
        //                          this.triggerExport(apiCall);
        //                      }
        //                  } );
        // } else {
        //     this.triggerExport(apiCall);
        // }

        this.triggerExport(apiCall);

    }

    triggerExport( apiCall: Observable<Reply> ): void {
        this.isLoading =
            apiCall.pipe( takeUntil( this.isDestroyed ) )
                   .subscribe( () => {
                       this.notificationService.success( 'Export generation triggered.', 'Article & Assemblies Export' );
                       this.close();
                   } );
    }

}
