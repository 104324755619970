import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Route, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { CurrentUserService } from '../current-user.service';
import { NotificationService } from '../overlay/notification/notification.service';
import { CurrentUserContext } from '../../shared/model';
import { map } from 'rxjs/operators';

/**
 * Checks if the current user has a certain access right to enter a route.
 *
 * Needs a route config like this:
 * ```
 * // to access `…/somePath`, current user needs the `ArticleManagementBrowse` access right.
 * {
 *     path:        'somePath',
 *     canActivate: [ AccessRightGuard, ],
 *     data:        {
 *         accessRight: AccessRight.ArticleManagementBrowse,
 *     },
 * },
 * ```
 */
@Injectable()
export class AccessRightGuard implements CanActivate, CanActivateChild {

    constructor(
        private currentUserService: CurrentUserService,
        private router: Router,
        private notificationService: NotificationService,
    ) {}

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | boolean {
        return this.authorize( route, state );
    }

    canActivateChild( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | boolean {
        return this.authorize( route, state );
    }

    private authorize( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> | boolean {
        const accessRight: CurrentUserContext.AccessRightsEnum = route.data[ 'accessRight' ] as CurrentUserContext.AccessRightsEnum;

        if ( accessRight === undefined ) {
            return true;
        }
        else {
            return this.currentUserService.hasCurrentUserAccessRight( accessRight ).pipe(
                map( ( hasRight: boolean ): boolean => {

                    if ( hasRight ) {
                        return true;
                    }

                    if (
                        !route.routeConfig.children || !route.routeConfig.children.length
                        || !route.routeConfig.children.filter( ( child: Route ) => child.path !== '' ).length
                    ) {
                        // send message only for last child (otherwise message would be send for every route segment),
                        // or if the children consist only of '' (list pages)
                        this.notificationService.error( 'You are not authorized to view this page!' );
                    }

                    if ( route.data[ 'navigateToParent' ] ) {
                        // maybe not the most elegant way, but it works
                        const segments: string[] = state.url.split( '/' );
                        segments.pop();
                        segments.shift();

                        this.router.navigate( segments );
                    }
                    else {
                        this.router.navigate( [ 'dashboard' ] );
                    }

                    return false;

                } ),
            );
        }
    }
}
