import { Injectable } from '@angular/core';
import { BaseSelectionService } from '../../../../shared/ui/lists/base-selection.service';
import { FileContainerTile } from '../../../../shared/model';


/**
 * Handles selection of DocumentViewModel. Provides an observable property (selectedItems$) which can be used by observers
 * which want to be notified when selection has been changed.
 */
@Injectable()
export class DocumentSelectionService extends BaseSelectionService<FileContainerTile> {

    getSelectedFileContainerIds(): Array<string> {
        return this.getSelectedItems()
                   .map( ( item: FileContainerTile ) => item.id );
    }

    protected getId( item: FileContainerTile ): string {

        if ( !item ) {
            throw new Error( ' [DocumentSelectionService] item must not be null!' );
        }

        return item.id;
    }
}
