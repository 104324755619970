/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { ConceptSuggestSearchConfiguration } from '../model/conceptSuggestSearchConfiguration';
import { DeleteConcept } from '../model/deleteConcept';
import { Reply } from '../model/reply';
import { ReplyCreateConceptReply } from '../model/replyCreateConceptReply';
import { ReplyGuid } from '../model/replyGuid';
import { UpdateConceptDetail } from '../model/updateConceptDetail';
import { UpdateConceptServiceModels } from '../model/updateConceptServiceModels';
import { ViewModelItemConceptDetail } from '../model/viewModelItemConceptDetail';
import { ViewModelItemConceptSummary } from '../model/viewModelItemConceptSummary';
import { ViewModelListConceptCluster } from '../model/viewModelListConceptCluster';
import { ViewModelListConceptServiceModelItem } from '../model/viewModelListConceptServiceModelItem';
import { ViewModelListFileContainerWithReference } from '../model/viewModelListFileContainerWithReference';
import { ViewModelPagedListConceptSuggestItem } from '../model/viewModelPagedListConceptSuggestItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class ArticleManagementConceptsService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create a copy of a Concept with the provided name
     * 
     * @param conceptId 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsCopyConcept(conceptId: string, name: string, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public conceptsCopyConcept(conceptId: string, name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public conceptsCopyConcept(conceptId: string, name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public conceptsCopyConcept(conceptId: string, name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling conceptsCopyConcept.');
        }
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling conceptsCopyConcept.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined) {
            queryParameters = queryParameters.set('name', <any> name);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/concepts/${encodeURIComponent(String(conceptId))}/copy`,
                null,
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Create
     * 
     * @param name 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsCreate(name: string, observe?: 'body', reportProgress?: boolean): Observable<ReplyCreateConceptReply>;
    public conceptsCreate(name: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyCreateConceptReply>>;
    public conceptsCreate(name: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyCreateConceptReply>>;
    public conceptsCreate(name: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling conceptsCreate.');
        }

        let queryParameters: HttpParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (name !== undefined) {
            queryParameters = queryParameters.set('name', <any> name);
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<ReplyCreateConceptReply>(
                `${this.basePath}/api/v1/articlemanagement/concepts`,
                null,
                {
                    params: queryParameters,
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Delete Concept
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsDeleteConcept(command: DeleteConcept, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public conceptsDeleteConcept(command: DeleteConcept, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public conceptsDeleteConcept(command: DeleteConcept, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public conceptsDeleteConcept(command: DeleteConcept, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling conceptsDeleteConcept.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.delete<Reply>(
                `${this.basePath}/api/Concepts`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetClusters
     * 
     * @param conceptId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsGetClusters(conceptId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListConceptCluster>;
    public conceptsGetClusters(conceptId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListConceptCluster>>;
    public conceptsGetClusters(conceptId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListConceptCluster>>;
    public conceptsGetClusters(conceptId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling conceptsGetClusters.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListConceptCluster>(
                `${this.basePath}/api/v1/articlemanagement/concepts/${encodeURIComponent(String(conceptId))}/clusters`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetDetail
     * 
     * @param conceptId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsGetDetail(conceptId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemConceptDetail>;
    public conceptsGetDetail(conceptId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemConceptDetail>>;
    public conceptsGetDetail(conceptId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemConceptDetail>>;
    public conceptsGetDetail(conceptId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling conceptsGetDetail.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemConceptDetail>(
                `${this.basePath}/api/v1/articlemanagement/concepts/${encodeURIComponent(String(conceptId))}/detail`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Returns a list of {RSP.ViewModel.Models.FileManagement.FileContainerWithReference} associated with the given concept
     * 
     * @param conceptId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsGetFileContainers(conceptId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListFileContainerWithReference>;
    public conceptsGetFileContainers(conceptId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListFileContainerWithReference>>;
    public conceptsGetFileContainers(conceptId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListFileContainerWithReference>>;
    public conceptsGetFileContainers(conceptId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling conceptsGetFileContainers.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListFileContainerWithReference>(
                `${this.basePath}/api/v1/articlemanagement/concepts/${encodeURIComponent(String(conceptId))}/files`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Returns a list of service-models associated with the concept
     * 
     * @param conceptId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsGetServiceLevels(conceptId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListConceptServiceModelItem>;
    public conceptsGetServiceLevels(conceptId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListConceptServiceModelItem>>;
    public conceptsGetServiceLevels(conceptId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListConceptServiceModelItem>>;
    public conceptsGetServiceLevels(conceptId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling conceptsGetServiceLevels.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListConceptServiceModelItem>(
                `${this.basePath}/api/v1/articlemanagement/concepts/${encodeURIComponent(String(conceptId))}/serviceLevels`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetSummary
     * 
     * @param conceptId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsGetSummary(conceptId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemConceptSummary>;
    public conceptsGetSummary(conceptId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemConceptSummary>>;
    public conceptsGetSummary(conceptId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemConceptSummary>>;
    public conceptsGetSummary(conceptId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling conceptsGetSummary.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemConceptSummary>(
                `${this.basePath}/api/v1/articlemanagement/concepts/${encodeURIComponent(String(conceptId))}/summary`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Suggests (searches) a non pageable list of short concept descriptor items.                  Please do not confuse the term suggest with a suggest mechanism in Elasticsearch.
     * 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsSuggest(configuration: ConceptSuggestSearchConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListConceptSuggestItem>;
    public conceptsSuggest(configuration: ConceptSuggestSearchConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListConceptSuggestItem>>;
    public conceptsSuggest(configuration: ConceptSuggestSearchConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListConceptSuggestItem>>;
    public conceptsSuggest(configuration: ConceptSuggestSearchConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (configuration === null || configuration === undefined) {
            throw new Error('Required parameter configuration was null or undefined when calling conceptsSuggest.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelPagedListConceptSuggestItem>(
                `${this.basePath}/api/v1/articlemanagement/concepts/suggest`,
                configuration,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Details
     * 
     * @param conceptId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsUpdateDetails(conceptId: string, command: UpdateConceptDetail, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public conceptsUpdateDetails(conceptId: string, command: UpdateConceptDetail, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public conceptsUpdateDetails(conceptId: string, command: UpdateConceptDetail, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public conceptsUpdateDetails(conceptId: string, command: UpdateConceptDetail, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling conceptsUpdateDetails.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling conceptsUpdateDetails.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/concepts/${encodeURIComponent(String(conceptId))}/details`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Files
     * 
     * @param conceptId 
     * @param fileContainerIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsUpdateFileContainers(conceptId: string, fileContainerIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public conceptsUpdateFileContainers(conceptId: string, fileContainerIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public conceptsUpdateFileContainers(conceptId: string, fileContainerIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public conceptsUpdateFileContainers(conceptId: string, fileContainerIds: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (conceptId === null || conceptId === undefined) {
            throw new Error('Required parameter conceptId was null or undefined when calling conceptsUpdateFileContainers.');
        }
        if (fileContainerIds === null || fileContainerIds === undefined) {
            throw new Error('Required parameter fileContainerIds was null or undefined when calling conceptsUpdateFileContainers.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/articlemanagement/concepts/${encodeURIComponent(String(conceptId))}/files`,
                fileContainerIds,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update Service Models
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public conceptsUpdateServiceModels(command: UpdateConceptServiceModels, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public conceptsUpdateServiceModels(command: UpdateConceptServiceModels, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public conceptsUpdateServiceModels(command: UpdateConceptServiceModels, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public conceptsUpdateServiceModels(command: UpdateConceptServiceModels, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling conceptsUpdateServiceModels.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/concepts/servicemodels`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
