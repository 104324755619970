import { ChangeDetectionStrategy, Component } from '@angular/core';

import { UserTileComponent } from '../../../../../shared/ui/user/user-tile/user-tile.component';

/**
 * Represents one row in the user list, when LIST view used.
 */
@Component( {
    selector:        'rsp-user-line',
    templateUrl:     './user-line.component.html',
    styleUrls:       [ './user-line.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class UserLineComponent extends UserTileComponent {
}
