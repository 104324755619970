<input
    #fileInput
    [attr.multiple]="multiple ? true : null"
    [attr.accept]="acceptedFileExtensions || null"
    [attr.id]="inputFieldHtmlId || null"
    [attr.title]="!fileArray || !fileArray.length ? 'no file selected' : null"

    rspDrop

    (dragged)="dragActive = true"
    (draggedEnd)="dragActive = false"
    (dropped)="dropFiles( $event )"
    (change)="changeFiles()"

    type="file"
    class="drop-zone--input"
>

<div
    [class.is-drop-zone-active]="dragActive"
    class="drop-zone--label"
>
    <ng-content></ng-content>
</div>

