/* tslint:disable:max-line-length */
import { NgModule, } from '@angular/core';
import { RouterModule, Routes, } from '@angular/router';

import { NonTradeItemDetailsPageComponent, } from './non-trade-items/non-trade-item/non-trade-item-details-page.component';
import { NonTradeItemAssemblyComponentsComponent, } from './non-trade-items/non-trade-item/non-trade-item-assembly-components/non-trade-item-assembly-components.component';
import { NonTradeItemDetailsComponent, } from './non-trade-items/non-trade-item/non-trade-item-details/non-trade-item-details.component';
import { NonTradeItemFilesComponent, } from './non-trade-items/non-trade-item/non-trade-item-files/non-trade-item-files.component';
import { NonTradeItemGraphicsComponent, } from './non-trade-items/non-trade-item/non-trade-item-graphics/non-trade-item-graphics.component';
import { NonTradeItemHistoryComponent, } from './non-trade-items/non-trade-item/non-trade-item-history/non-trade-item-history.component';
import { ArticleDetailSpmComponent, } from './non-trade-items/non-trade-item/article-detail-spm/article-detail-spm.component';
import { NonTradeItemStylesAndArtworksComponent, } from './non-trade-items/non-trade-item/non-trade-item-styles-and-artworks/non-trade-item-styles-and-artworks.component';
import { NonTradeItemListPageComponent, } from './non-trade-items/non-trade-item-list/non-trade-item-list-page.component';
import { NonTradeItemSummaryResolver, } from './non-trade-items/non-trade-item/non-trade-item-summary.resolver';
import { NonTradeItemArticleAssemblyUsageComponent, } from './non-trade-items/non-trade-item/non-trade-item-article-assembly-usage/non-trade-item-article-assembly-usage.component';

import { ClusterDetailsPageComponent, } from './concepts-clusters/cluster/cluster-details-page.component';
import { ClusterDetailsComponent, } from './concepts-clusters/cluster/cluster-details/cluster-details.component';
import { ClusterArticlesAndAssembliesComponent, } from './concepts-clusters/cluster/cluster-articles-and-assemblies/cluster-articles-and-assemblies.component';
import { ClusterFilesComponent, } from './concepts-clusters/cluster/cluster-files/cluster-files.component';
import { ClusterHistoryComponent, } from './concepts-clusters/cluster/cluster-history/cluster-history.component';
import { ClusterSummaryResolver, } from './concepts-clusters/cluster/cluster-summary.resolver';

import { ConceptListPageComponent, } from './concepts-clusters/concept-cluster-list/concept-cluster-list-page.component';
import { ConceptDetailsPageComponent, } from './concepts-clusters/concept/concept-details-page.component';
import { ConceptDetailsComponent, } from './concepts-clusters/concept/concept-details/concept-details.component';
import { ConceptClustersComponent, } from './concepts-clusters/concept/concept-clusters/concept-clusters.component';
import { ConceptServiceLevelsComponent, } from './concepts-clusters/concept/concept-service-levels/concept-service-levels.component';
import { ConceptFilesComponent, } from './concepts-clusters/concept/concept-files/concept-files.component';
import { ConceptHistoryComponent, } from './concepts-clusters/concept/concept-history/concept-history.component';
import { ConceptDetailResolver, } from './concepts-clusters/concept/concept-summary.resolver';

import { RspUserGuard, } from '../../core/guards/rsp-user.guard';
import { EmptyComponent, } from '../../shared/ui/empty/empty.component';
import { NonTradeItemType } from './non-trade-items/shared/non-trade-item-type.model';
import { NonTradeItemsToolbarComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-items-toolbar.component';
import { ConceptClusterToolbarComponent } from './concepts-clusters/shared/concept-cluster-toolbar/concept-cluster-toolbar.component';
import { AccessRightGuard } from '../../core/guards/access-right.guard';
import { EditConceptServiceModelsAssignmentDialogComponent } from './concepts-clusters/concept/concept-service-levels/edit-concept-service-models-assignment-dialog/edit-concept-service-models-assignment-dialog.component';
import { ArticleSummary } from '../../shared/model/articleSummary';
import { AssemblySummary } from '../../shared/model/assemblySummary';
import { ConceptSummary } from '../../shared/model/conceptSummary';
import { ClusterSummary } from '../../shared/model/clusterSummary';
import { IdentityServerLoginGuard } from '../../core/guards/identity-server-login.guard';
import { CurrentUserContext } from '../../shared/model';

/* tslint:enable:max-line-length */

export function getArticlePageTitle( routeData: { articleSummary: ArticleSummary } ): string {
    return `Article ${ routeData.articleSummary.number } ${ routeData.articleSummary.name }`;
}

export function getAssemblyPageTitle( routeData: { assemblySummary: AssemblySummary } ): string {
    return `Assembly ${ routeData.assemblySummary.number } ${ routeData.assemblySummary.name }`;
}

export function getConceptPageTitle( routeData: { conceptSummary: ConceptSummary } ): string {
    return `Concept ${ routeData.conceptSummary.tile.name }`;
}

export function getClusterPageTitle( routeData: { clusterSummary: ClusterSummary } ): string {
    return `Cluster ${ routeData.clusterSummary.tile.name }`;
}


const ARTICLE_MANAGEMENT_ROUTES: Routes = [

    {
        path:             '',
        canActivate:      [ IdentityServerLoginGuard, RspUserGuard, ],
        canActivateChild: [ IdentityServerLoginGuard, RspUserGuard, ],
        children:         [
            {
                path:        'articles-and-assemblies',
                canActivate: [ AccessRightGuard ],
                data:        {
                    accessRight: CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesBrowse,
                    pageTitle:   null, // titlePart of pathPart will be provided by ''-route.
                },
                children:    [

                    // NonTradeItem Management Toolbar
                    { path: '', component: NonTradeItemsToolbarComponent, outlet: 'secondary-toolbar' },

                    // Routes
                    {
                        path:      '',
                        component: NonTradeItemListPageComponent,
                        data:      { pageTitle: 'Articles & Assemblies', },
                    },
                ],
            },

            {
                path:             'article',
                canActivate:      [ AccessRightGuard ],
                canActivateChild: [ AccessRightGuard ],
                data:             {
                    accessRight: CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesBrowse,
                    pageTitle:   null, // titlePart of pathPart will be provided by ':no'-route.
                },
                children:         [

                    // NonTradeItem Management Toolbar
                    { path: '', component: NonTradeItemsToolbarComponent, outlet: 'secondary-toolbar' },

                    // Routes
                    {
                        path:      ':no',
                        component: NonTradeItemDetailsPageComponent,
                        data:      {
                            itemType:  NonTradeItemType.Article,
                            pageTitle: getArticlePageTitle,
                        },
                        resolve:   {
                            articleSummary: NonTradeItemSummaryResolver,
                        },
                        children:  [
                            { path: '', redirectTo: 'details', pathMatch: 'full', },
                            { path: 'assemblies', component: NonTradeItemArticleAssemblyUsageComponent, },
                            { path: 'details', component: NonTradeItemDetailsComponent, },
                            {
                                path:        'details/edit',
                                component:   NonTradeItemDetailsComponent,
                                canActivate: [ AccessRightGuard ],
                                data:        {
                                    isEditMode:       true,
                                    accessRight:      CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesArticleDetailsEdit,
                                    navigateToParent: true,
                                },
                            },
                            { path: 'files', component: NonTradeItemFilesComponent, },
                            { path: 'graphics', component: NonTradeItemGraphicsComponent, },
                            { path: 'history', component: NonTradeItemHistoryComponent, },
                            { path: 'spm', component: ArticleDetailSpmComponent, },
                            {
                                path:      'styles-and-artworks',
                                component: NonTradeItemStylesAndArtworksComponent,
                                children:  [
                                    { path: ':name', component: EmptyComponent, },
                                ],
                            },
                        ],
                    },
                ],
            },

            //   - assembly
            {
                path:             'assembly',
                canActivate:      [ AccessRightGuard ],
                canActivateChild: [ AccessRightGuard ],
                data:             {
                    accessRight: CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesBrowse,
                    pageTitle:   null, // titlePart of pathPart will be provided by ':no'-route.
                },
                children:         [

                    // NonTradeItem Management Toolbar
                    { path: '', component: NonTradeItemsToolbarComponent, outlet: 'secondary-toolbar' },

                    // Routes
                    {
                        path:      ':no',
                        component: NonTradeItemDetailsPageComponent,
                        data:      {
                            itemType: NonTradeItemType.Assembly,
                            pageTitle: getAssemblyPageTitle,
                        },
                        resolve:   {
                            assemblySummary: NonTradeItemSummaryResolver,
                        },
                        children:  [
                            { path: '', redirectTo: 'details', pathMatch: 'full', },
                            { path: 'components', component: NonTradeItemAssemblyComponentsComponent, },
                            {
                                path:        'components/edit',
                                component:   NonTradeItemAssemblyComponentsComponent,
                                canActivate: [ AccessRightGuard ],
                                data:        {
                                    isEditMode:       true,
                                    accessRight:      CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyComponentsEdit,
                                    navigateToParent: true,
                                },
                            },
                            { path: 'details', component: NonTradeItemDetailsComponent, },
                            {
                                path:        'details/edit',
                                component:   NonTradeItemDetailsComponent,
                                canActivate: [ AccessRightGuard ],
                                data:        {
                                    isEditMode:       true,
                                    accessRight:      CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyDetailsEdit,
                                    navigateToParent: true,
                                },
                            },
                            { path: 'graphics', component: NonTradeItemGraphicsComponent, },
                            { path: 'files', component: NonTradeItemFilesComponent, },
                            { path: 'history', component: NonTradeItemHistoryComponent, },
                        ],
                    },
                    {
                        path:      ':no/components/edit/multi',
                        component: NonTradeItemDetailsPageComponent,
                        data:      {
                            itemType:      NonTradeItemType.Assembly,
                            isMultiAction: true,
                        },
                        resolve:   {
                            assemblySummary: NonTradeItemSummaryResolver,
                        },
                    },
                ],
            },

            // concepts & clusters
            {
                path:        'concepts-and-clusters',
                canActivate: [ AccessRightGuard ],
                data:        {
                    accessRight: CurrentUserContext.AccessRightsEnum.ConceptsAndClustersBrowse,
                    pageTitle:   null, // titlePart of pathPart will be provided by ''-route.
                },
                children:    [
                    // ConceptCluster Toolbar
                    { path: '', component: ConceptClusterToolbarComponent, outlet: 'secondary-toolbar', },

                    // Routes
                    {
                        path:      '',
                        component: ConceptListPageComponent,
                        data:      { pageTitle: 'Concepts & Clusters', },
                    },
                ],
            },

            // concept
            {
                path:             'concept',
                canActivate:      [ AccessRightGuard ],
                canActivateChild: [ AccessRightGuard ],
                data: {
                    accessRight: CurrentUserContext.AccessRightsEnum.ConceptsAndClustersBrowse,
                    pageTitle:   null, // titlePart of pathPart will be provided by ':id'-route.
                },
                children:         [
                    // ConceptCluster Toolbar
                    { path: '', component: ConceptClusterToolbarComponent, outlet: 'secondary-toolbar', },

                    // Routes
                    {
                        path:      ':id',
                        component: ConceptDetailsPageComponent,
                        resolve:   {
                            conceptSummary: ConceptDetailResolver,
                        },
                        data:      {
                            pageTitle: getConceptPageTitle,
                        },
                        children:  [
                            { path: '', redirectTo: 'details', pathMatch: 'full', },
                            { path: 'details', component: ConceptDetailsComponent, },
                            {
                                path:        'details/edit',
                                component:   ConceptDetailsComponent,
                                canActivate: [ AccessRightGuard ],
                                data:        {
                                    isEditMode:       true,
                                    accessRight:      CurrentUserContext.AccessRightsEnum.ConceptsAndClustersConceptDetailsEdit,
                                    navigateToParent: true,
                                },
                            },
                            { path: 'clusters', component: ConceptClustersComponent, },
                            {
                                path: 'service-levels',
                                children: [
                                    {
                                        path: '',
                                        component: ConceptServiceLevelsComponent,
                                        children: [
                                            {
                                                path: 'edit',
                                                component: EditConceptServiceModelsAssignmentDialogComponent,
                                            },
                                        ],
                                    },
                                ],
                            },
                            { path: 'files', component: ConceptFilesComponent, },
                            { path: 'history', component: ConceptHistoryComponent, },
                        ],
                    },
                ],
            },

            // clusters
            {
                path:             'cluster',
                canActivate:      [ AccessRightGuard ],
                canActivateChild: [ AccessRightGuard ],
                data:             {
                    accessRight: CurrentUserContext.AccessRightsEnum.ConceptsAndClustersBrowse,
                    pageTitle:   null, // titlePart of pathPart will be provided by ':id'-route.
                },
                children:         [
                    // ConceptCluster Toolbar
                    { path: '', component: ConceptClusterToolbarComponent, outlet: 'secondary-toolbar', },

                    // Routes
                    {
                        path:      ':id',
                        component: ClusterDetailsPageComponent,
                        resolve:   {
                            clusterSummary: ClusterSummaryResolver,
                        },
                        data:      {
                            pageTitle: getClusterPageTitle,
                        },
                        children:  [
                            { path: '', redirectTo: 'details', pathMatch: 'full', },
                            { path: 'details', component: ClusterDetailsComponent, },
                            {
                                path:        'details/edit',
                                component:   ClusterDetailsComponent,
                                canActivate: [ AccessRightGuard ],
                                data:        {
                                    isEditMode:       true,
                                    accessRight:      CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterDetailsEdit,
                                    navigateToParent: true,
                                },
                            },
                            { path: 'articles-and-assemblies', component: ClusterArticlesAndAssembliesComponent, },
                            { path: 'files', component: ClusterFilesComponent, },
                            { path: 'history', component: ClusterHistoryComponent, },
                        ],
                    },
                ],
            },

        ],
    },
];


@NgModule( {
    imports: [
        RouterModule.forChild( ARTICLE_MANAGEMENT_ROUTES ),
    ],
    exports: [
        RouterModule,
    ],
} )
export class ArticleManagementRoutingModule {
}
