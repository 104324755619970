/* tslint:disable:max-line-length */
import { NgModule, } from '@angular/core';
import { RouterModule, Routes, } from '@angular/router';

import { IdentityServerLoginGuard } from '../../core/guards/identity-server-login.guard';
import { RegistrationWizardComponent } from './registration-wizard/registration-wizard.component';
/* tslint:enable:max-line-length */


const REGISTRATION_ROUTES: Routes = [
    {
        path:             '',
        canActivate:      [ IdentityServerLoginGuard, ],
        canActivateChild: [ IdentityServerLoginGuard, ],
        children:         [
            {
                path:             'registration',
                children:         [
                    // Registration Toolbar
                    // { path: '', component: RegistrationToolbarComponent, outlet: 'secondary-toolbar' },

                    // Routes
                    { path: '', component: RegistrationWizardComponent, },
                ],
            },
        ],
    },
];


@NgModule( {
    imports: [
        RouterModule.forChild( REGISTRATION_ROUTES ),
    ],
    exports: [
        RouterModule,
    ],
} )
export class RegistrationRoutingModule {
}
