import { AssemblyComponent, } from '../../../../../shared/model/assemblyComponent';
import { NonTradeListItem, } from '../../../../../shared/model/nonTradeListItem';
import { NonTradeSuggestItem, } from '../../../../../shared/model/nonTradeSuggestItem';


/**
 * Represents one item in the component table
 */
export class ComponentListItem {

    articleId: string;
    previewImageId: string;
    number: string;
    status: AssemblyComponent.StatusEnum | NonTradeListItem.StatusEnum | NonTradeSuggestItem.StatusEnum;
    name: string;
    quantity: number;

    // new quantity for edit case
    newQuantity: number;

    isNew: boolean = false;

    constructor( articleId: string,
                 previewImageId: string,
                 articleNumber: string,
                 status: AssemblyComponent.StatusEnum | NonTradeListItem.StatusEnum | NonTradeSuggestItem.StatusEnum,
                 name: string,
                 quantity: number,
                 newQuantity: number,
                 isNew: boolean = false ) {

        this.articleId      = articleId;
        this.previewImageId = previewImageId;
        this.number         = articleNumber;
        this.status         = status;
        this.name           = name;
        this.quantity       = quantity;
        this.newQuantity    = newQuantity;
        this.isNew          = isNew;
    }

    static createFromAssemblyComponent( assemblyComponent: AssemblyComponent,
                                        isNew: boolean = false ): ComponentListItem {

        if ( !assemblyComponent ) { return null; }

        // AssemblyComponent.StatusEnum

        return new ComponentListItem(
            assemblyComponent.id,
            assemblyComponent.previewImageId,
            assemblyComponent.number,
            assemblyComponent.status,
            assemblyComponent.name,
            assemblyComponent.quantity,
            assemblyComponent.quantity,
            isNew,
        );
    }

    static createFromNonTradeSuggestItem( nonTradeSuggestItem: NonTradeSuggestItem,
                                          isNew: boolean = false ): ComponentListItem {

        if ( !nonTradeSuggestItem ) { return null; }
        if ( nonTradeSuggestItem.type !== NonTradeListItem.TypeEnum.Article ) {
            throw new Error( 'ComponentListItem can not be created from ' + nonTradeSuggestItem.type );
        }

        return new ComponentListItem(
            nonTradeSuggestItem.id,
            nonTradeSuggestItem.previewImageId,
            nonTradeSuggestItem.number,
            nonTradeSuggestItem.status,
            nonTradeSuggestItem.name,
            null,
            1,
            isNew,
        );
    }

    static createFromNonTradeListItem( nonTradeListItem: NonTradeListItem,
                                       isNew: boolean = false ): ComponentListItem {

        if ( !nonTradeListItem ) { return null; }
        if ( nonTradeListItem.type !== NonTradeListItem.TypeEnum.Article ) {
            throw new Error( 'ComponentListItem can not be created from ' + nonTradeListItem.type );
        }

        return new ComponentListItem(
            nonTradeListItem.id,
            nonTradeListItem.previewImageId,
            nonTradeListItem.number,
            nonTradeListItem.status,
            nonTradeListItem.name,
            1,
            1,
            isNew,
        );
    }
}
