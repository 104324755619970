import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import { throttleTime } from 'rxjs/operators';

export type ProgressBarBackgroundColor = 'transparent' | 'super-lightest-gray' | 'lightest-gray';

/**
 * Progress-linear component.
 * Based on Angular Material -> https://material.angularjs.org/latest/demo/progressLinear
 *
 * ```html
 *
 *  <rsp-progress-linear
 *      [value]="numberValue"
 *  ></rsp-progress-linear>
 *
 *  <rsp-progress-linear
 *      [value]="progressLinearValue"
 *      [color]="'main-blue'"
 *      [backgroundColor]="'lightest-gray'"
 *  ></rsp-progress-linear>
 *
 * ```
 */
@Component( {
    selector:    'rsp-progress-linear',
    templateUrl: './progress-linear.component.html',
    styleUrls:   [
        './progress-linear.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class ProgressLinearComponent {

    /**
     * Color of the progress linear.
     */
    @Input()
    // @HostBinding( 'class' )
    color: 'main-blue' | 'light-gray' = 'main-blue';

    @Input()
    backgroundColor: ProgressBarBackgroundColor = 'lightest-gray';

    /**
     * Mode of the progress linear.
     * Possible values: 'determinate', 'indeterminate'.
     */
    @Input()
    @HostBinding( 'attr.mode' )
    mode: 'determinate' | 'indeterminate' = 'determinate';

    @Input()
    set max( value: number)  {
        this._max = value;
    }

    @Input()
    set value( value: number ) {
        const clampValue: number = this.clamp(value, 0, this._max);
        const scaledValue: number = this.scaleBetween(clampValue, 0, 1, 0, this._max);

        this.progress.next({ transform: `scaleX(${scaledValue})` } );
    }

    public progress$: Observable<object>;
    private progress: Subject<object> = new Subject<object>();
    private _max: number = 100;

    constructor() {
        this.progress$ = this.progress.asObservable().pipe( throttleTime(100) );
    }

    /** Clamps a value to be between two numbers, by default 0 and 100. */
    private clamp( value: number, min: number = 0, max: number = 100 ): number {
        return isNaN(value) ? 0 : Math.max( min, Math.min( max, value ) );
    }

    private scaleBetween(unscaledNum: number, minAllowed: 0, maxAllowed: 1, min: 0, max: number): number {
        return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed;
    }
}
