import { Injectable, } from '@angular/core';
import { I18nPluralPipe, } from '@angular/common';


interface PluralDefinition { [ amount: string ]: string; }

/**
 * @example
 *     @Component({ … })
 *     class MyComponent {
 *         constructor( private pluralService: PluralService ) {
 *             this.pluralService.pluralize( 2, 'article' ); // returns '2 articles'
 *         }
 *     }
 */
@Injectable()
export class PluralService {

    private mapping: { [ type: string ]: PluralDefinition } = {
        article: {
            '=0': 'no articles',
            '=1': 'one article',
            'other': '# articles',
        },
        artwork: {
            '=0': 'no artworks',
            '=1': '1 artwork',
            'other': '# artworks',
        },
        assembly: {
            '=0': 'no assemblies',
            '=1': 'one assembly',
            'other': '# assemblies',
        },
        cluster: {
            '=0': 'no clusters',
            '=1': 'one cluster',
            'other': '# clusters',
        },
        country: {
            '=0': 'no countries',
            '=1': '1 country',
            'other': '# countries',
        },
        'distribution center': {
            '=0': 'no distribution centers',
            '=1': '1 distribution center',
            'other': '# distribution centers',
        },
        'distribution channel': {
            '=0': 'no distribution channels',
            '=1': '1 distribution channel',
            'other': '# distribution channels',
        },
    };



    constructor( private i18nPluralPipe: I18nPluralPipe ) {
    }

    static simplePluralize( count: number, text: string ): string {
        if ( count < 0 ) {
            throw new Error( this.constructor.name + ': Count has to be positive.');
        }

        if ( count === 1 ) {
            return text;
        }

        let result: string = text;

        if ( text.charAt( text.length - 1 ) === 'y' ) {
            result = text.substr( 0, text.length - 1 ) + 'ies';
        }
        else {
            result = text + 's';
        }

        return result;
    }

    pluralize( value: number, type: string ): string {
        if ( value < 0 ) {
            throw new Error( this.constructor.name + ': Value has to be positive.');
        }

        return this.i18nPluralPipe.transform( value, this.getMappingForType( type ) );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private getMappingForType( type: string ): PluralDefinition {
        const result: PluralDefinition = this.mapping[ type.toLowerCase() ];

        if (!result) {
            throw new Error( this.constructor.name + ': Unsupported plural type for ' + type );
        }

        return result;
    }

}
