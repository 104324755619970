import { Component, ElementRef, EmbeddedViewRef, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { MainSlideInConfig, MainSlideInService, MainSlideInSize } from './main-slide-in.service';
import { mainSlideInAnimations } from './main-slide-in.animations';


/**
 * Main-Slide-In. An area where secondary-toolbar-buttons can display any component.
 * Use @see MainSlideInService to control it.
 */
@Component( {
    selector:    'rsp-main-slide-in',
    templateUrl: './main-slide-in.component.html',
    styleUrls:   [
        './main-slide-in.component.scss',
    ],
    animations:  mainSlideInAnimations,
} )
export class MainSlideInComponent implements OnInit, OnDestroy {

    @ViewChild( 'target', { read: ViewContainerRef, static: true } )
    target: ViewContainerRef;

    @ViewChild( 'backdrop', { static: true } ) backdrop: ElementRef;

    isVisible: boolean = false;

    inlineStyleAttributes: { [ property: string ]: string | number } = {};


    private isDestroyed: Subject<boolean>            = new Subject<boolean>();
    private isAnimationRunning: boolean              = false;
    private openAfterCloseAnimationFinished: boolean = false;
    private config: MainSlideInConfig;
    private embededView: EmbeddedViewRef<any>;


    constructor( private mainSlideInService: MainSlideInService ) {
    }

    ngOnInit(): void {

        this.mainSlideInService
            .config$
            .pipe(
                takeUntil( this.isDestroyed ),
                filter( () => !this.isAnimationRunning ),
            )
            .subscribe( ( config: MainSlideInConfig ) => {
                this.openCloseSlideIn( config );
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }


    onSlideInAnimationStart(): void {
        this.isAnimationRunning = true;
    }

    onSlideInAnimationDone( event: AnimationEvent ): void {

        if ( event.toState === 'out' ) {

            this.destroyTemplate();

            // close animation is finished
            if ( this.openAfterCloseAnimationFinished ) {

                this.openSlideIn();

                this.openAfterCloseAnimationFinished = false;
            }
            else {
                this.config = null;
            }
        }

        this.isAnimationRunning = false;
    }


    clickedBackdrop( event: MouseEvent ): void {
        if ( event.target === this.backdrop.nativeElement ) {
            this.closeSlideIn();
        }
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private openCloseSlideIn( config: MainSlideInConfig ): void {

        if ( config ) {

            // config provided, it means user want to open slide in

            this.config = config;

            if ( this.isVisible ) {

                // slide-in still opened. We must close it first and after the closing animation is ready, open it again with the new content.
                this.closeSlideIn();

                this.openAfterCloseAnimationFinished = true;

                // openSlideIn() will be called in onSlideInAnimationDone()
            }
            else {
                // slide-in is closed, so we can simply open it
                this.openSlideIn();
            }
        }
        else {
            this.closeSlideIn();
        }
    }

    private openSlideIn(): void {

        this.setSizeAndPosition();

        this.renderTemplate();

        this.isVisible = true;

        this.mainSlideInService.mainSlideInOpened$.emit( this.config.uniqueId );
    }

    private closeSlideIn(): void {

        this.isVisible = false;

        this.mainSlideInService.mainSlideInClosed$.emit();

        // rest will be done when the close animation is finished (see onSlideInAnimationDone)
    }


    private renderTemplate(): void {
        this.destroyTemplate();
        this.embededView = this.target.createEmbeddedView( this.config.template );
    }

    private destroyTemplate(): void {
        if ( this.embededView ) {
            this.embededView.destroy();
        }
    }

    private setSizeAndPosition(): void {

        const value: MainSlideInSize = this.config.size;

        if ( !value || value === 'full' ) {
            this.inlineStyleAttributes = { left: 0, right: 0, };
        }
        else if ( typeof value === 'object' ) {

            this.inlineStyleAttributes = value;

            if ( this.config.anchorElement ) {
                this.calculateOverlayCoordinatesAccordingToAnchor( this.config.anchorElement );
            }
        }
    }

    // TODO: This currently always aligns on the left of the anchor. We probably should have an option where to align.
    // TODO: This doesn't listen on resize events, yet.
    private calculateOverlayCoordinatesAccordingToAnchor( anchorElement: HTMLElement ): void {

        const anchorClientRect: ClientRect = anchorElement.getBoundingClientRect();
        this.inlineStyleAttributes.left    = anchorClientRect.left + 'px';
    }
}
