import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

import { ConfirmDialogService } from '../../../core/overlay/confirm-dialog/confirm-dialog.service';

@Component( {
    selector:    'rsp-remove',
    templateUrl: './remove.component.html',
    styleUrls:   [
        '../../scss/05_module/edit-icons.scss',
        './remove.component.scss',
    ],
} )
export class RemoveComponent {
    @Input() title: string = 'remove';
    @Input() text: string  = 'Do you really want to remove this item?';
    @Input() confirmButtonCaption: string;
    @Input() useButton: boolean;

    @Output() confirmClicked: EventEmitter<void> = new EventEmitter<void>();

    @ViewChild('removeAnchor') removeAnchor: ElementRef;

    constructor(
        private confirmDialogService: ConfirmDialogService,
    ) {}


    onClick( event: Event ): void {
        event.stopPropagation();

        this.confirmDialogService.openConfirmDialog( {
            anchorElement:        this.removeAnchor.nativeElement,
            text:                 this.text,
            confirmButtonCaption: this.confirmButtonCaption,
            confirmCallback:      () => this.confirmClicked.emit(),
        } );
    }
}
