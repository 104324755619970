<ng-container *ngIf="!isEditMode">
    <ng-container *ngIf="!width && !height && !depth && showNotAvailable">
        <rsp-not-available></rsp-not-available>
    </ng-container>
    <ng-container *ngIf="width || height || depth">
        {{ width || '-' }} <rsp-unit-millimeter *ngIf="width"></rsp-unit-millimeter> &times; {{ depth || '-' }} <rsp-unit-millimeter *ngIf="depth" ></rsp-unit-millimeter> &times; {{ height || '-' }} <rsp-unit-millimeter *ngIf="height"></rsp-unit-millimeter>
    </ng-container>
</ng-container>
<ng-container *ngIf="isEditMode">
    <label [for]="htmlIdFor.widthField" class="display-edit-width-depth-height--label">Width:</label>
    <input
        rspTextInput
        [withClearButton]="true"
        [textAlignedRight]="true"
        [formControl]="myFormGroup?.get('width')"
        [id]="htmlIdFor.widthField"
        [size]="sizeFor.widthField"
        type="text"
        class="display-edit-width-depth-height--input"
    > <rsp-unit-millimeter></rsp-unit-millimeter>

    &times;

    <label [for]="htmlIdFor.depthField" class="display-edit-width-depth-height--label">Depth:</label>
    <input
        rspTextInput
        [withClearButton]="true"
        [textAlignedRight]="true"
        [formControl]="myFormGroup?.get('depth')"
        [id]="htmlIdFor.depthField"
        [size]="sizeFor.depthField"
        type="text"
        class="display-edit-width-depth-height--input"
    > <rsp-unit-millimeter></rsp-unit-millimeter>

    &times;

    <label [for]="htmlIdFor.heightField" class="display-edit-width-depth-height--label">Height:</label>
    <input
        rspTextInput
        [withClearButton]="true"
        [textAlignedRight]="true"
        [formControl]="myFormGroup?.get('height')"
        [id]="htmlIdFor.heightField"
        [size]="sizeFor.heightField"
        type="text"
        class="display-edit-width-depth-height--input"
    > <rsp-unit-millimeter></rsp-unit-millimeter>

</ng-container>

<rsp-messages
    *ngIf="!hideValidationMessages && validationMessages.length > 0"
    [type]="messageTypeError"
    [messages]="validationMessages"
    [cssSubmodule]="validationMessagesCssSubmodule"
></rsp-messages>

