import { Injectable } from '@angular/core';
import { toString as lodashToString } from 'lodash';

import { DateTimeInfo } from '../../date-time/model/dateTimeInfo.model';

/**
 * Contains filters which can be used by CollectionView.
 */
@Injectable()
export class StandardFilters {

    /**
     * Default filter function. Used automatically, when <rsp-column-filter-*> is used in the template.
     * It converts given value to string and checks if it contains filter-string.
     * Following types are supported:
     * - string (case insensitive)
     * - number
     * - boolean
     * - Date (format comparable)
     * @param value
     * @param filter
     * @returns {boolean}
     */
    contains( value: any, filter: string ): boolean {

        if ( value === null || value === undefined ) {
            return false;
        }

        // string
        if ( typeof value === 'string' ) {
            return this.stringContains( value, filter );
        }

        // number
        if ( typeof value === 'number' ) {
            return lodashToString( value ).indexOf( filter ) !== -1;
        }

        // boolean
        if ( typeof value === 'boolean' ) {
            if ( value && ( filter.toLowerCase() === 'true' || filter === '1' ) ) {
                return true;
            }

            if ( !value && ( filter.toLowerCase() === 'false' || filter === '0' ) ) {
                return true;
            }
        }

        // Date
        if ( value instanceof Date ) {
            return this.dateComparableContains( value, filter );
        }

        return false;
    }

    /**
     * Checks if given string value contains filter-string. Comparison is case sensitive.
     * @param value
     * @param filter
     * @returns {boolean}
     */
    stringContainsCaseSensitive( value: string, filter: string ): boolean {

        if ( typeof value !== 'string' ) {
            throw new Error( 'Filter "stringContainsCaseSensitive" can be used only for type string, but was used for ' + typeof value + '.' );
        }

        if ( !value ) {
            return false;
        }

        return value.indexOf( filter ) !== -1;
    }

    /**
     * Checks if given string value contains filter-string. Comparison is case insensitive.
     * @param value
     * @param filter
     * @returns {boolean}
     */
    stringContains( value: string, filter: string ): boolean {

        if ( typeof value !== 'string' ) {
            throw new Error( 'Filter "stringContains" can be used only for type string, but was used for ' + typeof value + '.' );
        }

        if ( !value ) {
            return false;
        }

        return value.toLowerCase().indexOf( filter.toLowerCase() ) !== -1;
    }

    /**
     * Checks if given Date, converted to Comparable-string, contains filter-string.
     * @param value
     * @param filter
     * @returns {boolean}
     */
    dateComparableContains( value: Date, filter: string ): boolean {

        if ( !value ) {
            return false;
        }

        if ( !(value instanceof Date) ) {
            throw new Error( 'Filter "dateComparableContains" can be used only for type Date, but was used for ' + typeof value + '.' );
        }

        const dateTimeInfo: DateTimeInfo = new DateTimeInfo( value );
        return dateTimeInfo.getAsComparable().indexOf( filter ) !== -1;
    }

    /**
     * Checks if given Date, converted to HumanReadable-string, contains filter-string.
     * By the convertion to HumanReadable-string, default convertion is used (withTime = false ...).
     * NOTE: if necessary, we can create dateHumanReadableWithTimeContains() ...
     * @param value
     * @param filter
     * @returns {boolean}
     */
    dateHumanReadableContains( value: Date, filter: string ): boolean {

        if ( !value ) {
            return false;
        }

        if ( !(value instanceof Date) ) {
            throw new Error( 'Filter "dateComparableContains" can be used only for type Date, but was used for ' + typeof value + '.' );
        }

        const dateTimeInfo: DateTimeInfo = new DateTimeInfo( value );
        return dateTimeInfo.getAsHumanReadable().indexOf( filter ) !== -1;
    }

}
