import { Component, Output, EventEmitter, Input, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { ViewModelListSubComponentSuggestItem } from '../../model/viewModelListSubComponentSuggestItem';
import { SubComponentSuggestItem } from '../../model/subComponentSuggestItem';
import { SubComponentListItem } from '../../model/subComponentListItem';
import { ReplyGuid } from '../../model/replyGuid';
import { ArticleManagementSubComponentsService } from '../../api';
import { takeUntil } from 'rxjs/operators';

@Component( {
    selector:    'rsp-subcomponent-suggester',
    templateUrl: './subcomponent-suggester.component.html',
    styleUrls:   [
        './subcomponent-suggester.component.scss',
    ],
} )
export class SubComponentSuggesterComponent implements OnDestroy {
    @Output() selected: EventEmitter<SubComponentSuggestItem> = new EventEmitter<SubComponentSuggestItem>();

    @Input() itemFilter: Array<SubComponentListItem> = [];

    items: Array<SubComponentSuggestItem> = [];

    isLoading: Subscription;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private subcomponentsApi: ArticleManagementSubComponentsService,
    ) { }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    searchItems( searchTerm: string ): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }

        if ( !searchTerm || !searchTerm.trim() ) {
            this.items = [];

            return;
        }

        this.isLoading =
            this.subcomponentsApi.subComponentsSuggest( {
                term: searchTerm,
                ignoreIds:
                      this.itemFilter.length
                          ? this.itemFilter.map( ( articleSubComponent: SubComponentListItem ) => articleSubComponent.id )
                          : undefined,
                page: 1,
                size: 50,
            } )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( searchResult: ViewModelListSubComponentSuggestItem ) => {
                    this.items = searchResult.data;

                    // add searchTerm as new item, if not already in current subcomponent list && not in result list
                    if (
                        this.itemFilter.filter( ( articleSubComponent: SubComponentListItem ) => {
                            return articleSubComponent.number === searchTerm;
                        } )
                            .length === 0
                        && this.items.filter( ( item: SubComponentSuggestItem ) => {
                            return item.number === searchTerm;
                        } ).length === 0
                    ) {
                        this.items.unshift( { number: searchTerm } );
                    }
                } );
    }

    selectedItem( item: SubComponentSuggestItem ): void {
        if ( item && !item.id ) {

            this.isLoading = this.subcomponentsApi
                .subComponentsCreateSubComponent( item.number )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( result: ReplyGuid ) => {
                    item.id = result.value;
                    item.hasFiles = false;
                    this.selected.emit( item );
                } );
        }
        else {
            this.selected.emit( item );
        }
    }

}
