/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { CurrentUserContext } from '../model/currentUserContext';
import { OkResult } from '../model/okResult';
import { RegisterUser } from '../model/registerUser';
import { Reply } from '../model/reply';
import { ReplyGuid } from '../model/replyGuid';
import { RequestUserModuleGroups } from '../model/requestUserModuleGroups';
import { UpdateCurrentUserDetails } from '../model/updateCurrentUserDetails';
import { UpdateUserConcepts } from '../model/updateUserConcepts';
import { UpdateUserDistributionChannels } from '../model/updateUserDistributionChannels';
import { ViewModelItemCurrentUserRegistrationData } from '../model/viewModelItemCurrentUserRegistrationData';
import { ViewModelItemUserDetails } from '../model/viewModelItemUserDetails';
import { ViewModelListConceptListItem } from '../model/viewModelListConceptListItem';
import { ViewModelListDistributionChannelListItem } from '../model/viewModelListDistributionChannelListItem';
import { ViewModelListFileContainerWithReference } from '../model/viewModelListFileContainerWithReference';
import { ViewModelListModuleGroupInfo } from '../model/viewModelListModuleGroupInfo';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class UserManagementCurrentUserService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * GetConcepts
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserGetConcepts(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListConceptListItem>;
    public currentUserGetConcepts(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListConceptListItem>>;
    public currentUserGetConcepts(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListConceptListItem>>;
    public currentUserGetConcepts(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListConceptListItem>(
                `${this.basePath}/api/v1/usermanagement/current-user/concepts`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetConceptsAssignable - Concepts that are assignable to the current user.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserGetConceptsAssignable(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListConceptListItem>;
    public currentUserGetConceptsAssignable(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListConceptListItem>>;
    public currentUserGetConceptsAssignable(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListConceptListItem>>;
    public currentUserGetConceptsAssignable(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListConceptListItem>(
                `${this.basePath}/api/v1/usermanagement/current-user/concepts-assignable`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetCurrentUser
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserGetCurrentUser(observe?: 'body', reportProgress?: boolean): Observable<CurrentUserContext>;
    public currentUserGetCurrentUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<CurrentUserContext>>;
    public currentUserGetCurrentUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<CurrentUserContext>>;
    public currentUserGetCurrentUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<CurrentUserContext>(
                `${this.basePath}/api/v1/usermanagement/current-user`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetDetails
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserGetDetails(observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemUserDetails>;
    public currentUserGetDetails(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemUserDetails>>;
    public currentUserGetDetails(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemUserDetails>>;
    public currentUserGetDetails(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemUserDetails>(
                `${this.basePath}/api/v1/usermanagement/current-user/details`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetDistributionChannels
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserGetDistributionChannels(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListDistributionChannelListItem>;
    public currentUserGetDistributionChannels(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListDistributionChannelListItem>>;
    public currentUserGetDistributionChannels(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListDistributionChannelListItem>>;
    public currentUserGetDistributionChannels(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListDistributionChannelListItem>(
                `${this.basePath}/api/v1/usermanagement/current-user/distribution-channels`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Get CurrentUser&#39;s Files
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserGetFiles(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListFileContainerWithReference>;
    public currentUserGetFiles(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListFileContainerWithReference>>;
    public currentUserGetFiles(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListFileContainerWithReference>>;
    public currentUserGetFiles(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListFileContainerWithReference>(
                `${this.basePath}/api/v1/usermanagement/current-user/files`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetModuleGroupsForRegistration
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserGetModuleGroupsForRegistration(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListModuleGroupInfo>;
    public currentUserGetModuleGroupsForRegistration(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListModuleGroupInfo>>;
    public currentUserGetModuleGroupsForRegistration(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListModuleGroupInfo>>;
    public currentUserGetModuleGroupsForRegistration(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListModuleGroupInfo>(
                `${this.basePath}/api/v1/usermanagement/current-user/module-groups-for-registration`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetRegistrationData
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserGetRegistrationData(observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemCurrentUserRegistrationData>;
    public currentUserGetRegistrationData(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemCurrentUserRegistrationData>>;
    public currentUserGetRegistrationData(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemCurrentUserRegistrationData>>;
    public currentUserGetRegistrationData(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemCurrentUserRegistrationData>(
                `${this.basePath}/api/v1/usermanagement/current-user/registration-data`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * RegisterUser
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserRegisterUser(command: RegisterUser, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public currentUserRegisterUser(command: RegisterUser, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public currentUserRegisterUser(command: RegisterUser, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public currentUserRegisterUser(command: RegisterUser, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling currentUserRegisterUser.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/usermanagement/current-user/register`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * RequestModuleGroups
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserRequestModuleGroups(command: RequestUserModuleGroups, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public currentUserRequestModuleGroups(command: RequestUserModuleGroups, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public currentUserRequestModuleGroups(command: RequestUserModuleGroups, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public currentUserRequestModuleGroups(command: RequestUserModuleGroups, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling currentUserRequestModuleGroups.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/usermanagement/current-user/request-module-groups`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Sync CurrentUser
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserSyncCurrentUser(observe?: 'body', reportProgress?: boolean): Observable<OkResult>;
    public currentUserSyncCurrentUser(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<OkResult>>;
    public currentUserSyncCurrentUser(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<OkResult>>;
    public currentUserSyncCurrentUser(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.put<OkResult>(
                `${this.basePath}/api/v1/usermanagement/current-user/synchronize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateConcepts
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserUpdateConcepts(command: UpdateUserConcepts, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public currentUserUpdateConcepts(command: UpdateUserConcepts, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public currentUserUpdateConcepts(command: UpdateUserConcepts, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public currentUserUpdateConcepts(command: UpdateUserConcepts, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling currentUserUpdateConcepts.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/usermanagement/current-user/concepts`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateDetails
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserUpdateDetails(command: UpdateCurrentUserDetails, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public currentUserUpdateDetails(command: UpdateCurrentUserDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public currentUserUpdateDetails(command: UpdateCurrentUserDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public currentUserUpdateDetails(command: UpdateCurrentUserDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling currentUserUpdateDetails.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/usermanagement/current-user/details`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateDistributionChannels
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public currentUserUpdateDistributionChannels(command: UpdateUserDistributionChannels, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public currentUserUpdateDistributionChannels(command: UpdateUserDistributionChannels, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public currentUserUpdateDistributionChannels(command: UpdateUserDistributionChannels, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public currentUserUpdateDistributionChannels(command: UpdateUserDistributionChannels, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling currentUserUpdateDistributionChannels.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/usermanagement/current-user/distribution-channels`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
