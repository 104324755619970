import { Injectable, } from '@angular/core';

import { ComponentListItem, } from './component-list-item.model';
import { ArticleManagementAssembliesService } from '../../../../../shared/api';
import { AssemblyComponent, } from '../../../../../shared/model/assemblyComponent';
import { ViewModelListAssemblyComponent, } from '../../../../../shared/model/viewModelListAssemblyComponent';


@Injectable()
export class NonTradeItemAssemblyService {

    constructor( private assembliesApi: ArticleManagementAssembliesService ) {
    }


    /**
     *  Adds new component to the component list or increments quantity when list already
     *  contains this component.
     */
    addComponentToComponentList( component: ComponentListItem, componentItems: ComponentListItem[] ): void {

        if ( !component ) { throw new Error( '"component" can not be null!' ); }
        if ( !componentItems ) { throw new Error( '"componentItems" can not be null!' ); }

        // is component with given id already in the component list?
        const index: number = componentItems.findIndex( ( item: ComponentListItem ) => {
            return item.articleId === component.articleId;
        } );


        if ( index !== -1 ) {
            // article already exist - increment quantity
            const existingListItem: ComponentListItem = componentItems[ index ];
            existingListItem.newQuantity += component.quantity;
        }
        else {
            // article does not exists - create new component
            component.isNew = true;
            componentItems.push( component );
        }
    }


    /**
     * Takes all assembly's components from backend and merge they into component list.
     */
    addAssemblyToComponentList( assemblyId: string, componentItems: ComponentListItem[] ): void {

        if ( !componentItems ) { throw new Error( '"componentItems" can not be null!' ); }

        // get assembly components form server
        this.assembliesApi
            .assembliesGetComponents( assemblyId )
            .subscribe( ( result: ViewModelListAssemblyComponent ) => {

                if ( result.data ) {
                    result.data.forEach( ( item: AssemblyComponent ) => {

                        const newComponent: ComponentListItem = ComponentListItem.createFromAssemblyComponent( item );

                        this.addComponentToComponentList( newComponent, componentItems );
                    } );
                }
            } );

    }


    setComponentAsDeletedInComponentList( articleId: string, componentItems: ComponentListItem[] ): void {

        if ( !articleId ) { throw new Error( '"articleId" can not be null!' ); }
        if ( !componentItems ) { throw new Error( '"componentItems" can not be null!' ); }

        // search for component
        const index: number = componentItems.findIndex( ( item: ComponentListItem ) => {
            return item.articleId === articleId;
        } );


        if ( index !== -1 ) {
            // article exist - reset quantity
            const existingListItem: ComponentListItem = componentItems[ index ];
            existingListItem.newQuantity              = 0;
        }
    }


    setAssemblyComponentsAsDeletedInComponentList( assemblyId: string, componentItems: ComponentListItem[] ): void {
        if ( !componentItems ) { throw new Error( '"componentItems" can not be null!' ); }

        // get assembly components form server
        this.assembliesApi
            .assembliesGetComponents( assemblyId )
            .subscribe( ( result: ViewModelListAssemblyComponent ) => {

                if ( result.data ) {
                    result.data.forEach( ( item: AssemblyComponent ) => {

                        this.setComponentAsDeletedInComponentList( item.id, componentItems );
                    } );
                }
            } );
    }
}
