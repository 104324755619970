<ng-container *ngIf="!concept.brand || !concept.subBrand"
>
    Concept {{concept.name}} has no Brand & SubBrand defined. Categories can not be edited.
</ng-container>

<ng-container *ngIf="concept.brand && concept.subBrand">
    <div>
        <span
            *ngFor="let category of categories"
            rspKeyword
            [withClearButton]="true"
            (clearClick)="removeCategory( category )"
        >
            {{ category.name }}
        </span>
    </div>

    <rsp-dropdown
        [placeholder]="'Select Category'"
        [defaultValue]="null"
        [withSuggester]="true"
        [originalItems]="categoryOptions"
        (filteredItems)="setFilteredCategoryOptions( $event )"
        (selected)="setCategory( $event )"
    >
        <rsp-dropdown-item *ngFor="let category of filteredCategoryOptions" [item]="category">
            {{ category.name }}
        </rsp-dropdown-item>
    </rsp-dropdown>
</ng-container>
