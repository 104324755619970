import { ConceptListItem } from '../../model';

export class ConceptCollectionViewItem {
    get id(): string {
        return this.concept.id;
    }

    get name(): string {
        return this.concept.name;
    }

    get description(): string | null {
        return this.concept.description;
    }

    get brand(): string | null {
        return this.concept.brand ? this.concept.brand.name : null;
    }

    get subBrand(): string | null {
        return this.concept.subBrand ? this.concept.subBrand.name : null;
    }

    get status(): ConceptListItem.StatusEnum {
        return this.concept.status;
    }

    get statusName(): string {
        return ConceptListItem.StatusEnum.toString( this.concept.status );
    }

    constructor(
        public concept: ConceptListItem,
    ) {
    }
}
