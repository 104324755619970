import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { MainLoadingIndicatorService } from './main-loading-indicator.service';


/**
 * Main Loading Indicator. Placed below the main menu, controlled by the @see MainLoadingIndicatorService.
 */
@Component( {
    selector:    'rsp-main-loading-indicator',
    templateUrl: './main-loading-indicator.component.html',
    styleUrls:   [
        './main-loading-indicator.component.scss',
    ],
} )
export class MainLoadingIndicatorComponent {

    isVisible: Observable<boolean>;

    constructor( private mainLoadingIndicatorService: MainLoadingIndicatorService ) {

        this.isVisible = this.mainLoadingIndicatorService.isVisible$;
    }
}
