<ng-container *ngIf="!isEditMode">
    <ng-container *ngIf="actions && actions.length; else buttonOrPencil">
        <rsp-dropdown-button
            *ngIf="actions.length > 1"
            [submodule]="'primary'"
            (selected)="actionSelected( $event )"
        >
            <rsp-dropdown-button-item *ngFor="let action of actions" [value]="action">
                {{action.label}}
            </rsp-dropdown-button-item>
        </rsp-dropdown-button>

        <rsp-button
            *ngIf="actions.length === 1"
            [submodule]="'primary'"
            (clicked)="actionSelected( actions[0] )"
        >
            {{actions[0].label}}
        </rsp-button>
    </ng-container>

    <ng-template #buttonOrPencil>
        <rsp-button
            *ngIf="!singleActionPencil"
            [submodule]="'primary'"
            (click)="onEditClicked( $event )"
        >
            {{ actionButtonLabel }}
        </rsp-button>

        <div
            *ngIf="singleActionPencil"
            (click)="onEditClicked( $event )"
            class="form-buttons--edit-pencil"
        ></div>

    </ng-template>
</ng-container>

<ng-container *ngIf="isEditMode">
    <div #anchor>
        <rsp-button
            class="form-buttons--secondary-action"
            [submodule]="'secondary'"
            (click)="onCancelClicked( anchor, $event )"
        >
            {{ secondaryEditButtonLabel }}
        </rsp-button>

        <rsp-button
            [disabled]="saveDisabled()"
            [submodule]="'primary'"
            (click)="onSaveClicked( $event )"
        >
            {{ primaryEditButtonLabel }}
        </rsp-button>
    </div>
</ng-container>
