import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

import { CurrentUserService } from '../../../core/current-user.service';
import { UserSummary } from '../../../shared/model';
import { Tab } from '../../../shared/ui/navigation/vertical-tabs/tab';
import { UserService } from '../../../shared/ui/user/user.service';
import { BreadcrumbService } from '../../../shared/ui/navigation/breadcrumb/breadcrumb.service';
import { BreadcrumbPart } from '../../../shared/ui/navigation/breadcrumb/breadcrumb-part';

@Component( {
    selector:    'rsp-my-profile-page',
    templateUrl: './my-profile-page.component.html',
    styleUrls:   [ './my-profile-page.component.scss' ],
    providers:   [ BreadcrumbService, ],
} )
export class MyProfilePageComponent implements OnInit, OnDestroy {
    userSummary: UserSummary;
    tabs: Array<Tab> = [];

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private currentUserService: CurrentUserService,
        private userService: UserService,
        private breadcrumbService: BreadcrumbService,
        private activatedRoute: ActivatedRoute,
    ) {
        this.configureBreadcrumb();
    }

    ngOnInit(): void {
        // Two entry points:
        //  - of( this.activatedRoute.data ) - first component load
        //  - this.userService.summary$                 - for subscriptions of getAndPublishSummary()
        merge( this.activatedRoute.data, this.userService.summary$ )
            .pipe(
                map( ( data: { currentUserSummary: UserSummary } | UserSummary ) => this.fetchSummary( data ) ),
                distinctUntilChanged(),
                filter( ( summary: UserSummary | null ) => summary ? true : false ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( summary: UserSummary ) => {
                this.userSummary = summary;

                this.createTabs();
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
        this.breadcrumbService.disableAutoRefreshing();
    }

    private configureBreadcrumb(): void {
        const config: { [ key: string ]: BreadcrumbPart } = {};

        this.breadcrumbService.addPart( {
            label: 'My Profile',
            link:  [ '/my-profile', ],
        } );

        config[ 'profile' ]               = { label: 'Profile', };
        config[ 'distribution-channels' ] = { label: 'Distribution Channels', };
        config[ 'concepts' ]              = { label: 'Concepts', };

        this.breadcrumbService.enableAutoRefreshing( config, 2 );
    }

    private createTabs(): void {
        if ( !this.userSummary ) {
            throw new Error( 'userSummary is null' );
        }

        this.tabs = [ {
            link:  [ 'profile' ],
            label: 'Profile',
        } ];

        // Distribution Channels
        if ( Tab.shouldTabBeVisible( this.userSummary.distributionChannelsNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'distribution-channels', ],
                label: 'Distribution Channels',
                tag:   Tab.createTagFromNumber( this.userSummary.distributionChannelsNavigationItem ),
            } );
        }

        // Concepts
        if ( Tab.shouldTabBeVisible( this.userSummary.conceptsNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'concepts', ],
                label: 'Concepts',
                tag:   Tab.createTagFromNumber( this.userSummary.conceptsNavigationItem ),
            } );
        }
    }

    private fetchSummary( data: { currentUserSummary: UserSummary } | UserSummary ): UserSummary {
        if ( !data ) {
            return null;
        }

        if ( data.hasOwnProperty( 'currentUserSummary' ) ) {
            // data is { userSummary: .... }
            return (<{ currentUserSummary: UserSummary }> data).currentUserSummary;
        }

        // data is UserSummary
        return data as UserSummary;
    }
}
