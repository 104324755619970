export enum DateTimeDisplayFormat {
    /**
     * Displays datetime in human readable format.
     */
    HumanReadable,

    /**
     * Displays datetime as 'yyyy-MM-dd, hh:mm:ss Z'. Useful f.e. in the tables where table is sorted by datetime.
     */
    Comparable,

    /**
     * Displays datetime as rough time span (e.g. "a few seconds ago"). Either calculated from NOW or as time span between two datetimes.
     */
    RelativeApproximation,
}
