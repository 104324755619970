import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { DocumentManagementRoutingModule } from './document-management-routing.module';
import { DocumentListPageComponent } from './documents/document-list/document-list-page.component';
import { DocumentToolbarComponent } from './documents/document-toolbar/document-toolbar.component';
import { DocumentSelectionService } from './documents/shared/document-selection.service';
import { DocumentStoreService } from './documents/shared/document-store.service';
import { DocumentLineComponent } from './documents/document-list/document-line/document-line.component';
import { DocumentActionsListComponent } from './documents/document-toolbar/document-actions-list/document-actions-list.component';
import { AddOrEditDocumentDialogComponent } from './documents/shared/add-or-edit-document-dialog/add-or-edit-document-dialog.component';


@NgModule( {
    imports:      [
        DocumentManagementRoutingModule,
        SharedModule,
    ],
    declarations: [
        AddOrEditDocumentDialogComponent,
        DocumentActionsListComponent,
        DocumentLineComponent,
        DocumentListPageComponent,
        DocumentToolbarComponent,
    ],
    providers:    [
        DocumentSelectionService,
        DocumentStoreService,
    ],
} )
export class DocumentManagementModule {
}
