import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Subscription, of, merge, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { NonTradeItemService, } from '../../shared/non-trade-item.service';
import { ArticleSPMDetails, } from '../../../../../shared/model/articleSPMDetails';
import { ArticleManagementArticlesService } from '../../../../../shared/api';
import { ViewModelItemArticleSPMDetails } from '../../../../../shared/model/viewModelItemArticleSPMDetails';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';


@Component( {
    selector:    'rsp-article-detail-spm',
    templateUrl: './article-detail-spm.component.html',
    styleUrls:   [
        '../../../../../shared/scss/04_layout/two-columns-1200.scss',
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './article-detail-spm.component.scss',
    ],
} )
export class ArticleDetailSpmComponent implements OnInit, OnDestroy, HasLoadingIndicator {

    spmDetails: ArticleSPMDetails;
    isLoading: Subscription;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private nonTradeItemService: NonTradeItemService,
        private articlesApi: ArticleManagementArticlesService,
    ) {
    }

    ngOnInit(): void {

        if ( !this.nonTradeItemService.getCurrentNonTradeItem().isArticle() ) {
            throw Error( 'This page is available only for article.' );
        }

        // Two entry points:
        //  - of( true ) - first component load
        //  - currentNonTradeItem$ - prev/next navigation
        merge( of( true ), this.nonTradeItemService.currentNonTradeItem$ )
            .pipe(
                distinctUntilChanged(),
                loadWithProgressIndicator( () => this.articlesApi.articlesGetArticleSPMDetails( this.nonTradeItemService.getCurrentId() ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( spmDetailsContainer: ViewModelItemArticleSPMDetails ) => {
                this.spmDetails = spmDetailsContainer.data;
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }
}
