// Config for your local development environment. Adjust to your personal needs if necessary.
// Use with `npm start`.

import { Environment, } from './environment.interface';

export const environment: Environment = {
    id:                            'test',
    isAngularProdModeOn:           true,
    backendBaseUrl:                'https://rsp-test.nureg.de/api',
    supportEmailAddress:           'rsp.support@adidas.com',

    authentication: {
        clientId:  'rspTest',
        issuer:    'https://www.agrs-online.com/identity',
        loginUrl:  'https://www.agrs-online.com/identity/connect/authorize',
        logoutUrl: 'https://www.agrs-online.com/identity/connect/endsession',
        oidc:      true,
        scope:     'openid profile email roles all_claims roles_in_accesstoken identityinfo',
    },
};
