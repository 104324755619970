import { Component, Input, OnInit, OnChanges, Output, EventEmitter, } from '@angular/core';

import { environment,                                   } from '../../../../environments/environment';

interface ImageDimension {
    width?:  number;
    height?: number;
}

/**
 * ```html
 * <rsp-image
 *     [fileId]="someGUID"
 *     [alt]="alternative text"
 *     [class]="some-parent-module--image"
 *     class="some-parent-module--image-wrapper"
 * >
 * ```
 */
@Component( {
    selector:    'rsp-image',
    templateUrl: './image.component.html',
    styleUrls:   [ './image.component.scss', ],
} )
export class ImageComponent implements OnInit, OnChanges {

    /**
     * ID of the file version. Not a FileContainerId!
     */
    @Input() fileId:    string;

    /**
     * Width of the image. Deprecated, because it will be replaced with a `size` keyword in the future, once we know
     * what sizes we need.
     * @deprecated
     */
    @Input() width:      number;

    /**
     * Height of the image. Deprecated, because it will be replaced with a `size` keyword in the future, once we know
     * what sizes we need.
     * @deprecated
     */
    @Input() height:     number;

    /**
     * The alt text of the image. Must be set, but can be set to an empty string in case of double representation (when
     * the alt text is already around the image somewhere).
     */
    @Input() alt:        string;

    /**
     * An optional string containing CSS classes that are applied to the <img> tag itself.
     * Please note that, because of View Encapsulation, you need to use a shadow-piercing combinator on your CSS-selector. Example:
     * ```html
     *     <rsp-image
     *         [fileId]="someGUID"
     *         [alt]="alternative text"
     *         [class]="some-parent-module--image"
     *         class="some-parent-module--image-wrapper"
     *     >
     * ```
     *
     * ```scss
     *     .some-parent-module {
     *         &--image-wrapper ::ng-deep &--image {
     *             border-radius: 50%;
     *         }
     *     }
     * ```
     *
     * The wrapper class is recommended, to still profit from view encapsulation of the parent component (
     * `.some-parent-module--image-wrapper[_ngcontent-c19] .some-parent-module--image`
     * vs. `.some-parent-module--image`).
     */
    @Input() class:      string;

    /**
     * An optional flag, to activate alignment class. This centers the image.
     * BEWARE: container needs `position: relative`!!
     */
    @Input() alignImg:   boolean = false;


    @Output() imageLoaded: EventEmitter<void> = new EventEmitter<void>();

    defaultImage:  string = '/assets/filter.png';
    calculatedUrl: string;
    retinaUrl:     string;

    ngOnInit(): void {
        if ( !this.areParamsValid() ) {
            return;
        }

        this.width  = parseInt( ( <any> this.width  || 0 ), 10 );
        this.height = parseInt( ( <any> this.height || 0 ), 10 );

        this.setUrls();
    }

    // use ngOnChanges for async input values
    ngOnChanges(): void {
        this.setUrls();
    }


    calculateUrl( fileId: string, imageSize?: ImageDimension ): string {
        if (!fileId) {
            // use empty guid, if no fileId is given to request placeholder image from server
            fileId = '00000000-0000-0000-0000-000000000000';
        }

        let url: string = `${ environment.backendBaseUrl }/renditions/${ fileId }/previewimage`;
        const urlParams: Array<string> = [];

        // add size params
        if ( typeof imageSize === 'object' ) {
            if ( typeof imageSize.width === 'number' && imageSize.width > 0 ) {
                urlParams.push('width=' + encodeURIComponent( String( imageSize.width ) ) );
            }

            if ( typeof imageSize.height === 'number' && imageSize.height > 0 ) {
                urlParams.push('height=' + encodeURIComponent( String( imageSize.height ) ) );
            }
        }

        // add all params
        if ( urlParams.length ) {
            url += '?';
            url += urlParams.join( '&' );
        }

        return url;
    }

    emitImageLoaded(): void {
        this.imageLoaded.emit();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setUrls(): void {
            this.calculatedUrl = this.calculateUrl(
                this.fileId,
                { width: this.width, height: this.height, },
            );
            this.retinaUrl = this.calculateUrl(
                this.fileId,
                { width: this.width * 2, height: this.height * 2, },
            );
    }

    private areParamsValid(): Boolean {
        // TODO: with this check, the component does not work for async fileUrls
        // if ( !this.fileUrl ) {
        //     console.warn( 'RspImageComponent: Required parameter `fileUrl` missing.' );
        // // TODO: Report to exceptionless
        //     return false;
        // }

        if ( typeof this.alt === 'undefined' ) {
            console.warn( 'RspImageComponent: Required parameter `alt` missing.' ); // TODO: Report to exceptionless
            return false;
        }

        return true;
    }
}
