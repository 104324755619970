/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface ArticleInfo {
    id?: string;

    number?: string;

    name?: string;

    legacyNumber?: string;

    packagingUnit?: number;

    previewImageId?: string;

    spmTypeId?: string;

    status?: ArticleInfo.StatusEnum;

}

export namespace ArticleInfo {
    'use strict';
    export enum StatusEnum {
        Unpublished,
        Development,
        ActiveInPreRollout,
        ActiveInRollout,
        ActiveOnDemand,
        Discontinue,
        Inactive,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

