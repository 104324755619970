<rsp-card>
    <rsp-card-headline>Concept & Cluster</rsp-card-headline>
    <rsp-card-content>

        <ng-container *ngIf="!items?.length">
            <rsp-not-available></rsp-not-available>
        </ng-container>

        <ul
            *ngIf="items?.length > 0 "
            class="concept-and-cluster-list"
        >
            <li
                *ngFor="let item of items"
                class="concept-and-cluster-list--concept"
            >
                <a
                    [class.is-expanded]="item.isExpanded"
                    (click)="toggleItem( item )"
                    (keyup.enter)="toggleItem( item )"
                    class="concept-and-cluster-list--concept-wrapper"
                    tabindex="0"
                >
                    <!-- toggle indicator -->
                    <span class="concept-and-cluster-list--concept-toggle-indicator"></span>

                    <!-- status -->
                    <span class="concept-and-cluster-list--concept-status">
                        <rsp-status
                            [status]="item.conceptStatus"
                            [type]="conceptStatusEnumType"
                            [displayMode]="'badge'"
                        >
                        </rsp-status>
                    </span>

                    <!-- concept name -->
                    <span class="concept-and-cluster-list--concept-name">
                        {{ item.conceptName }}
                    </span>

                    <!-- usage info -->
                    <span class="concept-and-cluster-list--concept-usage-info">
                        ({{ getConceptUsageInfo( item ) }})
                    </span>
                </a>



                <ul
                    *ngIf="item.isExpanded"
                    class="concept-and-cluster-list--cluster-list"
                >
                    <li *ngIf="item.isLoading">
                        loading…
                    </li>

                    <li *ngFor="let cluster of item.clusters"
                        class="concept-and-cluster-list--cluster-wrapper"
                    >
                        <span class="concept-and-cluster-list--cluster-status">
                            <rsp-status
                                [status]="cluster.status"
                                [type]="clusterStatusEnumType"
                                [displayMode]="'badge'"
                            >
                        </rsp-status>
                        </span>

                        <span class="concept-and-cluster-list--cluster-name">
                            {{ cluster.name }}
                        </span>
                    </li>
                </ul>
            </li>
        </ul>

    </rsp-card-content>
</rsp-card>

