<rsp-dropdown
    [defaultValue]="displayValue"
    [isLoading]="isLoading"
    [placeholder]="'Search Concepts'"
    [withSuggester]="true"
    [allowClear]="true"
    [inputFieldHtmlId]="inputFieldHtmlId"
    (searchTermChanged)="searchItems($event)"
    (selected)="selectedItem($event)"
>
        <rsp-dropdown-item *ngFor="let item of items" [item]="item">
            <div class="concept-item">
                <span class="concept-item--name">{{item.name}}</span>
                <rsp-status
                    [displayMode]="'badge'"
                    [status]="item.status"
                    [type]="conceptTypeEnum"
                ></rsp-status>
            </div>
        </rsp-dropdown-item>
</rsp-dropdown>
<ng-content></ng-content>
