import { Component, Input, OnInit } from '@angular/core';

/**
 * Typical usage:
 *
 * ```
 *     <rsp-progress-step-view
 *         [steps]="[ 'Step by Step', 'Ooh baby', 'Gonna get to you girl', 'Really want you in my world' ]"
 *         [currentStep]="1"
 *     ></rsp-progress-step-view>
 * ```
 *
 * Showing step names:
 *
 * ```
 *     <rsp-progress-step-view
 *         [steps]="[ 'Step by Step', 'Ooh baby', 'Gonna get to you girl', 'Really want you in my world' ]"
 *         [currentStep]="1"
 *         [showStepNames]="true"
 *     ></rsp-progress-step-view>
 * ```
 */
@Component( {
    selector:    'rsp-progress-step-view',
    templateUrl: './progress-step-view.component.html',
    styleUrls:   [
        './progress-step-view.component.scss',
    ],
} )
export class ProgressStepViewComponent implements OnInit {
    @Input() steps: string[]     = [];
    @Input() currentStep: number = 1;
    @Input() showStepNames: boolean = false;

    ngOnInit(): void {
        if ( this.currentStep < 0 || this.currentStep > this.steps.length ) {
            throw new Error(
                `ProgressStepViewComponent: currentStep is out of bounds (must be 0 < currentStep <= ${this.steps.length}.} but is ${ this.currentStep }.`,
            );
        }
    }
}
