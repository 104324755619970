<a
    #myTile
    [class]="'non-trade-item-tile'"
    [class.is-non-trade-item-tile-checked]="isSelected"
    [routerLink]="routerLink"
    (click)="setIndex()"
>
    <article class="non-trade-item-tile--inner">

        <div *ngIf="(isSelectionAvailable | async) && isCheckboxVisible()">
            <input
                rspCheckbox
                [rspCheckboxWrapperClass]="'non-trade-item-tile--checkbox-wrapper'"
                [checked]="isSelected"
                (click)="toggleSelection( $event )"
                id="{{ item?.id }}"
                type="checkbox"
            >
        </div>

        <div class="non-trade-item-tile--content-container">
            <h3 class="non-trade-item-tile--item-number-and-status">
                <div class="non-trade-item-tile--item-number">{{ item.number }}</div>

                <div class="non-trade-item-tile--item-status">
                    <rsp-status
                        [displayMode]="'badge'"
                        [status]="item.status"
                        [type]="typeEnum"
                    ></rsp-status>
                </div>
            </h3>

            <h4 class="non-trade-item-tile--item-name">{{ item.name }}</h4>

        </div>

        <div class="non-trade-item-tile--image-container">
            <rsp-image
                [fileId]="item.previewImageId"
                [alt]="item.number + ' - ' + item.name"
                [height]="200"
                [alignImg]="true"
                class="non-trade-item-tile--image"
            ></rsp-image>
        </div>

    </article>
</a>

