/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface UpdateDocument {
    documentId?: string;

    conceptId?: string;

    brandId?: string;

    keywords?: Array<string>;

    visibleFor?: UpdateDocument.VisibleForEnum;

}

export namespace UpdateDocument {
    'use strict';
    export enum VisibleForEnum {
        All,
        Intern,
        Extern,
    }
    export namespace VisibleForEnum {
        export function toString( enumValue: VisibleForEnum ): string {
            return VisibleForEnum[ enumValue ];
        }
    }
}

