import { Component, Input, } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ClusterDetail } from '../../../../../../../shared/model/clusterDetail';
import { Tag } from '../../../../../../../shared/ui/tags/tag';
import { OptionItem } from '../../../../../../../shared/model/optionItem';
import { DistributionChannelListItem } from '../../../../../../../shared/model/distributionChannelListItem';

@Component( {
    selector:    'rsp-display-edit-own-retail',
    templateUrl: './display-edit-own-retail.component.html',
    styleUrls:   [
        './display-edit-own-retail.component.scss',
    ],
} )
export class DisplayEditOwnRetailComponent {
    @Input()
    set clusterDetails( value: ClusterDetail ) { this.setClusterDetails( value ); }
    get clusterDetails(): ClusterDetail { return this._clusterDetails; }

    @Input() myFormGroup: UntypedFormGroup;
    @Input() isEditMode: boolean = false;

    isActive: boolean = false;

    private _clusterDetails: ClusterDetail;

    getStoreFormatsAsTags(): Array<Tag> {
        if ( this.clusterDetails.distributionChannelStoreFormats ) {

            const tags: Array<Tag> = [];

            this.clusterDetails.distributionChannelStoreFormats.map( ( storeFormat: OptionItem ) => {
                tags.push( new Tag( storeFormat.name ) );
            } );

            return tags;
        }

        return null;
    }

    getStoreSubFormatsAsTags(): Array<Tag> {
        if ( this.clusterDetails.storeSubFormats ) {

            const tags: Array<Tag> = [];

            this.clusterDetails.storeSubFormats.map( ( storeSubFormat: OptionItem ) => {
                tags.push( new Tag( storeSubFormat.name ) );
            } );

            return tags;
        }

        return null;
    }

    private setClusterDetails( value: ClusterDetail ): void {
        this._clusterDetails = value;
        this.isActive = value.distributionChannels.findIndex( ( item: DistributionChannelListItem ) => item.name === 'Own Retail' ) !== -1;
    }
}
