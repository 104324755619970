import { Component, ContentChildren, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { PopupService } from '../../../core/overlay/popup/popup.service';
import { DropdownButtonItemComponent } from './dropdown-button-item.component';

type Submodule = 'primary' | 'primary-light' | 'secondary' | 'tertiary';


/**
 * Dropdown like component, renders button and select list.
 *
 *
 * ## Dynamic values ##
 *
 * ```html
 *      <rsp-dropdown-button
 *          [submodule]="'primary'"
 *          (selected)="onSelected( $event )"
 *      >
 *          <rsp-dropdown-button-item
 *              *ngFor="let item of stringArray"
 *              [value]="item"
 *          >{{ item }}</rsp-dropdown-button-item>
 *      </rsp-dropdown-button>
 * ```
 *
 *  * ## Static values ##
 *
 * ```html
 *      <rsp-dropdown-button
 *          [submodule]="'primary'"
 *          (selected)="onSelected( $event )"
 *      >
 *          <rsp-dropdown-button-item [value]="value_1">Value 1</rsp-dropdown-button-item>
 *          <rsp-dropdown-button-item [value]="value_2">Value 2</rsp-dropdown-button-item>
 *          <rsp-dropdown-button-item [value]="value_3">Value 2</rsp-dropdown-button-item>
 *      </rsp-dropdown-button>
 * ```
 *
 *
 */
@Component( {
    selector:    'rsp-dropdown-button',
    templateUrl: './dropdown-button.component.html',
    styleUrls:   [
        './dropdown-button.component.scss',
    ],
} )
export class DropdownButtonComponent implements OnInit, OnDestroy {


    @ViewChild( 'button', { static: true } ) buttonElement: ElementRef;

    @ViewChild( 'container', { static: true } ) container: ElementRef;

    @ViewChild( 'dropdownListTemplate', { read: TemplateRef, static: true } ) template: TemplateRef<any>;

    @ContentChildren( DropdownButtonItemComponent ) children: QueryList<DropdownButtonItemComponent>;

    /**
     * Button's label.
     */
    @Input() label: string = 'Select';

    /**
     * Specifies button's type. Available values: 'primary' | 'primary-light' | 'secondary' | 'tertiary'.
     */
    @Input() submodule: Submodule;

    /**
     * Is dropdown-button disabled?
     */
    @Input() disabled: boolean = false;

    /**
     * Occurs after one list item has been selected.
     */
    @Output() selected: EventEmitter<any> = new EventEmitter();

    isExpanded: boolean = false;

    popupWidth: number;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();


    constructor(
        private popupService: PopupService,
    ) {
    }


    ngOnInit(): void {

        this.popupService
            .popupClosed$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                this.isExpanded = false;
            } );

        this.popupService
            .popupOpened$
            .pipe(
                filter( ( triggerElement: ElementRef ) => this.container === triggerElement ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                this.isExpanded = true;
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    selectNextItem( event: KeyboardEvent ): void {
        this.selectItem( event );
    }

    selectPreviousItem( event: KeyboardEvent ): void {
        this.selectItem( event, true );
    }

    togglePopup(): void {
        if ( this.isExpanded ) {
            this.closePopup();
        }
        else {
            this.openPopup();
        }
    }

    openPopup(): void {
        this.calculatePopupWidth();
        this.isExpanded = true;
        this.popupService.openOverlay( this.template, this.container );
    }

    closePopup(): void {
        this.isExpanded = false;
        this.popupService.closeOverlay();
    }

    getClassSuffix(): string {

        switch ( this.submodule ) {

            case 'primary':
                return '_primary';

            case 'primary-light':
                return '_primary-light';

            case 'secondary':
                return '_secondary';

            case 'tertiary':

            default:
                return '_tertiary';
        }
    }

    onListItemSelected( value: any ): void {

        // called by the DropdownButtonItem
        this.selected.emit( value );

        this.closePopup();
    }

    onMouseUp(): void {
        // remove focus from button after it has been clicked. Button uses correct css after this.
        setTimeout( () => this.children.first.button.nativeElement.focus() );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private calculatePopupWidth(): void {

        const rect: ClientRect = this.container.nativeElement.getBoundingClientRect();
        this.popupWidth        = rect.width;
    }


    private selectItem( event: KeyboardEvent, reverse: boolean = false ): void {
        event.stopPropagation();
        event.preventDefault();

        if ( !this.isExpanded ) {
            this.togglePopup();
        }

        const childArray: DropdownButtonItemComponent[] = this.children.toArray().filter( ( item: DropdownButtonItemComponent ) => !item.disabled );

        const index: number = childArray.findIndex( ( button: DropdownButtonItemComponent ) => button.button.nativeElement === document.activeElement );

        const buttonToFocus: DropdownButtonItemComponent = index !== -1 ? childArray[ reverse ? index - 1 : index + 1 ] : this.children.first;

        if ( buttonToFocus ) {
            buttonToFocus.button.nativeElement.focus();
        }
    }
}
