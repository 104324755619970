<rsp-dropdown
    [defaultValue]="displayValue"
    [isLoading]="isLoading"
    [withSuggester]="true"
    [placeholder]="'Search Materials'"
    [allowClear]="true"
    (searchTermChanged)="searchMaterial( $event )"
    (selected)="selectedItem( $event )"
>
    <rsp-dropdown-item *ngFor="let item of items" [item]="item">
        {{ item.name }}
    </rsp-dropdown-item>
</rsp-dropdown>
<ng-content></ng-content>
