import { Component, OnDestroy, OnInit } from '@angular/core';
import { of, merge } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ArticleManagementClustersService } from '../../../../../shared/api';
import { ClusterService } from '../cluster.service';
import { ViewModelListFileContainerWithReference } from '../../../../../shared/model/viewModelListFileContainerWithReference';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { CurrentUserContext, FileKindItem } from '../../../../../shared/model';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { SecondaryToolbarService } from '../../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { FilesComponent } from '../../../../../shared/ui/files/files.component';


@Component( {
    selector:    'rsp-cluster-files',
    templateUrl: '../../../../../shared/ui/files/files.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        '../../../../../shared/ui/files/files.component.scss',
    ],
} )
export class ClusterFilesComponent extends FilesComponent implements OnInit, OnDestroy, HasLoadingIndicator {

    constructor(
        private clustersApi: ArticleManagementClustersService,
        private clusterService: ClusterService,
        protected currentUserService: CurrentUserService,
        protected toolbarService: SecondaryToolbarService,
    ) {
        super(
            currentUserService,
            toolbarService,
            FileKindItem.AreasEnum.ConceptAndClusters,
            'Cluster',
        );
    }

    protected load(): void {
        this.currentUserService
            .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterFilesEdit )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( hasRight: boolean ) => {
                this.userHasEditRight = hasRight;
            } );

        // Two entry points:
        //  - of( true ) - first component load
        //  - this.pblService.summary$ - prev/next navigation
        merge( of( true ), this.clusterService.summary$ )
            .pipe(
                loadWithProgressIndicator( () => this.clustersApi.clustersGetFileContainers( this.clusterService.clusterId ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: ViewModelListFileContainerWithReference ) => {
                this.files = result.data;
            } );
    }

    protected updateFiles( fileIds: string[] ): void {
        this.updatingFiles =
            this.clustersApi
                .clustersUpdateFileContainers( this.clusterService.clusterId, fileIds )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.clusterService.getAndPublishSummary();
                } );
    }
}
