import { Injectable } from '@angular/core';
import { Subject ,  Observable } from 'rxjs';

@Injectable()
export class VerticalScrollService {

    /**
     * Notifies subscribers ( should only be the vertical scroll component ) when scroll calculation is needed
     */
    calculateScroll$: Observable<boolean>;

    private refresh: Subject<boolean> = new Subject();

    constructor() {
        this.calculateScroll$ = this.refresh.asObservable();
    }

    /**
     * Triggers recalculation of scroll ( determine, if scroll down / up icons should be showen, etc )
     */
    refreshScrollCalculation(): void {
        this.refresh.next( true );
    }
}
