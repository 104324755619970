import { Injectable } from '@angular/core';

import { CurrentUserContext, FacetItem } from '../../../shared/model';
import { GlobalSearchItem } from '../../../shared/model/globalSearchItem';
import { EnumConverterService } from '../../../shared/utils/enum/enum-converter.service';
import { PluralService } from '../../../shared/utils/string/plural.service';

export interface GlobalSearchCategory extends GlobalSearchItem {
    url: string;
    label?: string;
    facetItem?: FacetItem;
}

@Injectable()
export class GlobalSearchService {
    readonly articlesAndAssembliesUrl: string = '/articles-and-assemblies';
    readonly articlesUrl: string              = '/article';
    readonly assembliesUrl: string            = '/assembly';
    readonly conceptsAndClustersUrl: string   = '/concepts-and-clusters';
    readonly conceptsUrl: string              = '/concept';
    readonly clustersUrl: string              = '/cluster';
    readonly documentsUrl: string             = '/documents';
    readonly userDetailUrl: string            = '/user';
    readonly usersUrl: string                 = this.userDetailUrl + 's';

    getCategoryUrl( url: string ): string {
        let categoryUrl: string = '';

        switch ( url ) {
            case this.articlesAndAssembliesUrl:
            case this.articlesUrl:
            case this.assembliesUrl:
                categoryUrl = this.articlesAndAssembliesUrl;
                break;

            case this.conceptsAndClustersUrl:
            case this.clustersUrl:
            case this.conceptsUrl:
                categoryUrl = this.conceptsAndClustersUrl;
                break;

            case this.documentsUrl:
                categoryUrl = this.documentsUrl;
                break;

            case this.usersUrl:
            case this.userDetailUrl:
                categoryUrl = this.usersUrl;
                break;

            default:
            // ignore
        }

        return categoryUrl;
    }

    isUrlSearchable( url: string ): boolean {
        return [
            this.articlesAndAssembliesUrl,
            this.articlesUrl,
            this.assembliesUrl,
            this.conceptsAndClustersUrl,
            this.conceptsUrl,
            this.clustersUrl,
            this.documentsUrl,
            this.userDetailUrl,
            this.usersUrl,
        ].findIndex( ( availableUrl: string ) => url === availableUrl ) !== -1;
    }

    getLabelForItem( item: GlobalSearchItem ): string {
        let label: string;
        if ( item.type === GlobalSearchItem.TypeEnum.FileContainer ) {
            label = 'Document';
        }
        else {
            label = EnumConverterService.getHumanReadableEnumValue( GlobalSearchItem.TypeEnum, item.type );
        }

        label = PluralService.simplePluralize( item.count, label );

        return label;
    }

    prepareCategories( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ): GlobalSearchCategory[] {
        const result: GlobalSearchCategory[] = [];
        if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesBrowse ) ) {
            result.push( {
                type:      GlobalSearchItem.TypeEnum.Article,
                count:     0,
                url:       this.articlesAndAssembliesUrl,
                facetItem: {
                    name:   'Types',
                    type:   FacetItem.TypeEnum.Multiselect,
                    values: [ {
                        isSelected: true,
                        value:      'Article',
                    } ],
                },
            } );
            result.push( {
                type:      GlobalSearchItem.TypeEnum.Assembly,
                count:     0,
                url:       this.articlesAndAssembliesUrl,
                facetItem: {
                    name:   'Types',
                    type:   FacetItem.TypeEnum.Multiselect,
                    values: [ {
                        isSelected: true,
                        value:      'Assembly',
                    } ],
                },
            } );
        }

        if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersBrowse ) ) {
            result.push( {
                type:      GlobalSearchItem.TypeEnum.Concept,
                count:     0,
                url:       this.conceptsAndClustersUrl,
                facetItem: {
                    name:   'Type',
                    type:   FacetItem.TypeEnum.Multiselect,
                    values: [ {
                        isSelected: true,
                        value:      'Concept',
                    } ],
                },
            } );
            result.push( {
                type:      GlobalSearchItem.TypeEnum.Cluster,
                count:     0,
                url:       this.conceptsAndClustersUrl,
                facetItem: {
                    name:   'Type',
                    type:   FacetItem.TypeEnum.Multiselect,
                    values: [ {
                        isSelected: true,
                        value:      'Cluster',
                    } ],
                },
            } );
        }

        if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.DocumentsBrowse ) ) {
            result.push( {
                type:  GlobalSearchItem.TypeEnum.FileContainer,
                count: 0,
                url:   this.documentsUrl,
            } );
        }

        if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.UsersBrowse ) ) {
            result.push( {
                type:  GlobalSearchItem.TypeEnum.User,
                count: 0,
                url:   this.usersUrl,
            } );
        }

        return result;
    }

}
