import { Component, Input } from '@angular/core';

import { TooltipTriggerType } from '../tooltip/tooltip.directive';
import { TooltipConfig } from '../tooltip/tooltip.service';


@Component( {
    selector:    'rsp-info-tooltip',
    templateUrl: './info-tooltip.component.html',
    styleUrls:   [ './info-tooltip.component.scss', ],
} )
export class InfoTooltipComponent {

    @Input()
    tooltipTrigger: TooltipTriggerType = 'mouseover';

    @Input()
    tooltipConfig: TooltipConfig = null;
}
