import { Component, Input, } from '@angular/core';

@Component( {
    selector:    'rsp-link',
    templateUrl: './link.component.html',
} )
export class LinkComponent {

    @Input() type: string;
    @Input() id: string;
    @Input() useContent: boolean = false;

}
