/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { CreateMaterial } from '../model/createMaterial';
import { Reply } from '../model/reply';
import { ReplyGuid } from '../model/replyGuid';
import { SuggestSearchConfiguration } from '../model/suggestSearchConfiguration';
import { UpdateMaterial } from '../model/updateMaterial';
import { ViewModelListMaterialListItem } from '../model/viewModelListMaterialListItem';
import { ViewModelPagedListMaterialInfo } from '../model/viewModelPagedListMaterialInfo';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class ArticleManagementMaterialsService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Create
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public materialsCreate(command: CreateMaterial, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public materialsCreate(command: CreateMaterial, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public materialsCreate(command: CreateMaterial, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public materialsCreate(command: CreateMaterial, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling materialsCreate.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/materials`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetArticleMaterialOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public materialsGetArticleMaterials(observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListMaterialInfo>;
    public materialsGetArticleMaterials(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListMaterialInfo>>;
    public materialsGetArticleMaterials(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListMaterialInfo>>;
    public materialsGetArticleMaterials(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelPagedListMaterialInfo>(
                `${this.basePath}/api/v1/articlemanagement/materials/articlematerials`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetGraphicMaterialOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public materialsGetGraphicMaterials(observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListMaterialInfo>;
    public materialsGetGraphicMaterials(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListMaterialInfo>>;
    public materialsGetGraphicMaterials(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListMaterialInfo>>;
    public materialsGetGraphicMaterials(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelPagedListMaterialInfo>(
                `${this.basePath}/api/v1/articlemanagement/materials/graphicmaterials`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetList
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public materialsGetList(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListMaterialListItem>;
    public materialsGetList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListMaterialListItem>>;
    public materialsGetList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListMaterialListItem>>;
    public materialsGetList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListMaterialListItem>(
                `${this.basePath}/api/v1/articlemanagement/materials`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Suggests (searches) a non pageable list of material suggest items.                  Please do not confuse the term suggest with the suggest mechanism in Elasticsearch.
     * 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public materialsSuggestMaterialsForArticles(configuration: SuggestSearchConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListMaterialInfo>;
    public materialsSuggestMaterialsForArticles(configuration: SuggestSearchConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListMaterialInfo>>;
    public materialsSuggestMaterialsForArticles(configuration: SuggestSearchConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListMaterialInfo>>;
    public materialsSuggestMaterialsForArticles(configuration: SuggestSearchConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (configuration === null || configuration === undefined) {
            throw new Error('Required parameter configuration was null or undefined when calling materialsSuggestMaterialsForArticles.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelPagedListMaterialInfo>(
                `${this.basePath}/api/v1/articlemanagement/materials/suggest/articlematerials`,
                configuration,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Suggests (searches) a non pageable list of material suggest items.                  Please do not confuse the term suggest with the suggest mechanism in Elasticsearch.
     * 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public materialsSuggestMaterialsForGraphics(configuration: SuggestSearchConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListMaterialInfo>;
    public materialsSuggestMaterialsForGraphics(configuration: SuggestSearchConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListMaterialInfo>>;
    public materialsSuggestMaterialsForGraphics(configuration: SuggestSearchConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListMaterialInfo>>;
    public materialsSuggestMaterialsForGraphics(configuration: SuggestSearchConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (configuration === null || configuration === undefined) {
            throw new Error('Required parameter configuration was null or undefined when calling materialsSuggestMaterialsForGraphics.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelPagedListMaterialInfo>(
                `${this.basePath}/api/v1/articlemanagement/materials/suggest/graphicmaterials`,
                configuration,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Update
     * 
     * @param id 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public materialsUpdate(id: string, command: UpdateMaterial, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public materialsUpdate(id: string, command: UpdateMaterial, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public materialsUpdate(id: string, command: UpdateMaterial, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public materialsUpdate(id: string, command: UpdateMaterial, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling materialsUpdate.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling materialsUpdate.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/materials/${encodeURIComponent(String(id))}`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
