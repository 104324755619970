import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StoreInfo } from '../../../../shared/ui/lists/store/store-info.model';
import { FileContainerTile } from '../../../../shared/model';
import { DocumentStoreService } from '../shared/document-store.service';
import { DocumentSelectionService } from '../shared/document-selection.service';
import { ToolbarBaseComponent } from '../../../../shared/ui/secondary-toolbar/toolbar-base.component';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { MainSlideInService } from '../../../../core/overlay/main-slide-in/main-slide-in.service';


@Component( {
    selector:    'rsp-document-toolbar',
    templateUrl: './document-toolbar.component.html',
    styleUrls:   [
        '../../../../shared/scss/05_module/secondary-toolbar.scss',
        './document-toolbar.component.scss',
    ],
} )
export class DocumentToolbarComponent extends ToolbarBaseComponent implements OnInit {

    storeInfo: Observable<StoreInfo>;

    selectedItems: Observable<Array<FileContainerTile>>;

    isSelectionAvailable: Observable<boolean>;

    constructor(
        protected storeService: DocumentStoreService,
        private selectionService: DocumentSelectionService,
        toolbarService: SecondaryToolbarService,
        router: Router,
        activatedRoute: ActivatedRoute,
        mainSlideInService: MainSlideInService,
    ) {
        super( storeService, router, activatedRoute, mainSlideInService, toolbarService );

        this.isSelectionAvailable = this.selectionService.isSelectionAvailable$;
    }


    ngOnInit(): void {
        super.ngOnInit();

        this.storeInfo     = this.storeService.storeInfo$;
        this.selectedItems = this.selectionService.selectedItems$;
    }


    clearSelection(): void {
        this.selectionService.clearSelection();
    }
}
