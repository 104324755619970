import { Subscription ,  Observable } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';


export interface HasLoadingIndicator {
    isLoading: Subscription;
}


/**
 * Rxjs operator which wraps given observable with loading indicator logic.
 * It also uses switchMap() to correct cancel pending old requests.
 *
 * Example:
 *
 *      Observable.merge( of( true ), this.pblService.summary$ )
 *                .takeWhile( () => this.isAlive )
 *                .loadWithProgressIndicator( () => this.pblService.getComponents(), this )
 *                .subscribe( ( components: Array<ComponentTableItem> ) => {
 *                    this.components = components;
 *                } );
 */
export function loadWithProgressIndicator<T, R>(
    loadFunction: ( result?: T ) => Observable<R>,
    context: HasLoadingIndicator,
): ( source: Observable<T> ) => Observable<R> {
    return function loadWithProgressIndicatorImpl( source: Observable<T> ): Observable<R> {
        function startLoadingIndicator( ctx: HasLoadingIndicator ): void {

            // unsubscribe previous request to avoid concurrency issue (f.e. prev/next navigation)
            if ( ctx.isLoading ) {
                ctx.isLoading.unsubscribe();
            }

            ctx.isLoading = new Subscription();
        }

        function stopLoadingIndicator( ctx: HasLoadingIndicator ): void {

            if ( ctx.isLoading ) {
                ctx.isLoading.unsubscribe();
            }
        }

        /* tslint:disable:no-invalid-this */
        return source.pipe(
            tap( () => startLoadingIndicator( context ) ),
            switchMap( ( result: T ) => loadFunction( result ) ),
            tap(
                () => stopLoadingIndicator( context ),
                () => stopLoadingIndicator( context ),
            ),
        );
    };
}

