/* tslint:disable:max-line-length */
import { NgModule, } from '@angular/core';
import { RouterModule, Routes, } from '@angular/router';

import { RspUserGuard, } from '../../core/guards/rsp-user.guard';
import { MyProfilePageComponent } from './my-profile-page/my-profile-page.component';
import { IdentityServerLoginGuard } from '../../core/guards/identity-server-login.guard';
import { CurrentUserSummaryResolver } from './my-profile-page/current-user-summary.resolver';
import { UserDistributionChannelsComponent } from '../../shared/ui/user/user-distribution-channels/user-distribution-channels.component';
import { UserConceptsComponent } from '../../shared/ui/user/user-concepts/user-concepts.component';
import { UserDetailsComponent } from '../../shared/ui/user/user-details/user-details.component';
/* tslint:enable:max-line-length */


const MY_PROFILE_ROUTES: Routes = [
    {
        path:             '',
        canActivate:      [ IdentityServerLoginGuard, RspUserGuard, ],
        canActivateChild: [ IdentityServerLoginGuard, RspUserGuard, ],
        children:         [
            {
                path:             'my-profile',
                children:         [
                    // My Profile Toolbar
                    // { path: '', component: MyProfileToolbarComponent, outlet: 'secondary-toolbar' },

                    // Routes
                    {
                        path:      '',
                        component: MyProfilePageComponent,
                        resolve: {
                            currentUserSummary: CurrentUserSummaryResolver,
                        },
                        children:  [
                            { path: '', redirectTo: 'profile', pathMatch: 'full', },
                            {
                                path:     'profile',
                                children: [
                                    {
                                        path:      '',
                                        component: UserDetailsComponent,
                                        data: {
                                            userEditContext: 'my-profile',
                                        },
                                    },
                                    {
                                        path:        'edit',
                                        component:   UserDetailsComponent,
                                        data:        {
                                            isEditMode:      true,
                                            userEditContext: 'my-profile',
                                        },
                                    },
                                ],
                            },
                            {
                                path:     'distribution-channels',
                                children: [
                                    {
                                        path:      '',
                                        component: UserDistributionChannelsComponent,
                                        data: {
                                            userEditContext: 'my-profile',
                                        },
                                    },
                                    {
                                        path:        'edit',
                                        component:   UserDistributionChannelsComponent,
                                        data:        {
                                            isEditMode:      true,
                                            userEditContext: 'my-profile',
                                        },
                                    },
                                ],
                            },
                            {
                                path:     'concepts',
                                children: [
                                    {
                                        path:      '',
                                        component: UserConceptsComponent,
                                        data: {
                                            userEditContext: 'my-profile',
                                        },
                                    },
                                    {
                                        path:        'edit',
                                        component:   UserConceptsComponent,
                                        data:        {
                                            isEditMode:      true,
                                            userEditContext: 'my-profile',
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];


@NgModule( {
    imports: [
        RouterModule.forChild( MY_PROFILE_ROUTES ),
    ],
    exports: [
        RouterModule,
    ],
} )
export class MyProfileRoutingModule {
}
