import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface ElementVisibleInfo {
    isVisible: boolean;
    target: Element;
}

/**
 * To observe element visibility, integrate this service into your component. Provide the desired element (e.g. by ViewChild()) to the getObservableForElement
 * method and register the element. Don't forget to unregister the element, when observation of visibility is not needed anymore (OnDestroy at the latest).
 *
 *      ```
 *      @ViewChild( 'observableElement' ) observableElement: ElementRef;
 *
 *      ngAfterViewInit(): void {
 *          this.elementVisibilityService
 *              .getObservableForElement( this.observableElement.nativeElement )
 *              .subscribe( ( isVisible: boolean ) => {
 *                  console.log( this.observableElement + ' is ' + isVisible );
 *              } );
 *
 *          this.elementVisibilityService.register( this.observableElement.nativeElement );
 *      }
 *
 *      ngOnDestroy(): void {
 *          this.elementVisibilityService.unregister( this.observableElement.nativeElement );
 *      }
 *      ```
 */
@Injectable()
export class ElementVisibilityService {
    private observer: IntersectionObserver;
    private elementVisible: BehaviorSubject<ElementVisibleInfo> = new BehaviorSubject<ElementVisibleInfo>( { isVisible: null, target: null } );

    constructor() {
        this.observer =
            new IntersectionObserver( ( entries: IntersectionObserverEntry[] ) => {
                entries.forEach( ( entry: IntersectionObserverEntry ) => {
                    // entry[ 'isIntersecting' ] is used, because current typescript version does not know the property yet
                    this.elementVisible.next( { isVisible: entry[ 'isIntersecting' ], target: entry.target } );
                } );
            } );
    }

    getObservableForElement( element: Element ): Observable<boolean> {
        return this.elementVisible
                   .pipe(
                       filter( ( value: ElementVisibleInfo ) => value.target === element ),
                       map( ( value: ElementVisibleInfo ) => value.isVisible ),
                   );

    }

    register( element: Element ): void {
        this.observer.observe( element );
    }

    unregister( element: Element ): void {
        this.observer.unobserve( element );
    }
}
