export class Tab {
    link: Array<string>;
    label: string;
    title?: string;
    tag?: string;


    public static createTagFromNumber( value?: number ): string {

        if ( !value ) { return ''; }

        return value > 0 ? value.toString() : '';
    }

    /**
     * Returns 'true' when tab should be visible, 'false' otherwise.
     */
    public static shouldTabBeVisible( navigationItem: any ): boolean {

        return navigationItem !== undefined && navigationItem !== null;
    }
}
