import { Component, Input } from '@angular/core';

import { DisplayEditTextComponent } from '../display-edit-text/display-edit-text.component';

@Component( {
    selector:    'rsp-display-edit-phone-number',
    templateUrl: '../display-edit-text/display-edit-text.component.html',
    styleUrls:   [ '../display-edit-text/display-edit-text.component.scss' ],
} )
export class DisplayEditPhoneNumberComponent extends DisplayEditTextComponent {
    @Input() placeholder: string = 'Format: +49 9132 84 0';
}
