<ng-template #dialogHeader>
    <h2 class="standard-dialog--headline">Export search result to Ariba</h2>
</ng-template>

<ng-template #dialogContent>
    <div class="export-search-result-to-ariba-dialog--content export-search-result-to-ariba-dialog--info">
    </div>

    <div class="export-search-result-to-ariba-dialog--info">
        <ng-container *ngIf="(storeInfo | async)?.totalCount > 3000">
            <strong>ATTENTION:</strong>
            <p><i>You requested to export {{(storeInfo | async)?.totalCount}} items.</i></p>
            <p><i>Your extract will be large and take a significant amount of time to generate.</i></p>
            <p><i>In addition exports of Images and Attachments will be triggered.</i></p>
            <br>
        </ng-container>
        <p>You will be notified by E-Mails once your extract and exports are ready to download.</p>
    </div>

    <div class="standard-dialog--button" [rspLoadingIndicator]="isLoading">
        <rsp-edit-text
            [myFormControl]="nameControl"
            [placeholder]="'Export Name'"
            style="flex: 1"
        ></rsp-edit-text>
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
            class="export-search-result-to-ariba-dialog--footer-button"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            [disabled]="!nameControl.valid || !hasCheckedSheets()"
            (clicked)="doAction()"
            class="export-search-result-to-ariba-dialog--footer-button"
        >
            Trigger Export
        </rsp-button>
    </div>
</ng-template>

<ng-template #dialogFooter>
</ng-template>
