import { ArticleAssembly } from '../../../../../shared/model';

export class ArticleAssemblyCollectionViewItem {

    articleAssembly: ArticleAssembly;

    get name(): string {
        return this.articleAssembly.name;
    }

    get number(): string {
        return this.articleAssembly.number;
    }

    get qunatity(): number {
        return this.articleAssembly.quantity;
    }

    get status(): string {
        return ArticleAssembly.StatusEnum.toString( this.articleAssembly.status );
    }

    constructor( articleAssembly: ArticleAssembly ) {
        this.articleAssembly = articleAssembly;
    }
}
