import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

import { ConceptListItem } from '../../model';
import { CollectionView } from '../tables/collection-view';
import { ConceptCollectionViewItem } from './concept-collection-view-item.model';
import { StandardFilters } from '../tables/filters/standard-filters';
import { EntityTypeEnum } from '../../model/enum/entity-type.enum';

@Component( {
    selector:    'rsp-concepts',
    templateUrl: './concepts.component.html',
    styleUrls:   [ './concepts.component.scss' ],
} )
export class ConceptsComponent implements OnInit, OnChanges {
    @Input() concepts: ConceptListItem[];
    @Input() selectedConcepts: ConceptListItem[] = [];
    @Input() isEditMode: boolean = false;
    @Input() noConceptsMessage: string;
    @Output() selectionChanged: EventEmitter<ConceptListItem[]> = new EventEmitter();

    collectionView: CollectionView<ConceptCollectionViewItem> = new CollectionView();
    clusterEntityType: EntityTypeEnum = EntityTypeEnum.Cluster;

    constructor(
        private standardFilters: StandardFilters,
    ) {
    }

    ngOnInit(): void {
        this.collectionView.setGlobalFilterFunction( ( item: ConceptCollectionViewItem, filter: string ) => {
            if ( typeof filter === 'undefined' || filter === null ) {
                return true;
            }

            if (
                   this.standardFilters.contains( item.name, filter )
                || this.standardFilters.contains( item.description, filter )
                || this.standardFilters.contains( item.brand, filter )
                || this.standardFilters.contains( item.subBrand, filter )
                || this.standardFilters.contains( item.statusName, filter )
            ) {
                return true;
            }

            return false;
        } );
    }

    ngOnChanges( changes: SimpleChanges ): void {
        if ( changes.concepts || ( changes.hasOwnProperty( 'isEditMode' ) && !changes.isEditMode ) ) {
            // this.selectedConcepts.push( this.concepts[ 0 ] );

            this.collectionView
                .setSourceItems(
                    this.concepts
                        .filter( ( concept: ConceptListItem ) => this.isEditMode ? true : this.isConceptSelected( concept ) )
                        .map( ( concept: ConceptListItem ) => new ConceptCollectionViewItem( concept ) ),
                )
                .sortBy( 'name', true )
                .refresh();

        }
    }

    isConceptSelected( concept: ConceptListItem ): boolean {
        return this.selectedConcepts.some( ( selectedConcept: ConceptListItem ) => selectedConcept.id === concept.id );
    }

    toggleConceptSelection( conceptToToggle: ConceptListItem, event?: Event ): void {
        // ignore delegated click events, because there will be a change event right away that will do the toggle.
        if (
            event &&
            event.type === 'click' &&
            (
                ( event.target as HTMLElement ).nodeName.toLowerCase() === 'input' ||
                ( event.target as HTMLElement ).nodeName.toLowerCase() === 'label'
            ) &&
            ( event.currentTarget as HTMLElement ).nodeName.toLowerCase() === 'tr'
        ) {
            return;
        }

        const index: number = this.selectedConcepts.findIndex( ( selectedConcept: ConceptListItem ) => conceptToToggle.id === selectedConcept.id );
        if ( index >= 0 ) {
            this.selectedConcepts.splice( index, 1 );
        }
        else {
            this.selectedConcepts.push( conceptToToggle );
        }

        this.selectionChanged.emit( this.selectedConcepts );
    }
}
