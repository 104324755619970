import { Component } from '@angular/core';
import { Observable } from 'rxjs';

import { ViewSwitcherService } from './view-switcher.service';


export enum ViewKind {
    Table,
    List,
}


/**
 * Displays view switcher -> Table | List
 */
@Component( {
    selector:    'rsp-view-switcher',
    templateUrl: './view-switcher.component.html',
    styleUrls:   [
        './view-switcher.component.scss',
    ],
} )
export class ViewSwitcherComponent {

    active: Observable<ViewKind>;

    viewKind: any = ViewKind;


    constructor( private viewSwitcherService: ViewSwitcherService ) {
        this.active = viewSwitcherService.activeViewKind$;
    }

    changeViewKind( viewKind: ViewKind ): void {
        this.viewSwitcherService.changeViewKind( viewKind );
    }
}
