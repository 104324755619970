import { Component, Input, NgZone, OnInit, } from '@angular/core';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { DisplayEditBaseComponent } from '../shared/display-edit-base.component';
import { ValidationMessagesService } from '../../validation/validation-messages.service';
import { MessageType } from '../../../ui/messages/messages.component';

@Component( {
    selector:    'rsp-edit-multiline',
    templateUrl: './edit-multiline.component.html',
    styleUrls:   [
        './edit-multiline.component.scss',
    ],
} )
export class EditMultilineComponent extends DisplayEditBaseComponent implements OnInit {

    @Input() inputFieldHtmlId: string;

    messageTypeError: MessageType = MessageType.Error;

    constructor(
        validationMessageService: ValidationMessagesService,
    ) {
        super( validationMessageService );
    }

    ngOnInit(): void {
        this.startEditMode();

        this.myFormControl
            .valueChanges
            // in case of "clear" (via text-input directive) the changes seem to be delayed, so small delay is needed
            .pipe(
                debounceTime( 1 ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                super.updateValidationMessages( this.myFormControl );
            } );
    }

}
