import { Component, Input, } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

type WeightType      = 'packed' | 'assembled';
type WeightFieldName = 'packedDimensionsWeight' | 'assembledDimensionsWeight';

/**
 * Encapsulates the non-trade-item-weight-cards and the different handling of the validation messages.
 *
 * ```html
 * <rsp-card-weight
 *     [weightType]="'packed'"
 *     [weight]="123"
 *     [isEditMode]="true"
 *     [formGroup]="form"
 *     [inputFieldHtmlId]="'weight-packed-field'"
 * ></rsp-card-weight>
 * ```
 */
@Component( {
    selector:    'rsp-card-weight',
    templateUrl: './card-weight.component.html',
    styleUrls:   [
        './card-weight.component.scss',
    ],
} )
export class CardWeightComponent {
    @Input()
    set weightType( weightType: WeightType ) { this.setWeightType( weightType ); }
    get weightType(): WeightType { return this._weightType; }

    @Input() weight: number;
    @Input() isEditMode: boolean;
    @Input() myFormGroup: UntypedFormGroup;
    @Input() inputFieldHtmlId: string;

    fieldName: WeightFieldName;

    private _weightType: WeightType;

    setWeightType( weightType: WeightType ): void {
        this._weightType = weightType;
        this.fieldName   = weightType === 'packed' ? 'packedDimensionsWeight' : 'assembledDimensionsWeight';
    }
}
