import { OnDestroy, OnInit } from '@angular/core';
import { HasLoadingIndicator } from '../../utils/rxjs-extensions/load-with-progress-indicator.extension';
import { FileContainerItem, FileContainerWithReference, FileKindItem } from '../../model';
import { Subject, Subscription } from 'rxjs';
import { CurrentUserService } from '../../../core/current-user.service';
import { SecondaryToolbarService } from '../secondary-toolbar/secondary-toolbar.service';
import { Component } from '@angular/core';
import { Inject } from '@angular/core';

@Component({
    template: '',
})
export abstract class FilesComponent implements OnInit, OnDestroy, HasLoadingIndicator {
    files: FileContainerWithReference[] = [];
    isLoading: Subscription;
    updatingFiles: Subscription;

    userHasEditRight: boolean;

    protected isDestroyed: Subject<boolean> = new Subject<boolean>();

    protected constructor(
        protected currentUserService: CurrentUserService,
        protected toolbarService: SecondaryToolbarService,
        public fileKindArea: FileKindItem.AreasEnum,
        @Inject(String) public itemTypeString: string,
        @Inject(String) public referenceHeader: string = null,
        @Inject(String) public referenceType: string = null,
    ) {
    }

    ngOnInit(): void {
        this.load();
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
        if ( this.updatingFiles ) {
            this.updatingFiles.unsubscribe();
        }
    }

    fileAdded( fileContainer: FileContainerItem ): void {
        const fileIds: string[] = this.files.map( ( file: FileContainerWithReference ) => file.id );
        fileIds.push( fileContainer.id );

        this.updateFiles( fileIds );
    }


    fileRemoved( fileId: string ): void {
        const index: number = this.files.findIndex( ( file: FileContainerWithReference ) => file.id === fileId );

        if ( index !== -1 ) {
            this.files.splice( index, 1 );

            this.updateFiles( this.files.map( ( file: FileContainerWithReference ) => file.id ) );
        }
    }

    fileEditActive( active: boolean ): void {
        active ? this.toolbarService.hidePrevNextNavigation() : this.toolbarService.showPrevNextNavigation();
    }

    protected abstract load(): void;
    protected abstract updateFiles( fileIds: string[] ): void;

}
