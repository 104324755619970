import {
    AfterViewInit,
    Component,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subject ,  fromEvent ,  NEVER ,  interval, TimeInterval, merge } from 'rxjs';
import { distinctUntilChanged, map, switchMap, take, takeUntil, timeInterval } from 'rxjs/operators';


export interface NotificationItemConfig {
    autoClose: boolean;
    title: string;
    text?: string;
    template?: TemplateRef<any>;
    templateData?: any;
}

@Component( {
    selector:    'rsp-notification-item',
    templateUrl: './notification-item.component.html',
    styleUrls:   [
        './notification-item.component.scss',
    ],
} )
export class NotificationItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @ViewChild( 'target', { read: ViewContainerRef, static: true } ) target: ViewContainerRef;

    @Input() config: NotificationItemConfig;

    @Output() closed: EventEmitter<void> = new EventEmitter<void>();

    closingIn: number = 5;

    private viewRef: EmbeddedViewRef<any>;
    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private element: ElementRef,
    ) {
    }

    ngOnInit(): void {
        if ( this.config.template ) {
            this.viewRef = this.target.createEmbeddedView( this.config.template, this.config.templateData );
        }
        if ( this.config.text ) {
            this.target.element.nativeElement.textContent = this.config.text;
        }
    }

    ngAfterViewInit(): void {
        if ( !this.config.autoClose ) {
            return;
        }

        const isPaused$: Subject<boolean> = new Subject<boolean>();

        merge(
            fromEvent( this.element.nativeElement, 'mouseover' ),
            fromEvent( this.element.nativeElement, 'mouseout' ),
        )
            .pipe(
                map( ( event: MouseEvent ) => event.type ),
                distinctUntilChanged(),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( type: string ) => {
                isPaused$.next( type === 'mouseover' );
            } );

        isPaused$
            .pipe(
                switchMap( ( paused: boolean ) => paused ? NEVER : interval( 1000 ).pipe( timeInterval(), take( 5 ) ) ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( data: TimeInterval<any> ) => {
                this.closingIn = 4 - data.value;

                // would be nicer, to emit closed on "complete", but isPaused$ never completes
                if ( data.value === 4 ) {
                    this.closed.emit();
                }
            } );

        isPaused$.next( false );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.viewRef ) {
            this.viewRef.destroy();
        }
    }

    triggerClose(): void {
        this.closed.emit();
    }
}
