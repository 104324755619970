import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription ,  forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { Environment } from '../../../../environments/environment.interface';
import {
    ConceptListItem,
    CultureInfo,
    CurrentUserRegistrationData,
    DistributionChannelListItem,
    ModuleGroupInfo,
    RegisterUser, ReplyGuid,
    ViewModelItemCurrentUserRegistrationData,
    ViewModelListConceptListItem,
    ViewModelListCultureInfo,
    ViewModelListDistributionChannelListItem,
    ViewModelListModuleGroupInfo,
} from '../../../shared/model';
import {
    MasterDataCulturesService,
    MasterDataDistributionChannelsService,
    UserManagementCurrentUserService,
    UserManagementUsersService,
} from '../../../shared/api';


export interface RegistrationUserData {
    globalId?: string;
    firstName?: string | null;
    familyName?: string;
    email?: string;
    profileImageFileContainerId?: string | null;
    profilePreviewImageId?: string | null;
    phoneNumber?: string | null;
    moduleGroups?: ModuleGroupInfo[];
    preferredCulture?: CultureInfo | null;
    wantsEmailNotifications?: boolean;
    distributionChannels?: DistributionChannelListItem[];
    concepts?: ConceptListItem[];
}

interface RegistrationWizardStep {
    id: string;
    name: string;
    userData: RegistrationUserData | null;
}

@Component( {
    selector:    'rsp-registration-wizard',
    templateUrl: './registration-wizard.component.html',
    styleUrls:   [ './registration-wizard.component.scss' ],
} )
export class RegistrationWizardComponent implements OnInit, OnDestroy {
    readonly steps: RegistrationWizardStep[] = [
        {
            id:       'my-profile',
            name:     'My Profile',
            userData: null,
        },
        {
            id:       'distribution-channels',
            name:     'Distribution Channels',
            userData: null,
        },
        {
            id:       'concepts',
            name:     'Concepts',
            userData: null,
        },
    ];

    readonly stepNames: string[] = this.steps.map( ( step: RegistrationWizardStep ) => step.name );

    currentStepNumber: number           = 1;
    currentStep: RegistrationWizardStep = this.steps[ this.currentStepNumber - 1 ];

    user: RegistrationUserData;
    moduleGroups: ModuleGroupInfo[];
    distributionChannels: DistributionChannelListItem[];
    concepts: ConceptListItem[];
    cultures: CultureInfo[];
    isLoading: Subscription;
    registrationDone: boolean = false;
    environment: Environment  = environment;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private userManagementCurrentUserService: UserManagementCurrentUserService,
        private userManagementUsersService: UserManagementUsersService,
        private masterDataDistributionChannelsService: MasterDataDistributionChannelsService,
        private masterDataCulturesService: MasterDataCulturesService,
    ) {
    }

    ngOnInit(): void {
        this.initAdditionalData();
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    setCurrentStepUserData( userData: RegistrationUserData ): void {
        this.currentStep.userData = userData;

        this.user = {
            ...this.user,
            ...userData,
        };
    }

    goToNextStep(): void {
        this.goToStep( this.currentStepNumber + 1 );
    }

    goToPreviousStep(): void {
        this.goToStep( this.currentStepNumber - 1 );
    }

    registerUser(): void {
        const command: RegisterUser = {
            // Jacek says, the frontend shouldn't submit the following properties: firstName, familyName, email, globalId, type.
            email:                       this.user.email,
            phoneNumber:                 this.user.phoneNumber,
            profileImageFileContainerId: this.user.profileImageFileContainerId,
            moduleGroupIds:              this.user.moduleGroups.map( ( moduleGroup: ModuleGroupInfo ) => moduleGroup.id ),
            distributionChannelIds:      this.user.distributionChannels.map( (channel: DistributionChannelListItem ) => channel.id ),
            conceptIds:                  this.user.concepts.map( (concept: ConceptListItem ) => concept.id ),
        };
        this.isLoading              = this.userManagementCurrentUserService
                                          .currentUserRegisterUser( command )
                                          .pipe( takeUntil( this.isDestroyed ) )
                                          .subscribe( ( reply: ReplyGuid ) => {
                                              this.registrationDone = true;
                                          } );
    }

    private goToStep( stepNumber: number ): void {
        this.currentStepNumber = stepNumber;
        this.currentStep       = this.steps[ this.currentStepNumber - 1 ];
    }

    private initAdditionalData(): void {
        this.isLoading =
            forkJoin(
                this.userManagementCurrentUserService.currentUserGetModuleGroupsForRegistration(),
                this.masterDataDistributionChannelsService.distributionChannelsGetList(),
                this.userManagementUsersService.usersGetConceptsAssignable(),
                this.userManagementCurrentUserService.currentUserGetRegistrationData(),
                this.masterDataCulturesService.culturesGetList(),
            )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe(
                    ( result: [
                        ViewModelListModuleGroupInfo,
                        ViewModelListDistributionChannelListItem,
                        ViewModelListConceptListItem,
                        ViewModelItemCurrentUserRegistrationData,
                        ViewModelListCultureInfo
                        ] ) => {
                        this.moduleGroups         = result[ 0 ].data;
                        this.distributionChannels = result[ 1 ].data;

                        this.concepts = result[ 2 ].data;

                        const userReadonlyData: CurrentUserRegistrationData = result[ 3 ].data;
                        this.user                                           = {
                            globalId:                    userReadonlyData.globalId,
                            firstName:                   userReadonlyData.firstName,
                            familyName:                  userReadonlyData.familyName,
                            email:                       userReadonlyData.email,
                            profileImageFileContainerId: null,
                            profilePreviewImageId:       null,
                            phoneNumber:                 null,
                            moduleGroups:                [],
                            distributionChannels:        [],
                            concepts:                    [],
                        };

                        this.cultures = result[ 4 ].data;
                    },
                );
    }
}
