import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, } from '@angular/forms';

import { UniqueHtmlIdService } from '../../../core/unique-html-id.service';
import { RadioButtonGroupItem, RadioButtonGroupItemId } from './radio-button-group.model';
import { MessageType } from '../messages/messages.component';
import { ValidationMessagesService } from '../../forms/validation/validation-messages.service';

/**
 * Provides radio buttons in a button-group-like style.
 * Works stand-alone or as part of an Angular FormGroup.
 *
 * Usage:
 * ```
 * <!-- without selected value -->
 * <rsp-radio-button-group
 *     [values]="[ { id: 1, caption: 'Foo', }, { id: 2, caption: 'Bar', }, ]"
 *     [name]="'my-radio-button-group'"
 * ></rsp-radio-button-group>
 *
 * <!-- with selected value by default -->
 * <rsp-radio-button-group
 *     [values]="[ { id: 1, caption: 'Foo', }, { id: 2, caption: 'Bar', }, ]"
 *     [name]="'my-radio-button-group'"
 *     [selectedValue]="1"
 * ></rsp-radio-button-group>
 *
 * <!-- with selected value by default and @Output -->
 * <rsp-radio-button-group
 *     [values]="[ { id: 1, caption: 'Foo', }, { id: 2, caption: 'Bar', }, ]"
 *     [name]="'my-radio-button-group'"
 *     [selectedValue]="1"
 *     (selectedValueChange)="doSomethingWithSelectedValue( $event )"
 * ></rsp-radio-button-group>
 *
 * <!-- with selected value (two-way binded) -->
 * <rsp-radio-button-group
 *     [values]="[ { id: 1, caption: 'Foo', }, { id: 2, caption: 'Bar', }, ]"
 *     [name]="'my-radio-button-group'"
 *     [(selectedValue)]="1"
 * ></rsp-radio-button-group>
 *
 * <!-- with [formControl] -->
 * <rsp-radio-button-group
 *     [values]="[ { id: 1, caption: 'Foo', }, { id: 2, caption: 'Bar', }, ]"
 *     [name]="'my-radio-button-group'"
 *     [myFormControl]="myFormControl"
 * ></rsp-radio-button-group>
 * <rsp-error-messages [formErrors]="myFormControl.errors"></rsp-error-messages>
 * ```
 */
@Component( {
    selector:    'rsp-radio-button-group',
    templateUrl: './radio-button-group.component.html',
    styleUrls:   [
        './radio-button-group.component.scss',
    ],
} )
export class RadioButtonGroupComponent implements OnInit {
    /**
     * Each value will become a "button" (a radio-button).
     */
    @Input()
    set values( values: Array<RadioButtonGroupItem> ) { this.setValues( values ); }
    get values(): Array<RadioButtonGroupItem> { return this._values; }

    /**
     * Will be used for the generated formControl (in FormGroup case) and as `name`-attribute on the generated `<input type="radio">`s.
     */
    @Input() name: string;

    /**
     * Optional default selected value.
     * If myFormControl is given and has a value the value of myFormControl will win over this.
     */
    @Input() selectedValue: RadioButtonGroupItemId;
    @Output() selectedValueChange: EventEmitter<RadioButtonGroupItemId> = new EventEmitter();

    /**
     * Optional validator.
     */
    @Input() isRequired: boolean = false;

    /**
     * Optional Angular FormControl.
     */
    @Input() myFormControl: UntypedFormControl;

    /**
     * Optional string that will be used as label.
     */
    @Input() label: string;

    public radioHtmlIds: Array<string>;

    public validationMessages: string[] = [];

    public messageTypeError: MessageType = MessageType.Error;

    private _values: Array<RadioButtonGroupItem> = [];

    constructor(
        private uniqueHtmlIdService: UniqueHtmlIdService,
        private validationMessagesService: ValidationMessagesService,
    ) {
    }

    ngOnInit(): void {
        if ( !Array.isArray( this.values ) ) {
            throw new Error( 'Attribute `values` must be set!' );
        }

        if ( !this.name ) {
            throw new Error( 'Attribute `name` must be set!' );
        }

        if (
            this.myFormControl &&
            this.myFormControl.value !== 'undefined' && this.myFormControl.value !== null &&
            ( !this.selectedValue || this.selectedValue !== this.myFormControl.value )
        ) {
            this.selectedValue = this.values.find( ( value: RadioButtonGroupItem ) => value.id === this.myFormControl.value ).id;
        }

        if ( this.myFormControl && this.validationMessagesService.hasControlRequiredConstraint( this.myFormControl ) ) {
            this.validationMessages = ( this.validationMessagesService.getValidationMessages( { 'required': true } ) );

            if ( this.myFormControl ) {
                this.myFormControl
                    .valueChanges
                    .subscribe( () => {
                        if ( this.myFormControl.valid ) {
                            this.validationMessages = this.validationMessagesService.getValidationMessages( this.myFormControl.errors );
                        }
                    } );
            }
        }

    }


    public setValue( value: RadioButtonGroupItemId ): void {
        this.selectedValue = value;
        this.selectedValueChange.emit( this.selectedValue );
        if ( this.myFormControl ) {
            this.myFormControl.setValue( value );
        }
    }

    private setValues( values: Array<RadioButtonGroupItem> ): void {
        this._values = values;

        this.radioHtmlIds = [];
        this.values.forEach( () => {
            this.radioHtmlIds.push( this.uniqueHtmlIdService.getUniqueHtmlId( this.name + '-radio-button' ) );
        } );
    }
}
