import { Component, ElementRef, EmbeddedViewRef, HostBinding, HostListener, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ConfirmDialogConfig, ConfirmDialogService } from './confirm-dialog.service';
import { WindowEventService } from '../../window-event.service';
import { OverlayPositionCoordinates, OverlayPositionService } from '../overlay-position.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component( {
    selector:    'rsp-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls:   [
        './confirm-dialog.component.scss',
    ],
} )
export class ConfirmDialogComponent implements OnInit, OnDestroy {

    @ViewChild( 'target', { read: ViewContainerRef, static: true } ) target: ViewContainerRef;
    @ViewChild( 'dialog', { static: true } ) dialog: ElementRef;

    @HostBinding( 'style.display' ) display: string = 'none';

    config: ConfirmDialogConfig;
    withReason: boolean = false;
    form: UntypedFormGroup;

    private viewRef: EmbeddedViewRef<any>;
    private isDestroyed: Subject<boolean> = new Subject<boolean>();


    constructor(
        private renderer: Renderer2,
        private eventService: WindowEventService,
        private confirmDialogService: ConfirmDialogService,
        private overlayPositionService: OverlayPositionService,
    ) {}

    @HostListener( 'document:keydown', [ '$event' ])
    onKeyUp( event: KeyboardEvent ): boolean {
        if ( this.isDialogVisible() && event.key === 'Tab' ) {
            event.stopPropagation();
            return false;
        }
    }

    ngOnInit(): void {
        this.form = new UntypedFormGroup( {
            reason: new UntypedFormControl(''),
        } );

        this.eventService
            .resized$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                if ( this.isDialogVisible() ) {
                    this.positionDialog();
                }
            } );

        this.confirmDialogService
            .dialogConfig$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( config: ConfirmDialogConfig  ) => {
                if ( this.viewRef ) {
                    this.viewRef.destroy();
                }
                this.config = config;
                this.withReason = config.withReason;

                if ( config.template ) {
                    this.viewRef = this.target.createEmbeddedView( config.template, config.templateData );
                }
                if ( config.text ) {
                    this.target.element.nativeElement.textContent = config.text;
                }

                this.showDialog();

                setTimeout( () => {
                    this.positionDialog();
                } );
            } );

        this.confirmDialogService
            .isDialogVisible$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( isVisible: boolean ) => {
                if ( isVisible ) {
                    this.showDialog();
                }
                else {
                    this.hideDialog();
                }
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    closeDialog(): void {
        this.confirmDialogService.closeConfirmDialog();
    }

    clickConfirm( reason?: string): void {
        this.config.confirmCallback( reason );
        this.closeDialog();
    }

    clickCancel(): void {
        if ( typeof this.config.cancelCallback !== 'undefined' ) {
            this.config.cancelCallback();
        }
        this.closeDialog();
    }

    private positionDialog(): void {
        if ( this.config.anchorElement ) {
            const overlayPosition: OverlayPositionCoordinates =
                this.overlayPositionService.getOverlayPosition( this.dialog.nativeElement, this.config.anchorElement );

            this.renderer.setStyle( this.dialog.nativeElement, 'top', overlayPosition.top + 'px' );

            this.renderer.setStyle( this.dialog.nativeElement, 'left', overlayPosition.left + 'px' );
        }
        else {
            this.centerDialog();
        }
    }

    private centerDialog(): void {
        const coordinates: OverlayPositionCoordinates = this.overlayPositionService.getCenteredPosition( this.dialog.nativeElement );

        this.renderer.setStyle( this.dialog.nativeElement, 'top',  coordinates.top + 'px' );
        this.renderer.setStyle( this.dialog.nativeElement, 'left',  coordinates.left + 'px' );
    }

    private showDialog(): void {
        this.form.get( 'reason' ).setValue( '' );
        this.display = 'block';
    }

    private hideDialog(): void {
        this.display = 'none';
    }

    private isDialogVisible(): boolean {
        return this.display !== 'none';
    }

}
