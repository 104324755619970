import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { MasterDataBrandsService } from '../../../../../../shared/api';
import { BrandListItem } from '../../../../../../shared/model/brandListItem';
import { ViewModelListBrandListItem } from '../../../../../../shared/model/viewModelListBrandListItem';
import { BrandInfo } from '../../../../../../shared/model/brandInfo';
import { SubBrandInfo } from '../../../../../../shared/model/subBrandInfo';
import { UniqueHtmlIdService } from '../../../../../../core/unique-html-id.service';
import { ViewModelListSubBrandListItem } from '../../../../../../shared/model/viewModelListSubBrandListItem';


@Component( {
    selector:    'rsp-concept-brand-and-subbrand-edit',
    templateUrl: './concept-brand-and-subbrand-edit.component.html',
    styleUrls:   [ './concept-brand-and-subbrand-edit.component.scss' ],
} )
export class ConceptBrandAndSubBrandEditComponent implements OnInit, OnDestroy {
    @Input()
    set brandInfo( value: BrandInfo) { this.setBrandInfo( value ); }

    @Input()
    set subBrandInfo ( value: SubBrandInfo) { this.setSubbrandInfo( value ); }

    @Input() brandFormControl: UntypedFormControl;
    @Input() subBrandFormControl: UntypedFormControl;

    brandName: string;
    subBrandName: string;

    brands: BrandListItem[]   = [];
    subBrands: SubBrandInfo[] = [];

    filteredBrands: BrandListItem[];
    filteredSubBrands: SubBrandInfo[];

    htmlIdFor: { brand: string, subBrand: string };

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private brandsApi: MasterDataBrandsService,
        private uniqueHtmlIdService: UniqueHtmlIdService,
    ) {
    }

    ngOnInit(): void {
        this.htmlIdFor = {
            brand:    this.uniqueHtmlIdService.getUniqueHtmlId( 'brand' ),
            subBrand: this.uniqueHtmlIdService.getUniqueHtmlId( 'sub-brand' ),
        };

        this.brandsApi
            .brandsGetList()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( result: ViewModelListBrandListItem ) => {
                this.brands = result.data;
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    setBrand( brand: BrandListItem ): void {
        if ( !brand || this.brandFormControl.value !== brand.id ) {
            this.subBrandFormControl.reset();
            this.subBrandName = '';
            this.subBrands    = brand ? brand.subBrands : [];
        }

        this.brandName = brand ? brand.name : '';
        this.brandFormControl.setValue( brand ? brand.id : null );
    }

    setSubBrand( subBrand: SubBrandInfo ): void {
        this.subBrandName = subBrand ? subBrand.name : '';
        this.subBrandFormControl.setValue( subBrand ? subBrand.id : null );
    }

    setFilteredBrands( items: BrandListItem[] ): void {
        this.filteredBrands = items;
    }

    setFilteredSubBrands( items: SubBrandInfo[] ): void {
        this.filteredSubBrands = items;
    }

    private setBrandInfo( value: BrandInfo ): void {
        if ( !value ) {
            return;
        }
        this.brandName = value.name;
        this.brandsApi
            .brandsGetSubbrandList( value.id )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( result: ViewModelListSubBrandListItem ) => {
                this.subBrands = result.data;
            } );
    }

    private setSubbrandInfo( value: SubBrandInfo ): void {
        this.subBrandName = value ? value.name : '';
    }
}
