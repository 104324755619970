<ng-container *ngIf="isEditMode; else displayMode">
    <div>
        <rsp-display-edit-number
            [value]="dimensions.width"
            [myFormGroup]="myFormGroup"
            [isEditMode]="isEditMode"
            [name]="namePrefix + 'Width'"
            [hideValidationMessages]="true"
        >
            <rsp-unit-millimeter></rsp-unit-millimeter>/
        </rsp-display-edit-number>
        <rsp-display-edit-number
            [value]="dimensions.height"
            [myFormGroup]="myFormGroup"
            [isEditMode]="isEditMode"
            [name]="namePrefix + 'Height'"
            [hideValidationMessages]="true"
        >
            <rsp-unit-millimeter></rsp-unit-millimeter>
        </rsp-display-edit-number>
    </div>

    <rsp-messages [messages]="validationMessages" [type]="messageTypeError"></rsp-messages>
</ng-container>

<ng-template #displayMode>
    <ng-container *ngIf="dimensions.width && dimensions.height">
        {{dimensions.width + ' x ' + dimensions.height}} <rsp-unit-millimeter></rsp-unit-millimeter>
    </ng-container>

    <ng-container *ngIf="!dimensions.width && !dimensions.height">
        -
    </ng-container>
</ng-template>

