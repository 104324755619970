import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

/* tslint:disable:max-line-length */
import { RspUserGuard } from '../../core/guards/rsp-user.guard';
import { AccessRightGuard } from '../../core/guards/access-right.guard';
import { IdentityServerLoginGuard } from '../../core/guards/identity-server-login.guard';
import { DocumentToolbarComponent } from './documents/document-toolbar/document-toolbar.component';
import { DocumentListPageComponent } from './documents/document-list/document-list-page.component';
import { CurrentUserContext } from '../../shared/model';
/* tslint:enable:max-line-length */


const DOCUMENT_MANAGEMENT_ROUTES: Routes = [
    {
        path:             '',
        canActivate:      [ IdentityServerLoginGuard, RspUserGuard, ],
        canActivateChild: [ IdentityServerLoginGuard, RspUserGuard, ],
        children:         [

            // forecasts
            {
                path:             'documents',
                canActivate:      [ AccessRightGuard, ],
                canActivateChild: [ AccessRightGuard, ],
                data:             {
                    accessRight: CurrentUserContext.AccessRightsEnum.DocumentsBrowse,
                    pageTitle: 'Documents',
                },
                children:         [

                    // Document Toolbar
                    { path: '', component: DocumentToolbarComponent, outlet: 'secondary-toolbar' },

                    // Routes
                    { path: '', component: DocumentListPageComponent, },
                ],
            },
        ],
    },
];


@NgModule( {
    imports: [
        RouterModule.forChild( DOCUMENT_MANAGEMENT_ROUTES ),
    ],
    exports: [
        RouterModule,
    ],
} )
export class DocumentManagementRoutingModule {
}
