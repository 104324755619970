import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Observable, of } from 'rxjs';


import { CurrentUserService } from '../../../core/current-user.service';
import { UserService } from '../../../shared/ui/user/user.service';
import { CurrentUserContext, UserSummary } from '../../../shared/model';
import { map, switchMap } from 'rxjs/operators';

@Injectable()
export class CurrentUserSummaryResolver implements Resolve<UserSummary> {

    constructor(
        private userService: UserService,
        private currentUserService: CurrentUserService,
    ) {
    }

    resolve( route: ActivatedRouteSnapshot ): Observable<UserSummary> | Observable<any> {
        return this.currentUserService
                   .getCurrentUser()
                   .pipe(
                       switchMap( ( currentUser: CurrentUserContext ) => {
                           this.userService.setUserId( currentUser.id );

                           return this.userService.getSummary();
                       } ),
                       map( ( result: UserSummary ) => {

                           if ( result ) {
                               return result;
                           }

                           // not found - webapi-service redirects to object-not-found page
                           return of( null );
                       } ),
                   );
    }
}
