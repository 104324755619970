<label>
    <input rspCheckbox type="checkbox" [formControl]="myFormGroup.get('needsCapacity')">
    Needs Capacity?
</label>

<table *ngIf="myFormGroup.get('needsCapacity').value">
    <thead hidden>
        <tr>
            <th>Pieces</th>
            <th>Type</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngFor="let capacityItem of myFormGroup.controls.capacityItems.controls;">
            <tr>
                <td class="is-right-aligned">
                    <rsp-edit-text
                        [adjustSize]="true"
                        [myFormControl]="capacityItem.get('pieces')"
                        [hideValidationMessages]="true"
                        [textAlignedRight]="true"
                    ></rsp-edit-text>
                </td>
                <td>
                    {{capacityItem.get('name').value}}
                </td>
            </tr>
            <tr
                *ngIf="validationMessagesR.get(capacityItem.get('capacityTypeId').value)
                && validationMessagesR.get(capacityItem.get('capacityTypeId').value).length > 0"
            >
                <td colspan="100%">
                    <rsp-messages
                        [type]="messageTypeError"
                        [messages]="validationMessagesR.get(capacityItem.get('capacityTypeId').value)"
                    ></rsp-messages>
                </td>
            </tr>
        </ng-container>
    </tbody>
</table>

