import { Component, OnDestroy, ViewChild } from '@angular/core';
import { Subscription, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';

import { CurrentUserService } from '../../../../../core/current-user.service';
import { MainSlideInService } from '../../../../../core/overlay/main-slide-in/main-slide-in.service';
import { CurrentUserContext } from '../../../../../shared/model';
import { Action } from '../../../../../shared/ui/lists/actions/list-actions.model';
import { DocumentSelectionService } from '../../shared/document-selection.service';
import { AddOrEditDocumentDialogComponent } from '../../shared/add-or-edit-document-dialog/add-or-edit-document-dialog.component';
import { CreateZipFromFilesDialogComponent } from '../../../../../shared/ui/lists/create-zip-from-files-dialog/create-zip-from-files-dialog.component';


export enum DocumentActions {
    Create,
    Download,
}

export interface DocumentAction extends Action {
    dialog: DocumentActions;
}


@Component( {
    selector:    'rsp-document-actions-list',
    templateUrl: './document-actions-list.component.html',
    styleUrls:   [ './document-actions-list.component.scss' ],
} )
export class DocumentActionsListComponent implements OnDestroy {
    @ViewChild( AddOrEditDocumentDialogComponent, { static: true } ) addOrEditDocumentDialogComponent: AddOrEditDocumentDialogComponent;
    @ViewChild( CreateZipFromFilesDialogComponent, { static: true } ) createZipFromFilesDialogComponent: CreateZipFromFilesDialogComponent;

    /**
     * Actions definition.
     */
    actions: DocumentAction[] = [];

    hasAnyAction: boolean = false;

    hasSelectedItems: Observable<boolean>;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    private accessRightsSubscription: Subscription;

    constructor(
        private mainSlideInService: MainSlideInService,
        private currentUserService: CurrentUserService,
        private selectionService: DocumentSelectionService,
    ) {
        this.hasSelectedItems = this.selectionService.hasSelectedItems$.pipe( distinctUntilChanged() );
        this.buildActions();
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    selectAction( action: DocumentAction ): void {
        switch ( action.dialog ) {

            case DocumentActions.Create:
                this.addOrEditDocumentDialogComponent.openForCreate();
                break;

            case DocumentActions.Download:
                this.createZipFromFilesDialogComponent.open( this.selectionService.getSelectedItems(), 'Documents' );
                break;

            default:
                break;

        }

        this.closeSlideIn();
    }

    closeSlideIn(): void {
        this.mainSlideInService.closeSlideIn();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private buildActions(): void {
        if ( this.accessRightsSubscription ) {
            this.accessRightsSubscription.unsubscribe();
        }

        this.actions = [];

        this.accessRightsSubscription =
            this.currentUserService
                .getCurrentUserAccessRightSet()
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {

                    if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.DocumentsCreate ) ) {
                        this.actions.push( {
                            label:             'Create new Document',
                            dialog:            DocumentActions.Create,
                            requiresSelection: false,
                        } );


                        this.hasAnyAction = true;


                        this.actions.push( {
                            label:             'Download selected Documents',
                            dialog:            DocumentActions.Download,
                            requiresSelection: true,
                        } );
                    }

                    // set selection available by count of items which require selection
                    this.selectionService.setSelectionAvailable(
                        this.actions.filter( ( item: DocumentAction ) => item.requiresSelection ).length > 0,
                    );
                } );
    }
}
