<div class="file-upload">
    <div
        *ngIf="file"
        class="file-upload--file-info"
    >
        <span
            title="{{ file.name }}"
            class="file-upload--file-info-filename"
        >
            {{ file.name }}
        </span>

        <rsp-x-button
            *ngIf="isComplete"
            (clicked)="triggerRemove( $event )"
            class="file-upload--file-info-undo-button"
            title="Undo upload"
        >Undo upload</rsp-x-button>
    </div>

    <div
        *ngIf="isUploading"
        class="file-upload--upload-status"
    >
        <!--<progress-->
        <!--max="100"-->
        <!--value="{{ progress }}"-->
        <!--class="file-upload&#45;&#45;upload-status-progress"-->
        <!--&gt;</progress>-->

        <!--
            Using rsp-progress-linear leads to browsers running wild occasionally (consuming 100% CPU power and triggering max execution warnings).
            Don't know why yet. Example URL: http://localhost:4200/company/1A5A3584-2A37-72FA-3472-181978891536/services/Distributor/document/add
        -->
        <rsp-progress-linear
            [value]="progress"
            [backgroundColor]="progressBarBackgroundColor"
            [mode]="progress === 100 || progress === 0 ? 'indeterminate' : 'determinate'"
            class="file-upload--upload-status-progress"
        ></rsp-progress-linear>

        <rsp-x-button
            *ngIf="isUploading && !isComplete"
            (clicked)="cancelFileUpload( $event )"
            class="file-upload--upload-status-cancel-button"
            title="Cancel upload"
        >Cancel upload</rsp-x-button>
    </div>

    <rsp-messages
        *ngIf="isFailed"
        [type]="messageTypeError"
        [messages]="[ 'Upload failed!' ]"
    ></rsp-messages>
</div>

