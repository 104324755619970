import { Component, OnInit, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

import { FileManagementFilesService } from '../../api';


type allowedTabIndex = '0' | '-1';


const submodules: { [key: string]: string; } = {
    'primary':       'button_primary',
    'primary-light': 'button_primary-light',
    'secondary':     'button_secondary',
    'tertiary':      'button_tertiary',
    'full-width':    'button_full-width',
    'seamless':      'button_seamless',
};


/**
 * ```html
 *      <rsp-button [type]="'button'">Click me</rsp-button>                                // Makes a <button>
 *      <rsp-button [type]="'button'" [submodule]="'primary'">Click me</rsp-button>        // Makes a primary <button>
 *      <rsp-button [type]="'button'" [disabled]="true">Can't click me</rsp-button>        // Makes a disabled <button>
 *      <rsp-button [type]="'download-button'" [fileGuid]="someGuid">Download</rsp-button> // Makes an <a href="http://server/path/to/download">
 *      <rsp-button [href]="['/there']">Go there</rsp-button>                              // Makes an <a>
 * ```
 *
 * TODO:
 *     * multi-line text in button is wrong
 *     * :hover style should differ from :focus style
 */
@Component( {
    selector:        'rsp-button',
    templateUrl:     './button.component.html',
    styleUrls:       [
        './button.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class ButtonComponent implements OnInit {

    @Input() href: any[] | string;
    @Input() disabled: boolean;
    @Input() type: 'button' | 'download-button' | 'submit' | 'reset';
    @Input() fileGuid: string;
    @Input() hasFullWidth: boolean;

    @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();

    @ViewChild('button') buttonElement: ElementRef;

    routerLink: any[];

    /**
     * Allows access to the focus state of the <button> or <a> element. Adds possibility to style the button-component with :focus.
     */
    hasFocus: boolean = false;

    @Input()
    get submodule(): string {
        return this._submodule;
    }

    set submodule( value: string ) {
        if ( submodules[ value ] ) {
            this._submodule = value;
            this.updateClassName();
        }
    }

    /**
     * Set to true, displays spinner inside the button.
     */
    @Input()
    get isLoading(): boolean {
        return this._isLoading;
    }

    set isLoading( value: boolean ) {
        this._isLoading = value;
        this.updateClassName();
    }

    @Input()
    isLoadingText: string;


    tabIndex: allowedTabIndex = '0';
    className: string         = 'button';

    private _submodule: string;
    private _isLoading: boolean;


    constructor(
        public hostElement: ElementRef,
        private filesApi: FileManagementFilesService,
    ) {

        if ( !this.submodule ) {
            this.submodule = 'tertiary';
        }
    }

    ngOnInit(): void {
        if ( this.href && this.type ) {
            throw new Error( `${ this.constructor.name } should either have 'href'- or 'type'-attribute.` );
        }

        if ( this.type === 'download-button' ) {
            if ( !this.fileGuid ) {
                throw new Error( `${ this.constructor.name }: Type 'download-button' needs a 'fileGuid'-attribute.` );
            }
            this.href = this.filesApi.fileContainersGetFileAuthenticatedUrl( this.fileGuid );
        }
        else if ( Array.isArray( this.href ) ) {
            this.routerLink = this.href;
            this.href       = null;
        }
        else {
            this.type = 'button';
        }

        if ( this.disabled ) {
            this.tabIndex = '-1';
        }
    }

    onClicked( event: Event ): void {
        if ( !this._isLoading ) {
            this.clicked.emit( event );
        }
    }

    onMouseUp(): void {
        // remove focus from button after it has been clicked. Button uses correct css after this.
        this.buttonElement.nativeElement.blur();
    }

    setHasFocus( state: boolean ): void {
        this.hasFocus = state;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private updateClassName(): void {
        this.className = submodules[ this.submodule ];

        if ( this._isLoading ) {
            this.className += '--is-loading';
        }
        if ( this.hasFullWidth ) {
            this.className += ' has-full-width';
        }
    }
}
