import { Component, Input } from '@angular/core';
import { ValidationErrors } from '@angular/forms';

import { MessageType } from '../../ui/messages/messages.component';
import { ValidationMessagesService } from '../validation/validation-messages.service';


/**
 *  This component is provided for forms with dynamic controls to facilitate error message handling.
 *
 * ```html
 * <form #myForm="ngForm">
 *     <input
 *          *ngFor="let item of items"
 *          [(ngModel)]="item.name"
 *          [name]="'name_'+item.id"
 *     >
 *     <rsp-error-messages [formErrors]="myForm.form.get('name_'+item.id)?.errors"></rsp-error-messages>
 * </form>
 * ```
 */
@Component( {
    selector:    'rsp-error-messages',
    templateUrl: './error-messages.component.html',
    styleUrls:   [
        './error-messages.component.scss',
    ],
} )
export class ErrorMessagesComponent {

    @Input()
    // throws 'ReferenceError: forms_1 is not defined', if type of value is 'ValidationErrors | null'
    set formErrors( value: any ) { this.setErrorMessages( value ); }

    errorMessages: string[] = [];
    messageTypeError: MessageType = MessageType.Error;


    constructor(
        private validationMessageService: ValidationMessagesService,
    ) { }

    private setErrorMessages( value: ValidationErrors | null ): void {
        if ( value ) {
            this.errorMessages = this.validationMessageService.getValidationMessages( value );
        }
        else {
            // needed, when input parameter changes to an errorless state, otherwise already displayed errors will not get cleared.
            this.errorMessages = [];
        }
    }
}
