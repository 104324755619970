import { Directive, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ScrollContainerService } from './scroll-container.service';


@Directive( {
                selector: '[rspScrollContainer]',
            } )
export class ScrollContainerDirective implements OnInit, OnDestroy {

    /* tslint:disable:no-input-rename */
    @Input( 'rspScrollContainerIdentifier' ) scrollContainerIdentifier: string;
    /* tslint:enable:no-input-rename */

    constructor(
        private directiveElement: ElementRef,
        private scrollContainerService: ScrollContainerService,
    ) {}

    ngOnInit(): void {
        this.scrollContainerService.setScrollContainerNode(this.scrollContainerIdentifier, this.directiveElement.nativeElement);
    }

    ngOnDestroy(): void {
        this.scrollContainerService.removeScrollContainerNode( this.scrollContainerIdentifier );
    }

}
