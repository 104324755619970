import { ChangeDetectionStrategy, Component, Input, } from '@angular/core';

/**
 * Show a status circle with the name of the task.
 *
 * ```
 *     <rsp-task-todo [isDone]="false">Define something</rsp-task-todo>
 * ```
 */
@Component( {
    selector:        'rsp-task-todo',
    templateUrl:     './task-todo.component.html',
    styleUrls:       [ './task-todo.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class TaskTodoComponent {
    @Input() isDone: boolean;
}
