<div>
    <span
        *ngFor="let storeSubFormat of storeSubFormats"
        rspKeyword
        [withClearButton]="true"
        (clearClick)="removeStoreSubFormat( storeSubFormat )"
    >
        {{ storeSubFormat.name }}
    </span>
</div>

<rsp-dropdown
    [placeholder]="'Select Store Sub-Format'"
    [defaultValue]="null"
    [withSuggester]="true"
    [originalItems]="storeSubFormatOptions"
    (filteredItems)="setFilteredStoreSubFormatOptions( $event )"
    (selected)="setStoreSubFormat( $event )"
>
    <rsp-dropdown-item *ngFor="let option of filteredStoreSubFormatOptions" [item]="option">
        {{ option.name }}
    </rsp-dropdown-item>
</rsp-dropdown>
