import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { ArticleManagementClustersService } from '../../../../../shared/api';
import { ClusterSuggestItem, ViewModelPagedListClusterSuggestItem } from '../../../../../shared/model';
import { EntityTypeEnum } from '../../../../../shared/model/enum/entity-type.enum';

@Component( {
    selector:    'rsp-cluster-suggester',
    templateUrl: './cluster-suggester.component.html',
    styleUrls:   [ './cluster-suggester.component.scss' ],
} )
export class ClusterSuggesterComponent {

    /**
     * Event fired when user select one element from the suggestion list.
     * 'sourceItem' used for creation of SuggestionItem will be used as event's parameter.
     */
    @Output() selected: EventEmitter<ClusterSuggestItem> = new EventEmitter<ClusterSuggestItem>();

    @Input() itemFilter: Array<string> = [];

    /**
     * Optional: Text that will be used as "selected" value.
     */
    @Input() displayValue: string;

    @Input() disabled: boolean;

    items: Array<ClusterSuggestItem> = [];

    isLoading: Subscription;

    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.Cluster;


    constructor(
        private clustersApi: ArticleManagementClustersService,
    ) { }

    searchItems( searchTerm: string ): void {
        if ( !searchTerm || !searchTerm.trim() ) {
            searchTerm = '*';
        }

        this.isLoading =
            this.clustersApi.clustersSuggest( {
                term:              searchTerm,
                conceptId:         undefined,
                ignoreIds:         this.itemFilter.length ? this.itemFilter : undefined,
                page:              1,
                size:              50,
            } )
                .subscribe( ( searchResult: ViewModelPagedListClusterSuggestItem ) => {
                    this.items = searchResult.data;
                } );
    }

    selectedItem( item: ClusterSuggestItem ): void {
        this.selected.emit( item );
    }
}
