import { Component, OnDestroy, OnInit, } from '@angular/core';
import { Subscription, of, merge, Subject } from 'rxjs';
import { CollectionView } from '../../../../../shared/ui/tables/collection-view';
import { StandardFilters } from '../../../../../shared/ui/tables/filters/standard-filters';
import { switchMap, takeUntil } from 'rxjs/operators';

import { ClusterService, } from '../cluster.service';
import { ArticleManagementClustersService } from '../../../../../shared/api';
import { ViewModelListClusterNonTradeItem, } from '../../../../../shared/model/viewModelListClusterNonTradeItem';
import { EntityTypeEnum } from '../../../../../shared/model/enum/entity-type.enum';
import { ClusterNonTradeItem } from '../../../../../shared/model/clusterNonTradeItem';
import { NonTradeListItem } from '../../../../../shared/model/nonTradeListItem';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { ClusterNonTradeItemCollectionViewItem } from './cluster-non-trade-item-collection-view-item.model';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { NonTradeSuggestItem } from '../../../../../shared/model/nonTradeSuggestItem';
import { AddArticlesAndAssembliesToClusters, CurrentUserContext } from '../../../../../shared/model';
import { RemoveArticlesAndAssembliesFromClusters } from '../../../../../shared/model/removeArticlesAndAssembliesFromClusters';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';


@Component( {
    selector:    'rsp-cluster-articles-and-assemblies',
    templateUrl: './cluster-articles-and-assemblies.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './cluster-articles-and-assemblies.component.scss',
    ],

} )
export class ClusterArticlesAndAssembliesComponent implements OnInit, OnDestroy, HasLoadingIndicator {

    items: Array<ClusterNonTradeItem>                                     = [];
    collectionView: CollectionView<ClusterNonTradeItemCollectionViewItem> = new CollectionView<ClusterNonTradeItemCollectionViewItem>();
    statusTypeEnum: EntityTypeEnum                                        = EntityTypeEnum.NonTradeItem;

    form: UntypedFormGroup;

    isLoading: Subscription;
    typeEnumToString: ( value: number ) => string = NonTradeListItem.TypeEnum.toString;

    itemIds: string[] = [];

    userHasEditRight: boolean;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private clustersApi: ArticleManagementClustersService,
        private clusterService: ClusterService,
        private standardFilters: StandardFilters,
        private currentUserService: CurrentUserService,
    ) {
    }


    ngOnInit(): void {
        this.form = new UntypedFormGroup( {
            reason: new UntypedFormControl(''),
        } );

        this.currentUserService
            .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterArticleAssemblyAssignmentsEdit )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( hasRight: boolean ) => {
                this.userHasEditRight = hasRight;
            } );

        this.initializeTableFeatures();

        // Two entry points:
        //  - of( true ) - first component load
        //  - this.pblService.summary$ - prev/next navigation
        merge( of( true ), this.clusterService.summary$ )
            .pipe(
                loadWithProgressIndicator( () => this.clustersApi.clustersGetNonTradeItems( this.clusterService.clusterId ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: ViewModelListClusterNonTradeItem ) => {
                this.setCollectionViewAndItemIds( result.data );
            } );
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    addNonTradeItem( item: NonTradeSuggestItem ): void {
        const command: AddArticlesAndAssembliesToClusters = {
            clusterIds: [ this.clusterService.clusterId ],
        };

        if ( item.type === NonTradeSuggestItem.TypeEnum.Article ) {
            command.articleIds = [ item.id ];
        }
        else {
            command.assemblyIds = [ item.id ];
        }

        command.reason = this.form.get( 'reason' ).value;

        this.isLoading =
            this.clustersApi
                .clustersAddArticlesAndAssembliesToClusters( command )
                .pipe(
                    switchMap( () => this.clustersApi.clustersGetNonTradeItems( this.clusterService.clusterId ) ),
                    takeUntil( this.isDestroyed ),
                )
                .subscribe( ( result: ViewModelListClusterNonTradeItem ) => {
                    this.setCollectionViewAndItemIds( result.data );
                    this.form.get( 'reason' ).setValue( '' );
                    this.clusterService.getAndPublishSummary();
                } );
    }

    removeItem( reason: string, item: ClusterNonTradeItemCollectionViewItem ): void {

        const command: RemoveArticlesAndAssembliesFromClusters = {
            clusterIds: [ this.clusterService.clusterId ],
        };

        if ( item.nonTradeItem.type === ClusterNonTradeItem.TypeEnum.Article ) {
            command.articleIds = [ item.nonTradeItem.nonTradeItemId ];
        }
        else {
            command.assemblyIds = [ item.nonTradeItem.nonTradeItemId ];
        }

        command.reason = reason;

        this.isLoading =
            this.clustersApi
                .clustersRemoveArticlesAndAssembliesFromClusters( command )
                .pipe(
                    switchMap( () => this.clustersApi.clustersGetNonTradeItems( this.clusterService.clusterId ) ),
                    takeUntil( this.isDestroyed ),
                )
                .subscribe( ( result: ViewModelListClusterNonTradeItem ) => {
                    this.setCollectionViewAndItemIds( result.data );
                    this.clusterService.getAndPublishSummary();
                } );
    }


    private setCollectionViewAndItemIds( items: ClusterNonTradeItem[] ): void {
        this.itemIds = [];

        this.collectionView
            .setSourceItems(
                items.map( ( nonTradeItem: ClusterNonTradeItem ) => {
                    this.itemIds.push( nonTradeItem.nonTradeItemId );

                    return new ClusterNonTradeItemCollectionViewItem( nonTradeItem );
                } ),
            )
            .refresh();
    }

    private initializeTableFeatures(): void {

        // filter function
        this.collectionView.setGlobalFilterFunction( ( item: ClusterNonTradeItemCollectionViewItem, filter: string ) => {

            if ( typeof filter === 'undefined' || filter === null ) {
                return true;
            }

            if ( this.standardFilters.contains( item.name, filter )
                || this.standardFilters.contains( item.number, filter )
                || this.standardFilters.contains( item.status, filter ) ) {
                return true;
            }

            return false;
        } );
    }
}
