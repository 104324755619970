import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';

import { NonTradeItemStoreService } from '../../../shared/non-trade-item-store.service';
import { NonTradeItemSelectionService } from '../../../shared/non-trade-item-selection.service';
import { NonTradeItemTileComponent } from '../non-trade-item-tile/non-trade-item-tile.component';
import { StorageService } from '../../../../../../core/storage.service';
import { CurrentUserService } from '../../../../../../core/current-user.service';


/**
 * Represents one row in the non-trade-item list, when LIST view used.
 */
@Component( {
    selector:    'rsp-non-trade-item-list-item',
    templateUrl: './non-trade-item-line.component.html',
    styleUrls:   [
        './non-trade-item-line.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class NonTradeItemLineComponent extends NonTradeItemTileComponent {


    constructor(
        router: Router,
        storageService: StorageService,
        currentUserService: CurrentUserService,
        nonTradeItemSelectionService: NonTradeItemSelectionService,
        nonTradeItemStoreService: NonTradeItemStoreService,
        changeDetectorRef: ChangeDetectorRef,
    ) {
        super( router, storageService, currentUserService, nonTradeItemSelectionService, nonTradeItemStoreService, changeDetectorRef );
    }
}
