import { Component, Input } from '@angular/core';

import { StoreInfo } from '../../lists/store/store-info.model';

/**
 * Displays current store informations - number of loaded and total items.
 *
 */
@Component( {
    selector:    'rsp-store-info',
    templateUrl: './store-info.component.html',
    styleUrls:   [
        './store-info.component.scss',
    ],
} )
export class StoreInfoComponent {

    /**
     * Object which contains data displayed in this component.
     */
    @Input()
    storeInfo: StoreInfo;
}
