import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { EntityTypeEnum } from '../../../../../shared/model/enum/entity-type.enum';
import { NonTradeListItem } from '../../../../../shared/model/nonTradeListItem';
import { NonTradeItemSelectionService } from '../non-trade-item-selection.service';
import { map } from 'rxjs/operators';

@Component( {
    selector:    'rsp-non-trade-item-selection-list',
    templateUrl: './non-trade-item-selection-list.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/simple-list.scss',
        './non-trade-item-selection-list.component.scss',
    ],
} )
export class NonTradeItemSelectionListComponent implements OnInit {
    /**
     * Optional input to filter shown items by type (assembly or article).
     */
    @Input() nonTradeItemType: NonTradeListItem.TypeEnum;

    @Input() onlyReadyToOrder: boolean = false;

    items: Observable<NonTradeListItem[]>;
    headline: string;
    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.NonTradeItem;

    constructor( private nonTradeItemSelectionService: NonTradeItemSelectionService, ) {
    }

    ngOnInit(): void {
        this.headline = 'Selected ';
        if ( typeof this.nonTradeItemType === 'number' ) {
            this.headline += ( this.nonTradeItemType === NonTradeListItem.TypeEnum.Article ) ? 'Articles' : 'Assemblies';
        }
        else {
            this.headline += 'Articles & Assemblies';
        }

        this.items = this.nonTradeItemSelectionService.selectedItems$
                         .pipe(
                             map( ( nonTradeItems: NonTradeListItem[] ) => {
                                 if ( typeof this.nonTradeItemType === 'number' ) {
                                     return nonTradeItems.filter( ( nonTradeItem: NonTradeListItem ) => {
                                         return (
                                             (this.nonTradeItemType === NonTradeListItem.TypeEnum.Article
                                                 && nonTradeItem.type === NonTradeListItem.TypeEnum.Article)
                                             ||
                                             (this.nonTradeItemType === NonTradeListItem.TypeEnum.Assembly
                                                 && nonTradeItem.type === NonTradeListItem.TypeEnum.Assembly)
                                         );
                                     } );
                                 }
                                 else {
                                     return nonTradeItems;
                                 }
                             } ),
                             map( ( nonTradeItems: NonTradeListItem[] ) => {
                                 if ( this.onlyReadyToOrder ) {
                                     return nonTradeItems.filter( ( nonTradeItem: NonTradeListItem ) => {
                                         return nonTradeItem.isReadyToOrder;
                                     } );
                                 }
                                 else {
                                     return nonTradeItems;
                                 }
                             } ),
                         );
    }

    removeItemFromSelection( item: NonTradeListItem ): void {
        this.nonTradeItemSelectionService.removeFromSelection( item );
    }
}
