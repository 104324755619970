<rsp-edit-experiences
    *ngIf="isEditMode"
    [experiences]="experiences"
    [myFormControl]="myFormControl"
>
</rsp-edit-experiences>


<ng-container *ngIf="!isEditMode && ( experiences == null || experiences?.length === 0 )">
    <rsp-not-available></rsp-not-available>
</ng-container>

<rsp-tags *ngIf="!isEditMode && experiences?.length" [tags]="getExperiencesAsTags()" [showEmptyText]="false">
</rsp-tags>
