/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { ArticleMaterial } from './articleMaterial';
import { ArticleRequiredFile } from './articleRequiredFile';
import { BrandLogoInfo } from './brandLogoInfo';
import { Capacity } from './capacity';
import { ConceptAndCluster } from './conceptAndCluster';
import { Dimensions } from './dimensions';
import { KeywordInfo } from './keywordInfo';
import { SPMGroupInfo } from './sPMGroupInfo';
import { SPMTypeInfo } from './sPMTypeInfo';
import { SubComponentListItem } from './subComponentListItem';


export interface ArticleDetail {
    id?: string;

    number?: string;

    status?: ArticleDetail.StatusEnum;

    legacyNumber?: string;

    name?: string;

    description?: string;

    category?: string;

    note?: string;

    packagingUnit?: number;

    customsTariffNumber?: string;

    assembledDimensions?: Dimensions;

    packedDimensions?: Dimensions;

    materialInfo?: string;

    materials?: Array<ArticleMaterial>;

    requiredFiles?: Array<ArticleRequiredFile>;

    appliedBrandLogo?: BrandLogoInfo;

    spmGroup?: SPMGroupInfo;

    spmType?: SPMTypeInfo;

    keywords?: Array<KeywordInfo>;

    needsCapacity?: boolean;

    capacities?: Array<Capacity>;

    subComponents?: Array<SubComponentListItem>;

    conceptsAndClusters?: Array<ConceptAndCluster>;

    engineeringPartnerName?: string;

}

export namespace ArticleDetail {
    'use strict';
    export enum StatusEnum {
        Unpublished,
        Development,
        ActiveInPreRollout,
        ActiveInRollout,
        ActiveOnDemand,
        Discontinue,
        Inactive,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

