export class TargetItem {
    id: string;
    number: string;
    name: string;
    previewImageId: string;
    link: Array<string>;

    isSelected: boolean;
    isDone: boolean      = false;
    isCancelled: boolean = false;

    get isFinished(): boolean {
        return this.isDone || this.isCancelled;
    }

    constructor( id: string,
                 itemNumber: string,
                 name: string,
                 previewImageId: string,
                 link: Array<string>,
                 isSelected: boolean = false ) {
        this.id             = id;
        this.number         = itemNumber;
        this.name           = name;
        this.previewImageId = previewImageId;
        this.link           = link;
        this.isSelected     = isSelected;
    }

}
