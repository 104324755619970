<img
    src="{{ calculatedUrl }}"
    srcset="{{ retinaUrl }} 2x"
    class="image {{ class }}"
    [class.rsp-image--is-aligned]="alignImg"
    alt="{{ alt }}"
    [attr.width]="width || null"
    [attr.height]="height || null"
    (load)="emitImageLoaded()"
/>
