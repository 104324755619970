<div>
    <span
        *ngFor="let storeFormat of storeFormats"
        rspKeyword
        [withClearButton]="true"
        (clearClick)="removeStoreFormat( storeFormat )"
    >
        {{ storeFormat.name }}
    </span>
</div>

<rsp-dropdown
    [placeholder]="'Select Store Format'"
    [defaultValue]="null"
    [withSuggester]="true"
    [originalItems]="storeFormatOptions"
    (filteredItems)="setFilteredStoreFormatOptions( $event )"
    (selected)="setStoreFormat( $event )"
>
    <rsp-dropdown-item *ngFor="let option of filteredStoreFormatOptions" [item]="option">
        {{ option.name }}
    </rsp-dropdown-item>
</rsp-dropdown>
