import { AfterContentInit, Component, Input, ContentChildren, QueryList, Renderer2 } from '@angular/core';
import { DropdownItemComponent } from './dropdown-item.component';

@Component( {
    selector: 'rsp-dropdown-group',
    template: `
                  <div class="dropdown--group">
                      <div class="dropdown--group-label">{{label}}</div>
                      <ng-content></ng-content>
                  </div>`,
    styleUrls: [
        './dropdown.scss',
    ],
} )
export class DropdownGroupComponent implements AfterContentInit {
    @Input() label: string;
    @ContentChildren( DropdownItemComponent ) items: QueryList<DropdownItemComponent>;

    constructor(
        private renderer: Renderer2,
    ) { }

    ngAfterContentInit(): void {
        this.items.forEach( ( item: DropdownItemComponent ) => {
            this.renderer.addClass( item.itemContainer.nativeElement, 'is-dropdown-item-grouped' );
        } );
    }

}
