import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute } from '@angular/router';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';

import { IndexGlobalsearchService } from '../../../shared/api';
import { ViewModelListGlobalSearchItem } from '../../../shared/model/viewModelListGlobalSearchItem';
import { CurrentUserService } from '../../current-user.service';
import { CurrentUserContext } from '../../../shared/model';
import { PopupService } from '../../overlay/popup/popup.service';
import { GlobalSearchItem } from '../../../shared/model/globalSearchItem';
import { FacetService } from '../../../shared/ui/filters/facet/facet.service';
import { ButtonComponent } from '../../../shared/ui/button/button.component';
import { GlobalSearchCategory, GlobalSearchService } from './global-search.service';
import { CustomValidators } from '../../../shared/forms/validation/custom-validators';

import { sortBy } from 'lodash';


@Component( {
    selector:    'rsp-global-search',
    templateUrl: './global-search.component.html',
    styleUrls:   [ './global-search.component.scss' ],
    providers: [ GlobalSearchService ],
} )
export class GlobalSearchComponent implements OnInit {
    @ViewChild( 'searchResultTemplate', { read: TemplateRef, static: true } ) template: TemplateRef<any>;
    @ViewChild( 'search', { static: true } ) searchElement: ElementRef;
    @ViewChild( 'popupContent' ) popupContent: ElementRef;
    @ViewChild( 'magnifier', { static: true } ) magnifierButton: ButtonComponent;

    formControl: UntypedFormControl = new UntypedFormControl( '', CustomValidators.stringNotEmpty );

    isLoading: Subscription;

    searchResult: GlobalSearchCategory[];

    categorySearchAvailable: boolean;

    private currentCategory: string;
    private categories: GlobalSearchCategory[] = [];
    private isPopUpVisible: boolean = false;

    constructor(
        private globalSearchService: GlobalSearchService,
        private currentUserService: CurrentUserService,
        private globalSearchApi: IndexGlobalsearchService,
        private facetService: FacetService,
        private popupService: PopupService,
        private activatedRoute: ActivatedRoute,
        private router: Router,
    ) {
        this.router
            .events
            .pipe( filter( ( event: any ) => event instanceof NavigationEnd ) )
            .subscribe( ( event: any ) => {
                this.setCurrentCategory( event.urlAfterRedirects );
                this.searchElement.nativeElement.blur();
            } );
    }

    ngOnInit(): void {
        // if router event was not fired, get current category from browser location
        if ( !this.currentCategory ) {
            this.setCurrentCategory( window.location.pathname );
        }

        this.initializeCategories();

        this.popupService
            .popupClosed$
            .subscribe( () => {
                this.isPopUpVisible = false;
            } );

        this.popupService
            .popupOpened$
            .subscribe( ( triggerElement: ElementRef ) => {
                this.isPopUpVisible = this.searchElement === triggerElement;
            } );

        this.popupService
            .clickedOutside$
            .subscribe( ( value: { target: HTMLElement | any, triggerElement: ElementRef } ) => {
                if (
                    !this.searchElement.nativeElement.contains( value.target )
                    && this.popupContent && !this.popupContent.nativeElement.contains( value.target )
                    && !this.magnifierButton.hostElement.nativeElement.contains( value.target )
                ) {
                    this.popupService.closeOverlay();
                }
            } );

        this.formControl
            .valueChanges
            .pipe(
                debounceTime( 300 ),
                distinctUntilChanged(),
            )
            .subscribe( ( term: string ) => {
                if ( term.trim().length > 0 ) {
                    this.openPopup();
                    if ( this.isLoading ) {
                        this.isLoading.unsubscribe();
                    }

                    this.isLoading =
                        this.globalSearchApi
                            .globalSearch( { value: term } )
                            .subscribe( ( result: ViewModelListGlobalSearchItem ) => {
                                this.processResult( result.data );
                            } );
                }
                else if ( this.isPopUpVisible ) {
                    this.searchResult = null;
                    this.closePopup();
                }
            } );
    }

    triggerSearch( term: string ): void {
        if ( term.trim().length > 0 ) {
            const targetUrl: string = this.globalSearchService.getCategoryUrl( this.currentCategory );

            if ( targetUrl ) {
                this.navigateToCategory( term, targetUrl );
            }
        }
    }

    navigateTo( category: GlobalSearchCategory ): void {
        const navigationExtras: NavigationExtras = { queryParams: {} };

        // if current url equals selected category, merge the queryParams to preserve sorting, filters, etc...
        if ( category.url === this.currentCategory ) {
            Object.assign( navigationExtras.queryParams, this.activatedRoute.snapshot.queryParams );
        }

        // set facet query params to get particular type of category (e.g. 'Article' and not 'Article & Assemblies')
        if ( category.facetItem ) {
            navigationExtras.queryParams.facets = JSON.stringify( [ this.facetService.getFacetFilterForFacet( category.facetItem ) ] );
        }

        navigationExtras.queryParams.qs = this.formControl.value;

        this.router.navigate( [ category.url ], navigationExtras );

        this.closePopup();
    }

    onKeydown( event: KeyboardEvent ): void {
        // close popup on "tab", "shift & tab" & 'escape'
        if ( event.key === 'Tab' || event.key === 'Escape' ) {
            this.closePopup();
        }
    }

    onFocus(): void {
        if ( this.searchResult ) {
            this.openPopup();
        }
        else if ( this.isPopUpVisible ) {
            this.closePopup();
        }
    }

    clickMagnifier( term: string ): void {
        if ( this.categorySearchAvailable && term !== this.activatedRoute.snapshot.queryParams.qs ) {
            this.triggerSearch( term );
        }
        else if ( this.searchResult && !this.isPopUpVisible ) {
            this.openPopup();
        }
        else {
            this.closePopup();
        }
    }

    private closePopup(): void {
        this.popupService.closeOverlay();
    }

    private openPopup(): void {
        if ( !this.isPopUpVisible ) {
            this.popupService.openOverlay( this.template, this.searchElement, 0, false );
        }
    }

    private navigateToCategory( term: string, url: string ): void {
        const navigationExtras: NavigationExtras = { queryParams: {} };

        Object.assign( navigationExtras.queryParams, this.activatedRoute.snapshot.queryParams );

        if ( term ) {
            navigationExtras.queryParams.qs = term;
        }
        else {
            delete navigationExtras.queryParams.qs;
        }

        this.router.navigate( [ url ], navigationExtras );
    }

    private processResult( items: GlobalSearchItem[] ): void {
        this.searchResult = items.map( ( item: GlobalSearchItem ) => {
            const category: GlobalSearchCategory = this.categories.find( ( found: GlobalSearchCategory ) => found.type === item.type );

            return {
                count:     item.count,
                type:      item.type,
                url:       category.url,
                facetItem: category.facetItem,
                label:     this.globalSearchService.getLabelForItem( item ),
            };
        } );

        this.searchResult = sortBy( this.searchResult, 'count' ).reverse();

        this.openPopup();
    }

    private setCurrentCategory( url: string ): void {
        const slashIndex: number = url.substr( 1 ).indexOf( '/' );

        if ( slashIndex === -1 && url.length > 1 ) {
            const paramIndex: number = url.indexOf( '?' );
            this.currentCategory     = paramIndex === -1 ? url : url.substr( 0, paramIndex );
        }
        else {
            this.currentCategory = url.substr( 0, slashIndex + 1 );
        }

        this.categorySearchAvailable = this.globalSearchService.isUrlSearchable( this.currentCategory );
    }

    private initializeCategories(): void {
        this.currentUserService
            .getCurrentUserAccessRightSet()
            .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                this.categories = this.globalSearchService.prepareCategories( accessRightSet );
            } );
    }
}
