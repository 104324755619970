import { Component, Input, } from '@angular/core';

// ToDo: Obsolete?
@Component( {
    selector:    'rsp-timeline',
    templateUrl: './timeline.component.html',
    styleUrls:   [
        './timeline.component.scss',
    ],
} )
export class TimelineComponent {
    @Input()
    data: any; // TODO: Type to be defined, and maybe rename
}
