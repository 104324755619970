import { Injectable } from '@angular/core';

import { AdvancedSearchFilterTypeOperator } from '../../../model/advancedSearchFilterTypeOperator';
import { AdvancedSearchFilterProperty } from '../../../model/advancedSearchFilterProperty';
import { EnumConverterService } from '../../../utils/enum/enum-converter.service';
import { AdvancedSearchFilterGroup as AdvancedSearchFilterQueryGroup } from '../../../model/advancedSearchFilterGroup';
import { AdvancedSearchFilterItem } from '../../../model/advancedSearchFilterItem';

export interface AdvancedSearchFilterField {
    name: string;
    type: AdvancedSearchFilterProperty.TypeEnum;
    operators: AdvancedSearchFilterOperator[];
    values: string[]; // for AdvancedSearchFilterProperty.TypeEnum.Enum
}

export interface AdvancedSearchFilterOperator {
    id: AdvancedSearchFilterTypeOperator.OperatorEnum;
    name: string;
}

export interface AdvancedSearchFilterGroup {
    groupOperator: AdvancedSearchFilterQueryGroup.GroupOperatorEnum;
    filters: AdvancedSearchFilter[];
}

export interface AdvancedSearchFilter extends AdvancedSearchFilterItem {
    type: AdvancedSearchFilterProperty.TypeEnum;
    operatorInfo: AdvancedSearchFilterOperator;
    operators: AdvancedSearchFilterOperator[];
}

@Injectable()
export class AdvancedSearchService {
    /**
     * Collects all operators that need a range instead of a value.
     */
    public static rangeOperators: AdvancedSearchFilterTypeOperator.OperatorEnum[] = [
        AdvancedSearchFilterTypeOperator.OperatorEnum.Between,
    ];

    getFilterGroups(
        filterProperties: AdvancedSearchFilterProperty[],
        filterTypeOperators: AdvancedSearchFilterTypeOperator[],
        activeFilterGroups: AdvancedSearchFilterQueryGroup[],
    ): AdvancedSearchFilterGroup[] {
        const possibleFilterFields: AdvancedSearchFilterField[] = this.getFilterFields( filterProperties, filterTypeOperators );

        const filterGroups: AdvancedSearchFilterGroup[] = activeFilterGroups.map( ( filterQueryGroup: AdvancedSearchFilterQueryGroup ) => {
            const filterGroup: AdvancedSearchFilterGroup = {
                groupOperator: filterQueryGroup.groupOperator,
                filters:       filterQueryGroup.filters.map( ( filterItem: AdvancedSearchFilterItem ) => {
                    const filterFieldForThisFilterItem: AdvancedSearchFilterField = possibleFilterFields.find(
                        ( filterField: AdvancedSearchFilterField ) => filterField.name === filterItem.name,
                    );

                    const filter: AdvancedSearchFilter = {
                        name:         filterItem.name,
                        operator:     filterItem.operator,
                        operatorInfo: this.convertOperatorEnumValueToAdvancedSearchFilterOperator( filterItem.operator ),
                        operators:    filterFieldForThisFilterItem.operators,
                        type:         filterFieldForThisFilterItem.type,
                        value:        filterItem.value,
                        range:        filterItem.range,
                    };
                    return filter;
                } ),
            };

            return filterGroup;
        } );

        return filterGroups;
    }

    getFilterFields(
        filterProperties: AdvancedSearchFilterProperty[],
        filterTypeOperators: AdvancedSearchFilterTypeOperator[],
    ): AdvancedSearchFilterField[] {
        return filterProperties
            .map( ( filterProperty: AdvancedSearchFilterProperty ) => {
                const operators: AdvancedSearchFilterOperator[] = [];

                filterTypeOperators.forEach( ( typeOperator: AdvancedSearchFilterTypeOperator ) => {
                    if ( typeOperator.type === filterProperty.type ) {
                        operators.push( this.convertOperatorEnumValueToAdvancedSearchFilterOperator( typeOperator.operator ) );
                    }
                } );

                return {
                    name:      filterProperty.name,
                    type:      filterProperty.type,
                    operators: operators,
                    values:    filterProperty.type === AdvancedSearchFilterProperty.TypeEnum.Enum ? filterProperty.values : null,
                };
            } )
            .sort( ( aFilterField: AdvancedSearchFilterField, bFilterField: AdvancedSearchFilterField ) => {
                if ( aFilterField.name < bFilterField.name ) {
                    return -1;
                }

                if ( aFilterField.name > bFilterField.name ) {
                    return 1;
                }

                return 0;
            } );
    }

    convertOperatorEnumValueToAdvancedSearchFilterOperator( operatorEnumValue: AdvancedSearchFilterTypeOperator.OperatorEnum ): AdvancedSearchFilterOperator {
        return {
            id:   operatorEnumValue,
            name: EnumConverterService.getHumanReadableEnumValue( AdvancedSearchFilterTypeOperator.OperatorEnum, operatorEnumValue ),
        };
    }

    getDefaultFilter( filterFields: AdvancedSearchFilterField[] ): AdvancedSearchFilter {
        const defaultFilter: AdvancedSearchFilter = {
            name:         filterFields[ 0 ].name,
            type:         filterFields[ 0 ].type,
            operators:    filterFields[ 0 ].operators,
            operatorInfo: filterFields[ 0 ].operators[ 0 ],
            operator:     filterFields[ 0 ].operators[ 0 ].id,
        };

        if ( this.isRangeOperator( filterFields[ 0 ].operators[ 0 ].id ) ) {
            defaultFilter.range = {
                from:  null,
                until: null,
            };
        }
        else {
            defaultFilter.value = null;
        }

        return defaultFilter;
    }

    mapAdvancedSearchFilterGroupsToQueryGroups( filterGroups: AdvancedSearchFilterGroup[] ): AdvancedSearchFilterQueryGroup[] {
        return filterGroups.map( ( filterGroup: AdvancedSearchFilterGroup ) => {
            const queryGroup: AdvancedSearchFilterQueryGroup = {
                groupOperator: filterGroup.groupOperator,
                filters:       filterGroup.filters
                                          .filter( ( filter: AdvancedSearchFilter ) => {
                                              // do not search empty values (will probably end in a server error)
                                              return filter.value || this.isRangeOperator( filter.operator ) && filter.range;
                                          } )
                                          .map( ( filter: AdvancedSearchFilter ) => {
                    const filterItem: AdvancedSearchFilterItem = {
                        name:     filter.name,
                        operator: filter.operator,
                    };

                    if ( this.isRangeOperator( filter.operator ) ) {
                        filterItem.range = filter.range;
                    }
                    else {
                        filterItem.value = filter.value;
                    }

                    return filterItem;
                } ),
            };

            return queryGroup;
        } );
    }

    isRangeOperator( operator: AdvancedSearchFilterTypeOperator.OperatorEnum ): boolean {
        return AdvancedSearchService.rangeOperators.some( ( rangeOperator: AdvancedSearchFilterTypeOperator.OperatorEnum ) => rangeOperator === operator );
    }

    areAdvancedSearchFiltersSet( filterGroups: AdvancedSearchFilterQueryGroup[] ): boolean {
        let areFiltersSet: boolean = false;
        if (
            Array.isArray( filterGroups ) &&
            filterGroups.some( ( filterGroup: AdvancedSearchFilterQueryGroup ) => {
                if ( !Array.isArray( filterGroup.filters ) ) {
                    return false;
                }

                return filterGroup.filters.some( ( filter: AdvancedSearchFilterItem ) => {
                    return ( filter.value || ( filter.range && ( filter.range.from || filter.range.until ) ) ? true : false );
                } );
            } )
        ) {
            areFiltersSet = true;
        }

        return areFiltersSet;
    }
}
