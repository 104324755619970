import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

import { backdropAnimations } from './backdrop.animations';
import { BackdropService } from './backdrop.service';


/**
 * Backdrop which covers the full browser viewport.
 * Use @see BackdropService to control this component.
 */
@Component( {
    selector:    'rsp-backdrop',
    templateUrl: './backdrop.component.html',
    styleUrls:   [
        './backdrop.component.scss',
    ],
    animations:  backdropAnimations,
} )
export class BackdropComponent {

    @Input()
    isVisible: Observable<boolean>;

    constructor( private backdropService: BackdropService ) {
        this.isVisible = this.backdropService.isBackdropVisible$;
    }

    onBackdropClick(): void {
        this.backdropService.emitBackdropClickedEvent();
    }
}
