/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { FileContainerItem } from './fileContainerItem';
import { PrepressProduction } from './prepressProduction';


export interface ArtworkItem {
    id?: string;

    image?: FileContainerItem;

    catchPhrase?: string;

    hasTranslations?: boolean;

    prepressProductions?: Array<PrepressProduction>;

    masterPrepressProductionStatus?: ArtworkItem.MasterPrepressProductionStatusEnum;

}

export namespace ArtworkItem {
    'use strict';
    export enum MasterPrepressProductionStatusEnum {
        Planned,
        Requested,
        InProgress,
        InApproval,
        Ready,
        Inactive,
        Rejected,
    }
    export namespace MasterPrepressProductionStatusEnum {
        export function toString( enumValue: MasterPrepressProductionStatusEnum ): string {
            return MasterPrepressProductionStatusEnum[ enumValue ];
        }
    }
}

