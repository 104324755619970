import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';

import { StorageService } from '../../../../core/storage.service';
import { STORAGE_KEYS } from '../../../../core/storage-keys';
import { SelectableItemComponent } from '../../lists/tile/selectable-item.component';
import { UserSelectionService } from '../../../../features/user-management/users/shared/user-selection.service';
import { UserStoreService } from '../../../../features/user-management/users/shared/user-store.service';
import { EntityTypeEnum } from '../../../model/enum/entity-type.enum';
import { UserListItem } from '../../../model/index';


@Component( {
    selector:    'rsp-user-tile',
    templateUrl: './user-tile.component.html',
    styleUrls:   [ './user-tile.component.scss' ],
} )
export class UserTileComponent extends SelectableItemComponent<UserListItem> implements OnInit {
    @Input() isLink: boolean = true;

    isHover: boolean = false;
    userType: string;

    readonly statusEnumType: EntityTypeEnum = EntityTypeEnum.User;
    readonly userTypeEnum: any = UserListItem.TypeEnum;

    constructor(
        private storageService: StorageService,
        userSelectionService: UserSelectionService,
        userStoreService: UserStoreService,
        changeDetectorRef: ChangeDetectorRef,
    ) {
        super( userSelectionService, userStoreService, changeDetectorRef );
    }

    ngOnInit(): void {
        if ( !this.item ) {
            throw new Error( 'no item given' );
        }

        this.item.type === this.userTypeEnum.Internal ? this.userType = 'INTERNAL' : this.userType = 'EXTERNAL';
        super.ngOnInit();
    }

    @HostListener( 'mouseover' )
    onMouseOver(): void {
        this.isHover = true;
    }

    @HostListener( 'mouseleave' )
    onMouseLeave(): void {
        this.isHover = false;
    }

    setIndex(): void {
        this.storageService.set<string>( STORAGE_KEYS.nonTradeItems.list.scrollToId, this.item.id );
    }

}
