import { Component } from '@angular/core';


/**
 * For the content to be vertically centered, the enclosing component must have host-styling display: flex and flex: 1.
 */
@Component( {
    selector:    'rsp-no-content',
    templateUrl: './no-content.component.html',
    styleUrls:   [ './no-content.component.scss', ],
} )
export class NoContentComponent {

}
