import { FileContainerWithReference } from '../../../model';

export class FileContainerWithReferenceCollectionViewItem {

    file: FileContainerWithReference;

    get name(): string {
        return this.file.name;
    }

    get fileKind(): string {
        return this.file.fileKind.name;
    }

    get uploadedOn(): Date {
        return this.file.uploadedOn;
    }

    get reference(): string {
        return this.file.referencedObject.displayValue;
    }

    constructor( fileContainerWithReference: FileContainerWithReference ) {
        this.file = fileContainerWithReference;
    }

}
