<div #container
     class="dropdown-button"
>
    <button
        #button
        [disabled]="disabled"
        (click)="togglePopup()"
        (mouseup)="onMouseUp()"
        class="dropdown-button--main-button{{ getClassSuffix() }}"
        [class.is-expanded]="isExpanded"
        (keydown.arrowDown)="selectNextItem( $event )"
        (keydown.arrowUp)="selectPreviousItem( $event )"
    >
        {{ label }}
    </button>
</div>

<!-- This template containing the item list will be put to DOM by the <rsp-popup> component -->
<ng-template #dropdownListTemplate>
    <div class="dropdown-button--list{{ getClassSuffix() }}"
         [style.min-width.px]="popupWidth"

         (keydown.arrowDown)="selectNextItem( $event )"
         (keydown.arrowUp)="selectPreviousItem( $event )"
    >
        <ng-content></ng-content>
    </div>
</ng-template>
