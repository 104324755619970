import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, Input, Output, QueryList, ViewChildren, } from '@angular/core';

import { WindowEventService } from '../../../../../core/window-event.service';
import { FacetValue, FacetFilterItem } from '../../../../model';
import { FacetService,                                                                 } from '../facet.service';
import { KeywordDirective } from '../../../keywords/keyword.directive';
import { facetAnimations } from '../facet.animations';
import { FacetItem } from '../../../../model/facetItem';
import { AdvancedSearchFilterGroup } from '../../../../model/advancedSearchFilterGroup';
import { AdvancedSearchService } from '../../advanced-search/advanced-search.service';

// TODO: Rename this component to <rsp-active-filters>. The current name is outdated.
/**
 * Provides a special facet which shows all active filter values from all the other facets.
 *
 * ```html
 * <rsp-facet-type-chosen-values
 *     facets="arrayOfFacets"
 *     advancedSearchFilterGroups="arrayOfAdvancedSearchFilterGroups"
 *     (valueChanged)="updateFacetFilters( allFacetFilters )"
 *     (clearAdvancedSearch)="removeAdvancedSearchFilters()"
 * ></rsp-facet-type-chosen-values>
 */
@Component( {
    selector:    'rsp-facet-type-chosen-values',
    templateUrl: './facet-type-chosen-values.component.html',
    styleUrls:   [
        './facet-type-chosen-values.component.scss',
    ],
    animations: facetAnimations,
} )
export class FacetTypeChosenValuesComponent implements AfterViewInit {

    @Input()
    set facets( facets: FacetItem[] | null ) {
        this.setFacets( facets );
    }
    get facets(): FacetItem[] | null {
        return this._facets;
    }

    @Input()
    set advancedSearchFilterGroups( filterGroups: AdvancedSearchFilterGroup[] ) {
        this.setAdvancedSearchFilterGroups( filterGroups );
    }
    get advancedSearchFilterGroups(): AdvancedSearchFilterGroup[] {
        return this._advancedSearchFilterGroups;
    }

    @Input() usedQuickSearchTerm: string;

    @Output() valueChanged: EventEmitter<FacetFilterItem[]> = new EventEmitter<FacetFilterItem[]>();
    @Output() clearAdvancedSearch: EventEmitter<void>       = new EventEmitter<void>();
    @Output() clearQuickSearch: EventEmitter<void>       = new EventEmitter<void>();

    @ViewChildren( KeywordDirective, { read: ElementRef } ) keywords: QueryList<ElementRef>;
    @HostBinding( 'class.has-keywords' ) hasKeywords: boolean = false;
    areSomeKeywordsHidden: boolean = false;
    isExpanded: boolean = false;
    areAdvancedSearchFiltersSet: boolean = false;

    set numberOfKeywords( numberOfKeywords: number ) {
        this._numberOfKeywords = numberOfKeywords;
        this.hasKeywords  = numberOfKeywords > 0;
    }
    get numberOfKeywords(): number {
        return this._numberOfKeywords;
    }

    private _facets: FacetItem[] | null;
    private _advancedSearchFilterGroups: AdvancedSearchFilterGroup[];
    private _numberOfKeywords: number = 0;

    private areSubscriptionsInitialized: boolean = false;

    constructor(
        private facetService: FacetService,
        private advancedSearchService: AdvancedSearchService,
        private windowEventService: WindowEventService,
    ) {
    }

    ngAfterViewInit(): void {
        if ( this.keywords && !this.areSubscriptionsInitialized ) {
            this.windowEventService.resized$.subscribe( () => {
                this.calculateAreSomeKeywordsHidden();
            } );

            this.keywords.changes.subscribe( () => {
                // `setTimeout` to not clash with angular change detection (aka prevent "Expression has changed after it was checked." error).
                window.setTimeout( () => { this.numberOfKeywords = this.keywords.length; } );

                this.calculateAreSomeKeywordsHidden();
            } );

            this.areSubscriptionsInitialized = true;
        }
    }

    /**
     * Removes a certain FacetValue from a given Facet and emits the change in the valueChanged event.
     */
    removeFacetValue(
        facetValueToRemove:     FacetValue,
        facetToRemoveValueFrom: FacetItem,
    ): void {
        // update internal state
        switch ( facetToRemoveValueFrom.type ) {
            case FacetItem.TypeEnum.Singleselect:
                this.removeFacetValueFromSingleselectFacet( facetToRemoveValueFrom );
                break;

            case FacetItem.TypeEnum.Multiselect:
                this.findFacetAndRemoveFacetValue( facetToRemoveValueFrom, facetValueToRemove );
                break;

            // No need for FacetItem.TypeEnum.MultiselectHierarchical, because we will only see (sub-)facets here that are from Type Multiselect.

            default:
                throw new Error( `FacetTypeChosenValuesComponent: removing values from facet type '${facetToRemoveValueFrom.type}' isn't implemented yet.` );
        }

        // emit complete array of all facetFilters
        const facetFilters: FacetFilterItem[] = [];
        this.facets.forEach( ( facet: FacetItem ) => {
            const facetFilter: FacetFilterItem | null = this.facetService.getFacetFilterForFacet( facet );
            if ( facetFilter ) {
                facetFilters.push( facetFilter );
            }
        } );

        this.valueChanged.emit( facetFilters );

        // if we are expanded, the removal of a facetValue may lead to no need for expansion at all, so we check if there are some keywords hidden and if not we
        // can set this.isExpanded to false.
        this.calculateAreSomeKeywordsHidden().then( ( areSomeKeywordsHidden: boolean ) => {
            this.areSomeKeywordsHidden = areSomeKeywordsHidden;
            if ( !this.areSomeKeywordsHidden ) {
                this.isExpanded = false;
            }
        } );
    }

    removeAdvancedSearchFilters(): void {
        this.clearAdvancedSearch.emit();
    }

    removeQuickSearch(): void {
        this.clearQuickSearch.emit();
    }

    toggleExpand(): void {
        this.isExpanded = !this.isExpanded;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------
    private setFacets( facets: FacetItem[] | null ): void {
        // deep copy incoming facets, because we will alter the structure
        facets = JSON.parse( JSON.stringify( facets ) );

        if ( Array.isArray( facets ) ) {
            facets = this.facetService.getActiveFacetsFromFacets( facets );
        }

        this._facets = facets;
    }

    private setAdvancedSearchFilterGroups( filterGroups: AdvancedSearchFilterGroup[] ): void {
        this._advancedSearchFilterGroups = filterGroups;
        this.areAdvancedSearchFiltersSet = this.advancedSearchService.areAdvancedSearchFiltersSet( filterGroups );
    }

    private removeFacetValueFromSingleselectFacet( facet: FacetItem ): void {
        // NOTE: This doesn't work for removal of nested facets, because all we have here is the current facet, but not the parent of it.
        //       This is okay for now, but may be a problem in the future.
        this.removeCompleteFacetFromFacetArray( this.facets, facet.name );
    }

    private findFacetAndRemoveFacetValue( facet: FacetItem, facetValue: FacetValue ): void {
        facet.values.splice(
            facet.values.findIndex( ( facetItem: FacetValue ) => facetItem === facetValue ),
            1,
        );

        if ( facet.values.length === 0 ) {
            // NOTE: This doesn't work for removal of nested facets, because all we have here is the current facet, but not the parent of it.
            //       This is okay for now, but may be a problem in the future.
            this.removeCompleteFacetFromFacetArray( this.facets, facet.name );
        }
    }

    private removeCompleteFacetFromFacetArray( facets: Array<FacetItem>, facetName: string ): void {
        facets.splice(
            facets.findIndex( ( facet: FacetItem ) => facet.name === facetName ),
            1,
        );
    }

    private calculateAreSomeKeywordsHidden(): Promise<boolean> {
        const promise: Promise<boolean> = new Promise<boolean>(
            ( resolve: ( resolveValue: boolean ) => void, reject: any ) => {
                // `setTimeout` to not clash with angular change detection (aka prevent "Expression has changed after it was checked." error).
                window.setTimeout(
                    () => {
                        const areSomeKeywordsHidden: boolean = this.keywords.length > 0 &&
                            this.keywords.last.nativeElement.offsetTop !== this.keywords.first.nativeElement.offsetTop;

                        if ( areSomeKeywordsHidden !== this.areSomeKeywordsHidden ) {
                            this.areSomeKeywordsHidden = areSomeKeywordsHidden;
                        }

                        resolve( areSomeKeywordsHidden );
                    },
                );
            },
        );

        return promise;
    }
}
