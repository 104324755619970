import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

import { NonTradeItemStatus } from '../../ui/non-trade-item/non-trade-item-status';
import { SPMGroupInfo } from '../../model/sPMGroupInfo';


/**
 * Custom Form Validators, defined to be used with FormControls.
 */
export class CustomValidators {

    static stringNotEmpty( control: UntypedFormControl ): ValidationErrors | null {
        return control.value === '' || typeof control.value === 'string' && control.value.trim() === ''
            ? { stringNotEmpty: true }
            : null;
    }

    static positiveNumber( control: UntypedFormControl ): ValidationErrors | null {
        if ( control.value !== null && ( isNaN( control.value ) || +control.value <= 0 ) ) {
            return { positiveNumber: true };
        }
        return null;
    }

    static emptyOrPositiveNumber( control: UntypedFormControl ): ValidationErrors | null {
        if ( control.value !== null && control.value !== '' && ( isNaN( control.value ) || +control.value <= 0 ) ) {
            return { positiveNumber: true };
        }
        return null;
    }

    static zeroOrPositiveNumber( control: UntypedFormControl ): ValidationErrors | null {
        if ( control.value !== null && ( isNaN( control.value ) || +control.value < 0 ) ) {
            return { zeroOrPositiveNumber: true };
        }
        return null;
    }

    static isInteger( control: UntypedFormControl ): ValidationErrors | null {
        if ( control.value && !/^(-)?\d+$/.test( control.value ) ) {
            return { isInteger: true };
        }
        return null;
    }

    static maxFloat( max: number ): ValidatorFn {
        return ( control: UntypedFormControl ): ValidationErrors | null => {
            const value: number = parseFloat( control.value );
            if ( control.value !== null && !isNaN( control.value ) && value > max ) {
                return { maxNumber: { maxValue: max } };
            }
            return null;
        };
    }

    static multipleOf( multiplier: number ): ValidatorFn {
        return ( control: UntypedFormControl ): ValidationErrors | null => {
            const value: number = parseInt( control.value, 10 );

            if ( !isNaN( value ) && value % multiplier !== 0 ) {
                return { multipleOf: { multiplier: multiplier } };
            }

            return null;
        };
    }

    // Validator.pattern could be used instead, but then the ValidationMessageService could not address the correct error message
    static isEmailAddress( control: UntypedFormControl ): ValidationErrors | null {
        // Regex source: http://w3c.github.io/html-reference/input.email.html
        if ( control.value && !/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test( control.value ) ) {
            return { isEmailAddress: true };
        }
        return null;
    }

    static isValidFilename( control: UntypedFormControl ): ValidationErrors | null {
        if ( control.value && !/^([a-zA-Z0-9\s\._-]+)$/.test( control.value ) ) {
            return { isValidFilename: true };
        }
        return null;
    }

    static isNonTradeItemReadyToOrder( control: UntypedFormControl ): ValidationErrors | null {
        if ( NonTradeItemStatus.isNonTradeItemReadyToOrder( control.value ) ) {
            return null;
        }
        else {
            return { isNonTradeItemReadyToOrder: true, };
        }
    }

    static isNonTradeItemReadyToForecast( spmGroup: SPMGroupInfo | SPMGroupInfo[] ): ValidatorFn {
        return ( control: UntypedFormControl ): ValidationErrors | null => {
            if ( NonTradeItemStatus.isNonTradeItemReadyToForecast( control.value, spmGroup ) ) {
                return null;
            }
            else {
                return { isNonTradeItemReadyToForecast: true, };
            }
        };
    }

    static containsNonEmptyArray( control: UntypedFormControl ): ValidationErrors | null {
        if ( Array.isArray( control.value ) && control.value.length > 0 ) {
            return null;
        }
        else {
            return { containsNonEmptyArray: true };
        }
    }

    static isDateRangeValid( fromControlName: string = 'from', untilControlName: string = 'until' ): ValidatorFn {
        return ( formGroup: UntypedFormGroup ): ValidationErrors | null  => {
            let isValid: boolean          = true;
            const fromValue: Date | null  = formGroup.get ? formGroup.get( fromControlName ).value  : null;
            const untilValue: Date | null = formGroup.get ? formGroup.get( untilControlName ).value : null;

            if ( fromValue instanceof Date && untilValue instanceof Date && fromValue.getTime() >= untilValue.getTime() ) {
                isValid = false;
            }

            return isValid ? null : { isDateRangeValid: true };
        };
    }
}
