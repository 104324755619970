import { Component, Input, OnInit, } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';

import { DisplayEditBaseComponent } from '../../../../../../shared/forms/controls/shared/display-edit-base.component';
import { ClusterDetail } from '../../../../../../shared/model/clusterDetail';
import { ValidationMessagesService } from '../../../../../../shared/forms/validation/validation-messages.service';
import { DistributionChannelListItem } from '../../../../../../shared/model/distributionChannelListItem';
import { MessageType } from '../../../../../../shared/ui/messages/messages.component';
import { OptionItem } from '../../../../../../shared/model/optionItem';
import { DistributionChannelValidator } from './distribution-channel-required.validator';

interface StoreFormatInfo {
    id?: string;

    description?: string;

}

@Component( {
    selector:    'rsp-display-edit-distribution-channels',
    templateUrl: './display-edit-distribution-channels.component.html',
    styleUrls:   [
        './display-edit-distribution-channels.component.scss',
    ],
} )
export class DisplayEditDistributionChannelsComponent extends DisplayEditBaseComponent implements OnInit {
    @Input() clusterDetails: ClusterDetail;

    messageTypeError: MessageType = MessageType.Error;

    constructor(
        validationMessageService: ValidationMessagesService,
    ) {
        super( validationMessageService );
    }

    static buildFormGroup( clusterDetail: ClusterDetail, distributionChannels: Array<DistributionChannelListItem> ): UntypedFormGroup {
        const ownRetailId: string = distributionChannels.find(( item: DistributionChannelListItem ) => item.name === 'Own Retail' ).id;
        const franchiseId: string = distributionChannels.find(( item: DistributionChannelListItem ) => item.name === 'Franchise' ).id;
        const wholesaleId: string = distributionChannels.find(( item: DistributionChannelListItem ) => item.name === 'Wholesale' ).id;

        const hasOwnRetail: boolean =
                  clusterDetail.distributionChannels.findIndex( ( item: DistributionChannelListItem ) => item.id === ownRetailId ) !== -1;
        const hasFranchise: boolean =
                  clusterDetail.distributionChannels.findIndex( ( item: DistributionChannelListItem ) => item.id === franchiseId ) !== -1;
        const hasWholesale: boolean =
                  clusterDetail.distributionChannels.findIndex( ( item: DistributionChannelListItem ) => item.id === wholesaleId ) !== -1;

        return new UntypedFormGroup(
            {
                ownRetail: new UntypedFormGroup( {
                    id:                new UntypedFormControl( ownRetailId),
                    isActive:          new UntypedFormControl( hasOwnRetail ),
                    storeFormatIds:    new UntypedFormControl( clusterDetail.distributionChannelStoreFormats.map( ( item: StoreFormatInfo ) => item.id ) ),
                    storeSubFormatIds: new UntypedFormControl( clusterDetail.storeSubFormats.map( ( item: OptionItem ) => item.id ) ),
                } ),
                franchise: new UntypedFormGroup( {
                    id:            new UntypedFormControl( franchiseId ),
                    isActive:      new UntypedFormControl( hasFranchise ),
                } ),
                wholesale: new UntypedFormGroup( {
                    id:            new UntypedFormControl( wholesaleId ),
                    isActive:      new UntypedFormControl( hasWholesale ),
                } ),
            },
            DistributionChannelValidator.distributionChannelRequiredValidator,
        );
    }

    ngOnInit(): void {
        if ( this.isEditMode ) {
            this.myFormGroup
                .valueChanges
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.updateValidationMessages( this.myFormGroup );
                } );
        }
    }
}
