import { Component, ViewChild, ViewContainerRef } from '@angular/core';

@Component( {
    selector: 'rsp-loading',
    template: `<div class="loading" [style.width.px]="width">
        <rsp-spinner *ngIf="!hideSpinner"></rsp-spinner>
        <div #target></div>
    </div>`,
    styleUrls: [
        './loading.component.scss',
    ],
} )
export class LoadingComponent {
    @ViewChild( 'target', { read: ViewContainerRef, static: true } ) templateTarget: ViewContainerRef;

    width: number;

    hideSpinner: boolean = false;
}
