import { Component, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { ClusterSummary, } from '../../../../shared/model/clusterSummary';
import { BreadcrumbService, } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb.service';
import { BreadcrumbPart, } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb-part';
import { Tab, } from '../../../../shared/ui/navigation/vertical-tabs/tab';
import { ClusterService, } from './cluster.service';
import { EntityTypeEnum } from '../../../../shared/model/enum/entity-type.enum';
import { ConceptOrClusterItem } from '../../../../shared/model/conceptOrClusterItem';
import { ConceptClusterPrevNextNavigationService } from '../shared/concept-cluster-prev-next-navigation/concept-cluster-prev-next-navigation.service';
import { QueryParamService } from '../../../../shared/utils/url/query-param.service';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';


@Component( {
    selector:    'rsp-cluster-details-page',
    providers:   [ BreadcrumbService, ],
    templateUrl: './cluster-details-page.component.html',
    styleUrls:   [
        './cluster-details-page.component.scss',
    ],
} )
export class ClusterDetailsPageComponent implements OnInit, OnDestroy {

    clusterSummary: ClusterSummary;
    clusterTileData: ConceptOrClusterItem;

    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.Cluster;

    tabs: Array<Tab> = [];


    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private clusterService: ClusterService,
        private toolbarService: SecondaryToolbarService,
        private prevNextNavigationService: ConceptClusterPrevNextNavigationService,
        private queryParamService: QueryParamService,
    ) {
        // configure toolbar
        // NOTE: previously it was in ngOnInit, but it throws a ExpressionChangedAfterItHasBeenCheckedError exception.
        // We are not sure why it occurs ... maybe something with named outlet (where toolbar is placed) and change detection.
        // This issue occurs in angular > 4.2.5.
        // https://github.com/angular/angular/issues/17572
        this.toolbarService.hideViewSwitcher();
        this.toolbarService.hideStoreInfo();
        this.toolbarService.hideSortBy();
        this.toolbarService.showPrevNextNavigation();

        this.configureBreadcrumb();
    }


    ngOnInit(): void {

        // Two entry points:
        //  - of( true ) - first component load
        //  - this.pblService.summary$ - prev/next navigation
        merge( this.activatedRoute.data, this.clusterService.summary$ )
            .pipe(
                map( ( data: { clusterSummary: ClusterSummary } | ClusterSummary ) => this.fetchSummary( data ) ),
                distinctUntilChanged(),  // prev/next navigation triggers both events. Take only one.
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( summary: ClusterSummary ) => {

                if ( summary ) {

                    this.clusterSummary = summary;

                    this.clusterTileData = {
                        type:    ConceptOrClusterItem.TypeEnum.Cluster,
                        cluster: this.clusterSummary.tile,
                    };

                    this.breadcrumbService.replacePart( { label: this.clusterSummary.tile.name }, 1 );

                    this.createTabs();

                    this.clusterService.setSummary( summary );  // updates tab content during prev/next navigation

                    this.prevNextNavigationService.setCurrentItem( this.clusterSummary.tile.id );
                }
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
        this.breadcrumbService.disableAutoRefreshing();
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private configureBreadcrumb(): void {
        const config: { [ key: string ]: BreadcrumbPart } = {};
        config[ 'details' ]                               = { label: 'Details', };
        config[ 'articles-and-assemblies' ]               = { label: 'Articles & Assemblies', };
        config[ 'files' ]                                 = { label: 'Files', };
        config[ 'history' ]                               = { label: 'History', };

        this.breadcrumbService.addPart( {
            label:      'Concepts & Clusters',
            link:       [ '/concepts-and-clusters', ],
            linkParams: this.queryParamService.getQueryParamsFor( '/concepts-and-clusters' ),
        } );

        this.breadcrumbService.addPart( { label: 'Cluster' } ); // will be replaced by summary data

        this.breadcrumbService.enableAutoRefreshing( config, 3 );
    }

    private createTabs(): void {

        if ( !this.clusterSummary ) {
            throw new Error( 'clusterSummary is null' );
        }

        this.tabs = [];

        // Details
        if ( Tab.shouldTabBeVisible( this.clusterSummary.detailNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'details', ],
                label: 'Details',
            } );
        }

        // Articles & Assemblies
        if ( Tab.shouldTabBeVisible( this.clusterSummary.articlesAndAssembliesNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'articles-and-assemblies', ],
                label: 'Articles & Assemblies',
                tag:   Tab.createTagFromNumber(
                    this.clusterSummary.articlesAndAssembliesNavigationItem ),
            } );
        }

        // Files
        if ( Tab.shouldTabBeVisible( this.clusterSummary.filesNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'files', ],
                label: 'Files',
                tag:   Tab.createTagFromNumber( this.clusterSummary.filesNavigationItem ),
            } );
        }

        // History
        // if ( Tab.shouldTabBeVisible( this.clusterSummaryData.historyNavigationItem ) ) {
        //     this.tabs.push( {
        //         link:  [ 'history', ],
        //         label: 'History',
        //     } );
        // }
    }


    private fetchSummary( data: { clusterSummary: ClusterSummary } | ClusterSummary ): ClusterSummary {

        if ( !data ) {
            return null;
        }

        if ( data.hasOwnProperty( 'clusterSummary' ) ) {

            // data is { clusterSummary: .... }
            return (<{ clusterSummary: ClusterSummary }> data).clusterSummary;

        }

        // data is ClusterSummary
        return data as ClusterSummary;
    }
}
