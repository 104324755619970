import { AnimationTriggerMetadata, animate, style, transition, trigger } from '@angular/animations';


export const backdropAnimations: Array<AnimationTriggerMetadata> = [

    // animation for overlay
    trigger( 'backdropTrigger', [
        transition(
            ':enter', [
                style( { opacity: '0' } ),
                animate( '400ms ease-in-out', style( { opacity: '*' } ), ),
            ] ),
        transition(
            ':leave', [
                animate( '400ms ease-in-out', style( { opacity: '0' } ), ),
            ] ),
    ] ),

];

