import { Component, Input, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { MasterDataRetailSegmentationsService } from '../../../../../../shared/api';
import { DisplayEditBaseComponent } from '../../../../../../shared/forms/controls/shared/display-edit-base.component';
import { ViewModelListListItem } from '../../../../../../shared/model/viewModelListListItem';
import { ValidationMessagesService } from '../../../../../../shared/forms/validation/validation-messages.service';
import { ListItem } from '../../../../../../shared/model/listItem';
import { OptionItem } from '../../../../../../shared/model/optionItem';

@Component( {
    selector:    'rsp-edit-retail-segmentations',
    templateUrl: './edit-retail-segmentations.component.html',
} )
export class EditRetailSegmentationsComponent extends DisplayEditBaseComponent implements OnInit {
    @Input() retailSegmentations: OptionItem[];

    retailSegmentationOptions: ListItem[]         = [];
    originalRetailSegmentationOptions: ListItem[] = [];

    filteredRetailSegmentationOptions: ListItem[];

    constructor(
        validationMessageService: ValidationMessagesService,
        private retailSegmentationsApi: MasterDataRetailSegmentationsService,
    ) {
        super( validationMessageService );
    }

    ngOnInit(): void {
        this.startEditMode();

        this.retailSegmentationsApi
            .retailSegmentationsGetList()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( result: ViewModelListListItem ) => {
                this.originalRetailSegmentationOptions = result.data;
                this.updateRetailSegmentationOptions();
            } );
    }


    setRetailSegmentation( retailSegmentation: ListItem ): void {
        const newRetailSegmentations: OptionItem[] = this.retailSegmentations;
        newRetailSegmentations.push( {
            name: retailSegmentation.name,
            id: retailSegmentation.id,
        } );
        this.retailSegmentations = newRetailSegmentations;
        this.updateRetailSegmentationOptions();
    }

    removeRetailSegmentation( retailSegmentation: OptionItem ): void {
        const index: number = this.retailSegmentations.findIndex( ( old: OptionItem ) => { return old.id === retailSegmentation.id; } );

        if ( index !== -1 ) {
            this.retailSegmentations.splice(index, 1);
        }

        this.updateRetailSegmentationOptions();
    }

    setFilteredRetailSegmentationOptions( items: ListItem[] ): void {
        this.filteredRetailSegmentationOptions = items;
    }

    private updateRetailSegmentationOptions(): void {
        this.retailSegmentationOptions =
            this.originalRetailSegmentationOptions.filter( ( item: ListItem ) => {
                return this.retailSegmentations.findIndex( ( current: OptionItem ) => { return item.id === current.id; } ) === -1;
            } );
        this.myFormControl.setValue( this.retailSegmentations.map( ( item: OptionItem ) => item.id ) );
    }
}
