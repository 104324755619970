<div *ngIf="keywords.length">
    <span *ngFor="let keyword of keywords"
          rspKeyword
          [withClearButton]="true"
          (clearClick)="removeKeyword( keyword )"
    >
        {{ keyword.name }}
    </span>
</div>
<rsp-keyword-suggester
    [itemFilter]="keywordIds"
    [groupName]="keywordsGroupName"
    [inputFieldHtmlId]="inputFieldHtmlId"
    (selected)="addKeyword( $event )"
></rsp-keyword-suggester>
