import { ServiceLevelItem } from '../../../../../../shared/ui/service-models-table/service-level-item';

/**
 * Represents one item in the Level dropdown.
 */
export interface LevelGroupOptionItem {
    id: number;
    name: string;
}


/**
 * Represents one row in the assignment table.
 */
export class ConceptServiceModelAssignmentItem {

    serviceModelId: string;

    name: string;

    assignedLevelGroupValue: number = null;

    get assignedLevelGroupName(): string {
        if ( this.assignedLevelGroupValue !== null ) {
            return 'Level ' + this.assignedLevelGroupValue;
        }

        return null;
    }

    serviceLevelGroupOptions: Array<LevelGroupOptionItem>;

    // NOTE: ServiceLevelItem is model defined in service-models-table. We need isLevelAvailableFor() here.
    serviceLevelItems: Array<ServiceLevelItem>;


    constructor(
        serviceModelId: string,
        name: string,
        assignedLevelGroupValue: number,
        serviceLevelGroupOptions: Array<LevelGroupOptionItem>,
        serviceLevelItems: Array<ServiceLevelItem>,
    ) {
        this.serviceModelId           = serviceModelId;
        this.name                     = name;
        this.serviceLevelGroupOptions = serviceLevelGroupOptions;
        this.assignedLevelGroupValue  = assignedLevelGroupValue;
        this.serviceLevelItems        = serviceLevelItems;
    }
}
