<div class="tab">
    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <rsp-no-content *ngIf="isLoading.closed && files.length === 0">
            This {{ itemTypeString }} does not hold any files.
        </rsp-no-content>

        <div [rspLoadingIndicator]="updatingFiles">
            <rsp-file-table
                [readOnly]="!userHasEditRight"
                [files]="files"
                [area]="fileKindArea"
                [referenceHeader]="referenceHeader"
                [referenceType]="referenceType"
                (fileAdded)="fileAdded( $event )"
                (fileRemoved)="fileRemoved( $event )"
                (editFileActive)="fileEditActive( $event )"
            ></rsp-file-table>
        </div>
    </div>
</div>
