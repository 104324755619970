import { Component, Input, OnInit, } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { UniqueHtmlIdService } from '../../../../../../core/unique-html-id.service';
import { SPMGroupInfo } from '../../../../../../shared/model/sPMGroupInfo';
import { SPMTypeInfo } from '../../../../../../shared/model/sPMTypeInfo';
import { KeywordInfo } from '../../../../../../shared/model/keywordInfo';
import { Tag } from '../../../../../../shared/ui/tags/tag';

@Component( {
    selector:    'rsp-card-keywords',
    templateUrl: './card-keywords.component.html',
    styleUrls:   [
        './card-keywords.component.scss',
    ],
} )
export class CardKeywordsComponent implements OnInit {
    @Input() spmGroup: SPMGroupInfo;

    @Input() spmType: SPMTypeInfo;

    @Input() keywords: Array<KeywordInfo>;

    @Input() isEditMode: boolean = false;
    @Input() isSPMGroupAndTypeEditable: boolean;
    @Input() myFormGroup: UntypedFormGroup;

    keywordFieldHtmlId: string;


    constructor( private uniqueHtmlIdService: UniqueHtmlIdService ) {}

    ngOnInit(): void {
        this.keywordFieldHtmlId = this.uniqueHtmlIdService.getUniqueHtmlId( 'keyword-field' );
    }

    getKeywordsAsTags(): Array<Tag> {

        const tags: Array<Tag> = [];

        this.addHighPrioTags( tags );
        this.addKeywordTags( tags );

        return tags;
    }

    getSPMGroupAndTypeAsTags(): Array<Tag> {
        const tags: Array<Tag> = [];

        this.addHighPrioTags( tags );

        return tags;
    }

    private addHighPrioTags( tags: Array<Tag> ): void {

        // SPM Group
        if ( this.spmGroup ) {

            const tagName: string = 'SPM Group: ' + this.spmGroup.name;
            tags.push( new Tag( tagName, true ) );
        }

        // SPM Type
        if ( this.spmType ) {

            const tagName: string = 'SPM Type: ' + this.spmType.name;
            tags.push( new Tag( tagName, true ) );
        }
    }

    private addKeywordTags( tags: Array<Tag> ): void {
        const keywords: Array<KeywordInfo> = this.keywords;

        if ( keywords ) {
            keywords.map(
                ( keyword: KeywordInfo ) => {
                    tags.push( new Tag( keyword.name ) );
                },
            );
        }
    }
}
