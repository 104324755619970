<ng-template #headline>
    <h2 class="standard-dialog--headline">Set Engineering Partner for selected Articles</h2>
</ng-template>

<ng-template #content>
    <div class="standard-dialog--content">
        <rsp-non-trade-item-selection-list></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #footer>
    <div
        *ngIf="hasAssembliesSelected"
        class="standard-dialog--button"
    >
        <rsp-supplementary-caption>
            Beware: Selected Assemblies will be ignored
        </rsp-supplementary-caption>
    </div>
    <div class="standard-dialog--button">
        <rsp-display-edit-text
            [value]=""
            [myFormGroup]="form"
            [isEditMode]="true"
            [name]="'engineeringPartner'"
        ></rsp-display-edit-text>
        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            (clicked)="doAction()"
            [disabled]="!form.valid || isProcessing"
            style="margin-left: 1em"
        >
            Apply
        </rsp-button>
    </div>
</ng-template>
