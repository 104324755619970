import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { ArticleManagementArticlesService } from '../../../../../../shared/api';
import { DialogService } from '../../../../../../core/overlay/dialog/dialog.service';
import { NonTradeItemService } from '../../../shared/non-trade-item.service';
import { NotificationService } from '../../../../../../core/overlay/notification/notification.service';
import { NonTradeListItem } from '../../../../../../shared/model';
import { StringConverterService } from '../../../../../../shared/utils/string/string-converter.service';

@Component( {
    selector:    'rsp-set-non-trade-item-status-action',
    templateUrl: './set-non-trade-item-status-dialog.component.html',
    styleUrls:   [
        '../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../shared/scss/05_module/simple-list.scss',
        './set-non-trade-item-status-dialog.component.scss',
    ],
} )
export class SetNonTradeItemStatusDialogComponent {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    form: UntypedFormGroup;
    itemId: string;
    statusId: string;
    statusName: string;

    isLoading: Subscription;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private articlesApi: ArticleManagementArticlesService,
        private dialogService: DialogService,
        private nonTradeItemService: NonTradeItemService,
        private notificationService: NotificationService,
    ) {
    }

    doAction(): void {
        this.isLoading =
            this.articlesApi
                .articlesUpdateStatus( this.itemId, this.statusId, this.form.get( 'reason' ).value )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
                    this.notificationService.success(
                        `Status was successfully changed to ${this.statusName}.`,
                    );
                    this.close();
                } );
    }

    open( itemId: string, status: string ): void {
        this.isLoading = null;
        this.itemId     = itemId;
        this.statusName = StringConverterService.splitCamelcaseWithSpace(
            NonTradeListItem.StatusEnum.toString( parseInt( status, 10 ) ));
        this.statusId =  status;

        if ( status === '5' || status === '6' ) {
            this.form = new UntypedFormGroup( {
                reason: new UntypedFormControl( null, Validators.required ),
            } );
        } else {
            this.form = new UntypedFormGroup( {
                reason: new UntypedFormControl(''),
            } );
        }

        this.dialogService.openDialog( {
            contentTemplate:  this.contentTemplate,
            headlineTemplate: this.headlineTemplate,
            footerTemplate:   this.footerTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }

}
