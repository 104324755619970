import { OverlayRef } from '@angular/cdk/overlay';
import { ElementRef } from '@angular/core';

import { TooltipConfig } from './tooltip.service';

/**
 * Tooltip reference (remote control).
 * Use it to control created tooltip.
 */
export class TooltipRef {

    constructor(
        public overlayRef: OverlayRef,
        public anchorElement: ElementRef,
        public config: TooltipConfig,
    ) {
    }

    /**
     * Closes visible tooltip.
     */
    close(): void {
        this.overlayRef.dispose();
    }
}
