import { Component, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { DateTimeDisplayFormat } from '../../../ui/date-time/model/dateTimeDisplayFormat.enum';


/**
 * Display edit for dates.
 * Not date times (the display case can show times, just in case you want to inform the user of a certain timezone implication).
 *
 * The associated FormControl is expected to contain a date object!
 *
 * Minimal example:
 * ```
 * <rsp-display-edit-date
 *     [isEditMode]="true"
 *     [myFormGroup]="form"
 *     [name]="'nameOfKeyInFormGroup'"
 * ></rsp-display-edit-date>
 * ```
 *
 * Full-fledged example:
 * ```
 * <rsp-display-edit-date
 *     [isEditMode]="true"
 *     [myFormGroup]="form"
 *     [name]="'nameOfKeyInFormGroup'"
 *     [disablePastDates]="true"
 *     [withClearButton]="true"
 *     [placeholder]="'Some placeholder text'"
 *     [format]="DateTimeDisplayFormat.HumanReadable"
 *     [withWeekday]="true"
 *     [withTime]="true"
 *     [withSeconds]="true"
 *     [withTimezone]="true"
 *     [withYear]="true"
 *     [minDate]="date"
 *     [inputFieldHtmlId]="'my-date-field'"
 * ></rsp-display-edit-date>
 * ```
 */
@Component( {
    selector:    'rsp-display-edit-date',
    templateUrl: './display-edit-date.component.html',
    styleUrls:   [ './display-edit-date.component.scss', ],
} )
export class DisplayEditDateComponent {

    @Input() isEditMode: boolean;
    @Input() myFormGroup: UntypedFormGroup;
    @Input() name: string;

    /**
     * For edit mode: Should past dates be selectable?
     * Set to `false` for edit cases, for add-cases the default `true` should be good.
     */
    @Input() disablePastDates: boolean;

    @Input() minDate: Date;
    @Input() maxDate: Date;
    /**
     * For edit mode: Should the input field have the clear-x-button?
     */
    @Input() withClearButton: boolean = true;

    /**
     * For edit mode: Optional placeholder text.
     */
    @Input() placeholder: string;

    /**
     * Optional, defaults to HumanReadable.
     * @see DateTimeComponent
     */
    @Input() format: string | DateTimeDisplayFormat = DateTimeDisplayFormat.HumanReadable;

    /**
     * For display mode. Optional.
     * @see DateTimeComponent
     */
    @Input() withWeekday: boolean;

    /**
     * For display mode. Optional.
     * @see DateTimeComponent
     */
    @Input() withTime: boolean;

    /**
     * For display mode. Optional.
     * @see DateTimeComponent
     */
    @Input() withSeconds: boolean;

    /**
     * For display mode. Optional.
     * @see DateTimeComponent
     */
    @Input() withTimezone: boolean;

    /**
     * For display mode. Optional.
     * @see DateTimeRangeComponent
     */
    @Input() withYear: boolean;

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    /**
     *
     */
    @Input() disabled: boolean;

    static buildFormControl( value: Date | null, validators?: Array<ValidatorFn> ): UntypedFormControl {
        return new UntypedFormControl( value, validators );
    }

}
