import { Pipe, PipeTransform, } from '@angular/core';

/**
 * Creates string which contains all joined elements from the array.
 *
 * ```html
 * {{ [ '1', '2', '3' ]     | joined }}                 =>  1 2 3
 * {{ [ '1', '2', '3' ]     | joined: ', ' }}           =>  1, 2, 3
 * {{ [ '1', '', '2', '3' ] | joined: ', ' }}           =>  1, 2, 3
 * {{ [ '1', '', '2', '3' ] | joined: ', ':false }}     =>  1,, 2, 3
 * ```
 *
 */
@Pipe( {
    name: 'joined',
} )
export class JoinedPipe implements PipeTransform {

    transform( arrayToJoin: Array<string>, separator?: string, skipEmpty: boolean = true ): any {

        if ( arrayToJoin && arrayToJoin.length ) {

            let result: string = '';
            let first: boolean = true;
            separator          = separator || ' ';

            arrayToJoin.forEach( ( item: string ) => {

                if ( item
                    || (!item && !skipEmpty) ) {

                    if ( !first ) {
                        result += separator;

                    }

                    result += item;
                    first = false;
                }

            } );

            return result;
        }
        return arrayToJoin;
    }
}
