import { Component, ElementRef, Injector, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { DropdownButtonComponent } from './dropdown-button.component';

/**
 * Represents one DropdownButton's item. See DropdownButton for more details.
 */
@Component( {
    selector:  'rsp-dropdown-button-item',
    template:  `
                   <button #button class="dropdown-button--list-item" [disabled]="disabled"
                           (click)="onClick()"
                   >
                       <ng-content></ng-content>
                   </button>
               `,
    styleUrls: [
        './dropdown-button.component.scss',
    ],
    encapsulation: ViewEncapsulation.None,  // because this component uses scss from DropdownButtonComponent
} )
export class DropdownButtonItemComponent {
    @ViewChild( 'button', { static: true } ) button: ElementRef;

    @Input()
    value: any;

    @Input() disabled: boolean;

    private dropdownButton: DropdownButtonComponent;

    constructor( private injector: Injector ) {

        this.dropdownButton = this.injector.get( DropdownButtonComponent );
        if ( !this.dropdownButton ) {
            throw new Error( '<rsp-dropdown-button-item> must be used inside <rsp-dropdown-button>!' );
        }
    }


    onClick(): void {
        this.dropdownButton.onListItemSelected( this.value );
    }
}
