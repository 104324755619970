import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';

import { AuthenticationService } from '../authentication.service';

/**
 * Checks if the current user is logged into the identity server.
 *
 * If the check fails, user is redirected to identity server login page.
 */
@Injectable()
export class IdentityServerLoginGuard implements CanActivate, CanActivateChild {
    constructor(
        private authenticationService: AuthenticationService,
    ) {}

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
        // console.log( '[IdentityServerLoginGuard.canActivate] Checking \'' + route.url + '\' on the way to \'' + state.url + '\'' );

        return this.authorize();
    }

    canActivateChild( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): boolean {
        // console.log( '[IdentityServerLoginGuard.canActivateChild] Checking \'' + route.url + '\' on the way to \'' + state.url + '\'' );

        return this.authorize();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private authorize(): boolean {
        return this.authenticationService.isLoggedIn();
    }
}
