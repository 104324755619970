<section #dialog class="dialog" (rspClickOutside)="clickedOutside( $event )" [isClickOutsideActive]="config && !config.withBackdrop">
    <header #headerContainer class="dialog--headline">
        <div #headlineTarget></div>
        <div (click)="closeDialog()" class="dialog--close-button"></div>
    </header>
    <div class="dialog--main-content" [style.max-height]="maxHeight">
        <div #target></div>
    </div>
    <footer #footerContainer class="dialog--footer">
        <div #footerTarget></div>
    </footer>
</section>
