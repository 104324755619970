import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component( {
    selector:        'rsp-registration-wizard-section-headline',
    templateUrl:     './registration-wizard-section-headline.component.html',
    styleUrls:       [ './registration-wizard-section-headline.component.scss' ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class RegistrationWizardSectionHeadlineComponent {
    @Input() isDone: boolean = false;
}
