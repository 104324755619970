import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { AdvancedSearchFilterTypeOperator } from '../../../model/advancedSearchFilterTypeOperator';
import { AdvancedSearchFilterProperty } from '../../../model/advancedSearchFilterProperty';
import { AdvancedSearchFilterGroup as AdvancedSearchFilterQueryGroup } from '../../../model/advancedSearchFilterGroup';
import { AdvancedSearchFilterField, AdvancedSearchFilterGroup, AdvancedSearchService } from './advanced-search.service';

@Component( {
    selector:    'rsp-advanced-search',
    templateUrl: './advanced-search.component.html',
    styleUrls:   [ './advanced-search.component.scss', ],
} )
export class AdvancedSearchComponent implements OnChanges {
    @Input() advancedSearchFilterTypeOperators: AdvancedSearchFilterTypeOperator[];
    @Input() advancedSearchFilterProperties: AdvancedSearchFilterProperty[];
    @Input() advancedSearchFilterGroups: AdvancedSearchFilterQueryGroup[];

    @Output() onClose: EventEmitter<void> = new EventEmitter<void>();
    @Output() valueChanged: EventEmitter<AdvancedSearchFilterQueryGroup[]> = new EventEmitter<AdvancedSearchFilterQueryGroup[]>();

    filterGroups: AdvancedSearchFilterGroup[] = [];
    filterFields: AdvancedSearchFilterField[] = [];

    constructor( private advancedSearchService: AdvancedSearchService ) {
    }

    ngOnChanges(): void {
        this.filterGroups = this.advancedSearchService.getFilterGroups(
            this.advancedSearchFilterProperties,
            this.advancedSearchFilterTypeOperators,
            this.advancedSearchFilterGroups,
        );

        if ( this.filterGroups.length === 0 ) {
            this.addEmptyFilterGroup();
        }

        this.filterFields = this.advancedSearchService.getFilterFields(
            this.advancedSearchFilterProperties,
            this.advancedSearchFilterTypeOperators,
        );
    }

    applyFilters( closeFilters: boolean = true): void {
        const queryGroups: AdvancedSearchFilterQueryGroup[] = this.advancedSearchService.mapAdvancedSearchFilterGroupsToQueryGroups( this.filterGroups );
        this.valueChanged.emit( queryGroups );

        if ( closeFilters ) {
            this.emitCloseEvent();
        }
    }

    resetFilters(): void {
        this.filterGroups = [];
        this.addEmptyFilterGroup();

        this.applyFilters( false );
    }

    addEmptyFilterGroup(): void {
        const defaultFilterGroup: AdvancedSearchFilterGroup = {
            groupOperator: AdvancedSearchFilterQueryGroup.GroupOperatorEnum.And,
            filters:       [],
        };

        this.filterGroups.push( defaultFilterGroup );
    }

    removeFilterGroup( index: number ): void {
        this.filterGroups.splice( index, 1 );

        if ( this.filterGroups.length === 0 ) {
            this.addEmptyFilterGroup();
        }
    }

    emitCloseEvent(): void {
        this.onClose.emit();
    }
}
