import { NgModule } from '@angular/core';

/* tslint:disable:max-line-length */
import { SharedModule } from '../../shared/shared.module';
import { UserManagementRoutingModule } from './user-management-routing.module';
import { UserListPageComponent } from './users/user-list/user-list-page.component';
import { UserToolbarComponent } from './users/user-toolbar/user-toolbar.component';
import { UserSelectionService } from './users/shared/user-selection.service';
import { UserStoreService } from './users/shared/user-store.service';
import { UserLineComponent } from './users/user-list/user-line/user-line.component';
import { UserDetailsPageComponent } from './users/user/user-details-page.component';
import { UserSummaryResolver } from './users/user/user-summary.resolver';
import { UserPrevNextNavigationService } from './users/shared/user-prev-next-navigation/user-prev-next-navigation.service';
import { UserPrevNextNavigationComponent } from './users/shared/user-prev-next-navigation/user-prev-next-navigation.component';
import { SecondaryToolbarService } from '../../shared/ui/secondary-toolbar/secondary-toolbar.service';
/* tslint:enable:max-line-length */

@NgModule( {
    imports:      [
        SharedModule,
        UserManagementRoutingModule,
    ],
    declarations: [
        UserLineComponent,
        UserListPageComponent,
        UserToolbarComponent,
        UserDetailsPageComponent,
        UserPrevNextNavigationComponent,
    ],
    providers: [
        UserPrevNextNavigationService,
        UserSelectionService,
        UserStoreService,
        UserSummaryResolver,

        SecondaryToolbarService,
    ],
} )
export class UserManagementModule {
}
