import { Component, } from '@angular/core';

import { StorageService, } from '../../../../../../core/storage.service';
import { FacetValue, } from '../../../../../model/facetValue';
import { FacetTypeSelectComponent, } from '../facet-type-select.component';
import {
    FacetTypeSelectComponent as FacetTypeSelectComponentInterface,
    FacetTypeSelect,
} from '../facet-type-select.model';
import { FacetService, } from '../../facet.service';
import { facetAnimations, } from '../../facet.animations';
import { VerticalScrollService } from '../../../../lists/vertical-scroll/vertical-scroll.service';
import { FacetItem } from '../../../../../model/facetItem';
import { FacetFilterItem } from '../../../../../model/facetFilterItem';
import { FacetValueChangedEventData } from '../../facet.model';


/**
 * Generates a facet for the `Singleselect`-type.
 *
 * ```html
 * <rsp-facet-type-singleselect facet="Facet" (valueChanged)="yourOnValueChanged()"></rsp-facet-type-singleselect>
 * ```
 */
// Inputs and outputs are defined in the base class, so we have to use `inputs` and `outputs` in the decorator.
@Component( {
    selector:    'rsp-facet-type-singleselect',
    templateUrl: '../facet-type-select.component.html',
    styleUrls:   [
        './facet-type-singleselect.component.scss',
    ],
    inputs:      [ 'facet' ], // tslint:disable-line:no-inputs-metadata-property
    outputs:     [ 'valueChanged' ], // tslint:disable-line:no-outputs-metadata-property
    animations:  facetAnimations,
} )
export class FacetTypeSingleselectComponent extends FacetTypeSelectComponent implements FacetTypeSelectComponentInterface {
    type: FacetTypeSelect = FacetItem.TypeEnum.Singleselect;
    cssModuleName: string = 'facet-type-singleselect';

    // I couldn't make ngForms radio-button binding work, so we handle the currentValue by ourselves.
    currentValue: FacetValue;

    constructor(
        storageService: StorageService,
        verticalScrollService: VerticalScrollService,
        private facetService: FacetService,
    ) {
        super( storageService, verticalScrollService );
    }

    set facet( facet: FacetItem ) {
        this.setFacet( facet );

        this.currentValue = this._facet.values.find( ( facetValue: FacetValue ) => facetValue.isSelected );
    }

    onValueClick( facetValue: FacetValue, event: MouseEvent ): void {
        this.onValueChanged( facetValue );
    }

    onValueChanged( changedValue: FacetValue ): void {
        // set `isSelected` for all IFacetItems to `false` (except for the `changedValue`)
        this._facet.values.forEach( ( valueItem: FacetValue ) => {
            if ( valueItem !== changedValue && valueItem.isSelected ) {
                valueItem.isSelected = false;
            }
        } );

        // set state for changedValue
        changedValue.isSelected = !changedValue.isSelected;
        this.currentValue = changedValue.isSelected ? changedValue : null;

        // get FacetValue and emit it
        const facetFilter: FacetFilterItem = this.facetService.getFacetFilterForSingleselectFacet( this._facet );

        const facetValueChangedEventData: FacetValueChangedEventData = {
            facetName:   this._facet.name,
            facetFilter: facetFilter,
        };

        this.valueChanged.emit( facetValueChangedEventData );
    }
}
