import {
    Component, OnInit, Input, Output, EventEmitter, ContentChildren, QueryList, ViewChild, ElementRef, AfterContentInit, OnDestroy, TemplateRef, AfterViewInit,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

import { DropdownItemComponent } from './dropdown-item.component';
import { PopupService } from '../../../core/overlay/popup/popup.service';
import { DropdownGroupComponent } from './dropdown-group.component';

import Fuse from 'fuse.js';

/**
 * Dropdown
 *
 * ## with default value ##
 *
 * ```html
 * <rsp-dropdown
 *     [defaultValue]="'default'"
 *     [inputFieldHtmlId]="'myDropdown'"
 *     (selected)="doSomethingWithSelectedItem( $event )"
 * >
 *     <rsp-dropdown-item ngFor="let item of items" [item]="item">
 *         # your visual portrayal of the item #
 *     </rsp-dropdown-item>
 * </rsp-dropdown>
 * ```
 *
 * ## with formControl ##
 *
 * ```html
 * <rsp-dropdown
 *     [myFormControl]="formControl"
 *     [inputFieldHtmlId]="'myDropdown'"
 *     (selected)="doSomethingWithSelectedItem( $event )"
 * >
 *     <rsp-dropdown-item ngFor="let item of items" [item]="item">
 *         # your visual portrayal of the item #
 *     </rsp-dropdown-item>
 * </rsp-dropdown>
 * ```
 *
 * ## with suggester ##
 *
 * ```html
 * <rsp-dropdown
 *     [withSuggester]="true"
 *     [inputFieldHtmlId]="'myDropdown'"
 *     (searchTermChanged)="triggerYourItemSearch( $event )"
 *     (selected)="doSomethingWithSelectedItem( $event )"
 * >
 *     <rsp-dropdown-item ngFor="let item of items" [item]="item">
 *         # your visual portrayal of the item #
 *     </rsp-dropdown-item>
 * </rsp-dropdown>
 * ```
 *
 * ## with groups ##
 *
 * ```html
 * <rsp-dropdown
 *     [inputFieldHtmlId]="'myDropdown'"
 *     [myFormControl]="formControl"
 *     (selected)="doSomethingWithSelectedItem( $event )"
 * >
 *     <rsp-dropdown-group [label]="'Your Group Label'">
 *         <rsp-dropdown-item ngFor="let item of items" [item]="item">
 *             # your visual portrayal of the item #
 *         </rsp-dropdown-item>
 *     </rsp-dropdown-group>
 * </rsp-dropdown>
 * ```
 *
 * The dropdown component has two modes. A *form* mode and a *suggest* mode. A combination of both is possible too.
 * If only the `withSuggester` input is set to true, the dropdown renders just one input field (`searchTermInput`), which will trigger `searchTermChanged`
 * events.
 * The listItems can be populated depending on the search Term and are shown in a popup. Selecting an item, clicking outside of the list or moving the focus
 * to another field will close the popup.
 * If `myFormControl` or `defaultValue` input is set, the *form*-mode is active. There is an input field (`formInput`) which represents the (default or
 * formControl) value. Clicking or focusing the input will open the popup (just like `withSuggester`).
 * In *form*-mode the `withSuggester` can also be set to true. The `searchTermInput` field is then shown in the popup (but it works equally).
 *
 */
@Component( {
    selector:    'rsp-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls:   [
        './dropdown.component.scss',
        './dropdown.scss',
    ],
} )
export class DropdownComponent implements OnInit, AfterViewInit, AfterContentInit, OnDestroy {
    @ContentChildren( DropdownItemComponent ) listItems: QueryList<DropdownItemComponent>;
    @ContentChildren( DropdownGroupComponent ) groups: QueryList<DropdownGroupComponent>;

    @ViewChild( 'searchTermInput' ) searchTermInput: ElementRef;
    @ViewChild( 'formInput' ) formInput: ElementRef;

    @ViewChild( 'list' ) list: ElementRef;
    @ViewChild( 'popup' ) popup: ElementRef;
    @ViewChild( 'container', { static: true } ) container: ElementRef;

    @ViewChild( 'contentTemplate', { read: TemplateRef, static: true } ) template: TemplateRef<any>;

    /**
     * When true a search input field is shown, which triggers `searchTermChanged` Events.
     */
    @Input() withSuggester: boolean = false;

    /**
     * In combination with defaultValue, it is possible to disable the input element
     */
    @Input() disabled: boolean = false;

    /**
     * Define the string, which is displayed inside the search input as HTML `placeholder`-attribute.
     */
    @Input() placeholder: string = 'Type to search';

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    /**
     * Show clear 'button' if true and defaultValue is set
     */
    @Input() allowClear: boolean = false;

    /**
     * Subscription which determines, if a loading indicator should be shown
     */
    @Input() isLoading: Subscription;


    /**
     * Define the original items to use with fuse.js
     */
    @Input()
    set originalItems( value: any[] ) {
        this.initFuzzySearch( value );
    }

    get originalItems(): any[] {
        return this._originalItems;
    }

    /**
     * set the "keys" option in fuzzySearchOptions, default is [ 'name' ]
     */
    @Input()
    set filterFields( fields: string[] ) {
        this.setFilterFields( fields );
    }

    /**
     * Set this to `true` to size the dropdown width with its parent node.
     */
    @Input() hasFullWidth: boolean = false;

    /**
     * Emits the selected sourceItem.
     */
    @Output() selected: EventEmitter<any | null> = new EventEmitter();

    /**
     * Emits the searchterm (even when its an empty string).
     */
    @Output() searchTermChanged: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Emits the filtered "original items" by fuse.js
     */
    @Output() filteredItems: EventEmitter<any[]> = new EventEmitter<any[]>();


    searchTermControl: UntypedFormControl = new UntypedFormControl();

    formMode: boolean = false;

    get isPopUpVisible(): boolean {
        return this._isPopUpVisible;
    }

    set isPopUpVisible( value: boolean ) {
        this.setIsPopUpVisible( value );
    }

    popupMinWidth: number;

    selectWrapperClasses: string[] = [ 'dropdown--select-wrapper', ];

    private fuzzySearch: Fuse<any>;
    private fuzzySearchOptions: Fuse.IFuseOptions<any> = {
        keys:      [ 'name' ],
        threshold: 0.4,
        distance:  100,
    };
    private _originalItems: any[];

    private TAB_KEY: string = 'Tab';

    private _isPopUpVisible: boolean = false;


    /**
     * Use the suggester in forms (current value, validators, ...)
     */
    @Input()
    get myFormControl(): UntypedFormControl {
        return this._formControl;
    }

    set myFormControl( value: UntypedFormControl ) {
        this.setFormControl( value );
    }

    /**
     * Comparable to `formControl`, but without actual form control element.
     */
    @Input()
    get defaultValue(): string {
        return this._defaultValue;
    }

    set defaultValue( value: string ) {
        this.setDefaultValue( value );
    }


    private _formControl: UntypedFormControl;
    private _defaultValue: string;

    private dropdownItems: Array<DropdownItemComponent> = [];

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private popupService: PopupService,
    ) {
    }


    ngOnInit(): void {
        if ( !this.formMode && !this.withSuggester ) {
            throw new Error(
                this.constructor.name + ': invalid configuration, either "withSuggester" or one of "defaultValue" and "myFormControl" has to be set',
            );
        }

        this.popupService
            .popupClosed$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                this.isPopUpVisible = false;
            } );

        this.popupService
            .popupOpened$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( triggerElement: ElementRef ) => {
                this.isPopUpVisible = this.container === triggerElement;
            } );

        this.popupService
            .clickedOutside$
            .pipe(
                filter( ( value: { target: HTMLElement, triggerElement: ElementRef } ) => this.container === value.triggerElement ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( value: { target: HTMLElement, triggerElement: ElementRef } ) => {
                if ( !this.searchTermInput || !this.searchTermInput.nativeElement.contains( value.target ) ) {
                    this.closePopUp();
                }
            } );

        if ( !this.withSuggester ) {
            return;
        }

        this.searchTermControl
            .valueChanges
            .pipe(
                debounceTime( 400 ),
                distinctUntilChanged(),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( value: string ) => {
                if ( this.fuzzySearch ) {
                    this.filteredItems.emit(
                        value
                            ? this.fuzzySearch.search( value )
                                  .map( ( searchResult: Fuse.FuseResult<any> ) => searchResult.item )
                            : this.originalItems );
                } else {
                    this.searchTermChanged.emit( value );
                }

                if ( value && !this.isPopUpVisible ) {
                    this.openPopUp();
                }
            } );
    }

    ngAfterContentInit(): void {
        if ( this.listItems.length && this.groups.length ) {
            throw new Error(
                this.constructor.name + ': invalid configuration, currently it is not possible to use groups and "single" items simultaneously',
            );
        }

        if ( this.listItems.length ) {
            this.initItems( this.listItems.toArray() );
        }

        this.listItems
            .changes
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( items: QueryList<DropdownItemComponent> ) => {
                this.initItems( items.toArray() );
            } );

        if ( this.groups.length ) {
            this.initGroupItems( this.groups );
        }

        this.groups
            .changes
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( groups: QueryList<DropdownGroupComponent> ) => {
                this.initGroupItems( groups );
            } );

        if ( this.hasFullWidth ) {
            this.selectWrapperClasses.push( 'has-full-width' );
        }
    }

    ngAfterViewInit(): void {
        this.setPopupMinWidth();
    }

    ngOnDestroy(): void {
        this.closePopUp();
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    onKeyDown( event: KeyboardEvent, moveFocusToSearchTermInput: boolean = false ): void {

        // press on the TAB key should close dropdown list and move
        // focus either to next element on the page (default behaviour)
        // or on #formInput (only when TAB pressed inside #searchTermInput).

        if ( event.key === this.TAB_KEY ) {

            this.closePopUp();

            if ( moveFocusToSearchTermInput
                && this.formMode
                && this.withSuggester ) {

                setTimeout( () => {
                    this.formInput.nativeElement.focus();
                } );
            }
        }
    }

    onClick(): void {
        if ( !this.isPopUpVisible ) {
            this.openPopUp();

            if ( !this.formMode ) {
                this.triggerEmptySearch();
            }
        } else {
            this.closePopUp();
        }
    }


    /**
     *  prevents the cursor to jump to start or end in input field
     */
    preventCursorMovement( event: KeyboardEvent ): boolean {
        event.preventDefault();
        event.stopPropagation();

        return false;
    }


    triggerEmptySearch(): void {
        if ( !this.searchTermControl.value ) {
            // if focusing on an empty suggester
            this.searchTermChanged.emit( '' );
        }
    }


    selectForAction(): void {
        const item: DropdownItemComponent = this.dropdownItems.find( ( listItem: DropdownItemComponent ) => {
            return listItem.isFocused;
        } );

        if ( item ) {
            this.selected.emit( item.item );
            this.searchTermControl.reset();
        } else if ( this.dropdownItems.length ) {
            this.selectNext();
            this.selectForAction();
        }

        this.closePopUp();

        if ( this.formMode ) {
            setTimeout(
                () => {
                    this.formInput.nativeElement.focus();
                },
                200, // textInputDirective has a blur listener with 200ms debouncetime
            );
        }
    }

    selectPrevious(): void {
        if ( !this.dropdownItems.length || !this.isPopUpVisible ) {
            return;
        }


        const index: number = this.dropdownItems.findIndex( ( listItem: DropdownItemComponent ) => {
            return listItem.isFocused;
        } );

        let item: DropdownItemComponent;

        if ( index === -1 ) {
            item = this.dropdownItems[ this.dropdownItems.length - 1 ];
        } else {
            if ( (index - 1) >= 0 ) {
                this.dropdownItems[ index ].isFocused = false;
                item                                  = this.dropdownItems[ index - 1 ];
            } else {
                item = this.dropdownItems[ index ];
            }
        }

        item.isFocused = true;

        const itemOffset: number   = item.itemContainer.nativeElement.offsetTop - this.list.nativeElement.offsetTop;
        const popUpHeight: number  = this.popup.nativeElement.clientHeight;
        const scrollHeight: number = this.list.nativeElement.scrollHeight;
        const scrollOffset: number = this.list.nativeElement.scrollTop;
        if ( popUpHeight < scrollHeight && itemOffset < scrollOffset ) {
            this.list.nativeElement.scrollTop = itemOffset;
        }
    }

    selectNext(): void {
        if ( !this.dropdownItems.length ) {
            if ( this.withSuggester ) {
                this.searchTermChanged.emit( this.searchTermControl.value || '' );
                this.openPopUp();
            }
            return;
        } else if ( !this.isPopUpVisible ) {
            this.openPopUp();
            return;
        }

        const index: number = this.dropdownItems.findIndex( ( listItem: DropdownItemComponent ) => {
            return listItem.isFocused;
        } );

        let item: DropdownItemComponent;

        if ( index === -1 ) {
            item = this.dropdownItems[ 0 ];
        } else {
            if ( (index + 1) < this.dropdownItems.length ) {
                this.dropdownItems[ index ].isFocused = false;
                item                                  = this.dropdownItems[ index + 1 ];
            } else {
                item = this.dropdownItems[ index ];
            }
        }

        item.isFocused = true;

        const itemOffset: number   = item.itemContainer.nativeElement.offsetTop;
        const itemHeight: number   = item.itemContainer.nativeElement.getBoundingClientRect().height;
        const popUpHeight: number  = this.popup.nativeElement.clientHeight;
        const scrollHeight: number = this.list.nativeElement.scrollHeight;
        const scrollOffset: number = this.list.nativeElement.scrollTop;
        if ( popUpHeight < scrollHeight && (itemOffset - scrollOffset + itemHeight) > popUpHeight ) {
            this.list.nativeElement.scrollTop = itemOffset - popUpHeight + itemHeight;
        }
    }

    openPopUp(): void {
        if ( (!this.dropdownItems.length && !this.withSuggester) || this.isPopUpVisible ) {
            return;
        }

        this.isPopUpVisible = true;

        // popup must be moved one pixel up/down. It causes that border is rendered correctly
        // (trigger element and popup borders are displayed as one border).
        const popupOffsetY: number = 1;

        this.popupService.openOverlay( this.template, this.container, popupOffsetY, false );

        // sometimes (IE11), when the template is not visible (slidein), native element width is 0. This fallback will be used.
        if ( !this.popupMinWidth ) {
            this.setPopupMinWidth();
        }
    }

    closePopUp(): void {

        this.isPopUpVisible = false;
        this.dropdownItems.forEach( ( listItem: DropdownItemComponent ) => {
            listItem.isFocused = false;
        } );
        this.popupService.closeOverlay();
    }

    clearSelection(): void {
        this.selected.emit( null );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private initFuzzySearch( items: any[] ): void {
        this._originalItems = items;
        this.fuzzySearch    = new Fuse( items, this.fuzzySearchOptions );

        this.filteredItems.emit( items );
    }

    private setFilterFields( fields: string[] ): void {
        if ( fields ) {
            this.fuzzySearchOptions.keys = fields;
            this.fuzzySearch             = new Fuse( this.originalItems, this.fuzzySearchOptions );
        }
    }

    private setIsPopUpVisible( value: boolean ): void {
        this._isPopUpVisible = value;
        if ( !this._isPopUpVisible && this.isLoading ) {
            // reset isLoading on closing popup
            this.isLoading = null;
        }
    }


    private initGroupItems( groups: QueryList<DropdownGroupComponent> ): void {
        let items: DropdownItemComponent[] = [];
        groups.forEach( ( group: DropdownGroupComponent ) => {
            items = items.concat( group.items.map( ( item: DropdownItemComponent ) => item ) );
        } );
        this.initItems( items );
    }


    private initItems( items: Array<DropdownItemComponent> ): void {
        items.forEach( ( item: DropdownItemComponent ) => {
            if ( !item.selectedItem.observers.length ) {
                item.selectedItem
                    .pipe( takeUntil( this.isDestroyed ) )
                    .subscribe( ( sourceItem: any ) => {
                        this.selected.emit( sourceItem );

                        this.searchTermControl.reset();

                        this.closePopUp();

                        if ( this.formMode ) {
                            setTimeout(
                                () => {
                                    this.formInput.nativeElement.focus();
                                },
                                200, // textInputDirective has a blur listener with 200ms debouncetime
                            );
                        }
                    } );
            }
        } );

        this.dropdownItems = items;
        this.popupService.autoPositionOverlay();
    }

    private setDefaultValue( value: string = null ): void {
        this.formMode      = true;
        this._defaultValue = value;
    }

    private setFormControl( value: UntypedFormControl ): void {
        this.formMode     = true;
        this._formControl = value;
    }

    private setPopupMinWidth(): void {
        // without setTimeout() it does not work reliable
        setTimeout(
            () => {
                const rect: DOMRect = this.container.nativeElement.getBoundingClientRect();

                this.popupMinWidth = rect.width;
            } );
    }
}
