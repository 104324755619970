<table class="service-level-table" *ngIf="isTableVisible()">
    <colgroup>
        <col span="1">
        <col span="6" class="service-level-table--colgroup-level">
        <col span="1" *ngIf="isAdministrationMode">
    </colgroup>
    <thead class="service-level-table--head">
    <tr class="service-level-table--head-row">
        <th class="service-level-table--head-cell">
            <rsp-column-header
                [collectionView]="collectionView"
                [propertyName]="'name'"
            >Service</rsp-column-header>
        </th>
        <th class="service-level-table--head-cell_level" colspan="6">Level</th>
        <th class="service-level-table--head-cell_actions" *ngIf="isAdministrationMode"></th>
    </tr>
    <tr class="service-level-table--head-row">
        <th class="service-level-table--head-cell"></th>
        <th class="service-level-table--head-cell_level">1</th>
        <th class="service-level-table--head-cell_level">2</th>
        <th class="service-level-table--head-cell_level">3</th>
        <th class="service-level-table--head-cell_level">4</th>
        <th class="service-level-table--head-cell_level">5</th>
        <th class="service-level-table--head-cell_level">6</th>
        <th class="service-level-table--head-cell_actions" *ngIf="isAdministrationMode"></th>
    </tr>
    </thead>
    <tbody class="service-level-table--body">
    <ng-container *ngFor="let model of collectionView.items">

        <!-- Service Models -->
        <tr
            (click)="model.toggleCollapsing()"
            class="service-level-table--body-row_service-model"
        >
            <td colspan="7" class="service-level-table--body-cell">
                <div class="service-level-table--service-model-wrapper">
                    <span>
                        <span
                            class="service-level-table--toggle"
                            [class.is-expanded]="!model.isCollapsed"
                        ></span>

                        <input
                            *ngIf="model.isInEditMode"
                            type="text"
                            rspTextInput
                            rspTextInputWrapperClass="service-level-table--service-model-name-input"
                            [withClearButton]="true"
                            (click)="$event.stopPropagation()"
                            [(ngModel)]="model.name"
                        >

                        <ng-container *ngIf="!model.isInEditMode">
                            {{ model.name }}
                        </ng-container>
                    </span>

                    <span *ngIf="!isAdministrationMode">({{ model.assignedLevelLabel }})</span>

                    <!-- Edit and Remove buttons -->
                    <div *ngIf="isAdministrationMode && !model.isInEditMode">
                        <rsp-pencil-button
                            class="service-level-table--service-model-edit-button"
                            (click)="onEditServiceModelClick( model, $event )"
                        >Edit</rsp-pencil-button>

                        <rsp-remove
                            [text]="'Are you sure you want to delete this Service Model?'"
                            [title]="'Remove'"
                            (confirmClicked)="removeServiceModel( model )"
                        ></rsp-remove>
                    </div>

                    <!-- Save and Cancel buttons -->
                    <div *ngIf="model.isInEditMode">

                        <rsp-button
                            [submodule]="'secondary'"
                            (click)="onCancelServiceModelItemEditModeClick( model, $event )"
                        >Cancel</rsp-button>

                        <rsp-button
                            class="service-level-table--service-model-save-button"
                            [disabled]="!model.isValid()"
                            [submodule]="'primary'"
                            (click)="onSaveServiceModelClick( model, $event )"
                        >Save</rsp-button>

                    </div>
                </div>
            </td>

            <td class="service-level-table--body-cell" *ngIf="isAdministrationMode"></td>
        </tr>

        <!-- Service Levels (collapsable) -->
        <ng-container *ngIf="!model.isCollapsed">

            <!-- toolbar row with LevelGroupIsAvailable -->
            <tr *ngIf="isAdministrationMode"
                class="service-level-table--service-levels-toolbar"
            >
                <td>
                    <div class="service-level-table--service-levels-toolbar_button-and-label-wrapper">
                        <rsp-button
                            [style.visibility]="!model.isInEditMode ? 'hidden' : null"
                            (clicked)="model.addNewServiceLevel()"
                        >Add Service Level</rsp-button>
                        <div class="service-level-table--service-levels-toolbar_label">
                            Service Level Group:
                        </div>
                    </div>
                </td>
                <td>
                    <input
                        rspCheckbox
                        type="checkbox"
                        [disabled]="!model.isInEditMode"
                        [(ngModel)]="model.levelGroup1IsAvailable"
                    >
                </td>
                <td>
                    <input
                        rspCheckbox
                        type="checkbox"
                        [disabled]="!model.isInEditMode"
                        [(ngModel)]="model.levelGroup2IsAvailable"
                    >
                </td>
                <td>
                    <input
                        rspCheckbox
                        type="checkbox"
                        [disabled]="!model.isInEditMode"
                        [(ngModel)]="model.levelGroup3IsAvailable"
                    >
                </td>
                <td>
                    <input
                        rspCheckbox
                        type="checkbox"
                        [disabled]="!model.isInEditMode"
                        [(ngModel)]="model.levelGroup4IsAvailable"
                    >
                </td>
                <td>
                    <input
                        rspCheckbox
                        type="checkbox"
                        [disabled]="!model.isInEditMode"
                        [(ngModel)]="model.levelGroup5IsAvailable"
                    >
                </td>
                <td>
                    <input
                        rspCheckbox
                        type="checkbox"
                        [disabled]="!model.isInEditMode"
                        [(ngModel)]="model.levelGroup6IsAvailable"
                    >
                </td>
                <td></td>
            </tr>

            <!-- service levels -->
            <tr *ngFor="let level of model.serviceLevelItems">

                <!-- Service Level Name -->
                <td
                    [ngClass]="{ 'is-level-not-available': !isAdministrationMode && !level.isLevelAvailableFor( model.assignedLevel ) }"
                    class="service-level-table--body-cell_level-name"
                >
                    <input
                        *ngIf="model.isInEditMode"
                        type="text"
                        rspTextInput
                        [withClearButton]="true"
                        rspTextInputWrapperClass="service-level-table--service-level-name-input"
                        [(ngModel)]="level.name"
                    >

                    <ng-container *ngIf="!model.isInEditMode">
                        {{ level.name }}
                    </ng-container>
                </td>

                <!-- Level 1 -->
                <td
                    [ngClass]="{ 'is-level-active': model.assignedLevel === 1 }"
                    class="service-level-table--body-cell_level"
                >
                    <input
                        *ngIf="model.isInEditMode"
                        rspCheckbox
                        type="checkbox"
                        [(ngModel)]="level.hasLevel1"
                    >

                    <ng-container
                        *ngIf="!model.isInEditMode"
                        [ngTemplateOutlet]="checkboxLevel"
                        [ngTemplateOutletContext]="{ hasLevel: level.hasLevel1, hasModel: model.assignedLevel === 1 }"
                    >
                    </ng-container>
                </td>

                <!-- Level 2 -->
                <td
                    [ngClass]="{ 'is-level-active': model.assignedLevel === 2 }"
                    class="service-level-table--body-cell_level"
                >
                    <input
                        *ngIf="model.isInEditMode"
                        rspCheckbox
                        type="checkbox"
                        [(ngModel)]="level.hasLevel2"
                    >

                    <ng-container
                        *ngIf="!model.isInEditMode"
                        [ngTemplateOutlet]="checkboxLevel"
                        [ngTemplateOutletContext]="{ hasLevel: level.hasLevel2, hasModel: model.assignedLevel === 2 }"
                    >
                    </ng-container>
                </td>

                <!-- Level 3 -->
                <td
                    [ngClass]="{ 'is-level-active': model.assignedLevel === 3 }"
                    class="service-level-table--body-cell_level"
                >
                    <input
                        *ngIf="model.isInEditMode"
                        rspCheckbox
                        type="checkbox"
                        [(ngModel)]="level.hasLevel3"
                    >

                    <ng-container
                        *ngIf="!model.isInEditMode"
                        [ngTemplateOutlet]="checkboxLevel"
                        [ngTemplateOutletContext]="{ hasLevel: level.hasLevel3, hasModel: model.assignedLevel === 3 }"
                    >
                    </ng-container>
                </td>

                <!-- Level 4 -->
                <td
                    [ngClass]="{ 'is-level-active': model.assignedLevel === 4 }"
                    class="service-level-table--body-cell_level"
                >
                    <input
                        *ngIf="model.isInEditMode"
                        rspCheckbox
                        type="checkbox"
                        [(ngModel)]="level.hasLevel4"
                    >

                    <ng-container
                        *ngIf="!model.isInEditMode"
                        [ngTemplateOutlet]="checkboxLevel"
                        [ngTemplateOutletContext]="{ hasLevel: level.hasLevel4, hasModel: model.assignedLevel === 4 }"
                    >
                    </ng-container>
                </td>

                <!-- Level 5 -->
                <td
                    [ngClass]="{ 'is-level-active': model.assignedLevel === 5 }"
                    class="service-level-table--body-cell_level"
                >
                    <input
                        *ngIf="model.isInEditMode"
                        rspCheckbox
                        type="checkbox"
                        [(ngModel)]="level.hasLevel5"
                    >

                    <ng-container
                        *ngIf="!model.isInEditMode"
                        [ngTemplateOutlet]="checkboxLevel"
                        [ngTemplateOutletContext]="{ hasLevel: level.hasLevel5, hasModel: model.assignedLevel === 5 }"
                    >
                    </ng-container>
                </td>

                <!-- Level 6 -->
                <td
                    [ngClass]="{ 'is-level-active': model.assignedLevel === 6 }"
                    class="service-level-table--body-cell_level"
                >
                    <input
                        *ngIf="model.isInEditMode"
                        rspCheckbox
                        type="checkbox"
                        [(ngModel)]="level.hasLevel6"
                    >

                    <ng-container
                        *ngIf="!model.isInEditMode"
                        [ngTemplateOutlet]="checkboxLevel"
                        [ngTemplateOutletContext]="{ hasLevel: level.hasLevel6, hasModel: model.assignedLevel === 6 }"
                    >
                    </ng-container>
                </td>

                <!-- Delete Service Level -->
                <td *ngIf="isAdministrationMode"
                    class="service-level-table--body-cell">
                    <rsp-x-button
                        *ngIf="model.isInEditMode"
                        (click)="model.removeServiceLevel( level )"
                    >Remove</rsp-x-button>
                </td>
            </tr>

        </ng-container>

    </ng-container>
    </tbody>
</table>


<ng-template #checkboxLevel let-hasLevel="hasLevel" let-hasModel="hasModel">
    <span
        [class.service-level-table--body-cell-active-checkbox-checked]="hasLevel && hasModel"
        [class.service-level-table--body-cell-active-checkbox]="!hasLevel && hasModel"
        [class.service-level-table--body-cell-disabled-checkbox-checked]="hasLevel && !hasModel"
        [class.service-level-table--body-cell-disabled-checkbox]="!hasLevel && !hasModel"
    ></span>
</ng-template>
