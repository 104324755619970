import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FileContainerItem, FileContainerWithReference, FileKindInfo, FileKindItem, ReferencedObject, ViewModelListFileKindItem } from '../../../model';
import { FileManagementFileKindsService, FileManagementFilesService } from '../../../api';
import { CollectionView } from '../../tables/collection-view';
import { StandardFilters } from '../../tables/filters/standard-filters';
import { FileContainerWithReferenceCollectionViewItem } from './file-container-with-reference-collection-view-item.model';
import { UploadedFileInfo } from '../../../forms/upload/upload-single-file/upload-single-file.component';
import { CreateZipFromFilesDialogComponent } from '../create-zip-from-files-dialog/create-zip-from-files-dialog.component';
import { FileVersionDialogComponent } from '../file-version-dialog/file-version-dialog.component';


/**
 * Typical use case:
 * ```
 *     <rsp-file-table
 *         [files]="fileContainerArray"
 *         [readOnly]="false"
 *         (fileAdded)="doSomethingWithNewFileContainer( $event )"
 *         (fileRemoved)="doSomethingWithRemovedFileContainerId( $event )"
 *     ></rsp-file-table>
 * ```
 */
@Component( {
    selector:    'rsp-file-table',
    templateUrl: './file-table.component.html',
    styleUrls:   [
        '../../../scss/05_module/edit-icons.scss',
        './file-table.component.scss',
    ],
} )
export class FileTableComponent implements OnInit, OnChanges, OnDestroy {
    @ViewChild( CreateZipFromFilesDialogComponent, { static: true } ) createZipFromFilesDialogComponent: CreateZipFromFilesDialogComponent;
    @ViewChild( FileVersionDialogComponent, { static: true } ) fileVersionDialogComponent: FileVersionDialogComponent;

    @Input() files: FileContainerWithReference[];
    @Input() referenceHeader: string;
    @Input() referenceType: string;
    @Input() ignoreSelfReference: boolean;
    @Input() useIdForReferenceLink: boolean;

    @Input() hidePreview: boolean;
    @Input() referenceNoLink: boolean;

    @Input() fileKinds: FileKindInfo[];
    @Input() area: FileKindItem.AreasEnum;

    @Input() readOnly: boolean;
    @Input() useAddFileEvent: boolean = false;
    @Input() anonymousDownload: boolean = false;

    @Input() exportToZip: boolean = true;

    /**
     * Parent component uses this, to link the added file to its entity.
     */
    @Output() fileAdded: EventEmitter<FileContainerItem> = new EventEmitter();

    /**
     * Parent component uses this, to remove the file from its entity.
     */
    @Output() fileRemoved: EventEmitter<string> = new EventEmitter();


    /**
     * in combination with useAddFileEvent, instead of a table row for a new file, this event is fired
     */
    @Output() addFileClicked: EventEmitter<void>    = new EventEmitter<void>();
    @Output() editFileActive: EventEmitter<boolean> = new EventEmitter<boolean>();


    collectionView: CollectionView<FileContainerWithReferenceCollectionViewItem> = new CollectionView<FileContainerWithReferenceCollectionViewItem>();

    referenceSelfDisplay: string;

    referenceEnumToString: ( value: number ) => string = ReferencedObject.TypeEnum.toString;

    newFile: boolean = false;

    selectedFileKind: FileKindInfo;

    filteredFileKinds: FileKindInfo[];

    editMode: Set<string> = new Set();

    selectedFiles: FileContainerWithReference[] = [];

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private filesApi: FileManagementFilesService,
        private fileKindApi: FileManagementFileKindsService,
        private standardFilters: StandardFilters,
    ) { }


    ngOnInit(): void {
        this.initializeTableFeatures();

        if ( this.readOnly && this.useAddFileEvent ) {
            throw new Error( this.constructor.name + ': readOnly and useAddFileEvent can not be used simultaneously.' );
        }

        this.referenceSelfDisplay = 'this ' + this.referenceType;
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    ngOnChanges(): void {
        if ( this.files && this.files.length > 0 ) {
            this.collectionView.setSourceItems(
                this.files.map( ( file: FileContainerWithReference ) => {
                    return new FileContainerWithReferenceCollectionViewItem( file );
                } ) ).refresh();
        }
        else {
            this.collectionView.setSourceItems([]);
        }

        this.referenceSelfDisplay = 'this ' + this.referenceType;
    }

    updateRow( file: FileContainerWithReference ): void {
        this.filesApi
            .fileContainersGetFileContainer( file.id )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( result: FileContainerItem ) => {
                file.name           = result.name;
                file.uploadedOn     = result.uploadedOn;
                file.previewImageId = result.previewImageId;

                this.setEditModeForFile( file );
            } );
    }

    setEditModeForFile( file: FileContainerWithReference ): void {
        if ( this.editMode.has( file.id ) ) {
            this.editMode.delete( file.id );
            this.editFileActive.emit( false );
        }
        else {
            this.editMode.add( file.id );
            this.editFileActive.emit( true );
        }
    }

    setFileKind( info: FileKindInfo ): void {
        this.selectedFileKind = info;
    }

    createFile(): void {
        if ( this.useAddFileEvent ) {
            this.addFileClicked.emit();
        }
        else {
            this.editFileActive.emit( true );
            this.newFile = true;
            if ( ( !this.fileKinds || !this.fileKinds.length ) && ( this.area === undefined || this.area === null ) ) {
                this.fileKindApi
                    .fileKindsGetList()
                    .pipe( takeUntil( this.isDestroyed ) )
                    .subscribe( ( result: ViewModelListFileKindItem ) => {
                        this.fileKinds = result.data.filter((fileKind: FileKindItem) => fileKind.name.indexOf('Sub-Component Drawing *.') === -1);
                        this.fileKinds = this.fileKinds.filter((fileKind: FileKindItem) => fileKind.name.indexOf('Ariba Preview') === -1);
                    } );
            }
            else {
                this.fileKindApi
                    .fileKindsGetFileKindsByArea( FileKindItem.AreasEnum.toString( this.area ) )
                    .pipe( takeUntil( this.isDestroyed ) )
                    .subscribe( ( result: FileKindInfo[] ) => {
                        this.fileKinds = result.filter((fileKind: FileKindItem) => fileKind.name.indexOf('Sub-Component Drawing *.') === -1);
                        this.fileKinds = this.fileKinds.filter((fileKind: FileKindItem) => fileKind.name.indexOf('Ariba Preview') === -1);
                    } );
            }
        }
    }

    endCreateFile(): void {
        this.editFileActive.emit( false );
        this.newFile = false;
        this.selectedFileKind = null;
    }

    addFile( file: UploadedFileInfo | null ): void {
        if ( file && file.fileContainerId ) {
            this.filesApi
                .fileContainersGetFileContainer( file.fileContainerId )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( fileContainer: FileContainerItem ) => {
                    this.fileAdded.emit( fileContainer );
                    this.endCreateFile();
                } );
        }
    }

    removeFile( fileId: string ): void {
        this.fileRemoved.emit( fileId );
    }

    setFilteredFileKinds( items: FileKindInfo[] ): void {
        this.filteredFileKinds = items;
    }

    isFileSelected( file: FileContainerWithReference ): boolean {
        return this.selectedFiles.some( ( fileToCheck: FileContainerWithReference ) => fileToCheck.id === file.id );
    }

    toggleSelection( fileToToggle: FileContainerWithReference ): void {
        const index: number = this.selectedFiles.findIndex( ( file: FileContainerWithReference ) => file.id === fileToToggle.id );
        if ( index > -1 ) {
            this.selectedFiles.splice( index, 1 );
        }
        else {
            this.selectedFiles.push( fileToToggle );
        }
    }

    areSomeFilesSelected(): boolean {
        return this.selectedFiles.length > 0 && this.selectedFiles.length < this.files.length;
    }

    areAllFilesSelected(): boolean {
        return this.files.every( ( file: FileContainerWithReference ) => this.isFileSelected( file ) );
    }

    toggleAllSelection(): void {
        if ( this.areAllFilesSelected() ) {
            this.selectedFiles = [];
        }
        else {
            this.selectedFiles = this.files.slice();
        }
    }

    zipSelectedFiles(): void {
        this.createZipFromFilesDialogComponent.open( this.selectedFiles );
    }

    showFileVersionDialog( fileContainerId: string ): void {
        this.fileVersionDialogComponent.open( fileContainerId );
    }

    private initializeTableFeatures(): void {

        // filter function
        this.collectionView.setGlobalFilterFunction( ( item: FileContainerWithReferenceCollectionViewItem, filter: string ) => {

            if ( typeof filter === 'undefined' || filter === null ) {
                return true;
            }

            if ( this.standardFilters.contains( item.name, filter )
                 || this.standardFilters.contains( item.reference, filter )
                 || this.standardFilters.contains( item.uploadedOn, filter )
                 || this.standardFilters.contains( item.fileKind, filter ) ) {
                return true;
            }

            return false;
        } );
    }
}
