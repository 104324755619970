import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { CustomValidators } from '../../../forms/validation/custom-validators';
import { NotificationService } from '../../../../core/overlay/notification/notification.service';
import { DialogConfig, DialogService } from '../../../../core/overlay/dialog/dialog.service';
import { CreateFilesExport, FileContainerItem, FileContainerTile, FileContainerWithReference } from '../../../model';
import { FileManagementFilesService } from '../../../api';

@Component( {
    selector:    'rsp-create-zip-from-files-dialog',
    templateUrl: './create-zip-from-files-dialog.component.html',
    styleUrls:   [
        '../../../scss/05_module/standard-dialog.scss',
        './create-zip-from-files-dialog.component.scss',
    ],
} )
export class CreateZipFromFilesDialogComponent implements OnDestroy {
    @ViewChild( 'dialogHeaderTemplate', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContentTemplate', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooterTemplate', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    nameControl: UntypedFormControl = new UntypedFormControl(
        null,
        [ Validators.required, CustomValidators.isValidFilename, CustomValidators.stringNotEmpty ],
    );

    isLoading: Subscription;

    itemType: string;

    private files: Array<FileContainerWithReference | FileContainerTile | FileContainerItem>;
    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private filesApi: FileManagementFilesService,
    ) {
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }


    open( files: Array<FileContainerWithReference | FileContainerTile | FileContainerItem>, type: string = 'Files' ): void {
        this.files = files;
        this.nameControl.setValue( null );

        this.itemType = type;

        const dialogConfig: DialogConfig = {
            headlineTemplate: this.dialogHeaderTemplate,
            contentTemplate:  this.dialogContentTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        };

        this.dialogService.openDialog( dialogConfig );
    }


    close(): void {
        this.dialogService.closeDialog();
    }


    doAction(): void {

        if ( this.nameControl.valid ) {

            const command: CreateFilesExport = {
                name:             this.nameControl.value,
                fileContainerIds: this.files.map( ( file: FileContainerTile | FileContainerWithReference | FileContainerItem ) => file.id ),
            };

            this.isLoading =
                this.filesApi
                    .fileContainersCreateFilesExport( command )
                    .pipe( takeUntil( this.isDestroyed ) )
                    .subscribe( () => {
                        this.notificationService.success( 'ZIP generation triggered.' );
                        this.close();
                    } );
        }
    }

}
