import { Injectable } from '@angular/core';

import { ConceptOrClusterItem } from '../../../../shared/model/conceptOrClusterItem';

@Injectable()
export class ConceptClusterService {

  getConceptOrClusterIdFromConceptOrClusterItem( item: ConceptOrClusterItem ): string {
      if ( item.type === ConceptOrClusterItem.TypeEnum.Concept ) {
          return item.concept.id;
      }
      else if ( item.type === ConceptOrClusterItem.TypeEnum.Cluster ) {
          return item.cluster.id;
      }
  }

}
