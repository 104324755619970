import { AnimationTriggerMetadata, animate, state, style, transition, trigger, } from '@angular/animations';

export const mainSlideInAnimations: Array<AnimationTriggerMetadata> = [

    trigger( 'slideIn', [
        state( 'out', style( { top: '-100vh', } ) ),
        state( 'in',  style( { top: '0',      } ) ),

        // NOTE: if you change the animation duration, you have to update "delay" value in MainSlideInComponent.openSlideIn()
        transition( 'in => out', animate( '400ms ease-in-out' ) ),
        transition( 'out => in', animate( '400ms ease-in-out' ) ),
    ] ),

];

