import { Router } from '@angular/router';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { BaseStoreService } from '../store/base-store.service';
import { DetailPagePrevNextNavigationConfig } from './detail-page-prev-next-navigation-config.model';


export abstract class DetailPagePrevNextNavigationService<T> {
    /**
     * Observable with current navigation configuration;
     */
    readonly config$: Observable<DetailPagePrevNextNavigationConfig>;

    protected lastUrlPart: string;

    private config: BehaviorSubject<DetailPagePrevNextNavigationConfig> = new BehaviorSubject( null );

    private onNextPageLoadedCallback: () => void;


    constructor(
        private router: Router,
        protected storeService: BaseStoreService<T>,
    ) {

        this.config$ = this.config.asObservable();

        // Navigation to the next item can trigger store-service to load next page to the cache.
        // When it is ready, we have to navigate to the next item (onNextPageLoadedCallback).
        // See navigateToNextItem().
        this.storeService
            .items$
            .subscribe( () => {
                if ( this.onNextPageLoadedCallback ) {
                    this.onNextPageLoadedCallback();
                    this.onNextPageLoadedCallback = null;
                }
            });
    }


    /**
     * Sets item which is currently visible on the details page.
     * It is used as calculation base for prev/next navigation.
     */
    setCurrentItem( id: any ): void {

        setTimeout( () => {
            const index: number = this.getItemIndex( id );

            if ( index === -1 ) {
                // hide StoreInfo
                this.config.next( null );
            }
            else {
                this.config.next( {
                    currentItemIndex: index,
                    totalCount:       this.storeService.totalCount(),
                    hasPrevItem:      index !== 0,
                    hasNextItem:      index + 1 < this.storeService.totalCount(),
                } );
            }
        } );
    }

    navigateToPrevItem( itemsToSkip: number = 1 ): void {
        const currentConfig: DetailPagePrevNextNavigationConfig = this.config.getValue();

        if ( currentConfig ) {

            // already first item?
            if ( currentConfig.currentItemIndex === 0 ) {
                return;
            }

            let prevItemIndex: number = currentConfig.currentItemIndex - itemsToSkip;

            if ( prevItemIndex < 0 ) {
                prevItemIndex = 0;  // show first item
            }

            const prevItem: T = this.storeService.getItemByIndex( prevItemIndex );

            if ( prevItem ) {
                this.router.navigate( this.createRouterLinkForItem( prevItem ) );
            }
        }

    }

    navigateToNextItem( itemsToSkip: number = 1 ): void {
        const currentConfig: DetailPagePrevNextNavigationConfig = this.config.getValue();

        if ( currentConfig ) {

            const nextItemIndex: number = currentConfig.currentItemIndex + itemsToSkip;

            const nextItem: T = this.storeService.getItemByIndex( nextItemIndex );

            if ( nextItem ) {
                this.router.navigate( this.createRouterLinkForItem( nextItem ) );
            }
            else {
                // item not found, try to load next page to the cache
                this.registerOnNextPageLoadedCallback( () => this.navigateToNextItem( itemsToSkip ) );
                this.storeService.loadNextPage();
            }
        }
    }

    setLastUrlPart( urlPart: string ): void {
        this.lastUrlPart = urlPart;
    }

    protected abstract getItemIndex( id: string ): number;

    protected abstract createRouterLinkForItem( item: T ): any[];

    private registerOnNextPageLoadedCallback( callback: () => void ): void {
        this.onNextPageLoadedCallback = callback;
    }
}
