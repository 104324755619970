<section class="accordion-group"
     [class.is-open]="isOpen"
     [class.is-disabled]="isDisabled"
>
    <!-- heading -->
    <header class="accordion-group--heading"
         (click)="toggleOpen()"
    >
        <div class="accordion-group--heading-content">
            <ng-container *ngIf="heading">{{ heading }}</ng-container>
            <ng-content *ngIf="!heading" select="[accordion-group-heading]"></ng-content>
        </div>

        <button type="button"
                class="accordion-group--heading-button"
        ></button>
    </header>

    <!-- content -->
    <div
         class="accordion-group--content"
         [ngClass]="{'is-animating-or-closed': isAnimating || !isOpen }"
         [@expandCollapse]="isOpen ? 'visible' : 'hidden'"
         (@expandCollapse.start)="animationStarted()"
         (@expandCollapse.done)="animationEnded()"
    >
        <div [ngStyle]="{'visibility': isAnimating || isOpen ? 'visible' : 'hidden'}" class="accordion-group--content-wrapper">
            <ng-content></ng-content>
        </div>
    </div>
</section>
