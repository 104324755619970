import { Component, } from '@angular/core';

/**
 * Renders a details page.
 *
 * ```html
 *      <rsp-details-page>
 *          <div class="details-page--sidebar">
 *              <!-- put your sidebar content here -->
 *              <ng-content></ng-content>
 *          </div>
 *          <div class="details-page--main">
 *              <!-- main shows breadcrumb and routed content by default -->
 *              <rsp-breadcrumb></rsp-breadcrumb>
 *              <router-outlet></router-outlet>
 *          </div>
 *      </rsp-details-page>
 * ```
 */
@Component( {
    selector:    'rsp-details-page',
    templateUrl: './details-page.component.html',
    styleUrls:   [
        './details-page.component.scss',
    ],
} )
export class DetailsPageComponent {
}

