import { Component, EventEmitter, Input, Output } from '@angular/core';


export type SecondaryToolbarButtonIcon = 'no-icon' | 'selection';


/**
 * Button used inside the secondary-toolbar. It triggers any action.
 *
 * ```html
 * <rsp-secondary-toolbar-button
 *      [label]="'Manage'"
 *      [icon]="'basket'"
 *      [badgeValue]="8"
 *      (click)="doSomething"
 * >
 * </rsp-secondary-toolbar-button>
 * ```
 */
@Component( {
    selector:    'rsp-secondary-toolbar-button',
    templateUrl: './secondary-toolbar-button.component.html',
    styleUrls:   [
        './secondary-toolbar-button.component.scss',
    ],
} )
export class SecondaryToolbarButtonComponent {

    @Input()
    label: string;

    /**
     * Specifies icon to be displayed.
     */
    @Input()
    icon: SecondaryToolbarButtonIcon = 'no-icon';


    /**
     * Number to be displayed as badge. Only numbers greater then 0 are displayed.
     */
    @Input()
    badgeValue: number;

    @Input()
    isActive: boolean;

    /**
     * Event emitted when user clicked the Clear-Button.
     */
    @Output()
    clearButtonClick: EventEmitter<void> = new EventEmitter<void>();

    onClearButtonClick(event: Event): void {
        event.stopPropagation();
        this.clearButtonClick.emit();
    }
}
