/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface NonTradeListItem {
    type?: NonTradeListItem.TypeEnum;

    id?: string;

    number?: string;

    name?: string;

    packagingUnit?: number;

    previewImageId?: string;

    status?: NonTradeListItem.StatusEnum;

    scope?: NonTradeListItem.ScopeEnum;

    hasStyles?: boolean;

    isReadyToOrder?: boolean;

}

export namespace NonTradeListItem {
    'use strict';
    export enum TypeEnum {
        Article,
        Assembly,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
    export enum StatusEnum {
        Unpublished,
        Development,
        ActiveInPreRollout,
        ActiveInRollout,
        ActiveOnDemand,
        Discontinue,
        Inactive,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
    export enum ScopeEnum {
        Global,
        Local,
    }
    export namespace ScopeEnum {
        export function toString( enumValue: ScopeEnum ): string {
            return ScopeEnum[ enumValue ];
        }
    }
}

