import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Provides validation messages.
 */
@Injectable()
export class ValidationMessagesService {

    private static messageTemplates: { [ key: string ]: string } = {
        'required':                       'This field is required.',
        'minlength':                      'This field must be at least __REQUIRED_LENGTH__ characters long.',
        'maxlength':                      'This field cannot be more than __REQUIRED_LENGTH__ characters long.',
        'positiveNumber':                 'This field must contain a positive number.',
        'zeroOrPositiveNumber':           'This field must contain zero or a positive number.',
        'isInteger':                      'Please provide an integer number.',
        'max':                            'The maximum value is __MAX_VALUE__',
        'min':                            'The minimum value is __MIN_VALUE__',
        'stringNotEmpty':                 'This field is required.',
        'distributionChannelRequired':    'At least one distribution channel has to be active.',
        'isNonTradeItemReadyToOrder':     'Only active and discontinue articles can be ordered.',
        'isEmailAddress':                 'This field must contain a valid e-mail address.',
        'isValidFilename':                'Only letters, digits, underscores (_), hyphens (-), dots (.) and whitespace ( ) are allowed.',
        'isDateRangeValid':               '"From date" must be before "until date".',
        'exceedsLimit':                   'The expected value exceeds the limit.',
        'multipleOf':                     'The value has to be a multiple of __MULTIPLIER__.',
        'companyCodeUnique':              'This company code already exists.',
        'countryRequired':                'At least one country is required.',
        'experienceRequired':             'At least one experience is required.',
    };


    /**
     * Gets validation messages for validation errors provided by Form Controls.
     *
     * @param errors
     * @returns {Array<string>}
     */
    getValidationMessages( errors: { [ key: string ]: any } ): Array<string> {

        // console.log( 'Validation Errors: ', errors );

        const messages: Array<string> = [];

        for ( const key in errors ) {

            if ( !errors.hasOwnProperty( key ) ) {
                continue;
            }

            const error: any = errors[ key ];
            let message: string;

            switch ( key ) {

                case 'minlength':
                    message = this.getValidationMessageForMinLength( error.actualLength, error.requiredLength );
                    break;

                case 'maxlength':
                    message = this.getValidationMessageForMaxLength( error.actualLength, error.requiredLength );
                    break;

                case 'max':
                    message = this.getValidationMessageForMaxNumber( error.max );
                    break;

                case 'min':
                    message = this.getValidationMessageForMin( error.min );
                    break;

                case 'multipleOf':
                    message = this.getValidationMessageForMultipleOf( error.multiplier );
                    break;

                default:
                    message = this.getValidationMessageFor( key );
                    break;
            }

            if ( message ) {
                messages.push( message );
            }
        }

        return messages;
    }

    /**
     * Gets validation message for given error key.
     * @param errorKey
     * @returns {string}
     */
    getValidationMessageFor( errorKey: string ): string {
        return ValidationMessagesService.messageTemplates[ errorKey ]
            || 'Message not defined for: ' + errorKey;
    }

    /**
     * Gets validation message for 'minlength' validation error.
     * @param actualLength
     * @param requiredLength
     * @returns {string}
     */
    getValidationMessageForMinLength( actualLength: number, requiredLength: number ): string {

        const message: string = ValidationMessagesService.messageTemplates[ 'minlength' ];

        if ( message ) {
            return message.replace( /__ACTUAL_LENGTH__/g, '' + actualLength )
                          .replace( /__REQUIRED_LENGTH__/g, '' + requiredLength );
        }

        return null;
    }

    /**
     * Gets validation message for 'maxlength' validation error.
     * @param actualLength
     * @param requiredLength
     * @returns {string}
     */
    getValidationMessageForMaxLength( actualLength: number, requiredLength: number ): string {

        const message: string = ValidationMessagesService.messageTemplates[ 'maxlength' ];

        if ( message ) {
            return message.replace( /__ACTUAL_LENGTH__/g, '' + actualLength )
                          .replace( /__REQUIRED_LENGTH__/g, '' + requiredLength );
        }

        return null;
    }


    /**
     * Gets validation message for 'maxNumber' validation error.
     * @param {number} maxValue
     * @returns {string}
     */
    getValidationMessageForMaxNumber( maxValue: number ): string {
        const message: string = ValidationMessagesService.messageTemplates[ 'max' ];

        if ( message ) {
            return message.replace( /__MAX_VALUE__/g, '' + maxValue.toString() );
        }

        return null;
    }

    /**
     * Gets validation message for 'min' validation error.
     * @param {number} minValue
     * @returns {string}
     */
    getValidationMessageForMin( minValue: number ): string {
        const message: string = ValidationMessagesService.messageTemplates[ 'min' ];

        if ( message ) {
            return message.replace( /__MIN_VALUE__/g, '' + minValue.toString() );
        }

        return null;
    }

    /**
     * Gets validation message for 'multipleOf' validation error.
     * @param {number} multiplier
     * @returns {string}
     */
    getValidationMessageForMultipleOf( multiplier: number ): string {
        const message: string = ValidationMessagesService.messageTemplates[ 'multipleOf' ];

        if ( message ) {
            return message.replace( /__MULTIPLIER__/g, '' + multiplier.toString() );
        }

        return null;
    }

    hasControlRequiredConstraint( abstractControl: AbstractControl ): boolean {
        if ( !abstractControl ) {
            return false;
        }

        if ( abstractControl.validator ) {
            const validator: ValidationErrors = abstractControl.validator( {}as AbstractControl );
            if ( validator && validator.required && !abstractControl.valid ) {
                return true;
            }
        }
        if ( abstractControl[ 'controls' ] && !abstractControl.valid ) {
            for ( const controlName in abstractControl[ 'controls' ] ) {
                if ( abstractControl[ 'controls' ][ controlName ] ) {
                    if ( this.hasControlRequiredConstraint( abstractControl[ 'controls' ][ controlName ] ) ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
