import { Component, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ConfirmDialogService } from '../../../core/overlay/confirm-dialog/confirm-dialog.service';

export interface DropDownAction {
    label: string;
    id: string;
}

@Component( {
    selector:    'rsp-form-buttons',
    templateUrl: './edit-form-buttons.component.html',
    styleUrls:   [
        './edit-form-buttons.component.scss',
    ],
} )
export class EditFormButtonsComponent {
    @Input() myFormGroup: UntypedFormGroup;
    @Input() confirmSecondaryEditButtonClick: boolean = false;

    @Input() isEditMode: boolean               = false;
    @Input() disablePrimaryEditButton: boolean = false;
    @Input() actions: DropDownAction[]         = [];
    @Input() singleActionPencil: boolean       = false;

    @Input() actionDropDownLabel: string      = 'Actions';
    @Input() actionButtonLabel: string        = 'Edit';
    @Input() primaryEditButtonLabel: string   = 'Save';
    @Input() secondaryEditButtonLabel: string = 'Cancel';

    @Output() actionButtonClick: EventEmitter<MouseEvent>         = new EventEmitter<MouseEvent>();
    @Output() primaryEditButtonClick: EventEmitter<MouseEvent>    = new EventEmitter<MouseEvent>();
    @Output() secondaryEditButtonClick: EventEmitter<MouseEvent>  = new EventEmitter<MouseEvent>();
    @Output() dropDownActionClicked: EventEmitter<DropDownAction> = new EventEmitter<DropDownAction>();


    constructor(
        private confirmDialogService: ConfirmDialogService,
    ) {}

    actionSelected( action: DropDownAction ): void {
        this.dropDownActionClicked.emit( action );
    }

    onEditClicked( event: MouseEvent ): void {
        this.actionButtonClick.emit( event );
    }

    onSaveClicked( event: MouseEvent ): void {
        this.primaryEditButtonClick.emit( event );
    }

    onCancelClicked( anchor: HTMLElement, event: MouseEvent ): void {
        if ( this.confirmSecondaryEditButtonClick || ( this.myFormGroup && this.myFormGroup.dirty && this.myFormGroup.touched ) ) {
            event.stopPropagation();

            this.confirmDialogService.openConfirmDialog( {
                anchorElement:   anchor,
                confirmCallback: () => this.secondaryEditButtonClick.emit( event ),
                text:            'Are you sure? Changes will be lost.',
            } );
        }
        else {
            this.secondaryEditButtonClick.emit( event );
        }
    }

    saveDisabled  (): boolean  {
        return this.disablePrimaryEditButton || this.myFormGroup && !this.myFormGroup.valid;
    }
}
