import { platformBrowserDynamic, } from '@angular/platform-browser-dynamic';
import { enableProdMode, } from '@angular/core';
import { environment, } from './environments/environment';
import { AppModule, } from './app/app.module';

if ( environment.isAngularProdModeOn ) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule( AppModule )
                        .catch( ( err: any ) => console.error( err ));
