<ng-container *ngIf="!isEditMode">
    <rsp-not-available *ngIf="!materials?.length && !materialInfo">
    </rsp-not-available>

    <table *ngIf="materials?.length" class="display-edit-materials--material-groups-wrapper">
        <thead hidden>
            <tr>
                <th>Percentage</th>
                <th>Material</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let material of materials">
                <td class="display-edit-materials--is-table-cell-right-aligned">
                    <ng-container *ngIf="material.percentage">{{material.percentage}}%</ng-container>
                </td>
                <td>{{material.name}}</td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="materialInfo" class="display-edit-materials--material-info-section">
        <h4 class="display-edit-materials--material-info-section-headline">Material Info:</h4>
        <rsp-display-edit-text-multiline
            [value]="materialInfo"
            [isEditMode]="false"
        ></rsp-display-edit-text-multiline>
    </div>
</ng-container>
<ng-container *ngIf="isEditMode">
    <div
        *ngIf="myFormGroup.get('materials')?.controls.length || materialListValidationMessages.length"
        class="display-edit-materials--material-groups-wrapper"
    >
        <ul
            *ngIf="myFormGroup.get('materials')?.controls.length"
            [formGroup]="myFormGroup.get('materials')"
            class="display-edit-materials--material-groups-list is-editable"
        >
            <ng-container *ngFor="let material of myFormGroup.controls.materials.controls; let i=index">
                <li
                    [formGroupName]="i"
                    class="display-edit-materials--material-group"
                >
                    <rsp-edit-material
                        [group]="myFormGroup.controls.materials.controls[i]"
                        [validationMessagesCssSubmodule]="editMaterialMessagesCssSubmodule.TransparentEditMaterial"
                        (selected)="updateMaterial( $event, myFormGroup.controls.materials.controls[i] )"
                        (validationMessagesChanged)="validationMessagesForMaterial[ i ] = $event"
                        (materialRemoved)="removeMaterial(i)"
                    ></rsp-edit-material>

                </li>
            </ng-container>
        </ul>

        <rsp-messages
            [type]="messageTypeError"
            [messages]="materialListValidationMessages"
            [cssSubmodule]="materialListMessagesCssSubmodule"
        >
        </rsp-messages>
    </div>

    <div class="display-edit-materials--material-info-section">
        <label [for]="materialInfoHtmlId" class="display-edit-materials--material-info-section-headline">Additional Info:</label>
        <div class="display-edit-materials--material-info-section-content">
            <rsp-display-edit-text-multiline
                [value]="materialInfo"
                [myFormGroup]="myFormGroup"
                [isEditMode]="true"
                [name]="'materialInfo'"
                [inputFieldHtmlId]="materialInfoHtmlId"
            ></rsp-display-edit-text-multiline>
        </div>
    </div>
</ng-container>
