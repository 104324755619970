import {
    AfterContentChecked, AfterContentInit, Component, ElementRef, Input, OnDestroy, OnInit, Renderer2,
    ViewChild,
} from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { Subscription ,  fromEvent, merge } from 'rxjs';

import { VerticalScrollService } from './vertical-scroll.service';
import { WindowEventService } from '../../../../core/window-event.service';
import { ContainerDimension } from '../../scroll/scroll.service';
import { distinctUntilChanged, map } from 'rxjs/operators';


/**
 * ```html
 *      <div class="wrapper">
 *          <rsp-vertical-scroll>
 *              <div>Some content that likes to get scrolled with our scrollbar implementation.</div>
 *          </rsp-vertical-scroll>
 *     </div>
 * ```
 *
 * The wrapper defines the scrolling space for <rsp-vertical-scroll>, so its dimensions are vital. Some `overflow` value != `visible` may
 * help <rsp-vertical-scroll> calculating everything correctly.
 */
@Component( {
    selector:    'rsp-vertical-scroll',
    templateUrl: './vertical-scroll.component.html',
    styleUrls:   [
        './vertical-scroll.component.scss',
    ],
    animations:  [
        trigger( 'enterAnimation', [
            transition( ':enter', [
                style( { opacity: 0 } ),
                animate( 100, style( { opacity: 1 } ) ),
            ] ),
            transition( ':leave', [
                style( { opacity: 1, } ),
                animate( 100, style( { opacity: 0 } ) ),
            ] ),
        ] ),
    ],
} )
export class VerticalScrollComponent implements OnInit, AfterContentInit, AfterContentChecked, OnDestroy {
    @ViewChild( 'scrollContainer', { static: true } ) scrollContainer: ElementRef;
    @ViewChild( 'heightContainer', { static: true } ) heightContainer: ElementRef;
    @ViewChild( 'scrollbarMeasure', { static: true } ) scrollbarMeasure: ElementRef;


    /**
     * By default this component tries to determine, when to calculate the scroll-height and show the scroll up / down buttons.
     * In some cases, this does not work sufficiently. If the content within this component is dynamic (e.g. with expandable items), this component may not
     * detect changes and the calculation will fail. For this case, autodetect mode can be switched off and the vertical scroll service can be used solely.
     *
     * @type {boolean}
     */
    @Input() autodetect: boolean = true;

    scrollable: boolean       = false;
    scrolledToTop: boolean    = true;
    scrolledToBottom: boolean = false;
    showButtons: boolean      = false;

    private height: number                      = 0;
    private readonly defaultScrollRatio: number = 10;
    private scrollBarWidth: number;
    private scrollRatio: number                 = this.defaultScrollRatio;
    private subscription: Subscription          = new Subscription();

    constructor(
        private element: ElementRef,
        private renderer: Renderer2,
        private verticalScrollService: VerticalScrollService,
        private windowEventService: WindowEventService,
    ) {
    }

    ngOnInit(): void {
        this.renderer.addClass( this.element.nativeElement.parentNode, 'vertical-scroll-container' );
    }

    ngAfterContentInit(): void {
        if ( this.autodetect ) {
            this.setContainerDimension();
        }
        this.setScrollBarWidth();

        this.subscription.add(
            fromEvent( this.scrollContainer.nativeElement, 'scroll' ).subscribe( () => {
                this.checkScrollIconVisibility();
            } ),
        );

        this.subscription.add(
            merge(
                fromEvent( this.element.nativeElement, 'mouseover' ),
                fromEvent( this.element.nativeElement, 'mouseleave' ),
            )
                .pipe(
                    map( ( event: MouseEvent ) => event.type ),
                    distinctUntilChanged(),
                )
                .subscribe( ( type: string ) => {
                    this.showButtons = type === 'mouseover';
                } ),
        );

        this.subscription.add(
            this.verticalScrollService.calculateScroll$.subscribe( () => {
                this.calculateScroll();
            } ),
        );


        this.subscription.add(
            this.windowEventService.resized$.subscribe( () => {
                this.renderer.removeStyle( this.scrollContainer.nativeElement, 'height' );
                this.renderer.removeStyle( this.element.nativeElement, 'height' );

                this.calculateScroll();
            } ),
        );

    }

    ngAfterContentChecked(): void {
        if ( !this.autodetect ) {
            return;
        }

        this.calculateScroll();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }


    scrollUp(): void {
        this.scrollContainer.nativeElement.scrollTop =
            this.scrollContainer.nativeElement.scrollTop >= this.scrollRatio ? this.scrollContainer.nativeElement.scrollTop - this.scrollRatio : 0;

        this.checkScrollIconVisibility();
    }

    scrollDown(): void {
        this.scrollContainer.nativeElement.scrollTop = this.scrollContainer.nativeElement.scrollTop + this.scrollRatio;

        this.checkScrollIconVisibility();
    }

    private calculateScroll(): void {

        this.setContainerDimension();

        const scrollHeight: number = this.heightContainer.nativeElement.getBoundingClientRect().height;

        this.scrollable = scrollHeight > this.height;

        if ( scrollHeight ) {
            const ratio: number = Math.ceil( (this.height / scrollHeight) * 100 );
            this.scrollRatio    = ratio >= this.defaultScrollRatio ? ratio : this.defaultScrollRatio;
        }

        this.checkScrollIconVisibility();
    }


    private checkScrollIconVisibility(): void {
        if ( !this.scrollable ) {
            this.renderer.removeClass( this.element.nativeElement, 'has-top-scroll-button' );
            this.renderer.removeClass( this.element.nativeElement, 'has-bottom-scroll-button' );
            return;
        }

        const scrollTop: number = this.scrollContainer.nativeElement.scrollTop;
        if ( scrollTop > 0 ) {
            this.scrolledToTop = false;
            this.renderer.addClass( this.element.nativeElement, 'has-top-scroll-button' );
        }
        else {
            this.scrolledToTop = true;
            this.renderer.removeClass( this.element.nativeElement, 'has-top-scroll-button' );
        }

        if ( scrollTop >= Math.floor( this.heightContainer.nativeElement.getBoundingClientRect().height - this.height ) ) {
            this.scrolledToBottom = true;
            this.renderer.removeClass( this.element.nativeElement, 'has-bottom-scroll-button' );
        }
        else {
            this.scrolledToBottom = false;
            this.renderer.addClass( this.element.nativeElement, 'has-bottom-scroll-button' );
        }
    }

    private setScrollBarWidth(): void {
        const scrollBarWidth: number = this.scrollbarMeasure.nativeElement.offsetWidth - this.scrollbarMeasure.nativeElement.clientWidth;
        this.scrollBarWidth          = scrollBarWidth;
        this.renderer.removeChild( this.element.nativeElement, this.scrollbarMeasure.nativeElement );
    }


    private setContainerDimension(): void {
        const dimension: ContainerDimension = this.findParentDimension( this.element.nativeElement );
        this.height                         = parseInt( dimension.height + '', 10 ); // parseInt to avoid subpixel rounding issues
        this.renderer.setStyle( this.element.nativeElement, 'height', this.height + 'px' );
        this.renderer.setStyle( this.scrollContainer.nativeElement, 'height', this.height + 'px' );

        // Enforce a width. Browsers shrink the width automatically when something gets a scrollbar, so setting a fixed width is important here.
        this.renderer.setStyle( this.heightContainer.nativeElement, 'width', dimension.width + 'px' );

        this.renderer.setStyle( this.scrollContainer.nativeElement, 'width', (dimension.width + this.scrollBarWidth) + 'px' );
    }

    private findParentDimension( sourceElement: any ): ContainerDimension {

        const parent: HTMLElement         = sourceElement.parentElement;
        const dimension: ClientRect       = parent.getBoundingClientRect();
        const styles: CSSStyleDeclaration = window.getComputedStyle( parent );

        return {
            height: dimension.height - parseInt( styles.borderTopWidth, 10 ) - parseInt( styles.borderBottomWidth, 10 ) - parseInt( styles.paddingTop, 10 )
                    - parseInt( styles.paddingBottom, 10 ),
            width:  dimension.width - parseInt( styles.borderLeftWidth, 10 ) - parseInt( styles.borderRightWidth, 10 ) - parseInt( styles.paddingLeft, 10 )
                    - parseInt( styles.paddingRight, 10 ),
        };
    }

}
