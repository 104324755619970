import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { KeywordInfo } from '../../../model/keywordInfo';

@Component( {
    selector:    'rsp-edit-keywords',
    templateUrl: './edit-keywords.component.html',
    styleUrls:   [
        './edit-keywords.component.scss',
    ],
} )
export class EditKeywordsComponent implements OnInit {
    @Input() myFormControl: UntypedFormControl;

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    keywordIds: Array<string> = [];

    @Input() keywords: Array<KeywordInfo>;

    @Input() keywordsGroupName: string;


    static buildFormControl( keywords: Array<KeywordInfo>, validators?: Array<ValidatorFn> ): UntypedFormControl {

        let keywordIds: Array<string> = [];

        if ( keywords ) {
            keywordIds = keywords.map( ( keyword: KeywordInfo ) => keyword.id );
        }

        return new UntypedFormControl( keywordIds, validators );
    }


    ngOnInit(): void {
        if ( !this.myFormControl ) {
            throw new Error( 'Attribute "myFormControl" must be set!' );
        }
    }


    removeKeyword( keyword: KeywordInfo ): void {
        const index: number = this.keywords.findIndex( ( old: KeywordInfo ) => { return old.name === keyword.name; } );

        if ( index !== -1 ) {
            this.keywords.splice( index, 1 );
            this.updateCurrentKeywordIds();
        }
    }


    addKeyword( keywordSuggestItem: KeywordInfo ): void {
        // should use this, but fails in tests
        // this.keywords.push( {
        //     name: keywordSuggestItem.name,
        //     id: keywordSuggestItem.keywordId,
        // } );

        const newKeywords: Array<KeywordInfo> = this.keywords;
        newKeywords.push( {
            name: keywordSuggestItem.name,
            id:   keywordSuggestItem.id,
        } );
        this.keywords = newKeywords;
        this.updateCurrentKeywordIds();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private updateCurrentKeywordIds(): void {
        if ( this.keywords && this.keywords.length ) {
            this.keywordIds = this.keywords.map( ( keyword: KeywordInfo ) => { return keyword.id; } );
        }
        else {
            this.keywordIds = [];
        }
        this.myFormControl.setValue( this.keywordIds );
    }
}
