<div class="radio-button-group">
    <div class="radio-button-group--label" *ngIf="label">
        {{ label }}
    </div>

    <div class="radio-button-group--radios">
        <ng-container *ngFor="let radio of values; let i = index">
            <input
                [attr.required]="isRequired || null"
                [attr.checked]="radio.id === selectedValue || null"
                [name]="name"
                [value]="radio.id"
                (change)="setValue( radio.id )"
                type="radio"
                class="radio-button-group--radio-input"
                id="{{ radioHtmlIds[ i ] }}"
            >

            <label
                [class.is-first-radio]="i === 0"
                class="radio-button-group--radio-label"
                for="{{ radioHtmlIds[ i ] }}"
                tabindex="1"
            >
                {{ radio.caption }}
            </label>
        </ng-container>
    </div>
</div>

<rsp-messages
    *ngIf="validationMessages.length > 0"
    [type]="messageTypeError"
    [messages]="validationMessages"
></rsp-messages>
