import { Component, Input, ViewEncapsulation, } from '@angular/core';

/**
 * Echoes a facet name, but may add some (HTML)-transformation.
 *
 * ```html
 * <rsp-facet-name facetName="Creation Date"></rsp-facet-name>
 *      → Creation Date
 *
 * <rsp-facet-name facetName="SPM Group"></rsp-facet-name>
 *      → <abbr title="Single Product Master">SPM</abbr> Group
 *
 * ```
 */
@Component( {
    selector:    'rsp-facet-name',
    templateUrl: './facet-name.component.html',
    styleUrls:   [
        './facet-name.component.scss',
    ],
    encapsulation: ViewEncapsulation.None, // 4 easier unit tests (ng2 will not put _ngcontent-attributes in the output)
} )
export class FacetNameComponent {
    @Input() facetName: string;
}
