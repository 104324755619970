import { ConceptServiceLevel, ServiceLevelItem as ServiceLevelItemApi } from '../../model';

/**
 * Represents Service Level in the Concept Service level table.
 */
export class ServiceLevelItem {

    name: string;

    hasLevel1: boolean;
    hasLevel2: boolean;
    hasLevel3: boolean;
    hasLevel4: boolean;
    hasLevel5: boolean;
    hasLevel6: boolean;

    constructor(
        name: string,
        hasLevel1: boolean,
        hasLevel2: boolean,
        hasLevel3: boolean,
        hasLevel4: boolean,
        hasLevel5: boolean,
        hasLevel6: boolean,
    ) {

        this.name      = name;
        this.hasLevel1 = hasLevel1;
        this.hasLevel2 = hasLevel2;
        this.hasLevel3 = hasLevel3;
        this.hasLevel4 = hasLevel4;
        this.hasLevel5 = hasLevel5;
        this.hasLevel6 = hasLevel6;
    }


    /**
     * Creates new empty ServiceLevelItem
     */
    static createNewServiceLevel(): ServiceLevelItem {
        return new ServiceLevelItem(
            '',
            false,
            false,
            false,
            false,
            false,
            false,
        );
    }

    /**
     * Creates ServiceLevelItem from ConceptServiceLevel returned by the backend.
     */
    static createFromConceptServiceLevel( item: ConceptServiceLevel ): ServiceLevelItem {
        return new ServiceLevelItem(
            item.name,
            item.hasLevel1,
            item.hasLevel2,
            item.hasLevel3,
            item.hasLevel4,
            item.hasLevel5,
            item.hasLevel6,
        );
    }

    /**
     * Creates ServiceLevelItem from object with same name (?!) returned by the backend.
     */
    static createFromApiServiceLevelItem( item: ServiceLevelItemApi ): ServiceLevelItem {
        return new ServiceLevelItem(
            item.name,
            item.hasLevel1,
            item.hasLevel2,
            item.hasLevel3,
            item.hasLevel4,
            item.hasLevel5,
            item.hasLevel6,
        );
    }


    /**
     * Specifies if this Service Level is available for given Service Model's assigned level.
     * @returns {boolean}
     */
    isLevelAvailableFor( serviceModelAssignedLevel: number ): boolean {

        switch ( serviceModelAssignedLevel ) {
            case 1:
                return this.hasLevel1;
            case 2:
                return this.hasLevel2;
            case 3:
                return this.hasLevel3;
            case 4:
                return this.hasLevel4;
            case 5:
                return this.hasLevel5;
            case 6:
                return this.hasLevel6;
            default:
                throw Error( 'Level ' + serviceModelAssignedLevel + ' not supported.' );
        }
    }


    /**
     * Specifies if service level is valid and can be saved.
     */
    isValid(): boolean {
        return this.name !== '';
    }
}
