import { AnimationTriggerMetadata, animate, state, style, transition, trigger, } from '@angular/animations';

const animationDuration: string = '0.1s';

export const facetAnimations: Array<AnimationTriggerMetadata> = [
    trigger( 'togglerRotated', [
        state( '0', style( { transform: 'rotate(0deg)',  } ) ),
        state( '1', style( { transform: 'rotate(45deg)', } ) ),
        transition( '1 => 0', animate( animationDuration + ' linear' ) ),
        transition( '0 => 1', animate( animationDuration + ' linear' ) ),
    ] ),
    trigger( 'bodyExpanded', [
        state( '0', style( { height: 0,   overflow: 'hidden', } ) ),
        state( '1', style( { height: '*', overflow: 'hidden', } ) ),
        transition( '1 => 0', animate( animationDuration ) ),
        transition( '0 => 1', animate( animationDuration ) ),
    ] ),

    trigger( 'keywordsExpand', [
        state( '0', style( { height: '0px', overflow: 'hidden', } ) ),
        state( '1', style( { height: '*',   overflow: 'hidden', } ) ),
        transition( '1 => 0', animate( '0.2s' ), ),
        transition( '0 => 1', animate( '0.2s' ), ),
    ] ),
];

