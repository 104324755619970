
<span *ngIf="showEmptyText && ( !tags?.length || tags?.length == 0 )">
    {{ emptyText }}
</span>

<ng-container *ngFor="let tag of tags">

    <ng-container *ngIf="tag.isHighPrioTag">
        <span rspKeyword>{{ tag.label }}</span>
    </ng-container>

    <ng-container *ngIf="!tag.isHighPrioTag">
        <span rspKeyword>{{ tag.label }}</span>
    </ng-container>

</ng-container>
