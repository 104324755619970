/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface BatchImportItem {
    fullName?: string;

    fileKindId?: string;

    fileContainerId?: string;

    itemId?: string;

    itemType?: BatchImportItem.ItemTypeEnum;

    localFilePath?: string;

    status?: BatchImportItem.StatusEnum;

}

export namespace BatchImportItem {
    'use strict';
    export enum ItemTypeEnum {
        FileContainer,
        Article,
        Assembly,
        SubComponent,
    }
    export namespace ItemTypeEnum {
        export function toString( enumValue: ItemTypeEnum ): string {
            return ItemTypeEnum[ enumValue ];
        }
    }
    export enum StatusEnum {
        Registrated,
        Downloaded,
        Stored,
        Processed,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

