
<ul class="breadcrumb">
    <li style="display: flex">
        <span class="breadcrumb--back-icon"></span>
        <span class="breadcrumb--back-link" (click)="goBack()">back</span>
        <span class="breadcrumb--back-separator"></span>
    </li>
    <li
        *ngFor="let part of breadcrumbParts | async; let isLast = last"
        class="breadcrumb--crumb"
    >

        <ng-container
                *ngIf="!isLast && part.link"
        >
            <a
                [routerLink]="part.link"
                [queryParams]="part.linkParams"
                class="breadcrumb--crumb-link"
            >
                {{ part.label }}
            </a>

            <span class="breadcrumb--separator"></span>
        </ng-container>

        <ng-container
            *ngIf="!isLast && !part.link"
        >
            <span class="breadcrumb--crumb-inactive">{{ part.label }}</span>
            <span class="breadcrumb--separator-inactive"></span>
        </ng-container>

        <ng-container *ngIf="isLast">{{ part.label }}</ng-container>
    </li>
</ul>
