<rsp-registration-wizard-section-headline [isDone]="form.valid">
    My Profile
</rsp-registration-wizard-section-headline>

<rsp-registration-wizard-section-content>
    <div class="l-two-columns-1200">
        <div class="l-two-columns-1200--column">
            <div class="l-two-columns-1200--column-content-gutter-item">
                <label
                    [for]="htmlIdFor.get( 'profileImageField' )"
                    class="registration-wizard-my-profile--top-aligned-label"
                >
                    <rsp-supplementary-caption>Profile Image</rsp-supplementary-caption>
                </label>

                <rsp-display-edit-user-profile-image
                    [isEditMode]="true"
                    [myFormControl]="form.get( 'profileImageFileContainerId' )"
                    [user]="userData"
                    [inputFieldHtmlId]="htmlIdFor.get( 'profileImageField' )"
                    (fileUpdated)="setFileContainerId( $event )"
                >
                    click here or drop file
                </rsp-display-edit-user-profile-image>
            </div>

             <div class="l-two-columns-1200--column-content-gutter-item">
                 <label
                     [for]="htmlIdFor.get( 'phoneNumberField' )"
                     class="registration-wizard-my-profile--top-aligned-label"
                 >
                    <rsp-supplementary-caption>Phone</rsp-supplementary-caption>
                 </label>

                 <rsp-display-edit-phone-number
                    [myFormGroup]="form"
                    [name]="'phoneNumber'"
                    [isEditMode]="true"
                    [inputFieldHtmlId]="htmlIdFor.get( 'phoneNumberField' )"
                 ></rsp-display-edit-phone-number>
             </div>

             <div class="l-two-columns-1200--column-content-gutter-item">
                 <rsp-supplementary-caption class="registration-wizard-my-profile--top-aligned-label">
                     My Module Group
                     <rsp-info-tooltip>These module groups define your access rights.</rsp-info-tooltip>
                 </rsp-supplementary-caption>

                 <ng-container *ngFor="let moduleGroup of moduleGroups">
                     <label class="registration-wizard-my-profile--checkbox-wrapper">
                         <input
                             rspCheckbox
                             [rspCheckboxWrapperClass]="'registration-wizard-my-profile--checkbox'"
                             [checked]="isModuleGroupAssigned( moduleGroup )"
                             (change)="toggleModuleGroup( moduleGroup )"
                             type="checkbox"
                         >

                         {{ moduleGroup.name }}
                     </label>
                 </ng-container>

                 <rsp-messages
                     *ngIf="this.userData.moduleGroups.length === 0"
                     [type]="messageType.Error"
                     [messages]="[ 'Please choose at least one module group.' ]"
                 ></rsp-messages>
             </div>
        </div>

         <div class="l-two-columns-1200--column">
             <div class="l-two-columns-1200--column-content-gutter-item">
                 <rsp-supplementary-caption class="registration-wizard-my-profile--top-aligned-label">Global ID</rsp-supplementary-caption>

                 {{ userData.globalId }}
             </div>

             <div class="l-two-columns-1200--column-content-gutter-item">
                 <rsp-supplementary-caption class="registration-wizard-my-profile--top-aligned-label">First Name</rsp-supplementary-caption>

                 <ng-container *ngIf="userData.firstName">{{ userData.firstName }}</ng-container>
                 <rsp-not-available *ngIf="!userData.firstName"></rsp-not-available>
             </div>

             <div class="l-two-columns-1200--column-content-gutter-item">
                 <rsp-supplementary-caption class="registration-wizard-my-profile--top-aligned-label">Family Name</rsp-supplementary-caption>

                 {{ userData.familyName }}
             </div>

             <div class="l-two-columns-1200--column-content-gutter-item">
                 <rsp-supplementary-caption class="registration-wizard-my-profile--top-aligned-label">Email</rsp-supplementary-caption>

                 {{ userData.email }}
             </div>
         </div>
     </div>
</rsp-registration-wizard-section-content>
