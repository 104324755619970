<div class="tab">

    <div *ngIf="userHasEditRight" class="tab--toolbar">
        <rsp-form-buttons
            [isEditMode]="isEditMode"
            [myFormGroup]="form"
            [disablePrimaryEditButton]="isLoadingSave"
            (actionButtonClick)="startEditMode()"
            (primaryEditButtonClick)="submitForm()"
            (secondaryEditButtonClick)="endEditMode()"
            class="tab--toolbar-item is-right"
        ></rsp-form-buttons>
    </div>

    <div class="tab--content" [rspLoadingIndicator]="isLoading">
        <ng-container *ngIf="conceptDetails">
            <div *ngIf="isEditMode || conceptDetails.note"
                 class="l-two-columns-1200"
            >
                <div class="l-two-columns-1200--span-column">
                    <div class="l-two-columns-1200--column-content-gutter-item">
                        <!-- Note -->
                        <rsp-card [type]="cardType.Note">
                            <rsp-card-headline>Note</rsp-card-headline>
                            <rsp-card-content>
                                <rsp-display-edit-text-multiline
                                    [value]="conceptDetails.note"
                                    [myFormGroup]="form"
                                    [isEditMode]="isEditMode"
                                    [name]="'note'"
                                ></rsp-display-edit-text-multiline>
                            </rsp-card-content>
                        </rsp-card>
                    </div>
                </div>
            </div>

            <div class="l-two-columns-1200">

                <!-- Left column -->
                <div class="l-two-columns-1200--column">

                    <!-- Name -->
                    <rsp-card>
                        <rsp-card-headline>Name</rsp-card-headline>
                        <rsp-card-content>
                            <rsp-display-edit-text
                                [myFormGroup]="form"
                                [name]="'name'"
                                [value]="conceptDetails.name"
                                [isEditMode]="isEditMode"
                            ></rsp-display-edit-text>
                        </rsp-card-content>
                    </rsp-card>

                    <!-- Description -->
                    <rsp-card>
                        <rsp-card-headline>Description</rsp-card-headline>
                        <rsp-card-content>
                            <rsp-display-edit-text-multiline
                                [myFormGroup]="form"
                                [name]="'description'"
                                [value]="conceptDetails.description"
                                [isEditMode]="isEditMode"
                            ></rsp-display-edit-text-multiline>
                        </rsp-card-content>
                    </rsp-card>
                </div>

                <!-- Right column -->
                <div class="l-two-columns-1200--column">

                    <!-- Keywords -->
                    <rsp-card>
                        <rsp-card-headline>Keywords</rsp-card-headline>
                        <rsp-card-content>
                            <rsp-tags
                                *ngIf="!isEditMode"
                                [tags]="getKeywordsAsTags()"
                            ></rsp-tags>
                            <rsp-edit-keywords
                                *ngIf="isEditMode"
                                [myFormControl]="form?.get('keywordIds')"
                                [keywords]="conceptDetails.keywords"
                                [keywordsGroupName]="'Concepts & Clusters'"
                            ></rsp-edit-keywords>
                        </rsp-card-content>
                    </rsp-card>

                </div>
            </div>

            <div class="l-two-columns-1200">

                <!-- Left column -->
                <div class="l-two-columns-1200--column">

                    <!-- Brand & Subbrand -->
                    <rsp-card>
                        <rsp-card-headline>Brand & Subbrand</rsp-card-headline>
                        <rsp-card-content>
                            <ng-container *ngIf="!isEditMode">
                                <rsp-not-available *ngIf="!conceptDetails.brandInfo && !conceptDetails.subBrandInfo">
                                </rsp-not-available>
                                <ng-container *ngIf="conceptDetails.brandInfo">
                                    {{conceptDetails.brandInfo.name}}
                                </ng-container>
                                <ng-container *ngIf="conceptDetails.subBrandInfo">
                                    <br>
                                    {{conceptDetails.subBrandInfo.name}}
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="isEditMode">
                                <rsp-concept-brand-and-subbrand-edit
                                    [brandFormControl]="form?.get('brandId')"
                                    [subBrandFormControl]="form?.get('subbrandId')"
                                    [brandInfo]="conceptDetails.brandInfo"
                                    [subBrandInfo]="conceptDetails.subBrandInfo"
                                ></rsp-concept-brand-and-subbrand-edit>
                            </ng-container>
                        </rsp-card-content>
                    </rsp-card>
                </div>
            </div>
        </ng-container>
    </div>

</div>
