import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';

import { NotificationService } from '../overlay/notification/notification.service';
import { AuthenticationService } from '../authentication.service';
import { catchError } from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private notificationService: NotificationService,
        private router: Router,
        private injector: Injector,
    ) { }

    intercept( req: HttpRequest<any>, next: HttpHandler ): Observable<HttpEvent<any>> {
        return next.handle( req ).pipe(
            catchError( ( error: HttpErrorResponse ) => {
                switch ( error.status ) {
                    case 401:
                        // TODO: this is just for convenience at the moment, to prevent "timeouts" for the current identity server session

                        const authenticationService: AuthenticationService = this.injector.get( AuthenticationService );
                        authenticationService.login();

                        return next.handle( req );
                    case 404:
                        // console.log('intercept');
                        // TODO: This is too global, because a 404 may be a valid response to a normal webApi request
                        // (like a basket that doesn't exist anymore).
                        //       We probably should do something like this in our route-resolvers.
                        if ( !error.url.endsWith('api/v1/usermanagement/current-user') ) {
                            // console.log('not found');
                            this.router.navigate( [ 'object-not-found' ] );
                            return throwError( error );
                        }
                        else {
                            // console.log('user not found');
                            this.router.navigateByUrl( '/registration' );
                            return throwError( error );
                        }
                    default:
                        this.notificationService.error(
                            error.statusText.toString() + ' (' + error.status.toString() + ')',
                            error.status === 0 ? 'The server can not be reached' : 'Sorry, a server error occurred',
                        );

                        return throwError( error || 'Server error' );
                }
            } ),
        );
    }
}
