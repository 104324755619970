import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { StoreInfo } from '../../../../../shared/ui/lists/store/store-info.model';
import { ConceptClusterStoreService } from '../concept-cluster-store.service';
import { ConceptClusterSelectionService } from '../concept-cluster-selection.service';
import { ConceptOrClusterItem } from '../../../../../shared/model/conceptOrClusterItem';
import { MainSlideInService } from '../../../../../core/overlay/main-slide-in/main-slide-in.service';
import { SecondaryToolbarService } from '../../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { ToolbarBaseComponent } from '../../../../../shared/ui/secondary-toolbar/toolbar-base.component';

/**
 * Concept / Cluster function toolbar. Placed in master-head as secondary-toolbar.
 */
@Component( {
    selector:    'rsp-concept-cluster-toolbar',
    templateUrl: './concept-cluster-toolbar.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/secondary-toolbar.scss',
        './concept-cluster-toolbar.component.scss',
    ],
} )
export class ConceptClusterToolbarComponent extends ToolbarBaseComponent {

    storeInfo: Observable<StoreInfo>;

    selectedItems: Observable<Array<ConceptOrClusterItem>>;

    isSelectionAvailable: Observable<boolean>;

    constructor(
        protected storeService: ConceptClusterStoreService,
        private selectionService: ConceptClusterSelectionService,
        toolbarService: SecondaryToolbarService,
        router: Router,
        activatedRoute: ActivatedRoute,
        mainSlideInService: MainSlideInService,
    ) {
        super( storeService, router, activatedRoute, mainSlideInService, toolbarService );

        this.storeInfo            = this.storeService.storeInfo$;
        this.selectedItems        = this.selectionService.selectedItems$;
        this.isSelectionAvailable = this.selectionService.isSelectionAvailable$;
    }

    clearSelection(): void {
        this.selectionService.clearSelection();
    }
}
