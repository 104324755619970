import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { mainMenuAnimations } from './main-menu.animations';
import { MainMenuService } from './main-menu.service';
import { BackdropService } from '../overlay/backdrop/backdrop.service';
import { AuthenticationService } from '../authentication.service';
import { CurrentUserService } from '../current-user.service';
import { CurrentUserContext } from '../../shared/model';
import { LegalNoticeDialogComponent } from '../../shared/ui/legal-notice-dialog/legal-notice-dialog.component';
import { ImprintDialogComponent } from '../../shared/ui/imprint-dialog/imprint-dialog.component';


/**
 * Collapsable main menu.
 * Use @see MainMenuService to control Main Menu from outside.
 */
@Component( {
    selector:    'rsp-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls:   [
        './main-menu.component.scss',
    ],
    animations:  mainMenuAnimations,
} )
export class MainMenuComponent implements OnInit, OnDestroy {
    @ViewChild( LegalNoticeDialogComponent, { static: true } ) legalNoticeDialog: LegalNoticeDialogComponent;
    @ViewChild( ImprintDialogComponent, { static: true } ) imprintDialog: ImprintDialogComponent;

    @Input()
    isVisible: boolean;

    mainMenuEntries: { description: string, url: string }[] = [];

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private backdropService: BackdropService,
        private mainMenuService: MainMenuService,
        private authenticationService: AuthenticationService,
        private currentUserService: CurrentUserService,
    ) {

        this.mainMenuService
            .isOpen$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( value: boolean ) => {

                this.isVisible = value;

                if ( value ) {
                    this.backdropService.showBackdrop();
                }
                else {
                    this.backdropService.hideBackdrop();
                }

            } );
    }

    ngOnInit(): void {
        this.currentUserService.getCurrentUserAccessRightSet().pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                this.mainMenuEntries = [];

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesBrowse ) ) {
                    this.mainMenuEntries.push( {
                        url:         '/articles-and-assemblies',
                        description: 'Articles & Assemblies',
                    } );
                }
                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersBrowse ) ) {
                    this.mainMenuEntries.push( {
                        url:         '/concepts-and-clusters',
                        description: 'Concepts & Clusters',
                    } );
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.DocumentsBrowse ) ) {
                    this.mainMenuEntries.push( {
                        url:         '/documents',
                        description: 'Documents',
                    } );
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.UsersBrowse ) ) {
                    this.mainMenuEntries.push( {
                        url:         '/users',
                        description: 'Users',
                    } );
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.AdministrationIsAdministrator ) ) {
                    this.mainMenuEntries.push( {
                        url:         '/administration',
                        description: 'Administration',
                    } );
                }
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    closeMenu( event?: Event ): void {
        const target: HTMLElement = event ? event.target as HTMLElement : null;
        if ( target && target.classList.contains( 'master-head--main-menu-button' ) ) {
            return;
        }

        this.mainMenuService.close();
    }

    logout(): void {
        this.authenticationService.logoff();
    }

    openLegalNotice(): void {
        this.legalNoticeDialog.openDialog();
        // close the side menu but keep showing the backdrop
        this.mainMenuService.close();
        this.backdropService.showBackdrop();
    }

    openImprint(): void {
        this.imprintDialog.openDialog();
        // close the side menu but keep showing the backdrop
        this.mainMenuService.close();
        this.backdropService.showBackdrop();
    }
}
