/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { BrowsingConfiguration } from './browsingConfiguration';


export interface CreateNonTradeItemExtract {
    configuration?: BrowsingConfiguration;

    fileName?: string;

    articleIds?: Array<string>;

    assemblyIds?: Array<string>;

    sheets?: Array<CreateNonTradeItemExtract.SheetsEnum>;

    jobId?: string;

    userTimeZoneInfo?: string;

}

export namespace CreateNonTradeItemExtract {
    'use strict';
    export enum SheetsEnum {
        General,
        ArticlesAndAssemblies,
        ConceptsAndClusters,
        AssemblyComponents,
        Files,
        SubComponents,
        Graphics,
        StylesAndArtworks,
        ArticleStatusLogs,
        ClusterAssignmentLogs,
    }
    export namespace SheetsEnum {
        export function toString( enumValue: SheetsEnum ): string {
            return SheetsEnum[ enumValue ];
        }
    }
}

