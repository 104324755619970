import { AnimationTriggerMetadata, animate, style, transition, trigger, state } from '@angular/animations';


export const mainMenuAnimations: Array<AnimationTriggerMetadata> = [

    // animation for main menu
    trigger( 'mainMenuTrigger', [
        transition(
            ':enter', [
                style( { transform: 'translateX( -100% )' } ),
                animate( '400ms ease-in-out', style( { transform: 'translateX( 0 )' } ) ),
            ] ),
        transition(
            ':leave', [
                animate( '400ms ease-in-out', style( { transform: 'translateX( -105% )' } ) ),
            ] ),
    ] ),

    // animation for the app-content, when main menu is open/closed.
    // Used in app.component
    trigger( 'appContainerTrigger', [
        state( 'none', style( { transform: 'translateX( 0 )' } ) ),
        // 350px is the main-menu width, menu should overlap content -> shift 310px to the right
        state( 'shift', style( { transform: 'translateX( 310px )' } ) ),
        transition( 'none => shift', animate( '400ms ease-in-out' ) ),
        transition( 'shift => none', animate( '400ms ease-in-out' ) ),
    ] ),
];

