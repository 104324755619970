import { Component, EventEmitter, Input, OnInit, Output, } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { DisplayEditBaseComponent, } from '../shared/display-edit-base.component';
import { MessageType } from '../../../ui/messages/messages.component';
import { ValidationMessagesService } from '../../validation/validation-messages.service';

export interface DisplayEditOption {
    id: string | number;
    name: string;
    disabled?: boolean;
}

/**
 * <select> for RSP.
 *
 * When using form mode, the default value is taken from the form control.
 *
 * ```
 * <rsp-display-edit-options
 *     [myFormControl]="form.get( 'countryId' )"
 *     [isEditMode]="isEditMode"
 *     [placeholder]="'Select Country'"
 *     [options]="[ { id: '1', name: 'Germany' }, { id: '2', name: 'New Zealand' } ]"
 *     [inputFieldHtmlId]="htmlIdFor.get( 'country-field' )"
 * ></rsp-display-edit-options>
 * ```
 */
@Component( {
    selector:    'rsp-display-edit-options',
    templateUrl: './display-edit-options.component.html',
    styleUrls:   [
        './display-edit-options.component.scss',
    ],
} )
export class DisplayEditOptionsComponent extends DisplayEditBaseComponent implements OnInit {
    @Input()
    get options(): DisplayEditOption[] {
        return this._options;
    }
    set options( value: DisplayEditOption[] ) {
        if ( value ) {
            this._options = value.sort( ( a: DisplayEditOption, b: DisplayEditOption ) => a.name.localeCompare( b.name ) );
        }
    }

    @Input() placeholder: string;

    filteredOptions: DisplayEditOption[];

    defaultValue: string;

    @Input()
    get defaultOption(): DisplayEditOption {
        return this._defaultOption;
    }
    set defaultOption( value: DisplayEditOption ) {
        this._defaultOption = value;
        if ( value ) {
            this.defaultValue = value.name;
        }
        else {
            this.defaultValue = '';
        }
    }

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    @Output() selected: EventEmitter<DisplayEditOption> = new EventEmitter();

    messageTypeError: MessageType = MessageType.Error;

    private _defaultOption: DisplayEditOption;
    private _options: DisplayEditOption[] = [];

    constructor( validationMessagesService: ValidationMessagesService ) {
        super( validationMessagesService );
    }

    ngOnInit(): void {
        this.setDefaultValueFromFormControl();

        if ( this.isEditMode ) {
            this.startEditMode();

            this.myFormControl
                .valueChanges
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.updateValidationMessages( this.myFormControl );
                } );
        }
    }

    setOption( option?: DisplayEditOption ): void {
        this.myFormControl.markAsDirty();

        this.myFormControl.setValue( option ? option.id : null );
        this.defaultValue = option ? option.name : '';


        this.selected.emit( option );
    }

    setFilteredOptions( filteredOptions: DisplayEditOption[] ): void {
        this.filteredOptions = filteredOptions;
    }

    // if this.myFormControl has a value, put it in this.defaultValue. Handle value updates, too.
    private setDefaultValueFromFormControl(): void {
        if ( this.myFormControl ) {
            const formControlValue: any = this.myFormControl.value;
            if ( !( typeof formControlValue === 'undefined' || formControlValue === null ) ) {
                const optionForFormControlValue: DisplayEditOption = this.getOptionFromFormControlValue();
                if ( optionForFormControlValue ) {
                    this.defaultValue = optionForFormControlValue.name;
                }
                else {
                    console.error( `DisplayEditOptionsComponent: Given form control value (${ this.myFormControl.value }) isn't in [options].` );
                }
            }

            // update default value when the form control value was updated from the outside (e.g. the parent component).
            this.myFormControl.valueChanges.pipe( takeUntil( this.isDestroyed ) ).subscribe( ( value: any ) => {
                window.setTimeout( () => { // wait a bit, maybe this.options was changed from outside, too (had cases where it was undefined for a short time).
                    const currentOption: DisplayEditOption = this.getOptionFromFormControlValue();
                    if ( currentOption ) {
                        this.defaultValue = currentOption.name;
                    }
                    else if ( typeof value === 'undefined' || value === null || !currentOption ) {
                        this.defaultValue = null;
                    }
                } );
            } );
        }
    }

    private getOptionFromFormControlValue(): DisplayEditOption | undefined {
        return ( Array.isArray( this.options ) ) ? this.options.find( ( option: DisplayEditOption ) => option.id === this.myFormControl.value ) : undefined;
    }
}
