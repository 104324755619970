<div>
    <span
        *ngFor="let retailSegmentation of retailSegmentations"
        rspKeyword
        [withClearButton]="true"
        (clearClick)="removeRetailSegmentation( retailSegmentation )"
    >
        {{ retailSegmentation.name }}
    </span>
</div>

<rsp-dropdown
    [placeholder]="'Select Retail Segmentation'"
    [defaultValue]="null"
    [withSuggester]="true"
    [originalItems]="retailSegmentationOptions"
    (filteredItems)="setFilteredRetailSegmentationOptions( $event )"
    (selected)="setRetailSegmentation( $event )"
>
    <rsp-dropdown-item *ngFor="let option of filteredRetailSegmentationOptions" [item]="option">
        {{ option.name }}
    </rsp-dropdown-item>
</rsp-dropdown>
