import { Component, Input, } from '@angular/core';

import { kebabCase } from 'lodash';

/**
 *     <!-- Simple example -->
 *     <rsp-card>
 *         <rsp-card-headline>Applied Brand Logo</rsp-card-headline>
 *         <rsp-card-content>
 *             adidas
 *         </rsp-card-content>
 *     </rsp-card>
 *
 *     <!-- Different type and HTML in headline -->
 *     <rsp-card [type]="CardType.Note">
 *         <rsp-card-headline>Note (<i>HTML allowed</i>)</rsp-card-headline>
 *         <rsp-card-content>
 *             Yes
 *         </rsp-card-content>
 *     </rsp-card>
 */
export enum CardType {
    Default,
    Note,
}

@Component( {
    selector:    'rsp-card',
    templateUrl: './card.component.html',
    styleUrls:   [
        './card.component.scss',
    ],
} )
export class CardComponent {
    cssClassPrefix: string;

    @Input()
    set type( type: CardType ) { this.setCssClassPrefix( type ); }
    get type(): CardType { return this._type; }

    private _type: CardType;

    constructor() {
        // set default CardType
        this.setCssClassPrefix( CardType.Default );
    }

    setCssClassPrefix( type: CardType ): void {
        this._type          = type;

        this.cssClassPrefix = 'card';
        if ( this._type > 0 ) {
            this.cssClassPrefix += '_type-' + kebabCase( CardType[ this._type ] );
        }
    }
}
