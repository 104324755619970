import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyInfo } from '../../model/currencyInfo';

/**
 * Displays CurrencyInfo object to label:
 *
 *      {{ abbreviation }}, {{ name }}
 *
 *      f.e.
 *      "EUR, Euro Member Countries"
 *
 * @example
 *      <div>{{ currency | currency-name-with-abbreviation"></div>
 */
@Pipe( {
    name: 'currencyNameWithAbbreviation',
} )
export class CurrencyNameWithAbbreviationPipe implements PipeTransform {

    transform( value: CurrencyInfo ): string {

        if ( value === null ) {
            return null;
        }

        let resultString: string = value.abbreviation;

        if ( value.name ) {
            resultString += ', ' + value.name;
        }

        return resultString;
    }
}
