import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { DateTimeDisplayFormat } from '../../../ui/date-time/model/dateTimeDisplayFormat.enum';


/**
 *
 * Displays DateTime range using <rsp-date-time-range> in display mode
 * and <rsp-edit-date-time-range> in edit mode
 *
 * @example
 *
 *      <rsp-display-edit-date-time-range
 *          [myFormGroup]="myForm"
 *          [isEditMode]="isEditMode"
 *          [datetimeFrom]="..."
 *          [datetimeUntil]="..."
 *          [format]="'HumanReadable'"
 *          [withWeekday]="false"
 *          [withTime]="true"
 *          [fromPlaceholder]="'Valid from'"
 *          [untilPlaceholder]="'Valid until'"
 *          [disablePastFromDates]="true"
 *          [disablePastUntilDates]="true"
 *          [fromInputFieldHtmlId]="'from-field'"
 *          [untilInputFieldHtmlId]="'until-field'"
 *      ></rsp-display-edit-date-time-range>
 *
 *
 */
@Component( {
    selector:    'rsp-display-edit-date-time-range',
    templateUrl: './display-edit-date-time-range.component.html',
    styleUrls:   [
        './display-edit-date-time-range.component.scss',
    ],
} )
export class DisplayEditDateTimeRangeComponent {
    @Input() isEditMode: boolean;
    @Input() myFormGroup: UntypedFormGroup;
    /**
     * @see DateTimeRangeComponent
     */
    @Input() datetimeFrom: string;

    /**
     * @see DateTimeRangeComponent
     */
    @Input() datetimeUntil: string;

    /**
     * @see DateTimeRangeComponent
     */
    @Input() format: string | DateTimeDisplayFormat = DateTimeDisplayFormat.HumanReadable;

    /**
     * @see DateTimeRangeComponent
     */
    @Input() withWeekday: boolean;

    /**
     * @see DateTimeRangeComponent
     */
    @Input() withTime: boolean;

    /**
     * @see DateTimeRangeComponent
     */
    @Input() withSeconds: boolean;

    /**
     * @see DateTimeRangeComponent
     */
    @Input() withTimezone: boolean;

    @Input() fromPlaceholder: string;
    @Input() untilPlaceholder: string;
    @Input() disablePastFromDates: boolean;
    @Input() disablePastUntilDates: boolean;
    @Input() fromInputFieldHtmlId: string;
    @Input() untilInputFieldHtmlId: string;
}
