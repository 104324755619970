import { Component, OnInit, OnDestroy, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of, merge, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ClusterService, } from '../cluster.service';
import { ArticleManagementClustersService } from '../../../../../shared/api';
import { ClusterDetail, } from '../../../../../shared/model/clusterDetail';
import { Tag, } from '../../../../../shared/ui/tags/tag';
import { CardType } from '../../../../../shared/ui/card/card.component';
import { NotificationService } from '../../../../../core/overlay/notification/notification.service';
import { UpdateClusterDetail } from '../../../../../shared/model/updateClusterDetail';
import { Reply } from '../../../../../shared/model/reply';
import { OptionItem } from '../../../../../shared/model/optionItem';
import { KeywordInfo } from '../../../../../shared/model/keywordInfo';
import { EditTextComponent } from '../../../../../shared/forms/controls/edit-text/edit-text.component';
import { EditDateTimeRangeComponent } from '../../../../../shared/forms/controls/edit-date-time-range/edit-date-time-range.component';
import { DisplayEditDistributionChannelsComponent } from './display-edit-distribution-channels/display-edit-distribution-channels.component';
import { MasterDataDistributionChannelsService } from '../../../../../shared/api';
import { ViewModelListDistributionChannelListItem } from '../../../../../shared/model/viewModelListDistributionChannelListItem';
import { DistributionChannelListItem } from '../../../../../shared/model/distributionChannelListItem';
import { ExperienceInfo } from '../../../../../shared/model/experienceInfo';
import { DropDownAction } from '../../../../../shared/forms/edit-form-buttons/edit-form-buttons.component';
import { ViewModelItemClusterDetailClusterDetailChangeability } from '../../../../../shared/model/viewModelItemClusterDetailClusterDetailChangeability';
import { ClusterDetailChangeability } from '../../../../../shared/model/clusterDetailChangeability';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { EditKeywordsComponent } from '../../../../../shared/forms/controls/edit-keywords/edit-keywords.component';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { CurrentUserContext } from '../../../../../shared/model';
import { SecondaryToolbarService } from '../../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';


interface DistributionChannelFormValue {
    isActive: boolean;
    id: string;
}

interface DistributionChannelFormValues {
    [ key: string ]: DistributionChannelFormValue;
}

class Actions {
    static edit: string      = 'edit';
    static publish: string   = 'publish';
    static unpublish: string = 'unpublish';
}


@Component( {
    selector:    'rsp-cluster-detail',
    templateUrl: './cluster-details.component.html',
    styleUrls:   [
        '../../../../../shared/scss/04_layout/two-columns-1200.scss',
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './cluster-details.component.scss',
    ],
} )
export class ClusterDetailsComponent implements OnInit, OnDestroy, HasLoadingIndicator {
    clusterDetails: ClusterDetail;
    isEditMode: boolean       = false;
    form: UntypedFormGroup;
    actions: DropDownAction[] = [];

    cardType: any = CardType;

    isLoading: Subscription;
    isLoadingSave: boolean                = false;
    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private clusterService: ClusterService,
        private clustersApi: ArticleManagementClustersService,
        private toolbarService: SecondaryToolbarService,
        private distributionChannelsApi: MasterDataDistributionChannelsService,
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private router: Router,
        private formBuilder: UntypedFormBuilder,
        private currentUserService: CurrentUserService,
    ) {
    }

    ngOnInit(): void {
        // Two entry points:
        //  - of( true ) - first component load
        //  - this.pblService.summary$ - prev/next navigation
        merge( of( true ), this.clusterService.summary$ )
            .pipe(
                loadWithProgressIndicator(
                    () => forkJoin(
                        this.clustersApi.clustersGetDetail( this.clusterService.clusterId ),
                        this.distributionChannelsApi.distributionChannelsGetList(),
                    ),
                    this,
                ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: Array<ViewModelItemClusterDetailClusterDetailChangeability | ViewModelListDistributionChannelListItem> ) => {
                this.initialize( result );
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }


    triggerAction( action: DropDownAction ): void {

        switch ( action.id ) {

            case Actions.edit:
                this.startEditMode();
                break;

            case Actions.publish:
                this.publishCluster();
                break;

            case Actions.unpublish:
                this.unpublishCluster();
                break;

            default:
                break;
        }
    }


    startEditMode(): void {
        this.toolbarService.hidePrevNextNavigation();
        this.router.navigate( [ 'edit' ], { relativeTo: this.route } );
    }

    endEditMode(): void {
        this.toolbarService.showPrevNextNavigation();
        this.router.navigate( [ '../' ], { relativeTo: this.route } );
    }

    submitForm(): void {

        this.isLoadingSave = true;

        const submitData: UpdateClusterDetail = {
            note:                              this.form.value.note,
            name:                              this.form.value.name,
            availableForOrderingFrom:          this.form.value.availableForOrdering.from,
            availableForOrderingUntil:         this.form.value.availableForOrdering.until,
            keywordIds:                        this.form.value.keywordIds,
            experienceIds:                     this.form.value.experienceIds,
            subbrandCategoryIds:               this.form.value.subbrandCategoryIds,
            retailSegmentationIds:             this.form.value.retailSegmentationIds,
            distributionChannelIds:            this.prepareDistributionChannels( this.form.value.distributionChannels ),
            storeSubFormatIds:                 this.form.value.distributionChannels.ownRetail.storeSubFormatIds,
            distributionChannelStoreFormatIds: this.form.value.distributionChannels.ownRetail.storeFormatIds,
        };

        this.isLoading =
            this.clustersApi
                .clustersUpdateDetail( this.clusterDetails.clusterId, submitData )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( result: Reply ) => {
                    if ( result.isOk ) {
                        this.clusterService.getAndPublishSummary();
                        this.notificationService.success( 'Cluster details have been saved successfully.' );
                        this.endEditMode();
                    }
                } );
    }


    getKeywordsAsTags(): Tag[] {

        if ( this.clusterDetails ) {

            const tags: Tag[] = [];

            this.clusterDetails.keywords.map( ( keyword: KeywordInfo ) => {
                tags.push( new Tag( keyword.name ) );
            } );

            return tags;
        }

        return null;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private initialize( data: Array<ViewModelItemClusterDetailClusterDetailChangeability | ViewModelListDistributionChannelListItem> ): void {

        this.isEditMode = this.route.snapshot.data[ 'isEditMode' ] || false;

        const details: ViewModelItemClusterDetailClusterDetailChangeability = data[ 0 ] as ViewModelItemClusterDetailClusterDetailChangeability;
        this.clusterDetails                                                 = details.data;

        this.configureActions( details.changeability );


        if ( this.isEditMode ) {
            this.buildForm( data[ 1 ].data as DistributionChannelListItem[] );
        }
    }


    private configureActions( changeability: ClusterDetailChangeability ): void {
        this.actions = [];

        this.currentUserService
            .getCurrentUserAccessRightSet()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                // action: edit
                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterDetailsEdit ) ) {
                    this.actions.push( {
                        label: 'Edit',
                        id:    Actions.edit,
                    } );
                }

                // action: publish
                if ( changeability.canBePublished &&
                    accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterLifeCycleStatusEdit ) ) {
                    this.actions.push( {
                        label: 'Set to published',
                        id:    Actions.publish,
                    } );
                }

                // action: unpublish
                if ( changeability.canBeUnpublished &&
                    accessRightSet.has( CurrentUserContext.AccessRightsEnum.ConceptsAndClustersClusterLifeCycleStatusEdit ) ) {
                    this.actions.push( {
                        label: 'Set to unpublished',
                        id:    Actions.unpublish,
                    } );
                }
            } );
    }


    private buildForm( distributionChannels: DistributionChannelListItem[] ): void {
        this.form = this.formBuilder.group( {
            note:                    EditTextComponent.buildFormControl( this.clusterDetails.note ),
            name:                    EditTextComponent.buildFormControl( this.clusterDetails.name, [ Validators.required ] ),
            experienceIds:           new UntypedFormControl( this.clusterDetails.experiences.map( ( experience: ExperienceInfo ) => experience.id ) ),
            keywordIds:              EditKeywordsComponent.buildFormControl( this.clusterDetails.keywords ),
            retailSegmentationIds:   new UntypedFormControl( this.clusterDetails.retailSegmentations.map( ( item: OptionItem ) => item.id ) ),
            subbrandCategoryIds:     new UntypedFormControl( this.clusterDetails.subbrandCategories.map( ( item: OptionItem ) => item.id ) ),
            distributionChannels:    DisplayEditDistributionChannelsComponent.buildFormGroup( this.clusterDetails, distributionChannels ),
            availableForOrdering:    EditDateTimeRangeComponent.buildFormGroup(
                this.clusterDetails.availableForOrderingFrom, this.clusterDetails.availableForOrderingUntil,
            ),
        } );
    }


    private prepareDistributionChannels( distributionChannels?: DistributionChannelFormValues ): string[] {
        const distributionChannelIds: string[] = [];

        if ( distributionChannels ) {
            Object.keys( distributionChannels )
                  .map( ( distributionChannel: string ) => distributionChannels[ distributionChannel ] )
                  .forEach( ( formValue: DistributionChannelFormValue ) => {
                      if ( formValue.isActive ) {
                          distributionChannelIds.push( formValue.id );
                      }
                  } );
        }

        return distributionChannelIds;
    }


    private publishCluster(): void {

        this.isLoading =
            this.clustersApi
                .clustersPublishCluster( this.clusterService.clusterId )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.notificationService.success( 'Cluster was successfully published.' );
                    this.clusterService.getAndPublishSummary();
                } );
    }


    private unpublishCluster(): void {

        this.isLoading =
            this.clustersApi
                .clustersUnpublishCluster( this.clusterService.clusterId )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.notificationService.success( 'Cluster was successfully unpublished.' );
                    this.clusterService.getAndPublishSummary();
                } );
    }
}
