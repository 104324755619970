import { Component, OnDestroy, TemplateRef, ViewChild, } from '@angular/core';
import { Subscription, } from 'rxjs';
import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';

import { ArticleManagementAssembliesService, ArticleManagementNonTradeItemsService } from '../../../../../../../shared/api/index';
import * as XLSX from 'xlsx';
import {
    AssemblyInfo,
    NonTradeListItem, Reply,
    ViewModelBrowsingListNonTradeListItem,
} from '../../../../../../../shared/model';
import { mergeMap, map, filter, groupBy, toArray, concatMap, tap } from 'rxjs/operators';
import { Observable, from } from 'rxjs';

type AOA = any[][];

interface IndexedRow {
    assemblyName: string;
    assemblyId: string;
    componentId: string;
    index: number;
    row: any[];
}

@Component( {
    selector:    'rsp-create-assembly-from-excel-dialog',
    templateUrl: './create-assembly-from-excel-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        './create-assembly-from-excel-dialog.component.scss',
    ],
} )
export class CreateAssemblyFromExcelDialogComponent implements OnDestroy {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    isLoading: Subscription;

    isImporting: boolean = false;
    isDone: boolean      = false;
    progress: string[];
    index: number = 1;

    caption: string;

    constructor(
        private assembliesApi: ArticleManagementAssembliesService,
        private nonTradeItemApi: ArticleManagementNonTradeItemsService,
        private dialogService: DialogService,
    ) {}

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }


    importAssembliesFromFile( files: File[] ): void {

        if ( files.length !== 1 ) {
            throw new Error( 'Cannot use multiple files' );
        }

        this.isImporting = true;

        const reader: FileReader = new FileReader();
        reader.onload            = ( e: any ) => {
            /* read workbook */
            let bstr: string = '';
            const bytes: Uint8Array = new Uint8Array(e.target.result);
            for (let i: number = 0; i < bytes.byteLength; i++) {
                bstr += String.fromCharCode(bytes[i]);
            }

            const wb: XLSX.WorkBook = XLSX.read( bstr, { type: 'binary' } );

            /* grab sheet */
            const wsname: string     = wb.SheetNames[ 2 ];
            const ws: XLSX.WorkSheet = wb.Sheets[ wsname ];

            /* save data */
            const importedData: AOA = <AOA> XLSX.utils.sheet_to_json( ws, { header: 1, raw: true } );

            let contextAssembly: string;

            this.isLoading = from( importedData )
                .pipe(
                    // Map each row into an IndexedRow Object
                    map( ( row: any[], index: number ): IndexedRow => {
                        return {
                            assemblyName: null,
                            assemblyId: null,
                            componentId: null,
                            index: index,
                            row: row,
                        };
                    } ),
                    // Skip Header
                    filter( ( indexedRow: IndexedRow ) => {
                        return indexedRow.index > 18 && indexedRow.row[ 2 ];
                    } ),
                    // Set Context
                    map( ( indexedRow: IndexedRow ) => {
                        if ( indexedRow.row[ 1 ] ) {
                            contextAssembly = indexedRow.row[ 1 ];
                        }

                        indexedRow.assemblyName = contextAssembly;
                        return indexedRow;
                    }),
                    // Group by Assembly Name
                    groupBy( ( indexedRow: IndexedRow ) => indexedRow.assemblyName ),
                    // Map Group to Array
                    mergeMap( ( group: any )  => group.pipe( toArray() ) ),
                    // Process each Group
                    concatMap( ( assemblyToGetComponentsFor: IndexedRow[] ) => {
                        return this.process( importedData, assemblyToGetComponentsFor );
                    } ),
                ).subscribe(
                    ( value: any ) => { return; },
                    () => { return; },
                    () => {

                        this.progress.push( 'Import DONE.' );

                        const header: string[] = [
                            'Assembly Number (SET)',
                            'Assembly Name',
                            'Single Article Number',
                            'Single Article Name',
                            'Quantity',
                            '',
                            '-',
                            '-',
                            '-',
                            '-',
                            '-',
                            'Comment',
                            '-',
                        ];

                        const data: AOA = [];

                        data.push( header );
                        importedData.splice(0, 19);
                        data.push(...importedData);

                        const workBook: XLSX.WorkBook = XLSX.utils.book_new();
                        const workSheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet( data );
                        XLSX.utils.book_append_sheet( workBook, workSheet, 'Import Result' );

                        const log: string[][] = this.progress.map( ( value: string ) => {
                            return [ value ];
                        });

                        const workSheetLog: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet( log );
                        XLSX.utils.book_append_sheet( workBook, workSheetLog, 'Import Log' );

                        /* save to file */
                        XLSX.writeFile( workBook, 'IMPORT_RESULT_' + files[0].name );

                        this.isDone = true;
                        this.isImporting = false;
                    } );

        };
        reader.readAsArrayBuffer( files[ 0 ] );

    }

    open( createFromSelection: boolean = false ): void {
        this.isImporting = false;
        this.progress = [];
        this.isDone = false;
        this.index = 1;
        this.caption = 'Create or update Assemblies from excel import';
        this.dialogService.openDialog( {
                                           contentTemplate:  this.contentTemplate,
                                           headlineTemplate: this.headlineTemplate,
                                           footerTemplate:   this.footerTemplate,
                                           withBackdrop:     true,
                                       } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }

    private process( importedData: any, assemblyToGetComponentsFor: IndexedRow[] ): Observable<IndexedRow[]> {

        this.progress.push(this.index + '. ' + assemblyToGetComponentsFor[0].assemblyName);
        this.progress.push('...getting Components');

        this.index++;

        return this.nonTradeItemApi
                   .nonTradeItemsSearch(
                       {
                           quickSearchTerm: assemblyToGetComponentsFor.map( ( indexedRow: IndexedRow ) => indexedRow.row[ 2 ] ).join(' '),
                           size: 100,
                           filters: [ { name: 'Types', values: [ { value: 'Article', filters: [] } ] } ],
                       } )
                   .pipe(
                       map( ( result: ViewModelBrowsingListNonTradeListItem ) => {
                           const resultItems: NonTradeListItem[] = result.data;

                           return assemblyToGetComponentsFor.map( ( indexedRow: IndexedRow ) => {
                               const matchingItem: NonTradeListItem = resultItems.find( ( item: NonTradeListItem ) => {
                                   return item.number === indexedRow.row[ 2 ];
                               });

                               if ( matchingItem ) {
                                   indexedRow.componentId = matchingItem.id;
                               }
                               else {
                                   importedData[ indexedRow.index ][ 11 ] =
                                       (importedData[ indexedRow.index ][ 11 ] ? importedData[ indexedRow.index ][ 11 ] + '\n' : '')  + 'Article not found!';
                               }

                               if ( !indexedRow.row[ 4 ] ) {
                                   importedData[ indexedRow.index ][ 11 ] =
                                       (importedData[ indexedRow.index ][ 11 ] ? importedData[ indexedRow.index ][ 11 ] + '\n' : '')  + 'No Quantity provided!';
                               }

                               if ( (importedData[ indexedRow.index ][ 0 ] && !(importedData[ indexedRow.index ][ 0 ] as string).startsWith( 'A-' )) ||
                                   !importedData[ indexedRow.index ][ 0 ] ) {
                                   importedData[ indexedRow.index ][ 0 ] = 'created';
                               }

                               return indexedRow;
                           });
                       } ),
                       concatMap( ( indexedRows: IndexedRow[] ) => {
                           return this.createAssemblyOrGetAssemblyId( importedData, indexedRows );
                       } ),
                   );
    }

    private createAssemblyOrGetAssemblyId( importedData: any, indexedRows: IndexedRow[] ): Observable<IndexedRow[]> {
        if ( indexedRows[ 0 ].row[ 0 ] && (indexedRows[ 0 ].row[ 0 ] as string).startsWith( 'A-' )) {
            return this.getExistingAssembly( importedData, indexedRows );
        }

        return this.createAssembly( importedData, indexedRows );
    }

    private createAssembly( importedData: any,  indexedRows: IndexedRow[] ): Observable<IndexedRow[]> {

        this.progress.push('...creating Assembly');

        return this.assembliesApi
                   .assembliesCreateAssembly(
                       {
                           name:       indexedRows[ 0 ].assemblyName,
                           articleIds: indexedRows
                                           .filter( ( indexedRow: IndexedRow ) => indexedRow.componentId )
                                           .map( ( indexedRow: IndexedRow ) => indexedRow.componentId ),
                       },
                   )
                   .pipe(
                       map( ( result: AssemblyInfo ) => {
                           return indexedRows.map( ( indexedRow: IndexedRow ) => {
                               importedData[ indexedRow.index ][ 0 ] = result.number;
                               indexedRow.assemblyId = result.id;

                               return indexedRow;
                           } );
                       } ),
                       tap( (createdAssembly: IndexedRow[] ) => {
                           this.progress[ this.progress.length - 1 ] =
                               '...creating Assembly (' + importedData[ createdAssembly[ 0 ].index ][ 0 ] + ')';
                       } ),
                       concatMap( ( assemblyToUpdate: IndexedRow[] ) => {
                           return this.updateComponentQuantities( assemblyToUpdate );
                       } ),
                   );
    }

    private getExistingAssembly( importedData: any, indexedRows: IndexedRow[] ): Observable<IndexedRow[]> {

        this.progress.push( '...getting existing Assembly (' + indexedRows[ 0 ].row[ 0 ] + ')' );

        return this.assembliesApi
                   .assembliesGetArticleIdByNumber( indexedRows[ 0 ].row[ 0 ] )
                   .pipe(
                       map( ( guid: string ) => {
                           return indexedRows.map( ( indexedRow: IndexedRow ) => {
                               importedData[ indexedRow.index ][ 0 ] = indexedRows[ 0 ].row[ 0 ];
                               indexedRow.assemblyId = guid;

                               return indexedRow;
                           } );
                       } ),
                       concatMap( ( assemblyToUpdate: IndexedRow[] ) => {
                           return this.updateComponentQuantities( assemblyToUpdate );
                       } ),
                   );
    }

    private updateComponentQuantities( indexedRows: IndexedRow[] ): Observable<IndexedRow[]> {

        this.progress.push('...update Component quantities');

        return this.assembliesApi
                   .assembliesUpdateComponents(
                       indexedRows[ 0 ].assemblyId,
                       indexedRows.filter( ( indexedRow: IndexedRow ) => {
                           return indexedRow.componentId && indexedRow.row[ 4 ];
                       } )
                                       .map( ( indexedRow: IndexedRow ) => {
                                           return {
                                               articleId: indexedRow.componentId,
                                               quantity:  indexedRow.row[ 4 ],
                                           };
                                       } ) )
                   .pipe(
                       map( ( result: Reply ) => {
                           return indexedRows;
                       } ),
                   );
    }


}
