<rsp-details-page>
    <div class="non-trade-item-tile_wrapper">
        <div class="non-trade-item-tile non-trade-item-tile_light is-link-deactivated">
            <article class="non-trade-item-tile--inner">
                <div class="non-trade-item-tile--content-container">
                    <h3 class="non-trade-item-tile--item-number-and-status">
                        <span class="non-trade-item-tile--item-number">{{ summary?.number }}</span>
                        <div class="non-trade-item-tile--item-status">
                            <rsp-status
                                [status]="summary?.status"
                                [type]="statusTypeEnum"
                                [displayMode]="'badge'"
                            >
                            </rsp-status>
                        </div>
                    </h3>
                    {{ summary?.name }}

                </div>

                <div class="non-trade-item-tile--image-container">
                    <rsp-image
                        [fileId]="summary?.previewImageId"
                        [alt]="summary?.number + ' - ' + summary?.name"
                        [height]="200"
                        [alignImg]="true"
                        class="non-trade-item-tile--image"
                    ></rsp-image>
                </div>

                <input
                    rspCheckbox
                    [rspCheckboxWrapperClass]="'non-trade-item-tile--checkbox-wrapper'"
                    [rspCheckboxWrapperClassOnChecked]="'is-checkbox-checked'"
                    [checked]="isSelected"
                    (click)="toggleSelection()"
                    id="{{ summary?.previewImageId }}"
                    type="checkbox"
                >
            </article>
        </div>
    </div>

    <div>
        <rsp-vertical-tabs
            *ngIf="!isMultiAction"
            [tabs]="tabs"
        >
        </rsp-vertical-tabs>
    </div>
</rsp-details-page>

