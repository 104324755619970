import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface ContainerDimension {
    width: number;
    height: number;
}

@Injectable()
export class ScrollService {
    scrollContainerDimension: BehaviorSubject<ContainerDimension> = new BehaviorSubject( { width: 0, height: 0 } );
    scrollOffset: BehaviorSubject<number>                         = new BehaviorSubject( 0 );
}
