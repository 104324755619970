import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

/* tslint:disable:max-line-length */
import { AdvancedSearchFilter, AdvancedSearchFilterField, AdvancedSearchFilterOperator, AdvancedSearchService } from '../../advanced-search.service';
import { AdvancedSearchFilterItem } from '../../../../../model/advancedSearchFilterItem';
import { AdvancedSearchFilterProperty } from '../../../../../model/advancedSearchFilterProperty';
import { EditDateTimeRangeComponent, EditDateTimeRangeComponentFormData } from '../../../../../forms/controls/edit-date-time-range/edit-date-time-range.component';
import { AdvancedSearchFilterTypeOperator } from '../../../../../model/advancedSearchFilterTypeOperator';
import { DisplayEditDateComponent } from '../../../../../forms/controls/display-edit-date/display-edit-date.component';
/* tslint:enable:max-line-length */

@Component( {
    selector:    'rsp-advanced-search-filter-group-filter',
    templateUrl: './advanced-search-filter-group-filter.component.html',
    styleUrls:   [ './advanced-search-filter-group-filter.component.scss' ],
} )
export class AdvancedSearchFilterGroupFilterComponent implements OnChanges {
    @Input() filter: AdvancedSearchFilter;
    @Input() otherFilters: AdvancedSearchFilter[];
    @Input() filterFields: AdvancedSearchFilterField[];
    @Input() noRemove: boolean = false;

    @Output() onRemove: EventEmitter<void> = new EventEmitter<void>();
    @Output() onSubmit: EventEmitter<void> = new EventEmitter<void>();

    dateValueForm: UntypedFormGroup;
    dateRangeForm: UntypedFormGroup;

    operatorEnum: any = AdvancedSearchFilterItem.OperatorEnum;
    typeEnum: any     = AdvancedSearchFilterProperty.TypeEnum;

    filteredFilterFields: AdvancedSearchFilterField[];
    filterValueOptions: string[] | null = null;


    constructor(
        private advancedSearchService: AdvancedSearchService,
    ) {
    }

    ngOnChanges(): void {
        this.initDateFilter();
        this.updateFilterValueOptions();
    }

    fieldSelected( fieldName: string ): void {
        if ( fieldName === this.filter.name ) {
            // no real change happened
            return;
        }

        const filterField: AdvancedSearchFilterField = this.getFilterFieldByName( fieldName );

        const newFilterHasCurrentOperator: boolean = filterField.operators
            .some( ( operator: AdvancedSearchFilterOperator ) => this.filter.operator === operator.id );

        // operator change needed?
        if ( !newFilterHasCurrentOperator ) {
            this.filter.operator     = filterField.operators[ 0 ].id;
            this.filter.operatorInfo = filterField.operators[ 0 ];
        }

        // reset value when…
        if (
            // … type changes
            ( this.filter.type !== filterField.type ) ||

            // … when we jump between enum filters and the current value isn't part of the new enum values
            (
                filterField.type === AdvancedSearchFilterProperty.TypeEnum.Enum &&
                !filterField.values.some( ( possibleValue: string ) => possibleValue === this.filter.value )
            )
        ) {
            if ( this.isRangeOperator( this.filter.operator ) ) {
                this.filter.range = { from: null, until: null };
            }
            else {
                this.filter.value = null;
            }
        }

        this.filter.name      = filterField.name;
        this.filter.type      = filterField.type;
        this.filter.operators = filterField.operators;

        this.initDateFilter();
        this.updateFilterValueOptions();
    }

    filterValueOptionChanged( selectedOption: string | null ): void {
        this.filter.value = selectedOption;
    }

    filterFilterFields( filtered: AdvancedSearchFilterField[] ): void {
        this.filteredFilterFields = filtered;
    }

    operatorSelected( operator: AdvancedSearchFilterOperator ): void {
        if ( this.advancedSearchService.isRangeOperator( this.filter.operator ) !== this.advancedSearchService.isRangeOperator( operator.id ) ) {
            this.filter.value = null;
            this.filter.range = { from: null, until: null };
        }

        this.filter.operator     = operator.id;
        this.filter.operatorInfo = operator;
    }

    isRangeOperator( operator: AdvancedSearchFilterTypeOperator.OperatorEnum ): boolean {
        return this.advancedSearchService.isRangeOperator( operator );
    }

    emitRemoveEvent(): void {
        this.onRemove.emit();
    }

    emitSubmitEvent(): void {
        this.onSubmit.emit();
    }

    private initDateFilter(): void {
        if ( this.filter.type === this.typeEnum.Date && ( !this.dateValueForm || !this.dateRangeForm ) ) {
            if ( !this.dateValueForm ) {
                const date: Date | null = this.prepareDate( this.filter.value );

                const dateValueControl: UntypedFormControl = DisplayEditDateComponent.buildFormControl( date );
                dateValueControl.valueChanges.subscribe( ( value: Date ) => {
                    this.filter.value = value.toISOString();
                } );

                this.dateValueForm = new UntypedFormGroup( { value: dateValueControl, } );
            }

            if ( !this.dateRangeForm ) {
                const fromDate: Date | null = this.prepareDate( this.filter.range ? this.filter.range.from  : null );
                const until: Date | null    = this.prepareDate( this.filter.range ? this.filter.range.until : null );

                this.dateRangeForm = EditDateTimeRangeComponent.buildFormGroup( fromDate, until );

                this.dateRangeForm.valueChanges.subscribe( ( formData: EditDateTimeRangeComponentFormData ) => {
                    this.filter.range.from  = formData.from  ? formData.from.toISOString()  : null;
                    this.filter.range.until = formData.until ? formData.until.toISOString() : null;
                } );
            }
        }
    }

    private updateFilterValueOptions(): void {
        const filterField: AdvancedSearchFilterField = this.getFilterFieldByName( this.filter.name );

        if ( this.filter.type === AdvancedSearchFilterProperty.TypeEnum.Enum ) {
            this.filterValueOptions = filterField.values;
        }
        else {
            this.filterValueOptions = null;
        }
    }

    private getFilterFieldByName( fieldName: string ): AdvancedSearchFilterField {
        return this.filterFields.find(
            ( potentialFilterField: AdvancedSearchFilterField ) => potentialFilterField.name === fieldName,
        );
    }

    // TODO: While using mocked data, the date is a string and I'm not sure if the final response from the server will give us a date object, so this method
    //       will try to handle all cases and can probably be deleted as soon as we have real backend connection.
    private prepareDate( someKindOfDate: Date | string | null ): Date | null {
        let date: Date;

        if ( someKindOfDate ) {
            if ( someKindOfDate instanceof Date ) {
                date = someKindOfDate;
            }
            else {
                date = new Date( someKindOfDate );
            }
        }
        else {
            date = null;
        }

        return date;
    }
}
