import { Injectable, } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { UserSummary } from '../../model/index';
import { UserManagementUsersService } from '../../api/index';
import { ViewModelItemUserSummary } from '../../model/viewModelItemUserSummary';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {
    summary$: Observable<UserSummary>;
    userId: string;

    private summary: Subject<UserSummary> = new Subject();

    constructor(
        private userApi: UserManagementUsersService,
    ) {
        this.summary$ = this.summary.asObservable();
    }

    /**
     * Sets current Cluster ID. This ID is used for all API calls.
     */
    setUserId( clusterId: string ): void {
        this.userId = clusterId;
    }


    /**
     * Sets new Summary object and publishes #summary$ observable.
     */
    setSummary( summary: UserSummary ): void {
        this.summary.next( summary );
    }


    getSummary(): Observable<UserSummary> {
        if ( !this.userId ) {
            throw new Error( '#userId must be set!' );
        }

        return this.userApi.usersGetSummary( this.userId ).pipe(
            map( ( result: ViewModelItemUserSummary ) => result.data ),
        );
    }


    /**
     * Gets Summary for current Cluster. It also publishes the #summary$ observable.
     */
    getAndPublishSummary(): void {

        if ( !this.userId ) {
            throw new Error( '#userId must be set!' );
        }

        this.getSummary()
            .subscribe( ( result: UserSummary ) => {
                this.setUserId( result.tile.id );
                this.setSummary( result );
            } );
    }
}
