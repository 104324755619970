import { Component, EventEmitter, Input, Output, ViewChild, } from '@angular/core';
import { DisplayEditTextComponent } from '../display-edit-text/display-edit-text.component';
import { EditTextComponent } from '../edit-text/edit-text.component';
import { UntypedFormGroup } from '@angular/forms';
import { NOT_AVAILABLE } from '../../../ui/not-available/not-available.component';
import { MessagesCssSubmodule, MessageType } from '../../../ui/messages/messages.component';

/**
 * Displays text as label (display mode) or as multiline textarea (edit mode).
 * In edit mode, the result is added to given FormGroup as property with given name.
 *
 * @example
 *      <rsp-display-edit-text-multiline
 *          [myFormGroup]="myForm"
 *          [name]="myName"
 *          ...
 *      ></<rsp-display-edit-text-multiline>
 *
 *  myForm.value contains
 *
 *  {
 *      ...
 *      myName: ...,
 *  }
 *
 */
@Component( {
    selector:    'rsp-display-edit-text-multiline',
    templateUrl: './display-edit-text-multiline.component.html',
    styleUrls:   [
        './display-edit-text-multiline.component.scss',
    ],
} )
export class DisplayEditTextMultilineComponent {

    @Input()
    set value( text: string ) { this.setValue( text ); }
    get value(): string { return this._value; }


    @ViewChild(EditTextComponent) editTextComponent: EditTextComponent;

    /**
     * Optional size of the input field (in number of chars).
     */
    @Input() size: number;

    @Input() myFormGroup: UntypedFormGroup;
    @Input() name: string;
    @Input() isEditMode: boolean;
    @Input() hideValidationMessages: boolean;
    /**
     * Specifies if "n/a" should be displayed when provided value is not empty (null|undefined) in the display mode.
     * @type {boolean}
     */
    @Input() showNotAvailable: boolean = true;
    /**
     * Specifies string which is displayed when given value is empty and [showNotAvailable] is true. Default 'n / a'.
     * @type {string}
     */
    @Input() notAvailableString: string = NOT_AVAILABLE;
    validationMessages: Array<string> = [];

    /**
     * Optional CSS Submodule for <rsp-messages>.
     */
    @Input() validationMessagesCssSubmodule: MessagesCssSubmodule;

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    /**
     * Optional string that is used as HTML `placeholder`-attribute on the input field.
     */
    @Input() placeholder: string;

    /**
     * Event that is triggered when the user pressed enter key while the focus was in the <input>-field.
     */
    @Output() enterPressed: EventEmitter<string> = new EventEmitter<string>();

    /**
     * Event that is triggered when the user pressed the escape key while the focus was in the <input>-field.
     */
    @Output() escapePressed: EventEmitter<null> = new EventEmitter<null>();

    splittedValue: Array<string> = [];
    messageType: any = MessageType;

    private _value: string;

    focusInputField(): void {
        this.editTextComponent.inputField.nativeElement.focus();
    }

    private setValue( text: string ): void {
        this._value = text;

        this.splittedValue = text ? text.trim().split( /(?:\r\n|\r|\n)/g ) : null;
    }
}
