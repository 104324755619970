<rsp-dropdown
    [isLoading]="isLoading"
    [placeholder]="placeholder"
    [withSuggester]="true"
    [inputFieldHtmlId]="inputFieldHtmlId"
    (searchTermChanged)="searchItems( $event )"
    (selected)="selectedItem( $event )"
>
    <rsp-dropdown-item *ngFor="let item of items" [item]="item">
        {{item.name}}
    </rsp-dropdown-item>
</rsp-dropdown>
