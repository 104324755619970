import { EventEmitter } from '@angular/core';
import { cloneDeep } from 'lodash';

export type GlobalFilterFn<T> = ( item: T, filter: any ) => boolean;
export type ColumnFilterFn<T, K extends keyof T> = ( value: T[K], filter: T[K] ) => boolean;
export type ColumnSortFn<T, K extends keyof T> = ( value1: T[K], value2: T[K], ascending: boolean ) => number;


interface KeyValueItem<T> {
    key: string;
    value: T;
}

export interface SortDescriptor<T> {
    propertyName: keyof T;
    ascending: boolean;
}

/**
 * Generic collection which supports filtering and sorting.
 * Can be used together with some special components like <rsp-column-filter-text>.
 *
 * Do not forget to call `refresh()` after filter/sort values are changed.
 *
 * ```
 * interface Item {
 *     foo: string;
 * }
 *
 * const myItems: Array<Item> = [ { foo: 'abc', }, { foo: 'def', }, ];
 * const myItemCollection: CollectionView<Item>
 *     = new CollectionView( myItems )
 *                      .setPropertySortFunction( 'foo', standardSorters.default.bind(  standardSorters ) )
 *                      .setPropertyFilterFunction( 'foo', standardFilters.contains.bind( standardFilters ) )
 *                      .sortBy( 'foo', true )
 *                      ;
 *
 * myItems.push( { foo: 'bbb', }, );
 * myItemCollection.refresh();
 * console.log( myItemCollection.items ); // → [ { foo: 'abc', }, { foo: 'bbb', }, { foo: 'def', }, ];
 *
 * myItemCollection.filterByProperty( 'foo', 'b' ).refresh();
 * console.log( myItemCollection.items ); // → [ { foo: 'abc', }, { foo: 'bbb' }, ];
 * ```
 *
 */
export class CollectionView<T> {

    /**
     * Original array. Will never be changed.
     */
    immutableSourceItems: Array<T> = [];

    /**
     * Filtered/sorted version of #sourceItems.
     * This array should be used for data binding in the template.
     */
    items: Array<T> = [];

    /**
     * Mutated version of #sourceItems.
     */
    sourceItems: Array<T> = [];

    /**
     * Events occurs when CollectionView starts filter/sort items.
     */
    itemsChanging: EventEmitter<void> = new EventEmitter<void>();

    /**
     * Events occurs when CollectionView finished filter/sort items.
     */
    itemsChanged: EventEmitter<void> = new EventEmitter<void>();


    private globalFilterFunction: GlobalFilterFn<T>;
    private globalFilterValue: any;

    private propertyFilterFunctions: Array<KeyValueItem<ColumnFilterFn<T, keyof T>>> = [];
    private propertyFiltersValues: Array<KeyValueItem<any>>                          = [];

    private propertySortFunctions: Array<KeyValueItem<ColumnSortFn<T, keyof T>>> = [];
    private sortDescriptors: Array<SortDescriptor<T>>                            = [];


    constructor( sourceItems?: Array<T> ) {

        if ( sourceItems ) {
            this.setSourceItems( sourceItems );
        }
    }


    /**
     * Returns true, when #sourceItems array does not contain any element.
     * @returns {boolean}
     */
    isEmpty(): boolean {
        return !this.immutableSourceItems || this.immutableSourceItems.length === 0;
    }


    /**
     * Sets array with new source items. Updates #sourceItems and #items properties.
     * @param sourceItems
     * @returns {CollectionView}
     */
    setSourceItems( sourceItems: Array<T> ): CollectionView<T> {

        this.immutableSourceItems = cloneDeep( sourceItems);
        this.sourceItems          = sourceItems;
        this.items                = this.sourceItems;

        return this;
    }

    /**
     * Resets items to initially supplied source items. #items ans #sourceItems properties.
     * @returns {CollectionView}
     */
    restoreOriginItems(): CollectionView<T> {

        this.sourceItems = cloneDeep( this.immutableSourceItems);
        this.items       = this.sourceItems;

        return this;
    }

    /**
     * Re-creates #items using the current filter and sort.
     */
    refresh(): CollectionView<T> {
        this.filterAndSortSourceItems();
        return this;
    }


    /**
     * Sets filter function for "global filter".
     * @param filterFn
     * @returns {CollectionView}
     */
    setGlobalFilterFunction( filterFn: GlobalFilterFn<T> ): CollectionView<T> {

        this.globalFilterFunction = filterFn;
        return this;
    }


    /**
     * Sets global filter value.
     * @param value
     * @returns {CollectionView}
     */
    filterBy( value: any ): CollectionView<T> {

        this.globalFilterValue = value;
        return this;
    }


    /**
     * Sets filter function for specified property.
     * @param propertyName
     * @param filterFn
     * @returns {CollectionView}
     */
    setPropertyFilterFunction( propertyName: keyof T, filterFn: ColumnFilterFn<T, keyof T> ): CollectionView<T> {

        // is property filter registered for specified property?
        const index: number = this.propertyFilterFunctions.findIndex( ( filterItem: KeyValueItem<ColumnFilterFn<T, keyof T>> ) => {
            return filterItem.key === propertyName;
        } );


        if ( index === -1 ) {
            // create new
            this.propertyFilterFunctions.push( { key: propertyName as string, value: filterFn } );
        }
        else {
            // update existing
            this.propertyFilterFunctions[ index ].value = filterFn;
        }

        return this;
    }


    /**
     * Returns filter function defined for specified property.
     * @param propertyName
     * @returns {ColumnFilterFn<T, keyof T>}
     */
    getPropertyFilterFunction( propertyName: keyof T ): ColumnFilterFn<T, keyof T> {
        return this.getFilterFunctionForProperty( propertyName );
    }


    /**
     * Sets filter value for specified property.
     * @param propertyName
     * @param value
     * @returns {CollectionView}
     */
    filterByProperty<K extends keyof T>( propertyName: K, value: T[K] ): CollectionView<T> {

        const index: number = this.propertyFiltersValues.findIndex( ( item: KeyValueItem<T> ) => item.key === propertyName );

        if ( index === -1 ) {
            // create new
            this.propertyFiltersValues.push( { key: propertyName as string, value: value } );
        }
        else {
            // update existing
            this.propertyFiltersValues[ index ].value = value;
        }

        return this;
    }


    /**
     * Sets sort function for specified property.
     * @param propertyName
     * @param sortFn
     * @returns {CollectionView}
     */
    setPropertySortFunction( propertyName: keyof T, sortFn: ColumnSortFn<T, keyof T> ): CollectionView<T> {

        // is property sort function registered for specified property?
        const index: number = this.propertySortFunctions.findIndex( ( item: KeyValueItem<ColumnSortFn<T, keyof T>> ) => item.key === propertyName );

        if ( index === -1 ) {
            // create new
            this.propertySortFunctions.push( { key: propertyName as string, value: sortFn } );
        }
        else {
            // update existing
            this.propertySortFunctions[ index ].value = sortFn;
        }

        return this;
    }


    /**
     * Returns sort function for specified property.
     * @param propertyName
     * @returns {ColumnSortFn<T, keyof T>}
     */
    getPropertySortFunction( propertyName: keyof T ): ColumnSortFn<T, keyof T> {
        return this.getSortFunctionForProperty( propertyName as string );
    }


    /**
     * Sets sort description for property.
     * @param propertyName
     * @param ascending
     * @returns {CollectionView}
     */
    sortBy( propertyName: keyof T, ascending: boolean ): CollectionView<T> {

        this.sortDescriptors = [ { propertyName: propertyName, ascending: ascending } ];
        return this;
    }


    /**
     * Sets many sort descriptors at ones.
     * @param sortDescriptors
     * @returns {CollectionView}
     */
    sortByMany( sortDescriptors: Array<SortDescriptor<T>> ): CollectionView<T> {

        this.sortDescriptors = sortDescriptors;
        return this;
    }

    /**
     * Returns current sort description.
     * @returns {SortDescriptor}
     */
    getSortDescriptors(): Array<SortDescriptor<T>> {
        return this.sortDescriptors;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private filterAndSortSourceItems(): void {

        this.itemsChanging.emit();

        let resultArray: Array<T> = this.sourceItems;

        // filter
        if ( this.anyFilterFunctionDefined() && this.anyFilterValueDefined() ) {
            resultArray = this.sourceItems.filter( ( item: T ) => {

                // global filter
                if ( this.matchGlobalFilter( item ) ) {

                    // property filters
                    return this.matchAllPropertyFilters( item );
                }

                return false;
            } );
        }


        // sort
        if ( this.anySortFunctionDefined() && this.anySortDescriptorDefined() ) {

            resultArray = resultArray.sort( ( a: T, b: T ) => {

                for ( const sortDescriptor of this.sortDescriptors ) {

                    const compareResult: number = this.compareItemsUsingPropertySortFunction(
                        sortDescriptor.propertyName as string,
                        sortDescriptor.ascending,
                        a,
                        b,
                    );

                    // when compareResult === 0 -> a and b are equal, use next SortDescriptor
                    if ( compareResult !== 0 ) {
                        return compareResult;
                    }
                }

                // no more SortDescriptors, return last 0 value
                return 0;
            } );
        }

        this.items = [ ...resultArray ];

        this.itemsChanged.emit();
    }


    private anyFilterFunctionDefined(): boolean {
        return ( this.globalFilterFunction !== undefined && this.globalFilterFunction !== null )
            || ( this.propertyFilterFunctions !== undefined && this.propertyFilterFunctions !== null && this.propertyFilterFunctions.length > 0 );
    }

    private anyFilterValueDefined(): boolean {
        return ( this.globalFilterValue !== undefined && this.globalFilterValue !== null )
            || ( this.propertyFiltersValues !== undefined && this.propertyFiltersValues !== null && this.propertyFiltersValues.length > 0 );
    }

    private matchGlobalFilter( item: T ): boolean {

        if ( this.globalFilterFunction && this.globalFilterValue ) {
            return this.globalFilterFunction( item, this.globalFilterValue );
        }

        return true;
    }

    private matchAllPropertyFilters( item: T ): boolean {

        let matched: boolean = true;

        Object.keys(item).forEach((key: any) => {
            const filter: any = this.getFilterForProperty( key );

            if ( filter !== null ) {
                const filterFn: ColumnFilterFn<T, keyof T> = this.getFilterFunctionForProperty( key );

                if ( !filterFn ) {
                    throw new Error( '[CollectionView] There is no filter function for property "' + key + '"' );
                }

                if ( !filterFn( item[ key ], filter ) ) {
                    matched = false;
                }
            }
        });

        return matched;
    }

    private getFilterForProperty( propertyName: keyof T ): any {

        const resultItem: KeyValueItem<any> = this.propertyFiltersValues.find( ( item: KeyValueItem<any> ) => {
            return item.key === propertyName;
        } );

        if ( resultItem ) {
            return resultItem.value;
        }

        return null;
    }

    private getFilterFunctionForProperty( propertyName: keyof T ): ColumnFilterFn<T, keyof T> {

        const resultItem: KeyValueItem<ColumnFilterFn<T, keyof T>>
                  = this.propertyFilterFunctions.find( ( item: KeyValueItem<ColumnFilterFn<T, keyof T>> ) => item.key === propertyName );

        if ( resultItem ) {
            return resultItem.value;
        }

        return null;
    }


    private anySortFunctionDefined(): boolean {
        return this.propertySortFunctions !== undefined
            && this.propertySortFunctions !== null
            && this.propertySortFunctions.length > 0;
    }


    private anySortDescriptorDefined(): boolean {
        return this.sortDescriptors !== undefined
            && this.sortDescriptors !== null
            && this.sortDescriptors.length > 0;
    }

    private compareItemsUsingPropertySortFunction( propertyName: string, ascending: boolean, item1: T, item2: T ): number {

        const sortFn: ColumnSortFn<T, keyof T> = this.getSortFunctionForProperty( propertyName );

        if ( !sortFn ) {
            throw new Error( '[CollectionView] There is no sort function for property "' + propertyName + '"' );
        }

        return sortFn( item1[ propertyName ], item2[ propertyName ], ascending );
    }


    private getSortFunctionForProperty( propertyName: string ): ColumnSortFn<T, keyof T> {

        const resultItem: KeyValueItem<ColumnSortFn<T, keyof T>>
                  = this.propertySortFunctions.find( ( item: KeyValueItem<ColumnSortFn<T, keyof T>> ) => item.key === propertyName );

        if ( resultItem ) {
            return resultItem.value;
        }

        return null;
    }
}
