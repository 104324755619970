import { Injectable } from '@angular/core';

import { BaseSelectionService } from '../../../../shared/ui/lists/base-selection.service';
import { UserListItem } from '../../../../shared/model';

/**
 * Handles selection of UserListItems. Provides an observable property (selectedItems$) which can be used by observers
 * which want to be notified when selection has been changed.
 */
@Injectable()
export class UserSelectionService extends BaseSelectionService<UserListItem> {
    protected getId( item: UserListItem ): string {
        if ( !item ) {
            throw new Error( '[UserSelectionService] item must not be null!' );
        }

        return item.id;
    }
}
