<ng-template #headline>
    <h2 class="standard-dialog--headline">{{ caption }}</h2>
</ng-template>

<ng-template #content>
    <div
        [ngClass]="fromSelection ? 'standard-dialog--content' : 'standard-dialog--button'"
        [rspLoadingIndicator]="isLoading"
    >
        <rsp-non-trade-item-selection-list *ngIf="fromSelection; else createTemplate"></rsp-non-trade-item-selection-list>
    </div>
</ng-template>

<ng-template #footer>
    <div *ngIf="fromSelection" class="standard-dialog--button"  [rspLoadingIndicator]="isLoading">
        <ng-template [ngTemplateOutlet]="createTemplate" ></ng-template>
    </div>
</ng-template>

<ng-template #createTemplate>
    <rsp-edit-text
        [myFormControl]="assemblyNameControl"
        [placeholder]="'Assembly name'"
        style="flex: 1"
    >
    </rsp-edit-text>
    <rsp-button
        [type]="'submit'"
        [submodule]="'primary'"
        (clicked)="doAction()"
        [disabled]="!assemblyNameControl.valid"
        style="margin-left: 1em"
    >
        Create
    </rsp-button>
</ng-template>
