// angular
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS } from '@angular/common/http';


// third party
import { OAuthModule } from 'angular-oauth2-oidc';


// our global app stuff
import { AppComponent, } from './app.component';
import { environment } from '../environments/environment';

import { BootstrapService } from './core/bootstrap.service';
import { LoadingComponent } from './shared/ui/loading-indicators/loading-indicator/loading.component';
import { Configuration } from './shared/generated/configuration';
import { AuthenticationService } from './core/authentication.service';
import { TransformResponseDateInterceptor } from './core/interceptor/transform-response-date.interceptor';
import { AuthenticationInterceptor } from './core/interceptor/authentication.interceptor';
import { ErrorInterceptor } from './core/interceptor/error.interceptor';


// modules
import { AppRoutingModule, NotFoundRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
import { ArticleManagementModule } from './features/article-management/article-management.module';
import { ApiModule } from './shared/generated/api.module';
import { DashboardModule } from './features/dashboard/dashboard.module';
import { MyProfileModule } from './features/my-profile/my-profile.module';
import { RegistrationModule } from './features/registration/registration.module';
import { SharedModule } from './shared/shared.module';
import { UserManagementModule } from './features/user-management/user-management.module';
import { DocumentManagementModule } from './features/document-management/document-management.module';

@NgModule( {
    imports: [
        // Angular
        BrowserModule,
        BrowserAnimationsModule,
        // Third party
        OAuthModule.forRoot(),
        // Routing
        AppRoutingModule,
        CoreModule,
        // Features
        ArticleManagementModule,
        DashboardModule,
        DocumentManagementModule,
        MyProfileModule,
        RegistrationModule,
        UserManagementModule,
        NotFoundRoutingModule,
        ApiModule,
        // SharedModule (LoadingComponent uses SpinnerComponent)
        SharedModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthenticationInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TransformResponseDateInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: Configuration,
            useFactory: (authService: AuthenticationService) => new Configuration({
                basePath: environment.backendBaseUrl,
                accessToken: authService.getAccessToken.bind(authService),
            }),
            deps: [AuthenticationService],
            multi: false,
        },
    ],
    declarations: [
        AppComponent,
        LoadingComponent,
    ],
    bootstrap: [
        AppComponent,
    ],
} )
export class AppModule {

    constructor( bootstrapService: BootstrapService ) {

        bootstrapService.initializeQueryParamsTracking();

        bootstrapService.initializeMainSlideIn();

        bootstrapService.initializeMainLoadingIndicator();
    }
}
