import { Component, Input, OnInit, } from '@angular/core';
import { EntityTypeEnum } from '../../model/enum/entity-type.enum';
import { NonTradeListItem } from '../../model/nonTradeListItem';
import { ConceptListItem } from '../../model/conceptListItem';
import { ClusterInfo } from '../../model/clusterInfo';
import { PrepressProduction } from '../../model/prepressProduction';
import { UserListItem } from '../../model/userListItem';
import { StringConverterService } from '../../utils/string/string-converter.service';


/**
 * ```html
 * <rsp-status [status]="statusEnum" [type]="statusEnumType"></rsp-status>
 * <rsp-status [status]="statusEnum" [type]="statusEnumType" [displayMode]="'tag'"></rsp-status>
 * <rsp-status [status]="statusEnum" [type]="statusEnumType" [displayMode]="'badge'"></rsp-status>
 * ```
 */
@Component( {
    selector:    'rsp-status',
    templateUrl: './status.component.html',
    styleUrls:   [
        './status.component.scss',
    ],
} )
export class StatusComponent implements OnInit {

    @Input() type: EntityTypeEnum;

    /**
     * Status ID, usually given from the backend.
     * @type {states}
     */
    @Input() status: number;

    /**
     * Display mode:
     * - tag - colored tag containing status name
     * - text-and-badge (default) - status name and colored square
     * - badge - only colored square without any text
     */
    @Input() displayMode: 'tag' | 'text-and-badge' | 'badge' = 'text-and-badge';

    /**
     * Name of the status that is shown to the user.
     */
    public statusName: string;

    /**
     * The CSS state class name that is used in the template to colorize states.
     */
    public statusCssClassName: string;

    isNonTradeItem: boolean = false;


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private statusCssClassPrefix: string = 'is-adidas-status-';

    ngOnInit(): void {
        let statusName: string;
        switch ( this.type ) {
            case EntityTypeEnum.NonTradeItem:
                statusName = NonTradeListItem.StatusEnum.toString( this.status );
                this.isNonTradeItem = true;
                break;

            case EntityTypeEnum.Concept:
                statusName = ConceptListItem.StatusEnum.toString( this.status );
                break;

            case EntityTypeEnum.Cluster:
                statusName = ClusterInfo.StatusEnum.toString( this.status );
                break;

            case EntityTypeEnum.PrepressProduction:
                statusName = PrepressProduction.StatusEnum.toString( this.status );
                break;

            case EntityTypeEnum.User:
                statusName = UserListItem.StatusEnum.toString( this.status );
                break;

            default:
                console.warn( 'No Definition for Status Type', this.type );

        }

        if ( this.isStatusValid( statusName ) ) {
            this.statusName         = StringConverterService.splitCamelcaseWithSpace( statusName );
            this.statusCssClassName = this.statusCssClassPrefix + statusName.toLowerCase();
        } else {
            this.statusName = statusName;
            console.warn( `StatusComponent: Status '${statusName}' unknown.` ); // TODO: Report to exceptionless
        }
    }

    /**
     * Checks if the given status is valid at runtime.
     * @param status
     * @returns {boolean}
     */
    private isStatusValid( status: string ): boolean {
        let statusToCheck: string = '';
        if ( typeof status === 'string' ) {
            statusToCheck = status.toLowerCase();
        }

        switch ( statusToCheck ) {
            case 'active':
            case 'activeinrollout':
            case 'activeinprerollout':
            case 'activeondemand':
            case 'activedelivery':
            case 'availabletoforecast':
            case 'availabletoforecastandorder':
            case 'availabletoorder':
            case 'canbedeliveredtocountry':
            case 'canbedeliveredtosupplychainzone':
            case 'cannotbedeliveredtocountry':
            case 'unavailable':
            case 'development':
            case 'discontinue':
            case 'expired':
            case 'inactive':
            case 'inprogress':
            case 'nodelivery':
            case 'planned':
            case 'published':
            case 'ready':
            case 'unpublished':
            case 'creating':
            case 'registered':
                return true;

            default:
                return false;
        }
    }
}
