<ng-template #dialogHeadline>
    <div class="standard-dialog--headline">
        Disclaimer
    </div>
</ng-template>

<ng-template #dialogContent>
    <div class="standard-dialog--content legal-notice-dialog">
        Your use of and browsing of this web site are at your own risk. Everything on this website is provided „as is“ without warranty of any kind, either express or implied. NUREG GmbH makes no warranties or representations regarding the information contained on this website, and specifically disclaims any warranty as to the suitability, reliability, timeliness or accuracy of the information contained on this website for any purpose. NUREG GmbH does not warrant that the functions contained in the materials will be uninterrupted or error free, that defects will be corrected, or that this site or the server that makes such materials available are free of viruses or other harmful components.
    </div>
</ng-template>

<ng-template #dialogFooter>
    <div class="standard-dialog--footer">
        <rsp-button
            [submodule]="'secondary'"
            (clicked)="closeDialog()"
            class="standard-dialog--button"
        >
            Ok
        </rsp-button>
    </div>
</ng-template>
