<rsp-drop-zone
    *ngIf="!file"
    [multiple]="false"
    [validFileTypeGroup]="validFileTypeGroup"
    [inputFieldHtmlId]="inputFieldHtmlId"
    (filesChanged)="storeFile( $event )"
>
    <div class="upload-single-file--drop-zone">
        <ng-content></ng-content>
    </div>
</rsp-drop-zone>

<rsp-file-upload
    *ngIf="file"
    [file]="file"
    [fileKindId]="fileKindId"
    [fileContainerId]="fileContainerId"
    [progressBarBackgroundColor]="progressBarBackgroundColor"
    (remove)="removeFile()"
    (fileUploaded)="fileUploaded( $event )"
    class="upload-single-file--file-upload"
>
</rsp-file-upload>

