import { Component } from '@angular/core';

/**
 * Displays unit: kg
 */
@Component( {
    selector:    'rsp-unit-kilogram',
    templateUrl: 'unit-kilogram.component.html',
    styleUrls:   [
        './unit-kilogram.component.scss',
    ],
} )
export class UnitKilogramComponent {
}
