import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

import { Subscription, Observable, forkJoin, merge, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { sortBy } from 'lodash';

import { NotificationService } from '../../../../core/overlay/notification/notification.service';
import { DialogService } from '../../../../core/overlay/dialog/dialog.service';
import { UniqueHtmlIdService } from '../../../../core/unique-html-id.service';
import { UserService } from '../user.service';
import { MasterDataCulturesService, UserManagementCurrentUserService, UserManagementModuleGroupsService, UserManagementUsersService } from '../../../api/index';
import { EntityTypeEnum } from '../../../model/enum/entity-type.enum';
import { DropDownAction } from '../../../forms/edit-form-buttons/edit-form-buttons.component';
import { UploadedFileInfo } from '../../../forms/upload/upload-single-file/upload-single-file.component';
import { EditTextComponent } from '../../../forms/controls/edit-text/edit-text.component';
import { DisplayEditUserProfileImageComponent } from '../../../forms/controls/display-edit-user-profile-image/display-edit-user-profile-image.component';
import {
    CultureInfo,
    ModuleGroupInfo,
    ModuleGroupListItem,
    Reply,
    UpdateCurrentUserDetails,
    UpdateUserDetails,
    UserDetails,
    UserDetailsChangeability,
    UserModuleGroupInfo,
    UserSummary,
    ViewModelItemUserDetails,
    ViewModelItemUserDetailsUserDetailsChangeability,
    ViewModelListCultureInfo,
    ViewModelListModuleGroupInfo,
} from '../../../model/index';
import { UserNameService } from '../../user-name/user-name.service';
import { UserEditContext } from '../user.model';
import { UserModuleGroupsDialogComponent } from './user-module-groups-dialog/user-module-groups-dialog.component';
import { SecondaryToolbarService } from '../../secondary-toolbar/secondary-toolbar.service';
import { CurrentUserService } from '../../../../core/current-user.service';
import { loadWithProgressIndicator } from '../../../utils/rxjs-extensions/load-with-progress-indicator.extension';
import { CurrentUserContext, UserInfo } from '../../../model';


interface UserDetailsFormData {
    profileImageFileId: string | null;
    phoneNumber: string | null;
    companyId: string | null;
    locationId: string | null;
    preferredCulture: CultureInfo | null;
    wantsEmailNotifications: boolean;
}

@Component( {
    selector:    'rsp-user-details',
    templateUrl: './user-details.component.html',
    styleUrls:   [
        '../../../scss/04_layout/two-columns-1200.scss',
        '../../../scss/05_module/detail-page-tab.scss',
        '../../../scss/05_module/standard-dialog.scss',
        './user-details.component.scss',
    ],
} )
export class UserDetailsComponent implements OnInit, OnDestroy {
    mode: UserEditContext;

    userDetails: UserDetails;
    allModuleGroups: ModuleGroupListItem[] | ModuleGroupInfo[];
    allCultures: CultureInfo[];
    actions: DropDownAction[]                         = [];
    form: UntypedFormGroup;
    isEditMode: boolean                               = false;
    originalUserProfileImageId: string | null;

    isLoading: Subscription;
    isLoadingSave: boolean         = false;
    isLoadingDelete: boolean       = false;
    htmlIdFor: Map<string, string> = new Map();
    statusEnumType: EntityTypeEnum = EntityTypeEnum.User;

    currentUser: CurrentUserContext;

    isAdministrator: boolean;

    representatives: UserInfo[] = [];
    substitutes: UserInfo[]     = [];

    @ViewChild( 'deleteUserConfirmDialogHeadline', { static: true } ) deleteUserConfirmDialogHeadline: TemplateRef<any>;
    @ViewChild( 'deleteUserConfirmDialogContent', { static: true } ) deleteUserConfirmDialogContent: TemplateRef<any>;
    @ViewChild( 'deleteUserConfirmDialogFooter', { static: true } ) deleteUserConfirmDialogFooter: TemplateRef<any>;
    @ViewChild( 'moduleGroupsDialog', { read: UserModuleGroupsDialogComponent } ) moduleGroupsDialog: UserModuleGroupsDialogComponent;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private toolbarService: SecondaryToolbarService,
        private userService: UserService,
        private currentUserService: CurrentUserService,
        private notificationService: NotificationService,
        private dialogService: DialogService,
        private userNameService: UserNameService,
        private formBuilder: UntypedFormBuilder,
        private uniqueHtmlIdService: UniqueHtmlIdService,
        private userManagementUsersService: UserManagementUsersService,
        private userManagementCurrentUserService: UserManagementCurrentUserService,
        private userManagementModuleGroupsService: UserManagementModuleGroupsService,
        private masterDataCulturesService: MasterDataCulturesService,
    ) {

    }

    ngOnInit(): void {
        this.mode                             = this.activatedRoute.snapshot.data.userEditContext;
        const initialUserSummary: UserSummary = this.getInitialUserSummary();

        // Two entry points:
        //  - of( initialUserSummary ) - first component load
        //  - this.userService.summary$           - prev/next navigation
        merge( of( initialUserSummary ), this.userService.summary$ )
            .pipe(
                loadWithProgressIndicator(
                    ( userSummary: UserSummary ) =>
                        forkJoin(
                            ... (
                                this.mode === 'my-profile'
                                    ? [
                                        this.userManagementCurrentUserService.currentUserGetDetails(),
                                        this.userManagementCurrentUserService.currentUserGetModuleGroupsForRegistration(),
                                        this.masterDataCulturesService.culturesGetList(),
                                        this.currentUserService.getCurrentUser(),
                                    ]
                                    : [
                                        this.userManagementUsersService.usersGetDetails( this.userService.userId ),
                                        this.userManagementModuleGroupsService.moduleGroupsGetList(),
                                        this.masterDataCulturesService.culturesGetList(),
                                        this.currentUserService.getCurrentUser(),
                                    ]
                            ),
                        ),
                    this,
                ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: [
                ViewModelItemUserDetails | ViewModelItemUserDetailsUserDetailsChangeability,
                ViewModelListModuleGroupInfo | ModuleGroupListItem[],
                ViewModelListCultureInfo,
                CurrentUserContext
                ] ) => {
                if ( this.mode === 'my-profile' ) {
                    this.initialize(
                        ( result[ 0 ] as ViewModelItemUserDetails ).data,
                        null,
                        ( result[ 1 ] as ViewModelListModuleGroupInfo ).data,
                        result[ 2 ].data,
                        result[ 3 ] as CurrentUserContext,
                    );
                }
                else {
                    this.initialize(
                        ( result[ 0 ] as ViewModelItemUserDetailsUserDetailsChangeability ).data,
                        ( result[ 0 ] as ViewModelItemUserDetailsUserDetailsChangeability ).changeability,
                        ( result[ 1 ] as ModuleGroupListItem[] ),
                        result[ 2 ].data,
                        result[ 3 ] as CurrentUserContext,
                    );
                }
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    startEditMode(): void {
        this.isEditMode = true;
        this.toolbarService.hidePrevNextNavigation();
        this.router.navigate( [ 'edit' ], { relativeTo: this.activatedRoute } );
    }

    endEditMode( doReset: boolean = false ): void {
        this.isEditMode = false;

        if ( doReset ) {
            this.userDetails.profileFileContainerId = this.originalUserProfileImageId;
        }

        // for profile image, company...
        this.userService.getAndPublishSummary();

        this.toolbarService.showPrevNextNavigation();
        this.router.navigate( [ '../' ], { relativeTo: this.activatedRoute } );
    }

    triggerAction( action: DropDownAction ): void {
        switch ( action.id ) {
            case 'activate':
            case 'deactivate':
                this.activateOrDeactivateUser( action.id );
                break;

            case 'delete':
                this.openUserDeleteConfirmDialog();
                break;

            case 'edit':
                this.startEditMode();
                break;

            case 'changeModuleGroups':
                this.openModuleGroupDialog();
                break;

            default:
                throw new Error( `UserProfileComponent: Unknown action '${ action.id }'.` );
        }
    }

    submitEditForm(): void {
        const formData: UserDetailsFormData = this.form.value;

        const backendCalls: Observable<Reply>[] = [];
        if ( this.mode === 'my-profile' ) {
            const updateCurrentUserDetailsCommand: UpdateCurrentUserDetails = {
                profileFileContainerId:  formData.profileImageFileId,
                phoneNumber:             formData.phoneNumber,
            };
            backendCalls.push( this.userManagementCurrentUserService.currentUserUpdateDetails( updateCurrentUserDetailsCommand ) );
        }
        else {
            const updateUserDetailsCommand: UpdateUserDetails = {
                userId:                  this.userDetails.id,
                profileFileContainerId:  formData.profileImageFileId,
                phoneNumber:             formData.phoneNumber,
            };
            backendCalls.push( this.userManagementUsersService.usersUpdateDetails( this.userDetails.id, updateUserDetailsCommand ) );

        }

        this.isLoadingSave = true;
        this.isLoading     =
            forkJoin( backendCalls )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.isLoadingSave = false;

                    const message: string = this.mode === 'my-profile'
                        ? 'Your profile changes were saved.'
                        : `Your changes to user ${ this.userNameService.format( this.userDetails ) } were saved.`;
                    this.notificationService.success(
                        message,
                        `${ this.mode === 'my-profile' ? 'Profile' : 'User' } updated`,
                    );

                    if ( this.mode === 'my-profile' ) {
                        this.currentUserService.setProfilePreviewImageId( this.userDetails.profilePreviewImageId );
                    }
                    this.endEditMode();
                } );
    }

    setProfileImageFileContainerId( uploadedFile: UploadedFileInfo | null ): void {
        this.userDetails.profileFileContainerId = uploadedFile ? uploadedFile.fileContainerId : null;
        this.userDetails.profilePreviewImageId  = uploadedFile ? uploadedFile.previewImageId : null;
    }

    closeUserDeleteConfirmDialog(): void {
        this.dialogService.closeDialog();
    }

    openModuleGroupDialog(): void {
        this.moduleGroupsDialog.openDialog();
    }

    updateUserModuleGroups( userModuleGroups: UserModuleGroupInfo[] ): void {
        this.userDetails.moduleGroups = sortBy( userModuleGroups, 'name' );
    }

    deleteUser(): void {
        this.isLoadingDelete = true;

        this.userManagementUsersService
            .usersDelete( this.userDetails.id )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                this.isLoadingDelete = false;

                this.notificationService.success(
                    `The user ${ this.userNameService.format( this.userDetails ) } was deleted.`,
                    `User deleted`,
                );

                this.closeUserDeleteConfirmDialog();
                this.router.navigate( [ '/users' ], { relativeTo: this.activatedRoute } );
            } );
    }

    private initialize(
        userData: UserDetails,
        changeability: UserDetailsChangeability | null,
        allModuleGroups: ModuleGroupListItem[] | ModuleGroupInfo[],
        allCultures: CultureInfo[],
        currentUserContext: CurrentUserContext,
    ): void {
        this.userDetails                = userData;
        this.userDetails.moduleGroups   = sortBy( this.userDetails.moduleGroups, 'name' );
        this.allModuleGroups            = sortBy( allModuleGroups, 'name' );
        this.allCultures                = allCultures;
        this.originalUserProfileImageId = this.userDetails.profileFileContainerId;

        this.currentUser = currentUserContext;

        this.isAdministrator = !!this.currentUser.accessRights
            .find( ( presentRight: CurrentUserContext.AccessRightsEnum ) => {
                return presentRight === CurrentUserContext.AccessRightsEnum.AdministrationIsAdministrator;
            } );

        this.isEditMode = this.activatedRoute.snapshot.data[ 'isEditMode' ] || false;

        if ( this.mode === 'user-management' ) {
            this.configureActions( changeability );
        }
        else {
            this.actions = [ {
                label: 'Edit',
                id:    'edit',
            } ];
        }

        this.buildForm();
    }

    private configureActions( changeability: UserDetailsChangeability ): void {
        this.actions = [];

        this.currentUserService
            .getCurrentUserAccessRightSet()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.UsersDetailsEdit ) ) {
                    this.actions.push( {
                        label: 'Edit',
                        id:    'edit',
                    } );
                    this.actions.push( {
                        label: 'Change Module Groups',
                        id:    'changeModuleGroups',
                    } );
                }
                else if ( this.userDetails.id === this.currentUser.id ) {
                    this.actions.push( {
                        label: 'Edit',
                        id:    'edit',
                    } );
                }

                if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.UsersLifeCycleStatusEdit ) ) {
                    if ( changeability.canBeActivated ) {
                        this.actions.push( {
                            label: 'Activate user',
                            id:    'activate',
                        } );
                    }

                    if ( changeability.canBeDeactivated ) {
                        this.actions.push( {
                            label: 'Deactivate user',
                            id:    'deactivate',
                        } );
                    }

                    if ( changeability.canBeDeleted ) {
                        this.actions.push( {
                            label: 'Delete user',
                            id:    'delete',
                        } );
                    }
                }

            } );
    }

    private activateOrDeactivateUser( action: 'activate' | 'deactivate' ): void {
        const backendCall: Observable<Reply> = action === 'activate'
            ? this.userManagementUsersService.usersActivate( this.userDetails.id )
            : this.userManagementUsersService.usersDeactivate( this.userDetails.id );

        this.isLoadingSave = true;
        this.isLoading     = backendCall
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                this.isLoadingSave = false;

                this.notificationService.success(
                    `The user ${ this.userNameService.format( this.userDetails ) } is now ${ action === 'activate' ? 'active' : 'inactive' }.`,
                    `User ${ action }d`,
                );

                this.userService.getAndPublishSummary();
            } );
    }

    private openUserDeleteConfirmDialog(): void {
        this.dialogService.openDialog( {
            headlineTemplate: this.deleteUserConfirmDialogHeadline,
            contentTemplate:  this.deleteUserConfirmDialogContent,
            footerTemplate:   this.deleteUserConfirmDialogFooter,
            withBackdrop:     true,
        } );
    }

    private buildForm(): void {
        this.form = this.formBuilder.group( {
            profileImageFileId:      DisplayEditUserProfileImageComponent.buildFormControl( this.userDetails.profileFileContainerId ),
            moduleGroupIds:          new UntypedFormControl(
                this.userDetails.moduleGroups
                    ? this.userDetails.moduleGroups.map( ( item: UserModuleGroupInfo ) => item.id )
                    : [],
            ),
            phoneNumber:             EditTextComponent.buildFormControl( this.userDetails.phoneNumber ),
        } );

        [
            'profile-image-field', 'module-groups-fieldset', 'phone-number-field', 'company-field', 'additional-companies-field', 'location-field',
            'wants-email-notifications-field',
        ].forEach( ( field: string ) => {
            this.htmlIdFor.set( field, this.uniqueHtmlIdService.getUniqueHtmlId( field ) );
        } );
    }

    private getInitialUserSummary(): UserSummary {
        let routeSnapshot: ActivatedRouteSnapshot = this.activatedRoute.snapshot;
        while ( !(routeSnapshot.data.currentUserSummary || routeSnapshot.data.userSummary) ) {
            routeSnapshot = routeSnapshot.parent;
        }

        const initialUserSummary: UserSummary = routeSnapshot.data.currentUserSummary || routeSnapshot.data.userSummary;
        return initialUserSummary;
    }
}
