import { Injectable, } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, } from '@angular/router';
import { Observable, of } from 'rxjs';

import { ConceptService } from './concept.service';
import { ConceptSummary } from '../../../../shared/model/conceptSummary';
import { map } from 'rxjs/operators';


@Injectable()
export class ConceptDetailResolver implements Resolve<ConceptSummary> {

    constructor( private conceptsService: ConceptService ) {
    }


    resolve( route: ActivatedRouteSnapshot ): Observable<ConceptSummary> | Observable<any> {

        const conceptId: string = route.params[ 'id' ];

        this.conceptsService.setConceptId( conceptId );

        return this.conceptsService
                   .getSummary()
                   .pipe(
                       map( ( result: ConceptSummary ) => {

                           if ( result ) {
                               return result;
                           }

                           // not found - webapi-service redirects to object-not-found page
                           return of( null );
                       } ),
                   );
    }
}
