import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { NonTradeItemSelectionService } from '../../../../shared/non-trade-item-selection.service';
import { ArticleManagementArticlesService } from '../../../../../../../shared/api/index';
import { Subscription } from 'rxjs';
import { NonTradeListItem } from '../../../../../../../shared/model/nonTradeListItem';
import { ReplyGuid } from '../../../../../../../shared/model/replyGuid';
import { NotificationService } from '../../../../../../../core/overlay/notification/notification.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EntityTypeEnum } from '../../../../../../../shared/model/enum/entity-type.enum';

@Component( {
    selector:    'rsp-set-article-status-action',
    templateUrl: './set-article-status-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        './set-article-status-dialog.component.scss',
    ],
} )
export class SetArticleStatusDialogComponent implements OnInit, OnDestroy {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    form: UntypedFormGroup;
    statusEnumType: any = EntityTypeEnum.NonTradeItem;
    articleType: NonTradeListItem.TypeEnum = NonTradeListItem.TypeEnum.Article;

    activeInPreRollout: NonTradeListItem.StatusEnum = NonTradeListItem.StatusEnum.ActiveInPreRollout;
    activeInRollout: NonTradeListItem.StatusEnum    = NonTradeListItem.StatusEnum.ActiveInRollout;
    discontinue: NonTradeListItem.StatusEnum        = NonTradeListItem.StatusEnum.Discontinue;
    activeOnDemand: NonTradeListItem.StatusEnum     = NonTradeListItem.StatusEnum.ActiveOnDemand;
    inactive: NonTradeListItem.StatusEnum           = NonTradeListItem.StatusEnum.Inactive;

    hasAssembliesSelected: boolean = false;
    isProcessing: boolean          = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private articlesApi: ArticleManagementArticlesService,
        private dialogService: DialogService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.subscription.add(
            this.nonTradeItemSelectionService
                .selectedItems$
                .pipe(
                    map( ( listItems: Array<NonTradeListItem> ) => {
                        return listItems.filter( ( item: NonTradeListItem ) => item.type === NonTradeListItem.TypeEnum.Assembly ).length > 0;
                    } ),
                    distinctUntilChanged(),
                )
                .subscribe( ( hasAssemblies: boolean ) => {
                    this.hasAssembliesSelected = hasAssemblies;
                } ),
        );
    }

    setStatus( status: string ): void {
        this.form.get( 'status' ).setValue( status );
        if ( status === 'inactive' || status === 'discontinue') {
            this.form.get( 'reason' ).setValidators( Validators.required );
        }
        else {
            this.form.get( 'reason' ).clearValidators();
        }
        this.form.get( 'reason' ).setValue( '' );
        this.form.get('reason').updateValueAndValidity({ emitEvent: true});
    }

    doAction(): void {
        const articleIds: string[] = this.nonTradeItemSelectionService.getSelectedArticles().map( ( item: NonTradeListItem ) => {
            return item.id;
        } );
        this.subscription.add(
            this.articlesApi.articlesUpdateStatusForArticles(
                this.form.get( 'status' ).value,
                this.form.get('reason').value,
                articleIds,
            ).subscribe( ( result: ReplyGuid ) => {
                this.notificationService.success(
                    'Process started.',
                    'Set Article Status',
                );
                this.close();
            } ),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    open(): void {
        this.form = new UntypedFormGroup( {
            status: new UntypedFormControl( null, Validators.required ),
            reason: new UntypedFormControl(''),
        } );

        this.dialogService.openDialog( {
            contentTemplate:  this.contentTemplate,
            headlineTemplate: this.headlineTemplate,
            footerTemplate:   this.footerTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }

}
