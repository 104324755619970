/* tslint:disable:max-line-length */

import { Component, OnDestroy, OnInit, ViewChild, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, merge, of, Subject, forkJoin } from 'rxjs';
import { distinctUntilChanged, takeUntil, tap, } from 'rxjs/operators';

import { ArticleManagementArticlesService, IndexManagementService } from '../../../../../shared/api';
import { ArticleDetail, } from '../../../../../shared/model/articleDetail';
import { AssemblyDetail, } from '../../../../../shared/model/assemblyDetail';
import { NonTradeItemService, } from '../../shared/non-trade-item.service';
import { CurrentNonTradeItem, } from '../../shared/current-non-trade-item.model';
import { NonTradeItemType, } from '../../shared/non-trade-item-type.model';
import { NonTradeItemDetailsService } from './non-trade-item-details.service';
import { NotificationService } from '../../../../../core/overlay/notification/notification.service';
import { BrandLogoInfo } from '../../../../../shared/model/brandLogoInfo';
import { CardType } from '../../../../../shared/ui/card/card.component';
import { UniqueHtmlIdService } from '../../../../../core/unique-html-id.service';
import { MessageType, MessagesCssSubmodule } from '../../../../../shared/ui/messages/messages.component';
import { Reply } from '../../../../../shared/model/reply';
import { EditTextComponent } from '../../../../../shared/forms/controls/edit-text/edit-text.component';
import { CustomValidators } from '../../../../../shared/forms/validation/custom-validators';
import {
    DisplayEditWidthDepthHeightComponent,
} from '../../../../../shared/forms/controls/display-edit-width-height-depth/display-edit-width-depth-height.component';
import { CapacityTypeInfo } from '../../../../../shared/model/capacityTypeInfo';
import { EditCapacitiesComponent } from '../../shared/forms/display-edit-capacities/edit-capacities.component';
import { ArticleDetailChangeability } from '../../../../../shared/model/articleDetailChangeability';
import { AssemblyDetailChangeability } from '../../../../../shared/model/assemblyDetailChangeability';
import { DropDownAction } from '../../../../../shared/forms/edit-form-buttons/edit-form-buttons.component';
import { EditKeywordsComponent } from '../../../../../shared/forms/controls/edit-keywords/edit-keywords.component';
import { CurrentUserService } from '../../../../../core/current-user.service';
import { ArticleManagementAssembliesService } from '../../../../../shared/api/articleManagementAssemblies.service';
import { CurrentUserContext } from '../../../../../shared/model';
import { SecondaryToolbarService } from '../../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { EnumConverterService } from '../../../../../shared/utils/enum/enum-converter.service';
import { loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { CardRequiredFilesComponent } from './card-required-files/card-required-files.component';
import { SetNonTradeItemStatusDialogComponent } from './set-non-trade-item-status-dialog/set-non-trade-item-status-dialog.component';
import { ARTICLE_PROCUREMENT_CATEGORIES, ArticleProcurementCategory } from '../../../../../shared/utils/procurement-categories';

/* tslint:enable:max-line-length */

@Component(
    {
        selector:    'rsp-non-trade-item-details',
        templateUrl: './non-trade-item-details.component.html',
        styleUrls:   [
            '../../../../../shared/scss/04_layout/two-columns-1200.scss',
            '../../../../../shared/scss/05_module/detail-page-tab.scss',
            './non-trade-item-details.component.scss',
        ],
        providers:   [ NonTradeItemDetailsService ],
    },
)
export class NonTradeItemDetailsComponent implements OnInit, OnDestroy {
    @ViewChild( SetNonTradeItemStatusDialogComponent, { static: true } ) setNonTradeItemStatusDialog: SetNonTradeItemStatusDialogComponent;

    isEditMode: boolean = false;
    form: UntypedFormGroup;

    articleDetails: ArticleDetail;
    articlePermissions: ArticleDetailChangeability;
    assemblyDetails: AssemblyDetail;
    assemblyPermissions: AssemblyDetailChangeability;
    allAppliedBrandLogos: Array<{ id: string, name: string }>;
    cardType: any                          = CardType;
    messageType: any                       = MessageType;
    messagesCssSubmodule: any              = MessagesCssSubmodule;
    htmlIdFor: { [ key: string ]: string } = {};
    changeAction: DropDownAction[]         = [];

    currentItem: CurrentNonTradeItem;

    isLoading: Subscription;
    isLoadingSave: boolean = false;

    isSPMGroupAndTypeEditable: boolean;

    allCategories: Array<ArticleProcurementCategory> = ARTICLE_PROCUREMENT_CATEGORIES;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private articlesApi: ArticleManagementArticlesService,
        private assembliesApi: ArticleManagementAssembliesService,
        private nonTradeItemService: NonTradeItemService,
        private nonTradeItemDetailsService: NonTradeItemDetailsService,
        private indexManagementService: IndexManagementService,
        private toolbarService: SecondaryToolbarService,
        private notificationService: NotificationService,
        private route: ActivatedRoute,
        private router: Router,
        private uniqueHtmlIdService: UniqueHtmlIdService,
        private formBuilder: UntypedFormBuilder,
        private currentUserService: CurrentUserService,
    ) {
    }

    ngOnInit(): void {

        merge( of( true ), this.nonTradeItemService.currentNonTradeItem$ )
            .pipe(
                tap( () => this.isEditMode = this.route.snapshot.data[ 'isEditMode' ] || false ),
                distinctUntilChanged(),
                loadWithProgressIndicator( () => this.nonTradeItemService.getDetail( this.isEditMode ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( results: Array<any> ) => {

                // results[0] - article/assembly details
                // results[1] - (only in edit mode) brand logos
                // results[2] - (only in edit mode) capacityInfoOptions
                const details: ArticleDetail | AssemblyDetail                               = results[ 0 ].data;
                const permissions: ArticleDetailChangeability | AssemblyDetailChangeability = results[ 0 ].changeability;

                this.currentItem = this.nonTradeItemService.getCurrentNonTradeItem();

                if ( !this.isEditMode ) {
                    this.initDisplayMode( details, permissions );
                } else {
                    const brandLogos: Array<BrandLogoInfo>             = results[ 1 ] ? results[ 1 ].data : undefined;
                    const capacityTypeOptions: Array<CapacityTypeInfo> = results[ 2 ] ? results[ 2 ].data : undefined;

                    this.initEditMode( details, permissions, brandLogos, capacityTypeOptions );
                }

            } );
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    isArticle(): boolean {
        return this.currentItem !== null
            && this.currentItem.type === NonTradeItemType.Article;
    }

    isAssembly(): boolean {
        return this.currentItem !== null
            && this.currentItem.type === NonTradeItemType.Assembly;
    }

    startEditMode(): void {
        this.toolbarService.hidePrevNextNavigation();
        this.router.navigate( [ 'edit' ], { relativeTo: this.route } );
    }

    endEditMode(): void {
        this.toolbarService.showPrevNextNavigation();
        this.router.navigate( [ '../' ], { relativeTo: this.route } );
    }

    submitForm(): void {
        this.isLoadingSave = true;

        const api: Observable<Reply> =
                  this.currentItem.type === NonTradeItemType.Article
                      ? this.nonTradeItemDetailsService.saveArticleDetails( this.currentItem.id, this.form.value )
                      : this.nonTradeItemDetailsService.saveAssemblyDetails( this.currentItem.id, this.form.value );

        this.isLoading =
            api.pipe( takeUntil( this.isDestroyed ) )
               .subscribe( () => {
                       this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
                       this.notificationService.success( 'Saved successfully.' );
                       this.endEditMode();
                   },
               );
    }

    triggerAction( action: DropDownAction ): void {
        switch ( action.id ) {
            case 'edit':
                this.startEditMode();
                break;
            case 'publish':
                this.isLoading =
                    this.assembliesApi
                        .assembliesUpdatePublishedStatusForAssemblies( true, [ this.assemblyDetails.id ] )
                        .pipe( takeUntil( this.isDestroyed ) )
                        .subscribe( () => {
                            this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
                            this.notificationService.success( 'Status was successfully changed to published.' );
                        } );
                break;
            case 'unpublish':
                this.isLoading =
                    this.assembliesApi
                        .assembliesUpdatePublishedStatusForAssemblies( false, [ this.assemblyDetails.id ] )
                        .pipe( takeUntil( this.isDestroyed ) )
                        .subscribe( () => {
                            this.nonTradeItemService.getAndPublishCurrentNonTradeItem();
                            this.notificationService.success( 'Status was successfully changed to unpublished.' );
                        } );
                break;
            case 'refresh-article':
                const articleApiCalls: Array<Observable<Reply>> = [
                    this.indexManagementService.indicesUpdateArticleDocuments( [ this.currentItem.id ] ),
                ];
                this.isLoading                                  =
                    forkJoin( articleApiCalls )
                        .subscribe( () => {
                            this.notificationService.success(
                                `Refresh was successfully triggered.`,
                            );
                        } );
                break;
            case 'refresh-assembly':
                const assemblyApiCalls: Array<Observable<Reply>> = [
                    this.indexManagementService.indicesUpdateAssemblyDocuments( [ this.currentItem.id ] ),
                ];
                this.isLoading                                   =
                    forkJoin( assemblyApiCalls )
                        .subscribe( () => {
                            this.notificationService.success(
                                `Refresh was successfully triggered.`,
                            );
                        } );
                break;
            default:
                this.setNonTradeItemStatusDialog.open( this.currentItem.id, action.id );
                break;
        }
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private initDisplayMode(
        details: ArticleDetail | AssemblyDetail,
        permissions: ArticleDetailChangeability | AssemblyDetailChangeability,
    ): void {

        this.setDetails( details );

        this.setPermissions( permissions );
    }

    private initEditMode(
        details: ArticleDetail | AssemblyDetail,
        permissions: ArticleDetailChangeability | AssemblyDetailChangeability,
        brandLogos: Array<BrandLogoInfo>,
        capacityTypeOptions: Array<CapacityTypeInfo>,
    ): void {

        this.setDetails( details );

        this.setPermissions( permissions );

        if ( brandLogos ) {
            this.setBrandLogos( brandLogos );
        }

        this.buildForm( details, capacityTypeOptions );

        this.setHtmlIds();
    }

    private setHtmlIds(): void {

        this.htmlIdFor[ 'nameField' ]                = this.uniqueHtmlIdService.getUniqueHtmlId( 'name-field' );
        this.htmlIdFor[ 'packagingUnit' ]            = this.uniqueHtmlIdService.getUniqueHtmlId( 'packaging-unit-field' );
        this.htmlIdFor[ 'noteField' ]                = this.uniqueHtmlIdService.getUniqueHtmlId( 'note-field' );
        this.htmlIdFor[ 'legacyArticleNumberField' ] = this.uniqueHtmlIdService.getUniqueHtmlId( 'legacy-article-number-field' );
        this.htmlIdFor[ 'descriptionField' ]         = this.uniqueHtmlIdService.getUniqueHtmlId( 'description-field' );
        this.htmlIdFor[ 'appliedBrandLogoField' ]    = this.uniqueHtmlIdService.getUniqueHtmlId( 'applied-brand-logo-field' );
        this.htmlIdFor[ 'weightAssembledField' ]     = this.uniqueHtmlIdService.getUniqueHtmlId( 'weight-assembled-field' );
        this.htmlIdFor[ 'weightPackedField' ]        = this.uniqueHtmlIdService.getUniqueHtmlId( 'weight-packed-field' );
        this.htmlIdFor[ 'customsTariffNumberField' ] = this.uniqueHtmlIdService.getUniqueHtmlId( 'customs-tariff-number-field' );
        this.htmlIdFor[ 'engineeringPartnerField' ]  = this.uniqueHtmlIdService.getUniqueHtmlId( 'engineering-partner-field' );
        this.htmlIdFor[ 'categoryField' ]            = this.uniqueHtmlIdService.getUniqueHtmlId( 'category-field' );
    }

    private buildForm( details: ArticleDetail | AssemblyDetail, capacityTypeOptions?: Array<CapacityTypeInfo> ): void {
        this.form = this.formBuilder.group( {
            name:        EditTextComponent.buildFormControl( details.name, [ Validators.required, Validators.maxLength(73) ] ),
            note:        EditTextComponent.buildFormControl( details.note ),
            description: EditTextComponent.buildFormControl( details.description ),
            keywordIds:  EditKeywordsComponent.buildFormControl( details.keywords ),
            spmTypeId:   new UntypedFormControl( details.spmType ? details.spmType.id : null ),
            spmGroupId:  new UntypedFormControl( details.spmGroup ? details.spmGroup.id : null ),
            assembledDimensions:
                         DisplayEditWidthDepthHeightComponent.buildFormGroup(
                             details.assembledDimensions.width,
                             details.assembledDimensions.height,
                             details.assembledDimensions.depth,
                         ),
        } );

        if ( this.isArticle() ) {

            this.form.addControl(
                'appliedBrandLogoId',
                new UntypedFormControl( this.articleDetails.appliedBrandLogo ? this.articleDetails.appliedBrandLogo.id : null ),
            );

            this.form.addControl(
                'categoryId',
                new UntypedFormControl( this.articleDetails.category ? this.articleDetails.category : null ),
            );

            this.form.addControl(
                'packagingUnit',
                EditTextComponent.buildFormControl(
                    this.articleDetails.packagingUnit ? this.articleDetails.packagingUnit.toString() : null,
                    [ Validators.required, CustomValidators.isInteger, CustomValidators.positiveNumber ],
                ),
            );
            this.form.addControl( 'materialInfo', EditTextComponent.buildFormControl( this.articleDetails.materialInfo ) );
            this.form.addControl( 'customsTariffNumber', EditTextComponent.buildFormControl( this.articleDetails.customsTariffNumber ) );
            this.form.addControl( 'legacyArticleNumber', EditTextComponent.buildFormControl( this.articleDetails.legacyNumber ) );

            this.form.addControl(
                'assembledDimensionsWeight',
                EditTextComponent.buildFormControl(
                    this.articleDetails.assembledDimensions.weight ? this.articleDetails.assembledDimensions.weight.toString() : null,
                    [ CustomValidators.positiveNumber ],
                ),
            );

            this.form.addControl(
                'packedDimensionsWeight',
                EditTextComponent.buildFormControl(
                    this.articleDetails.packedDimensions.weight ? this.articleDetails.packedDimensions.weight.toString() : null,
                    [ CustomValidators.positiveNumber ],
                ),
            );

            this.form.addControl(
                'packedDimensions',
                DisplayEditWidthDepthHeightComponent.buildFormGroup(
                    this.articleDetails.packedDimensions.width,
                    this.articleDetails.packedDimensions.height,
                    this.articleDetails.packedDimensions.depth,
                ),
            );

            this.form.addControl(
                'capacities',
                EditCapacitiesComponent.buildFormGroup( capacityTypeOptions, this.articleDetails.capacities, this.articleDetails.needsCapacity ),
            );

            this.form.addControl( 'engineeringPartnerName', EditTextComponent.buildFormControl( this.articleDetails.engineeringPartnerName ) );

            this.form.addControl(
                'requiredFiles',
                CardRequiredFilesComponent.buildFormControl( this.articleDetails.requiredFiles ),
            );
        }
    }

    private setDetails( details: ArticleDetail | AssemblyDetail ): void {

        if ( this.currentItem.type === NonTradeItemType.Article ) {
            this.articleDetails = <ArticleDetail> details;
        } else {
            this.assemblyDetails = <AssemblyDetail> details;
        }
    }

    private setBrandLogos( brandLogos: Array<BrandLogoInfo> ): void {
        this.allAppliedBrandLogos = brandLogos.map( ( option: BrandLogoInfo ) => {
            return { id: option.id, name: option.name };
        } );
    }

    private setPermissions( permissions: ArticleDetailChangeability | AssemblyDetailChangeability ): void {

        this.changeAction = [];

        if ( this.currentItem.type === NonTradeItemType.Article ) {

            this.articlePermissions = <ArticleDetailChangeability> permissions;

            this.isSPMGroupAndTypeEditable = this.articlePermissions.canEditSPMGroupAndSPMType;

            this.currentUserService
                .getCurrentUserAccessRightSet()
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                    if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesArticleDetailsEdit ) ) {
                        this.changeAction.push( {
                            label: 'Edit',
                            id:    'edit',
                        } );
                    }

                    if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesArticleLifeCycleStatusEdit ) ) {
                        this.articlePermissions.availableStatus.forEach( ( status: ArticleDetailChangeability.AvailableStatusEnum ) => {
                            this.changeAction.push( {
                                label: 'Set to ' + EnumConverterService.getHumanReadableEnumValue( ArticleDetailChangeability.AvailableStatusEnum, status ),
                                id:    status.toString(),
                            } );
                        } );
                    }

                    if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.AdministrationIsAdministrator ) ) {
                        this.changeAction.push( {
                            label: 'Refresh Metadata',
                            id:    'refresh-article',
                        } );
                    }
                } );
        } else {
            this.assemblyPermissions = <AssemblyDetailChangeability> permissions;

            this.currentUserService
                .getCurrentUserAccessRightSet()
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                    if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyDetailsEdit ) ) {
                        this.changeAction.push( {
                            label: 'Edit',
                            id:    'edit',
                        } );
                    }

                    if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.ArticlesAndAssembliesAssemblyLifeCycleStatusEdit ) ) {
                        if ( this.assemblyPermissions.canBePublished ) {
                            this.changeAction.push( {
                                label: 'Set to published',
                                id:    'publish',
                            } );
                        } else if ( this.assemblyPermissions.canBeUnpublished ) {
                            this.changeAction.push( {
                                label: 'Set to unpublished',
                                id:    'unpublish',
                            } );
                        }
                    }

                    if ( accessRightSet.has( CurrentUserContext.AccessRightsEnum.AdministrationIsAdministrator ) ) {
                        this.changeAction.push( {
                            label: 'Refresh Metadata',
                            id:    'refresh-assembly',
                        } );
                    }
                } );
        }
    }
}
