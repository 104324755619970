import { Injectable, TemplateRef } from '@angular/core';

import { BehaviorSubject ,  Subject ,  Observable } from 'rxjs';


export interface ConfirmDialogConfig {
    text?: String;
    template?: TemplateRef<any>;
    templateData?: any;
    anchorElement: HTMLElement;
    confirmButtonCaption?: string;
    cancelButtonCaption?: string;
    withReason?: boolean;
    confirmCallback( reason?: string ): void;
    cancelCallback?(): void;
}

@Injectable()
export class ConfirmDialogService {

    dialogConfig$: Observable<ConfirmDialogConfig>;
    isDialogVisible$: Observable<boolean>;

    private dialogConfig: Subject<ConfirmDialogConfig> = new Subject();
    private isDialogVisible: BehaviorSubject<boolean>  = new BehaviorSubject( false );

    constructor() {
        this.dialogConfig$    = this.dialogConfig.asObservable();
        this.isDialogVisible$ = this.isDialogVisible.asObservable();
    }

    openConfirmDialog( config: ConfirmDialogConfig ): void {
        if ( !config.withReason ) {
            config.withReason = false;
        }
        this.dialogConfig.next( config );
        this.isDialogVisible.next( true );
    }

    closeConfirmDialog(): void {
        this.isDialogVisible.next( false );
    }
}
