import { Component, } from '@angular/core';

import { StorageService, } from '../../../../../../core/storage.service';
import { FacetValue } from '../../../../../model/facetValue';
import { FacetTypeSelectComponent, } from '../facet-type-select.component';
import {
    FacetTypeSelectComponent as FacetTypeSelectComponentInterface,
    FacetTypeSelect, SplitFacetValues,
} from '../facet-type-select.model';
import { FacetService, } from '../../facet.service';
import { facetAnimations, } from '../../facet.animations';
import { ExpandStateForValue, SubValueSelectedStateForValue, } from './facet-type-multiselect-hierarchical.model';
import { VerticalScrollService } from '../../../../lists/vertical-scroll/vertical-scroll.service';
import { FacetItem } from '../../../../../model/facetItem';
import { FacetFilterItem } from '../../../../../model/facetFilterItem';

interface AreBottomFacetSubValuesVisibleForValue {
    [ facetValueName: string ]: boolean;
}

/**
 * Generates a facet for the `MultiselectHierarchical`-type.
 *
 * ```html
 * <rsp-facet-type-multiselect-hierarchical facet="FacetItem" (valueChanged)="yourOnValueChanged()">
 * </rsp-facet-type-multiselect-hierarchical>
 * ```
 */
// Inputs and outputs are defined in the base class, so we have to use `inputs` and `outputs` in the decorator.
/* tslint:disable:use-input-property-decorator use-output-property-decorator */
@Component( {
    selector:    'rsp-facet-type-multiselect-hierarchical',
    templateUrl: '../facet-type-select.component.html',
    styleUrls:   [
        './facet-type-multiselect-hierarchical.component.scss',
    ],
    inputs:      [ 'facet' ], // tslint:disable-line:no-inputs-metadata-property
    outputs:     [ 'valueChanged' ], // tslint:disable-line:no-outputs-metadata-property
    animations:  facetAnimations,
    /* tslint:enable:use-input-property-decorator use-output-property-decorator */
} )
export class FacetTypeMultiselectHierarchicalComponent extends FacetTypeSelectComponent implements FacetTypeSelectComponentInterface {
    type: FacetTypeSelect = FacetItem.TypeEnum.MultiselectHierarchical;

    cssModuleName: string                                                                     = 'facet-type-multiselect-hierarchical';
    expandStateForValue: ExpandStateForValue                                                  = {};
    subValueSelectedStateForValue: SubValueSelectedStateForValue                              = {};
    topFacetSubValuesForValue:              { [ facetValueName: string ]: Array<FacetValue> } = {};
    bottomFacetSubValuesForValue:           { [ facetValueName: string ]: Array<FacetValue> } = {};
    areBottomFacetSubValuesVisibleForValue: AreBottomFacetSubValuesVisibleForValue            = {};

    set facet( facet: FacetItem ) {
        this.setFacet( facet ); // will call this.initStorageKeys()
        this.initExpandedStateForValue();
        this.initSubValueSelectedStateForValue();
        this.initSplitFacetValuesForSubvalues();
    }
    get facet(): FacetItem {
        return this._facet;
    }

    protected _facet: FacetItem;


    constructor(
        storageService: StorageService,
        verticalScrollService: VerticalScrollService,
        private facetService: FacetService,
    ) {
        super( storageService, verticalScrollService );
    }

    toggleFacetValueExpansion( facetValue: FacetValue, event?: MouseEvent ): void {
        this.expandStateForValue[ facetValue.value ].isExpanded = !this.expandStateForValue[ facetValue.value ].isExpanded;

        this.storageService.set<ExpandStateForValue>( this.storageKeyFor[ 'expandStateForValue' ], this.expandStateForValue );

        // if the toggle button was clicked, don't bubble event further, because then onValueClick will retrigger this method.
        if ( event && ( event.target as HTMLElement ).nodeName.toLowerCase() === 'button' ) {
            event.stopPropagation();
        }
    }

    onValueClick( facetValue: FacetValue, event: MouseEvent ): void {
        const clickedHtmlNode: HTMLElement = event.target as HTMLElement;
        if ( clickedHtmlNode.nodeName.toLocaleLowerCase() === 'input' && clickedHtmlNode.getAttribute( 'type' ) === 'checkbox' ) {
            // this click event isn't for us, it was for the subvalue-state checkbox
            return;
        }
        else if ( Array.isArray( facetValue.facets ) && facetValue.facets.length > 0 ) {
            this.toggleFacetValueExpansion( facetValue );
        }
        else {
            facetValue.isSelected = !facetValue.isSelected;
            this.onValueChanged( facetValue );
        }
    }

    onSubvalueStateCheckboxClick( facetValue: FacetValue, event: MouseEvent ): void {
        const checkbox: HTMLInputElement = event.target as HTMLInputElement;
        facetValue.facets[ 0 ].values.forEach( ( subValue: FacetValue ) => {
            subValue.isSelected = checkbox.checked;
        } );

        this.subValueSelectedStateForValue[ facetValue.value ] = checkbox.checked ? 'all' : 'none';

        const facetFilter: FacetFilterItem = this.facetService.getFacetFilterForMultiselectHierarchicalFacet( this._facet );
        this.valueChanged.emit( { facetName: this.facet.name, facetFilter: facetFilter, } );
    }

    onValueChanged( value: FacetValue ): void {
        const facetFilter: FacetFilterItem = this.facetService.getFacetFilterForMultiselectHierarchicalFacet( this._facet );

        this.valueChanged.emit( { facetName: this.facet.name, facetFilter: facetFilter, } );
    }

    showBottomFacetSubValuesForValue( value: FacetValue ): void {
        this.areBottomFacetSubValuesVisibleForValue[ value.value ] = true;

        this.storageService.set<AreBottomFacetSubValuesVisibleForValue>(
            this.storageKeyFor[ 'areBottomFacetSubValuesVisibleForValue' ], this.areBottomFacetSubValuesVisibleForValue,
        );

        // give the browser a moment, to expand the list
        setTimeout( () => {
            this.verticalScrollService.refreshScrollCalculation();
        } );
    }

    protected initStorageKeys(): void {
        super.initStorageKeys();

        const keyPrefix: string                                        = this.getStorageKeyPrefix();
        this.storageKeyFor[ 'expandStateForValue' ]                    = keyPrefix + 'expandStateForValue';
        this.storageKeyFor[ 'areBottomFacetSubValuesVisibleForValue' ] = keyPrefix + 'areBottomFacetSubValuesVisibleForValue';
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private initExpandedStateForValue(): void {
        let expandStateForValue: ExpandStateForValue =
                this.storageService.get<ExpandStateForValue>( this.storageKeyFor[ 'expandStateForValue' ] );

        if ( !( expandStateForValue instanceof Object ) ) {
            expandStateForValue = {};
        }

        // We may get facet values from the server, that we didn't see before (and therefore weren't in our storage), so may end up with access to
        // `expandStateForValue[ newPreviouslyUnseenValueThatIsnotInThisObjectYet ].isExpanded` which will trigger an "Can't accesss property `isExpanded` on
        // undefined". => Add the facetItem.name to expandedStateForValue.
        this._facet.values.forEach( ( facetValue: FacetValue ) => {
            if ( !expandStateForValue[ facetValue.value ] ) {
                expandStateForValue[ facetValue.value ] = {
                    isExpanded: false,
                };
            }
        } );

        this.expandStateForValue = expandStateForValue;
    }

    private initSubValueSelectedStateForValue(): void {
        this.facet.values.forEach( ( facetValue: FacetValue ) => {
            const numberOfSubValues: number         = facetValue.facets[0].values.length;
            const numberOfSelectedSubValues: number = facetValue.facets[0].values.filter( ( subValue: FacetValue ) => subValue.isSelected ).length;

            this.subValueSelectedStateForValue[ facetValue.value ] =
                numberOfSelectedSubValues === numberOfSubValues ? 'all'  :
                numberOfSelectedSubValues === 0                 ? 'none' :
                                                                  'indeterminate';
        } );
    }

    private initSplitFacetValuesForSubvalues(): void {
        this.areBottomFacetSubValuesVisibleForValue
            = this.storageService
                  .get<AreBottomFacetSubValuesVisibleForValue>( this.storageKeyFor[ 'areBottomFacetSubValuesVisibleForValue' ] ) || {};

        this.facet.values.forEach( ( facetValue: FacetValue ) => {
            // the "subfacets" (formerly known as "subvalues") only have one element which is a special facet called "Cluster" or "SPM Type" or whatever
            // it is semantically.
            if ( facetValue.facets.length !== 1 ) {
                throw new Error( `FacetTypeMultiselectHierarchicalComponent: Unexpected sub facet length '${ facetValue.facets.length }' .` );
            }
            const splitFacetValues: SplitFacetValues = this.splitFacetValues( facetValue.facets[ 0 ].values );

            this.topFacetSubValuesForValue[    facetValue.value ] = splitFacetValues.topFacetValues as FacetValue[];
            this.bottomFacetSubValuesForValue[ facetValue.value ] = splitFacetValues.bottomFacetValues as FacetValue[];

            if ( !this.areBottomFacetSubValuesVisibleForValue[ facetValue.value ] ) {
                this.areBottomFacetSubValuesVisibleForValue[ facetValue.value ] = false;
            }
        } );
    }
}
