import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { MasterDataCurrenciesService } from '../../../api';
import { ViewModelPagedListCurrencyInfo } from '../../../model/viewModelPagedListCurrencyInfo';
import { CurrencyInfo } from '../../../model/currencyInfo';


/**
 * Currency Suggester - search currencies using specified search term.
 */
@Component( {
    selector:    'rsp-currency-suggester',
    templateUrl: './currency-suggester.component.html',
    styleUrls:   [ './currency-suggester.component.scss', ],
} )
export class CurrencySuggesterComponent {

    @Output() selected: EventEmitter<CurrencyInfo> = new EventEmitter<CurrencyInfo>();

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    /**
     * Text that will be used as placeholder attribute for the `<input>`-field.
     */
    @Input() placeholder: string = 'Search Currencies';

    /**
     * Optional value displayed in dropdown's text field.
     */
    @Input() displayedValue: string;

    /**
     * Should the suggester input be disabled?
     */
    @Input() disabled: boolean = false;

    @Input() itemFilter: Array<string> = [];


    items: Array<CurrencyInfo> = [];

    isLoading: Subscription;


    constructor( private masterDataCurrenciesApi: MasterDataCurrenciesService ) { }

    searchItems( searchTerm: string ): void {

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }

        this.items = [];

        if ( !searchTerm || !searchTerm.trim() ) {
            searchTerm = '*';
        }

        this.isLoading =
            this.masterDataCurrenciesApi
                .currenciesSuggest( {
                    term: searchTerm,
                    page: 1,
                    size: 50,
                    ignoreIds: this.itemFilter,
                }, )
                .subscribe( ( result: ViewModelPagedListCurrencyInfo ) => {
                    this.items = result.data;
                } );
    }

    selectedItem( item: CurrencyInfo ): void {
        this.selected.emit( item );
    }
}
