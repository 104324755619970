/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { FacetValue } from './facetValue';


export interface FacetItem {
    name?: string;

    values?: Array<FacetValue>;

    type?: FacetItem.TypeEnum;

}

export namespace FacetItem {
    'use strict';
    export enum TypeEnum {
        Multiselect,
        MultiselectHierarchical,
        Singleselect,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
}

