<h2 class="registration-wizard-section-headline">
    <rsp-task-todo
        [isDone]="isDone"
        class="registration-wizard-section-headline--icon"
    ></rsp-task-todo>

    <span class="registration-wizard-section-headline--text">
        <ng-content></ng-content>
    </span>
</h2>
