import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { BaseCheckboxRadiobuttonDirective } from './base-checkbox-radiobutton.directive';


/**
 * Transforms a standard `<input type="radio">` to our styled radiobuttons.
 *
 * Usage:
 * ```
 *     <input
 *         rspRadiobutton
 *         [rspRadiobuttonWrapperClass]="'an-additional-class-if-needed'"
 *         [rspRadiobuttonWrapperClassOnChecked]="'an-additional-optional-state-class-that-is-added-to-the-wrapper-when-the-radiobutton-is-checked'"
 *         type="radio"
 *     >
 * ```
 *
 * Final markup for the above example will be:
 * ```
 * <div class="an-additional-class-if-needed radiobutton">
 *     <input class="radiobutton--radiobutton" type="radio">
 *     <span class="radiobutton--fake-radiobutton"></span>
 * </div>
 * ```
 *
 * Any additional attributes provided to the original `<input type="radio">` will be preserved.
 */
@Directive( {
    selector: '[rspRadiobutton]',
} )
export class RadioButtonDirective extends BaseCheckboxRadiobuttonDirective {

    @Input()
    set rspRadiobuttonWrapperClass( value: string ) {
        this.rspWrapperClass = value;
    }

    @Input()
    set rspRadiobuttonWrapperClassOnChecked( value: string ) {
        this.rspWrapperClassOnChecked = value;
    }


    constructor(
        inputElement: ElementRef,
        renderer: Renderer2,
    ) {
        super( 'radiobutton', inputElement, renderer );
    }
}
