import { Directive, HostListener, ElementRef, Output, EventEmitter, Input } from '@angular/core';

@Directive({
    selector: '[rspClickOutside]',
})
export class ClickOutsideDirective {

    /* tslint:disable:no-output-rename */
    @Output( 'rspClickOutside' )
    public clickOutside: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
    /* tslint:enable:no-output-rename */

    /**
     * clickOutside Event is only emitted, if this is true (default).
     * Set this to false, to reduce the amount of events (e.g. if the elementRef is hidden / not visible).
     * @type {boolean}
     */
    @Input() isClickOutsideActive: boolean = true;

    constructor(
        private elementRef: ElementRef,
    ) {}

    @HostListener( 'document:click', [ '$event', '$event.target' ] )
    public onClick( event: MouseEvent, targetElement: HTMLElement ): void {
        if ( !targetElement ) {
            return;
        }

        const clickedInside: boolean = this.elementRef.nativeElement.contains( targetElement );
        if ( !clickedInside && this.isClickOutsideActive ) {
            this.clickOutside.emit( event );
        }
    }

}

