import { Component, Input } from '@angular/core';
import { FileManagementFilesService } from '../../api';

/**
 * ```
 * <rsp-download-link [fileGuid]="someFileGuid">Download me</rsp-download-link>
 * ```
 */
@Component( {
    selector:    'rsp-download-link',
    templateUrl: './download-link.component.html',
    styleUrls:   [
        './download-link.component.scss',
    ],
} )
export class DownloadLinkComponent {
    @Input()
    set fileGuid( value: string ) {
        if ( value ) {
            this._fileGuid = value;
            this.href      = this.filesApi.fileContainersGetFileAuthenticatedUrl( value );
        }
    }
    get fileGuid(): string {
        return this._fileGuid;
    }

    @Input()
    set versionGuid( value: string ) {
        this._versionGuid = value;
        this.versionHref = this.filesApi.fileContainersGetFileVersionAuthenticatedUrl( this.fileGuid, this.versionGuid );
    }
    get versionGuid(): string {
        return this._versionGuid;
    }

    @Input()
    set fileGuidAnonymous( value: string ) {
        if ( value ) {
            this._fileGuid   = value;
            this.href = this.filesApi.fileContainersGetFileAnonymousUrl( value );
        }
    }
    get fileGuidAnonymous(): string {
        return this._fileGuid;
    }

    @Input()
    set staticFileName( value: string ) {
        if ( value ) {
            this._fileGuid   = value;
            this.href = '/assets/documents/' + value;
        }
    }
    get staticFileName(): string {
        return this._fileGuid;
    }

    href: string;
    versionHref: string;

    private _fileGuid: string;
    private _versionGuid: string;

    constructor(
        private filesApi: FileManagementFilesService,
    ) { }

}
