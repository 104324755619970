import { NgModule } from '@angular/core';

import { SharedModule } from '../../shared/shared.module';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { WelcomeUserComponent } from './dashboard/welcome-user/welcome-user.component';
import { FileExportsComponent } from './dashboard/file-exports/file-exports.component';
import { FileExportsDialogComponent } from './dashboard/file-exports/file-exports-dialog/file-exports-dialog.component';

@NgModule( {
    declarations: [
        FileExportsComponent,
        DashboardComponent,
        WelcomeUserComponent,
        FileExportsDialogComponent,
    ],
    imports:      [
        DashboardRoutingModule,
        SharedModule,
    ],
} )
export class DashboardModule {
}
