<rsp-button
    [type]="!href ? type : null"
    [href]="href ? href : null"
    [submodule]="'seamless'"
    [disabled]="disabled"
    [class]="cssModuleName"
    [class.is-disabled]="disabled"
    (click)="emitClicked( $event )"
>
    <span class="{{ cssModuleName }}--assistive-content">
        <ng-content></ng-content>
    </span>
</rsp-button>
