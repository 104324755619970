/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { CreateBrandLogo } from '../model/createBrandLogo';
import { Reply } from '../model/reply';
import { ReplyGuid } from '../model/replyGuid';
import { UpdateBrandLogo } from '../model/updateBrandLogo';
import { ViewModelListBrandLogoInfo } from '../model/viewModelListBrandLogoInfo';
import { ViewModelListBrandLogoListItem } from '../model/viewModelListBrandLogoListItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class ArticleManagementBrandLogosService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * CreateBrandLogo
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public brandLogosCreateBrandLogo(command: CreateBrandLogo, observe?: 'body', reportProgress?: boolean): Observable<ReplyGuid>;
    public brandLogosCreateBrandLogo(command: CreateBrandLogo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyGuid>>;
    public brandLogosCreateBrandLogo(command: CreateBrandLogo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyGuid>>;
    public brandLogosCreateBrandLogo(command: CreateBrandLogo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling brandLogosCreateBrandLogo.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ReplyGuid>(
                `${this.basePath}/api/v1/articlemanagement/brandlogos`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetList
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public brandLogosGetList(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListBrandLogoListItem>;
    public brandLogosGetList(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListBrandLogoListItem>>;
    public brandLogosGetList(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListBrandLogoListItem>>;
    public brandLogosGetList(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListBrandLogoListItem>(
                `${this.basePath}/api/v1/articlemanagement/brandlogos`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetOptions
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public brandLogosGetOptions(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListBrandLogoInfo>;
    public brandLogosGetOptions(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListBrandLogoInfo>>;
    public brandLogosGetOptions(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListBrandLogoInfo>>;
    public brandLogosGetOptions(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListBrandLogoInfo>(
                `${this.basePath}/api/v1/articlemanagement/brandlogos/options`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateBrandLogo
     * 
     * @param id 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public brandLogosUpdateBrandLogo(id: string, command: UpdateBrandLogo, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public brandLogosUpdateBrandLogo(id: string, command: UpdateBrandLogo, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public brandLogosUpdateBrandLogo(id: string, command: UpdateBrandLogo, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public brandLogosUpdateBrandLogo(id: string, command: UpdateBrandLogo, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling brandLogosUpdateBrandLogo.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling brandLogosUpdateBrandLogo.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/articlemanagement/brandlogos/${encodeURIComponent(String(id))}`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
