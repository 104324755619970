/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { Reply } from '../model/reply';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class IndexManagementService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Initialize All
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeAllIndexes(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeAllIndexes(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeAllIndexes(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeAllIndexes(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/all/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * InitializeArticleIndex
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeArticleIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeArticleIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeArticleIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeArticleIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/articles/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Initialize Assembly Index
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeAssemblyIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeAssemblyIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeAssemblyIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeAssemblyIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/assemblies/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Initialize Cluster Index
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeClustersIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeClustersIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeClustersIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeClustersIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/clusters/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Initialize Concept Index
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeConceptIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeConceptIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeConceptIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeConceptIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/concepts/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * InitializeFileContainerIndex
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeFileContainerIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeFileContainerIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeFileContainerIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeFileContainerIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/file-containers/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Initialize Keyword Index
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeKeywordIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeKeywordIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeKeywordIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeKeywordIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/keywords/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Initialize Material Index
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeMaterialIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeMaterialIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeMaterialIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeMaterialIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/materials/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Initialize Subcomponent Index
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeSubcomponentIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeSubcomponentIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeSubcomponentIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeSubcomponentIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/subcomponents/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Initialize Users Index
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesInitializeUserIndex(observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesInitializeUserIndex(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesInitializeUserIndex(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesInitializeUserIndex(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/users/initialize`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateArticleDocuments
     * 
     * @param articleIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesUpdateArticleDocuments(articleIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesUpdateArticleDocuments(articleIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesUpdateArticleDocuments(articleIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesUpdateArticleDocuments(articleIds: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (articleIds === null || articleIds === undefined) {
            throw new Error('Required parameter articleIds was null or undefined when calling indicesUpdateArticleDocuments.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/articles/update`,
                articleIds,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateAssemblyDocuments
     * 
     * @param assemblyIds 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesUpdateAssemblyDocuments(assemblyIds: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesUpdateAssemblyDocuments(assemblyIds: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesUpdateAssemblyDocuments(assemblyIds: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesUpdateAssemblyDocuments(assemblyIds: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (assemblyIds === null || assemblyIds === undefined) {
            throw new Error('Required parameter assemblyIds was null or undefined when calling indicesUpdateAssemblyDocuments.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/assemblies/update`,
                assemblyIds,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateClusterDocument
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public indicesUpdateClusterDocument(id: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public indicesUpdateClusterDocument(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public indicesUpdateClusterDocument(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public indicesUpdateClusterDocument(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling indicesUpdateClusterDocument.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/index/management/clusters/update/${encodeURIComponent(String(id))}`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
