<div
    #container
    (keyup.arrowUp)="selectPrevious()"
    (keydown.arrowUp)="preventCursorMovement( $event )"
    (keyup.arrowDown)="selectNext()"
    (keydown.arrowDown)="preventCursorMovement( $event )"
    (keyup.enter)="selectForAction()"
    (keyup.escape)="closePopUp()"
    (keyup.delete)="clearSelection()"
    [class.has-full-width]="hasFullWidth"
    class="dropdown"
>
    <ng-container *ngIf="formMode; else noFormMode">
        <input
            *ngIf="!myFormControl"
            #formInput
            rspTextInput
            [rspTextInputWrapperClass]="selectWrapperClasses"
            [withSelectMenuIcon]="!allowClear || !defaultValue"
            [withClearButton]="allowClear && !!defaultValue"
            [isSelectMenuVisible]="isPopUpVisible"
            [value]="defaultValue"
            [tabindex]="withSuggester && isPopUpVisible ? '-1' : null"
            [disabled]="disabled"
            [readonly]="true"
            [placeholder]="placeholder"
            [attr.id]="inputFieldHtmlId || null"
            [class.is-popup-visible]="isPopUpVisible"
            (click)="onClick()"
            (keydown)="onKeyDown( $event )"
            (onClear)="clearSelection()"
            type="text"
            class="dropdown--select"
            [title]="defaultValue || ''"
        >
        <input
            *ngIf="myFormControl"
            #formInput
            rspTextInput
            [rspTextInputWrapperClass]="selectWrapperClasses"
            [withSelectMenuIcon]="!allowClear || !defaultValue"
            [withClearButton]="allowClear && !!defaultValue"
            [isSelectMenuVisible]="isPopUpVisible"
            [tabindex]="withSuggester && isPopUpVisible ? '-1' : null"
            [formControl]="myFormControl"
            [placeholder]="placeholder"
            [readonly]="true"
            [attr.id]="inputFieldHtmlId || null"
            [class.is-popup-visible]="isPopUpVisible"
            [value]="defaultValue || null"
            (click)="onClick()"
            (keydown)="onKeyDown( $event )"
            type="text"
            class="dropdown--select"
        >
    </ng-container>

    <ng-template #noFormMode>
        <input
            *ngIf="withSuggester"
            #searchTermInput
            rspTextInput
            [rspTextInputWrapperClass]="selectWrapperClasses"
            [leftIcon]="'magnifier'"
            [withClearButton]="allowClear"
            [formControl]="searchTermControl"
            [placeholder]="placeholder"
            [attr.id]="inputFieldHtmlId || null"
            [class.dropdown--arrow-down]="!formMode"
            (click)="onClick()"
            (keydown)="onKeyDown( $event, true )"
            type="text"
            class="dropdown--select"
        >
        <rsp-spinner
            *ngIf="withSuggester && isLoading && !isLoading.closed"
            class="dropdown--loading-indicator"
        ></rsp-spinner>
    </ng-template>
</div>


<ng-template #contentTemplate>
    <div
        #popup
        (keyup.arrowUp)="selectPrevious()"
        (keyup.arrowDown)="selectNext()"
        (keydown.arrowDown)="preventCursorMovement( $event )"
        (keydown.arrowUp)="preventCursorMovement( $event )"
        (keyup.escape)="closePopUp()"
        (keyup.enter)="selectForAction()"
        [style.min-width.px]="popupMinWidth"
        class="dropdown-popup"
    >
        <input
            *ngIf="withSuggester && formMode"
            #searchTermInput
            rspTextInput
            [rspTextInputWrapperClass]="'dropdown--input-wrapper'"
            [leftIcon]="'magnifier'"
            [withClearButton]="allowClear"
            [formControl]="searchTermControl"
            [placeholder]="placeholder"
            [attr.id]="inputFieldHtmlId || null"
            [class.dropdown--arrow-down]="!formMode"
            (focus)="triggerEmptySearch()"
            (keydown)="onKeyDown( $event, true )"
            type="text"
            class="dropdown--input"
        >
        <rsp-spinner
            *ngIf="withSuggester && formMode && isLoading && !isLoading.closed"
            class="dropdown--loading-indicator"
        ></rsp-spinner>
        <div #list [class.dropdown--list]="( listItems && listItems.length > 0 ) || ( groups && groups.length > 0 )">
            <ng-content></ng-content>
        </div>
    </div>
</ng-template>
