import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subscription, forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogConfig, DialogService } from '../../../../../core/overlay/dialog/dialog.service';
import { NotificationService } from '../../../../../core/overlay/notification/notification.service';
import {
    BrandListItem,
    ConceptSuggestItem,
    FileContainerTile,
    FileKindInfo,
    FileKindItem,
    KeywordListItem, OptionItem,
    ViewModelItemDocumentDetails
} from '../../../../../shared/model';
import { FileManagementDocumentsService, FileManagementFileKindsService, MasterDataBrandsService, MasterDataKeywordsService } from '../../../../../shared/api';
import { DocumentForm } from './document-form.model';
import { RadioButtonGroupItem, RadioButtonGroupItemId } from '../../../../../shared/ui/radio-button-group/radio-button-group.model';
import { EnumConverterService } from '../../../../../shared/utils/enum/enum-converter.service';
import { UploadedFileInfo } from '../../../../../shared/forms/upload/upload-single-file/upload-single-file.component';


export type DocumentDialogMode = 'add' | 'edit';


@Component( {
    selector:    'rsp-add-or-edit-document-dialog',
    templateUrl: './add-or-edit-document-dialog.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/standard-dialog.scss',
        './add-or-edit-document-dialog.component.scss',
    ],
} )
export class AddOrEditDocumentDialogComponent implements OnDestroy {

    @ViewChild( 'dialogHeaderTemplate', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContentTemplate', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooterTemplate', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    mode: DocumentDialogMode;

    isLoading: Subscription;

    brands: Array<BrandListItem> = [];
    filteredBrands: Array<BrandListItem>;

    groups: Array<KeywordListItem> = [];
    filteredGroups: Array<KeywordListItem>;

    visibleForValues: Array<RadioButtonGroupItem>;

    fileKinds: Array<FileKindInfo>;
    filteredFileKinds: Array<FileKindInfo>;

    form: DocumentForm;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private documentsApi: FileManagementDocumentsService,
        private brandsApi: MasterDataBrandsService,
        private fileKindsApi: FileManagementFileKindsService,
        private keywordApi: MasterDataKeywordsService,
    ) {

        this.visibleForValues = EnumConverterService.convertToRadioButtonGroupItems( FileContainerTile.VisibleForEnum );
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    openForCreate(): void {

        this.form = DocumentForm.buildFormForCreate();
        this.mode = 'add';

        this.loadMetadata();

        this.openDialog();
    }

    openForEdit( itemToEdit: FileContainerTile ): void {

        if ( !itemToEdit || !itemToEdit.referencedObject ) {
            throw new Error( 'itemToEdit and itemToEdit.referencedObject must not be null!' );
        }

        const documentId: string = itemToEdit.referencedObject.id;

        this.isLoading =
            this.documentsApi
                .documentSectionsDetails( documentId )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( result: ViewModelItemDocumentDetails ) => {

                    this.form = DocumentForm.buildFormForEdit( result.data );

                    this.mode = 'edit';

                    this.loadMetadata();

                    this.openDialog();
                } );
    }

    closeDialog(): void {
        this.dialogService.closeDialog();
    }

    setFilteredBrands( items: BrandListItem[] ): void {
        this.filteredBrands = items;
    }

    setBrand( brand: BrandListItem ): void {
        this.form.setBrand( brand );
    }

    setFilteredGroups( items: KeywordListItem[] ): void {
        this.filteredGroups = items;
    }

    setGroup( group: KeywordListItem ): void {
        this.form.setGroup( [group] );
    }

    setConcept( concept: ConceptSuggestItem ): void {
        this.form.setConcept( concept );
    }

    setVisibleFor( value: RadioButtonGroupItemId ): void {
        this.form.setVisibleFor( <FileContainerTile.VisibleForEnum> value );
    }

    setFilteredFileKinds( items: Array<FileKindInfo> ): void {
        this.filteredFileKinds = items;
    }

    setFileKind( item: FileKindInfo ): void {
        this.form.setFileKind( item );
    }

    getContainerId(): string {

        // during document creation we want create new file container each time user uploaded the file

        if ( this.mode === 'edit' && this.form.getFileContainer() ) {
            return this.form.getFileContainer().id;
        }

        return null;
    }

    setFile( file: UploadedFileInfo ): void {

        this.form.setFileContainer( { id: file.fileContainerId, name: file.fileName, previewImageId: file.previewImageId } );
    }

    submitForm(): void {

        if ( this.mode === 'add' ) {
            // create
            this.isLoading =
                this.documentsApi
                    .documentSectionsCreate( this.form.getAsCreateDocument() )
                    .pipe( takeUntil( this.isDestroyed ) )
                    .subscribe( () => {
                        this.notificationService.success( 'Document created successfully.' );
                        this.closeDialog();
                    } );
        }
        else {
            // update
            this.isLoading =
                this.documentsApi
                    .documentSectionsUpdate( this.form.getAsUpdateDocument(), this.form.getDocumentId() )
                    .pipe( takeUntil( this.isDestroyed ) )
                    .subscribe( () => {
                        this.notificationService.success( 'Document updated successfully.' );
                        // TODO: update FileContainerTile in the storeService or reload page
                        this.closeDialog();
                    } );
        }
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private openDialog(): void {
        const dialogConfig: DialogConfig = {
            headlineTemplate: this.dialogHeaderTemplate,
            contentTemplate:  this.dialogContentTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        };

        this.dialogService.openDialog( dialogConfig );
    }
    private loadMetadata(): void {
        this.isLoading =
            forkJoin(
                this.fileKindsApi.fileKindsGetFileKindsByArea( FileKindItem.AreasEnum.toString( FileKindItem.AreasEnum.Documents ) ),
                this.brandsApi.brandsGetList(),
                this.keywordApi.keywordsGetList(),
            )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( results: any[] ) => {

                    // results[0] - Array<FileKindInfo>
                    // results[1] - ViewModelListBrandListItem

                    this.fileKinds = results[ 0 ];
                    this.brands    = results[ 1 ].data;
                    this.groups    = results[ 2 ].data.filter( ( keyword: KeywordListItem ) => {
                            return keyword.keywordGroups.map( ( group: OptionItem ) => {
                                return group.name.toLowerCase();
                            } ).includes( 'documents' );
                        } );
                } );
    }

}
