import { Component, Input, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { ExperienceInfo } from '../../../model/experienceInfo';
import { Tag } from '../../../ui/tags/tag';

@Component( {
    selector:    'rsp-display-edit-experiences',
    templateUrl: './display-edit-experiences.component.html',
    styleUrls:   [
        './display-edit-experiences.component.scss',
    ],
} )
export class DisplayEditExperiencesComponent {
    @Input() experiences: ExperienceInfo[];
    @Input() isEditMode: boolean;
    @Input() myFormControl: UntypedFormControl;


    getExperiencesAsTags(): Tag[] {
        if ( this.experiences ) {

            const tags: Tag[] = [];

            this.experiences.map( ( experience: ExperienceInfo ) => {
                tags.push( new Tag( experience.name ) );
            } );

            return tags;
        }

        return null;
    }
}
