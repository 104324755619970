import { Component, HostBinding, Input, } from '@angular/core';

import { FacetService, } from '../facet.service';
import { FacetItem } from '../../../../model/facetItem';


/**
 * Gives a summary of a facet state (aka "How many filter values for this facet are currently active?") and shows them as badge counter.
 *
 * ```html
 * <rsp-facet-summary [facet]="FacetItem"></rsp-facet-summary> → 3
 * <rsp-facet-summary [facet]="Facet (type=Singleselect)"></rsp-facet-summary> → •
 * ```
 */
@Component( {
    selector:    'rsp-facet-summary',
    templateUrl: './facet-summary.component.html',
    styleUrls:   [
        './facet-summary.component.scss',
    ],
} )
export class FacetSummaryComponent {
    numberOfSelectedValues: number;
    summary:                string;

    @HostBinding( 'style.visibility' ) visibility: 'hidden' | 'visible' = 'hidden';

    /**
     * Facet to count the selected values from. Can also be an array of (sub)facets.
     */
    @Input()
    set facet( facet: FacetItem | FacetItem[] ) {
        if ( Array.isArray( facet ) ) {
            this.numberOfSelectedValues = 0;
            facet.forEach( ( aFacet: FacetItem ) => {
                this.numberOfSelectedValues += this.facetService.countSelectedFacetValues( aFacet );
            } );
        }
        else {
            this.numberOfSelectedValues = this.facetService.countSelectedFacetValues( facet );
        }

        if ( !Array.isArray( facet ) && facet.type === FacetItem.TypeEnum.Singleselect ) {
            this.summary = this.numberOfSelectedValues ? '•' : '–';
        }
        else {
            this.summary = this.numberOfSelectedValues + '';
        }
        this.updateVisibility();
    }


    constructor( private facetService: FacetService ) {
    }

    private updateVisibility(): void {
        // hide the output of this component if the count is 0, but reserve the space (to prevent sudden "jumps" in each facet line).
        this.visibility = this.numberOfSelectedValues > 0 ? 'visible' : 'hidden';
    }
}
