import { Injectable } from '@angular/core';

@Injectable()
export class ObjectPathService {
    /**
     * Missing the elvis operator outside of Angular templates? Use this method to get a deep value of an object without the need to do tiresome checks like
     * `const isTender = elvis && elvis.song && elvis.song.love ? elvis.song.love.me === 'tender' : false` (which are also very happy-casy, because of course
     * `elvis.song` could be an array or something else truthy.
     *
     * Use like this:
     *     objectPathService.elvisGet( { love: { me: 'tender' } }, 'love' );    // returns `{ me: 'tender' }`
     *     objectPathService.elvisGet( { love: { me: 'tender' } }, 'love.me' ); // returns `'tender'`
     *     objectPathService.elvisGet( { love: { me: 'tender' } }, 'love.you' ); // returns `undefined`
     *     objectPathService.elvisGet( { love: { me: 'tender' } }, 'love.you', 'jailhouse rock' ); // returns `'jailhouse rock'`
     *
     * May support arrays (`elvis.songs.[0].title`), Sets or Maps in the future.
     */
    elvisGet( object: Object, path: string, fallbackValue?: any ): any {
        const pathParts: string[] = path.split( /\./ );
        for ( let index: number = 0; index < pathParts.length; index++ ) {
            const pathPart: string = pathParts[ index ];

            if ( object !== null && object.hasOwnProperty( pathPart ) ) {
                if ( index + 1 === pathParts.length ) {
                    return object[ pathPart ];
                }
                else {
                    object = object[ pathPart ];
                    continue;
                }
            }
            else {
                break;
            }
        }

        return typeof fallbackValue !== 'undefined' ? fallbackValue : undefined;
    }
}
