/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { BrowsingConfiguration } from '../model/browsingConfiguration';
import { Reply } from '../model/reply';
import { UpdateUserConcepts } from '../model/updateUserConcepts';
import { UpdateUserDetails } from '../model/updateUserDetails';
import { UpdateUserDistributionChannels } from '../model/updateUserDistributionChannels';
import { UpdateUserModuleGroups } from '../model/updateUserModuleGroups';
import { UserSuggestSearchConfiguration } from '../model/userSuggestSearchConfiguration';
import { ViewModelBrowsingListUserListItem } from '../model/viewModelBrowsingListUserListItem';
import { ViewModelItemUserDetailsUserDetailsChangeability } from '../model/viewModelItemUserDetailsUserDetailsChangeability';
import { ViewModelItemUserSummary } from '../model/viewModelItemUserSummary';
import { ViewModelListConceptListItem } from '../model/viewModelListConceptListItem';
import { ViewModelListDistributionChannelListItem } from '../model/viewModelListDistributionChannelListItem';
import { ViewModelPagedListUserInfo } from '../model/viewModelPagedListUserInfo';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class UserManagementUsersService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Activate
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersActivate(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public usersActivate(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public usersActivate(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public usersActivate(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersActivate.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/activate`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Deactivate
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersDeactivate(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public usersDeactivate(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public usersDeactivate(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public usersDeactivate(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersDeactivate.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.post<Reply>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/deactivate`,
                null,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Delete
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersDelete(userId: string, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public usersDelete(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public usersDelete(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public usersDelete(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersDelete.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.delete<Reply>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetConcepts
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetConcepts(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListConceptListItem>;
    public usersGetConcepts(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListConceptListItem>>;
    public usersGetConcepts(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListConceptListItem>>;
    public usersGetConcepts(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersGetConcepts.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListConceptListItem>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/concepts`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetConceptsAssignable - Concepts that are assignable to user.
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetConceptsAssignable(observe?: 'body', reportProgress?: boolean): Observable<ViewModelListConceptListItem>;
    public usersGetConceptsAssignable(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListConceptListItem>>;
    public usersGetConceptsAssignable(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListConceptListItem>>;
    public usersGetConceptsAssignable(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListConceptListItem>(
                `${this.basePath}/api/v1/usermanagement/users/concepts-assignable`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetDetails
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetDetails(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemUserDetailsUserDetailsChangeability>;
    public usersGetDetails(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemUserDetailsUserDetailsChangeability>>;
    public usersGetDetails(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemUserDetailsUserDetailsChangeability>>;
    public usersGetDetails(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersGetDetails.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemUserDetailsUserDetailsChangeability>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/details`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetDistributionChannels
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetDistributionChannels(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListDistributionChannelListItem>;
    public usersGetDistributionChannels(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListDistributionChannelListItem>>;
    public usersGetDistributionChannels(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListDistributionChannelListItem>>;
    public usersGetDistributionChannels(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersGetDistributionChannels.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListDistributionChannelListItem>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/distribution-channels`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * GetSummary
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersGetSummary(userId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelItemUserSummary>;
    public usersGetSummary(userId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelItemUserSummary>>;
    public usersGetSummary(userId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelItemUserSummary>>;
    public usersGetSummary(userId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersGetSummary.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelItemUserSummary>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/summary`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Search
     * 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersSearch(configuration: BrowsingConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelBrowsingListUserListItem>;
    public usersSearch(configuration: BrowsingConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelBrowsingListUserListItem>>;
    public usersSearch(configuration: BrowsingConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelBrowsingListUserListItem>>;
    public usersSearch(configuration: BrowsingConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (configuration === null || configuration === undefined) {
            throw new Error('Required parameter configuration was null or undefined when calling usersSearch.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelBrowsingListUserListItem>(
                `${this.basePath}/api/v1/usermanagement/users/search`,
                configuration,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Suggests (search) for users. Used for user suggester.
     * 
     * @param configuration 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersSuggest(configuration: UserSuggestSearchConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelPagedListUserInfo>;
    public usersSuggest(configuration: UserSuggestSearchConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelPagedListUserInfo>>;
    public usersSuggest(configuration: UserSuggestSearchConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelPagedListUserInfo>>;
    public usersSuggest(configuration: UserSuggestSearchConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (configuration === null || configuration === undefined) {
            throw new Error('Required parameter configuration was null or undefined when calling usersSuggest.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelPagedListUserInfo>(
                `${this.basePath}/api/v1/usermanagement/users/suggest`,
                configuration,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateConcepts
     * 
     * @param userId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUpdateConcepts(userId: string, command: UpdateUserConcepts, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public usersUpdateConcepts(userId: string, command: UpdateUserConcepts, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public usersUpdateConcepts(userId: string, command: UpdateUserConcepts, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public usersUpdateConcepts(userId: string, command: UpdateUserConcepts, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersUpdateConcepts.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling usersUpdateConcepts.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/concepts`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateDetails
     * 
     * @param userId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUpdateDetails(userId: string, command: UpdateUserDetails, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public usersUpdateDetails(userId: string, command: UpdateUserDetails, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public usersUpdateDetails(userId: string, command: UpdateUserDetails, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public usersUpdateDetails(userId: string, command: UpdateUserDetails, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersUpdateDetails.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling usersUpdateDetails.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/details`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateDistributionChannels
     * 
     * @param userId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUpdateDistributionChannels(userId: string, command: UpdateUserDistributionChannels, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public usersUpdateDistributionChannels(userId: string, command: UpdateUserDistributionChannels, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public usersUpdateDistributionChannels(userId: string, command: UpdateUserDistributionChannels, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public usersUpdateDistributionChannels(userId: string, command: UpdateUserDistributionChannels, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersUpdateDistributionChannels.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling usersUpdateDistributionChannels.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/distribution-channels`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * UpdateModuleGroups
     * 
     * @param userId 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public usersUpdateModuleGroups(userId: string, command: UpdateUserModuleGroups, observe?: 'body', reportProgress?: boolean): Observable<Reply>;
    public usersUpdateModuleGroups(userId: string, command: UpdateUserModuleGroups, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Reply>>;
    public usersUpdateModuleGroups(userId: string, command: UpdateUserModuleGroups, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Reply>>;
    public usersUpdateModuleGroups(userId: string, command: UpdateUserModuleGroups, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (userId === null || userId === undefined) {
            throw new Error('Required parameter userId was null or undefined when calling usersUpdateModuleGroups.');
        }
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling usersUpdateModuleGroups.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.put<Reply>(
                `${this.basePath}/api/v1/usermanagement/users/${encodeURIComponent(String(userId))}/module-groups`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
