<div class="login--header">
    <div class="login--header-item">
        <img class="login--header-image"
             src="/assets/adidas_company_logo_Wp.svg"
             alt="adidas Logo"
        />
    </div>
    <div class="login--header-item login--header-logo">
        <img class="login--header-logo-image"
            src="/assets/rsp_logo_landscape.svg"
            alt="rsp Logo"
        >
    </div>
    <div class="login--header-item">
        <rsp-button
            [submodule]="'primary'"
            (clicked)="login()"
            class="login--header-login"
        >
            Login
        </rsp-button>
    </div>
</div>
<div class="login--image">
    <span *ngIf="showLogoutMessage" class="login--logout-message">You have been successfully logged out!</span>
</div>
<div class="login--footer">
    <span (click)="openImprint()">Imprint</span>
    <span (click)="openLegalNotice()">Legal Notice</span>
</div>

<rsp-legal-notice-dialog></rsp-legal-notice-dialog>
<rsp-imprint-dialog></rsp-imprint-dialog>
