<ul class="vertical-tabs">
    <li *ngFor="let tab of tabs">
        <a
            [routerLink]="tab.link"
            [routerLinkActive]="['is-vertical-tab-selected']"
            [attr.title]="tab.title ? tab.title : null"
            class="vertical-tabs--tab"
        >
            <span style="flex: 1;">
                <ng-container *ngIf="!tab.title">{{tab.label}}</ng-container>
                <abbr *ngIf="tab.title" title="{{tab.title}}">{{tab.label}}</abbr>
            </span>
            <rsp-badge-counter *ngIf="tab.tag" [content]="tab.tag" class="vertical-tabs--tab-counter"></rsp-badge-counter>

            <span class="vertical-tabs--tab-icon" style="order: 3"></span>
        </a>
    </li>
</ul>
