import { EventEmitter, Injectable, } from '@angular/core';
import { Observable ,  Observer } from 'rxjs';
import { TransformService } from '../shared/utils/string/transform.service';
import { WindowEventService } from './window-event.service';

export * from './storage-keys';

export type StorageType = 'browser' | 'backend';

/**
 * Simple key-value storage. Can be used for store any data, f.e. last used facets, sort order etc.
 * This service belongs to the common module - it means is a singleton for the whole application.
 * In the future we will add an option to persist the data.
 */
@Injectable()
export class StorageService {

    triggerReevaluation: EventEmitter<void> = new EventEmitter<void>();

    private storage: any = {};


    constructor(
        private transformService: TransformService,
        private eventService: WindowEventService,
    ) {
        this.eventService.focused$.subscribe( () => {
            this.triggerReevaluation.emit();
        } );
    }


    /**
     * Returns value for the specified key or null if value does not exist.
     * type defines, which method should be used to get the key.
     *
     * @param key
     * @param type
     * @returns {any}
     */
    get <T>( key: string, type?: StorageType ): T {

        let result: T = null;

        switch ( type ) {
            case 'browser':
                result = this.getLocalStorage<T>( key );
                break;
            case 'backend':
                result = this.getFromBackend<T>( key );
                break;
            default:
                result = this.getDefault<T>( key );

        }

        if ( typeof result !== 'undefined' ) {
            return result;
        }

        return null;
    }

    /**
     * Sets a value for specified key.
     * type defines, which method should be used to save the key.
     *
     * @param key
     * @param value
     * @param type
     */
    set <T>( key: string, value: T, type?: StorageType ): void {

        switch ( type ) {
            case 'browser':
                this.setLocalStorage<T>( key, value );
                break;
            case 'backend':
                this.saveToBackend<T>( key, value );
                break;
            default:
                this.setDefault<T>( key, value );

        }
    }

    /**
     * Returns an observable which will emit the value for the key on every "triggerReevaluation" event.
     *
     * @param key
     * @returns Observable<T>
     */
    getAsObservable<T>( key: string, type?: StorageType ): Observable<T> {
        return Observable.create( ( observer: Observer<T> ) => {
            this.triggerReevaluation.subscribe( () => {
                observer.next( this.get<T>( key, type ) );
            } );
        } );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setDefault<T>( key: string, value: T ): void {
        this.storage[ key ] = value;
    }

    private getDefault<T>( key: string ): T {
        return this.storage[ key ];
    }

    private setLocalStorage<T>( key: string, value: T ): void {
        if ( this.isLocalStorageAvailable() ) {
            window.localStorage.setItem( key, JSON.stringify( value ) );
        }
        else {
            this.setDefault<T>( key, value );
        }
    }

    private getLocalStorage<T>( key: string ): T {
        if ( this.isLocalStorageAvailable() ) {
            const result: string = window.localStorage.getItem( key );

            return result ? this.transformService.transformWithDate<T>( result ) : null;
        }
        else {
            return this.getDefault<T>( key );
        }
    }

    // TODO backend endpoint for saveToBackend & getFromBackend needed
    private saveToBackend<T>( key: string, value: T ): void {
        this.setLocalStorage<T>( key, value );
    }

    private getFromBackend<T>( key: string ): T {
        return this.getLocalStorage<T>( key );
    }


    private isLocalStorageAvailable(): boolean {
        try {
            window.localStorage.setItem( 'LocalStorageTest', 'true' );
            window.localStorage.getItem( 'LocalStorageTest' );
            window.localStorage.removeItem( 'LocalStorageTest' );
            return true;
        }
        catch ( e ) {
            console.warn( 'local storage not available. some features may not work as expected.' );
            return false;
        }
    }

}
