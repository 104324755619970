/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface UserSuggestSearchConfiguration {
    types?: Array<UserSuggestSearchConfiguration.TypesEnum>;

    states?: Array<UserSuggestSearchConfiguration.StatesEnum>;

    ignoreIds?: Array<string>;

    term?: string;

    size?: number;

    page?: number;

}

export namespace UserSuggestSearchConfiguration {
    'use strict';
    export enum TypesEnum {
        Internal,
        External,
    }
    export namespace TypesEnum {
        export function toString( enumValue: TypesEnum ): string {
            return TypesEnum[ enumValue ];
        }
    }
    export enum StatesEnum {
        Active,
        Inactive,
        Registered,
    }
    export namespace StatesEnum {
        export function toString( enumValue: StatesEnum ): string {
            return StatesEnum[ enumValue ];
        }
    }
}

