import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { BaseListPageComponent } from '../../../../shared/ui/lists/base-list-page.component';
import { FileContainerTile } from '../../../../shared/model';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { MainLoadingIndicatorService } from '../../../../core/main-loading-indicator/main-loading-indicator.service';
import { DocumentSelectionService } from '../shared/document-selection.service';
import { DocumentStoreService } from '../shared/document-store.service';
import { StorageService } from '../../../../core/storage.service';
import { STORAGE_KEYS } from '../../../../core/storage-keys';
import { AddOrEditDocumentDialogComponent } from '../shared/add-or-edit-document-dialog/add-or-edit-document-dialog.component';
import { FileManagementDocumentsService } from '../../../../shared/api';
import { NotificationService } from '../../../../core/overlay/notification/notification.service';


@Component( {
    selector:    'rsp-document-list-page',
    templateUrl: './document-list-page.component.html',
    styleUrls:   [ './document-list-page.component.scss' ],
} )
export class DocumentListPageComponent extends BaseListPageComponent<FileContainerTile> implements OnInit {

    @ViewChild( AddOrEditDocumentDialogComponent, { static: true } ) addOrEditDocumentDialogComponent: AddOrEditDocumentDialogComponent;

    get storageKeyIsSidebarVisible(): string {
        return STORAGE_KEYS.documents.list.isSidebarVisible;
    }

    get storageKeyScrollToId(): string {
        return STORAGE_KEYS.documents.list.scrollToId;
    }

    isSaving: Subscription;

    constructor(
        private toolbarService: SecondaryToolbarService,
        private documentsApi: FileManagementDocumentsService,
        private notificationService: NotificationService,
        router: Router,
        activatedRoute: ActivatedRoute,
        mainLoadingIndicatorService: MainLoadingIndicatorService,
        selectionService: DocumentSelectionService,
        storeService: DocumentStoreService,
        storageService: StorageService,
    ) {
        super(
            router,
            activatedRoute,
            storeService,
            selectionService,
            storageService,
            mainLoadingIndicatorService,
        );

        // configure toolbar
        this.toolbarService.showStoreInfo();
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    openEditDialog( item: FileContainerTile ): void {
        this.addOrEditDocumentDialogComponent.openForEdit( item );
    }

    deleteDocument( item: FileContainerTile ): void {

        this.isSaving =
            this.documentsApi
                .documentSectionsDelete( item.referencedObject.id )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.notificationService.success( 'Document deleted successfully.' );
                    // this.reloadList();
                } );

    }
}
