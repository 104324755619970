<div class="details-page--sidebar">
    <ng-content></ng-content>
</div>
<div
    class="details-page--main"
    rspScrollContainer
    [rspScrollContainerIdentifier]="'scrollContainer'"
>
    <rsp-breadcrumb></rsp-breadcrumb>
    <router-outlet></router-outlet>
</div>
