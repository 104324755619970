import { ComponentRef, ElementRef, Injectable, Injector, TemplateRef } from '@angular/core';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import {
    ConnectedPosition,
    FlexibleConnectedPositionStrategy,
    HorizontalConnectionPos,
    VerticalConnectionPos,
    Overlay,
    OverlayConfig,
    OverlayRef,
} from '@angular/cdk/overlay';

import { TooltipRef } from './tooltip-ref.model';
import { TooltipComponent } from './tooltip.component';
import { TOOLTIP_DATA } from './tooltip.token';

export interface TooltipConfig {

    closeWhenClickOutside?: boolean;

    /**
     * Connection point for anchor element.
     */
    anchorPosition?: TooltipAnchorConnectionPosition;

    /**
     * Connection point for tooltip.
     */
    tooltipPosition?: TooltipConnectionPosition;

    /**
     * Tooltip's vertical offset.
     */
    tooltipOffsetY?: number;

    /**
     * Tooltip's horizontal offset.
     */
    tooltipOffsetX?: number;


    fallbackAnchorPosition?: TooltipAnchorConnectionPosition;

    fallbackTooltipPosition?: TooltipConnectionPosition;

    fallbackTooltipOffsetX?: number;

    templateContext?: any;
}


const DEFAULT_CONFIG: TooltipConfig = {
    closeWhenClickOutside: true,

    anchorPosition: {
        anchorX: 'center',
        anchorY: 'bottom',
    },

    tooltipPosition: {
        tooltipX: 'center',
        tooltipY: 'top',
    },

    tooltipOffsetY: 0,

    tooltipOffsetX: 0,

    fallbackAnchorPosition: null,

    fallbackTooltipPosition: null,

    fallbackTooltipOffsetX: null,
};


// wrap types defined in Angular Material CDK
export declare type TooltipHorizontalConnectionPos = HorizontalConnectionPos;
export declare type TooltipVerticalConnectionPos = VerticalConnectionPos;

export interface TooltipAnchorConnectionPosition {
    anchorX: TooltipHorizontalConnectionPos;
    anchorY: TooltipVerticalConnectionPos;
}

export interface TooltipConnectionPosition {
    tooltipX: TooltipHorizontalConnectionPos;
    tooltipY: TooltipVerticalConnectionPos;
}

/**
 * Data injected to TooltipComponent
 */
export interface TooltipData {

    displayedType: 'string' | 'template';

    stringToDisplay: string;

    templateToDisplay: TemplateRef<any>;

    templateContext?: any;

    positionStrategy: FlexibleConnectedPositionStrategy;
}


@Injectable()
export class TooltipService {
    constructor(
        private injector: Injector,
        private overlay: Overlay,
    ) {
    }


    show( tooltipContent: string | TemplateRef<any>, anchorElement: ElementRef, config: TooltipConfig = null ): TooltipRef {
        const tooltipConfig: TooltipConfig                        = { ...DEFAULT_CONFIG, ...config }; // override default configuration
        const positionStrategy: FlexibleConnectedPositionStrategy = this.getPositionStrategy( anchorElement, tooltipConfig );
        const overlayConfig: OverlayConfig                        = new OverlayConfig( {
            hasBackdrop:      false,
            scrollStrategy:   this.overlay.scrollStrategies.close( { threshold: 20 } ),
            positionStrategy: positionStrategy,
        } );

        const overlayRef: OverlayRef = this.overlay.create( overlayConfig );
        const tooltipRef: TooltipRef = new TooltipRef( overlayRef, anchorElement, tooltipConfig );
        this.attachTooltipComponentPortal( tooltipContent, overlayRef, tooltipRef, positionStrategy, config ? config.templateContext : null );

        if ( tooltipConfig.closeWhenClickOutside ) {
            // TODO: use our click outside directive or implement this manually in TooltipComponent
            // overlayRef.backdropClick().subscribe( () => overlayRef.dispose() );
        }

        return tooltipRef;
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private getPositionStrategy( anchorElement: ElementRef, config: TooltipConfig ): FlexibleConnectedPositionStrategy {

        // per default Tooltip is placed below anchor element. If there is no place, it will be move above the anchor element.
        // This behavior can be changed if you use TooltipConfig.anchorPosition, TooltipConfig.tooltipPosition.

        const position: ConnectedPosition = {
            originX:  config.anchorPosition.anchorX,
            originY:  config.anchorPosition.anchorY,
            overlayX: config.tooltipPosition.tooltipX,
            overlayY: config.tooltipPosition.tooltipY,
        };

        const fallbackPosition: ConnectedPosition = {
            originX:
                config.fallbackAnchorPosition ? config.fallbackAnchorPosition.anchorX : this.getFallbackForHorizontalConnectionPos( position.originX ),
            originY:
                config.fallbackAnchorPosition ? config.fallbackAnchorPosition.anchorY : this.getFallbackForVerticalConnectionPos( position.originY ),
            overlayX:
                config.fallbackTooltipPosition ? config.fallbackTooltipPosition.tooltipX : this.getFallbackForHorizontalConnectionPos( position.overlayX ),
            overlayY:
                config.fallbackTooltipPosition ? config.fallbackTooltipPosition.tooltipY : this.getFallbackForVerticalConnectionPos( position.overlayY ),
        };

        return this.overlay
                   .position()
                   .flexibleConnectedTo( anchorElement )
                   .withPositions( [ position, fallbackPosition ] )
                   .withLockedPosition( false )
                   .withDefaultOffsetY( config.tooltipOffsetY )
                   .withDefaultOffsetX( config.tooltipOffsetX );
    }

    private attachTooltipComponentPortal(
        tooltipContent: string | TemplateRef<any>,
        overlayRef: OverlayRef,
        tooltipRef: TooltipRef,
        positionStrategy: FlexibleConnectedPositionStrategy,
        templateContext?: any,
    ): TooltipComponent {

        const injector: PortalInjector = this.createInjector( tooltipContent, tooltipRef, positionStrategy, templateContext );

        const containerPortal: ComponentPortal<TooltipComponent> = new ComponentPortal( TooltipComponent, null, injector );

        const containerRef: ComponentRef<TooltipComponent> = overlayRef.attach( containerPortal );

        return containerRef.instance;
    }


    private createInjector(
        tooltipContent: string | TemplateRef<any>,
        tooltipRef: TooltipRef,
        positionStrategy: FlexibleConnectedPositionStrategy,
        templateContext?: any,
    ): PortalInjector {

        const injectionTokens: WeakMap<any, any> = new WeakMap();

        const data: TooltipData = this.createTooltipData( tooltipContent, positionStrategy, templateContext );

        // set custom injection tokens
        injectionTokens.set( TooltipRef, tooltipRef );
        injectionTokens.set( TOOLTIP_DATA, data );

        return new PortalInjector( this.injector, injectionTokens );
    }


    private createTooltipData(
        tooltipContent: string | TemplateRef<any>,
        positionStrategy: FlexibleConnectedPositionStrategy,
        templateContext?: any,
    ): TooltipData {

        if ( this.isString( tooltipContent ) ) {

            return {
                displayedType:     'string',
                stringToDisplay:   <string> tooltipContent,
                templateToDisplay: null,
                positionStrategy:  positionStrategy,
                templateContext:   templateContext,
            };

        }
        else if ( this.isTemplate( tooltipContent ) ) {

            return {
                displayedType:     'template',
                stringToDisplay:   null,
                templateToDisplay: <TemplateRef<any>> tooltipContent,
                positionStrategy:  positionStrategy,
                templateContext:   templateContext,
            };

        }

        return null;
    }


    private isTemplate( toTest: string | TemplateRef<any> ): boolean {
        return toTest instanceof TemplateRef;
    }


    private isString( toTest: string | TemplateRef<any> ): boolean {
        return typeof toTest === 'string';
    }


    private getFallbackForHorizontalConnectionPos( pos: HorizontalConnectionPos ): HorizontalConnectionPos {

        // TODO: is not perfect. Works correctly only with standard top/below/right/left positions.

        switch ( pos ) {
            case 'start':
                return 'end';

            case 'end':
                return 'start';

            default:
                return pos;
        }
    }

    private getFallbackForVerticalConnectionPos( pos: VerticalConnectionPos ): VerticalConnectionPos {

        // TODO: is not perfect. Works correctly only with standard top/below/right/left positions.

        switch ( pos ) {
            case 'top':
                return 'bottom';

            case 'bottom':
                return 'top';

            default:
                return pos;
        }
    }
}
