import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable,  Subscription, Subject, merge,  forkJoin, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { NotificationService } from '../../../../core/overlay/notification/notification.service';
import { DistributionChannelListItem, Reply, UpdateUserDistributionChannels, ViewModelListDistributionChannelListItem } from '../../../model/index';
import { MasterDataDistributionChannelsService, UserManagementCurrentUserService, UserManagementUsersService } from '../../../api/index';
import { UserService } from '../user.service';
import { UserEditContext } from '../user.model';
import { SecondaryToolbarService } from '../../secondary-toolbar/secondary-toolbar.service';
import { loadWithProgressIndicator } from '../../../utils/rxjs-extensions/load-with-progress-indicator.extension';
import { CurrentUserContext } from '../../../model';
import { CurrentUserService } from '../../../../core/current-user.service';
import { DistributionChannelSelection } from '../../distribution-channels/distribution-channels.component';

@Component( {
    selector:    'rsp-user-distribution-channels',
    templateUrl: './user-distribution-channels.component.html',
    styleUrls:   [
        '../../../scss/05_module/detail-page-tab.scss',
        './user-distribution-channels.component.scss',
    ],
} )
export class UserDistributionChannelsComponent implements OnDestroy, OnInit {
    mode: UserEditContext;

    allDistributionChannels: DistributionChannelListItem[];
    userDistributionChannels: DistributionChannelListItem[];
    distributionChannelsToSave: DistributionChannelListItem[] | null = null;
    isEditMode: boolean                                              = false;
    hasEditRight: boolean                                            = true;
    isLoading: Subscription;

    isValid: boolean;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private userService: UserService,
        private currentUserService: CurrentUserService,
        private toolbarService: SecondaryToolbarService,
        private notificationService: NotificationService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private masterDataDistributionChannelsService: MasterDataDistributionChannelsService,
        private userManagementUsersService: UserManagementUsersService,
        private userManagementCurrentUserService: UserManagementCurrentUserService,
    ) {
    }

    ngOnInit(): void {
        this.mode = this.activatedRoute.snapshot.data.userEditContext;

        if ( this.mode === 'user-management' ) {
            this.currentUserService
                .hasCurrentUserAccessRight( CurrentUserContext.AccessRightsEnum.UsersDistributionChannelsEdit )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( hasRight: boolean ) => {
                    this.hasEditRight = hasRight;
                } );
        }
        // Two entry points:
        //  - of( true ) - first component load
        //  - summary$              - prev/next navigation
        merge( of( true ), this.userService.summary$ )
            .pipe(
                loadWithProgressIndicator(
                    () => forkJoin(
                        this.masterDataDistributionChannelsService.distributionChannelsGetList(),

                        this.mode === 'my-profile'
                            ? this.userManagementCurrentUserService.currentUserGetDistributionChannels()
                            : this.userManagementUsersService.usersGetDistributionChannels( this.userService.userId ),
                    ),
                    this,
                ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: ViewModelListDistributionChannelListItem[] ) => {
                this.initialize( result[ 0 ].data, result[ 1 ].data );
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    startEditMode( doNavigate: boolean = true ): void {
        this.isEditMode = true;
        this.toolbarService.hidePrevNextNavigation();
        this.distributionChannelsToSave = this.userDistributionChannels;

        if ( doNavigate ) {
            this.router.navigate( [ 'edit' ], { relativeTo: this.activatedRoute } );
        }
    }

    endEditMode(): void {
        this.isEditMode = false;
        this.toolbarService.showPrevNextNavigation();
        this.distributionChannelsToSave = null;
        this.router.navigate( [ '../' ], { relativeTo: this.activatedRoute } );
    }

    setDistributionChannelsToSave( distributionChannelSelection: DistributionChannelSelection ): void {
        this.distributionChannelsToSave = distributionChannelSelection.selectedDistributionChannels;
        this.isValid                    = distributionChannelSelection.isSelectionValid;
    }

    saveDistributionChannels(): void {

        const command: UpdateUserDistributionChannels = {
            userId:               this.userService.userId,
            distributionChannels: this.distributionChannelsToSave.map( ( item: DistributionChannelListItem ) => item.id ),
        };

        const backendCall: Observable<Reply> = this.mode === 'my-profile'
            ? this.userManagementCurrentUserService.currentUserUpdateDistributionChannels( command )
            : this.userManagementUsersService.usersUpdateDistributionChannels( this.userService.userId, command );

        this.isLoading = backendCall
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                const message: string =
                          this.distributionChannelsToSave.length > 0
                              ? (this.mode === 'my-profile' ? 'You now have' : 'This user now has') + ' the following distribution channels assigned: ' +
                              this.distributionChannelsToSave
                                  .map( ( distributionChannel: DistributionChannelListItem ) => distributionChannel.name )
                                  .sort()
                                  .join( ', ' )
                              : (this.mode === 'my-profile' ? 'You don\'t' : 'This user now doesn\'t') + ' have any distribution channels assigned anymore.';

                this.notificationService.success( message, 'Distribution Channels updated' );

                this.userService.getAndPublishSummary();

                this.userDistributionChannels = this.distributionChannelsToSave;

                this.endEditMode();
            } );
    }

    private initialize( allDistributionChannels: DistributionChannelListItem[], userDistributionChannels: DistributionChannelListItem[] ): void {
        this.allDistributionChannels  = allDistributionChannels;
        this.userDistributionChannels = userDistributionChannels;

        if ( this.activatedRoute.snapshot.data[ 'isEditMode' ] ) {
            const doNavigate: boolean = false; // we are already on the edit route, don't navigate again
            this.startEditMode( doNavigate );
        }
    }
}
