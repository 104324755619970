/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface ConceptAndCluster {
    conceptId?: string;

    conceptName?: string;

    conceptStatus?: ConceptAndCluster.ConceptStatusEnum;

    usedClusterCount?: number;

    availableClusterCount?: number;

}

export namespace ConceptAndCluster {
    'use strict';
    export enum ConceptStatusEnum {
        Unpublished,
        Published,
        Active,
        Inactive,
    }
    export namespace ConceptStatusEnum {
        export function toString( enumValue: ConceptStatusEnum ): string {
            return ConceptStatusEnum[ enumValue ];
        }
    }
}

