<div *ngIf="icon === 'selection'"
     class="secondary-toolbar-button--clear-button-container"
>

    <rsp-button
        *ngIf="badgeValue > 0"
        [submodule]="'seamless'"
        (click)="onClearButtonClick($event)"
        class="secondary-toolbar-button--clear-button"
        title="Clear"
    ></rsp-button>

</div>


<button
    class="secondary-toolbar-button"
    type="button"
    [class.is-active]="isActive"
    [class.no-action]="icon === 'selection'"
>

    <rsp-badge-counter
        *ngIf="badgeValue > 0"
        class="secondary-toolbar-button--badge"
        [content]="badgeValue"
        [color]="'main-blue'"
    ></rsp-badge-counter>

    <div class="secondary-toolbar-button--icon-{{ icon }}"></div>

    <div *ngIf="label !== null && label !== undefined && label !== ''"
         class="secondary-toolbar-button--label"
    >
        {{ label }}
    </div>

</button>
