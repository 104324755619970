import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { BreadcrumbService } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb.service';
import { Tab } from '../../../../shared/ui/navigation/vertical-tabs/tab';
import { UserService } from '../../../../shared/ui/user/user.service';
import { UserSummary } from '../../../../shared/model';
import { BreadcrumbPart } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb-part';
import { QueryParamService } from '../../../../shared/utils/url/query-param.service';
import { UserNameService } from '../../../../shared/ui/user-name/user-name.service';
import { UserPrevNextNavigationService } from '../shared/user-prev-next-navigation/user-prev-next-navigation.service';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';

@Component( {
    selector:    'rsp-user-details-page',
    templateUrl: './user-details-page.component.html',
    styleUrls:   [ './user-details-page.component.scss' ],
    providers:   [ BreadcrumbService, ],
} )
export class UserDetailsPageComponent implements OnInit, OnDestroy {
    userSummary: UserSummary;
    tabs: Array<Tab> = [];

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private breadcrumbService: BreadcrumbService,
        private userService: UserService,
        private toolbarService: SecondaryToolbarService,
        private queryParamService: QueryParamService,
        private prevNextNavigationService: UserPrevNextNavigationService,
        private userNameService: UserNameService,
    ) {
        // configure toolbar
        // NOTE: previously it was in ngOnInit, but it throws a ExpressionChangedAfterItHasBeenCheckedError exception.
        // We are not sure why it occurs ... maybe something with named outlet (where toolbar is placed) and change detection.
        // This issue occurs in angular > 4.2.5.
        // https://github.com/angular/angular/issues/17572
        this.toolbarService.hideViewSwitcher();
        this.toolbarService.hideStoreInfo();
        this.toolbarService.hideSortBy();
        this.toolbarService.showPrevNextNavigation();

        this.configureBreadcrumb();
    }

    ngOnInit(): void {
        // Two entry points:
        //  - of( this.activatedRoute.data ) - first component load
        //  - this.userService.summary$                 - prev/next navigation
        merge( this.activatedRoute.data, this.userService.summary$ )
            .pipe(
                map( ( data: { userSummary: UserSummary } | UserSummary ) => this.fetchSummary( data ) ),
                distinctUntilChanged(),   // prev/next navigation triggers both events. Take only one.
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( summary: UserSummary ) => {
                if ( summary ) {
                    this.userSummary = summary;

                    this.breadcrumbService.replacePart(
                        {
                            label: this.userNameService.format( this.userSummary.tile ),
                        },
                        1,
                    );

                    this.createTabs();

                    this.userService.setSummary( summary );  // updates tab content during prev/next navigation

                    this.prevNextNavigationService.setCurrentItem( this.userSummary.tile.id );
                }
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
        this.breadcrumbService.disableAutoRefreshing();
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private configureBreadcrumb(): void {
        const config: { [ key: string ]: BreadcrumbPart } = {};
        config[ 'details' ]                               = { label: 'Details', };
        config[ 'distribution-channels' ]                 = { label: 'Distribution Channels', };
        config[ 'concepts' ]                              = { label: 'Concepts', };

        this.breadcrumbService.addPart( {
            label:      'Users',
            link:       [ '/users', ],
            linkParams: this.queryParamService.getQueryParamsFor( '/users' ),
        } );
        this.breadcrumbService.addPart( { label: 'User' } ); // will be replaced by summary data

        this.breadcrumbService.enableAutoRefreshing( config, 3 );
    }

    private createTabs(): void {
        if ( !this.userSummary ) {
            throw new Error( 'userSummary is null' );
        }

        this.tabs = [];

        // Details
        this.tabs.push( {
            link:  [ 'details', ],
            label: 'Details',
        } );

        // Distribution Channels
        if ( Tab.shouldTabBeVisible( this.userSummary.distributionChannelsNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'distribution-channels', ],
                label: 'Distribution Channels',
                tag:   Tab.createTagFromNumber( this.userSummary.distributionChannelsNavigationItem ),
            } );
        }

        // Concepts
        if ( Tab.shouldTabBeVisible( this.userSummary.conceptsNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'concepts', ],
                label: 'Concepts',
                tag:   Tab.createTagFromNumber( this.userSummary.conceptsNavigationItem ),
            } );
        }
    }

    private fetchSummary( data: { userSummary: UserSummary } | UserSummary ): UserSummary {
        if ( !data ) {
            return null;
        }

        if ( data.hasOwnProperty( 'userSummary' ) ) {
            // data is { userSummary: .... }
            return (<{ userSummary: UserSummary }> data).userSummary;
        }

        // data is UserSummary
        return data as UserSummary;
    }
}
