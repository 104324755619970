import { Component, OnDestroy, OnInit } from '@angular/core';

import { NotificationItem, NotificationService } from '../notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component( {
    selector:    'rsp-notification-list',
    templateUrl: './notification-list.component.html',
    styleUrls:   [
        './notification-list.component.scss',
    ],
} )
export class NotificationListComponent implements OnInit, OnDestroy {

    visibleNotifications: NotificationItem[] = [];
    moreCount: number                        = 0;

    private notifications: NotificationItem[] = [];
    private maxVisibleNotifications: number   = 5;
    private isDestroyed: Subject<boolean>     = new Subject<boolean>();

    constructor(
        private notificationService: NotificationService,
    ) { }

    ngOnInit(): void {
        this.notificationService
            .notification$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( notification: NotificationItem ) => {

                this.notifications.push( notification );

                this.prepareVisibleNotifications();
            } );

        this.notificationService
            .closeNotification$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( notificationId: number | null ) => {

                if ( !notificationId ) {
                    this.removeAll();
                }
                else {
                    this.removeNotification( notificationId );
                }

            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    removeNotification( notificationId: number ): void {
        const found: number = this.notifications.findIndex( ( obj: NotificationItem ) => obj.notificationId === notificationId );

        if ( found !== -1 ) {
            this.notifications.splice( found, 1 );
            this.prepareVisibleNotifications();
        }
    }

    private removeAll(): void {
        this.notifications = [];
        this.prepareVisibleNotifications();
    }


    private prepareVisibleNotifications(): void {
        this.visibleNotifications =
            this.notifications.filter( ( notification: NotificationItem, index: number ) => {
                return index < this.maxVisibleNotifications;
            } );

        this.moreCount = this.notifications.length > this.maxVisibleNotifications ? this.notifications.length - this.maxVisibleNotifications : 0;
    }
}
