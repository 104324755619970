<input
    #inputField
    rspTextInput
    [rspTextInputWrapperClass]="( adjustSize || size ) ? null : 'edit-text--input-wrapper'"
    [withClearButton]="true"
    [textAlignedRight]="textAlignedRight || null"
    [formControl]="myFormControl"
    [attr.id]="inputFieldHtmlId"
    [attr.placeholder]="placeholder || null"
    [attr.size]="adjustSize ? ( size || fallbackSize) : size || null"
    type="text"
    class="edit-text--input-field"
    (keyup)="handleKeyupEvent( $event )"
>
<ng-content></ng-content>
<rsp-messages
    *ngIf="!hideValidationMessages && validationMessages.length > 0"
    [type]="messageTypeError"
    [messages]="validationMessages"
></rsp-messages>
