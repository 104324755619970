import { Injectable } from '@angular/core';
import { Observable ,  BehaviorSubject } from 'rxjs';

import { ViewKind } from './view-switcher.component';
import { StorageService } from '../../../../core/storage.service';
import { STORAGE_KEYS } from '../../../../core/storage-keys';


/**
 * Provides observable which can be used by observers to be informed when user changed the current view kind.
 */
@Injectable()
export class ViewSwitcherService {

    activeViewKind$: Observable<ViewKind>;

    private activeViewKind: BehaviorSubject<ViewKind> = new BehaviorSubject( ViewKind.Table );

    constructor( private storageService: StorageService ) {

        this.activeViewKind$ = this.activeViewKind.asObservable();

        // restore last used view kind
        const lastUsedViewKind: ViewKind
                  = this.storageService.get<ViewKind>( STORAGE_KEYS.common.masterHead.viewSwitcher.viewKind, 'browser' ) || ViewKind.Table;
        this.activeViewKind.next( lastUsedViewKind );
    }

    changeViewKind( viewKind: ViewKind ): void {

        // store last used view kind
        this.storageService.set<ViewKind>( STORAGE_KEYS.common.masterHead.viewSwitcher.viewKind, viewKind, 'browser' );

        // notify
        this.activeViewKind.next( viewKind );
    }
}
