import { UntypedFormGroup, UntypedFormControl, } from '@angular/forms';
import { CustomValidators } from '../../../../../../shared/forms/validation/custom-validators';


export class MaterialValidator {
    static validateMaterialGroup( formGroup: UntypedFormGroup ): { [ key: string ]: boolean } {
        if ( formGroup.value && formGroup.value.percentage && !formGroup.value.name ) {
            return { nameRequiredWhenPercentage: true };
        }

        if ( formGroup.value && formGroup.value.percentage && formGroup.value.name ) {
            const control: UntypedFormControl = <UntypedFormControl> formGroup.get( 'percentage' );

            return MaterialValidator.validatePercentage( control );
        }

        return null;
    }

    static validatePercentage( control: UntypedFormControl ): { [ key: string ]: boolean } {
        if ( control.value && control.dirty && ( CustomValidators.isInteger( control ) || CustomValidators.positiveNumber( control ) ) ) {
            return { percentageNotValidNumber: true };
        }

        return null;
    }
}
