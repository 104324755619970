import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, } from '@angular/core';
import { NotificationService } from '../../../core/overlay/notification/notification.service';

export type FileTypeGroup = 'images' | 'spreadsheets' | 'archives' | 'pdfs';

@Component( {
    selector:    'rsp-drop-zone',
    templateUrl: './drop-zone.component.html',
    styleUrls:   [
        './drop-zone.component.scss',
    ],
} )
export class DropZoneComponent implements OnChanges {
    @Input() validFileTypeGroup: FileTypeGroup;
    @Input() inputFieldHtmlId: string;
    @Input() multiple: boolean                               = true;
    @Output() filesChanged: EventEmitter<File[] | null> = new EventEmitter<File[]>();

    @ViewChild( 'fileInput', { static: true } ) fileInput: ElementRef;

    dragActive: boolean = false;
    fileArray: File[];
    acceptedFileExtensions: string | null;

    constructor( private notificationService: NotificationService ) {
    }

    ngOnChanges( changes: SimpleChanges ): void {
        if ( changes.validFileTypeGroup.currentValue !== changes.validFileTypeGroup.previousValue ) {
            this.acceptedFileExtensions = this.getAcceptAttributeForFileTypeGroup( changes.validFileTypeGroup.currentValue );
        }
    }

    changeFiles(): void {
        // this method is somehow triggered twice in ie11, so this check prevents processFiles with empty files
        if ( this.fileInput.nativeElement.files.length ) {
            this.processFiles( this.fileInput.nativeElement.files );

            // resetting the input is needed to trigger "processFiles" for every selection (in case the file not changed, the event would not be fired)
            this.fileInput.nativeElement.value = '';
        }

    }

    dropFiles( fileList: FileList ): void {
        this.dragActive = false;

        this.processFiles( fileList );
    }

    private processFiles( fileList: FileList ): void {
        this.fileArray = Array.from( fileList );
        if ( this.validFileTypeGroup ) {
            // Despite the accept attribute on the `<input type="file">`, validation is still necessary, because the attribute may not be respected by browsers
            // when it comes to droping files on the input.
            this.fileArray = this.validateFileTypeOfUploadedFiles( this.fileArray );
        }

        this.filesChanged.emit( this.fileArray.length > 0 ? this.fileArray : null );
    }

    private validateFileTypeOfUploadedFiles( files: File[] ): File[] {

        return files.filter( ( file: File ) => {
            if ( this.getMimeTypesForFileTypeGroup( this.validFileTypeGroup ).some( ( mimeType: string ) => mimeType === file.type ) ) {
                return true;
            }
            else {
                this.notificationService.warning(
                    `The file ${ file.name } is no ${ this.validFileTypeGroup.substr( 0, this.validFileTypeGroup.length - 1 ) } file.`,
                    'File invalid',
                );
                return false;
            }
        } );
    }

    private getAcceptAttributeForFileTypeGroup( fileTypeGroup: FileTypeGroup ): string {
        let extensions: string[];

        switch ( fileTypeGroup ) {
            case 'images':
                extensions = [ 'jpg', 'jpeg', 'png', 'bmp', 'dwg', 'tif', 'tiff', 'indd', 'psd' ];
                break;

            case 'spreadsheets':
                extensions = [ 'xlsx', ];
                break;

            case 'archives':
                extensions = [ 'zip', ];
                break;

            case 'pdfs':
                extensions = [ 'pdf', ];
                break;

            default:
                throw new Error( `DropZoneComponent: Can not map filetypeGroup '${fileTypeGroup}' to extensions.` );
        }

        return extensions.map( ( extension: string ) => `.${extension}` ).join( ',' );
    }

    private getMimeTypesForFileTypeGroup( fileTypeGroup: FileTypeGroup ): string[] {
        let mimeTypes: string[];

        switch ( fileTypeGroup ) {
            case 'images':
                mimeTypes = [
                    'image/jpeg', 'image/jpg', 'image/jp_', 'application/jpg', 'application/x-jpg', 'image/pjpeg', 'image/pipeg', 'image/vnd.swiftview-jpeg',
                    'image/x-xbitmap',

                    'image/png', 'application/png', 'application/x-png',

                    'image/bmp', 'image/x-bmp', 'image/x-bitmap', 'image/x-xbitmap', 'image/x-win-bitmap', 'image/x-windows-bmp', 'image/ms-bmp',
                    'image/x-ms-bmp', 'application/bmp', 'application/x-bmp', 'application/x-win-bitmap',

                    'application/acad', 'application/x-acad', 'application/autocad_dwg', 'image/x-dwg', 'application/dwg', 'application/x-dwg',
                    'application/x-autocad', 'image/vnd.dwg', 'drawing/dwg',

                    'image/tif', 'image/x-tif', 'image/tiff', 'image/x-tiff', 'application/tif', 'application/x-tif', 'application/tiff', 'application/x-tiff',

                    'application/x-indesign',

                    'image/photoshop', 'image/x-photoshop', 'image/psd', 'application/photoshop', 'application/psd',
                ];
                break;

            case 'spreadsheets':
                mimeTypes = [ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', ];
                break;

            case 'archives':
                mimeTypes = [
                    'application/zip', 'application/x-zip', 'application/x-zip-compressed', 'application/octet-stream', 'application/x-compress',
                    'application/x-compressed', 'multipart/x-zip',
                ];
                break;

            case 'pdfs':
                mimeTypes = [ 'application/pdf', 'application/x-pdf', 'application/acrobat', 'applications/vnd.pdf', 'text/pdf', 'text/x-pdf', ];
                break;

            default:
                throw new Error( `DropZoneComponent: Can not map filetypeGroup '${fileTypeGroup}' to mime types.` );
        }

        return mimeTypes;
    }
}
