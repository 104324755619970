<rsp-list-page
    [rspLoadingIndicator]="isSaving"
    [isSidebarVisible]="isSidebarVisible"
    [facets]="facets | async"
    [chosenValuesFacet]="chosenValuesFacet | async"
    [usedQuickSearchTerm]="usedQuickSearchTerm | async"
    (quickSearchReset)="onQuickSearchReset()"
    (facetValuesChanged)="onFacetValuesChanged( $event )"
    (advancedSearchValuesChanged)="onAdvancedSearchValueChanged( $event )"
    (filtersReset)="onFilterReset()"
    (sidebarVisibilityChanged)="onSidebarVisibilityChanged( $event )"
>
    <rsp-virtual-scroll
        [items]="items | async"
        [columnCount]="1"
        [scrollToValue]="scrollToId"
        (update)="visibleItems = $event"
        (scrolledToBottom)="onScrolledToBottom()"
    >

        <rsp-no-content *ngIf="(items | async).length === 0 && !isLoading">
            Your search did not match any Documents
        </rsp-no-content>

        <ng-container *ngIf="(items | async).length > 0">
            <rsp-virtual-scroll-item *ngFor="let item of visibleItems;">
                <rsp-document-line
                    [item]="item"
                    (editClick)="openEditDialog( $event )"
                    (deleteDocumentConfirmed)="deleteDocument( $event )"
                ></rsp-document-line>
            </rsp-virtual-scroll-item>
        </ng-container>

    </rsp-virtual-scroll>
</rsp-list-page>

<rsp-add-or-edit-document-dialog></rsp-add-or-edit-document-dialog>
