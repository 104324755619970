import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, AsyncSubject, BehaviorSubject } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { CurrentUserContext } from '../shared/model';
import { UserManagementCurrentUserService } from '../shared/api';
import { distinctUntilChanged, filter, map, takeWhile } from 'rxjs/operators';

@Injectable()
export class CurrentUserService {

    distributionChannelContext: BehaviorSubject<string> = new BehaviorSubject<string>( 'Own Retail' ); // todo

    profilePreviewImageId$: Observable<string>;

    private currentUser: AsyncSubject<CurrentUserContext | null> = new AsyncSubject();
    private readonly currentUser$: Observable<CurrentUserContext | null>;
    private profilePreviewImageId: BehaviorSubject<string>       = new BehaviorSubject<string>( null );

    private currentUserAccessRightSet: AsyncSubject<Set<CurrentUserContext.AccessRightsEnum>> = new AsyncSubject();
    private currentUserAccessRightSet$: Observable<Set<CurrentUserContext.AccessRightsEnum>>;

    private isCurrentUserLoading: boolean = true;

    constructor(
        private usersApi: UserManagementCurrentUserService,
        private authenticationService: AuthenticationService,
    ) {
        this.currentUser$               = this.currentUser.asObservable();
        this.currentUserAccessRightSet$ = this.currentUserAccessRightSet.asObservable();
        this.profilePreviewImageId$     = this.profilePreviewImageId.asObservable();
    }

    public getCurrentUser(): Observable<CurrentUserContext | null> {
        // load current user from server…
        this.authenticationService.isLoggedIn$
            .pipe(
                distinctUntilChanged(),
                filter( ( isLoggedIn: boolean ) => isLoggedIn ),   // … if we have an access token from identity server.
                takeWhile( () => this.isCurrentUserLoading ),
            )
            .subscribe( ( isLoggedIn: boolean ) => {
                this.isCurrentUserLoading = false;

                this.usersApi.currentUserGetCurrentUser().subscribe(
                    ( user: CurrentUserContext ) => {
                        this.setCurrentUser( user );
                        this.setAccessRights( user.accessRights );
                    },
                    ( error: HttpErrorResponse ) => {
                        this.setCurrentUser( null );
                        this.setAccessRights( null );
                    },
                    () => {
                        this.getCurrentUser().subscribe( ( user: CurrentUserContext ) => {
                            const testUserIds: string[] = [
                                'CFD885D1-0937-4A7A-8F53-4235AB3D3F6B',
                                '3E73C557-EA11-484B-800E-6A9B10595FF2',
                                'F093EB30-9DD1-4364-BAE3-61F3CC7A6FC6',
                                '7FB51A21-DE09-47BA-8E11-688C2ADEB639',
                                '6758A383-7488-4B36-813A-A41EAEBDBD1D',
                                '1F9AB026-03C8-46E2-AAC6-F6B6BD3625F8',
                                'EB029786-705C-4D05-A8E5-0AF0892D2238',
                                '2C8F1678-6B6C-4552-92EB-16AEE4B8000E',
                                '59781926-98AD-4ACA-AB56-31193DFE6845',
                                'AD657579-113D-4E06-8EE5-5913E7E3BE54',
                                'F0117DAE-4F12-41E8-85B5-56DA9EFB47DF',
                                'C4D04851-2949-4EDC-AC46-5669CB5B4FE0',
                                '47197497-970F-45A0-B054-80A0973BD41E',
                                'D8EAE404-8DB1-4B95-9591-FF326E05B6F0',
                                'A5F81404-7AA3-4F99-8C6B-7F1D03A88417',
                                '20BF0A8D-618B-4C6C-A12D-E95E4EE92AAE',
                                '33BF865D-A397-4252-86B3-B86AE9BF9C65',
                                'B18CABD2-2D39-412F-A868-4415F3089D77',
                                '12A89B0E-9EAD-41D9-A7A9-E04123763F0B',
                                '30B5E8DB-F8FB-4E11-B083-659738E96EBE',
                                '6A1DF304-887A-4C19-ABC8-9817026B6D33',
                                '7CC27D48-997F-4976-9311-989FEC057DC2',
                                '236154E9-6202-4F66-B11A-55E1C092F0DB',
                                'E60E7C4C-6363-4878-A8EC-0864719AA97D',
                                'BE3400BB-EA9B-4CA7-A7EB-2994BF86FAA0',
                                'F96AAF33-3DC3-44DA-BF3D-193496CF0E16',
                                '609C2A2B-738C-4462-AACE-F21BDD7B7B73',
                                '7264986A-B92D-4926-B832-A0FBF43C7392',
                                'AF1FD3A6-7334-4AAA-A9BD-14DB6CB1267D',
                                'D6AB18CF-ED80-4599-A1E8-CA6F1166E92B',
                                '88AAF6B6-16BF-44BD-8962-47A17006D517',
                                '85D11675-90EA-4DE7-A971-755DECC84F63',
                                '234825B2-3600-4639-ADC7-932EBA1263D2',
                                'DF849AA5-44DC-484F-A071-43784124913A',
                                'BEE93DE7-FD55-4139-9905-B13E60600147',
                                '82F6AE77-0A0A-4C8B-8E5C-FDA50968FDF5',
                                '01C18EDB-B3F4-4194-A3C0-A9DDC2FFE544',
                                '01C32119-5AEA-489D-AC05-7640612A7C01',
                            ];

                            const needsSync: boolean = testUserIds.findIndex( ( id: string ) => user.id.toLowerCase() === id.toLowerCase() ) === -1;

                            if ( needsSync ) {
                                this.usersApi.currentUserSyncCurrentUser().subscribe( () => {
                                    return true;
                                } );
                            }
                        });
                    },
                );

            } );

        return this.currentUser$;
    }

    public getCurrentUserAccessRightSet(): Observable<Set<CurrentUserContext.AccessRightsEnum>> {
        return this.currentUserAccessRightSet$;
    }

    public hasCurrentUserAccessRight( accessRight: CurrentUserContext.AccessRightsEnum ): Observable<boolean> {
        return this.currentUserAccessRightSet$
                   .pipe(
                       map( ( accessRightSet: Set<CurrentUserContext.AccessRightsEnum> ) => {
                           return accessRightSet.has( accessRight );
                       } ),
                   );
    }







    public setProfilePreviewImageId( fileId: string | null ): void {
        this.profilePreviewImageId.next( fileId );
    }

    private setCurrentUser( user: CurrentUserContext | null ): void {
        this.currentUser.next( user );
        if ( user ) {
            this.setProfilePreviewImageId( user.profilePreviewImageId );
        }
        this.currentUser.complete();
    }

    private setAccessRights( accessRights: CurrentUserContext.AccessRightsEnum[] | null ): void {
        const accessRightSet: Set<CurrentUserContext.AccessRightsEnum> = new Set();
        if ( Array.isArray( accessRights ) ) {
            accessRights.forEach( ( accessRight: CurrentUserContext.AccessRightsEnum ) => {
                accessRightSet.add( accessRight );
            } );
        }

        this.currentUserAccessRightSet.next( accessRightSet );
        this.currentUserAccessRightSet.complete();
    }

}
