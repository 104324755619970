<ng-container *ngIf="displayMode === 'tag'">

    <div
        [rspTooltip]="tooltipTemplate"
        [rspTooltipConfig]="{ tooltipOffsetY: 10, tooltipOffsetX: 4 }"
        [class]="'adidas-status_tag' + ' ' + statusCssClassName">
        <span class="adidas-status--text">{{ statusName }}</span>
    </div>

</ng-container>

<ng-container *ngIf="displayMode === 'text-and-badge'">

    <div
        [rspTooltip]="tooltipTemplate"
        [rspTooltipConfig]="{ tooltipOffsetY: 10, tooltipOffsetX: 0 }"
        [class]="'adidas-status' + ' ' + statusCssClassName">
        <span class="adidas-status--text">{{ statusName }}</span>
        <span class="adidas-status--badge"></span>
    </div>

</ng-container>

<ng-container *ngIf="displayMode === 'badge'">

    <div
        [rspTooltip]="tooltipTemplate"
        [rspTooltipConfig]="{ tooltipOffsetY: 10, tooltipOffsetX: 3 }"
        [class]="'adidas-status' + ' ' + statusCssClassName">
        <abbr class="adidas-status--badge"></abbr>
    </div>

</ng-container>

<ng-template #tooltipTemplate>
    <div class="adidas-status--tooltip-container">
        <rsp-supplementary-caption>{{ statusName }}</rsp-supplementary-caption>
        <div *ngIf="isNonTradeItem && statusName === 'Active In Pre Rollout'">
            <p>This article is not finalized in Engineering yet and only available at dedicated vendors. Please consider:</p>
            <ul>
                <li>No final Value Engineering happened yet</li>
                <li>No availability of Engineering files yet</li>
                <li>No Engineering Prototype yet</li>
                <li>No optimized Supply Chain and Price yet</li>
                <li>Risk of a design, colour variations and tolerances issues for a later re-order in roll-out</li>
            </ul>
        </div>
        <div *ngIf="isNonTradeItem && statusName === 'Active In Rollout'">
            <p>Article is fully engineered and global Supply Chain is available</p>
        </div>
        <div *ngIf="isNonTradeItem && statusName === 'Discontinue'">
            <p>Article to be stopped but still offered to deplete stock and allow currently planning and ordering</p>
        </div>
        <div *ngIf="isNonTradeItem && statusName === 'Active On Demand'">
            <p>Article is offered on demand with limited range of vendors due to the limited life cycle of the current concept, price and vendor selection is executed on order level</p>
        </div>
        <div *ngIf="isNonTradeItem && statusName === 'Unavailable'">
            <p>Article is not offered anymore due to the limited life cycle of the concept</p>
        </div>
        <div *ngIf="isNonTradeItem && statusName === 'Inactive'">
            <p>Article is no longer wanted to be implemented and orders cannot be placed any longer</p>
        </div>
    </div>
</ng-template>
