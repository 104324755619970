import { Component, OnDestroy, ViewChild, TemplateRef, } from '@angular/core';
import { UntypedFormControl, Validators, } from '@angular/forms';
import { Router, } from '@angular/router';
import { Observable ,  Subscription, } from 'rxjs';
import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';

import { ArticleManagementAssembliesService } from '../../../../../../../shared/api/index';
import { AssemblyInfo, } from '../../../../../../../shared/model/assemblyInfo';
import { CustomValidators, } from '../../../../../../../shared/forms/validation/custom-validators';

import { CreateAssembly } from '../../../../../../../shared/model/createAssembly';
import { NonTradeItemSelectionService } from '../../../../shared/non-trade-item-selection.service';
import { NonTradeListItem } from '../../../../../../../shared/model/nonTradeListItem';

// TODO: CloneAssemblyActionComponent and CreateAssemblyDialogComponent are very similar. We probably should merge these
@Component( {
    selector:    'rsp-create-assembly-dialog',
    templateUrl: './create-assembly-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        './create-assembly-dialog.component.scss',
    ],
} )
export class CreateAssemblyDialogComponent implements OnDestroy {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    assemblyNameControl: UntypedFormControl = new UntypedFormControl( '', [ Validators.required, CustomValidators.stringNotEmpty, Validators.maxLength(73) ] );

    isLoading: Subscription;

    fromSelection: boolean;
    caption: string;

    items: Observable<NonTradeListItem[]>;

    constructor(
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private assembliesApi: ArticleManagementAssembliesService,
        private dialogService: DialogService,
        private router: Router,
    ) {}

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    doAction(): void {
        if ( this.assemblyNameControl.valid ) {

            let articleIds: Array<string>  = [];
            let assemblyIds: Array<string> = [];

            if ( this.fromSelection ) {
                 articleIds  = this.nonTradeItemSelectionService
                                  .getSelectedArticles()
                                  .map( ( item: NonTradeListItem ) => item.id );
                 assemblyIds = this.nonTradeItemSelectionService
                                   .getSelectedAssemblies()
                                   .map( ( item: NonTradeListItem ) => item.id );
            }
            const data: CreateAssembly = {
                articleIds:  articleIds,
                assemblyIds: assemblyIds,
                name:        this.assemblyNameControl.value,
            };

            this.isLoading =
                this.assembliesApi
                    .assembliesCreateAssembly( data )
                    .subscribe( ( result: AssemblyInfo ) => {
                        this.close();
                        this.router.navigate( [ '/assembly', result.number, ] );
                    } );
        }
    }

    open( createFromSelection: boolean = false ): void {
        this.assemblyNameControl.setValue( null );
        this.fromSelection = createFromSelection;
        this.caption = this.fromSelection ? 'Create Assembly from selection' : 'Create Assembly';
        this.dialogService.openDialog( {
                                           contentTemplate:  this.contentTemplate,
                                           headlineTemplate: this.headlineTemplate,
                                           footerTemplate:   this.footerTemplate,
                                           withBackdrop:     true,
                                       } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }
}
