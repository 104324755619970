import { Pipe, PipeTransform } from '@angular/core';
import { UserDetails, UserInfo } from '../../model';
import { UserNameService } from '../user-name/user-name.service';

@Pipe( {
    name: 'userName',
} )
export class UserNamePipe implements PipeTransform {

    constructor(
        private userNameService: UserNameService,
    ) {}

    transform( user: UserInfo | UserDetails, withCompany: boolean = false ): any {
        return this.userNameService.format( user );
    }

}
