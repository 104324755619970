import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, ValidationErrors, Validator } from '@angular/forms';

import { CustomValidators } from './custom-validators';

@Directive( {
    selector: '[rspIntegerValidation][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: IntegerValidationDirective, multi: true },
    ],
} )
export class IntegerValidationDirective implements Validator {

    validate( c: UntypedFormControl ): ValidationErrors | null {
        return !c.value ? null : CustomValidators.isInteger( c );
    }

}
