<!-- extra wrapper span needed, because it looks like Angular discards classes we place on our [rspKeyword]-hosts -->
<span class="language-keyword">
    <span
        rspKeyword
        [withClearButton]="hasClearButton"
        (clearClick)="onClearClick( language )"
    >
        <abbr *ngIf="language.name" title="{{ language.name }}">
            {{ language.code }}
        </abbr>

        <abbr *ngIf="!language.name">{{ language.code }}</abbr>
    </span>
</span>
