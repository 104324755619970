/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { AdvancedSearchFilterRange } from './advancedSearchFilterRange';


export interface AdvancedSearchFilterItem {
    name?: string;

    operator?: AdvancedSearchFilterItem.OperatorEnum;

    value?: string;

    range?: AdvancedSearchFilterRange;

}

export namespace AdvancedSearchFilterItem {
    'use strict';
    export enum OperatorEnum {
        StartsWith,
        Contains,
        Equals,
        Greater,
        GreaterOrEqual,
        Less,
        LessOrEqual,
        Between,
        DateEquals,
    }
    export namespace OperatorEnum {
        export function toString( enumValue: OperatorEnum ): string {
            return OperatorEnum[ enumValue ];
        }
    }
}

