import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    HostBinding,
    Input,
    NgZone,
    OnDestroy,
    Renderer2,
    ViewChild,
} from '@angular/core';


/**
 * Spinner and Progress-Spinner components.
 * Based on Angular Material -> https://material.angular.io/components/component/progress-spinner
 *
 */


/** A single degree in radians. */
const DEGREE_IN_RADIANS: number = Math.PI / 180;

/** Duration of the indeterminate animation. */
const DURATION_INDETERMINATE: number = 667;

/** Duration of the indeterminate animation. */
const DURATION_DETERMINATE: number = 225;

/** Start animation value of the indeterminate animation */
const startIndeterminate: number = 3;

/** End animation value of the indeterminate animation */
const endIndeterminate: number = 80;

/** Maximum angle for the arc. The angle can't be exactly 360, because the arc becomes hidden. */
const MAX_ANGLE: number = 359.99 / 100;

/** Whether the user's browser supports requestAnimationFrame. */
const HAS_RAF: boolean = typeof requestAnimationFrame !== 'undefined';

/** Default stroke width as a percentage of the viewBox. */
export const PROGRESS_SPINNER_STROKE_WIDTH: number = 10;

const DEFAULT_COLOR: SpinnerColor = 'main-blue';

export type ProgressSpinnerMode = 'determinate' | 'indeterminate';

type EasingFn = ( currentTime: number, startValue: number, changeInValue: number, duration: number ) => number;

export type SpinnerColor = 'white' | 'black' | 'main-blue' | 'medium-gray' | 'light-gray';


/**
 * <rsp-progress-spinner> component.
 */
@Component( {
    selector:        'rsp-progress-spinner',
    templateUrl:     './progress-spinner.component.html',
    styleUrls:       [
        './progress-spinner.component.scss',
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class ProgressSpinnerComponent implements AfterViewInit, OnDestroy {

    /** Stroke width of the progress spinner. By default uses 10px as stroke width. */
    @Input() strokeWidth: number = PROGRESS_SPINNER_STROKE_WIDTH;

    /** Color of the spinner. */
    @Input()
    get color(): SpinnerColor {
        return this._color;
    }

    set color( value: SpinnerColor ) {
        this.setColor( value );
    }

    /** The SVG <path> node that is used to draw the circle. */
    @ViewChild( 'path', { static: true } ) private path: ElementRef;

    /**
     * Values for aria max and min are only defined as numbers when in a determinate mode.  We do this
     * because voiceover does not report the progress indicator as indeterminate if the aria min
     * and/or max value are number values.
     */
    get ariaValueMin(): number {
        return this.mode === 'determinate' ? 0 : null;
    }

    get ariaValueMax(): number {
        return this.mode === 'determinate' ? 100 : null;
    }

    get interdeterminateInterval(): any {
        return this._interdeterminateInterval;
    }

    set interdeterminateInterval( interval: any ) {
        this.setInterdeterminateInterval( interval );
    }


    /** Value of the progress circle. It is bound to the host as the attribute aria-valuenow. */
    @Input()
    @HostBinding( 'attr.aria-valuenow' )
    get value(): number {
        return this.getValue();

    }

    set value( value: number ) {
        this.setValue( value );
    }

    /**
     * Mode of the progress circle
     *
     * Input must be one of the values from ProgressMode, defaults to 'determinate'.
     * mode is bound to the host as the attribute host.
     */
    @HostBinding( 'attr.mode' )
    protected get mode(): ProgressSpinnerMode {
        return this._mode;
    }

    protected set mode( mode: ProgressSpinnerMode ) {
        this.setMode( mode );
    }


    /** The id of the last requested animation. */
    private lastAnimationId: number = 0;

    private _interdeterminateInterval: any;
    private _mode: ProgressSpinnerMode = 'determinate';
    private _color: SpinnerColor;
    private _value: number;


    constructor(
        private renderer: Renderer2,
        private ngZone: NgZone,
    ) {
    }

    ngAfterViewInit(): void {
        if ( !this._color ) {
            // set default color
            this.setColor( DEFAULT_COLOR );
        }
    }

    /**
     * Clean up any animations that were running.
     */
    ngOnDestroy(): void {
        this.cleanupIndeterminateAnimation();
    }



    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setColor( value: SpinnerColor ): void {

        this.renderer.removeClass( this.path.nativeElement, this._color );
        this.renderer.addClass( this.path.nativeElement, value );

        this._color = value;
    }

    private setInterdeterminateInterval( value: any ): void {
        clearInterval( this._interdeterminateInterval );
        this._interdeterminateInterval = value;
    }

    private getValue(): number {

        if ( this.mode === 'determinate' ) {
            return this._value;
        }

        return null;
    }

    private setValue( value: number ): void {

        if ( value !== null && this.mode === 'determinate' ) {
            const newValue: number = clamp( value );
            this.animateCircle( this.value || 0, newValue );
            this._value = newValue;
        }
    }

    private setMode( mode: ProgressSpinnerMode ): void {

        if ( mode !== this._mode ) {
            if ( mode === 'indeterminate' ) {
                this.startIndeterminateAnimation();
            } else {
                this.cleanupIndeterminateAnimation();
                this.animateCircle( 0, this._value );
            }
            this._mode = mode;
        }
    }

    /**
     * Animates the circle from one percentage value to another.
     *
     * @param animateFrom The percentage of the circle filled starting the animation.
     * @param animateTo The percentage of the circle filled ending the animation.
     * @param ease The easing function to manage the pace of change in the animation.
     * @param duration The length of time to show the animation, in milliseconds.
     * @param rotation The starting angle of the circle fill, with 0° represented at the top center
     *    of the circle.
     */
    private animateCircle(
        animateFrom: number,
        animateTo: number,
        ease: EasingFn   = linearEase,
        duration: number = DURATION_DETERMINATE,
        rotation: number = 0,
    ): void {

        const id: number            = ++this.lastAnimationId;
        const startTime: number     = Date.now();
        const changeInValue: number = animateTo - animateFrom;

        // No need to animate it if the values are the same
        if ( animateTo === animateFrom ) {
            this.renderArc( animateTo, rotation );
        } else {
            const animation: () => void = () => {
                // If there is no requestAnimationFrame, skip ahead to the end of the animation.
                const elapsedTime: number = HAS_RAF
                    ? Math.max( 0, Math.min( Date.now() - startTime, duration ) )
                    : duration;

                this.renderArc(
                    ease( elapsedTime, animateFrom, changeInValue, duration ),
                    rotation,
                );

                // Prevent overlapping animations by checking if a new animation has been called for and
                // if the animation has lasted longer than the animation duration.
                if ( id === this.lastAnimationId && elapsedTime < duration ) {
                    requestAnimationFrame( animation );
                }
            };

            // Run the animation outside of Angular's zone, in order to avoid
            // hitting ZoneJS and change detection on each frame.
            this.ngZone.runOutsideAngular( animation );
        }
    }


    /**
     * Starts the indeterminate animation interval, if it is not already running.
     */
    private startIndeterminateAnimation(): void {
        let rotationStartPoint: number = 0;
        let start: number              = startIndeterminate;
        let end: number                = endIndeterminate;
        const duration: number         = DURATION_INDETERMINATE;
        const animate: () => void      = () => {
            this.animateCircle( start, end, materialEase, duration, rotationStartPoint );
            // Prevent rotation from reaching Number.MAX_SAFE_INTEGER.
            rotationStartPoint = (rotationStartPoint + end) % 100;
            const temp: number = start;
            start              = -end;
            end                = -temp;
        };

        if ( !this.interdeterminateInterval ) {
            this.ngZone.runOutsideAngular( () => {
                this.interdeterminateInterval = setInterval( animate, duration + 50, 0, false );
                animate();
            } );
        }
    }

    /**
     * Removes interval, ending the animation.
     */
    private cleanupIndeterminateAnimation(): void {
        this.interdeterminateInterval = null;
    }

    /**
     * Renders the arc onto the SVG element. Proxies `getArc` while setting the proper
     * DOM attribute on the `<path>`.
     */
    private renderArc( currentValue: number, rotation: number = 0 ): void {
        if ( this.path ) {
            const svgArc: string = getSvgArc( currentValue, rotation, this.strokeWidth );
            this.renderer.setAttribute( this.path.nativeElement, 'd', svgArc );
        }
    }
}

/**
 * <rsp-spinner> component.
 *
 * This is a component definition to be used as a convenience reference to create an
 * indeterminate <rsp-progress-spinner> instance.
 */
@Component( {
    selector:    'rsp-spinner',
    templateUrl: './progress-spinner.component.html',
    styleUrls:   [
        './progress-spinner.component.scss',
    ],
} )
export class SpinnerComponent extends ProgressSpinnerComponent implements OnDestroy {

    @HostBinding( 'attr.role' ) role: string = 'progressbar';

    constructor( ngZone: NgZone, renderer: Renderer2 ) {
        super( renderer, ngZone );
        this.mode = 'indeterminate';
    }

    ngOnDestroy(): void {
        // The `ngOnDestroy` from `ProgressSpinnerComponent` should be called explicitly, because
        // in certain cases Angular won't call it (e.g. when using AoT and in unit tests).
        super.ngOnDestroy();
    }
}

/**
 * Module functions.
 */

/** Clamps a value to be between 0 and 100. */
function clamp( v: number ): number {
    return Math.max( 0, Math.min( 100, v ) );
}


/**
 * Converts Polar coordinates to Cartesian.
 */
function polarToCartesian( radius: number, pathRadius: number, angleInDegrees: number ): string {
    const angleInRadians: number = (angleInDegrees - 90) * DEGREE_IN_RADIANS;

    return (radius + (pathRadius * Math.cos( angleInRadians ))) +
        ',' + (radius + (pathRadius * Math.sin( angleInRadians )));
}


/**
 * Easing function for linear animation.
 */
function linearEase( currentTime: number, startValue: number, changeInValue: number, duration: number ): number {
    return changeInValue * currentTime / duration + startValue;
}


/**
 * Easing function to match material design indeterminate animation.
 */
function materialEase( currentTime: number, startValue: number, changeInValue: number, duration: number ): number {
    const time: number      = currentTime / duration;
    const timeCubed: number = Math.pow( time, 3 );
    const timeQuad: number  = Math.pow( time, 4 );
    const timeQuint: number = Math.pow( time, 5 );
    return startValue + changeInValue * ((6 * timeQuint) + (-15 * timeQuad) + (10 * timeCubed));
}


/**
 * Determines the path value to define the arc.  Converting percentage values to to polar
 * coordinates on the circle, and then to cartesian coordinates in the viewport.
 *
 * @param currentValue The current percentage value of the progress circle, the percentage of the
 *    circle to fill.
 * @param rotation The starting point of the circle with 0 being the 0 degree point.
 * @param strokeWidth Stroke width of the progress spinner arc.
 * @return A string for an SVG path representing a circle filled from the starting point to the
 *    percentage value provided.
 */
function getSvgArc( currentValue: number, rotation: number, strokeWidth: number ): string {
    const startPoint: number = rotation || 0;
    const radius: number     = 50;
    const pathRadius: number = radius - strokeWidth;

    const startAngle: number = startPoint * MAX_ANGLE;
    const endAngle: number   = currentValue * MAX_ANGLE;
    const start: string      = polarToCartesian( radius, pathRadius, startAngle );
    const end: string        = polarToCartesian( radius, pathRadius, endAngle + startAngle );
    const arcSweep: number   = endAngle < 0 ? 0 : 1;
    let largeArcFlag: number;

    if ( endAngle < 0 ) {
        largeArcFlag = endAngle >= -180 ? 0 : 1;
    } else {
        largeArcFlag = endAngle <= 180 ? 0 : 1;
    }

    return `M${start}A${pathRadius},${pathRadius} 0 ${largeArcFlag},${arcSweep} ${end}`;
}
