import { Component, Input,  } from '@angular/core';

@Component({
    selector: 'rsp-cluster-link',
    template: '<a [routerLink]="[ \'/cluster\', clusterId ]">{{ linkText }}</a>',
})
export class ClusterLinkComponent {

    @Input() clusterId: string;

    @Input() linkText: string;
}
