/* tslint:disable:max-line-length */

import { NgModule,                                  } from '@angular/core';

import { ArticleManagementRoutingModule,            } from './article-management-routing.module';
import { SharedModule,                              } from '../../shared/shared.module';

import { AddOrRemoveClusterDialogComponent,         } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/add-or-remove-cluster-dialog/add-or-remove-cluster-dialog.component';
import { CreateAssemblyDialogComponent,             } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/create-assembly-dialog/create-assembly-dialog.component';
import { CreateClusterDialogComponent,              } from './shared/actions/create-cluster-dialog/create-cluster-dialog.component';

import { NonTradeItemDetailsPageComponent,                     } from './non-trade-items/non-trade-item/non-trade-item-details-page.component';
import { NonTradeItemGraphicsComponent,             } from './non-trade-items/non-trade-item/non-trade-item-graphics/non-trade-item-graphics.component';
import { NonTradeItemHistoryComponent,              } from './non-trade-items/non-trade-item/non-trade-item-history/non-trade-item-history.component';
import { NonTradeItemStylesAndArtworksComponent,    } from './non-trade-items/non-trade-item/non-trade-item-styles-and-artworks/non-trade-item-styles-and-artworks.component';
import { ArticleDetailSpmComponent,                 } from './non-trade-items/non-trade-item/article-detail-spm/article-detail-spm.component';
import { NonTradeItemDetailsComponent,              } from './non-trade-items/non-trade-item/non-trade-item-details/non-trade-item-details.component';
import { NonTradeItemFilesComponent,                } from './non-trade-items/non-trade-item/non-trade-item-files/non-trade-item-files.component';
import { NonTradeItemAssemblyComponentsComponent,   } from './non-trade-items/non-trade-item/non-trade-item-assembly-components/non-trade-item-assembly-components.component';

import { NonTradeItemArticleAssemblyUsageComponent,         } from './non-trade-items/non-trade-item/non-trade-item-article-assembly-usage/non-trade-item-article-assembly-usage.component';
import { NonTradeItemAssemblyComponentsMultiEditComponent,  } from './non-trade-items/non-trade-item/non-trade-item-assembly-components-multi-edit/non-trade-item-assembly-components-multi-edit.component';
import { NonTradeItemAssemblyComponentsMultiEditService,    } from './non-trade-items/non-trade-item/non-trade-item-assembly-components-multi-edit/non-trade-item-assembly-components-multi-edit.service';

import { NonTradeItemSummaryResolver,               } from './non-trade-items/non-trade-item/non-trade-item-summary.resolver';
import { NonTradeItemTileComponent,                 } from './non-trade-items/non-trade-item-list/non-trade-item-tile-list/non-trade-item-tile/non-trade-item-tile.component';
import { NonTradeItemListPageComponent,             } from './non-trade-items/non-trade-item-list/non-trade-item-list-page.component';
import { EditSpmGroupAndTypeComponent,              } from './non-trade-items/shared/forms/edit-spm-group-and-type/edit-spm-group-and-type.component';

import { NonTradeItemService,                       } from './non-trade-items/shared/non-trade-item.service';

import { ClusterDetailsPageComponent,                          } from './concepts-clusters/cluster/cluster-details-page.component';
import { ClusterDetailsComponent,                   } from './concepts-clusters/cluster/cluster-details/cluster-details.component';
import { ClusterArticlesAndAssembliesComponent,     } from './concepts-clusters/cluster/cluster-articles-and-assemblies/cluster-articles-and-assemblies.component';
import { ClusterFilesComponent,                     } from './concepts-clusters/cluster/cluster-files/cluster-files.component';
import { ClusterHistoryComponent,                   } from './concepts-clusters/cluster/cluster-history/cluster-history.component';
import { ClusterSummaryResolver,                    } from './concepts-clusters/cluster/cluster-summary.resolver';
import { ClusterService,                            } from './concepts-clusters/cluster/cluster.service';

import { ConceptListPageComponent,                      } from './concepts-clusters/concept-cluster-list/concept-cluster-list-page.component';
import { ConceptClusterTileComponent,                      } from './concepts-clusters/concept-cluster-list/concept-cluster-tile-list/concept-cluster-tile/concept-cluster-tile.component';
import { ConceptDetailsPageComponent,                          } from './concepts-clusters/concept/concept-details-page.component';
import { ConceptDetailsComponent,                   } from './concepts-clusters/concept/concept-details/concept-details.component';
import { ConceptClustersComponent,                  } from './concepts-clusters/concept/concept-clusters/concept-clusters.component';
import { ConceptServiceLevelsComponent,             } from './concepts-clusters/concept/concept-service-levels/concept-service-levels.component';
import { ConceptFilesComponent,                     } from './concepts-clusters/concept/concept-files/concept-files.component';
import { ConceptHistoryComponent,                   } from './concepts-clusters/concept/concept-history/concept-history.component';
import { ClusterArticleAssemblyCountComponent,      } from './concepts-clusters/concept/concept-clusters/cluster-article-assembly-count/cluster-article-assembly-count.component';
import { ConceptDetailResolver,                     } from './concepts-clusters/concept/concept-summary.resolver';
import { ConceptService,                            } from './concepts-clusters/concept/concept.service';

import { DisplayEditMaterialsComponent,             } from './non-trade-items/shared/forms/display-edit-materials/display-edit-materials.component';
import { EditMaterialComponent,                     } from './non-trade-items/shared/forms/display-edit-materials/edit-material/edit-material.component';
import { DisplayEditCapacitiesComponent,            } from './non-trade-items/shared/forms/display-edit-capacities/display-edit-capacities.component';
import { DisplayEditSubComponentsComponent,         } from './non-trade-items/shared/forms/display-edit-subcomponents/display-edit-subcomponents.component';
import { CardWeightComponent } from './non-trade-items/non-trade-item/non-trade-item-details/card-weight/card-weight.component';
import { CardKeywordsComponent } from './non-trade-items/non-trade-item/non-trade-item-details/card-keywords/card-keywords.component';
import { CardConceptAndClusterComponent } from './non-trade-items/non-trade-item/non-trade-item-details/card-concept-and-cluster/card-concept-and-cluster.component';
import { NonTradeItemStoreService } from './non-trade-items/shared/non-trade-item-store.service';
import { NonTradeItemSelectionService } from './non-trade-items/shared/non-trade-item-selection.service';
import { NonTradeItemActionsListComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/non-trade-item-actions-list.component';
import { ConceptClusterSelectionService } from './concepts-clusters/shared/concept-cluster-selection.service';
import { ConceptClusterStoreService } from './concepts-clusters/shared/concept-cluster-store.service';
import { ConceptBrandAndSubBrandEditComponent } from './concepts-clusters/concept/concept-details/concept-brand-and-subbrand-edit/concept-brand-and-subbrand-edit.component';
import { DisplayEditClusterCategoriesComponent } from './concepts-clusters/cluster/cluster-details/display-edit-cluster-categories/display-edit-cluster-categories.component';
import { DisplayEditRetailSegmentationsComponent } from './concepts-clusters/cluster/cluster-details/display-edit-retail-segmentations/display-edit-retail-segmentations.component';
import { DisplayEditStoreSubFormatsComponent } from './concepts-clusters/cluster/cluster-details/display-edit-distribution-channels/display-edit-own-retail/display-edit-store-sub-formats/display-edit-store-sub-formats.component';
import { DisplayEditDistributionChannelsComponent } from './concepts-clusters/cluster/cluster-details/display-edit-distribution-channels/display-edit-distribution-channels.component';
import { DisplayEditStoreFormatsComponent } from './concepts-clusters/cluster/cluster-details/display-edit-distribution-channels/display-edit-own-retail/display-edit-store-formats/display-edit-store-formats.component';
import { SetEngineeringPartnerDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/set-engineering-partner-dialog/set-engineering-partner-dialog.component';
import { NonTradeItemsToolbarComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-items-toolbar.component';
import { MaterialSuggesterComponent } from './non-trade-items/shared/material-suggester/material-suggester.component';
import { NonTradeItemGraphicEditComponent } from './non-trade-items/non-trade-item/non-trade-item-graphics/non-trade-item-graphic-edit/non-trade-item-graphic-edit.component';
import { EditClusterCategoriesComponent } from './concepts-clusters/cluster/cluster-details/display-edit-cluster-categories/edit-cluster-categories.component';
import { EditRetailSegmentationsComponent } from './concepts-clusters/cluster/cluster-details/display-edit-retail-segmentations/edit-retail-segmentations.component';
import { DisplayEditOwnRetailComponent } from './concepts-clusters/cluster/cluster-details/display-edit-distribution-channels/display-edit-own-retail/display-edit-own-retail.component';
import { DisplayEditFranchiseComponent } from './concepts-clusters/cluster/cluster-details/display-edit-distribution-channels/display-edit-franchise/display-edit-franchise.component';
import { EditDistributionChannelComponent } from './concepts-clusters/cluster/cluster-details/display-edit-distribution-channels/edit-distribution-channel.component';
import { DisplayEditWholesaleComponent } from './concepts-clusters/cluster/cluster-details/display-edit-distribution-channels/display-edit-wholesale/display-edit-wholesale.component';
import { NonTradeItemLineComponent } from './non-trade-items/non-trade-item-list/non-trade-item-tile-list/non-trade-item-line/non-trade-item-line.component';
import { EditCapacitiesComponent } from './non-trade-items/shared/forms/display-edit-capacities/edit-capacities.component';
import { NonTradeItemPrevNextNavigationService } from './non-trade-items/non-trade-item/non-trade-item-prev-next-navigation/non-trade-item-prev-next-navigation.service';
import { NonTradeItemPrevNextNavigationComponent } from './non-trade-items/non-trade-item/non-trade-item-prev-next-navigation/non-trade-item-prev-next-navigation.component';
import { ConceptClusterToolbarComponent } from './concepts-clusters/shared/concept-cluster-toolbar/concept-cluster-toolbar.component';
import { ConceptClusterLineComponent } from './concepts-clusters/concept-cluster-list/concept-cluster-tile-list/concept-cluster-line/concept-cluster-line.component';
import { ConceptClusterService } from './concepts-clusters/shared/concept-cluster.service';
import { ConceptClusterPrevNextNavigationComponent } from './concepts-clusters/shared/concept-cluster-prev-next-navigation/concept-cluster-prev-next-navigation.component';
import { ConceptClusterPrevNextNavigationService } from './concepts-clusters/shared/concept-cluster-prev-next-navigation/concept-cluster-prev-next-navigation.service';
import { CardLanguagesComponent } from './non-trade-items/non-trade-item/non-trade-item-styles-and-artworks/card-languages/card-languages.component';
import { CreateArticleDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/create-article-dialog/create-article-dialog.component';
import { LanguageKeywordComponent } from './non-trade-items/non-trade-item/non-trade-item-styles-and-artworks/language-keyword/language-keyword.component';
import { NonTradeItemSelectionListComponent } from './non-trade-items/shared/non-trade-item-selection-list/non-trade-item-selection-list.component';
import { ConceptClusterListActionsComponent } from './concepts-clusters/concept-cluster-list/concept-cluster-list-actions/concept-cluster-list-actions.component';
import { CreateConceptDialogComponent } from './concepts-clusters/concept-cluster-list/concept-cluster-list-actions/actions/create-concept-dialog/create-concept-dialog.component';
import { CopyConceptDialogComponent } from './concepts-clusters/concept-cluster-list/concept-cluster-list-actions/actions/copy-concept-dialog/copy-concept-dialog.component';
import { CopyAssemblyDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/copy-assembly-dialog/copy-assembly-dialog.component';
import { AddSelectionToAssemblyDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/add-selection-to-assembly-dialog/add-selection-to-assembly-dialog.component';
import { RemoveSelectionFromAssemblyDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/remove-selection-from-assembly-dialog/remove-selection-from-assembly-dialog.component';
import { ExportSelectionToExcelDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/export-selection-to-excel-dialog/export-selection-to-excel-dialog.component';
import { ExportSearchResultToExcelDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/export-search-result-to-excel-dialog/export-search-result-to-excel-dialog.component';
import { ExportSearchResultToAribaDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/export-search-result-to-ariba-dialog/export-search-result-to-ariba-dialog.component';
import { EditConceptServiceModelsAssignmentDialogComponent } from './concepts-clusters/concept/concept-service-levels/edit-concept-service-models-assignment-dialog/edit-concept-service-models-assignment-dialog.component';
import { SecondaryToolbarService } from '../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { ExportArticleAssemblyFilesDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/export-article-assembly-files-dialog/export-article-assembly-files-dialog.component';
import { CreateAssemblyFromExcelDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/create-assembly-from-excel-dialog/create-assembly-from-excel-dialog.component';
import { SetArticleStatusDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/set-article-status-dialog/set-article-status-dialog.component';
import { SetAssemblyStatusDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/set-assembly-status-dialog/set-assembly-status-dialog.component';
import { CardRequiredFilesComponent } from './non-trade-items/non-trade-item/non-trade-item-details/card-required-files/card-required-files.component';
import { ExportSelectionToAribaDialogComponent } from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/export-selection-to-ariba-dialog/export-selection-to-ariba-dialog.component';
import {
    SetNonTradeItemStatusDialogComponent,
} from './non-trade-items/non-trade-item/non-trade-item-details/set-non-trade-item-status-dialog/set-non-trade-item-status-dialog.component';
import {
    CreateArticleFromExcelDialogComponent,
} from './non-trade-items/non-trade-items-toolbar/non-trade-item-actions-list/actions/create-article-from-excel-dialog/create-article-from-excel-dialog.component';

/* tslint:enable:max-line-length */



@NgModule( {
    imports: [
        ArticleManagementRoutingModule,
        SharedModule,
    ],
    declarations: [
        // non-trade-items/shared:
        NonTradeItemSelectionListComponent,

        DisplayEditCapacitiesComponent,
        EditCapacitiesComponent,
        DisplayEditMaterialsComponent,
        EditMaterialComponent,
        DisplayEditSubComponentsComponent,
        MaterialSuggesterComponent,

        // Non-Trade-Item
        AddOrRemoveClusterDialogComponent,
        AddSelectionToAssemblyDialogComponent,
        ArticleDetailSpmComponent,
        CardKeywordsComponent,
        CardWeightComponent,
        CardConceptAndClusterComponent,
        CreateArticleDialogComponent,
        CreateAssemblyDialogComponent,
        CreateAssemblyFromExcelDialogComponent,
        CreateArticleFromExcelDialogComponent,
        CopyAssemblyDialogComponent,
        ExportSelectionToExcelDialogComponent,
        ExportSearchResultToAribaDialogComponent,
        ExportSelectionToAribaDialogComponent,
        ExportSearchResultToExcelDialogComponent,
        LanguageKeywordComponent,
        NonTradeItemDetailsPageComponent,
        NonTradeItemGraphicsComponent,
        NonTradeItemGraphicEditComponent,
        NonTradeItemHistoryComponent,
        NonTradeItemStylesAndArtworksComponent,
        NonTradeItemDetailsComponent,
        NonTradeItemFilesComponent,
        NonTradeItemAssemblyComponentsComponent,
        NonTradeItemArticleAssemblyUsageComponent,
        NonTradeItemAssemblyComponentsMultiEditComponent,
        NonTradeItemPrevNextNavigationComponent,
        NonTradeItemTileComponent,
        NonTradeItemLineComponent,
        NonTradeItemActionsListComponent,
        NonTradeItemListPageComponent,
        NonTradeItemsToolbarComponent,
        SetEngineeringPartnerDialogComponent,
        SetArticleStatusDialogComponent,
        SetAssemblyStatusDialogComponent,
        SetNonTradeItemStatusDialogComponent,

        // Edit Non-Trade-Item
        EditSpmGroupAndTypeComponent,

        // ConceptCluster
        ConceptClusterPrevNextNavigationComponent,
        ConceptClusterListActionsComponent,
        CreateConceptDialogComponent,
        CopyConceptDialogComponent,
        EditConceptServiceModelsAssignmentDialogComponent,

        // Cluster
        ClusterDetailsPageComponent,
        ClusterDetailsComponent,
        ClusterArticlesAndAssembliesComponent,
        ClusterFilesComponent,
        ClusterHistoryComponent,
        DisplayEditClusterCategoriesComponent,
        EditClusterCategoriesComponent,
        DisplayEditRetailSegmentationsComponent,
        EditRetailSegmentationsComponent,
        DisplayEditStoreFormatsComponent,
        DisplayEditStoreSubFormatsComponent,
        DisplayEditDistributionChannelsComponent,
        EditDistributionChannelComponent,
        DisplayEditOwnRetailComponent,
        DisplayEditFranchiseComponent,
        DisplayEditWholesaleComponent,


        // Concept
        ConceptListPageComponent,
        ConceptDetailsPageComponent,
        ConceptDetailsComponent,
        ConceptBrandAndSubBrandEditComponent,
        ConceptClustersComponent,
        ConceptServiceLevelsComponent,
        ConceptFilesComponent,
        ConceptHistoryComponent,
        ClusterArticleAssemblyCountComponent,
        ConceptClusterTileComponent,
        ConceptClusterToolbarComponent,
        ConceptClusterLineComponent,
        CardLanguagesComponent,

        // shared
        CreateClusterDialogComponent,

        RemoveSelectionFromAssemblyDialogComponent,

        ExportArticleAssemblyFilesDialogComponent,

        CardRequiredFilesComponent,
    ],
    providers: [

        NonTradeItemAssemblyComponentsMultiEditService,
        NonTradeItemSummaryResolver,

        NonTradeItemPrevNextNavigationService,
        NonTradeItemService,
        NonTradeItemSelectionService,
        NonTradeItemStoreService,
        ConceptClusterPrevNextNavigationService,
        ClusterService,
        ConceptService,
        ConceptClusterService,
        ConceptClusterSelectionService,
        ConceptClusterStoreService,
        ConceptDetailResolver,
        ClusterSummaryResolver,

        SecondaryToolbarService,
    ],
} )
export class ArticleManagementModule {
}



