<div *ngIf="showClearButton"
     class="secondary-toolbar-slidein-button--clear-button-container"
>
    <rsp-button
        *ngIf="showClearButton && badgeValue > 0"
        [submodule]="'seamless'"
        (click)="onClearButtonClick()"
        class="secondary-toolbar-slidein-button--clear-button"
        title="Clear"
    ></rsp-button>
</div>

<button #buttonContainer
        type="button"
        class="secondary-toolbar-slidein-button"
        [class.is-active]="isActive"
        (click)="toggleActive()"
>
    <rsp-badge-counter
        *ngIf="badgeValue > 0"
        class="secondary-toolbar-slidein-button--badge"
        [content]="badgeValue"
        [color]="'main-blue'"
    ></rsp-badge-counter>

    <div class="secondary-toolbar-slidein-button--icon-{{ icon }}"></div>

    <div class="secondary-toolbar-slidein-button--label">{{ label }}</div>

    <div class="secondary-toolbar-slidein-button--open-close-button"
         [@rotateButtonTrigger]="isActive"
    ></div>

</button>

<ng-template #slideInContent>
    <ng-content></ng-content>
</ng-template>
