<rsp-edit-cluster-categories
    *ngIf="isEditMode"
    [concept]="concept"
    [categories]="categories"
    [myFormControl]="myFormControl"
>
</rsp-edit-cluster-categories>


<ng-container *ngIf="!isEditMode && ( categories == null || categories?.length === 0 )">
    - ALL -
</ng-container>

<rsp-tags *ngIf="!isEditMode && categories?.length" [tags]="getCategoriesAsTags()" [showEmptyText]="false">
</rsp-tags>
