import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

import { CollectionView } from '../../collection-view';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

/**
 * Global filter. Can be used with CollectionView to filter table.
 */
@Component( {
    selector:    'rsp-global-filter',
    templateUrl: './global-filter.component.html',
    styleUrls:   [
        './global-filter.component.scss',
    ],
} )
export class GlobalFilterComponent implements OnInit {

    @Input()
    collectionView: CollectionView<any>;

    @Input()
    placeholder: string = 'Quick Search';

    formControl: UntypedFormControl = new UntypedFormControl();

    ngOnInit(): void {

        this.formControl
            .valueChanges
            .pipe(
                debounceTime( 500 ),
                distinctUntilChanged(),
            )
            .subscribe( ( filter: string ) => {

                if ( !this.collectionView ) {
                    throw new Error( '[GlobalFilterComponent] Input property "collectionView" is not set.' );
                }

                this.collectionView
                    .filterBy( filter )
                    .refresh();
            } );
    }
}
