import { Observable ,  BehaviorSubject } from 'rxjs';


/**
 * Base class for object specific selection services (f.e. NonTradeItemSelectionService, ConceptClusterSelectionService etc.)
 * Provides an observable property (selectedItems$) which can be used by observers which want to be
 * notified when selection has been changed.
 */
export abstract class BaseSelectionService<T> {

    /**
     * Observable with currently selected items.
     */
    selectedItems$: Observable<Array<T>>;

    /**
     * Can be used to determine if checkboxes should be shown or not.
     */
    isSelectionAvailable$: Observable<boolean>;

    hasSelectedItems$: Observable<boolean>;


    private selectedItems: BehaviorSubject<Array<T>>;
    private isSelectionAvailable: BehaviorSubject<boolean>;
    private hasSelectedItems: BehaviorSubject<boolean>;

    // Map for better performance
    // NOTE: Map is not fully supported by all browsers. We are using only supported features -> http://kangax.github.io/compat-table/es6/#test-Map
    private selectedItemsMap: Map<string, T>;

    private lastSelectedItem: T = null;


    constructor() {
        this.selectedItems    = new BehaviorSubject<Array<T>>( [] );
        this.selectedItems$   = this.selectedItems.asObservable();
        this.selectedItemsMap = new Map();

        this.isSelectionAvailable  = new BehaviorSubject<boolean>( false );
        this.isSelectionAvailable$ = this.isSelectionAvailable.asObservable();

        this.hasSelectedItems  = new BehaviorSubject( false );
        this.hasSelectedItems$ = this.hasSelectedItems.asObservable();
    }


    /**
     * Returns selected items.
     */
    getSelectedItems(): Array<T> {
        return this.getSelectedItemsFromMap();
    }


    /**
     * Marks specified item as selected.
     */
    addToSelection( item: T ): void {

        if ( !this.isSelected( item ) ) {

            this.selectedItemsMap.set( this.getId( item ), item );

            this.lastSelectedItem = item;

            this.notifySubscribers();
        }
    }

    /**
     * Removes specified item from selection.
     */
    removeFromSelection( item: T ): void {

        const selectedItem: T = this.selectedItemsMap.get( this.getId( item ) );

        if ( selectedItem ) {

            this.selectedItemsMap.delete( this.getId( item ) );

            this.lastSelectedItem = null;

            this.notifySubscribers();
        }
    }

    /**
     * Toggles selection for specified item. Returns 'true' when item has been selected or 'false' when unselected.
     */
    toggleSelection( item: T ): boolean {

        if ( this.isSelected( item ) ) {
            this.removeFromSelection( item );
            return false;
        }
        else {
            this.addToSelection( item );
            return true;
        }
    }

    /**
     * Returns true when specified item is marked as selected.
     */
    isSelected( item: T ): boolean {
        return this.selectedItemsMap.get( this.getId( item )) !== undefined;
    }

    /**
     * Clears selection.
     */
    clearSelection(): void {

        this.selectedItemsMap.clear();
        // TODO: add unit test
        this.lastSelectedItem = null;

        this.notifySubscribers();
    }


    /**
     * Returns last selected item. If last action was "removeFromSelection" this method returns null.
     */
    getLastSelectedItem(): T {
        return this.lastSelectedItem;
    }


    /**
     * Clears last selected item.
     */
    clearLastSelectedItem(): void {
        this.lastSelectedItem = null;
    }


    /**
     * Add range of items into selection.
     */
    addRangeToSelection( items: Array<T> ): void {

        if ( items ) {
            items.forEach( ( item: T ) => this.addToSelection( item ) );
        }
    }

    setSelectionAvailable( state: boolean ): void {
        this.isSelectionAvailable.next( state );
    }


    /**
     * Returns identifier for T. Must be implemented by the class which inherits BaseSelectionService.
     */
    protected abstract getId( item: T ): string;



    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private notifySubscribers(): void {
        const selectedItems: T[] = this.getSelectedItemsFromMap();

        this.selectedItems.next( selectedItems );
        this.hasSelectedItems.next( selectedItems.length > 0 );
    }

    private getSelectedItemsFromMap(): Array<T> {

        // NOTE: Testing if it works with IE11. If not, uncomment code below.
        return Array.from( this.selectedItemsMap.values() );

        // NOTE: this.selectedItemsMap.values() is not supported in IE11, so we must do this manually
        // const resultArray: Array<NonTradeListItem> = [];
        //
        // this.selectedItemsMap.forEach( ( value: NonTradeListItem ) => {
        //     resultArray.push( value );
        // } );
        //
        // return resultArray;
    }
}
