import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormControl, ValidatorFn } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { UploadedFileInfo } from '../../upload/upload-single-file/upload-single-file.component';
import { RegistrationUserData } from '../../../../features/registration/registration-wizard/registration-wizard.component';
import { FileKindInfo, FileKindItem, UserDetails } from '../../../model';
import { FileManagementFileKindsService } from '../../../api';

@Component( {
    selector:    'rsp-display-edit-user-profile-image',
    templateUrl: './display-edit-user-profile-image.component.html',
    styleUrls:   [ './display-edit-user-profile-image.component.scss' ],
} )
export class DisplayEditUserProfileImageComponent implements OnInit, OnDestroy {
    @Input() myFormControl: UntypedFormControl;
    @Input() isEditMode: boolean = false;
    @Input() user: RegistrationUserData | UserDetails;

    /**
     * Optional ID that will be used as HTML-ID-attribute so you can link `<label for="">` with the `<input>`.
     */
    @Input() inputFieldHtmlId: string;

    @Output() fileUpdated: EventEmitter<UploadedFileInfo | null> = new EventEmitter();

    profileImageFileKind: FileKindInfo;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private fileKindsApi: FileManagementFileKindsService,
    ) { }

    static buildFormControl( value: string | null, validators?: Array<ValidatorFn> ): UntypedFormControl {
        return new UntypedFormControl( value, validators );
    }

    ngOnInit(): void {
        this.fileKindsApi
            .fileKindsGetFileKindsByArea( FileKindItem.AreasEnum.toString( FileKindItem.AreasEnum.User ) )
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( result: FileKindInfo[] ) => {
                this.profileImageFileKind = result[ 0 ];
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    clearFileContainer(): void {
        this.myFormControl.setValue( null );
    }

    onFileChange( file: UploadedFileInfo | null ): void {
        this.myFormControl.setValue( file ? file.fileContainerId : null );
        this.myFormControl.markAsDirty();
        this.myFormControl.markAsTouched();

        this.fileUpdated.emit( file );
    }
}
