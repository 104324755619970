import { Injectable, } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router, } from '@angular/router';
import { Observable } from 'rxjs';

import { CurrentUserService } from '../current-user.service';
import { CurrentUserContext } from '../../shared/model';
import { map } from 'rxjs/operators';

/**
 * Checks if the current user has an active RSP user account.
 *
 * If the check fails, user is redirected to registration page.
 */
@Injectable()
export class RspUserGuard implements CanActivate, CanActivateChild {

    constructor(
        private currentUserService: CurrentUserService,
        private router: Router,
    ) {
    }

    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
        // console.log( '[RspUserGuard.canActivate] Checking \'' + route.url + '\' on the way to \'' + state.url + '\'' );

        return this.authorize( state );
    }

    canActivateChild( route: ActivatedRouteSnapshot, state: RouterStateSnapshot ): Observable<boolean> {
        // console.log( '[RspUserGuard.canActivateChild] Checking \'' + route.url + '\' on the way to \'' + state.url + '\'' );

        return this.authorize( state );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private authorize( state: RouterStateSnapshot ): Observable<boolean> {
        return this.currentUserService
                   .getCurrentUser()
                   .pipe(
                       map( ( user: CurrentUserContext | null ) => {
                           if ( !user ) {
                               // console.log( 'User has no RSP account, yet. Redirect to registration page.' );

                               this.router.navigateByUrl( '/registration' );

                               return false;
                           }

                           return true;
                       } ),
                   );
    }
}
