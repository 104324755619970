<!-- header -->
<ng-template #dialogHeaderTemplate>
    <h2 class="standard-dialog--headline">
        Download selected {{itemType}}
    </h2>
</ng-template>

<!-- content -->
<ng-template #dialogContentTemplate>

    <div [rspLoadingIndicator]="isLoading"
         class="standard-dialog--content create-zip-from-files-dialog">

        <div class="create-zip-from-files-dialog--info">
            You will be notified by E-Mail once your ZIP file is ready to download.
        </div>

    </div>

</ng-template>


<!-- footer -->
<ng-template #dialogFooterTemplate>

    <div class="standard-dialog--footer">

        <rsp-edit-text
            [myFormControl]="nameControl"
            [placeholder]="'Download Name'"
            style="flex: 1"
        ></rsp-edit-text>

        <rsp-form-buttons
            [isEditMode]="true"
            [disablePrimaryEditButton]="!nameControl.valid"
            [actionButtonLabel]="'Trigger Download'"
            (secondaryEditButtonClick)="close()"
            (primaryEditButtonClick)="doAction()"
        ></rsp-form-buttons>

    </div>

</ng-template>

