import { Injectable } from '@angular/core';

import { NonTradeListItem } from '../../../../shared/model/nonTradeListItem';
import { BaseSelectionService } from '../../../../shared/ui/lists/base-selection.service';


/**
 * Handles selection of NonTradeItems. Provides an observable property (selectedItems$) which can be used by observers
 * which want to be notified when selection has been changed.
 */
@Injectable()
export class NonTradeItemSelectionService extends BaseSelectionService<NonTradeListItem> {


    /**
     * Returns selected items of type Article.
     */
    getSelectedArticles(): Array<NonTradeListItem> {
        return this.getSelectedItems()
                   .filter( ( item: NonTradeListItem ) => item.type === NonTradeListItem.TypeEnum.Article );
    }


    /**
     * Returns selected items of type Assembly.
     */
    getSelectedAssemblies(): Array<NonTradeListItem> {
        return this.getSelectedItems()
                   .filter( ( item: NonTradeListItem ) => item.type === NonTradeListItem.TypeEnum.Assembly );
    }


    protected getId( item: NonTradeListItem ): string {

        if ( !item ) {
            throw new Error( '[NonTradeItemSelectionService] item must not be null!' );
        }

        return item.id;
    }
}
