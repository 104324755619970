/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

import { ClusterTile } from './clusterTile';
import { ConceptTile } from './conceptTile';


export interface ConceptOrClusterItem {
    type?: ConceptOrClusterItem.TypeEnum;

    concept?: ConceptTile;

    cluster?: ClusterTile;

}

export namespace ConceptOrClusterItem {
    'use strict';
    export enum TypeEnum {
        Article,
        Assembly,
        Concept,
        Cluster,
        Company,
        Manufacturer,
        Distributor,
        StoreOperator,
        Transport,
        CreativeAgency,
        EngineeringPartner,
        ProfessionalService,
        ITService,
        Location,
        DistributionCenter,
        Store,
        Factory,
        Office,
        ArticleForecast,
        ProjectForecast,
        CallOffOrder,
        ServiceOrder,
        ProductionOrder,
        FileContainer,
        PriceBaseLayout,
        User,
    }
    export namespace TypeEnum {
        export function toString( enumValue: TypeEnum ): string {
            return TypeEnum[ enumValue ];
        }
    }
}

