import { Component, HostBinding, Input, } from '@angular/core';

/**
 * A number that will be displayed with a circled background.
 * Is gray by default, but can be set to other colors with the `color`-attribute.
 *
 * ```html
 *     <rsp-badge-counter [content]="'1'"></rsp-badge-counter>
 *     <rsp-badge-counter [content]="'1'" [color|="'main-blue'"></rsp-badge-counter>
 *
 *     <!-- Influencing font-sizes / dimensions should work, too: -->
 *     <rsp-badge-counter [content]="'1'" class="set-font-size-to-3em"></rsp-badge-counter>
 *     <rsp-badge-counter [content]="'1'" class="set-width-to-100px"></rsp-badge-counter>
 * ```
 *
 * Please consider if providing a meaningful explanation of the number (via a tooltip) may make sense:
 * ```html
 *     <rsp-badge-counter [content]="'1'" title="1 filter active"></rsp-badge-counter>
 * ```
 *
 */
@Component( {
    selector:    'rsp-badge-counter',
    templateUrl: './badge-counter.component.html',
    styleUrls:   [
        './badge-counter.component.scss',
    ],
} )
export class BadgeCounterComponent {
    @Input() content: string;

    @Input()
    set color( color: string ) {
        this.isMainBlue = false;
        this.isMediumGray = false;

        if ( color === 'main-blue' ) {
            this.isMainBlue = true;
        }
        else if ( color === 'medium-gray' ) {
            this.isMediumGray = true;
        }
    }

    @HostBinding( 'class.is-badge-counter-main-blue'   ) isMainBlue: boolean = false;
    @HostBinding( 'class.is-badge-counter-medium-gray' ) isMediumGray: boolean = false;
}
