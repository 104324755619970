/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface SortingItem {
    name?: string;

    sortingOrder?: SortingItem.SortingOrderEnum;

}

export namespace SortingItem {
    'use strict';
    export enum SortingOrderEnum {
        Ascending,
        Descending,
    }
    export namespace SortingOrderEnum {
        export function toString( enumValue: SortingOrderEnum ): string {
            return SortingOrderEnum[ enumValue ];
        }
    }
}

