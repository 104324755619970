import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subscription, forkJoin, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { DialogService } from '../../../../../../core/overlay/dialog/dialog.service';
import { ArticleManagementConceptsService, ArticleManagementServiceModelsService } from '../../../../../../shared/api';
import { ConceptService } from '../../concept.service';
import { EditConceptServiceModelsAssignmentService } from './edit-concept-service-models-assignment.service';
import { ConceptServiceModelAssignmentItem, LevelGroupOptionItem } from './concept-service-model-assignment-item.model';
import { UpdateConceptServiceModels } from '../../../../../../shared/model';
import { NotificationService } from '../../../../../../core/overlay/notification/notification.service';
import { CollectionView } from '../../../../../../shared/ui/tables/collection-view';


/**
 * This dialog allows assign Service Models to Concept.
 */
@Component( {
    selector:    'rsp-edit-concept-service-models-assignment-dialog',
    templateUrl: './edit-concept-service-models-assignment-dialog.component.html',
    styleUrls:   [
        '../../../../../../shared/scss/05_module/standard-dialog.scss',
        './edit-concept-service-models-assignment-dialog.component.scss',
    ],
    providers:   [ EditConceptServiceModelsAssignmentService ],
} )
export class EditConceptServiceModelsAssignmentDialogComponent implements OnInit, OnDestroy {

    @ViewChild( 'dialogHeaderTemplate', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContentTemplate', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooterTemplate', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    collectionView: CollectionView<ConceptServiceModelAssignmentItem> = new CollectionView<ConceptServiceModelAssignmentItem>();

    isLoading: Subscription;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private conceptService: ConceptService,
        private notificationService: NotificationService,
        private serviceModelsApi: ArticleManagementServiceModelsService,
        private conceptsApi: ArticleManagementConceptsService,
        private editConceptServiceModelsAssignmentService: EditConceptServiceModelsAssignmentService,
    ) {
        // default sort column
        this.collectionView.sortBy( 'name', true );
    }

    ngOnInit(): void {

        this.loadServiceModels();

        this.dialogService.openDialog( {
            headlineTemplate: this.dialogHeaderTemplate,
            contentTemplate:  this.dialogContentTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
            cancelCallback:   () => this.closeDialog(),
        } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
        this.dialogService.closeDialog();
    }


    closeDialog(): void {
        this.dialogService.closeDialog();
        this.router.navigate( [ '../' ], { relativeTo: this.activatedRoute } );
    }

    onServiceLevelGroupSelected( option: LevelGroupOptionItem, item: ConceptServiceModelAssignmentItem ): void {
        item.assignedLevelGroupValue = option.id;
    }

    saveServiceModels(): void {

        const command: UpdateConceptServiceModels =
                  this.editConceptServiceModelsAssignmentService.convertToSubmitData( this.collectionView.items, this.conceptService.conceptId );

        this.isLoading =
            this.conceptsApi
                .conceptsUpdateServiceModels( command )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( () => {
                    this.notificationService.success( 'Service Models saved successfully.' );
                    this.closeDialog();
                    this.conceptService.getAndPublishSummary();
                } );
    }

    isInfoTooltipVisible( item: ConceptServiceModelAssignmentItem ): boolean {
        return item.serviceLevelItems
            && item.serviceLevelItems.length
            && item.assignedLevelGroupValue !== null;
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private loadServiceModels(): void {

        this.isLoading =
            forkJoin(
                this.serviceModelsApi.serviceModelsGetServiceModels( 1, 1000 ),
                this.conceptsApi.conceptsGetServiceLevels( this.conceptService.conceptId ),
            )
                .pipe( takeUntil( this.isDestroyed ) )
                .subscribe( ( results: any ) => {

                    // result[0] - ViewModelPagedListServiceModelItem
                    // result[1] - ViewModelListConceptServiceModelItem

                    this.collectionView
                        .setSourceItems( this.editConceptServiceModelsAssignmentService.createRowItems( results[ 0 ].data, results[ 1 ].data ) )
                        .refresh();
                } );
    }
}
