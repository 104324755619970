import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { AccordionComponent } from '../accordion.component';


/**
 * Represents one group inside an accordion.
 */
@Component( {
    selector:    'rsp-accordion-group',
    templateUrl: './accordion-group.component.html',
    styleUrls:   [
        './accordion-group.component.scss',
    ],
    animations:  [
        trigger( 'expandCollapse', [
            state( 'hidden', style( { height: '0' } ) ),
            state( 'visible', style( { height: '*' } ) ),
            transition( 'visible <=> hidden', animate( '200ms ease-in' ) ),
        ] ),
    ],
} )
export class AccordionGroupComponent implements OnInit, OnDestroy {

    @Input()
    heading: string;

    /**
     * Disables accordion group.
     */
    @Input()
    isDisabled: boolean;

    isVisible: boolean;

    /**
     * Specifies if accordion group is open or closed.
     */
    @Input()
    get isOpen(): boolean {
        return this._isOpen;
    }

    set isOpen( value: boolean ) {
        this.setIsOpen( value );
    }

    /**
     * Emits boolean value for current isOpen state.
     */
    @Output() isOpened: EventEmitter<boolean> = new EventEmitter();

    isAnimating: boolean;

    private _isOpen: boolean;


    constructor( private accordion: AccordionComponent ) {
    }

    ngOnInit(): void {
        this.accordion.addGroup( this );
    }

    ngOnDestroy(): void {
        this.accordion.removeGroup( this );
    }

    toggleOpen(): void {
        if ( !this.isDisabled ) {
            if ( !this.isOpen ) {
                this.isVisible = true;
            }

            this.isOpen = !this.isOpen;
            this.isOpened.emit( this.isOpen );
        }
    }

    animationStarted(): void {
        this.isAnimating = true;
    }

    animationEnded(): void {
        this.isAnimating = false;

        if ( !this.isOpen ) {
            this.isVisible = false;
        }
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setIsOpen( value: boolean ): void {
        this._isOpen = value;

        if ( value ) {
            this.accordion.closeOtherGroups( this );
        }
    }
}
