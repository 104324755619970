import { Injectable, } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';

import { ClusterSummary } from '../../../../shared/model/clusterSummary';
import { ArticleManagementClustersService } from '../../../../shared/api';
import { ViewModelItemClusterSummary } from '../../../../shared/model/viewModelItemClusterSummary';
import { map } from 'rxjs/operators';


@Injectable()
export class ClusterService {

    summary$: Observable<ClusterSummary>;


    clusterId: string;


    private summary: Subject<ClusterSummary> = new Subject();


    constructor(
        private clusterApi: ArticleManagementClustersService,
    ) {
        this.summary$ = this.summary.asObservable();
    }

    /**
     * Sets current Cluster ID. This ID is used for all API calls.
     */
    setClusterId( clusterId: string ): void {
        this.clusterId = clusterId;
    }


    /**
     * Sets new Summary object and publishes #summary$ observable.
     */
    setSummary( summary: ClusterSummary ): void {
        this.summary.next( summary );
    }


    getSummary(): Observable<ClusterSummary> {

        if ( !this.clusterId ) {
            throw new Error( '#clusterId must be set!' );
        }

        return this.clusterApi
                   .clustersGetSummary( this.clusterId )
                   .pipe(
                       map( ( result: ViewModelItemClusterSummary ) => {
                           return result.data;
                       } ),
                   );
    }


    /**
     * Gets Summary for current Cluster. It also publishes the #summary$ observable.
     */
    getAndPublishSummary(): void {

        if ( !this.clusterId ) {
            throw new Error( '#clusterId must be set!' );
        }

        this.getSummary()
            .subscribe( ( result: ClusterSummary ) => {
                this.setClusterId( result.tile.id );
                this.setSummary( result );
            } );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

}
