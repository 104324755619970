import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { NonTradeItemSelectionService } from '../../../../shared/non-trade-item-selection.service';
import { ArticleManagementAssembliesService } from '../../../../../../../shared/api/index';
import { Subscription } from 'rxjs';
import { NonTradeListItem } from '../../../../../../../shared/model/nonTradeListItem';
import { ReplyGuid } from '../../../../../../../shared/model/replyGuid';
import { BatchProcessStepStatus } from '../../../../../../../shared/model/batchProcessStepStatus';
import { NotificationService } from '../../../../../../../core/overlay/notification/notification.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { EntityTypeEnum } from '../../../../../../../shared/model/enum/entity-type.enum';

@Component( {
    selector:    'rsp-set-assembly-status-action',
    templateUrl: './set-assembly-status-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        './set-assembly-status-dialog.component.scss',
    ],
} )
export class SetAssemblyStatusDialogComponent implements OnInit, OnDestroy {

    @ViewChild( 'headline', { read: TemplateRef, static: true } ) headlineTemplate: TemplateRef<any>;
    @ViewChild( 'content', { read: TemplateRef, static: true } ) contentTemplate: TemplateRef<any>;
    @ViewChild( 'footer', { read: TemplateRef, static: true } ) footerTemplate: TemplateRef<any>;

    form: UntypedFormGroup;
    statusEnumType: any = EntityTypeEnum.NonTradeItem;
    assemblyType: NonTradeListItem.TypeEnum = NonTradeListItem.TypeEnum.Assembly;

    hasAssembliesSelected: boolean = false;
    isProcessing: boolean          = false;

    private subscription: Subscription = new Subscription();

    constructor(
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private assembliesApi: ArticleManagementAssembliesService,
        private dialogService: DialogService,
        private notificationService: NotificationService,
    ) {
    }

    ngOnInit(): void {
        this.subscription.add(
            this.nonTradeItemSelectionService
                .selectedItems$
                .pipe(
                    map( ( listItems: Array<NonTradeListItem> ) => {
                        return listItems.filter( ( item: NonTradeListItem ) => item.type === NonTradeListItem.TypeEnum.Assembly ).length > 0;
                    } ),
                    distinctUntilChanged(),
                )
                .subscribe( ( hasAssemblies: boolean ) => {
                    this.hasAssembliesSelected = hasAssemblies;
                } ),
        );
    }

    setStatus( status: string ): void {
        this.form.get( 'status' ).setValue( status );
    }

    doAction(): void {
        const assemblyIds: string[] = this.nonTradeItemSelectionService.getSelectedAssemblies().map( ( item: NonTradeListItem ) => {
            return item.id;
        } );
        this.subscription.add(
            this.assembliesApi.assembliesUpdatePublishedStatusForAssemblies(
                this.form.get( 'status' ).value === 'Publish' ? true : false,
                assemblyIds,
            ).subscribe( ( result: ReplyGuid ) => {
                this.notificationService.success(
                    'Process started.',
                    'Set Assembly Status',
                );
                this.close();
            } ),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    open(): void {
        this.form = new UntypedFormGroup( {
            status: new UntypedFormControl( null, Validators.required ),
        } );

        this.dialogService.openDialog( {
            contentTemplate:  this.contentTemplate,
            headlineTemplate: this.headlineTemplate,
            footerTemplate:   this.footerTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }

    private sendNotification( event: BatchProcessStepStatus ): void {
        // create component via notification service...
        // this.socketService.socketNotifications$.next( {
        //     name:     'setEngineeringPartner',
        //     messages: [
        //         event.numberOfItemsFinishedProcessing + ' of ' + event.numberOfItemsToBeProcessed + ' processed',
        //     ],
        // } );
    }

}
