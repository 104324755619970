import { Component, Input, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, } from '@angular/router';
import { merge, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil } from 'rxjs/operators';

import { NonTradeItemService, } from '../shared/non-trade-item.service';
import { Tab, } from '../../../../shared/ui/navigation/vertical-tabs/tab';
import { CurrentUserService, } from '../../../../core/current-user.service';
import { QueryParamService, } from '../../../../shared/utils/url/query-param.service';
import { ArticleSummary, } from '../../../../shared/model/articleSummary';
import { AssemblySummary, } from '../../../../shared/model/assemblySummary';
import { EntityTypeEnum, } from '../../../../shared/model/enum/entity-type.enum';
import { BreadcrumbService, } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb.service';
import { BreadcrumbPart, } from '../../../../shared/ui/navigation/breadcrumb/breadcrumb-part';
import { NonTradeItemType, } from '../shared/non-trade-item-type.model';
import { NonTradeItemSelectionService } from '../shared/non-trade-item-selection.service';
import { NonTradeListItem, } from '../../../../shared/model/nonTradeListItem';
import { NonTradeItemPrevNextNavigationService } from './non-trade-item-prev-next-navigation/non-trade-item-prev-next-navigation.service';
import { CurrentNonTradeItem } from '../shared/current-non-trade-item.model';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { CurrentUserContext } from '../../../../shared/model';


/**
 * Represents data provided by the router.
 */
export interface NonTradeItemRoutingData {
    itemType: NonTradeItemType;
    isMultiAction: boolean;
    articleSummary: ArticleSummary;
    assemblySummary: AssemblySummary;
}


interface Summary {
    number: string;
    name: string;
    status: ArticleSummary.StatusEnum | AssemblySummary.StatusEnum;
    packagingUnit?: number;
    previewImageId: string;
}


@Component( {
    selector:    'rsp-non-trade-item-details-page',
    providers:   [ BreadcrumbService, ],
    templateUrl: './non-trade-item-details-page.component.html',
    styleUrls:   [
        '../../../../shared/scss/06_state/is-visible-for-screenreaders-only.scss',
        '../non-trade-item-list/non-trade-item-tile-list/non-trade-item-tile/non-trade-item-tile.component.scss',
        './non-trade-item-details-page.component.scss',
    ],
} )
export class NonTradeItemDetailsPageComponent implements OnInit, OnDestroy {

    @Input()
    articleSummary: ArticleSummary;

    @Input()
    assemblySummary: AssemblySummary;

    countryCode: string;
    distributionChannel: string;
    summary: Summary;
    tabs: Array<Tab> = [];
    itemType: NonTradeItemType;
    itemTypeString: string;
    type: string;
    isMultiAction: boolean;

    // make enum type be visible in template
    NonTradeItemType: any = NonTradeItemType;


    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.NonTradeItem;

    isSelected: boolean = false;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private nonTradeItemService: NonTradeItemService,
        private currentUserService: CurrentUserService,
        private queryParamService: QueryParamService,
        private breadcrumbService: BreadcrumbService,
        private selectionService: NonTradeItemSelectionService,
        private toolbarService: SecondaryToolbarService,
        private prevNextNavigationService: NonTradeItemPrevNextNavigationService,
    ) {
        // configure toolbar
        // NOTE: previously it was in ngOnInit, but it throws a ExpressionChangedAfterItHasBeenCheckedError exception.
        // We are not sure why it occurs ... maybe something with named outlet (where toolbar is placed) and change detection.
        // This issue occurs in angular > 4.2.5.
        // https://github.com/angular/angular/issues/17572
        this.toolbarService.hideViewSwitcher();
        this.toolbarService.hideStoreInfo();
        this.toolbarService.hideSortBy();
        this.toolbarService.showPrevNextNavigation();

        this.configureBreadcrumb();
    }

    ngOnInit(): void {

        this.distributionChannel = this.currentUserService.distributionChannelContext.value;

        // There are two entry points:
        //    - route resolver                  - first page load, prev/next navigation
        //    - pblService.currentNonTradeItem$ - page refresh after edit
        merge( this.activatedRoute.data, this.nonTradeItemService.currentNonTradeItem$ )
            .pipe(
                map( ( data: NonTradeItemRoutingData | CurrentNonTradeItem ) => this.fetchCurrentNonTradeItem( data ) ),
                distinctUntilChanged(),  // prev/next navigation triggers both events. Take only one.
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( currentNonTradeItem: CurrentNonTradeItem ) => {

                if ( currentNonTradeItem ) {

                    this.isMultiAction  = currentNonTradeItem.isMultiAction;
                    this.itemType       = currentNonTradeItem.type;
                    this.itemTypeString = currentNonTradeItem.typeString;


                    if ( currentNonTradeItem.isArticle() ) {

                        // article
                        this.articleSummary = currentNonTradeItem.articleSummary;

                        this.breadcrumbService.replacePart( { label: this.articleSummary.name }, 1 );

                        this.createArticleSummary();

                        if ( !this.isMultiAction ) {
                            this.createArticleTabs();
                        }

                        this.nonTradeItemService.setCurrentNonTradeItem( currentNonTradeItem );   // updates tab content during prev/next navigation

                        this.prevNextNavigationService.setCurrentItem( this.articleSummary.id );
                    }
                    else {
                        // assembly
                        this.assemblySummary = currentNonTradeItem.assemblySummary;

                        this.breadcrumbService.replacePart(
                            {
                                label: this.assemblySummary.number,
                                link:  [ '/assembly/' + this.assemblySummary.number, ],
                            },
                            1,
                        );

                        this.createAssemblySummary();

                        if ( !this.isMultiAction ) {
                            this.createAssemblyTabs();
                        }

                        this.nonTradeItemService.setCurrentNonTradeItem( currentNonTradeItem );   // updates tab content during prev/next navigation

                        this.prevNextNavigationService.setCurrentItem( this.assemblySummary.id );
                    }
                }
            } );


        // // take data provided by router's resolve
        // this.route
        //     .data
        //     .takeWhile( () => this.alive )
        //     // .distinctUntilChanged()
        //     // .debounceTime( 200 )
        //     .subscribe(
        //         ( data: {
        //             itemType: NonTradeItemType,
        //             isMultiAction: boolean,
        //             articleSummary: ArticleSummary,
        //             assemblySummary: AssemblySummary,
        //         } ) => {
        //
        //             this.isMultiAction  = data.isMultiAction;
        //             this.itemType       = data.itemType;
        //             this.itemTypeString = this.nonTradeItemService.getItemTypeAsString( data.itemType );
        //
        //
        //             if ( data.itemType === NonTradeItemType.Article ) {
        //
        //                 this.articleSummary = data.articleSummary;
        //
        //                 this.nonTradeItemService.setCurrentNonTradeItem(
        //                     this.articleSummary.id,
        //                     data.itemType,
        //                     this.articleSummary.name,
        //                     this.articleSummary.number,
        //                 );
        //
        //                 this.configureArticleBreadcrumb();
        //
        //                 this.createArticleSummary();
        //
        //                 if ( !this.isMultiAction ) {
        //                     this.createArticleTabs();
        //                 }
        //
        //                 this.prevNextNavigationService.setCurrentItem( this.articleSummary.id );
        //             }
        //             else if ( data.itemType === NonTradeItemType.Assembly ) {
        //
        //                 this.assemblySummary = data.assemblySummary;
        //
        //                 this.nonTradeItemService.setCurrentNonTradeItem(
        //                     this.assemblySummary.id,
        //                     data.itemType,
        //                     this.assemblySummary.name,
        //                     this.assemblySummary.number,
        //                 );
        //
        //                 this.configureAssemblyBreadcrumb();
        //
        //                 this.createAssemblySummary();
        //
        //                 if ( !this.isMultiAction ) {
        //                     this.createAssemblyTabs();
        //                 }
        //
        //                 this.prevNextNavigationService.setCurrentItem( this.assemblySummary.id );
        //             }
        //             else {
        //                 throw new Error( 'Unsupported itemType: \'' + data.itemType + '\'.' );
        //             }
        //         } );


        // selection service expects NonTradeListItem (TODO change service item type?)
        this.selectionService
            .selectedItems$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                if ( this.nonTradeItemService.getCurrentNonTradeItem() ) {
                    this.isSelected = this.selectionService.isSelected( { id: this.nonTradeItemService.getCurrentId() } );
                }
            } );
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
        this.nonTradeItemService.setCurrentNonTradeItem( null );
        this.breadcrumbService.disableAutoRefreshing();
    }

    toggleSelection(): void {
        // selection service expects NonTradeListItem, so a conversion is done (TODO change service item type?)
        this.selectionService.toggleSelection( {
            id:             this.nonTradeItemService.getCurrentId(),
            number:         this.summary.number,
            name:           this.summary.name,
            status:         this.summary.status,
            previewImageId: this.summary.previewImageId,
            isReadyToOrder: this.itemType === NonTradeItemType.Article ? this.articleSummary.isReadyToOrder : this.assemblySummary.isReadyToOrder,
            type:           this.itemType === NonTradeItemType.Article ? NonTradeListItem.TypeEnum.Article : NonTradeListItem.TypeEnum.Assembly,
        } );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private configureBreadcrumb(): void {
        const config: { [ key: string ]: BreadcrumbPart } = {};
        config[ 'details' ]                               = { label: 'Details', };
        config[ 'graphics' ]                              = { label: 'Graphics', };
        config[ 'files' ]                                 = { label: 'Files', };
        config[ 'assemblies' ]                            = { label: 'Assemblies', };
        config[ 'styles-and-artworks' ]                   = { label: 'Styles & Artworks', };
        config[ 'spm' ]                                   = { label: 'SPM', };
        config[ 'history' ]                               = { label: 'History', };
        config[ 'components' ]                            = { label: 'Components', };
        config[ 'components/edit/multi' ]                 = { label: 'Components Multi Edit', };

        this.breadcrumbService.addPart( {
            label:      'Articles & Assemblies',
            link:       [ '/articles-and-assemblies', ],
            linkParams: this.queryParamService.getQueryParamsFor( '/articles-and-assemblies' ),
        } );

        this.breadcrumbService.addPart( { label: 'Item' } ); // will be replaced by summary data

        this.breadcrumbService.enableAutoRefreshing( config, 3 );
    }

    private createArticleTabs(): void {

        if ( !this.articleSummary ) {
            throw new Error( 'ArticleSummary is null' );
        }

        this.tabs = [];

        if ( Tab.shouldTabBeVisible( this.articleSummary.detailNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'details', ],
                label: 'Details',
            } );
        }

        if ( Tab.shouldTabBeVisible( this.articleSummary.graphicsNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'graphics', ],
                label: 'Graphics',
                tag:   Tab.createTagFromNumber( this.articleSummary.graphicsNavigationItem ),
            } );
        }
        if ( Tab.shouldTabBeVisible( this.articleSummary.filesNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'files', ],
                label: 'Files',
                tag:   Tab.createTagFromNumber( this.articleSummary.filesNavigationItem ),
            } );
        }
        if ( Tab.shouldTabBeVisible( this.articleSummary.assemblyUsageNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'assemblies', ],
                label: 'Assemblies',
                tag:   Tab.createTagFromNumber( this.articleSummary.assemblyUsageNavigationItem ),
            } );
        }
        if ( Tab.shouldTabBeVisible( this.articleSummary.stylesAndArtworksNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'styles-and-artworks', ],
                label: 'Styles & Artworks',
                tag:   Tab.createTagFromNumber( this.articleSummary.stylesAndArtworksNavigationItem ),
            } );
        }
        if ( Tab.shouldTabBeVisible( this.articleSummary.spmNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'spm', ],
                label: 'SPM',
                title: 'Single Product Master',
            } );
        }

    }

    private createArticleSummary(): void {

        if ( !this.articleSummary ) {
            throw new Error( 'ArticleSummary is null' );
        }

        this.summary = {
            name:           this.articleSummary.name,
            number:         this.articleSummary.number,
            packagingUnit:  this.articleSummary.packagingUnit,
            status:         this.articleSummary.status,
            previewImageId: this.articleSummary.previewImageId,
        };
    }

    private createAssemblyTabs(): void {

        if ( !this.assemblySummary ) {
            throw new Error( 'AssemblySummary is null' );
        }

        this.tabs = [];

        if ( Tab.shouldTabBeVisible( this.assemblySummary.detailNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'details', ],
                label: 'Details',
            } );
        }
        if ( Tab.shouldTabBeVisible( this.assemblySummary.componentsNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'components', ],
                label: 'Components',
                tag:   Tab.createTagFromNumber( this.assemblySummary.componentsNavigationItem ),
            } );
        }
        if ( Tab.shouldTabBeVisible( this.assemblySummary.graphicsNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'graphics', ],
                label: 'Graphics',
                tag:   Tab.createTagFromNumber( this.assemblySummary.graphicsNavigationItem ),
            } );
        }
        if ( Tab.shouldTabBeVisible( this.assemblySummary.filesNavigationItem ) ) {
            this.tabs.push( {
                link:  [ 'files', ],
                label: 'Files',
                tag:   Tab.createTagFromNumber( this.assemblySummary.filesNavigationItem ),
            } );
        }
    }

    private createAssemblySummary(): void {

        if ( !this.assemblySummary ) {
            throw new Error( 'AssemblySummary is null' );
        }

        this.summary = {
            name:           this.assemblySummary.name,
            number:         this.assemblySummary.number,
            status:         this.assemblySummary.status,
            previewImageId: this.assemblySummary.previewImageId,
        };
    }

    private fetchCurrentNonTradeItem( data: NonTradeItemRoutingData | CurrentNonTradeItem ): CurrentNonTradeItem {

        if ( !data ) {
            return null;
        }

        if ( data.hasOwnProperty( 'itemType' ) ) {
            // data comes from resolver and is { itemType: ..., ... }
            return CurrentNonTradeItem.createFromRoutingData( <NonTradeItemRoutingData> data );
        }

        // data is CurrentNonTradeItem
        return <CurrentNonTradeItem> data;
    }
}
