<!-- Button -->
<button
    #button
    *ngIf="!routerLink && (type && type !== 'download-button')"
    [class]="className"
    [disabled]="disabled"
    [type]="type ? type : 'button'"
    [tabindex]="tabIndex"
    (click)="onClicked( $event )"
    (mouseup)="onMouseUp()"
    (focus)="setHasFocus( true )"
    (blur)="setHasFocus( false )"
>
    <ng-template [ngTemplateOutlet]="buttonLabelTemplateRef"></ng-template>
</button>

<!-- Link within the application (routerLink) -->
<a
    #button
    *ngIf="routerLink"
    [class]="className"
    [tabindex]="tabIndex"
    [routerLink]="routerLink"
    (click)="onClicked( $event )"
    (mouseup)="onMouseUp()"
    (focus)="setHasFocus( true )"
    (blur)="setHasFocus( false )"
>
    <ng-template [ngTemplateOutlet]="buttonLabelTemplateRef"></ng-template>
</a>

<!-- Link outside the application (like download-link) -->
<a
    #button
    *ngIf="href"
    [class]="className"
    [tabindex]="tabIndex"
    (click)="onClicked( $event )"
    (mouseup)="onMouseUp()"
    (focus)="setHasFocus( true )"
    (blur)="setHasFocus( false )"
    href="{{ href }}"
>
    <ng-template [ngTemplateOutlet]="buttonLabelTemplateRef"></ng-template>
</a>

<!-- <ng-content> has to be in a <template>, because it can't be placed multiple times in an (Angular-)template. -->
<ng-template #buttonLabelTemplateRef>
    <rsp-spinner *ngIf="isLoading" [color]="submodule !== 'primary-light' ? 'white' : 'main-blue'" class="button--spinner"></rsp-spinner>
    <ng-content *ngIf="!isLoading || ( isLoading && !isLoadingText )"></ng-content>
    <span *ngIf="isLoading && isLoadingText !== ''">{{ isLoadingText }}</span>
</ng-template>
