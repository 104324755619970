import { Component } from '@angular/core';

/**
 * Displays unit: m2
 */
@Component( {
    selector:    'rsp-unit-square-meter',
    templateUrl: './unit-square-meter.component.html',
    styleUrls:   [
        './unit-square-meter.component.scss',
    ],
} )
export class UnitSquareMeterComponent {
}
