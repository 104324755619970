import { Injectable, } from '@angular/core';
import { Observable ,  Subject ,  forkJoin, of } from 'rxjs';

import { CurrentNonTradeItem, } from './current-non-trade-item.model';
import { NonTradeItemType, } from './non-trade-item-type.model';
import { ArticleManagementAssembliesService } from '../../../../shared/api/articleManagementAssemblies.service';
import { ArticleManagementArticlesService } from '../../../../shared/api/articleManagementArticles.service';
import { ViewModelItemArticleSummary } from '../../../../shared/model/viewModelItemArticleSummary';
import { ViewModelItemAssemblySummary } from '../../../../shared/model/viewModelItemAssemblySummary';
import { ArticleSummary } from '../../../../shared/model/articleSummary';
import { AssemblySummary } from '../../../../shared/model/assemblySummary';
import { ViewModelItemArticleDetailArticleDetailChangeability } from '../../../../shared/model/viewModelItemArticleDetailArticleDetailChangeability';
import { ViewModelItemAssemblyDetailAssemblyDetailChangeability } from '../../../../shared/model/viewModelItemAssemblyDetailAssemblyDetailChangeability';
import { ArticleManagementCapacityTypesService } from '../../../../shared/api/articleManagementCapacityTypes.service';
import { map, switchMap } from 'rxjs/operators';


@Injectable()
export class NonTradeItemService {

    currentNonTradeItem$: Observable<CurrentNonTradeItem> = new Observable();

    /**
     * Used by non-trade-item details area to specify currently displayed item.
     */
    private currentNonTradeItemSubject: Subject<CurrentNonTradeItem> = new Subject<CurrentNonTradeItem>();
    private currentNonTradeItem: CurrentNonTradeItem;


    constructor(
        private articlesApi: ArticleManagementArticlesService,
        private assembliesApi: ArticleManagementAssembliesService,
        private capacityTypesApi: ArticleManagementCapacityTypesService,
    ) {
        this.currentNonTradeItem$ = this.currentNonTradeItemSubject.asObservable();
    }


    /**
     * Sets new CurrentNonTradeItem object and publishes #currentNonTradeItem$ observable.
     */
    setCurrentNonTradeItem( item: CurrentNonTradeItem ): void {
        this.currentNonTradeItem = item;
        this.currentNonTradeItemSubject.next( item );
    }


    getCurrentNonTradeItem(): CurrentNonTradeItem {
        return this.currentNonTradeItem;
    }


    /**
     * Current article or assembly id.
     */
    getCurrentId(): string {

        if ( this.currentNonTradeItem ) {
            return this.currentNonTradeItem.id;
        }

        return null;
    }


    /**
     * Returns string representation of NonTradeItemType enum.
     */
    getItemTypeAsString( itemType: NonTradeItemType ): string {

        if ( itemType === NonTradeItemType.Article ) {
            return 'Article';
        }
        if ( itemType === NonTradeItemType.Assembly ) {
            return 'Assembly';
        }
    }


    getArticleSummaryByNumber( articleNumber: string ): Observable<ArticleSummary> {

        return this.articlesApi
                   .articlesGetArticleIdByNumber( articleNumber )
                   .pipe(
                       switchMap( ( id: string ) => {

                           if ( !id ) {
                               return of( null );
                           }

                           return this.articlesApi
                                      .articlesGetArticleSummary( id )
                                      .pipe(
                                          map( ( result: ViewModelItemArticleSummary ) => {
                                              return result.data;
                                          } ),
                                      );
                       } ),
                   );
    }


    getAssemblySummaryByNumber( assemblyNumber: string ): Observable<AssemblySummary> {

        return this.assembliesApi
                   .assembliesGetArticleIdByNumber( assemblyNumber )
                   .pipe(
                       switchMap( ( id: string ) => {

                           if ( !id ) {
                               return of( null );
                           }

                           return this.assembliesApi
                                      .assembliesGetAssemblySummary( id )
                                      .pipe(
                                          map( ( result: ViewModelItemAssemblySummary ) => {
                                              return result.data;
                                          } ),
                                      );
                       } ),
                   );

    }


    getAndPublishCurrentNonTradeItem(): void {

        if ( !this.currentNonTradeItem ) {
            throw new Error( '#currentNonTradeItem must be set!' );
        }

        if ( this.currentNonTradeItem.isArticle() ) {

            this.articlesApi
                .articlesGetArticleSummary( this.currentNonTradeItem.id )
                .subscribe( ( result: ViewModelItemArticleSummary ) => {
                    this.setCurrentNonTradeItem( CurrentNonTradeItem.createFromArticleSummary( result.data ) );
                } );
        }

        if ( this.currentNonTradeItem.isAssembly() ) {

            this.assembliesApi
                .assembliesGetAssemblySummary( this.currentNonTradeItem.id )
                .subscribe( ( result: ViewModelItemAssemblySummary ) => {
                    this.setCurrentNonTradeItem( CurrentNonTradeItem.createFromAssemblySummary( result.data ) );
                } );
        }
    }


    getDetail( isEditMode: boolean ): Observable<Array<any>> {

        const id: string = this.currentNonTradeItem.id;

        const observables: Array<Observable<any>> = [];

        // details
        observables.push( this.getDetailsApi( id ) );

        if ( isEditMode ) {

            // for article in edit case we need also:
            if ( this.currentNonTradeItem.type === NonTradeItemType.Article ) {

                // brand logos
                observables.push( this.articlesApi.articlesGetBrandLogoOptionsByArticleId( id ) );

                // capacity types
                observables.push( this.capacityTypesApi.capacityTypesGetCapacityTypeOptions() );
            }
        }

        // [0] - article/assembly details
        // [1] - brand logos
        // [2] - capacityInfoOptions
        return forkJoin( observables );
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private getDetailsApi( id: string ):
        Observable<ViewModelItemArticleDetailArticleDetailChangeability | ViewModelItemAssemblyDetailAssemblyDetailChangeability> {

        return this.currentNonTradeItem.isArticle()
            ? this.articlesApi.articlesGetArticleDetail( id )
            : this.assembliesApi.assembliesGetAssemblyDetail( id );
    }

    // private getArticleSummary(): Observable<ArticleSummary> {
    //
    // }

}
