import { FileVersionItem } from '../../../model';

export class FileVersionCollectionViewItem {

    file: FileVersionItem;

    get name(): string {
        return this.file.name;
    }

    get versionNumber(): number {
        return this.file.versionNumber;
    }

    get uploadedOn(): Date {
        return this.file.uploadedOn;
    }

    constructor( fileVersion: FileVersionItem ) {
        this.file = fileVersion;
    }

}
