<button
    *ngIf="!useButton"
    #removeAnchor
    [title]="title"
    (click)="onClick( $event )"
    type="button"
    class="edit-icons--remove remove"
></button>
<span
    *ngIf="useButton"
    #removeAnchor
>
    <rsp-button
        [title]="title"
        [submodule]="'tertiary'"
        (click)="onClick( $event )"
    >{{title}}</rsp-button>
</span>
