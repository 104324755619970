<rsp-card class="card-keywords">
    <rsp-card-headline>Keywords</rsp-card-headline>
    <rsp-card-content>
        <rsp-tags
            *ngIf="!isEditMode"
            [tags]="getKeywordsAsTags()"
            [showEmptyText]="true"
        ></rsp-tags>

        <ng-container *ngIf="isEditMode">
            <div
                *ngIf="isSPMGroupAndTypeEditable"
                class="card-keywords--section"
            >
                <rsp-edit-spm-group-and-type
                    [myFormGroup]="myFormGroup"
                    [spmGroup]="spmGroup"
                    [spmType]="spmType"
                ></rsp-edit-spm-group-and-type>
            </div>

            <div class="card-keywords--section">
                <label
                    *ngIf="isSPMGroupAndTypeEditable"
                    [for]="keywordFieldHtmlId"
                    class="card-keywords--section-headline"
                >
                    Additional Keywords:
                </label>

                <div class="card-keywords--section-content">
                    <div>
                        <rsp-tags
                            *ngIf="!isSPMGroupAndTypeEditable"
                            [tags]="getSPMGroupAndTypeAsTags()"
                            [showEmptyText]="false"
                        ></rsp-tags>
                    </div>

                    <rsp-edit-keywords
                        [myFormControl]="myFormGroup.get('keywordIds')"
                        [keywords]="keywords"
                        [keywordsGroupName]="'Articles & Assemblies'"
                        [inputFieldHtmlId]="keywordFieldHtmlId"
                    ></rsp-edit-keywords>
                </div>
            </div>
        </ng-container>
    </rsp-card-content>
</rsp-card>
