import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { ConfirmDialogService } from '../../../core/overlay/confirm-dialog/confirm-dialog.service';
import { FormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component( {
    selector:    'rsp-remove-with-reason',
    templateUrl: './remove-with-reason.component.html',
    styleUrls:   [
        '../../scss/05_module/edit-icons.scss',
        './remove-with-reason.component.scss',
    ],
} )
export class RemoveWithReasonComponent {
    @Input() title: string = 'remove';
    @Input() text: string  = 'Do you really want to remove this item?';
    @Input() confirmButtonCaption: string;
    @Input() useButton: boolean;

    @Output() confirmClicked: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild('removeAnchor') removeAnchor: ElementRef;

    form: UntypedFormGroup;

    constructor(
        private confirmDialogService: ConfirmDialogService,
    ) {}

    onClick( event: Event ): void {
        event.stopPropagation();

        this.confirmDialogService.openConfirmDialog( {
            anchorElement:        this.removeAnchor.nativeElement,
            text:                 this.text,
            confirmButtonCaption: this.confirmButtonCaption,
            withReason: true,
            confirmCallback:      (reason: string) => this.confirmClicked.emit( reason ),
        } );
    }
}
