import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { UserNameService } from '../user-name/user-name.service';
import { UserInfo } from '../../model/index';
import { CurrentUserContext, UserDetails } from '../../model';
import { RegistrationUserData } from '../../../features/registration/registration-wizard/registration-wizard.component';

/**
 * Displays either a user profile image or the user initials in a circle.
 *
 * Box aspect ratio is 1:1, scale with defining a font-size:
 * ```html
 *     <rsp-user-profile-image
 *         [user]="user"
 *         class="set-font-size-to-2em"
 *     </rsp-user-profile-image>
 * ```
 */
@Component( {
    selector:    'rsp-user-profile-image',
    templateUrl: './user-profile-image.component.html',
    styleUrls:   [ './user-profile-image.component.scss' ],
} )
export class UserProfileImageComponent implements OnChanges {
    @Input() user: CurrentUserContext | RegistrationUserData | UserDetails;

    /**
     * When user has no profile image, the initials with a tooltip containing the full name is shown.
     */
    @Input() showTooltip: boolean = true;

    @Input() showClearButton: boolean = false;

    @Output() cleared: EventEmitter<void> = new EventEmitter();

    userName:     string | null;
    userInitials: string | null;

    constructor(
        private userNameService: UserNameService,
    ) {
    }

    ngOnChanges(): void {
        if ( this.user ) {
            // this.user.profileImageFileContainerId = '23be9cb6-109c-234e-9e0a-34e06f14f451';
            const userInfo: UserInfo = {
                firstName:  this.user.firstName,
                familyName: this.user.familyName,
            };

            this.userName     = this.userNameService.format( userInfo );
            this.userInitials = this.userNameService.getInitials( userInfo );
        }
        else {
            this.userName     = null;
            this.userInitials = null;
        }
    }

    clear(): void {
        this.user.profilePreviewImageId = null;

        this.cleared.emit();
    }
}
