<div class="secondary-toolbar">

    <div class="secondary-toolbar--left-area">

        <ng-container *ngIf="isSelectionAvailable | async">
            <rsp-secondary-toolbar-button
                [icon]="'selection'"
                [badgeValue]="( selectedItems | async )?.length"
                (clearButtonClick)="clearSelection()"
            >
            </rsp-secondary-toolbar-button>

            <div class="secondary-toolbar--separator"></div>
        </ng-container>

        <span [hidden]="!actions.hasAnyAction">
            <rsp-secondary-toolbar-slidein-button
                class="non-trade-items-toolbar--actions-button"
                [label]="'Actions'"
                [slideInSize]="{ width: '350px' }"
            >
                <rsp-non-trade-item-actions-list #actions></rsp-non-trade-item-actions-list>
            </rsp-secondary-toolbar-slidein-button>
        </span>

        <ng-container *ngIf="isSortByVisible | async">
            <span [class]="sortingDirection === 'Descending' ? 'secondary-toolbar--sort-down' : 'secondary-toolbar--sort-up'" [title]="sortingDirection"></span>

            <div class="secondary-toolbar--separator"></div>

            <rsp-secondary-toolbar-slidein-button
                [label]="sortingLabel"
                [title]="sortingTitle"
                [slideInSize]="{ width: '250px' }"
            >
                <rsp-sort-by
                    [availableSorting]="availableSorting | async"
                    [activeSorting]="activeSorting"
                    (selectedSorting)="setSorting( $event )"
                ></rsp-sort-by>
            </rsp-secondary-toolbar-slidein-button>
        </ng-container>

    </div>

    <div class="secondary-toolbar--right-area">

        <!-- ViewSwitcher - visible only on the list page -->
        <rsp-view-switcher
            *ngIf="isViewSwitcherVisible | async"
            class="secondary-toolbar--right-area-item"
        ></rsp-view-switcher>

        <!-- StoreInfo - visible only on the list page -->
        <rsp-store-info
            *ngIf="isStoreInfoVisible | async"
            class="secondary-toolbar--right-area-item"
            [storeInfo]="storeInfo | async"
        ></rsp-store-info>

        <!-- PrevNextNavigation - visible only on the details page  -->
        <rsp-non-trade-item-prev-next-navigation
            *ngIf="isPrevNextNavigationVisible | async"
            class="secondary-toolbar--right-area-item"
        ></rsp-non-trade-item-prev-next-navigation>
    </div>

</div>
