import { Injectable } from '@angular/core';

@Injectable()
export class TransformService {

    transformWithDate<T>( response: string ): T {
        const utcDateRegex: RegExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:Z|[+-]\d{2}:\d{2})$/;

        const responseObject: T = JSON.parse(
            response,
            ( key: string, value: any ) => {
                if ( typeof value === 'string' && utcDateRegex.test( value ) ) {
                    return new Date( value );
                }
                else {
                    return value;
                }
            },
        );

        return responseObject;
    }

}
