import { Component, Input, OnInit, } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { DisplayEditBaseComponent } from '../shared/display-edit-base.component';
import { ValidationMessagesService } from '../../validation/validation-messages.service';
import { MessageType } from '../../../ui/messages/messages.component';
import { CustomValidators } from '../../validation/custom-validators';

export interface EditDateTimeRangeComponentFormData {
    from: Date;
    until: Date;
}

/**
 *  TODO: we need a DateTime picker, also time conversion from local to utc must be solved.
 *
 *  myForm.value contains:
 *
 *  {
 *      ...,
 *      'nameOfSubFormGroup': {
 *          from: ...,
 *          until: ...,
 *      },
 *  }
 */
@Component( {
    selector:    'rsp-edit-date-time-range',
    templateUrl: './edit-date-time-range.component.html',
    styleUrls:   [
        './edit-date-time-range.component.scss',
    ],
} )
export class EditDateTimeRangeComponent extends DisplayEditBaseComponent implements OnInit {
    @Input() withTime: boolean = false;
    @Input() fromPlaceholder: string;
    @Input() untilPlaceholder: string;
    @Input() disablePastFromDates: boolean;
    @Input() disablePastUntilDates: boolean;
    @Input() fromInputFieldHtmlId: string;
    @Input() untilInputFieldHtmlId: string;

    dateFromFormControl: UntypedFormControl;
    dateUntilFormControl: UntypedFormControl;

    messageTypeError: MessageType = MessageType.Error;

    constructor(
        validationMessageService: ValidationMessagesService,
    ) {
        super( validationMessageService );
    }


    /**
     *
     * TODO: withTime
     *
     * @param {Date} from
     * @param {Date} until
     * @param {Array<ValidatorFn>} fromValidators
     * @param {Array<ValidatorFn>} untilValidators
     * @returns {FormGroup}
     */
    static buildFormGroup( from: Date, until: Date, fromValidators?: Array<ValidatorFn>, untilValidators?: Array<ValidatorFn> ): UntypedFormGroup {
        return new UntypedFormGroup(
            {
                from:  new UntypedFormControl( from, fromValidators ),
                until: new UntypedFormControl( until, untilValidators ),
            },
            CustomValidators.isDateRangeValid(),
        );
    }


    ngOnInit(): void {
        super.startEditMode();

        if ( this.withTime ) {
            throw new Error( 'EditDateTimeRangeComponent: [withTime] not yet implemented (need a date picker that supports time inputs, too).' );
        }

        this.dateFromFormControl  = <UntypedFormControl> this.myFormGroup.get( 'from' );
        this.dateUntilFormControl = <UntypedFormControl> this.myFormGroup.get( 'until' );

        if ( !this.dateFromFormControl || !this.dateUntilFormControl ) {
            throw new Error( '#myFormGroup must contain "from"  and "until" FormControls!' );
        }

        this.myFormGroup
            .valueChanges
            // in case of "clear" (via text-input directive) the changes seem to be delayed, so small delay is needed
            .pipe(
                debounceTime( 1 ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                super.updateValidationMessages( this.myFormGroup );
            } );
    }
}
