<div class="edit-date-time-range">
    <rsp-display-edit-date
        [isEditMode]="true"
        [myFormGroup]="myFormGroup"
        [name]="'from'"
        [placeholder]="fromPlaceholder || null"
        [disablePastDates]="disablePastFromDates"
        [inputFieldHtmlId]="fromInputFieldHtmlId || null"
        class="edit-date-time-range--input"
    ></rsp-display-edit-date>

    <span class="edit-date-time-range--separator">&ndash;</span>

    <rsp-display-edit-date
        [isEditMode]="true"
        [myFormGroup]="myFormGroup"
        [name]="'until'"
        [placeholder]="untilPlaceholder || null"
        [disablePastDates]="disablePastUntilDates"
        [inputFieldHtmlId]="untilInputFieldHtmlId || null"
        class="edit-date-time-range--input"
    ></rsp-display-edit-date>
</div>

<rsp-messages
    *ngIf="!hideValidationMessages && validationMessages.length > 0"
    [type]="messageTypeError"
    [messages]="validationMessages"
></rsp-messages>
