import { Injectable, } from '@angular/core';

/**
 * Provides current time. Should be the only place in the whole application where new Date() is used.
 */
type logicImplementation = () => Date;

@Injectable()
export class ApplicationTimeService {
    public static replaceLogic( logic: logicImplementation ): void {
        this.currentLogic = logic;
    }

    public static revertLogic(): void {
        this.currentLogic = this.defaultLogic;
    }

    public static current(): Date {
        return ApplicationTimeService.currentLogic();
    }

    private static defaultLogic(): Date {
        return new Date();
    }

    /* tslint:disable:member-ordering */ // Prevent "Block-scoped variable 'defaultLogic' used before its declaration."-error.
    private static currentLogic: logicImplementation = ApplicationTimeService.defaultLogic;
    /* tslint:enable:member-ordering */
}
