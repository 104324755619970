<div class="registration-wizard">
    <header class="registration-wizard--header">
        <h1 class="registration-wizard--welcome">Hello <rsp-user-name *ngIf="user" [user]="user"></rsp-user-name></h1>

        <rsp-progress-step-view
            [steps]="stepNames"
            [currentStep]="currentStepNumber"
            [showStepNames]="true"
            class="registration-wizard--steps"
        ></rsp-progress-step-view>
    </header>

    <section
        [rspLoadingIndicator]="isLoading"
        class="registration-wizard--step-content"
    >
        <ng-container
            *ngIf="!registrationDone"
            [ngSwitch]="currentStep.id"
        >
            <ng-container *ngSwitchCase="'my-profile'">
                <rsp-registration-wizard-my-profile
                    *ngIf="moduleGroups"
                    [originalUserData]="user"
                    [moduleGroups]="moduleGroups"
                    [cultures]="cultures"
                    (dataChanged)="setCurrentStepUserData( $event )"
                ></rsp-registration-wizard-my-profile>
            </ng-container>

            <ng-container *ngSwitchCase="'distribution-channels'">
                <rsp-registration-wizard-distribution-channels
                    *ngIf="distributionChannels"
                    [userData]="user"
                    [distributionChannels]="distributionChannels"
                    (dataChanged)="setCurrentStepUserData( $event )"
                ></rsp-registration-wizard-distribution-channels>
            </ng-container>

            <ng-container *ngSwitchCase="'concepts'">
                <rsp-registration-wizard-concepts
                    *ngIf="concepts"
                    [userData]="user"
                    [concepts]="concepts"
                    (dataChanged)="setCurrentStepUserData( $event )"
                ></rsp-registration-wizard-concepts>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="registrationDone">
            <div class="registration-wizard--registration-done-message">
                <h2 class="registration-wizard--registration-done-message-headline">Thanks for registering!</h2>
                <p>You will receive an email as soon as your account request was evaluated by our support team.</p>
                <p>
                    If you have any questions in the meantime, please contact
                    <a href="mailto:{{ environment.supportEmailAddress }}">{{ environment.supportEmailAddress }}</a>.
                </p>
            </div>
        </ng-container>
    </section>

    <footer
        *ngIf="!registrationDone"
        class="registration-wizard--bottom-buttons-wrapper"
    >
        <rsp-button
            *ngIf="currentStepNumber > 1"
            [submodule]="'secondary'"
            (clicked)="goToPreviousStep()"
            class="registration-wizard--bottom-buttons-back-button"
        >
            Back
        </rsp-button>

        <rsp-button
            *ngIf="currentStepNumber < steps.length"
            [submodule]="'primary'"
            [disabled]="currentStep.userData === null"
            (clicked)="goToNextStep()"
        >
            Continue
        </rsp-button>

        <rsp-button
            *ngIf="currentStepNumber === steps.length"
            [submodule]="'primary'"
            [disabled]="currentStep.userData === null"
            (clicked)="registerUser()"
        >
            Finish Registration
        </rsp-button>
    </footer>
</div>
