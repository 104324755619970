/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface ConceptTile {
    clusters?: number;

    id?: string;

    name?: string;

    status?: ConceptTile.StatusEnum;

}

export namespace ConceptTile {
    'use strict';
    export enum StatusEnum {
        Unpublished,
        Published,
        Active,
        Inactive,
    }
    export namespace StatusEnum {
        export function toString( enumValue: StatusEnum ): string {
            return StatusEnum[ enumValue ];
        }
    }
}

