import { Component, OnInit, OnDestroy, } from '@angular/core';
import { Subscription, of, merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ArticleManagementConceptsService } from '../../../../../shared/api/articleManagementConcepts.service';
import { ConceptCluster, } from '../../../../../shared/model/conceptCluster';
import { ViewModelListConceptCluster, } from '../../../../../shared/model/viewModelListConceptCluster';
import { CollectionView } from '../../../../../shared/ui/tables/collection-view';
import { StandardFilters } from '../../../../../shared/ui/tables/filters/standard-filters';
import { ConceptService, } from '../concept.service';
import { EntityTypeEnum } from '../../../../../shared/model/enum/entity-type.enum';
import { HasLoadingIndicator, loadWithProgressIndicator } from '../../../../../shared/utils/rxjs-extensions/load-with-progress-indicator.extension';
import { ConceptClusterCollectionViewItem } from './concept-cluster-collection-view-item.model';


@Component( {
    selector:    'rsp-concept-clusters',
    templateUrl: './concept-clusters.component.html',
    styleUrls:   [
        '../../../../../shared/scss/05_module/detail-page-tab.scss',
        './concept-clusters.component.scss',
    ],
} )
export class ConceptClustersComponent implements OnInit, OnDestroy, HasLoadingIndicator {

    collectionView: CollectionView<ConceptClusterCollectionViewItem> = new CollectionView<ConceptClusterCollectionViewItem>();
    isLoading: Subscription;

    statusTypeEnum: EntityTypeEnum = EntityTypeEnum.Cluster;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private conceptsApi: ArticleManagementConceptsService,
        private conceptService: ConceptService,
        private standardFilters: StandardFilters,
    ) {
    }


    ngOnInit(): void {

        this.initializeTableFeatures();

        // Two entry points:
        //  - of( true ) - first component load
        //  - this.pblService.summary$ - prev/next navigation
        merge( of( true ), this.conceptService.summary$ )
            .pipe(
                loadWithProgressIndicator( () => this.conceptsApi.conceptsGetClusters( this.conceptService.conceptId ), this ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( ( result: ViewModelListConceptCluster ) => {
                this.collectionView.setSourceItems(
                    result.data.map( ( conceptCluster: ConceptCluster ) => {
                        return new ConceptClusterCollectionViewItem( conceptCluster );
                    } ) ).refresh();
            } );
    }


    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();

        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    private initializeTableFeatures(): void {

        // filter function
        this.collectionView.setGlobalFilterFunction( ( item: ConceptClusterCollectionViewItem, filter: string ) => {

            if ( typeof filter === 'undefined' || filter === null ) {
                return true;
            }

            if ( this.standardFilters.contains( item.name, filter )
                || this.standardFilters.contains( item.availableForOrderingFrom, filter )
                || this.standardFilters.contains( item.availableForOrderingUntil, filter )
                || this.standardFilters.contains( item.articles, filter )
                || this.standardFilters.contains( item.assemblies, filter )
                || this.standardFilters.contains( item.articlesAndAssemblies, filter )
                || this.standardFilters.contains( item.status, filter ) ) {
                return true;
            }

            return false;
        } );
    }

}
