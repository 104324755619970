import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RegistrationUserData } from '../registration-wizard.component';
import { ConceptListItem } from '../../../../shared/model';

@Component( {
    selector:    'rsp-registration-wizard-concepts',
    templateUrl: './registration-wizard-concepts.component.html',
    styleUrls:   [ './registration-wizard-concepts.component.scss' ],
} )
export class RegistrationWizardConceptsComponent implements OnInit {
    @Input() userData: RegistrationUserData;
    @Input() concepts: ConceptListItem[];
    @Output() dataChanged: EventEmitter<RegistrationUserData | null> = new EventEmitter();

    selectedConcepts: ConceptListItem[] = [];

    ngOnInit(): void {
        this.selectedConcepts = JSON.parse( JSON.stringify( this.userData.concepts ) );
    }

    setSelectedConcepts( concepts: ConceptListItem[] ): void {
        this.selectedConcepts = concepts;

        if ( this.selectedConcepts.length > 0 ) {
            this.dataChanged.emit( {
                concepts: this.selectedConcepts,
            } );
        }
        else {
            this.dataChanged.emit( null );
        }
    }
}
