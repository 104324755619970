import { Component } from '@angular/core';

/**
 * Displays unit: m3
 */
@Component( {
    selector:    'rsp-unit-cubic-meter',
    templateUrl: './unit-cubic-meter.component.html',
    styleUrls:   [
        './unit-cubic-meter.component.scss',
    ],
} )
export class UnitCubicMeterComponent {
}
