import { Injectable } from '@angular/core';

import { UserInfo } from '../../model/userInfo';
import { CurrentUserContext } from '../../model';

@Injectable()
export class UserNameService {
    static format( user: UserInfo | CurrentUserContext ): string {
        let result: string = user.familyName;

        if ( user.firstName ) {
            result += ', ' + user.firstName;
        }

        return result;
    }

    static getInitials( user: UserInfo | CurrentUserContext ): string {
        let result: string = '';

        if ( user.firstName ) {
            result += user.firstName.charAt( 0 );
        }

        if ( user.familyName ) {
            result += user.familyName.charAt( 0 );
        }

        return result;
    }

    format( user: UserInfo | CurrentUserContext ): string {
        return UserNameService.format( user );
    }

    getInitials( user: UserInfo | CurrentUserContext ): string {
        return UserNameService.getInitials( user );
    }
}
