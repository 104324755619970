import { Directive } from '@angular/core';
import { UntypedFormControl, NG_VALIDATORS, ValidationErrors, Validator, } from '@angular/forms';
import { CustomValidators } from './custom-validators';


/**
 *  Validates null or positive Number
 */
@Directive( {
    selector: '[rspPositiveNumberValidation][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: PositiveNumberValidationDirective, multi: true },
    ],
} )
export class PositiveNumberValidationDirective implements Validator {

    validate( c: UntypedFormControl ): ValidationErrors | null {
        return !c.value ? null : CustomValidators.positiveNumber( c );
    }
}


/**
 *  Validates null or positive Number
 */
@Directive( {
    selector: '[rspZeroOrPositiveNumberValidation][ngModel]',
    providers: [
        { provide: NG_VALIDATORS, useExisting: ZeroOrPositiveNumberValidationDirective, multi: true },
    ],
} )
export class ZeroOrPositiveNumberValidationDirective implements Validator {

    validate( c: UntypedFormControl ): ValidationErrors | null {
        return !c.value ? null : CustomValidators.zeroOrPositiveNumber( c );
    }
}
