import { Pipe, PipeTransform } from '@angular/core';
import { StringConverterService } from './string-converter.service';

@Pipe( {
    name: 'camelCaseToSpace',
} )
export class CamelCaseToSpacePipe implements PipeTransform {

    transform( value: any, args?: any ): any {
        const initialValue: string = (value as string);

        if (initialValue) {
            if (
                initialValue.indexOf( ' ' ) === -1 &&         // don't transform values that already have spaces
                initialValue.toUpperCase() !== initialValue // don't transform values that are all UPPERCASE
            ) {
                value = StringConverterService.splitCamelcaseWithSpace( initialValue );
            }
        }

        return value;
    }

}
