import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription ,  Observable } from 'rxjs';

import { RadioButtonGroupItem } from '../../../../../../shared/ui/radio-button-group/radio-button-group.model';
import { UniqueHtmlIdService } from '../../../../../../core/unique-html-id.service';
import { MaterialInfo } from '../../../../../../shared/model/materialInfo';
import { ArticleManagementGraphicsService } from '../../../../../../shared/api';
import { GraphicListItem } from '../../../../../../shared/model/graphicListItem';
import { UploadedFileInfo, UploadSingleFileComponent } from '../../../../../../shared/forms/upload/upload-single-file/upload-single-file.component';
import { FileKindInfo } from '../../../../../../shared/model/fileKindInfo';
import { ArticleManagementArticlesService } from '../../../../../../shared/api';
import { ArticleManagementAssembliesService } from '../../../../../../shared/api';
import { CurrentNonTradeItem } from '../../../shared/current-non-trade-item.model';
import { NonTradeItemType, } from '../../../shared/non-trade-item-type.model';
import { ReplyGuid } from '../../../../../../shared/model/replyGuid';
import { GraphicDetailsDto } from '../../../../../../shared/model/graphicDetailsDto';
import { EditTextComponent } from '../../../../../../shared/forms/controls/edit-text/edit-text.component';
import { CustomValidators } from '../../../../../../shared/forms/validation/custom-validators';
import { MessageType } from '../../../../../../shared/ui/messages/messages.component';
import { ValidationMessagesService } from '../../../../../../shared/forms/validation/validation-messages.service';

@Component( {
    selector:    'rsp-non-trade-item-graphic-edit',
    templateUrl: './non-trade-item-graphic-edit.component.html',
    styleUrls:   [
        '../../../../../../shared/scss/04_layout/two-columns-1200.scss',
        './non-trade-item-graphic-edit.component.scss',
    ],
} )
export class NonTradeItemGraphicEditComponent implements OnInit, OnDestroy {
    @Input() graphic: GraphicListItem;

    @Input()
    set isEditMode( value: boolean) { this.setEditMode( value ); }
    get isEditMode(): boolean { return this._isEditMode; }

    @Input() fileKinds: Array<FileKindInfo>;

    @Input() nonTradeItem: CurrentNonTradeItem;

    @Output() updatedSuccessful: EventEmitter<void>   = new EventEmitter<void>();
    @Output() createdSuccessful: EventEmitter<string> = new EventEmitter<string>();

    @ViewChild(UploadSingleFileComponent) uploadComponent: UploadSingleFileComponent;

    htmlIdFor: { [ key: string ]: string } = {};

    form: UntypedFormGroup;

    isLoading: Subscription;

    materialName: string;

    previewImageId: string;

    messageTypeError: MessageType = MessageType.Error;

    thicknessValidationMessages: String[] = [];

    private _isEditMode: boolean = false;

    constructor(
        private uniqueHtmlIdService: UniqueHtmlIdService,
        private graphicsApi: ArticleManagementGraphicsService,
        private articlesApi: ArticleManagementArticlesService,
        private assembliesApi: ArticleManagementAssembliesService,
        private formBuilder: UntypedFormBuilder,
        private validationMessageService: ValidationMessagesService,
    ) { }

    ngOnInit(): void {
        this.htmlIdFor[ 'nameField' ] = this.uniqueHtmlIdService.getUniqueHtmlId( 'name-field' );
        this.htmlIdFor[ 'noteField' ] = this.uniqueHtmlIdService.getUniqueHtmlId( 'note-field' );

        if ( this.graphic.material ) {
            this.materialName = this.graphic.material.name;
        }
    }

    ngOnDestroy(): void {
        if ( this.isLoading ) {
            this.isLoading.unsubscribe();
        }
    }

    selectedMaterial( item?: MaterialInfo ): void {
        this.form.get( 'materialId' ).setValue( item ? item.id : null );

        this.materialName = item ? item.name : '';
    }

    setTemplateFile( uploadedFileInfo: UploadedFileInfo ): void {
        this.form.get( 'templateFileContainerId' ).setValue( uploadedFileInfo.fileContainerId );
        this.previewImageId = uploadedFileInfo.previewImageId;
    }

    triggerSave( event: MouseEvent ): void {
        event.stopPropagation();

        const graphicDetails: GraphicDetailsDto = this.form.value;

        if ( !this.graphic.id ) {
            const api: Observable<ReplyGuid> =
                      this.nonTradeItem.type === NonTradeItemType.Article
                          ? this.articlesApi.articlesCreateGraphic( this.nonTradeItem.id, graphicDetails )
                          : this.assembliesApi.assembliesCreateGraphic( this.nonTradeItem.id, graphicDetails );

            this.isLoading =
                api.subscribe( ( result: ReplyGuid ) => {
                    this.createdSuccessful.emit( result.value );
                } );

        }
        else {
            this.isLoading =
                this.graphicsApi.graphicsUpdateGraphic( this.graphic.id, graphicDetails ).subscribe( () => {
                    this.updatedSuccessful.emit();
                } );
        }
    }

    private setEditMode( value: boolean ): void {
        this._isEditMode = value;

        if ( value ) {
            this.buildForm();
        }
        else if ( this.form ) {
            this.form = null;
        }
    }

    private buildForm(): void {
        const thicknessControl: UntypedFormControl = EditTextComponent.buildFormControl( this.graphic.thickness, [ CustomValidators.positiveNumber ] );

        this.form = this.formBuilder.group( {
            name:                             EditTextComponent.buildFormControl( this.graphic.name, [ Validators.required, CustomValidators.stringNotEmpty ] ),
            note:                             EditTextComponent.buildFormControl( this.graphic.note ),
            thickness:                        thicknessControl,
            materialId:                       new UntypedFormControl( this.graphic.material ? this.graphic.material.id : null ),
            templateFileContainerId:          new UntypedFormControl( this.graphic.templateFile ? this.graphic.templateFile.id : null ),
            type:                             new UntypedFormControl(),
            graphicAreaDimensionsWidth:
                                              EditTextComponent.buildFormControl(
                                                  this.graphic.graphicArea.width ? this.graphic.graphicArea.width.toString() : null,
                                                  [ CustomValidators.positiveNumber ],
                                              ),
            graphicAreaDimensionsHeight:
                                              EditTextComponent.buildFormControl(
                                                  this.graphic.graphicArea.height ? this.graphic.graphicArea.height.toString() : null,
                                                  [ CustomValidators.positiveNumber ],
                                              ),
            visiblePrintAreaDimensionsWidth:
                                              EditTextComponent.buildFormControl(
                                                  this.graphic.visiblePrintArea.width ? this.graphic.visiblePrintArea.width.toString() : null,
                                                  [ CustomValidators.positiveNumber ],
                                              ),
            visiblePrintAreaDimensionsHeight:
                                              EditTextComponent.buildFormControl(
                                                  this.graphic.visiblePrintArea.height ? this.graphic.visiblePrintArea.height.toString() : null,
                                                  [ CustomValidators.positiveNumber ],
                                              ),
        } );

        // for styling reasons, we have to take care of the validation messages ourselves
        thicknessControl.valueChanges.subscribe( () => {
            this.thicknessValidationMessages = [];
            this.thicknessValidationMessages.push( ...this.validationMessageService.getValidationMessages( thicknessControl.errors ) );
        } );
    }
}
