import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

/**
 * Converts a checkbox to a switch toggle.
 *
 *
 * ```html
 *  <input rspSwitch type="checkbox">
 *  <input rspSwitch [formControl]="myForm.get( 'someFormControlName' )" type="checkbox" >
 * ```
 */
@Directive( {
    selector: '[rspSwitch]',
} )
export class SwitchDirective implements OnInit, OnDestroy {
    @Input() switchWrapperClass: string;

    @Input()
    set disabled( value: boolean ) { this.setDisabled( value ); }
    get disabled(): boolean { return this._disabled; }

    @Input() formControl: UntypedFormControl;

    private wrapperElement: HTMLDivElement;
    private _disabled: boolean;

    constructor(
        private hostElement: ElementRef,
        private renderer: Renderer2,
    ) {}


    ngOnInit(): void {
        this.initSwitch();
    }

    ngOnDestroy(): void {
        const wrapper: HTMLElement = this.hostElement.nativeElement.parentNode;
        const wrapperParent: Node = wrapper.parentNode;

        this.renderer.removeChild( wrapperParent, wrapper );
    }

    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private initSwitch(): void {
        this.renderer.addClass( this.hostElement.nativeElement, 'switch--input' );

        // build wrapper and wrap the original checkbox with it
        this.wrapperElement = this.renderer.createElement( 'label' );
        this.renderer.addClass( this.wrapperElement, 'switch' );
        this.renderer.insertBefore( this.hostElement.nativeElement.parentNode, this.wrapperElement, this.hostElement.nativeElement );
        this.renderer.appendChild( this.wrapperElement, this.hostElement.nativeElement );

        // add slider element
        const span: HTMLElement = this.renderer.createElement( 'span' );
        this.renderer.addClass( span, 'switch--slider' );
        this.renderer.appendChild( this.wrapperElement, span );

        this.setDisabled( this._disabled);

        if ( this.switchWrapperClass ) {
            this.renderer.addClass( this.wrapperElement, this.switchWrapperClass );
        }
    }

    private setDisabled( value: boolean | string ): void {
        const isDisabled: boolean = this.formControl          ? this.formControl.disabled :
                                    typeof value === 'string' ? value !== 'false'         : // value can be empty string (e.g. <input type="text" disabled>)
                                                                value;
        this._disabled = isDisabled;

        this.renderer.setProperty( this.hostElement.nativeElement, 'disabled', isDisabled );

        const span: HTMLElement = this.hostElement.nativeElement.parentNode.querySelector('span');

        if ( span ) {
            if ( isDisabled ) {
                this.renderer.addClass( span, 'is-disabled' );
            } else {
                this.renderer.removeClass( span, 'is-disabled' );
            }
        }
    }
}
