/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */



export interface NonTradeItemSuggestSearchConfiguration {
    nonTradeItemType?: NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum;

    states?: Array<NonTradeItemSuggestSearchConfiguration.StatesEnum>;

    isReadyToOrder?: boolean;

    ignoreIds?: Array<string>;

    term?: string;

    size?: number;

    page?: number;

}

export namespace NonTradeItemSuggestSearchConfiguration {
    'use strict';
    export enum NonTradeItemTypeEnum {
        Article,
        Assembly,
    }
    export namespace NonTradeItemTypeEnum {
        export function toString( enumValue: NonTradeItemTypeEnum ): string {
            return NonTradeItemTypeEnum[ enumValue ];
        }
    }
    export enum StatesEnum {
        Unpublished,
        Development,
        ActiveInPreRollout,
        ActiveInRollout,
        ActiveOnDemand,
        Discontinue,
        Inactive,
    }
    export namespace StatesEnum {
        export function toString( enumValue: StatesEnum ): string {
            return StatesEnum[ enumValue ];
        }
    }
}

