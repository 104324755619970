import { Component, ElementRef, forwardRef, HostBinding, Inject, OnInit, ViewChild } from '@angular/core';
import { VirtualScrollComponent } from '../virtual-scroll.component';

@Component( {
    selector:    'rsp-virtual-scroll-item',
    templateUrl: './virtual-scroll-item.component.html',
    styleUrls:   [
        './virtual-scroll-item.component.scss',
    ],
} )
export class VirtualScrollItemComponent implements OnInit {

    @ViewChild( 'container', { static: true } )
    container: ElementRef;

    @HostBinding( 'style.visibility' )
    visibility: string = 'visible';

    private virtualScrollComponent!: VirtualScrollComponent;


    constructor(
        // using forwardRef because VirtualScrollComponent uses ng-content
        // with a proper typedef we would get a runtime error during bootstrap:
        // > main.js:124833 Uncaught ReferenceError: Cannot access 'VirtualScrollComponent' before initialization
        // >    at Module.VirtualScrollComponent
        // See https://github.com/angular/angular-cli/issues/14247#issuecomment-486582423
        // tslint:disable-next-line:no-any
        @Inject( forwardRef( () => VirtualScrollComponent ) ) virtualScrollComponent: any,
    ) {
        this.virtualScrollComponent = virtualScrollComponent as VirtualScrollComponent;
    }


    ngOnInit(): void {

        if ( !this.virtualScrollComponent.isInitialized() ) {

            // VirtualScrollComponent is not yet initialized. It means that this item must be rendered with style visibility: 'hidden'.
            this.hide();
        }
    }

    show(): void {
        this.visibility = 'visible';
    }

    hide(): void {
        this.visibility = 'hidden';
    }
}
