import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { DetailPagePrevNextNavigationService } from '../../../../../shared/ui/lists/detail-page-prev-next-navigation/detail-page-prev-next-navigation.service';
import { ConceptOrClusterItem } from '../../../../../shared/model/conceptOrClusterItem';
import { ConceptClusterStoreService } from '../concept-cluster-store.service';
import { ConceptClusterService } from '../concept-cluster.service';

@Injectable()
export class ConceptClusterPrevNextNavigationService extends DetailPagePrevNextNavigationService<ConceptOrClusterItem> {
    constructor(
        router: Router,
        storeService: ConceptClusterStoreService,
        private conceptClusterService: ConceptClusterService,
    ) {
        super( router, storeService );
    }

    protected getItemIndex( id: string ): number {
        // meh…
        const pseudoItem: any = {
            type:    ConceptOrClusterItem.TypeEnum.Concept,
            concept: { id: id, },
        };
        const index: number   = this.storeService.getItemIndex( pseudoItem );

        return index;
    }

    protected createRouterLinkForItem( item: ConceptOrClusterItem ): any[] {
        const itemTypeAsString: string = ConceptOrClusterItem.TypeEnum.toString( item.type ).toLowerCase();
        const id: string               = this.conceptClusterService.getConceptOrClusterIdFromConceptOrClusterItem( item );

        return [ '/' + itemTypeAsString, id, this.lastUrlPart ];
    }
}
