import { Component } from '@angular/core';

/**
 * Displays unit: mm
 */
@Component( {
    selector:    'rsp-unit-millimeter',
    templateUrl: './unit-millimeter.component.html',
    styleUrls:   [
        './unit-millimeter.component.scss',
    ],
} )
export class UnitMillimeterComponent {
}
