import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Observable ,  Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DialogService } from '../../../../../core/overlay/dialog/dialog.service';
import { ModuleGroupInfo, Reply, RequestUserModuleGroups, UpdateUserModuleGroups, UserModuleGroupInfo } from '../../../../model';
import { UserManagementCurrentUserService, UserManagementUsersService } from '../../../../api';
import { UserEditContext } from '../../user.model';
import { MessageType } from '../../../messages/messages.component';

@Component( {
    selector:    'rsp-user-module-groups-dialog',
    templateUrl: './user-module-groups-dialog.component.html',
    styleUrls:   [
        '../../../../scss/05_module/standard-dialog.scss',
        './user-module-groups-dialog.component.scss',
    ],
} )
export class UserModuleGroupsDialogComponent implements OnChanges, OnDestroy {
    @Input() userModuleGroups: UserModuleGroupInfo[];
    @Input() allModuleGroups: ModuleGroupInfo[];
    @Input() userEditMode: UserEditContext;
    @Input() userId: string;
    @Output() changeModuleGroups: EventEmitter<UserModuleGroupInfo[]> = new EventEmitter();

    @ViewChild( 'moduleGroupDialogHeadline', { static: true } ) moduleGroupDialogHeadline: TemplateRef<any>;
    @ViewChild( 'moduleGroupDialogContent', { static: true } ) moduleGroupDialogContent: TemplateRef<any>;
    @ViewChild( 'moduleGroupDialogFooter', { static: true } ) moduleGroupDialogFooter: TemplateRef<any>;

    assignedModuleGroups: UserModuleGroupInfo[];
    availableModuleGroups: ModuleGroupInfo[];
    selectedModuleGroups: Set<string> = new Set();
    messageTypeEnum: any              = MessageType;
    isSaving: Subscription;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dialogService: DialogService,
        private userManagementUsersService: UserManagementUsersService,
        private userManagementCurrentUserService: UserManagementCurrentUserService,
    ) {

    }

    ngOnChanges( changes: SimpleChanges ): void {
        if ( changes.userModuleGroups ) {
            // copy userModuleGroups @Input() so we don't have referential side effects (e.g. using `.push()` on the same array the parent component uses)
            this.userModuleGroups = JSON.parse( JSON.stringify( this.userModuleGroups ) );
        }

        if ( changes.userModuleGroups || changes.allModuleGroups ) {
            this.assignedModuleGroups = [];
            this.availableModuleGroups = [];
            this.selectedModuleGroups.clear();
            this.allModuleGroups.forEach( ( moduleGroup: ModuleGroupInfo ) => {
                const foundUserModuleGroup: UserModuleGroupInfo = this.userModuleGroups.find(
                    ( userModuleGroup: ModuleGroupInfo ) => moduleGroup.id === userModuleGroup.id,
                );
                if ( foundUserModuleGroup ) {
                    this.assignedModuleGroups.push( foundUserModuleGroup );
                    this.selectedModuleGroups.add( foundUserModuleGroup.id );
                }
                else {
                    this.availableModuleGroups.push( moduleGroup );
                }
            } );
        }

        if ( this.userEditMode === 'user-management' && !this.userId ) {
            throw new Error( 'UserModuleGroupsDialogComponent: Need [userId].' );
        }
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    openDialog(): void {
        this.dialogService.openDialog( {
            contentTemplate:  this.moduleGroupDialogContent,
            headlineTemplate: this.moduleGroupDialogHeadline,
            footerTemplate:   this.moduleGroupDialogFooter,
            withBackdrop:     true,
        } );
    }

    closeDialog(): void {
        this.dialogService.closeDialog();
    }

    toggleModuleGroupSelection( moduleGroupId: string ): void {
        if ( this.selectedModuleGroups.has( moduleGroupId ) ) {
            this.selectedModuleGroups.delete( moduleGroupId );
        }
        else {
            this.selectedModuleGroups.add( moduleGroupId );
        }
    }

    saveModuleGroups(): void {
        let backendCall: Observable<Reply>;
        if ( this.userEditMode === 'my-profile' ) {
            const command: RequestUserModuleGroups = {
                // Jacek says we should ignore the `userId` property.
                moduleGroupIds: Array.from( this.selectedModuleGroups ),
            };
            backendCall = this.userManagementCurrentUserService.currentUserRequestModuleGroups( command );
        }
        else {
            const command: UpdateUserModuleGroups = {
                userId:         this.userId,
                moduleGroupIds: Array.from( this.selectedModuleGroups ),
            };

            backendCall = this.userManagementUsersService.usersUpdateModuleGroups( this.userId, command );
        }

        this.isSaving = backendCall
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( () => {
                const updatedUserModuleGroups: UserModuleGroupInfo[] = this.allModuleGroups
                    .filter( ( moduleGroup: ModuleGroupInfo ) => this.selectedModuleGroups.has( moduleGroup.id ) )
                    .map(    ( moduleGroup: ModuleGroupInfo ) => {
                        let isWaitingForApproval: boolean = false;
                        if ( this.userEditMode === 'my-profile' ) {
                            const currentUserModuleGroup: UserModuleGroupInfo = this.userModuleGroups.find(
                                ( userModuleGroup: UserModuleGroupInfo ) => userModuleGroup.id === moduleGroup.id,
                            );

                            if ( !currentUserModuleGroup || ( currentUserModuleGroup && currentUserModuleGroup.isWaitingForApproval ) ) {
                                isWaitingForApproval = true;
                            }
                        }

                        const updatedUserModuleGroup: UserModuleGroupInfo = {
                            ... moduleGroup,
                            isWaitingForApproval,
                        };

                        return updatedUserModuleGroup;
                    } );
                this.changeModuleGroups.emit( updatedUserModuleGroups );
                this.closeDialog();
            } );
    }
}
