<div class="tab">
    <div class="tab--toolbar">
        <rsp-form-buttons
            *ngIf="hasEditRight"
            [isEditMode]="isEditMode"
            (actionButtonClick)="startEditMode()"
            (primaryEditButtonClick)="saveConcepts()"
            (secondaryEditButtonClick)="endEditMode()"
            class="tab--toolbar-item is-right"
        ></rsp-form-buttons>
    </div>

    <div
        *ngIf="allConcepts && userConcepts"
        [rspLoadingIndicator]="isLoading"
        class="tab--content"
    >
        <rsp-concepts
            [concepts]="allConcepts"
            [selectedConcepts]="userConcepts"
            [noConceptsMessage]="noConceptsMessage"
            [isEditMode]="isEditMode"
            (selectionChanged)="setConceptsToSave( $event )"
        ></rsp-concepts>
    </div>
</div>
