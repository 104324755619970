/**
 * RSP.WebApi
 * Data API for RSP Platform
 *
 * OpenAPI spec version: v1
 * Contact: info@nureg.de
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */

/* tslint:disable:no-unused-variable member-ordering max-line-length no-trailing-whitespace prefer-const*/

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse, HttpEvent } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../generated/encoder';

import { Observable } from 'rxjs';

import { BrowsingConfiguration } from '../model/browsingConfiguration';
import { CreateFilesExport } from '../model/createFilesExport';
import { FileContainerItem } from '../model/fileContainerItem';
import { ModelObject } from '../model/modelObject';
import { ReplyListGuid } from '../model/replyListGuid';
import { ViewModelBrowsingListFileContainerTile } from '../model/viewModelBrowsingListFileContainerTile';
import { ViewModelListFileContainerItem } from '../model/viewModelListFileContainerItem';
import { ViewModelListFileVersionItem } from '../model/viewModelListFileVersionItem';

import { BASE_PATH, COLLECTION_FORMATS } from '../generated/variables';
import { Configuration } from '../generated/configuration';


@Injectable()
export class FileManagementFilesService {

    protected basePath: string;
public defaultHeaders: HttpHeaders = new HttpHeaders( {
'Cache-Control': 'no-cache',
'Pragma': 'no-cache',
'Expires': 'Sat, 01 Jan 2000 00:00:00 GMT',
} );
    public configuration: Configuration = new Configuration();

    constructor( protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration ) {
        if ( basePath ) {
            this.basePath = basePath;
        }
        if ( configuration ) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form: string = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * CreateZipFile
     * 
     * @param command 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersCreateFilesExport(command: CreateFilesExport, observe?: 'body', reportProgress?: boolean): Observable<ModelObject>;
    public fileContainersCreateFilesExport(command: CreateFilesExport, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ModelObject>>;
    public fileContainersCreateFilesExport(command: CreateFilesExport, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ModelObject>>;
    public fileContainersCreateFilesExport(command: CreateFilesExport, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (command === null || command === undefined) {
            throw new Error('Required parameter command was null or undefined when calling fileContainersCreateFilesExport.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ModelObject>(
                `${this.basePath}/api/v1/filemanagement/files/zip`,
                command,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Get latest version of file container
     * 
     * @param fileContainerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersGetFile(fileContainerId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public fileContainersGetFile(fileContainerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public fileContainersGetFile(fileContainerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public fileContainersGetFile(fileContainerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileContainerId === null || fileContainerId === undefined) {
            throw new Error('Required parameter fileContainerId was null or undefined when calling fileContainersGetFile.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(
                `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/latest`,
                
                {
                    responseType: 'blob',
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }


    /**
     * Returns the URL used by fileContainersGetFile including the user token.
     * @param fileContainerId 
     */
    public fileContainersGetFileAuthenticatedUrl( fileContainerId: string,  ): string {
        const path: string = `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/latest`;

        const accessToken: any = typeof this.configuration.accessToken === 'function'
            ? this.configuration.accessToken()
            : this.configuration.accessToken;

        return path + '?TK=' + encodeURIComponent( accessToken );
    }

    /**
     * Returns the URL used by fileContainersGetFile.
     * @param fileContainerId 
     */
    public fileContainersGetFileUrl( fileContainerId: string,  ): string {
        const path: string = `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/latest`;

        return path;
    }

    /**
     * Get latest version of file container without login
     * 
     * @param fileContainerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersGetFileAnonymous(fileContainerId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public fileContainersGetFileAnonymous(fileContainerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public fileContainersGetFileAnonymous(fileContainerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public fileContainersGetFileAnonymous(fileContainerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileContainerId === null || fileContainerId === undefined) {
            throw new Error('Required parameter fileContainerId was null or undefined when calling fileContainersGetFileAnonymous.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(
                `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/latest/anonymous`,
                
                {
                    responseType: 'blob',
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }


    /**
     * Returns the URL used by fileContainersGetFileAnonymous including the user token.
     * @param fileContainerId 
     */
    public fileContainersGetFileAnonymousAuthenticatedUrl( fileContainerId: string,  ): string {
        const path: string = `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/latest/anonymous`;

        const accessToken: any = typeof this.configuration.accessToken === 'function'
            ? this.configuration.accessToken()
            : this.configuration.accessToken;

        return path + '?TK=' + encodeURIComponent( accessToken );
    }

    /**
     * Returns the URL used by fileContainersGetFileAnonymous.
     * @param fileContainerId 
     */
    public fileContainersGetFileAnonymousUrl( fileContainerId: string,  ): string {
        const path: string = `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/latest/anonymous`;

        return path;
    }

    /**
     * Get File Container
     * 
     * @param fileContainerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersGetFileContainer(fileContainerId: string, observe?: 'body', reportProgress?: boolean): Observable<FileContainerItem>;
    public fileContainersGetFileContainer(fileContainerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileContainerItem>>;
    public fileContainersGetFileContainer(fileContainerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileContainerItem>>;
    public fileContainersGetFileContainer(fileContainerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileContainerId === null || fileContainerId === undefined) {
            throw new Error('Required parameter fileContainerId was null or undefined when calling fileContainersGetFileContainer.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<FileContainerItem>(
                `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Get File Version
     * 
     * @param fileContainerId 
     * @param fileVersionId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersGetFileVersion(fileContainerId: string, fileVersionId: string, observe?: 'body', reportProgress?: boolean): Observable<Blob>;
    public fileContainersGetFileVersion(fileContainerId: string, fileVersionId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Blob>>;
    public fileContainersGetFileVersion(fileContainerId: string, fileVersionId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Blob>>;
    public fileContainersGetFileVersion(fileContainerId: string, fileVersionId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileContainerId === null || fileContainerId === undefined) {
            throw new Error('Required parameter fileContainerId was null or undefined when calling fileContainersGetFileVersion.');
        }
        if (fileVersionId === null || fileVersionId === undefined) {
            throw new Error('Required parameter fileVersionId was null or undefined when calling fileContainersGetFileVersion.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/octet-stream',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get(
                `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/${encodeURIComponent(String(fileVersionId))}`,
                
                {
                    responseType: 'blob',
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }


    /**
     * Returns the URL used by fileContainersGetFileVersion including the user token.
     * @param fileContainerId 
     * @param fileVersionId 
     */
    public fileContainersGetFileVersionAuthenticatedUrl( fileContainerId: string, fileVersionId: string,  ): string {
        const path: string = `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/${encodeURIComponent(String(fileVersionId))}`;

        const accessToken: any = typeof this.configuration.accessToken === 'function'
            ? this.configuration.accessToken()
            : this.configuration.accessToken;

        return path + '?TK=' + encodeURIComponent( accessToken );
    }

    /**
     * Returns the URL used by fileContainersGetFileVersion.
     * @param fileContainerId 
     * @param fileVersionId 
     */
    public fileContainersGetFileVersionUrl( fileContainerId: string, fileVersionId: string,  ): string {
        const path: string = `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions/${encodeURIComponent(String(fileVersionId))}`;

        return path;
    }

    /**
     * Get File Versions
     * 
     * @param fileContainerId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersGetFileVersionItems(fileContainerId: string, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListFileVersionItem>;
    public fileContainersGetFileVersionItems(fileContainerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListFileVersionItem>>;
    public fileContainersGetFileVersionItems(fileContainerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListFileVersionItem>>;
    public fileContainersGetFileVersionItems(fileContainerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileContainerId === null || fileContainerId === undefined) {
            throw new Error('Required parameter fileContainerId was null or undefined when calling fileContainersGetFileVersionItems.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.get<ViewModelListFileVersionItem>(
                `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions`,
                
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Search
     * 
     * @param request 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersSearch(request: BrowsingConfiguration, observe?: 'body', reportProgress?: boolean): Observable<ViewModelBrowsingListFileContainerTile>;
    public fileContainersSearch(request: BrowsingConfiguration, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelBrowsingListFileContainerTile>>;
    public fileContainersSearch(request: BrowsingConfiguration, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelBrowsingListFileContainerTile>>;
    public fileContainersSearch(request: BrowsingConfiguration, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (request === null || request === undefined) {
            throw new Error('Required parameter request was null or undefined when calling fileContainersSearch.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/xml',
            'text/xml',
            'application/x-www-form-urlencoded',
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set( 'Content-Type', httpContentTypeSelected );
        }

        return this.httpClient.post<ViewModelBrowsingListFileContainerTile>(
                `${this.basePath}/api/v1/filemanagement/files/search`,
                request,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Create File Container(s)
     * 
     * @param fileKindId 
     * @param file1 
     * @param file2 
     * @param file3 
     * @param file4 
     * @param file5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersUploadMultipleFileContainers(fileKindId: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ViewModelListFileContainerItem>;
    public fileContainersUploadMultipleFileContainers(fileKindId: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ViewModelListFileContainerItem>>;
    public fileContainersUploadMultipleFileContainers(fileKindId: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ViewModelListFileContainerItem>>;
    public fileContainersUploadMultipleFileContainers(fileKindId: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileKindId === null || fileKindId === undefined) {
            throw new Error('Required parameter fileKindId was null or undefined when calling fileContainersUploadMultipleFileContainers.');
        }
        if (file1 === null || file1 === undefined) {
            throw new Error('Required parameter file1 was null or undefined when calling fileContainersUploadMultipleFileContainers.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm: boolean = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm: boolean = false;
        let convertFormParamsToString: boolean = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if ( useForm ) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file1 !== undefined) {
            formParams.append('file1', <any> file1);
        }
        if (file2 !== undefined) {
            formParams.append('file2', <any> file2);
        }
        if (file3 !== undefined) {
            formParams.append('file3', <any> file3);
        }
        if (file4 !== undefined) {
            formParams.append('file4', <any> file4);
        }
        if (file5 !== undefined) {
            formParams.append('file5', <any> file5);
        }

        return this.httpClient.post<ViewModelListFileContainerItem>(
                `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileKindId))}`,
                convertFormParamsToString ? formParams.toString() : formParams,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



    /**
     * Add File Version(s)
     * 
     * @param fileContainerId 
     * @param file1 
     * @param file2 
     * @param file3 
     * @param file4 
     * @param file5 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public fileContainersUploadMultipleFileVersions(fileContainerId: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ReplyListGuid>;
    public fileContainersUploadMultipleFileVersions(fileContainerId: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ReplyListGuid>>;
    public fileContainersUploadMultipleFileVersions(fileContainerId: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ReplyListGuid>>;
    public fileContainersUploadMultipleFileVersions(fileContainerId: string, file1: Blob, file2?: Blob, file3?: Blob, file4?: Blob, file5?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
        if (fileContainerId === null || fileContainerId === undefined) {
            throw new Error('Required parameter fileContainerId was null or undefined when calling fileContainersUploadMultipleFileVersions.');
        }
        if (file1 === null || file1 === undefined) {
            throw new Error('Required parameter file1 was null or undefined when calling fileContainersUploadMultipleFileVersions.');
        }

        let headers: HttpHeaders = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json',
            'text/json',
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data',
        ];

        const canConsumeForm: boolean = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm: boolean = false;
        let convertFormParamsToString: boolean = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if ( useForm ) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (file1 !== undefined) {
            formParams.append('file1', <any> file1);
        }
        if (file2 !== undefined) {
            formParams.append('file2', <any> file2);
        }
        if (file3 !== undefined) {
            formParams.append('file3', <any> file3);
        }
        if (file4 !== undefined) {
            formParams.append('file4', <any> file4);
        }
        if (file5 !== undefined) {
            formParams.append('file5', <any> file5);
        }

        return this.httpClient.post<ReplyListGuid>(
                `${this.basePath}/api/v1/filemanagement/files/${encodeURIComponent(String(fileContainerId))}/versions`,
                convertFormParamsToString ? formParams.toString() : formParams,
                {
                    headers: headers,
                    observe: observe,
                    reportProgress: reportProgress,
                },
            );
    }



}
