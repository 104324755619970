<div class="advanced-search-filter-group">
    <div class="advanced-search-filter-group--header">
        <div class="advanced-search-filter-group--match">
            <rsp-radio-button-group
                [label]="'Match'"
                [values]="matcherOptions"
                [selectedValue]="filterGroup.groupOperator"
                [name]="'advanced-search-filter_' + id + '_operator'"
                (selectedValueChange)="setFilterGroupOperator( $event )"
            ></rsp-radio-button-group>

            <span class="advanced-search-filter-group--match-appendix">of the following conditions:</span>
        </div>

        <div class="advanced-search-filter-group--actions">
            <rsp-button
                [type]="'button'"
                [submodule]="'seamless'"
                class="advanced-search-filter-group--add-button"
                title="Add Filter"
                (click)="addFilter()"
            >
                <span class="advanced-search-filter-group--add-button-icon"></span>
                <span class="advanced-search-filter-group--add-button-assistive-content">Add Filter</span>
            </rsp-button>

            <rsp-x-button
                [style.visibility]="noRemove ? 'hidden' : 'visible'"
                class="advanced-search-filter-group--delete-button"
                title="Remove Filter Group"
                (clicked)="emitRemoveEvent()"
            >
                Remove Filter Group
            </rsp-x-button>
        </div>
    </div>

    <rsp-advanced-search-filter-group-filter
        *ngFor="let filter of filterGroup.filters; let index = index"
        [filter]="filter"
        [otherFilters]="filterGroup.filters"
        [filterFields]="filterFields"
        [noRemove]="filterGroup.filters.length === 1"
        (onRemove)="removeFilter( index )"
        (onSubmit)="emitSubmitEvent()"
    ></rsp-advanced-search-filter-group-filter>
</div>
