import { Component, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { DialogService } from '../../../../../../../core/overlay/dialog/dialog.service';
import { ArticleManagementAssembliesService } from '../../../../../../../shared/api/articleManagementAssemblies.service';
import { NonTradeListItem } from '../../../../../../../shared/model/nonTradeListItem';
import { NonTradeItemSuggestSearchConfiguration } from '../../../../../../../shared/model/nonTradeItemSuggestSearchConfiguration';
import { NonTradeSuggestItem } from '../../../../../../../shared/model/nonTradeSuggestItem';
import { ViewModelListAssemblyComponent } from '../../../../../../../shared/model/viewModelListAssemblyComponent';
import { AssemblyComponent, Reply } from '../../../../../../../shared/model';
import { Component as ComponentDto } from '../../../../../../../shared/model/component';
import { NonTradeItemSelectionService } from '../../../../shared/non-trade-item-selection.service';
import { MessageType } from '../../../../../../../shared/ui/messages/messages.component';
import { NotificationService } from '../../../../../../../core/overlay/notification/notification.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component( {
    selector:    'rsp-remove-selection-from-assembly-dialog',
    templateUrl: './remove-selection-from-assembly-dialog.component.html',
    styleUrls:   [
        '../../../../../../../shared/scss/05_module/standard-dialog.scss',
        '../../../../../../../shared/scss/05_module/simple-list.scss',
        './remove-selection-from-assembly-dialog.component.scss',
    ],
} )
export class RemoveSelectionFromAssemblyDialogComponent implements OnDestroy {
    @ViewChild( 'dialogHeader', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContent', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooter', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    remainingComponents: AssemblyComponent[] = [];
    messageType: MessageType;
    messages: string[]                       = [];

    form: UntypedFormGroup;
    isLoading: Subscription;
    articleType: NonTradeListItem.TypeEnum                                    = NonTradeListItem.TypeEnum.Article;
    assemblyType: NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum = NonTradeItemSuggestSearchConfiguration.NonTradeItemTypeEnum.Assembly;
    messageTypeEnum: any                                                      = MessageType;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dialogService: DialogService,
        private notificationService: NotificationService,
        private nonTradeItemSelectionService: NonTradeItemSelectionService,
        private articleManagementAssembliesService: ArticleManagementAssembliesService,
    ) {
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    setAssemblyToRemoveComponentsFrom( assembly: NonTradeSuggestItem ): void {
        this.form.get( 'targetAssembly' ).setValue( assembly );

        if ( !assembly ) {
            this.messages = [];
            return;
        }

        this.isLoading = this.articleManagementAssembliesService
                             .assembliesGetComponents( assembly.id )
                             .pipe(
                                 map( ( viewModelListAssemblyComponent: ViewModelListAssemblyComponent ) => viewModelListAssemblyComponent.data ),
                                 takeUntil( this.isDestroyed ),
                             )
                             .subscribe( ( assemblyComponents: AssemblyComponent[] ) => {
                                 const articlesToRemove: NonTradeListItem[] = this.nonTradeItemSelectionService.getSelectedArticles();

                                 this.remainingComponents = assemblyComponents.filter( ( component: AssemblyComponent ) => {
                                     return articlesToRemove.some( ( article: NonTradeListItem ) => article.id === component.id ) ? false : true;
                                 } );

                                 if ( this.remainingComponents.length === assemblyComponents.length ) {
                                     this.messageType = MessageType.Error;
                                     this.messages    = [ 'None of your selected articles is currently assigned to this assembly.' ];
                                 }
                                 else {
                                     const numberOfArticlesThatWillBeRemoved: number = assemblyComponents.length - this.remainingComponents.length;
                                     this.messageType                                = MessageType.Info;
                                     this.messages                                   = [
                                         numberOfArticlesThatWillBeRemoved +
                                         ' of your selected articles ' +
                                         (numberOfArticlesThatWillBeRemoved === 1 ? 'is' : 'are') +
                                         ' currently assigned to this assembly and will be removed.',
                                     ];
                                 }
                             } );
    }

    doAction(): void {
        const components: ComponentDto[] = this.remainingComponents
                                               .map( ( assemblyComponent: AssemblyComponent ) => {
                                                   const component: ComponentDto = {
                                                       articleId: assemblyComponent.id,
                                                       quantity:  assemblyComponent.quantity,
                                                   };
                                                   return component;
                                               } );

        const assemblyToRemoveComponentsFrom: NonTradeSuggestItem = this.form.get( 'targetAssembly' ).value;

        this.isLoading = this.articleManagementAssembliesService
                             .assembliesUpdateComponents( assemblyToRemoveComponentsFrom.id, components )
                             .pipe( takeUntil( this.isDestroyed ) )
                             .subscribe( ( response: Reply ) => {
                                 this.notificationService.success(
                                     `The assembly ${ assemblyToRemoveComponentsFrom.number } - ${ assemblyToRemoveComponentsFrom.name } has now ` +
                                     `${ components.length } components.`,
                                     'Components removed',
                                 );
                                 this.close();
                             } );
    }

    open(): void {
        this.form = new UntypedFormGroup( {
            targetAssembly: new UntypedFormControl( null, Validators.required ),
        } );

        this.dialogService.openDialog( {
            contentTemplate:  this.dialogContentTemplate,
            headlineTemplate: this.dialogHeaderTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        } );
    }

    close(): void {
        this.dialogService.closeDialog();
    }
}
