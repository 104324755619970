import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationStart, Router, RouterEvent } from '@angular/router';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { PageTitleService } from '@nureg/angular-page-title';

import { WindowEventService } from './core/window-event.service';
import { MainMenuService } from './core/main-menu/main-menu.service';
import { mainMenuAnimations } from './core/main-menu/main-menu.animations';
import { AuthenticationService } from './core/authentication.service';


@Component( {
    selector:    'rsp-root',
    templateUrl: './app.component.html',
    styleUrls:   [
        './app.component.scss',
    ],
    animations:  mainMenuAnimations,
} )
export class AppComponent implements OnInit {
    isMainMenuOpen: Observable<boolean>;

    isLoggedIn: boolean = true;

    showLogoutMessage: boolean = false;

    constructor(
        private mainMenuService: MainMenuService,
        private windowEventService: WindowEventService,
        private pageTitleService: PageTitleService,
        private authenticationService: AuthenticationService,
        private router: Router,
    ) {
        this.isMainMenuOpen = mainMenuService.isOpen$;

        this.router
            .events
            .pipe( filter( ( event: RouterEvent ) => event instanceof NavigationStart ) )
            .subscribe( ( event: RouterEvent ) => {
                if ( event.url === '/logout' ) {
                    this.showLogoutMessage = true;
                }
            } );

        this.authenticationService
            .isLoggedIn$
            .subscribe( ( isLoggedIn: boolean ) => {
                this.isLoggedIn = isLoggedIn;
            } );
    }

    ngOnInit(): void {
        this.pageTitleService.updateTitleOnRouteChange();
    }

    @HostListener( 'window:focus' )
    onFocus(): void {
        this.windowEventService.notifyWindowFocused();
    }

    @HostListener( 'window:blur' )
    onBlur(): void {
        this.windowEventService.notifyWindowBlurred();
    }

    @HostListener( 'window:resize' )
    onResize(): void {
        this.windowEventService.notifyWindowResized();
    }

}
