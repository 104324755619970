<table *ngIf="( !isEditMode && capacities != null && capacities.length > 0 )">
    <thead hidden>
        <tr>
            <th>Count</th>
            <th>Type</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let capacity of capacities">
            <td class="is-right-aligned">{{ capacity.pieces }}</td>
            <td>{{ capacity.name }}</td>
        </tr>
    </tbody>
</table>

<rsp-edit-capacities
    *ngIf="isEditMode"
    [myFormGroup]="myFormGroup"
    [capacities]="capacities"
>
</rsp-edit-capacities>

<ng-container *ngIf="!isEditMode && ( capacities == null || capacities?.length === 0 ) && showNotAvailable">
    <rsp-not-available [notAvailable]="notAvailableString"></rsp-not-available>
</ng-container>

