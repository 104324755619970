import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';

import { BaseStoreService, StoreSearchConfiguration } from '../../../../shared/ui/lists/store/base-store.service';
import { ConceptOrClusterItem } from '../../../../shared/model';
import { ConceptClusterService } from './concept-cluster.service';
import { ViewModelBrowsingListConceptOrClusterItem } from '../../../../shared/model';
import { ArticleManagementConceptsOrClustersService } from '../../../../shared/api';


// TODO: rename "store" to "storage"

/**
 * Handles loading and caching for Concepts
 */
@Injectable()
export class ConceptClusterStoreService extends BaseStoreService<ConceptOrClusterItem> {

    constructor(
        private conceptsOrClusterApi: ArticleManagementConceptsOrClustersService,
        private conceptClusterService: ConceptClusterService,
    ) {
        super();
    }

    protected getId( item: ConceptOrClusterItem ): string {

        if ( !item ) {
            throw new Error( '[ConceptClusterStoreService] item must not be null' );
        }

        return this.conceptClusterService.getConceptOrClusterIdFromConceptOrClusterItem( item );
    }

    protected loadItemsFromBackend( request: StoreSearchConfiguration ): Subscription {

        return this.conceptsOrClusterApi
                   .conceptsOrClustersSearch( request )
                   .subscribe(
                       ( result: ViewModelBrowsingListConceptOrClusterItem ) => {
                           super.setBackendResult(
                               result.data,
                               result.paging,
                               result.facets,
                               result.selectLists,
                               result.advancedSearchFilterTypeOperators,
                               result.advancedSearchFilterProperties,
                               result.advancedSearchFilterGroups,
                               result.availableSortings,
                               result.usedSortings,
                               result.quickSearchTerm,
                           );
                       },
                       () => {
                           super.unregisterLoadingObservable();
                       } );
    }
}
