<ng-template #headline>
    <h2 class="standard-dialog--headline">Create new Article</h2>
</ng-template>

<ng-template #content>
    <div class="standard-dialog--button" [rspLoadingIndicator]="isLoading">
        <rsp-edit-text
            [myFormControl]="articleNameControl"
            [placeholder]="'Article Name'"
        >
        </rsp-edit-text>
        <rsp-button
            [type]="'submit'"
            [submodule]="'primary'"
            (clicked)="doAction()"
            [disabled]="!articleNameControl.valid"
            style="margin-left: 1em"
        >
            Create
        </rsp-button>
    </div>
</ng-template>

<ng-template #footer>
</ng-template>
