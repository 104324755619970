<a
    #myTile
    class="concept-cluster-line"
    [class.is-concept-cluster-line-checked]="isSelected"
    [routerLink]="routerLink"
    (click)="setIndex()"
>
    <div
        *ngIf="isSelectionAvailable | async"
        class="concept-cluster-line--checkbox-container"
    >
        <input
            id="{{ conceptOrCluster.id }}"
            type="checkbox"
            rspCheckbox
            [rspCheckboxWrapperClass]="'concept-cluster-line--checkbox-wrapper'"
            [checked]="isSelected"
            (click)="toggleSelection( $event )"
        >
    </div>

    <div class="concept-cluster-line--name">
        <ng-container *ngIf="type === typeEnum.Cluster">
            <rsp-supplementary-caption>
                {{ conceptOrCluster.concept.name }}
            </rsp-supplementary-caption>
            {{ conceptOrCluster.name }}
        </ng-container>
        <ng-container *ngIf="type === typeEnum.Concept">
            <rsp-supplementary-caption>
                Concept
            </rsp-supplementary-caption>
            {{ conceptOrCluster.name }}
        </ng-container>
    </div>

    <div class="concept-cluster-line--additional-data">
        <ng-container *ngIf="type === typeEnum.Concept">
            <div class="concept-cluster-line--additional-data-content">
                <rsp-supplementary-caption>
                    cluster{{ conceptOrCluster.clusters !== 1 ? 's' : '' }}
                </rsp-supplementary-caption>
                {{ conceptOrCluster.clusters }}</div>
        </ng-container>
        <ng-container *ngIf="type === typeEnum.Cluster">
            <div class="concept-cluster-line--additional-data-content">
                <rsp-supplementary-caption>
                    Ordering
                </rsp-supplementary-caption>
                {{ conceptOrCluster.isAvailableToOrder ? 'yes' : 'no' }}
            </div>
            <div class="concept-cluster-line--additional-data-content">
                <rsp-supplementary-caption>
                    Forecasting
                </rsp-supplementary-caption>
                {{ conceptOrCluster.isAvailableToForecast ? 'yes' : 'no' }}
            </div>

            <ng-container *ngIf="conceptOrCluster.articles">
                <div class="concept-cluster-line--additional-data-content">
                    <rsp-supplementary-caption>
                        article{{ conceptOrCluster.articles !== 1 ? 's' : '' }}
                    </rsp-supplementary-caption>
                    {{ conceptOrCluster.articles }}
                </div>
            </ng-container>

            <ng-container *ngIf="!conceptOrCluster.articles">
                <div class="concept-cluster-line--additional-data-content">
                    <rsp-supplementary-caption>
                        articles
                    </rsp-supplementary-caption>
                    0
                </div>

            </ng-container>

            <ng-container *ngIf="conceptOrCluster.assemblies">
                <div class="concept-cluster-line--additional-data-content">
                    <rsp-supplementary-caption>
                        assembl{{ conceptOrCluster.assemblies !== 1 ? 'ies' : 'y' }}
                    </rsp-supplementary-caption>
                    {{ conceptOrCluster.assemblies }}
                </div>
            </ng-container>

            <ng-container *ngIf="!conceptOrCluster.assemblies">
                <div class="concept-cluster-line--additional-data-content">
                    <rsp-supplementary-caption>
                        assemblies
                    </rsp-supplementary-caption>
                    0
                </div>
            </ng-container>
        </ng-container>
    </div>

    <rsp-status
        class="concept-cluster-line--status"
        [displayMode]="'badge'"
        [status]="conceptOrCluster.status"
        [type]="type"
    ></rsp-status>
</a>
