interface StorageKeys {

    common: {
        masterHead: {
            viewSwitcher: {
                viewKind: string;
            },
        },
    };

    companies: {
        list: {
            isSidebarVisible: string,
            scrollToId: string,
        },
    };

    concepts: {
        list: {
            isSidebarVisible: string,
            scrollToId: string,
        },
    };

    documents: {
        list: {
            isSidebarVisible: string;
            scrollToId: string;
        },
    };

    forecasts: {
        list: {
            isSidebarVisible: string,
            viewSwitcher: {
                viewKind: string,
            },
            // scrollToId: string,
        },
    };

    locations: {
        list: {
            isSidebarVisible: string,
            scrollToId: string,
        },
    };

    nonTradeItems: {
        list: {
            isSidebarVisible: string;
            scrollToId: string;
        },

    };

    orders: {
        list: {
            isSidebarVisible: string;
            scrollToId: string;
        },
    };

    pbls: {
        list: {
            isSidebarVisible: string;
            scrollToId: string;
        },
    };

    users: {
        list: {
            isSidebarVisible: string;
            scrollToId: string;
        },
    };
}


/**
 * Common place for all keys used by the StorageService.
 */
export const STORAGE_KEYS: StorageKeys = {

    common: {
        masterHead: {
            viewSwitcher: {
                viewKind: 'common.masterHead.viewSwitcher.viewKind',
            },
        },
    },

    companies: {
        list: {
            isSidebarVisible: 'companies.list.isSidebarVisible',
            scrollToId:       'companies.list.scrollToId',
        },
    },

    concepts: {
        list: {
            isSidebarVisible: 'concepts.list.isSidebarVisible',
            scrollToId:       'concepts.list.scrollToId',
        },
    },

    documents: {
        list: {
            isSidebarVisible: 'documents.list.isSidebarVisible',
            scrollToId:       'documents.list.scrollToId',
        },
    },

    forecasts: {
        list: {
            isSidebarVisible: 'forecasts.list.isSidebarVisible',
            viewSwitcher: {
                viewKind: 'forecasts.list.viewSwitcher.viewKind',
            },
            // scrollToId:       'forecasts.list.scrollToId',
        },
    },

    locations: {
        list: {
            isSidebarVisible: 'locations.list.isSidebarVisible',
            scrollToId:       'locations.list.scrollToId',
        },
    },

    nonTradeItems: {
        list: {
            isSidebarVisible: 'nonTradeItems.list.isSidebarVisible',
            scrollToId:       'nonTradeItems.list.scrollToId',
        },

    },

    orders: {
        list: {
            isSidebarVisible: 'orders.list.isSidebarVisible',
            scrollToId:       'orders.list.scrollToId',
        },
    },

    pbls: {
        list: {
            isSidebarVisible: 'pbls.list.isSidebarVisible',
            scrollToId:       'pbls.list.scrollToId',
        },
    },

    users: {
        list: {
            isSidebarVisible: 'users.list.isSidebarVisible',
            scrollToId:       'users.list.scrollToId',
        },
    },
};
