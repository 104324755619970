<a
    *ngIf="isLink;"
    [class]="'user-tile'"
    [class.is-user-tile-checked]="isSelected"
    [routerLink]="[ '/user', item.id ]"
    (click)="setIndex()"
>
    <ng-template [ngTemplateOutlet]="innerTile"></ng-template>
</a>

<div
    *ngIf="!isLink"
    class="user-tile is-link-deactivated"
>
    <ng-template [ngTemplateOutlet]="innerTile"></ng-template>
</div>


<ng-template #innerTile>
    <article class="user-tile--inner">
        <div class="user-tile--main">
            <h3 class="user-tile--name">
                <span class="user-tile--first-name">{{ item.firstName }}</span>
                <span class="user-tile--family-name">{{ item.familyName }}</span>
            </h3>

            <div class="user-tile--profile-image">
                <rsp-user-profile-image [user]="item"></rsp-user-profile-image>
            </div>

            <div class="user-tile--status">
                <rsp-status
                    [status]="item.status"
                    [type]="statusEnumType"
                    [displayMode]="'tag'"
                ></rsp-status>
            </div>

        </div>

        <footer class="user-tile--footer">
            <div
                class="user-tile--company"
                [class.is-internal-user]="item.type === userTypeEnum.Internal"
            >
                {{ userType }}
            </div>
        </footer>
    </article>
</ng-template>
