import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ObjectNotFoundPageComponent } from './core/object-not-found-page/object-not-found-page.component';


const APP_ROUTES: Routes = [
    {
        path: '', redirectTo: 'dashboard', pathMatch: 'full',
    },
    {
        path: 'logout', redirectTo: '', pathMatch: 'full',
    },
    {
        path: 'object-not-found', component: ObjectNotFoundPageComponent,
    },

    // modules to lazy load:
    {
        path:         'administration',

        /* tslint:disable-next-line */ // we have to maintain exact the correct format, otherwise Angular will not recognize the lazy loading
        loadChildren: () => import( './features/administration/adminstration.module' ).then( ( m ) => m.AdministrationModule ),
    },
    {
        path:         'styleguide',

        /* tslint:disable-next-line */ // we have to maintain exact the correct format, otherwise Angular will not recognize the lazy loading
        loadChildren: () => import( './features/style-guide/style-guide.module' ).then( ( m ) => m.StyleGuideModule ),
    },
];


@NgModule( {
    imports: [
        RouterModule.forRoot(
            APP_ROUTES,
            {
    initialNavigation: 'disabled',
},
        ),
    ],
    exports: [ RouterModule ],
} )
export class AppRoutingModule {
}

@NgModule(
    {
        imports: [ RouterModule.forChild( [
            {
                path: '**', redirectTo: 'dashboard',
            },
        ] ) ],
        exports: [ RouterModule ],
    },
)
export class NotFoundRoutingModule {
}
