import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControlName, UntypedFormGroup } from '@angular/forms';


@Component( {
    selector: 'rsp-edit-distribution-channel',
    template: `
                  <span [formGroup]="myFormGroup">
                      <input formControlName="isActive" rspCheckbox type="checkbox" (change)="toggleDistributionChannel()" >
                  </span>`,
    styleUrls: [
    ],
} )
export class EditDistributionChannelComponent {
    @Input() myFormGroup: UntypedFormGroup;

    @Output() activeChanged: EventEmitter<boolean> = new EventEmitter();

    @ViewChild( FormControlName, { static: true } ) fcn: FormControlName;

    toggleDistributionChannel(): void {
        this.activeChanged.emit( this.fcn.control.value );
    }
}
