import { Component, ElementRef, EmbeddedViewRef, HostBinding, OnDestroy, OnInit, TemplateRef, ViewChild, ViewContainerRef, } from '@angular/core';
import { Subscription ,  fromEvent } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

import { PopupConfig, PopupService } from './popup.service';
import { WindowEventService } from '../../window-event.service';
import { OverlayPositionCoordinates, OverlayPositionService } from '../overlay-position.service';


/**
 * Renders popups, f.e. Dropdown Lists. It appends the popup very high in the DOM hierarchy,
 * so the popup overlaps other containers and does not trigger overflow.
 */
@Component( {
    selector:    'rsp-popup',
    templateUrl: './popup.component.html',
    styleUrls:   [
        './popup.component.scss',
    ],
} )
export class PopupComponent implements OnInit, OnDestroy {

    @ViewChild( 'target', { read: ViewContainerRef, static: true } ) target: ViewContainerRef;

    @HostBinding( 'style.top.px' ) top: number        = 0;
    @HostBinding( 'style.left.px' ) left: number      = -1000;
    @HostBinding( 'style.display' ) display: string   = 'none';
    @HostBinding( 'class.is-above' ) isAbove: boolean = false;

    public config: PopupConfig;

    private viewRef: EmbeddedViewRef<any>;
    private scrollContainer: HTMLElement;

    private scrollSubscription: Subscription;
    private subscription: Subscription = new Subscription();

    constructor(
        private popupContainer: ElementRef,
        private popupService: PopupService,
        private windowEventService: WindowEventService,
        private overlayPositionService: OverlayPositionService,
    ) {
    }

    ngOnInit(): void {

        this.subscription.add(
            this.popupService
                .popupConfig$
                .subscribe( ( config: PopupConfig ) => {
                    if ( config ) {
                        this.showPopup( config );
                    }
                    else {
                        this.hidePopup();
                    }
                } ),
        );

        this.subscription.add(
            this.popupService
                .calculatePosition$
                .subscribe( () => {
                    setTimeout( () => {
                        this.calculatePopupPosition();
                    } );
                } ),
        );

        // window resized -> calculate new popup position
        this.subscription.add(
            this.windowEventService
                .resized$
                .subscribe( () => this.onWindowResize() ),
        );

        // window lost focus -> close popup
        this.subscription.add(
            this.windowEventService
                .blurred$
                .subscribe( () => this.hidePopup() ),
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();

        if ( this.scrollSubscription ) {
            this.scrollSubscription.unsubscribe();
        }
    }

    onClickOutSide( event: MouseEvent ): void {
        if ( !this.config || !this.config.triggerElement ) {
            return;
        }

        if ( !this.clickedOnTriggerElement( event ) ) {
            if ( this.config.autoClose ) {
                this.hidePopup();
            }
            else if ( this.isContainerVisible() ) {
                this.popupService.notifyClickOutside( event.target as HTMLElement, this.config.triggerElement );
            }
        }
    }

    onWindowResize(): void {
        this.calculatePopupPosition();
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private showPopup( config: PopupConfig ): void {

        if ( this.isContainerVisible() ) {
            // user want to open dropdown, while another dropdown is open.
            this.hidePopup();
        }

        this.config = config;

        this.makePopupContainerVisible();

        this.renderTemplate( config.template );

        this.setFocusOnContentDiv();

        setTimeout( () => { this.calculatePopupPosition(); } );

        this.handleParentContainerScrolling();

        this.popupService.popupOpened$.emit( this.config.triggerElement );
    }


    private hidePopup(): void {

        this.config = null;

        this.left = -1000;

        this.makeContainerInvisible();

        this.destroyTemplate();

        if ( this.scrollSubscription ) {
            this.scrollSubscription.unsubscribe();
        }

        this.popupService.popupClosed$.emit( null );
    }


    private makePopupContainerVisible(): void {
        this.display = 'block';
    }


    private makeContainerInvisible(): void {
        this.display = 'none';
    }


    private isContainerVisible(): boolean {
        return this.display === 'block';
    }


    private renderTemplate( template: TemplateRef<any> ): void {

        this.viewRef = this.target.createEmbeddedView( template );
    }


    private destroyTemplate(): void {
        if ( this.viewRef ) {
            this.viewRef.destroy();
        }
    }


    private handleParentContainerScrolling(): void {

        // remove old subscription
        if ( this.scrollSubscription ) {
            this.scrollSubscription.unsubscribe();
        }

        // search for next scrollable container
        this.scrollContainer = this.findNextScrollContainer( this.config.triggerElement.nativeElement );
        if ( !this.scrollContainer ) {
            console.warn( `${ this.constructor.name }: Can't find scroll container.` );
        }

        if ( this.scrollContainer ) {

            // update popup position when scroll event occurs
            this.scrollSubscription
                = fromEvent( this.scrollContainer, 'scroll' )
                            .pipe( distinctUntilChanged() )
                            .subscribe( () => {
                                this.calculatePopupPosition();
                            } );
        }
    }


    private findNextScrollContainer( sourceElement: any ): HTMLElement {

        let scrollContainerNode: HTMLElement = sourceElement;
        let overflowValue: string;

        do {
            scrollContainerNode = scrollContainerNode.parentElement as HTMLElement;

            if ( scrollContainerNode ) {
                overflowValue = window.getComputedStyle( scrollContainerNode ).overflowY;
                if ( overflowValue === 'scroll' || overflowValue === 'auto' ) {
                    break;
                }
            }
        } while ( scrollContainerNode.parentElement );

        return scrollContainerNode;
    }


    private calculatePopupPosition(): void {

        if ( !this.config || !this.config.triggerElement ) {
            return;
        }

        // close popup when trigger element is outside scroll area
        if ( this.scrollContainer
            && !this.isInsideScrollArea( this.config.triggerElement.nativeElement.getBoundingClientRect(), this.scrollContainer.getBoundingClientRect() ) ) {

            this.hidePopup();
            return;
        }

        const overlayPosition: OverlayPositionCoordinates =
            this.overlayPositionService.getOverlayPosition( this.popupContainer.nativeElement, this.config.triggerElement.nativeElement );

        this.isAbove = overlayPosition.aboveTriggerElement;
        this.top     = overlayPosition.top;
        this.left    = overlayPosition.left;
    }


    private getInput( element: HTMLElement ): HTMLElement | null {

        const length: number = element.children.length;
        if ( length ) {
            for ( let i: number = 0; i < length; i++ ) {
                if ( element.children[ i ].children && element.children[ i ].children.length ) {
                    return this.getInput( element.children[ i ] as HTMLElement );
                }
                if ( element.children[ i ].localName === 'input' ) {
                    return element.children[ i ] as HTMLElement;
                }
            }
        }

        return null;
    }

    private setFocusOnContentDiv(): void {

        if ( !this.viewRef ) {
            return;
        }

        this.viewRef.rootNodes.forEach( ( node: Node ) => {

            if ( node.nodeName.toLowerCase() === 'div' ) {
                const div: HTMLElement = node as HTMLElement;

                setTimeout( () => {
                    const input: HTMLElement = this.getInput( div );

                    if ( input ) {
                        input.focus();
                    }
                } );
            }
        } );
    }


    private clickedOnTriggerElement( event: MouseEvent ): boolean {
        return this.config.triggerElement.nativeElement.contains( event.target );
    }

    /**
     * Checks if element is inside scrolled container.
     */
    private isInsideScrollArea( elementClientRect: ClientRect, containerClientRect: ClientRect ): boolean {

        return elementClientRect.bottom > containerClientRect.top
            && elementClientRect.top < containerClientRect.bottom;
    }
}
