<div *ngIf="config | async"
     class="prev-next-navigation">

    <!-- label -->
    <span class="prev-next-navigation--label">
        item:
    </span>

    <!-- arrow < -->
    <span class="prev-next-navigation--arrow-prev-wrapper">
        <button *ngIf="( config | async )?.hasPrevItem"
                type="button"
                class="prev-next-navigation--arrow-prev"
                (click)="prevButtonClicks.next()"
        ></button>
    </span>

    <!-- value -->
    <span class="prev-next-navigation--value">
        {{
            ( config | async )?.currentItemIndex + 1 + itemsToSkipCount > 0
                ? ( config | async )?.currentItemIndex + 1 + itemsToSkipCount
                :  0
        }} of {{ ( config | async )?.totalCount || 0 }}
    </span>

    <!-- arrow > -->
    <span class="prev-next-navigation--arrow-next-wrapper">
        <button *ngIf="( config | async )?.hasNextItem"
                type="button"
                class="prev-next-navigation--arrow-next"
                (click)="nextButtonClicks.next()"
        ></button>
    </span>
</div>
