import { BehaviorSubject ,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class SecondaryToolbarService {
    /**
     * Specifies if View-Switcher should be visible on the toolbar.
     */
    isViewSwitcherVisible$: Observable<boolean>;

    /**
     * Specifies if StoreInfo component should be visible on the toolbar.
     */
    isStoreInfoVisible$: Observable<boolean>;

    /**
     * Specifies if PrevNextNavigation component should be visible on the toolbar.
     */
    isPrevNextNavigationVisible$: Observable<boolean>;

    isSortByVisible$: Observable<boolean>;

    private isViewSwitcherVisible: BehaviorSubject<boolean>       = new BehaviorSubject( true );
    private isSortByVisible: BehaviorSubject<boolean>             = new BehaviorSubject( true );
    private isStoreInfoVisible: BehaviorSubject<boolean>          = new BehaviorSubject( false );
    private isPrevNextNavigationVisible: BehaviorSubject<boolean> = new BehaviorSubject( false );


    constructor() {
        this.isViewSwitcherVisible$       = this.isViewSwitcherVisible.asObservable();
        this.isStoreInfoVisible$          = this.isStoreInfoVisible.asObservable();
        this.isPrevNextNavigationVisible$ = this.isPrevNextNavigationVisible.asObservable();
        this.isSortByVisible$             = this.isSortByVisible.asObservable();
    }

    /**
     * Shows ViewSwitcher component on secondary-toolbar.
     */
    showViewSwitcher(): void {
        this.isViewSwitcherVisible.next( true );
    }


    /**
     * Hides ViewSwitcher component on secondary-toolbar.
     */
    hideViewSwitcher(): void {
        this.isViewSwitcherVisible.next( false );
    }


    /**
     * Shows StoreInfo component on secondary-toolbar.
     */
    showStoreInfo(): void {
        this.isStoreInfoVisible.next( true );
    }


    /**
     * Hides StoreInfo component on secondary-toolbar.
     */
    hideStoreInfo(): void {
        this.isStoreInfoVisible.next( false );
    }


    /**
     * Shows PrevNextNavigation component on secondary-toolbar.
     */
    showPrevNextNavigation(): void {
        this.isPrevNextNavigationVisible.next( true );
    }


    /**
     * Hides PrevNextNavigation component on secondary-toolbar.
     */
    hidePrevNextNavigation(): void {
        this.isPrevNextNavigationVisible.next( false );
    }

    /**
     * Shows SortBy on secondary-toolbar.
     */
    showSortBy(): void {
        this.isSortByVisible.next( true );
    }

    /**
     * Hides SortBy on secondary-toolbar.
     */
    hideSortBy(): void {
        this.isSortByVisible.next( false );
    }

}
