import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserSelectionService } from '../shared/user-selection.service';
import { StoreInfo } from '../../../../shared/ui/lists/store/store-info.model';
import { UserStoreService } from '../shared/user-store.service';
import { UserListItem } from '../../../../shared/model';
import { SecondaryToolbarService } from '../../../../shared/ui/secondary-toolbar/secondary-toolbar.service';
import { ToolbarBaseComponent } from '../../../../shared/ui/secondary-toolbar/toolbar-base.component';
import { MainSlideInService } from '../../../../core/overlay/main-slide-in/main-slide-in.service';


@Component( {
    selector:    'rsp-user-toolbar',
    templateUrl: './user-toolbar.component.html',
    styleUrls:   [
        '../../../../shared/scss/05_module/secondary-toolbar.scss',
        './user-toolbar.component.scss',
    ],
} )
export class UserToolbarComponent extends ToolbarBaseComponent {
    storeInfo: Observable<StoreInfo>;

    selectedItems: Observable<Array<UserListItem>>;

    constructor(
        protected storeService: UserStoreService,
        private selectionService: UserSelectionService,
        toolbarService: SecondaryToolbarService,
        router: Router,
        activatedRoute: ActivatedRoute,
        mainSlideInService: MainSlideInService,
    ) {
        super( storeService, router, activatedRoute, mainSlideInService, toolbarService );

        this.storeInfo     = this.storeService.storeInfo$;
        this.selectedItems = this.selectionService.selectedItems$;
    }

    clearSelection(): void {
        this.selectionService.clearSelection();
    }
}
