import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { FileManagementFilesService } from '../../../api';
import { CollectionView } from '../../tables/collection-view';
import { StandardFilters } from '../../tables/filters/standard-filters';
import { FileVersionCollectionViewItem } from './file-version-collection-view-item.model';
import { FileVersionItem, ViewModelListFileVersionItem } from '../../../model';
import { DialogConfig, DialogService } from '../../../../core/overlay/dialog/dialog.service';
import { takeUntil } from 'rxjs/operators';


/**
 * Typical use case:
 * ```
 *     <rsp-file-table
 *         [files]="fileContainerArray"
 *         [readOnly]="false"
 *         (fileAdded)="doSomethingWithNewFileContainer( $event )"
 *         (fileRemoved)="doSomethingWithRemovedFileContainerId( $event )"
 *     ></rsp-file-table>
 * ```
 */
@Component( {
    selector:    'rsp-file-version-dialog',
    templateUrl: './file-version-dialog.component.html',
    styleUrls:   [
        '../../../scss/05_module/standard-dialog.scss',
        './file-version-dialog.component.scss',
    ],
} )
export class FileVersionDialogComponent implements OnInit, OnDestroy {
    @ViewChild( 'dialogHeaderTemplate', { read: TemplateRef, static: true } ) dialogHeaderTemplate: TemplateRef<any>;
    @ViewChild( 'dialogContentTemplate', { read: TemplateRef, static: true } ) dialogContentTemplate: TemplateRef<any>;
    @ViewChild( 'dialogFooterTemplate', { read: TemplateRef, static: true } ) dialogFooterTemplate: TemplateRef<any>;

    fileContainerId: string;
    files: FileVersionItem [];
    collectionView: CollectionView<FileVersionCollectionViewItem>;

    isLoading: Subscription;

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private dialogService: DialogService,
        private filesApi: FileManagementFilesService,
        private standardFilters: StandardFilters,
    ) {
    }


    ngOnInit(): void {
        this.collectionView = new CollectionView<FileVersionCollectionViewItem>([]);
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    open( fileContainerId: string ): void {

        this.fileContainerId = fileContainerId;

        this.isLoading = this.filesApi
                             .fileContainersGetFileVersionItems( this.fileContainerId )
                             .pipe( takeUntil( this.isDestroyed ) )
                             .subscribe( ( result: ViewModelListFileVersionItem ) => {
                                 this.files = result.data;

                                 if ( this.files && this.files.length > 0 ) {
                                     this.collectionView.setSourceItems(
                                         this.files.map( ( file: FileVersionItem ) => {
                                             return new FileVersionCollectionViewItem( file );
                                         } ) ).refresh();
                                 }
                                 else {
                                     this.collectionView.setSourceItems( [] );
                                 }

                                 this.initializeTableFeatures();

                             } );

        const dialogConfig: DialogConfig = {
            headlineTemplate: this.dialogHeaderTemplate,
            contentTemplate:  this.dialogContentTemplate,
            footerTemplate:   this.dialogFooterTemplate,
            withBackdrop:     true,
        };

        this.dialogService.openDialog( dialogConfig );
    }


    close(): void {
        this.dialogService.closeDialog();
    }

    private initializeTableFeatures(): void {

        // filter function
        this.collectionView.setGlobalFilterFunction( ( item: FileVersionCollectionViewItem, filter: string ) => {

            if ( typeof filter === 'undefined' || filter === null ) {
                return true;
            }

            if ( this.standardFilters.contains( item.name, filter )
                || this.standardFilters.contains( item.uploadedOn, filter ) ) {
                return true;
            }

            return false;
        } );
    }
}
