import { AfterViewInit, Component, ElementRef, EmbeddedViewRef, HostBinding, OnDestroy, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';
import { Subject } from 'rxjs';
import { distinct, filter, takeUntil } from 'rxjs/operators';

import { DialogConfig, DialogService } from './dialog.service';
import { WindowEventService } from '../../window-event.service';
import { BackdropService } from '../backdrop/backdrop.service';
import { OverlayPositionCoordinates, OverlayPositionService } from '../overlay-position.service';

/**
 * Don't use this component directly. If you want to create new dialogs, use the `DialogService`.
 */
@Component( {
    selector:    'rsp-dialog',
    templateUrl: './dialog.component.html',
    styleUrls:   [ './dialog.component.scss' ],
} )
export class DialogComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild( 'target', { read: ViewContainerRef, static: true } ) target: ViewContainerRef;
    @ViewChild( 'footerTarget', { read: ViewContainerRef, static: true } ) footerTarget: ViewContainerRef;
    @ViewChild( 'headlineTarget', { read: ViewContainerRef, static: true } ) headlineTarget: ViewContainerRef;
    @ViewChild( 'footerContainer', { static: true } ) footerContainer: ElementRef;
    @ViewChild( 'headerContainer', { static: true } ) headerContainer: ElementRef;
    @ViewChild( 'dialog', { static: true } ) dialog: ElementRef;

    @HostBinding( 'style.display' ) display: string = 'none';

    public config: DialogConfig;

    maxHeight: string = '100%';

    private contentRef: EmbeddedViewRef<any>;
    private headlineRef: EmbeddedViewRef<any>;
    private footerRef: EmbeddedViewRef<any>;

    private domMutationSubject$: Subject<number> = new Subject();

    private isDestroyed: Subject<boolean> = new Subject<boolean>();

    constructor(
        private renderer: Renderer2,
        private eventService: WindowEventService,
        private dialogService: DialogService,
        private backdropService: BackdropService,
        private elementRef: ElementRef,
        private overlayPositionService: OverlayPositionService,
    ) { }

    ngAfterViewInit(): void {
        this.domMutationSubject$
            .pipe(
                distinct(),
                filter( () => this.isDialogVisible() ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                this.determineDialogPosition();
            } );


        const observer: MutationObserver = new MutationObserver( ( mutations:  MutationRecord[] ) => {
            mutations.filter(( mutation: MutationRecord ) => mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0 )
                     .forEach(() => {
                         // produce an event with time (in centisecond), which then can be filtered by distinctUntilChanged
                         this.domMutationSubject$.next( Math.floor( new Date().getTime() / 100 ) );
                     });
        });

        observer.observe( this.elementRef.nativeElement, { attributes: true, childList: true, characterData: true, subtree: true } );
    }


    ngOnInit(): void {
        this.eventService
            .resized$
            .pipe(
                filter( () =>  this.isDialogVisible() ),
                takeUntil( this.isDestroyed ),
            )
            .subscribe( () => {
                this.determineDialogPosition();
            } );

        this.dialogService
            .dialogConfig$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( config: DialogConfig ) => {
                this.config = config;

                if ( this.headlineRef ) {
                    this.headlineRef.destroy();
                }
                if ( this.contentRef ) {
                    this.contentRef.destroy();
                }
                if ( this.footerRef ) {
                    this.footerRef.destroy();
                }

                if ( config.headlineTemplate ) {
                    this.headlineRef = this.headlineTarget.createEmbeddedView( config.headlineTemplate, config.templateData );
                }

                this.contentRef = this.target.createEmbeddedView( config.contentTemplate, config.templateData );

                if ( config.footerTemplate ) {
                    this.footerRef = this.footerTarget.createEmbeddedView( config.footerTemplate, config.templateData );
                }

                this.showDialog();

                setTimeout( () => {
                    this.determineDialogPosition();
                } );
            } );

        this.dialogService
            .isDialogVisible$
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( isVisible: boolean ) => {
                if ( isVisible ) {
                    this.showDialog();
                }
                else {
                    this.hideDialog();
                }
            } );
    }

    ngOnDestroy(): void {
        this.isDestroyed.next( true );
        this.isDestroyed.complete();
    }

    closeDialog(): void {
        if ( typeof this.config.cancelCallback !== 'undefined' ) {
            this.config.cancelCallback();
        }
        this.dialogService.closeDialog();
    }

    centerDialog(): void {
        const coordinates: OverlayPositionCoordinates = this.overlayPositionService.getCenteredPosition( this.dialog.nativeElement );

        this.renderer.setStyle( this.dialog.nativeElement, 'top',  coordinates.top + 'px' );
        this.renderer.setStyle( this.dialog.nativeElement, 'left',  coordinates.left + 'px' );
    }

    clickedOutside( event: MouseEvent ): void {
        if ( this.isDialogVisible() && this.config.anchorElement !== event.target ) {
            this.closeDialog();
        }
    }

    private determineDialogPosition(): void {
        this.calculateMaxHeight();

        if ( this.config && this.config.anchorElement ) {
            this.positionDialog( this.config.anchorElement );
        }
        else {
            this.centerDialog();
        }
    }

    // this is needed for IE, because it doesn't draw a scrollbar
    private calculateMaxHeight(): void {
        const viewportHeight: number = window.document.documentElement.getBoundingClientRect().height;
        const footerHeight: number   = this.footerContainer.nativeElement.getBoundingClientRect().height;
        const headerHeight: number   = this.headerContainer.nativeElement.getBoundingClientRect().height;

        this.maxHeight = ( viewportHeight - headerHeight - footerHeight ) + 'px';
    }

    private positionDialog( element: HTMLElement ): void {
        const overlayPosition: OverlayPositionCoordinates = this.overlayPositionService.getOverlayPosition( this.dialog.nativeElement, element );

        this.renderer.setStyle( this.dialog.nativeElement, 'top', overlayPosition.top + 'px' );
        this.renderer.setStyle( this.dialog.nativeElement, 'left', overlayPosition.left + 'px' );
    }

    private showDialog(): void {
        this.display = 'block';

        if ( this.config && this.config.withBackdrop ) {
            this.backdropService.showBackdrop();
        }
    }

    private hideDialog(): void {
        this.display = 'none';
        this.target.clear();
        this.headlineTarget.clear();
        this.footerTarget.clear();

        if ( this.config ) {
            if ( this.config.withBackdrop ) {
                this.backdropService.hideBackdrop();
            }

            this.config = null;
        }
    }

    private isDialogVisible(): boolean {
        return this.display !== 'none';
    }

}
