import { Component, Input, } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { OptionItem } from '../../../../../../shared/model/optionItem';
import { ConceptInfo } from '../../../../../../shared/model/conceptInfo';
import { Tag } from '../../../../../../shared/ui/tags/tag';

@Component( {
    selector:    'rsp-display-edit-cluster-categories',
    templateUrl: './display-edit-cluster-categories.component.html',
    styleUrls:   [
        './display-edit-cluster-categories.component.scss',
    ],
} )
export class DisplayEditClusterCategoriesComponent {
    @Input() categories: Array<OptionItem>;

    @Input() concept: ConceptInfo;

    @Input() myFormControl: UntypedFormControl;

    @Input() isEditMode: boolean;


    getCategoriesAsTags(): Array<Tag> {

        if ( this.categories ) {

            const tags: Array<Tag> = [];

            this.categories.map( ( category: OptionItem ) => {
                tags.push( new Tag( category.name ) );
            } );

            return tags;
        }

        return null;
    }

}
