<ng-template #dialogHeaderTemplate>
    <h2 class="standard-dialog--headline">Create new Concept</h2>
</ng-template>

<ng-template #dialogContentTemplate>
    <div
        [rspLoadingIndicator]="isLoading"
        class="standard-dialog--content"
    >
        <rsp-display-edit-text
            #newConceptNameInputComponent
            [isEditMode]="true"
            [myFormGroup]="form"
            [name]="'name'"
            [placeholder]="'Concept name'"
        ></rsp-display-edit-text>
    </div>
</ng-template>

<ng-template #dialogFooterTemplate>
    <div class="standard-dialog--footer">
        <rsp-button
            [type]="'button'"
            [submodule]="'secondary'"
            (clicked)="close()"
        >
            Cancel
        </rsp-button>

        <rsp-button
            [type]="'button'"
            [submodule]="'primary'"
            [disabled]="!form.valid"
            (clicked)="doAction()"
            class="create-concept-dialog--submit-button"
        >
            Create
        </rsp-button>
    </div>
</ng-template>
