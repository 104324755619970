import { Component, Input, OnInit, } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

import { DisplayEditBaseComponent } from '../../../../../../../../shared/forms/controls/shared/display-edit-base.component';
import { ValidationMessagesService } from '../../../../../../../../shared/forms/validation/validation-messages.service';
import { OptionItem } from '../../../../../../../../shared/model/optionItem';
import { StoreSubFormatListItem, ViewModelListStoreSubFormatListItem } from '../../../../../../../../shared/model';
import { MasterDataStoreSubFormatsService } from '../../../../../../../../shared/api';

@Component( {
    selector:    'rsp-display-edit-store-sub-formats',
    templateUrl: './display-edit-store-sub-formats.component.html',
    styleUrls:   [
        './display-edit-store-sub-formats.component.scss',
    ],
} )
export class DisplayEditStoreSubFormatsComponent extends DisplayEditBaseComponent implements OnInit {
    @Input() storeSubFormats: OptionItem[];

    storeSubFormatOptions: StoreSubFormatListItem[]         = [];
    originalStoreSubFormatOptions: StoreSubFormatListItem[] = [];

    filteredStoreSubFormatOptions: StoreSubFormatListItem[];

    constructor(
        validationMessageService: ValidationMessagesService,
        private storeSubFormatsApi: MasterDataStoreSubFormatsService,
    ) {
        super( validationMessageService );
    }

    ngOnInit(): void {
        this.storeSubFormatsApi
            .storeSubFormatsGetList()
            .pipe( takeUntil( this.isDestroyed ) )
            .subscribe( ( result: ViewModelListStoreSubFormatListItem ) => {
                this.originalStoreSubFormatOptions = result.data;
                this.updateStoreSubFormatOptions();
            } );
    }

    setStoreSubFormat( storeSubFormat: StoreSubFormatListItem ): void {
        const newStoreSubFormats: OptionItem[] = this.storeSubFormats;
        newStoreSubFormats.push( {
            name: storeSubFormat.name,
            id:   storeSubFormat.id,
        } );
        this.storeSubFormats = newStoreSubFormats;
        this.updateStoreSubFormatOptions();
    }

    removeStoreSubFormat( storeSubFormat: StoreSubFormatListItem ): void {
        const index: number = this.storeSubFormats.findIndex( ( old: OptionItem ) => { return old.id === storeSubFormat.id; } );

        if ( index !== -1 ) {
            this.storeSubFormats.splice(index, 1);
        }

        this.updateStoreSubFormatOptions();
    }

    setFilteredStoreSubFormatOptions( items: StoreSubFormatListItem[] ): void {
        this.filteredStoreSubFormatOptions = items;
    }

    private updateStoreSubFormatOptions(): void {
        this.storeSubFormatOptions =
            this.originalStoreSubFormatOptions.filter( ( item: StoreSubFormatListItem ) => {
                return this.storeSubFormats.findIndex( ( current: StoreSubFormatListItem ) => { return item.id === current.id; } ) === -1;
            } );

        this.myFormControl.setValue( this.storeSubFormats.map( ( item: OptionItem ) => item.id ) );
    }

}
