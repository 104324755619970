import { Component, Input, ChangeDetectorRef, } from '@angular/core';

import { Tab, } from './tab';

@Component( {
    selector:    'rsp-vertical-tabs',
    templateUrl: './vertical-tabs.component.html',
    styleUrls:   [
        './vertical-tabs.component.scss',
    ],
} )
export class VerticalTabsComponent {
    @Input()
    get tabs(): Array<Tab> {
        return this._tabs;
    }

    set tabs( value: Array<Tab> ) {
        this._tabs = value;
        // Prevent "Expression has changed after it was checked."
        // https://github.com/angular/angular/issues/6005
        this.changeDetectionRef.detectChanges();
    }


    private _tabs: Array<Tab>;

    constructor( private changeDetectionRef: ChangeDetectorRef ) {
    }
}
