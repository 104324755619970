import { Component, Input, AfterContentInit } from '@angular/core';

import { AccordionGroupComponent } from './accordion-group/accordion-group.component';


/**
 * Accordion component. Groups and controls many accordion groups.
 *
 * Simple accordion:
 * ```html
 *      <rsp-accordion [closeOthers]="true|false">
 *          <rsp-accordion-group [heading]="Item 1">Simple content 1</rsp-accordion-group>
 *          <rsp-accordion-group [heading]="Item 2">Simple content 2</rsp-accordion-group>
 *          <rsp-accordion-group [heading]="Item 3">Simple content 3</rsp-accordion-group>
 *          <rsp-accordion-group [heading]="Item 4">Simple content 4</rsp-accordion-group>
 *      </rsp-accordion>
 * ```
 *
 * With complex heading:
 *
 * ```html
 *      <rsp-accordion>
 *          <rsp-accordion-group>
 *              <!-- heading -->
 *              <ng-container accordion-group-heading>
 *                  <!-- put your heading here -->
 *              </ng-container>
 *
 *              <!-- put your content here -->
 *          </rsp-accordion-group>
 *          <rsp-accordion-group [heading]="Item with text heading">Simple content</rsp-accordion-group>
 *      </rsp-accordion>
 * ```
 */
@Component( {
    selector:    'rsp-accordion',
    templateUrl: './accordion.component.html',
    styleUrls:   [
        './accordion.component.scss',
    ],
} )
export class AccordionComponent implements AfterContentInit {

    /**
     * If set to true, expanding one accordion group will close all others.
     */
    @Input()
    closeOthers: boolean = true;

    /**
     * Disables accordion.
     */
    @Input()
    get isDisabled(): boolean {
        return this._isDisabled;
    }

    set isDisabled( value: boolean ) {
        this.setIsDisabled( value );
    }


    private _isDisabled: boolean                   = false;
    private groups: Array<AccordionGroupComponent> = [];


    ngAfterContentInit(): void {
        // all child views (accordion-group) are already in DOM, disable they if necessary
        if ( this.isDisabled ) {
            this.setIsDisabled( true );
        }
    }


    addGroup( group: AccordionGroupComponent ): void {

        this.groups.push( group );
    }

    removeGroup( group: AccordionGroupComponent ): void {

        const index: number = this.groups.indexOf( group );
        if ( index !== -1 ) {
            this.groups.splice( index, 1 );
        }
    }

    closeOtherGroups( openGroup: AccordionGroupComponent ): void {

        if ( !this.closeOthers ) {
            return;
        }

        this.groups
            .forEach( ( group: AccordionGroupComponent ) => {
                if ( group !== openGroup && !group.isDisabled && group.isOpen ) {
                    group.isOpen = false;
                    group.isOpened.emit( false );
                }
            } );
    }


    // private methods
    // ----------------------------------------------------------------------------------------------------------------

    private setIsDisabled( value: boolean ): void {

        this._isDisabled = value;

        this.groups
            .forEach( ( group: AccordionGroupComponent ) => {
                group.isDisabled = value;
            } );
    }
}
